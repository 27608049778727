import React, { useState, useEffect } from "react";
import { Redirect, useLocation, useHistory, Link } from "react-router-dom";
import { DefaultContainer } from "../../../containers/UIKit";
import PeopleBlue from "../../../assets/img/bg/people-blue.png";
import { Icon } from "@iconify/react";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import useMobile from "../../../hooks/useMobile";

import {
  Card,
  CardBody,
  CardGroup,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { withAuthentication } from "../../../containers/Session";
import { withFirebase } from "../../../containers/Firebase";
import DeleteAccountLink from "../../../containers/DeleteAccountLink";
import "./Edit.scss";
import styled from "styled-components/macro";
import { COLORS } from "../../../assets/fansaves-ui";
import UnlockExclusives from "../../Pages/UnlockExclusives/UnlockExclusives";
import {
  PersonalInfoEdit,
  PasswordSection,
  RedeemedDeals,
  UnlockedDeals,
  DistanceEdit,
  LanguageEdit,
} from "../../../containers/Profile";

const ProfileCardGroup = styled(CardGroup)`
  display: flex;
  border-top: 1px solid ${COLORS.mediumDarkGrey};

  @media (max-width: 730px) {
    flex-direction: column;
  }
`;

const ProfileTitle = styled.div`
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: ${COLORS.primaryBlack};
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`;

const SlideMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);

  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
  transition: transform 0.3s ease-in-out;
`;

const MenuToggleRight = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 30px;
  height: 60px;
  background-color: ${COLORS.primaryGreenDark};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    border: solid ${COLORS.primaryWhite};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: translateX(1px) rotate(135deg);
    margin-left: 7px;
  }
`;

const NavigationCard = styled(Card)`
  border: none;
  flex: 1;
  max-width: ${({ isMobile }) => (isMobile ? "100%" : "320px")};
  background-color: ${COLORS.lightestBlue};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
  height: fit-content;
`;

const NavigationCardBody = styled(CardBody)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const FormCard = styled(Card)`
  border: none;
  flex: 3;
  flex-grow: 1;
  position: relative;
  min-height: 690px;
  height: 100%;

  @media (max-width: 730px) {
    margin-bottom: 0;
  }
`;

const FormCardBody = styled(CardBody)`
  position: relative;
  padding: 0;
`;

const NavigationList = styled(ListGroup)`
  gap: 10px;
  .list-group-item {
    border: none;
    background-color: ${({ active }) =>
      active ? COLORS.mediumBlue : COLORS.lightestBlue};
    border-radius: ${({ active }) => (active ? "10px" : "0")};
    color: ${COLORS.primaryBlack};
    padding: 0;
    margin: 0;
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: ${COLORS.primaryBlack};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.mediumBlue};
      border-radius: 10px;
    }

    &:focus-visible {
      outline: none;
    }

    &.active {
      background-color: ${COLORS.mediumBlue};
      border-radius: 10px;
    }

    .purchases-link {
      color: ${COLORS.primaryBlack};
      text-decoration: none;
      font-weight: 500;
      font-size: 20px;
      font-family: "Manrope", sans-serif;
    }
  }
`;

const UnlockExclusivesContainer = styled.div`
  .component-unlock-exclusives {
    min-height: 690px;
    height: 100%;
    padding-top: 0px;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 8, 62, 0.7);
    }

    .component-default-container {
      padding: 20px;
    }

    .enter-code-page {
      margin: 0px auto;
    }
  }
`;

const DeleteAccountLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const Edit = ({ authUser, firebase }) => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const params = new URLSearchParams(location.search);
  const activeTab = params.get("tab") || "1";

  useEffect(() => {
    if (!params.get("tab")) {
      params.set("tab", "1");
      history.replace({ search: params.toString() });
    }
  }, [history, params]);

  if (!authUser) {
    return <Redirect to="/" />;
  }

  const onTabClick = (tab) => {
    params.set("tab", tab);

    params.delete("subTab");
    history.push({ search: params.toString() });
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };
  const renderActiveTab = () => {
    switch (activeTab) {
      case "1":
        return (
          <PersonalInfoEdit
            authUser={authUser}
            firebase={firebase}
            onTabClick={onTabClick}
          />
        );
      case "2":
        return (
          <UnlockExclusivesContainer>
            <UnlockExclusives />
          </UnlockExclusivesContainer>
        );
      case "3":
        return <PasswordSection />;
      case "4":
        return <RedeemedDeals />;
      case "5":
        return <UnlockedDeals />;
      case "6":
        return <LanguageEdit />;
      case "7":
        return <DistanceEdit />;
      default:
        return null;
    }
  };

  return (
    <CheckOnboarding>
      <DefaultContainer style={{ position: "relative", height: "100vh" }}>
        <Row className="justify-content-center">
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            {isMobile && (
              <>
                <MenuToggleRight onClick={() => setIsMenuOpen(!isMenuOpen)}>
                  <Icon
                    icon="bx:bx-menu"
                    width={24}
                    height={24}
                    color={COLORS.primaryGreenDark}
                  />
                </MenuToggleRight>
                <SlideMenu isOpen={isMenuOpen}>
                  <NavigationCard isMobile={isMobile}>
                    <NavigationCardBody>
                      <TopMenu>
                        <ProfileTitle>Profile</ProfileTitle>
                        <NavigationList flush>
                          <ListGroupItem
                            active={activeTab === "1"}
                            onClick={() => onTabClick("1")}
                          >
                            <Icon
                              icon="mdi:user"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Personal Information
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "2"}
                            onClick={() => onTabClick("2")}
                          >
                            <Icon
                              icon="ph:confetti-bold"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Unlock Exclusive Deals
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "3"}
                            onClick={() => onTabClick("3")}
                          >
                            <Icon
                              icon="mdi:shield-lock"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Password
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "4"}
                            onClick={() => onTabClick("4")}
                          >
                            <Icon
                              icon="material-symbols:redeem"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Redeemed Deals
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "5"}
                            onClick={() => onTabClick("5")}
                          >
                            <Icon
                              icon="bx:bxs-lock-open"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Unlocked Deals
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "6"}
                            onClick={() => onTabClick("6")}
                          >
                            <Icon
                              icon="fa-solid:globe"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px" }}
                            />
                            Language
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "7"}
                            onClick={() => onTabClick("7")}
                          >
                            <Icon
                              icon="tdesign:measurement"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px", rotate: "45deg" }}
                            />
                            Distance
                          </ListGroupItem>
                          <ListGroupItem
                            active={activeTab === "8"}
                            onClick={() => onTabClick("8")}
                          >
                            <Icon
                              icon="bx:bxs-purchase-tag"
                              width={24}
                              height={24}
                              color={COLORS.primaryGreenDark}
                              style={{ marginRight: "10px", rotate: "90deg" }}
                            />
                            <Link to="/purchases" className="purchases-link">
                              My Purchases
                            </Link>
                          </ListGroupItem>
                        </NavigationList>
                      </TopMenu>
                      <DeleteAccountLinkContainer>
                        <DeleteAccountLink />
                      </DeleteAccountLinkContainer>
                    </NavigationCardBody>
                    <img
                      src={PeopleBlue}
                      style={{
                        objectFit: "contain",
                        padding: "10px",
                      }}
                      alt="Background"
                    />
                  </NavigationCard>
                </SlideMenu>
              </>
            )}
            <ProfileCardGroup>
              <NavigationCard className="d-sm-down-none">
                <NavigationCardBody>
                  <TopMenu>
                    <ProfileTitle>Profile</ProfileTitle>
                    <NavigationList flush>
                      <ListGroupItem
                        active={activeTab === "1"}
                        onClick={() => onTabClick("1")}
                      >
                        <Icon
                          icon="mdi:user"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Personal Information
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "2"}
                        onClick={() => onTabClick("2")}
                      >
                        <Icon
                          icon="ph:confetti-bold"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Unlock Exclusive Deals
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "3"}
                        onClick={() => onTabClick("3")}
                      >
                        <Icon
                          icon="mdi:shield-lock"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Password
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "4"}
                        onClick={() => onTabClick("4")}
                      >
                        <Icon
                          icon="material-symbols:redeem"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Redeemed Deals
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "5"}
                        onClick={() => onTabClick("5")}
                      >
                        <Icon
                          icon="bx:bxs-lock-open"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Unlocked Deals
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "6"}
                        onClick={() => onTabClick("6")}
                      >
                        <Icon
                          icon="fa-solid:globe"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px" }}
                        />
                        Language
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "7"}
                        onClick={() => onTabClick("7")}
                      >
                        <Icon
                          icon="tdesign:measurement"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px", rotate: "45deg" }}
                        />
                        Distance
                      </ListGroupItem>
                      <ListGroupItem
                        active={activeTab === "8"}
                        onClick={() => onTabClick("8")}
                      >
                        <Icon
                          icon="bx:bxs-purchase-tag"
                          width={24}
                          height={24}
                          color={COLORS.primaryGreenDark}
                          style={{ marginRight: "10px", rotate: "90deg" }}
                        />
                        <Link to="/purchases" className="purchases-link">
                          My Purchases
                        </Link>
                      </ListGroupItem>
                    </NavigationList>
                  </TopMenu>
                  <DeleteAccountLinkContainer>
                    <DeleteAccountLink />
                  </DeleteAccountLinkContainer>
                </NavigationCardBody>
                <img
                  src={PeopleBlue}
                  style={{
                    objectFit: "contain",
                  }}
                  alt="Background"
                />
              </NavigationCard>
              <FormCard className="profile-form-card">
                <FormCardBody>{renderActiveTab()}</FormCardBody>
              </FormCard>
            </ProfileCardGroup>
          </Col>
        </Row>
      </DefaultContainer>
    </CheckOnboarding>
  );
};

export default withAuthentication(withFirebase(Edit));
