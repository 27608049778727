import React, { useEffect } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";

import "../../../scss/base/typography.scss";
import { COLORS } from "../colors";

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  .label {
    color: ${({ titleColor }) => titleColor || COLORS.primaryWhite};
  }
`;

const Switch = styled.div`
  position: relative;
  width: 30px;
  height: 18px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 2px;
    background: ${COLORS.primaryWhite};
    transform: translate(0, -50%);
  }
`;

const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: ${({ backgroundColor }) => backgroundColor};

    &:before {
      transform: translate(10px, -50%);
    }
  }
`;

const ToggleButton = ({
  onChange,
  checked,
  labelLeft,
  labelRight,
  backgroundColor,
  titleColor,
}) => {
  function handleKeyPress(e) {
    if (e.key === "ArrowRight") {
      onChange(true);
    } else if (e.key === "ArrowLeft") {
      onChange(false);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [checked]);

  return (
    <Label className="toggle-button" titleColor={titleColor}>
      {labelLeft && <span className="roboto-b3 label">{labelLeft}</span>}
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        onKeyDown={handleKeyPress}
        backgroundColor={backgroundColor}
      />
      <Switch backgroundColor={backgroundColor} />
      {labelRight && <span className="roboto-b3 label">{labelRight}</span>}
    </Label>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  onChange: propTypes.func.isRequired,
  checked: propTypes.bool.isRequired,
  labelLeft: propTypes.string,
  labelRight: propTypes.string,
};
