import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import DealSearchResult from "../DealSearchResult";

import {
  Configure,
  InstantSearch,
  Hits,
  PoweredBy,
} from "react-instantsearch-dom";
import { searchClient, ResultsWithEmptyStateLoading } from "../Algolia";

const UnlockedDealsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const RedeemedDealResults = styled.div`
  display: flex;
  flex-direction: column;

  /* Algolia Styling */
  .ais-Hits {
    ul.ais-Hits-list {
      list-style: none;
      padding-left: 0;
      justify-content: flex-start;
      gap: 7%;

      display: flex;
      flex-wrap: wrap;

      .ais-Hits-item {
        margin-right: 10px;
      }
    }
  }
  .algolia-powered-by-container {
    padding: 10px 0;
    text-align: center;
  }

  .loading-deals-placeholder-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 7%;
  }
  .component-results-with-empty-state.empty-state {
    text-align: center;
    padding: 80px 0;
    font-size: 20px;
    font-family: "Manrope", sans-serif;
    color: ${COLORS.primaryGrey};
  }

  @media screen and (max-width: 850px) {
    display: block;
    width: 100%;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
    .ais-Hits {
      ul.ais-Hits-list {
        justify-content: center;
      }
    }
  }
`;

const UnlockedDeals = ({ authUser }) => {
  const updateTagFilters = useCallback(() => {
    if (authUser && authUser.unlockedSpecialDeals) {
      const accessTags = Object.entries(authUser.unlockedSpecialDeals).flatMap(
        ([teamKey, value]) => {
          return Object.keys(value).map(
            (accessLevel) => `hidden${teamKey}-${accessLevel}`
          );
        }
      );

      const tags = [[...accessTags, "-hidden"]];

      return tags;
    }

    return ["-hidden"];
  }, [authUser]);

  const unlockExclusivesFilters = useCallback(() => {
    let filterString = "";
    if (authUser && authUser.unlockedExclusivesTeams) {
      const unlockedExclusivesTeams = Object.keys(
        authUser.unlockedExclusivesTeams || {}
      );
      filterString = `teams.key:${unlockedExclusivesTeams.join(
        " OR teams.key:"
      )}`;
    } else {
      // Set an impossible filter condition to ensure no results are returned
      filterString = "teams.key:nonexistentteam";
    }

    filterString = `published:true AND (exclusive:true OR hiddenDeal:true) AND (${filterString})`;

    return filterString;
  }, [authUser]);

  return (
    <UnlockedDealsContainer>
      <Title>Profile / Unlocked Deals</Title>
      <InstantSearch searchClient={searchClient} indexName="offers">
        <Configure
          filters={unlockExclusivesFilters()}
          tagFilters={updateTagFilters()}
        />
        <RedeemedDealResults>
          <ResultsWithEmptyStateLoading
            message="You haven't unlocked any deals yet or your unlocked deals are not available at the moment."
            loadingPlacehoderCount={3}
          >
            <Hits hitComponent={DealSearchResult} />
          </ResultsWithEmptyStateLoading>

          <div className="algolia-powered-by-container">
            <PoweredBy />
          </div>
        </RedeemedDealResults>
      </InstantSearch>
    </UnlockedDealsContainer>
  );
};

export default withFirebase(withAuthentication(UnlockedDeals));
