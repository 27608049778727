import { create } from "zustand";
import {
  loadAllTeams,
  loadTeam,
  updateTeamWithExclusiveCodesUserEmails,
} from "../utilities/team_helpers";

const useTeamsStore = create((set, get) => ({
  teams: null,
  team: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchTeams: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const onSuccess = (teams) => {
        set({ teams });
      };

      const onError = (error) => {
        set({ error });
      };

      loadAllTeams(firebase, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchTeam: async (firebase, teamId) => {
    try {
      const onSuccess = async (team) => {
        const teamObj = {};
        const updatedTeam = await updateTeamWithExclusiveCodesUserEmails(team);
        teamObj[teamId] = updatedTeam;
        set({ team: teamObj });
      };

      const onError = (error) => {
        set({ error });
      };

      loadTeam(firebase, teamId, onSuccess, onError);
    } catch (error) {
      set({ error });
    }
  },

  setTeam: (team) => {
    if (team) {
      const updatedTeam = updateTeamWithExclusiveCodesUserEmails(team);
      const teamObj = {};
      teamObj[team.id] = updatedTeam;
      set({ team: teamObj });
    } else {
      set({ team });
    }
  },

  updateTeamExclusiveCodes: (codes, teamId) => {
    const newTeam = { ...get().team };
    if (!newTeam[teamId]) {
      newTeam[teamId] = {};
    }
    newTeam[teamId].exclusiveCodes = codes;
    set({ team: newTeam });
  },
}));

export default useTeamsStore;
