import React, { Component } from "react";
import { dbLoadTeam } from "../../../../containers/DatabaseHelpers/DatabaseHelpers";
import { withFirebase } from "../../../../containers/Firebase";
import TeamForm from "../Form";
import { Banner } from "../../../../containers/UIKit";
import { uploadResourceImages } from "../../utilities/FormHelpers";
import {
  removeUndefineFieldFromObject,
  removeDuplicateElementInArray,
} from "../../../../utilities/core";

import "./Edit.scss";

class Edit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTeam: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushTeamData = this.pushTeamData.bind(this);
  }

  componentDidMount() {
    this.setState(
      {
        teamKey: this.props.match.params.team_id,
      },
      () => {
        this.loadTeam();
      }
    );
  }

  loadTeam = () => {
    const { firebase } = this.props;
    const { teamKey } = this.state;

    dbLoadTeam(firebase, teamKey, (team) => {
      this.setState({ team, loadingTeam: false });
    });
  };

  handleSubmit(teamData) {
    const { teamKey } = this.state;
    uploadResourceImages(
      this.props.firebase,
      teamKey,
      teamData,
      "images/teams",
      ["emblem", "logo", "teamPhoto"],
      this.pushTeamData
    );
  }

  pushTeamData = (teamKey, teamData, imageUrls) => {
    const dataSet = {
      name: teamData.title,
      "short name": teamData.shortName,
      description: teamData.description,
      slug: teamData.slug,
      sport: teamData.sport,
      league: teamData.league,
      website: teamData.teamWebsite,
      division: teamData.division,
      "buy tickets link": teamData.buyTicketsLink,
      "app iOS": teamData.teamAppLinkiOS,
      "app android": teamData.teamAppLinkAndroid,
      "social fb": teamData.facebook,
      "social instagram": teamData.instagram,
      "social twitter": teamData.twitter,
      dashUrl: teamData.dashUrl,
      "social snapchat": teamData.snapchat,
      "arena name": teamData.arenaName,
      "arena address": teamData.arenaAddress,
      city: teamData.city,
      lat: teamData.lat ? parseFloat(teamData.lat) : teamData.lat,
      long: teamData.long ? parseFloat(teamData.long) : teamData.long,
      newsRssFeed: teamData.newsRssFeed,
      sponsors: teamData.teamSponsors,
      archived: teamData.archived,
      keywords: teamData.keywords,
      categories: removeDuplicateElementInArray(teamData.categories),
      subcategories: removeDuplicateElementInArray(teamData.subcategories),
    };

    if (imageUrls.emblemUrl) {
      dataSet.emblem = imageUrls.emblemUrl;
    }
    if (imageUrls.logoUrl) {
      dataSet.logo = imageUrls.logoUrl;
    }
    if (imageUrls.teamPhotoUrl) {
      dataSet.picture = imageUrls.teamPhotoUrl;
    }

    const teamRef = this.props.firebase.database.ref(`/team/${teamKey}`);

    let existingSponsorKeys = [];

    // Add/Remove team to teams property on sponsors
    teamRef
      .once("value")
      .then((teamSnapshot) => {
        if (teamSnapshot.val() !== null) {
          if (teamSnapshot.val().sponsors) {
            existingSponsorKeys = [...teamSnapshot.val().sponsors];
          }
        }
        return teamRef.update(removeUndefineFieldFromObject(dataSet));
      })
      .then(() => {
        const deletedSponsorKeys = [];

        existingSponsorKeys.forEach((existingSponsorKey) => {
          if (!teamData.teamSponsors.includes(existingSponsorKey)) {
            deletedSponsorKeys.push(existingSponsorKey);
          }
        });

        // Set teams property on sponsors who were kept
        const sponsorUpdatePromises = [];
        teamData.teamSponsors.forEach((sponsorKey) => {
          const sponsorTeamsRef = this.props.firebase.database.ref(
            `/sponsor/${sponsorKey}/teams/${teamKey}`
          );
          sponsorUpdatePromises.push(
            sponsorTeamsRef.set({ key: teamKey, name: teamData.title })
          );
        });

        // Remove team from sponsors who were deleted
        deletedSponsorKeys.forEach((sponsorKey) => {
          const sponsorTeamsRef = this.props.firebase.database.ref(
            `/sponsor/${sponsorKey}/teams/${teamKey}`
          );
          sponsorUpdatePromises.push(sponsorTeamsRef.remove());
        });

        return Promise.all(sponsorUpdatePromises);
      })
      .then(() => {
        this.props.history.push("/portal/teams");
      });
  };

  render() {
    const { loadingTeam, team } = this.state;

    return (
      <div className="views-portal-teams-edit animated fadeIn" id="main">
        {!loadingTeam && (
          <>
            {team && team.archived && (
              <Banner color="error">
                Warning: This team is currently archived
              </Banner>
            )}
            <TeamForm existingTeam={team} onSubmit={this.handleSubmit} />
          </>
        )}
      </div>
    );
  }
}

export default withFirebase(Edit);
