/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Icon } from "@iconify/react";

const DistancePreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-height: 600px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const ToggleButton = styled.button`
  display: flex;
  justify-content: space-between;
  max-width: 493px;
  width: 100%;
  height: fit-content;
  background: ${COLORS.primaryWhite};
  border-radius: 10px;
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primaryBlue};
`;

const ButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
`;

const distanceOptions = [
  { label: "Kilometers", value: "km" },
  { label: "Miles", value: "mi" },
];

const DistanceEdit = ({ firebase, authUser }) => {
  const [distancePreference, setDistancePreference] = useState(
    authUser?.distancePreference || "km"
  );

  const updateDistancePreference = useCallback(async () => {
    if (!authUser) return;

    if (authUser.distancePreference === distancePreference) return;

    const userPath = `/user/${authUser.key}`;
    const userRef = firebase.database.ref(userPath);

    try {
      await userRef.update({ distancePreference });
    } catch (error) {
      console.error(error);
    }
  }, [authUser, distancePreference, firebase]);

  useEffect(() => {
    updateDistancePreference();
  }, [distancePreference, updateDistancePreference]);

  const handleDistanceChange = async (value) => {
    if (value !== distancePreference) {
      setDistancePreference(value);
      await updateDistancePreference(value);
    }
  };
  return (
    <DistancePreferenceContainer>
      <Title>Profile / Distance</Title>
      {distanceOptions.map((option) => (
        <ToggleButton
          key={option.value}
          onClick={() => handleDistanceChange(option.value)}
        >
          <ButtonTitle>{option.label}</ButtonTitle>
          {distancePreference === option.value && (
            <Icon
              icon="akar-icons:check"
              style={{ color: COLORS.primaryGreen }}
              width={24}
            />
          )}
        </ToggleButton>
      ))}
    </DistancePreferenceContainer>
  );
};

export default withFirebase(withAuthentication(DistanceEdit));
