function isAdmin(authUser) {
  return (authUser && authUser.role === 'admin');
}

function isSponsor(authUser) {
  return (authUser && authUser.role === 'sponsor');
}

function isTeam(authUser) {
  return (authUser && authUser.role === 'team');
}

export { isAdmin, isSponsor, isTeam };