import React from "react";

import "./ExclusiveOverlay.scss";
import lockIcon from "../../assets/img/Lock.png";
import unlockIcon from "../../assets/img/Unlock.png";

const ExclusiveOverlay = ({ deal, unlocked, size }) => {
  if (!deal.exclusive) {
    return <></>;
  }

  return (
    <div className="component-exclusive-overlay">
      {deal.exclusive && (
        <div className="exclusive-deal">
          {unlocked ? (
            <img
              className="deal-exclusive-icon"
              src={unlockIcon}
              alt="deal-image"
            />
          ) : (
            <img
              className="deal-exclusive-icon"
              src={lockIcon}
              alt="deal-image"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ExclusiveOverlay;
