export const COLORS = {
  /* Brand Colours */
  primaryBlue: "#00083e",
  primaryBlueHover: "#333964",
  secondaryBlue: "#000f80",
  primaryGreen: "#35b729",
  primaryGreenHover: "#50B847",
  primaryGreenLight: "#4cc441",
  primaryGreenDark: "#25a919",
  primaryWhite: "#ffffff",
  primaryBlack: "#000000",
  primaryGrey: "#2b2b32",
  primaryGreyLight: "#e3e3e3",
  transparentBlue: "rgba(116, 124, 177, 0.06)",
  transparentBlack: "rgba(0, 0, 0, 0.75)",
  primaryDarkBlue: "#01073b",
  hoverGrey: "rgba(0, 7, 56, 0.2)",
  greyText: "#464646",

  chartRed: "#BA0909",
  chartGrey: "#E5E6EB",
  errorRed: "#BA1E1E",

  yellow: "#C9C101",

  /* SHADES */
  darkGrey: "#7e7f86",
  mediumGrey: "#959595",
  mediumDarkGrey: "#656565",
  lightGrey: "#dfdfe0",
  lightBlue: "#dde5ff",
  mediumBlue: "#CFE6FF",
  lightestBlue: "#F2F9FF",

  /* Box Shadows */
  primaryGreen30: "rgba(53, 183, 41, 0.3)",
  darkGreen25: "rgba(21, 73, 16, 0.25)",
  darkGreen15: "rgba(21, 73, 16, 0.15)",
  grey: "rgba(126, 127, 134, 0.2)",
  blue30: "rgba(0, 8, 62, 0.3)",
  blue15: "rgba(0, 8, 62, 0.15)",
  blue10: "rgba(0, 8, 62, 0.1)",
  lightBlue25: "rgba(221, 229, 255, 0.25)",

  /* Backgrounds */
  backgroundWhite: "rgba(228, 228, 228, 0.2)",

  /* ----------- Legacy Swatches ----------------------- */
  fansavesGreenDark2: "#106109",
  fansavesGreenDark1: "#219117",
  fansavesGreen: "#35b729",
  fansavesGreenLight1: "#4cc441",
  fansavesGreenLight2: "#84e07b",
  fansavesGreenLight3: "#bdf5b8",
  fansavesGreenLight4: "#e5ffe3",

  /* Note, this is the same $primaryColor right now */
  fansavesBlue: "#00083e; ",
  fansavesBlueLight1: "#000a4d",

  primaryColorLight2: "#dde5ff",
  primaryColorLight1: "#000f80",
  primaryColor: "#00083e",
  primaryColorDark1: "#000420",

  redColorDark1: "#990000",

  grayDark2: "#333333",
  grayDark1: "#555555",
  gray: "#777777",
  grayLight1: "#999999",
  grayLight2: "#aaaaaa",
  grayLight3: "#cccccc",
  grayLight4: "#ededed",
  grayLight5: "#fafafa",

  successColorLight3: "#f1fff1",
  successColor: "#009900",
  successColorDark1: "#005500",

  errorColorLight3: "#ffeeee",
  errorColorLight2: "#ffdddd",
  errorColorLight1: "#ffaaaa",
  errorColor: "#ff0000",
  errorColorDark1: "#aa0000",
  errorColorDark2: "#880000",
  errorColorDark3: "#550000",
};
