import React from "react";
import { Icon } from "@iconify/react";
import { COLORS } from "../colors";

const DeviceInfoIcon = ({ deviceInfo, width, height }) => {
  if (!deviceInfo) {
    return "-";
  }

  let icon;
  if (deviceInfo.web) {
    icon = "mdi:web";
  } else if (deviceInfo.platform === "iOS") {
    icon = "mdi:apple-ios";
  } else if (deviceInfo.platform === "Android") {
    icon = "wpf:android-os";
  } else {
    icon = "material-symbols-light:device-unknown-outline";
  }

  return (
    <Icon
      icon={icon}
      width={width || 20}
      height={height || 20}
      className="device-icon"
      style={{ color: COLORS.primaryBlue }}
    />
  );
};

export default DeviceInfoIcon;
