import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  DashboardButton,
  FlexContainer,
  DashboardCard,
  DoughnutChart,
  DashboardTitle,
  GeoChart,
  SpinLoader,
} from "../../assets/fansaves-ui";

import PortalHorizontalChartContainer from "../PortalHorizontalChartContainer/PortalHorizontalChartContainer";

const ExpandedDashboardCard = styled(DashboardCard)`
  height: ${(props) => {
    const { expanded, locationChartData } = props;
    let height = 480;
    if (expanded && locationChartData) {
      height = Object.keys(locationChartData).length * 50 + 100;
    }
    if (height > 480) {
      return `${height}px`;
    } else {
      return "480px";
    }
  }};
`;

const DashboardRedemptionAnalytics = ({
  redemptions,
  genderChartData,
  ageChartData,
  locationChartData,
  selectedLocationChartData,
  heatMapData,
}) => {
  const [viewAllCitites, setViewAllCities] = useState(false);

  const handleViewAllCitiesClick = () => {
    setViewAllCities(!viewAllCitites);
  };

  const renderNoDataContainer = () => {
    return (
      <FlexContainer
        className="no-data-container"
        direction="vertical"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <span className="no-data-text">No Data Available</span>
      </FlexContainer>
    );
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="no-data-container"
        direction="vertical"
        height="100%"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };

  return (
    <FlexContainer
      className="dashboard-redemption-analytics-container"
      direction="vertical"
      justifyContent="space-around"
      gap="50px"
      margin="0 auto 50px auto"
    >
      <DashboardTitle
        title="Redemption Analytics"
        className="dashboard-chart-title"
      />
      <FlexContainer
        className="charts"
        direction="horizontal"
        responsive
        justifyContent="space-around"
        alignItems="flex-start"
        gap="20px"
        width="100%"
      >
        <DashboardCard
          className="dashboard-gender-chart-card"
          shadowStyle
          width="50%"
          height="470px"
          padding="20px 15px"
          responsive
        >
          <DashboardTitle
            title="Gender Demographics"
            className="dashboard-gender-chart-title"
          />
          {genderChartData ? (
            genderChartData.some((data) => data !== 0) ? (
              <DoughnutChart
                dataSet={genderChartData}
                className="dashboard-gender-chart"
              />
            ) : (
              renderNoDataContainer()
            )
          ) : (
            renderLoadingContainer()
          )}
        </DashboardCard>
        <DashboardCard
          className="dashboard-age-chart-card"
          shadowStyle
          width="50%"
          height="470px"
          padding="20px 15px"
          responsive
        >
          <DashboardTitle
            title="Redemption Age Range"
            className="dashboard-age-chart-title"
          />
          {ageChartData ? (
            ageChartData.some((data) => data !== 0) ? (
              <PortalHorizontalChartContainer data={ageChartData} type="age" />
            ) : (
              renderNoDataContainer()
            )
          ) : (
            renderLoadingContainer()
          )}
        </DashboardCard>
      </FlexContainer>
      <FlexContainer
        className="charts"
        direction="horizontal"
        responsive
        justifyContent="space-around"
        alignItems="flex-start"
        gap="20px"
        width="100%"
      >
        <FlexContainer
          className="cities-chart-container"
          direction="vertical"
          gap="20px"
          width="50%"
          mobileWidth="100%"
          responsive
          expanded={viewAllCitites}
        >
          <ExpandedDashboardCard
            className={`dashboard-cities-chart-card ${
              viewAllCitites ? "expanded" : ""
            }`}
            shadowStyle
            width="100%"
            height="470px"
            padding="20px 15px"
            expanded={viewAllCitites}
            responsive
            locationChartData={locationChartData}
          >
            <DashboardTitle
              title="Top Cities"
              className="dashboard-cities-chart-title"
            />
            {locationChartData ? (
              Object.keys(locationChartData).length > 0 ? (
                <PortalHorizontalChartContainer
                  data={
                    Object.keys(locationChartData).length > 6
                      ? viewAllCitites
                        ? locationChartData
                        : selectedLocationChartData
                      : locationChartData
                  }
                  type="cities"
                />
              ) : (
                renderNoDataContainer()
              )
            ) : (
              renderLoadingContainer()
            )}
          </ExpandedDashboardCard>
          {selectedLocationChartData &&
            Object.keys(locationChartData).length > 6 && (
              <DashboardButton
                title={viewAllCitites ? "View Less" : "View All"}
                className="view-all-cities"
                size="small"
                type="shadow-white"
                typography="public-h2"
                onClick={(e) => {
                  e.preventDefault();
                  handleViewAllCitiesClick();
                }}
                move="right"
                disabled={Object.keys(locationChartData).length < 6}
              />
            )}
        </FlexContainer>
        <DashboardCard
          className="dashboard-heat-map-card"
          shadowStyle
          width="50%"
          height="480px"
          padding="20px 15px"
          responsive
        >
          <DashboardTitle
            title="Redemption Heat Map"
            className="dashboard-heat-map-title"
          />
          {heatMapData && Object.keys(heatMapData).length > 0 ? (
            <>
              <GeoChart data={heatMapData} />
            </>
          ) : (
            renderNoDataContainer()
          )}
        </DashboardCard>
      </FlexContainer>
    </FlexContainer>
  );
};

export default DashboardRedemptionAnalytics;
