import styled, { css } from "styled-components/macro";
import { COLORS } from "../colors";

const Text = styled.span`
  margin: 0;

  ${({ type }) => {
    switch (type) {
      case "standard-h1":
        return css`
          font-size: 32px;
          font-weight: 700;
          letter-spacing: 3%;
          text-shadow: 0px 4px 4px 0px #00000040;
          font-family: "Inter";
          line-height: 39px;
        `;
      case "standard-h2":
        return css`
          font-size: 32px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 39px;
        `;
      case "standard-h3":
        return css`
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 28px;
        `;

      case "standard-h3-inter":
        return css`
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 28px;
        `;

      case "standard-h4":
        return css`
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 28px;
        `;
      case "standard-h5":
        return css`
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 24px;
        `;
      case "standard-h6":
        return css`
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 24px;
        `;
      case "standard-h8":
        return css`
          font-size: 22px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 28px;
        `;
      case "standard-h9":
        return css`
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 4%;
          font-family: "Rubik";
          line-height: 24px;
        `;
      case "standard-b1":
        return css`
          font-size: 16px;
          font-weight: 400;
          font-family: "Rubik";
          line-height: 19px;
        `;
      case "standard-b2":
        return css`
          font-size: 16px;
          font-weight: 700;
          font-family: "Rubik";
          line-height: 19px;
        `;
      case "standard-b3":
        return css`
          font-size: 12px;
          font-weight: 400;
          font-family: "Rubik";
          line-height: 14px;
          color: ${COLORS.primaryGrey};
        `;
      case "standard-b4":
        return css`
          font-size: 16px;
          font-weight: 700;
          font-family: "Rubik";
          line-height: 19px;
          color: ${COLORS.primaryGrey};
        `;

      case "mobile-h1":
        return css`
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 24.2px;
        `;
      case "mobile-h2":
        return css`
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 24.2px;
        `;
      case "mobile-h3":
        return css`
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 24px;
        `;
      case "mobile-h4":
        return css`
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 4%;
          font-family: "Inter";
          line-height: 15px;
        `;
      case "mobile-h5":
        return css`
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 4%;
          font-family: "Inter";
          line-height: 15px;
        `;
      case "mobile-h6":
        return css`
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 18px;
        `;
      case "mobile-h7":
        return css`
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 17px;
        `;
      case "mobile-b1":
        return css`
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 19px;
        `;
      case "mobile-b2":
        return css`
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Rubik";
          line-height: 19px;
        `;
      case "mobile-b3":
        return css`
          font-size: 12px;
          font-weight: 400;
          font-family: "Rubik";
          line-height: 14px;
        `;
      case "mobile-b4":
        return css`
          font-size: 12px;
          font-weight: 700;
          font-family: "Rubik";
          line-height: 14px;
        `;
      case "mobile-b5":
        return css`
          font-size: 11px;
          font-weight: 400;
          font-family: "Inter";
          line-height: 13px;
        `;
      case "public-h1":
        return css`
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 3%;
          font-family: "Public Sans", sans-serif;
          line-height: 19px;
          color: ${COLORS.primaryBlack};
        `;
      case "public-h1-bold":
        return css`
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 3%;
          font-family: "Public Sans", sans-serif;
          line-height: 19px;
          color: ${COLORS.primaryBlack};
        `;

      case "public-h1-small":
        return css`
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 3%;
          font-family: "Public Sans", sans-serif;
          line-height: 18px;
          color: ${COLORS.primaryBlack};
        `;

      case "public-h2":
        return css`
          font-size: 13px;
          font-weight: 500;
          font-family: "Inter";
          line-height: 16px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "rubik-h3":
        return css`
          font-size: 16px;
          font-weight: 500;
          font-family: "Rubik";
          line-height: 19px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "rubik-h4":
        return css`
          font-size: 20px;
          font-weight: 400;
          font-family: "Rubik";
          line-height: 24px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "rubik-h5":
        return css`
          font-size: 14px;
          font-weight: 400;
          font-family: "Rubik";
          line-height: 17px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "rubik-h6":
        return css`
          font-size: 20px;
          font-weight: 500;
          font-family: "Rubik";
          line-height: 24px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "inter-b1":
        return css`
          font-size: 16px;
          font-weight: 500;
          font-family: "Inter";
          line-height: 19px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "inter-b2":
        return css`
          font-size: 12px;
          font-weight: 500;
          font-family: "Inter";
          line-height: 15px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      case "roboto-b3":
        return css`
          margin: 0;
          font-size: 12px;
          font-weight: 400;
          font-family: "Roboto", sans-serif;
          line-height: 14px;
          letter-spacing: 0.04em;
          color: ${COLORS.primaryBlack};
        `;
      default:
        return css`
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 3%;
          font-family: "Inter";
          line-height: 24px;
        `;
    }
  }}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
  ${({ semiBold }) =>
    semiBold &&
    css`
      font-weight: 600;
    `}
  ${({ medium }) =>
    medium &&
    css`
      font-weight: 500;
    `}
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}
  ${({ blue }) =>
    blue &&
    css`
      color: ${COLORS.primaryBlue};
    `}

  ${({ green }) =>
    green &&
    css`
      color: ${COLORS.primaryGreen};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
`;

export default Text;
