import { makeAutoObservable } from "mobx";
import Category from "./category";

export class CategorySelectorStore {
  constructor() {
    this.categories = [];
    this.subcategories = [];
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initialize(firebase, selectedCategoryName) {
    this.categories = [];
    this.database = firebase.database;
    const categoriesSnapshot = (await this.database.ref("category/").once("value")).val();
    if (categoriesSnapshot) {
      Object.keys(categoriesSnapshot)
        .forEach(
          categoryId => {
            const category = categoriesSnapshot[categoryId];
            this.categories.push(
              new Category(this.database, categoryId, category.name, category.subcategories)
            );
          });

      if (selectedCategoryName) {
        this.updateSubCategories(selectedCategoryName);
      }
    };
  }

  updateSubCategories(categoryName) {
    const selectedCategory = this.categories.find((category => category.name === categoryName));

    if (selectedCategory) {
      this.subcategories = selectedCategory.subCategories;
    }
  }

  cleanUp() {
    this.categories = [];
    this.subcategories = [];
  }
}

export const categorySelectorStore = new CategorySelectorStore()