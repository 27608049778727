import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import { COLORS } from "../../assets/fansaves-ui/colors";
import { FlexContainer } from "../../assets/fansaves-ui";
import { GLOBALS } from "../../assets/fansaves-ui";

const ToggleWrapper = styled.div`
  box-sizing: border-box;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: transparent;
  color: ${COLORS.primaryBlack};
  width: 260px;
  gap: 15px;
  margin-bottom: ${(props) =>
    props.className.includes("all-deals") ? "0px" : props.show ? "40px" : "0"};
  cursor: pointer;
  .toggle-icon {
    margin-right: 10px;
    font-size: 30px;
  }
  .toggle-underline {
    width: ${({ show }) => (show ? "100%" : "92px")};
    height: 4px;
    background: ${COLORS.primaryGreen};
    border-radius: 5px;
    align-self: flex-start;
  }
  .circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid ${COLORS.primaryGreen};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
    padding-left: 0;
    margin-bottom: ${(props) =>
      props.className.includes("all-deals")
        ? "0px"
        : props.show
        ? "40px"
        : "0"};
  }
`;

const DashboardDealsListlToggle = ({ show, onShow, children, className }) => {
  return (
    <ToggleWrapper onClick={onShow} className={className} show={show}>
      <FlexContainer
        direction="horizontal"
        alignItems="center"
        justifyContent="flex-start"
      >
        {show ? (
          <Icon icon="mdi:minus" className="toggle-icon" />
        ) : (
          <Icon icon="mdi:plus" className="toggle-icon" />
        )}
        {children}
      </FlexContainer>
      <div className="toggle-underline" />
    </ToggleWrapper>
  );
};

export default DashboardDealsListlToggle;

DashboardDealsListlToggle.propTypes = {
  show: propTypes.bool,
  onShow: propTypes.func,
  children: propTypes.node,
  className: propTypes.string,
};
