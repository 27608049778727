import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import queryString from 'query-string';

const Logout = () => (
  <LogoutComponent />
);

class LogoutBase extends Component {
  constructor(props) {
    super(props);
    
    this.props.firebase.doSignOut().then(() => {
      const parsed = queryString.parse(window.location.search);
      if (parsed.toLogin) {
        this.props.history.push('/login');
      } else if (parsed.toLoginToPortal) {
        this.props.history.push('/login?toPortal=true');
      } else {
        this.props.history.push('/');
      }

    });
  }


  render() {
    return (
      <div className="app flex-row align-items-center">
      </div>
    );
  }
}

const LogoutComponent = compose(
  withRouter,
  withFirebase,
  )(LogoutBase);
  
export default Logout;

export { LogoutBase };