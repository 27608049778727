import algoliasearch from 'algoliasearch/lite';
import environment from '../../environment';

let searchClient;

if(environment.name === 'production') {
  searchClient = algoliasearch('PQZREHRAC5', '2615264427a091c36b6101121bf1027e');
} else if(environment.name === 'staging') {
  searchClient = algoliasearch('M6J5C1TXKI', 'dd29714e7fd8a06c6fb5b92b2758dd9d');
} else if(environment.name === 'dev') {
  searchClient = algoliasearch('Y3KICFA7UD', 'd9b30416a7af07d3fadacc3ebcdea441');
}

export default searchClient;