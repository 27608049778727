import React from "react";
import styled from "styled-components/macro";
import { Text } from "../../assets/fansaves-ui";
import { DefaultContainer } from "../../containers/UIKit";

const Spacer = styled.div`
  height: ${({ height }) => height || "40px"};
`;

const PaddedList = styled.ul`
  padding-left: 20px;
`;

const UpdatedLink = styled.a`
  word-break: break-all;
`;
const PrivacyPolicy = () => {
  return (
    <DefaultContainer>
      <div className="terms-and-conditions animated fadeIn">
        <Text type="standard-h1">Privacy Policy</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">Effective date: September 1, 2023</Text>
        </p>
        <p>
          <Text type="inter-b1">
            FanSaves Inc. ("us", "we", or "our") operates the FanSaves mobile
            application (hereinafter referred to as the "Service").
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our Service and the
            choices you have associated with that data.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms and Conditions.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Definitions</Text>
        <Spacer />
        <PaddedList>
          <li>
            <Text type="inter-b1" bold>
              Service
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Service is the FanSaves mobile application operated by FanSaves
              Inc.
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Personal Data
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Usage Data
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Cookies
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Cookies are small files stored on your device (computer or mobile
              device).
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Data Controller
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Data Controller means the natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal
              information are, or are to be, processed.
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              For the purpose of this Privacy Policy, we are a Data Controller
              of your Personal Data.
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Data Processors (or Service Providers)
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Data Processor (or Service Provider) means any natural or legal
              person who processes the data on behalf of the Data Controller.
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              We may use the services of various Service Providers in order to
              process your data more effectively.
            </Text>
          </li>
          <Spacer height="20px" />
          <li>
            <Text type="inter-b1" bold>
              Data Subject (or User)
            </Text>
            <Spacer height="10px" />
            <Text type="inter-b1">
              Data Subject is any living individual who is using our Service and
              is the subject of Personal Data.
            </Text>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">Information Collection and Use</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h5">Types of Data Collected</Text>
        <Spacer height="20px" />
        <Text type="inter-b1" bold>
          Personal Data
        </Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">Email address</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">First name and last name</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              Address, State, Province, ZIP/Postal code, City
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">Cookies and Usage Data</Text>
          </li>
        </PaddedList>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            Our partners will also receive access to this data when you follow a
            FanPage or redeem a deal. Our partners and our company may use your
            Personal Data to contact you with newsletters, marketing or
            promotional materials and other information that may be of interest
            to you. You may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or the
            instructions provided in any email we or our partners send.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="inter-b1" bold>
          Usage Data
        </Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            When you access the Service with a mobile device, we may collect
            certain information automatically, including, but not limited to,
            the type of mobile device you use, your mobile device unique ID, the
            IP address of your mobile device, your mobile operating system, the
            type of mobile Internet browser you use, unique device identifiers
            and other diagnostic data ("Usage Data").
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="inter-b1" bold>
          Location Data
        </Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            We may use and store information about your location if you give us
            permission to do so ("Location Data"). We use this data to provide
            features of our Service, to improve and customise our Service. You
            can enable or disable location services when you use our Service at
            any time by way of your device settings.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="inter-b1" bold>
          Tracking & Cookies Data
        </Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information. Cookies are
            files with a small amount of data which may include an anonymous
            unique identifier. Cookies are sent to your browser from a website
            and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information
            and to improve and analyse our Service. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service. Examples of Cookies we use:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">
              <b>Session Cookies.</b> We use Session Cookies to operate our
              Service.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>Preference Cookies.</b> We use Preference Cookies to remember
              your preferences and various settings.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>Security Cookies.</b> We use Security Cookies for security
              purposes.
            </Text>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">Use of Data</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            FanSaves Inc. uses the collected data for various purposes:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">To provide and maintain our Service</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To notify you about changes to our Service
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">To provide customer support</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To gather analysis or valuable information so that we can improve
              our Service
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">To monitor the usage of our Service</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To detect, prevent and address technical issues
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </Text>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">
          Legal Basis for Processing Personal Data under the General Data
          Protection Regulation (GDPR)
        </Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            If you are from the European Economic Area (EEA), FanSaves Inc.
            legal basis for collecting and using the personal information
            described in this Privacy Policy depends on the Personal Data we
            collect and the specific context in which we collect it. FanSaves
            Inc. may process your Personal Data because:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">We need to perform a contract with you</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">You have given us permission to do so</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              The processing is in our legitimate interests and it is not
              overridden by your rights
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">To comply with the law</Text>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">Retention of Data</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            FanSaves Inc. will retain your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes
            and enforce our legal agreements and policies. FanSaves Inc. will
            also retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer periods.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Transfer of Data</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            Your information, including Personal Data, may be transferred to -
            and maintained on - computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </Text>
          <Spacer height="10px" />
          <Text type="inter-b1">
            If you are located outside and choose to provide information to us,
            please note that we transfer the data, including Personal Data, to
            and process it there.
          </Text>
          <Spacer height="10px" />
          <Text type="inter-b1">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </Text>
          <Spacer height="10px" />
          <Text type="inter-b1">
            FanSaves Inc. will take all the steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your Personal Data will take place
            to an organisation or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Disclosure of Data</Text>
        <Spacer />
        <Text type="inter-b1" bold>
          Business Transaction
        </Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            If FanSaves Inc. is involved in a merger, acquisition or asset sale,
            your Personal Data may be transferred. We will provide notice before
            your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Disclosure for Law Enforcement</Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            Under certain circumstances, FanSaves Inc. may be required to
            disclose your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Legal Requirements</Text>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            FanSaves Inc. may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">To comply with a legal obligation</Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To protect and defend the rights or property of FanSaves Inc.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              To protect the personal safety of users of the Service or the
              public
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">To protect against legal liability</Text>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">Security of Data</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">
          Our Policy on "Do Not Track" Signals under the California Online
          Protection Act (CalOPPA)
        </Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We do not support Do Not Track ("DNT"). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked.
          </Text>
        </p>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">
          Your Data Protection Rights under the General Data Protection
          Regulation (GDPR)
        </Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. FanSaves Inc. aims to take
            reasonable steps to allow you to correct, amend, delete or limit the
            use of your Personal Data.
          </Text>
        </p>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please
            contact us.
          </Text>
        </p>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            In certain circumstances, you have the following data protection
            rights:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">
              <b>
                The right to access, update or delete the information we have on
                you.
              </b>{" "}
              Whenever made possible, you can access, update or request deletion
              of your Personal Data directly within your account settings
              section. If you are unable to perform these actions yourself,
              please contact us to assist you.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>The right of rectification.</b> You have the right to have your
              information rectified if that information is inaccurate or
              incomplete.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>The right to object.</b> You have the right to object to our
              processing of your Personal Data.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>The right of restriction.</b> You have the right to request
              that we restrict the processing of your personal information.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>The right to data portability.</b> You have the right to be
              provided with a copy of the information we have on you in a
              structured, machine-readable and commonly used format.
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>The right to withdraw consent.</b> You also have the right to
              withdraw your consent at any time where FanSaves Inc. relied on
              your consent to process your personal information.
            </Text>
          </li>
        </PaddedList>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            Please note that we may ask you to verify your identity before
            responding to such requests.
          </Text>
        </p>
        <Spacer height="10px" />
        <p>
          <Text type="inter-b1">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Service Providers</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide the Service on our
            behalf, perform Service-related services or assist us in analysing
            how our Service is used. These third parties have access to your
            Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Analytics</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We may use third-party Service Providers to monitor and analyse the
            use of our Service.
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">
              <b>Google Analytics</b>
            </Text>
            <Spacer height="10px" />
            <p>
              <Text type="inter-b1">
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network.
              </Text>
            </p>
            <Spacer height="10px" />
            <p>
              <Text type="inter-b1">
                You may opt-out of certain Google Analytics features through
                your mobile device settings, such as your device advertising
                settings or by following the instructions provided by Google in
                their Privacy Policy:{" "}
                <UpdatedLink
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </UpdatedLink>
              </Text>
            </p>
            <Spacer height="10px" />
            <p>
              <Text type="inter-b1">
                For more information on the privacy practices of Google, please
                visit the Google Privacy & Terms web page:{" "}
                <UpdatedLink
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </UpdatedLink>
              </Text>
            </p>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              <b>Firebase</b>
            </Text>
            <Spacer height="10px" />
            <p>
              <Text type="inter-b1">
                Firebase is an analytics service provided by Google Inc.
              </Text>
              <Spacer height="10px" />
              <Text type="inter-b1">
                You may opt-out of certain Firebase features through your mobile
                device settings, such as your device advertising settings or by
                following the instructions provided by Google in their Privacy
                Policy:{" "}
                <UpdatedLink
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </UpdatedLink>
              </Text>
              <Spacer height="10px" />
              <Text type="inter-b1">
                We also encourage you to review the Google's policy for
                safeguarding your data:{" "}
                <UpdatedLink
                  href="https://support.google.com/analytics/answer/6004245"
                  target="_blank"
                >
                  https://support.google.com/analytics/answer/6004245
                </UpdatedLink>
              </Text>
              <Spacer height="10px" />
              <Text type="inter-b1">
                For more information on what type of information Firebase
                collects, please visit the Google Privacy & Terms web page:{" "}
                <UpdatedLink
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </UpdatedLink>
              </Text>
            </p>
          </li>
        </PaddedList>
        <Spacer height="20px" />
        <Text type="standard-h3">Links to Other Sites</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party's site. We strongly advise you to review the Privacy
            Policy of every site you visit. We have no control over and assume
            no responsibility for the content, privacy policies or practices of
            any third party sites or services.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Children's Privacy</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            Our Service does not address anyone under the age of 18
            ("Children").
          </Text>
          <Spacer height="10px" />
          <Text type="inter-b1">
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Changes to This Privacy Policy</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            "effective date" at the top of this Privacy Policy.
          </Text>
          <Spacer height="10px" />
          <Text type="inter-b1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Contact Us</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            If you have any questions about this Privacy Policy, please contact
            us:
          </Text>
        </p>
        <Spacer height="10px" />
        <PaddedList>
          <li>
            <Text type="inter-b1">
              By email:{" "}
              <a
                href="mailto:info@fansaves.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@fansaves.com
              </a>
            </Text>
          </li>
          <Spacer height="10px" />
          <li>
            <Text type="inter-b1">
              By visiting this page on our website:{" "}
              <a href="/">https://fansaves.com</a>
            </Text>
          </li>
        </PaddedList>
        <Spacer />
      </div>
    </DefaultContainer>
  );
};

export default PrivacyPolicy;
