import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../../containers/Firebase";
import { withAuthentication } from "../../containers/Session";
import LoginForm from "../../containers/LoginForm";
import queryString from "query-string";
import { isAdmin, isSponsor, isTeam } from "../../containers/Session";

const Login = () => <LoginComponent />;

class LoginBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }

  componentDidMount() {
    if (this.props.firebase.auth.currentUser) {
      this.setState({ loggedIn: true });
    }
  }

  handleSuccessfulLogin = () => {
    this.setState({ loggedIn: true });
  };

  handleRedirectToPortal = () => {
    const { authUser: user } = this.props;
    if (isAdmin(user)) {
      return <Redirect to="/portal/dashboard" />;
    } else if (isSponsor(user)) {
      return <Redirect to={`/portal/sponsors/${user.sponsorID}/dashboard`} />;
    } else if (isTeam(user)) {
      return <Redirect to={`/portal/teams/${user.teamID}/dashboard`} />;
    }
  };

  render() {
    const { loggedIn } = this.state;

    const parsed = queryString.parse(window.location.search);

    return (
      <div className="views-login app flex-row align-items-center">
        {loggedIn ? (
          parsed.toPortal ? (
            this.handleRedirectToPortal()
          ) : (
            <Redirect to="/feed" />
          )
        ) : (
          <LoginForm onSuccessfulLogin={this.handleSuccessfulLogin} />
        )}
      </div>
    );
  }
}

const LoginComponent = compose(
  withRouter,
  withFirebase,
  withAuthentication
)(LoginBase);

export default Login;

export { LoginBase };
