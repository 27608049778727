import React from "react";
import { Redirect } from "react-router-dom";
import { withAuthentication } from "../Session";
import useOnboardingStore from "../../stores/onboardingStore";

export const CheckOnboarding = (props) => {
  const { authUser } = props;
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep);

  if (authUser && authUser.onboarding) {
    setCurrentStep(1);
    return <Redirect to="/onboarding?step=1" />;
  }

  return <>{props.children}</>;
};

export default withAuthentication(CheckOnboarding);
