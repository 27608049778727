import React from 'react';
import './PortableSearchBar.scss';

class PortableSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerms: props.searchTerms || '',
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSearchClick = (e) => {
    e.preventDefault();
    const { onSearch } = this.props;
    const { searchTerms } = this.state;

    onSearch(searchTerms);
  }

  handleKeyDown = (e) => {
    if(e.keyCode === 13) {
      const { onSearch } = this.props;
      const { searchTerms } = this.state;
      onSearch(searchTerms);
    }
  }



  render() {
    const { placeholder } = this.props;
    const {
      searchTerms,
    } = this.state;

    return(
      <div className='component-portable-search-bar'>
        <input 
          type='text'
          className='search-terms'
          placeholder={placeholder || 'Search for a FanPage'}
          name='searchTerms'
          value={searchTerms}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
        />
        <a href='#' className='search-button' onClick={this.handleSearchClick}>
          <i className='icon-magnifier' />
        </a>
      </div>
    );
  }
}

export default PortableSearchBar;