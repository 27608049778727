const GetStarted = {
  name: "get-started",
  publicName: "Get Started",
  frenchName: "Commencer",
  url: "/get-started",
  icon: "bi:check-circle-fill",
};

const Dashboard = {
  name: "dashboard",
  publicName: "Dashboard",
  frenchName: "Tableau de bord",
  url: "/dashboard",
  icon: "fa-solid:home",
};

const Organizations = {
  name: "teams",
  publicName: "Organizations",
  frenchName: "Équipes",
  url: "/teams",
  icon: "ion:american-football",
};

const Sponsors = {
  name: "sponsors",
  publicName: "Businesses",
  frenchName: "Entreprises",
  url: "/sponsors",
  icon: "fa-solid:briefcase",
};

const Brands = {
  name: "brands",
  publicName: "Brands",
  frenchName: "Marques",
  url: "/brands",
  icon: "ic:baseline-format-list-bulleted",
};

const Reports = {
  name: "reports",
  publicName: "Reports",
  frenchName: "Rapports",
  url: "/reports",
  icon: "fa-solid:chart-bar",
};

const Training = {
  name: "training",
  publicName: "Training",
  frenchName: "Formation",
  url: "/training",
  icon: "fa-solid:book-open",
};

const PendingApproval = {
  name: "pending-approval",
  publicName: "Pending Approval",
  frenchName: "En attente d'approbation",
  url: "/pending-approval",
  icon: "bi:check-circle-fill",
};

const AdminSettings = {
  name: "admin-settings",
  publicName: "Admin Settings",
  frenchName: "Paramètres d'administration",
  url: "/admin-settings",
  icon: "mdi:cog",
};

const MainSite = {
  name: "main-site",
  publicName: "Main Site",
  frenchName: "Site principal",
  url: "/",
  icon: "fa-solid:globe",
};

const Logout = {
  name: "logout",
  publicName: "Logout",
  frenchName: "Déconnexion",
  url: "/logout",
  icon: "fa-solid:file-export",
};

const adminNavigationMenu = {
  items: [
    Dashboard,
    Organizations,
    Sponsors,
    Brands,
    Reports,
    AdminSettings,
    MainSite,
    Logout,
  ],
};

const sponsorNavigationMenu = {
  items: [Dashboard, MainSite, Logout],
};

const teamNavigationMenu = {
  items: [Dashboard, Sponsors, MainSite, Logout],
};

export { adminNavigationMenu, sponsorNavigationMenu, teamNavigationMenu };
