import {loadStripe} from '@stripe/stripe-js';
import environment from '../../environment';

let stripePublicKey;
if(environment.name === 'production') {
  stripePublicKey = 'pk_live_u5XVTbLPVf9glMtcXlqjMDYt008lWWT7oR';
} else if(environment.name === 'staging') {
  stripePublicKey = 'pk_test_g1hxSyrYeognLGyLHwrexdf700lR2f0hy9';
} else if(environment.name === 'dev') {
  stripePublicKey = 'pk_test_g1hxSyrYeognLGyLHwrexdf700lR2f0hy9';
}

export default loadStripe(stripePublicKey);
