import React, { useState, useEffect } from "react";
import Select from "react-select";
import useLanguageStore from "../../stores/useLanguageStore";
import {
  genderChoicesOptions,
  genderChoicesOptionsFrench,
} from "../../containers/constants/GenderConstats";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { Label } from "reactstrap";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Button } from "../../containers/UIKit";
import { Icon } from "@iconify/react";

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const EditIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
`;

const EditIconContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: #cfe6ff;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    background-color: #b3d6ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  gap: 10px;
  margin-top: 10px;
`;

const StyledButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const GenderEdit = ({ authUser, firebase }) => {
  const language = useLanguageStore((state) => state.language);
  const [editMode, setEditMode] = useState(false);
  const [gender, setGender] = useState(authUser.gender || "");
  const [genderOptions, setGenderOptions] = useState(genderChoicesOptions);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (language === "fr") {
      setGenderOptions(genderChoicesOptionsFrench);
    } else {
      setGenderOptions(genderChoicesOptions);
    }
  }, [language]);

  const handleUserInput = (selectedOption, action) => {
    const value = selectedOption ? selectedOption.value : "";
    setGender(value);
    setError("");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!gender) {
      setError("Gender is required");
      return;
    }

    try {
      setSubmitting(true);
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      await userRef.update({ gender });
      setSubmitting(false);
      setEditMode(false);
      setError("");
    } catch (error) {
      setSubmitting(false);
      setError("Error updating gender. Please try again.");
      console.error("Error updating gender", error);
    }
  };

  const handleCancel = () => {
    setGender(authUser.gender || "");
    setEditMode(false);
    setError("");
  };

  return (
    <div className="form-section">
      <StyledLabel>Gender</StyledLabel>
      <InputContainer>
        <div style={{ width: "100%" }}>
          <Select
            name="gender"
            value={genderOptions.find((option) => option.value === gender)}
            onChange={handleUserInput}
            options={genderOptions}
            isSearchable
            isDisabled={!editMode}
            styles={{
              control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                borderWidth: "1px",
                borderColor: error ? COLORS.errorRed : COLORS.primaryBlue,
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Manrope, sans-serif",
                color: COLORS.primaryBlack,
                width: "100%",
              }),
              singleValue: (styles, { isDisabled }) => ({
                ...styles,
                color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
              }),
            }}
          />
          {error && <ErrorMessage error={error} />}
        </div>
        <EditIconContainer onClick={() => setEditMode(!editMode)}>
          <EditIcon icon="fa-solid:pencil-alt" width={17} />
        </EditIconContainer>
      </InputContainer>
      {editMode && (
        <ButtonContainer>
          <Button
            className="green-outline-continue rounded-10 medium"
            onClick={handleCancel}
            disabled={submitting}
          >
            <StyledButtonTitle>Cancel</StyledButtonTitle>
          </Button>
          <Button
            className={`green-continue rounded-10 medium ${
              (submitting || !gender) && "disabled"
            }`}
            onClick={handleUpdate}
            disabled={submitting}
          >
            <StyledButtonTitle>Save</StyledButtonTitle>
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

export default withFirebase(withAuthentication(GenderEdit));
