import React, { useEffect, useCallback } from "react";
import { Highlight, connectRefinementList } from "react-instantsearch-dom";

import "./DealTypeRefinementList.scss";

function RefinementList({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  currentRefinement,
}) {
  let publicRefinementItem;
  let exclusiveRefinementItem;

  items.forEach((item) => {
    if (item.label === "false") {
      publicRefinementItem = item;
    } else if (item.label === "true") {
      exclusiveRefinementItem = item;
    }
  });

  const setUrlonDealTypeUpdate = useCallback((dealType) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("tab", "deals");
    urlParams.set("deal_type", dealType);
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${urlParams.toString()}`
    );
  }, []);

  useEffect(() => {
    if (currentRefinement.includes("false")) {
      setUrlonDealTypeUpdate("public");
    } else if (currentRefinement.includes("true")) {
      setUrlonDealTypeUpdate("exclusive");
    }
  }, [currentRefinement, setUrlonDealTypeUpdate]);

  return (
    <div className="component-deal-type-refinement-list tab-headers level-2-headers">
      <a
        href="#"
        className={`tab-header ${
          currentRefinement.includes("false") ? "selected" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          refine("false");
        }}
      >
        Public Deals (
        {(publicRefinementItem && publicRefinementItem.count) || 0})
      </a>
      <a
        href="#"
        className={`tab-header ${
          currentRefinement.includes("true") ? "selected" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          refine("true");
        }}
      >
        Exclusive Deals (
        {(exclusiveRefinementItem && exclusiveRefinementItem.count) || 0})
      </a>
    </div>
  );
}

const DealTypeRefinementList = connectRefinementList(RefinementList);

export default DealTypeRefinementList;
