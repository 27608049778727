import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components/macro";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

import { withFirebase } from "../../../../containers/Firebase";
import TeamForm from "../Form";
import { uploadResourceImages } from "../../utilities/FormHelpers";
import { inputStyle } from "../../utilities/PortalFormStyles";
import {
  removeUndefineFieldFromObject,
  removeDuplicateElementInArray,
} from "../../../../utilities/core";

const StyledContainer = styled.div`
  margin-top: 40px;
`;

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: null,
      sortedSponsors: [],
      sponsors: [],
      accountFormShow: true,
      teamFormShow: false,
      teamLinkFormShow: false,
      newUserUuid: "",
      newUserEmail: "",
      newUserPassword: "",
      newUserPasswordConfirm: "",
      selectedTeamOption: "",
      sortedTeams: [],
      categories: null,
      subcategories: null,
    };
  }

  componentDidMount() {
    var sponsorRef = this.props.firebase.database.ref("sponsor");
    sponsorRef.once("value").then((dataSnapshot) => {
      var sponsors = [];
      dataSnapshot.forEach((sponsor) => {
        let name = sponsor.val().name;
        sponsors.push({ value: sponsor.key, label: name });
      });
      const sortedSponsors = sponsors.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setState({ sortedSponsors });
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const files = e.target.files;
    if (files) {
      let fileName = name + "File";
      let filePath = name + "Path";
      let file = files[0];
      this.setState({ [fileName]: file });
      this.setState({ [filePath]: URL.createObjectURL(file) });
    } else {
      if (name === "slug") {
        value = value.toLowerCase().replace(/\s/g, "");
      }

      this.setState({ [name]: value });
    }
  };

  handleSelectChange = (selectedOption) => {
    var sponsorKeys = [];
    selectedOption.forEach((option) => {
      sponsorKeys.push(option.value);
    });
    this.setState({ sponsors: sponsorKeys });
  };

  handleTeamSelectChange = (selectedTeamOption) => {
    this.setState({ selectedTeamOption });
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  handleSubmit = (teamData) => {
    var teamRef = this.props.firebase.database.ref("team").push();
    var teamKey = teamRef.key;

    uploadResourceImages(
      this.props.firebase,
      teamKey,
      teamData,
      "images/teams",
      ["emblem", "logo", "teamPhoto"],
      this.pushTeamData
    );
  };

  pushTeamData = (teamKey, teamData, imageUrls) => {
    const teamRef = this.props.firebase.database.ref(`/team/${teamKey}`);

    if (
      this.state.emblemFile &&
      (!this.state.emblemURL || this.state.emblemURL.length === 0)
    ) {
      return;
    }

    if (imageUrls.logoUrl && imageUrls.teamPhotoUrl) {
      if (this.state.newUserUuid && this.state.newUserUuid.length > 0) {
        const dataSet = {
          name: teamData.title,
          "short name": teamData.shortName,
          description: teamData.description,
          slug: teamData.slug,
          sport: teamData.sport,
          league: teamData.league,
          website: teamData.teamWebsite,
          division: teamData.division,
          "buy tickets link": teamData.buyTicketsLink,
          "app iOS": teamData.teamAppLinkiOS,
          "app android": teamData.teamAppLinkAndroid,
          "social fb": teamData.facebook,
          "social instagram": teamData.instagram,
          "social twitter": teamData.twitter,
          "social snapchat": teamData.snapchat,
          dashUrl: teamData.dashUrl,
          "arena name": teamData.arenaName,
          "arena address": teamData.arenaAddress,
          city: teamData.city,
          lat: teamData.lat ? parseFloat(teamData.lat) : teamData.lat,
          long: teamData.long ? parseFloat(teamData.long) : teamData.long,
          sponsors: teamData.teamSponsors,
          email: this.state.newUserEmail,
          newsRssFeed: teamData.newsRssFeed,
          keywords: teamData.keywords,
          categories: removeDuplicateElementInArray(teamData.categories),
          subcategories: removeDuplicateElementInArray(teamData.subcategories),
        };

        if (imageUrls.emblemUrl) {
          dataSet.emblem = imageUrls.emblemUrl;
        }
        if (imageUrls.logoUrl) {
          dataSet.logo = imageUrls.logoUrl;
        }
        if (imageUrls.teamPhotoUrl) {
          dataSet.picture = imageUrls.teamPhotoUrl;
        }

        const teamKey = teamRef.key;
        const userPath = "user/" + this.state.newUserUuid;
        const userRef = this.props.firebase.database.ref(userPath);
        const userDataSet = { teamID: teamKey, role: "team" };

        userRef
          .set(userDataSet)
          .then(() => {
            // Set team data
            return teamRef.set(removeUndefineFieldFromObject(dataSet));
          })
          .then(() => {
            // Set teams property on sponsors
            const sponsorUpdatePromises = [];
            teamData.teamSponsors.forEach((sponsorKey) => {
              const sponsorTeamsRef = this.props.firebase.database.ref(
                `/sponsor/${sponsorKey}/teams/${teamKey}`
              );
              sponsorUpdatePromises.push(
                sponsorTeamsRef.set({ key: teamKey, name: teamData.title })
              );
            });

            return Promise.all(sponsorUpdatePromises);
          })
          .then(() => {
            this.props.history.push("/portal/teams");
          });
      }
    } else {
      window.alert("You must upload a Logo and Team Photo.");
    }
  };

  handleAccountCreateSubmit = (event) => {
    event.preventDefault();

    if (!this.state.newUserEmail || this.state.newUserEmail.length < 1) {
      return;
    }
    if (!this.state.newUserPassword || this.state.newUserPassword.length < 1) {
      return;
    }
    if (
      !this.state.newUserPasswordConfirm ||
      this.state.newUserPasswordConfirm.length < 1
    ) {
      return;
    }

    this.props.firebase.auth2
      .createUserWithEmailAndPassword(
        this.state.newUserEmail,
        this.state.newUserPassword
      )
      .then((dataSnapshot) => {
        // this should really be teamCreate instead
        this.setState({
          accountFormShow: false,
          teamFormShow: true,
          newUserUuid: dataSnapshot.user.uid,
        });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
      });
  };

  handleTeamLink = (event) => {
    event.preventDefault();

    if (!this.state.newUserEmail || this.state.newUserEmail.length < 1) {
      return;
    }
    if (!this.state.newUserPassword || this.state.newUserPassword.length < 1) {
      return;
    }
    if (
      !this.state.newUserPasswordConfirm ||
      this.state.newUserPasswordConfirm.length < 1
    ) {
      return;
    }

    var teamRef = this.props.firebase.database.ref("team");
    teamRef.once("value").then((dataSnapshot) => {
      var teams = [];
      dataSnapshot.forEach((team) => {
        let name = team.val().name;
        teams.push({ value: team.key, label: name });
      });
      const sortedTeams = teams.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setState({ sortedTeams }, () => this.showTeamLinkForm());
    });

    this.props.firebase.auth2
      .createUserWithEmailAndPassword(
        this.state.newUserEmail,
        this.state.newUserPassword
      )
      .then((dataSnapshot) => {
        this.setState(
          {
            newUserUuid: dataSnapshot.user.uid,
          },
          () => this.showTeamLinkForm()
        );
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
  };

  showTeamLinkForm = () => {
    if (
      this.state.newUserUuid.length > 0 &&
      this.state.sortedSponsors.length > 0
    ) {
      this.setState({
        teamLinkFormShow: true,
        accountFormShow: false,
      });
    }
  };

  handleTeamLinkSubmit = (event) => {
    event.preventDefault();

    if (!this.state.newUserUuid || this.state.newUserUuid.length < 1) {
      return;
    }
    if (
      !this.state.selectedTeamOption ||
      this.state.selectedTeamOption.length < 1
    ) {
      return;
    }

    let teamID = this.state.selectedTeamOption.value;
    var userPath = "user/" + this.state.newUserUuid;
    var userRef = this.props.firebase.database.ref(userPath);
    var payload = { teamID, role: "team" };

    userRef.set(payload).then(() => {
      this.props.history.push("/portal/teams");
    });

    var teamEmailPath = "team/" + teamID + "/email";
    var teamEmailRef = this.props.firebase.database.ref(teamEmailPath);
    teamEmailRef.set(this.state.newUserEmail);
  };

  render() {
    const { selectedTeamOption } = this.state;
    return (
      <StyledContainer
        className="component-create-team animated fadeIn"
        id="main"
      >
        {this.state.accountFormShow && (
          <Form className="row" onSubmit={this.handleAccountCreateSubmit}>
            <div className="col-12 text-center mb-3">
              <h3>Create Organization Account</h3>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>EMAIL</Label>
                <Input
                  name="newUserEmail"
                  value={this.state.newUserEmail}
                  onChange={this.handleUserInput}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>PASSWORD</Label>
                <Input
                  name="newUserPassword"
                  value={this.state.newUserPassword}
                  type="password"
                  onChange={this.handleUserInput}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>PASSWORD CONFIRM</Label>
                <Input
                  name="newUserPasswordConfirm"
                  value={this.state.newUserPasswordConfirm}
                  type="password"
                  onChange={this.handleUserInput}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Button name="link-existing" onClick={this.handleTeamLink}>
                Submit and Link to Existing Organization
              </Button>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Button
                type="submit"
                value="Submit and Create New Organization"
                name="create"
              >
                Submit and Create New Organization
              </Button>
            </div>
          </Form>
        )}

        {this.state.teamLinkFormShow && (
          <Form className="row" onSubmit={this.handleTeamLinkSubmit}>
            <div className="col-12 mb-4">
              <Label>SELECT ORGANIZATIONS</Label>
              <Select
                value={selectedTeamOption}
                onChange={this.handleTeamSelectChange}
                options={this.state.sortedTeams}
                isMulti={false}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? "#464646" : "#b3b3b3",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            </div>
            <Input type="submit" value="Submit" style={inputStyle} />
          </Form>
        )}

        {this.state.teamFormShow && <TeamForm onSubmit={this.handleSubmit} />}
      </StyledContainer>
    );
  }
}

export default withFirebase(Create);
