import React from "react";
import LoginForm from "../LoginForm";
import "./LoginModal.scss";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

function LoginModal({ mode, onClose, returnToCurrentPageAfterOnboarding }) {
  function handleSuccessfulLogin() {
    if (returnToCurrentPageAfterOnboarding) {
      const searchParams = new URLSearchParams(window.location.search);
      const tab = searchParams.get("tab");
      if (tab === "specialDeals") {
        window.sessionStorage.setItem(
          "redirect_path_after_onboarding",
          window.location.pathname + "?tab=specialDeals"
        );
      } else {
        window.sessionStorage.setItem(
          "redirect_path_after_onboarding",
          window.location.pathname
        );
      }
    }
    onClose();
  }

  function handleCloseClick(e) {
    e.preventDefault();
    onClose();
  }

  return (
    <div className="component-login-modal">
      <Container className="close-modal-container">
        <Row className="justify-content-center">
          <Col xl="8" lg="9" md="8">
            <div className="close-modal-icon-container">
              <i className="icon-close" onClick={handleCloseClick} />
            </div>
          </Col>
        </Row>
      </Container>
      <LoginForm onSuccessfulLogin={handleSuccessfulLogin} mode={mode} />
    </div>
  );
}

LoginModal.propTypes = {
  mode: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  returnToCurrentPageAfterOnboarding: PropTypes.bool,
};

export default LoginModal;
