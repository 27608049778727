import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Form as ReactStrapForm, FormGroup, Label, Input } from "reactstrap";
import ImageUploader from "../../../../containers/ImageUploader";
import {
  dbLoadTeams,
  dbLoadTeamSponsors,
} from "../../../../containers/DatabaseHelpers/DatabaseHelpers";
import { withFirebase } from "../../../../containers/Firebase";
import {
  imageUploadInputStyle,
  imageStyle,
  labelStyle,
} from "../../utilities/PortalFormStyles";
import { SelectableList } from "../../../../containers/UIKit";
import CategorySelector from "../../../../containers/CategorySelector";
import { isBlank } from "../../../../utilities/core";

function Form({ firebase, existingBrand, onSubmit }) {
  const brandTypeOptions = [
    { value: "team", label: "Organization Brand" },
    { value: "sponsor", label: "Business Brand" },
  ];

  const [selectedBrandTypeOption, setSelectedBrandTypeOption] = useState(
    existingBrand
      ? { value: existingBrand.brandType }
      : {
          value: undefined,
        }
  );

  /* Common Fields */
  const [name, setName] = useState(existingBrand ? existingBrand.name : "");

  const [logoFile, setLogoFile] = useState();
  const [logoPath, setLogoPath] = useState(
    existingBrand ? existingBrand.logo : ""
  );

  const [loadingTeamOptions, setLoadingTeamOptions] = useState(false);
  const [teamOptions, setTeamOptions] = useState([]);

  /* Team Brand Specific Fields */
  const [selectedCategories, setSelectedCategories] = useState(
    existingBrand && existingBrand.categories ? existingBrand.categories : []
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState(
    existingBrand && existingBrand.subcategories
      ? existingBrand.subcategories
      : []
  );

  const [pictureFile, setPictureFile] = useState();
  const [picturePath, setPicturePath] = useState(
    existingBrand ? existingBrand.picture : ""
  );

  const [selectedTeamKeys, setSelectedTeamKeys] = useState(
    existingBrand && existingBrand.teamKeys ? existingBrand.teamKeys : []
  );

  /* Sponsor Brand Specific Fields */
  const [selectedTeamOption, setSelectedTeamOption] = useState();
  const [sponsors, setSponsors] = useState([]);
  const [selectedSponsorKeys, setSelectedSponsorKeys] = useState(
    existingBrand && existingBrand.sponsorKeys ? existingBrand.sponsorKeys : []
  );

  const firstSponsorsLoad = useRef(true);
  const logoInput = useRef();
  const pictureInput = useRef();

  function validateForm() {
    if (isBlank(name)) {
      window.alert("Please specify a name.");
      return false;
    }

    if (selectedBrandTypeOption.value === "team") {
      // Add any Team Brand validations.
    } else {
      if (!selectedTeamOption) {
        window.alert("Please select an organization.");
        return false;
      }
    }
    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (validateForm()) {
      let brandData = {};
      if (selectedBrandTypeOption.value === "team") {
        brandData = {
          brandType: selectedBrandTypeOption.value,
          name,
          logoFile,
          logoPath,
          pictureFile,
          picturePath,
          categories: selectedCategories,
          subcategories: selectedSubcategories,
          teamKeys: selectedTeamKeys,
        };
      } else {
        brandData = {
          brandType: selectedBrandTypeOption.value,
          name,
          logoFile,
          logoPath,
          teamKey: selectedTeamOption.value,
          sponsorKeys: selectedSponsorKeys,
        };
      }
      onSubmit(brandData);
    }
  }

  function logoClick() {
    logoInput.current.click();
  }

  function pictureClick() {
    pictureInput.current.click();
  }

  function logoInputChange(e) {
    if (e.target.files) {
      const file = e.target.files[0];
      setLogoFile(file);
      setLogoPath(URL.createObjectURL(file));
    }
  }

  function pictureInputChange(e) {
    if (e.target.files) {
      const file = e.target.files[0];
      setPictureFile(file);
      setPicturePath(URL.createObjectURL(file));
    }
  }

  function loadTeams() {
    setLoadingTeamOptions(true);
    dbLoadTeams(firebase, (teams) => {
      setLoadingTeamOptions(false);
      setTeamOptions(
        teams.map((team) => ({ value: team.key, label: team.name }))
      );
    });
  }

  function handleTeamToggle(teamKey) {
    if (selectedTeamKeys.includes(teamKey)) {
      setSelectedTeamKeys(selectedTeamKeys.filter((key) => key !== teamKey));
    } else {
      setSelectedTeamKeys([...selectedTeamKeys, teamKey]);
    }
  }

  function handleSponsorToggle(sponsorKey) {
    if (selectedSponsorKeys.includes(sponsorKey)) {
      setSelectedSponsorKeys(
        selectedSponsorKeys.filter((key) => key !== sponsorKey)
      );
    } else {
      setSelectedSponsorKeys([...selectedSponsorKeys, sponsorKey]);
    }
  }

  useEffect(loadTeams, []);

  useEffect(() => {
    if (teamOptions.length > 0 && existingBrand) {
      setSelectedTeamOption(
        teamOptions.find(
          (teamOption) => teamOption.value === existingBrand.teamKey
        )
      );
    }
  }, [teamOptions]);

  useEffect(() => {
    if (selectedTeamOption) {
      dbLoadTeamSponsors(firebase, selectedTeamOption.value, (results) => {
        if (firstSponsorsLoad.current) {
          firstSponsorsLoad.current = false;
        } else {
          setSelectedSponsorKeys([]);
        }
        setSponsors(results);
      });
    }
  }, [selectedTeamOption]);

  function renderTeamBrandForm() {
    return (
      <>
        <div className="row" style={{ margin: "24px 0" }}>
          <div className="col-6">
            <FormGroup className="text-center">
              <Label style={labelStyle}>
                <strong>LOGO</strong>
              </Label>
              <ImageUploader
                style={imageStyle}
                src={logoPath}
                onClick={logoClick}
                alt="Logo"
              />
              <Input
                style={imageUploadInputStyle}
                name="logo"
                onChange={logoInputChange}
                innerRef={logoInput}
                type="file"
              />
            </FormGroup>
          </div>
          <div className="col-6">
            <FormGroup className="text-center">
              <Label style={labelStyle}>
                <strong>TEAM PHOTO</strong>
              </Label>
              <ImageUploader
                style={imageStyle}
                src={picturePath}
                onClick={pictureClick}
                alt="Team"
              />
              <Input
                style={imageUploadInputStyle}
                name="picture"
                onChange={pictureInputChange}
                innerRef={pictureInput}
                type="file"
              />
            </FormGroup>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <h2>Basic Info</h2>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Input>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Label>Category/Subcategory</Label>
                <CategorySelector
                  categories={selectedCategories}
                  subcategories={selectedSubcategories}
                  categoriesOnUpdate={(categoryNameArray) =>
                    setSelectedCategories(categoryNameArray)
                  }
                  subcategoriesOnUpdate={(subCategoryNameArray) =>
                    setSelectedSubcategories(subCategoryNameArray)
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <h2>Organizations</h2>
              {loadingTeamOptions && "Loading..."}
              {teamOptions && (
                <SelectableList
                  items={teamOptions}
                  displayField="label"
                  valueField="value"
                  selectedValues={selectedTeamKeys}
                  onItemToggle={handleTeamToggle}
                />
              )}
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <Input type="submit" value="Submit" />
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderSponsorBrandForm() {
    return (
      <>
        <div className="row" style={{ margin: "24px 0" }}>
          <div className="col-4 offset-4">
            <FormGroup className="text-center">
              <Label style={labelStyle}>
                <strong>LOGO</strong>
              </Label>
              <ImageUploader
                style={imageStyle}
                src={logoPath}
                onClick={logoClick}
                alt="Logo"
              />
              <Input
                style={imageUploadInputStyle}
                name="logo"
                onChange={logoInputChange}
                type="file"
                innerRef={logoInput}
              />
            </FormGroup>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <h2>Basic Info</h2>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Input>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <FormGroup>
                <Label>Organization</Label>
                <Select
                  value={selectedTeamOption}
                  onChange={(option) => {
                    setSelectedTeamOption(option);
                  }}
                  options={teamOptions}
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <h2>Businesses</h2>
              {sponsors && (
                <SelectableList
                  items={sponsors}
                  displayField="name"
                  valueField="key"
                  selectedValues={selectedSponsorKeys}
                  onItemToggle={handleSponsorToggle}
                />
              )}
            </div>
          </div>
        </div>

        <div className="form-section">
          <div className="row">
            <div className="col-12">
              <Input type="submit" value="Submit" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <ReactStrapForm onSubmit={handleSubmit}>
        <div className="form-section">
          <div className="row">
            <div className="col-sm-12 col-md-6 offset-md-3">
              <div className="row">
                <div className="col-sm-12">
                  <h2>Brand Type</h2>
                  {existingBrand ? (
                    <div>
                      {existingBrand.brandType === "team"
                        ? "Organization Brand"
                        : "Business Brand"}
                    </div>
                  ) : (
                    <FormGroup>
                      <Select
                        value={selectedBrandTypeOption}
                        onChange={(option) => {
                          setSelectedBrandTypeOption(option);
                        }}
                        options={brandTypeOptions}
                      />
                    </FormGroup>
                  )}
                </div>
              </div>

              {selectedBrandTypeOption.value === "sponsor" &&
                renderSponsorBrandForm()}
              {selectedBrandTypeOption.value === "team" &&
                renderTeamBrandForm()}
            </div>
          </div>
        </div>
      </ReactStrapForm>
    </div>
  );
}

export default withFirebase(Form);
