import styled, { css } from "styled-components/macro";
import { COLORS } from "../colors";
import { GLOBALS } from "../globals";

const DashboardCard = styled.div`
  box-sizing: border-box;
  background-color: ${COLORS.primaryWhite};
  border: 1px solid ${COLORS.primaryBlue};
  border-radius: 10px;
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  ${(props) =>
    props.shadowStyle &&
    css`
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    `}

  ${(props) => {
    if (props.responsive) {
      return css`
        flex-direction: row;
        @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
          width: ${(props) => (props.mobileWidth ? props.mobileWidth : "100%")};
        }
      `;
    }
  }}
`;

export default DashboardCard;
