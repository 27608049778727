import { create } from "zustand";
import {
  fetchRedemptions,
  fetchRedemptionsByChunks,
} from "../utilities/admin_helpers";

const useRedemptionsStore = create((set, get) => ({
  redemptions: null,
  contestEntries: null,
  redemptionsWithoutContestEntries: null,
  isLoading: false,
  error: null,
  isFetching: false,
  isLoadingDealRedemptions: false,
  errorDealRedemptions: null,
  dealRedemptions: null,
  lastVisible: null,
  chunkSize: 10000,

  fetchRedemptions: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await fetchRedemptions(firebase);
      const redemptionsWithoutContestEntries = data.filter(
        (redemption) => redemption.transactionType === "redemption"
      );
      const contestEntries = data.filter(
        (redemption) => redemption.transactionType === "contest"
      );

      set({
        redemptions: data,
        contestEntries,
        redemptionsWithoutContestEntries,
      });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchRedemptionsByChunks: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }

    set({ isLoading: true, error: null, isFetching: true });

    const chunkSize = get().chunkSize;
    let lastVisible = get().lastVisible;
    let allFetchedRedemptions = [];
    try {
      let hasMoreData = true;
      while (hasMoreData) {
        const { redemptions: fetchedRedemptions, lastVisible: newLastVisible } =
          await fetchRedemptionsByChunks(firebase, lastVisible, chunkSize);

        if (fetchedRedemptions.length === 0) {
          hasMoreData = false;
        } else {
          allFetchedRedemptions = [
            ...allFetchedRedemptions,
            ...fetchedRedemptions,
          ];

          const redemptionsWithoutContestEntries = fetchedRedemptions.filter(
            (redemption) => redemption.transactionType === "redemption"
          );
          const contestEntries = fetchedRedemptions.filter(
            (redemption) => redemption.transactionType === "contest"
          );

          const existingRedemptions = get().redemptions || [];
          const existingContestEntries = get().contestEntries || [];
          const existingRedemptionsWithoutContestEntries =
            get().redemptionsWithoutContestEntries || [];

          const updatedRedemptions = [
            ...existingRedemptions,
            ...fetchedRedemptions,
          ];
          const updatedContestEntries = [
            ...existingContestEntries,
            ...contestEntries,
          ];
          const updatedRedemptionsWithoutContestEntries = [
            ...existingRedemptionsWithoutContestEntries,
            ...redemptionsWithoutContestEntries,
          ];

          set({
            redemptions: updatedRedemptions,
            contestEntries: updatedContestEntries,
            redemptionsWithoutContestEntries:
              updatedRedemptionsWithoutContestEntries,
            lastVisible: newLastVisible,
          });

          lastVisible = newLastVisible;

          if (!newLastVisible) {
            hasMoreData = false;
            set({ isLoading: false, isFetching: false });
          }
        }
      }
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchDealRedemptions: async (firebase, dealId, sponsorId, teamId) => {
    if (get().isLoadingDealRedemptions) {
      return;
    }
    set({
      isLoadingDealRedemptions: true,
      errorDealRedemptions: null,
    });
    try {
      const data = await firebase.firestore
        .collection("redemptions")
        .where("offerID", "==", dealId)
        .where("sponsorID", "==", sponsorId)
        .where("teamID", "==", teamId)
        .get();

      const redemptions = [];
      const recordIds = new Set();
      if (!data.empty) {
        data.forEach((doc) => {
          if (recordIds.has(doc.id)) {
            return;
          }
          recordIds.add(doc.id);
          redemptions.push({ id: doc.id, ...doc.data() });
        });

        set({
          dealRedemptions: {
            [dealId]: redemptions,
          },
        });
      } else {
        set({
          dealRedemptions: {
            [dealId]: [],
          },
        });
      }
    } catch (error) {
      set({ errorDealRedemptions: error });
    } finally {
      set({ isLoadingDealRedemptions: false });
    }
  },
}));

export default useRedemptionsStore;
