/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

function useFollowedOrganizationOffers(
  firebase,
  authUser,
  loadingFollowedOrganizations,
  followedOrganizations,
  excludeOffersThatArePartOfOfferGroups
) {
  const [followedOrganizationOffers, setFollowedOrganizationOffers] = useState(
    []
  );
  const [loading, setLoading] = useState(true);

  const sponsorOrganizationMap = {};
  const offerSponsorMap = {};

  function loadFollowedOrganizationsOffers() {
    const sponsorPromises = [];

    followedOrganizations.forEach((organization) => {
      if (organization.sponsors) {
        organization.sponsors.forEach((sponsorKey) => {
          sponsorOrganizationMap[sponsorKey] = organization;
          sponsorPromises.push(
            firebase.database.ref(`/sponsor/${sponsorKey}`).once("value")
          );
        });
      }
    });

    Promise.all(sponsorPromises)
      .then((sponsorSnapshots) => {
        const offerPromises = [];
        const offerKeys = [];

        sponsorSnapshots.forEach((sponsorSnapshot) => {
          if (sponsorSnapshot.val() !== null) {
            const sponsor = sponsorSnapshot.val();
            sponsor.key = sponsorSnapshot.key;

            if (sponsor.offers) {
              sponsor.offers.forEach((offerInfo) => {
                if (offerInfo.active) {
                  if (!offerInfo.hiddenDeal) {
                    if (!offerKeys.includes(offerInfo.key)) {
                      offerSponsorMap[offerInfo.key] = sponsor;
                      offerKeys.push(offerInfo.key);
                      offerPromises.push(
                        firebase.database
                          .ref(`/offer/${offerInfo.key}`)
                          .once("value")
                      );
                    }
                  } else {
                    // check if a user has access to hidden deals
                    if (authUser && authUser.unlockedSpecialDeals) {
                      const teamKey = sponsor.teams
                        ? Object.keys(sponsor.teams)[0]
                        : null;
                      if (teamKey) {
                        const userAccessLevels =
                          (authUser.unlockedSpecialDeals[teamKey] &&
                            Object.keys(
                              authUser.unlockedSpecialDeals[teamKey]
                            )) ||
                          [];

                        const offerAccessLevels = offerInfo.accessLevels;

                        if (userAccessLevels && offerAccessLevels) {
                          const hasAccess = userAccessLevels.some((level) => {
                            return offerAccessLevels.includes(level);
                          });

                          if (hasAccess) {
                            if (!offerKeys.includes(offerInfo.key)) {
                              offerSponsorMap[offerInfo.key] = sponsor;
                              offerKeys.push(offerInfo.key);
                              offerPromises.push(
                                firebase.database
                                  .ref(`/offer/${offerInfo.key}`)
                                  .once("value")
                              );
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
            }
          }
        });

        return Promise.all(offerPromises);
      })
      .then((offerSnapshots) => {
        const offers = [];

        offerSnapshots.forEach((offerSnapshot) => {
          if (offerSnapshot.val() !== null) {
            const offer = offerSnapshot.val();
            offer.key = offerSnapshot.key;

            if (
              !excludeOffersThatArePartOfOfferGroups ||
              !offer.offerGroupKey
            ) {
              const sponsorInfo = offerSponsorMap[offer.key];
              const organizationInfo = sponsorOrganizationMap[sponsorInfo.key];
              offer.organizationInfo = organizationInfo;

              offers.push(offer);
            }
          }
        });

        setFollowedOrganizationOffers(offers);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!loadingFollowedOrganizations) {
      loadFollowedOrganizationsOffers();
    }
  }, [loadingFollowedOrganizations, followedOrganizations]);

  return { followedOrganizationOffers, loading };
}

export default useFollowedOrganizationOffers;
