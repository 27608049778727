import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";

const LinkWrapper = styled.a`
  padding: ${(props) => props.padding || "0"};
  cursor: pointer;
  .content {
    color: ${(props) => props.color || COLORS.primaryBlue};
    text-decoration: ${(props) => props.textDecoration || "none"};
  }

  &:hover {
    .content {
      color: ${(props) => props.hoverColor || COLORS.primaryBlueHover};
      text-decoration: ${(props) => props.hoverTextDecoration || "none"};
    }
  }
`;

const Link = ({
  children,
  href,
  title,
  onClick,
  color,
  hoverColor,
  textDecoration,
  hoverTextDecoration,
  padding,
  className,
}) => {
  return (
    <LinkWrapper
      href={href}
      title={title}
      onClick={onClick}
      color={color}
      hoverColor={hoverColor || color}
      textDecoration={textDecoration}
      hoverTextDecoration={hoverTextDecoration}
      padding={padding}
      className={className}
    >
      {children}
    </LinkWrapper>
  );
};

export default Link;

Link.propTypes = {
  children: propTypes.node.isRequired,
  href: propTypes.string,
  title: propTypes.string,
  onClick: propTypes.func,
  color: propTypes.string,
  hoverColor: propTypes.string,
  textDecoration: propTypes.string,
  hoverTextDecoration: propTypes.string,
  padding: propTypes.string,
  className: propTypes.string,
};

Link.defaultProps = {
  href: "#",
  title: "",
  onClick: () => {},
  color: COLORS.primaryBlue,
  hoverColor: "",
  textDecoration: "none",
  hoverTextDecoration: "none",
  padding: "0",
  className: "",
};
