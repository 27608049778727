import React from "react";
import { withRouter, Link } from "react-router-dom";
import FrenchEmail1 from "../../../assets/img/french-emails/1.png";
import FrenchEmail2 from "../../../assets/img/french-emails/2.png";
import FrenchEmail3 from "../../../assets/img/french-emails/3.png";
import FrenchEmail4 from "../../../assets/img/french-emails/4.png";
import FrenchEmail5 from "../../../assets/img/french-emails/5.png";
import styled from "styled-components";

const EmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow: auto;
  img {
    width: 100%;
    height: auto;
  }
`;

const FrenchEmails = ({ match }) => {
  const { params } = match;
  const { email_id } = params;

  const emailImages = {
    1: FrenchEmail1,
    2: FrenchEmail2,
    3: FrenchEmail3,
    4: FrenchEmail4,
    5: FrenchEmail5,
  };

  const emailLinks = {
    1: "/",
    2: "/organizations",
    3: "/",
    4: "/deals",
    5: "/deals",
  };

  const emailImage = emailImages[email_id] || FrenchEmail1;
  const emailLink = emailLinks[email_id] || "/";

  return (
    <EmailContainer>
      <Link to={emailLink}>
        <img src={emailImage} alt="French Email" />
      </Link>
    </EmailContainer>
  );
};

export default withRouter(FrenchEmails);
