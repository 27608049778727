import React, { Component } from "react";
import Select from "react-select";
import styled from "styled-components/macro";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { withFirebase } from "../../../../containers/Firebase";
import ImageUploader from "../../../../containers/ImageUploader";
import { isBlank } from "../../../../utilities/core";
import {
  imageUploadInputStyle,
  imageStyle,
  labelStyle,
} from "../../utilities/PortalFormStyles";
import { inputStyle } from "../../utilities/PortalFormStyles";
import { dbLoadTeams } from "../../../../containers/DatabaseHelpers";

const StyledContainer = styled.div`
  margin-top: 40px;
`;

class Create extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      picture: "",
      pictureFile: null,
      picturePath: null,
      pictureURL: "",
      address: "",
      alertRadius: "",
      description: "",
      lat: "",
      long: "",
      city: "",
      title: "",
      offers: [],
      redeemRadius: "",
      facebook: "",
      linkedin: "",
      twitter: "",
      instagram: "",
      website: "",
      phone: "",
      displayEmail: "",
      category: "",
      keywords: "",
      uuid: "",
      email: "",
      password: "",
      passwordConfirm: "",
      accountFormShow: true,
      sponsorFormShow: false,
      sponsorLinkFormShow: false,
      multiSelect: [],
      selectedOption: "",
      teams: null,
    };

    this.pictureClick = this.pictureClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushSponsorData = this.pushSponsorData.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = () => {
    const { firebase } = this.props;

    dbLoadTeams(firebase, (teams) => {
      const allTeamsMap = {};
      teams.forEach((team) => {
        allTeamsMap[team.key] = team;
      });

      const teamsForSelect = teams
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((team) => ({ value: team.key, label: team.name }));

      this.setState({ allTeamsMap, teamsForSelect }, this.mapTeamList);
    });
  };

  mapTeamList = () => {
    const { allTeamsMap, teams } = this.state;
    if (!allTeamsMap) return;
    var defaultValue = [];
    if (teams) {
      teams.forEach((t) => {
        const team = allTeamsMap[t];
        if (team) {
          defaultValue.push({ value: team.key, label: team.name });
        }
      });
    }
    this.setState({ selectedOption: defaultValue });
    this.handleSelectChange(defaultValue);
  };

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    const files = e.target.files;
    if (files) {
      let fileName = name + "File";
      let filePath = name + "Path";
      let file = files[0];
      this.setState({ [fileName]: file });
      this.setState({ [filePath]: URL.createObjectURL(file) });
    } else {
      this.setState({ [name]: value });
    }
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  pictureClick() {
    document.getElementById("picture_upload").click();
  }

  validateForm() {
    const { lat, long } = this.state;

    if (isBlank(lat)) {
      alert("Please enter a latitude value");
      return false;
    }

    if (isBlank(long)) {
      alert("Please enter a longitude value");
      return false;
    }

    return true;
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.validateForm()) {
      return;
    }

    let sponsorStorageRef = this.props.firebase.storage.ref();
    let sponsorStorageBasePath = "images/sponsor/";

    var ref = this.props.firebase.database.ref("sponsor").push();
    var sponsorID = ref.key;
    sponsorStorageBasePath += sponsorID + "/";

    var picturePath = sponsorStorageBasePath + "profile.jpg";

    if (this.state.pictureFile) {
      sponsorStorageRef
        .child(picturePath)
        .put(this.state.pictureFile)
        .then((dataSnapshot) => dataSnapshot.ref.getDownloadURL())
        .then((url) => {
          this.setState({ pictureURL: url });

          if (this.state.uuid && this.state.uuid.length > 0) {
            var userPath = "user/" + this.state.uuid;
            var userRef = this.props.firebase.database.ref(userPath);
            var payload = { sponsorID, role: "sponsor" };

            userRef.set(payload).then(() => {
              this.pushSponsorData(ref);
            });
          } else {
            this.pushSponsorData(ref);
          }
        });
    } else {
      this.pushSponsorData(ref);
    }
  }

  pushSponsorData = (ref) => {
    const sponsorData = {
      name: this.state.title,
      description: this.state.description,
      address: this.state.address,
      "alert radius": this.state.alertRadius
        ? parseInt(this.state.alertRadius)
        : this.state.alertRadius,
      "redeem radius": this.state.redeemRadius
        ? parseInt(this.state.redeemRadius)
        : this.state.redeemRadius,
      facebook: this.state.facebook,
      twitter: this.state.twitter,
      linkedin: this.state.linkedin,
      instagram: this.state.instagram,
      lat: this.state.lat ? parseFloat(this.state.lat) : this.state.lat,
      long: this.state.long ? parseFloat(this.state.long) : this.state.long,
      city: this.state.city,
      picture: this.state.pictureURL,
      sponsorUser: this.state.uuid,
      email: this.state.email,
      displayEmail: this.state.displayEmail,
      phone: this.state.phone,
      website: this.state.website,
      category: this.state.category,
      keywords: this.state.keywords,
      teams: this.state.teams,
    };

    ref.set(sponsorData).then(() => {
      this.props.history.push("/portal/sponsors");
    });
  };

  handleAccountCreateSubmit = (event) => {
    event.preventDefault();

    if (!this.state.email || this.state.email.length < 1) {
      return;
    }
    if (!this.state.password || this.state.password.length < 1) {
      return;
    }
    if (!this.state.passwordConfirm || this.state.passwordConfirm.length < 1) {
      return;
    }

    this.props.firebase.auth2
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((dataSnapshot) => {
        this.setState({
          accountFormShow: false,
          sponsorFormShow: true,
          uuid: dataSnapshot.user.uid,
        });
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
      });
  };

  handleSponsorLink = (event) => {
    event.preventDefault();

    if (!this.state.email || this.state.email.length < 1) {
      return;
    }
    if (!this.state.password || this.state.password.length < 1) {
      return;
    }
    if (!this.state.passwordConfirm || this.state.passwordConfirm.length < 1) {
      return;
    }

    var sponsorRef = this.props.firebase.database.ref("sponsor");
    sponsorRef.once("value").then((dataSnapshot) => {
      var sponsors = [];
      dataSnapshot.forEach((sponsor) => {
        let name = sponsor.val().name;
        sponsors.push({ value: sponsor.key, label: name });
      });
      var sorted = sponsors.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setState({ multiSelect: sorted }, () => this.showSponsorLinkForm());
    });

    this.props.firebase.auth2
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then((dataSnapshot) => {
        this.setState(
          {
            uuid: dataSnapshot.user.uid,
          },
          () => this.showSponsorLinkForm()
        );
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/weak-password") {
          alert("The password is too weak.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
  };

  showSponsorLinkForm = () => {
    if (this.state.uuid.length > 0 && this.state.multiSelect.length > 0) {
      this.setState({
        sponsorLinkFormShow: true,
        accountFormShow: false,
      });
    }
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption });
    const teams = {};
    selectedOption.forEach((option) => {
      teams[option.value] = { key: option.value, name: option.label };
    });
    this.setState({ teams });
  };

  handleSponsorLinkSubmit = (event) => {
    event.preventDefault();

    if (!this.state.uuid || this.state.uuid.length < 1) {
      return;
    }
    if (!this.state.selectedOption || this.state.selectedOption.length < 1) {
      return;
    }

    let sponsorID = this.state.selectedOption.value;
    var userPath = "user/" + this.state.uuid;
    var userRef = this.props.firebase.database.ref(userPath);
    var payload = { sponsorID, role: "sponsor" };

    userRef.set(payload).then(() => {
      this.props.history.push("/");
    });

    var sponsorEmailPath = "sponsor/" + sponsorID + "/email";
    var sponsorRef = this.props.firebase.database.ref(sponsorEmailPath);
    sponsorRef.set(this.state.email);
  };

  render() {
    const { selectedOption, teamsForSelect } = this.state;
    return (
      <StyledContainer className="animated fadeIn" id="main">
        {this.state.accountFormShow && (
          <Form
            className="row"
            onSubmit={(event) => this.handleAccountCreateSubmit(event)}
          >
            <div className="col-12 text-center mb-3">
              <h3>Create Business Account</h3>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>EMAIL</Label>
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={(event) => this.handleUserInput(event)}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>PASSWORD</Label>
                <Input
                  name="password"
                  value={this.state.password}
                  type="password"
                  onChange={(event) => this.handleUserInput(event)}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-4">
              <FormGroup>
                <Label>PASSWORD CONFIRM</Label>
                <Input
                  name="passwordConfirm"
                  value={this.state.passwordConfirm}
                  type="password"
                  onChange={(event) => this.handleUserInput(event)}
                  style={inputStyle}
                ></Input>
              </FormGroup>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Button
                name="link-existing"
                onClick={(event) => this.handleSponsorLink(event)}
              >
                Submit and Link to Existing Business
              </Button>
            </div>
            <div className="col-12 col-md-6 text-center">
              <Button
                type="submit"
                value="Submit and Create New Business"
                name="create"
              >
                Submit and Create New Business
              </Button>
            </div>
          </Form>
        )}

        {this.state.sponsorLinkFormShow && (
          <Form
            className="row"
            onSubmit={(event) => this.handleSponsorLinkSubmit(event)}
          >
            <div className="col-12 mb-4">
              <Label>SELECT BUSINESSES</Label>
              <Select
                value={selectedOption}
                onChange={this.handleSelectChange}
                options={this.state.multiSelect}
                isMulti={false}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? "#464646" : "#b3b3b3",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            </div>
          </Form>
        )}

        {this.state.sponsorFormShow && (
          <Form onSubmit={(event) => this.handleSubmit(event)}>
            <div className="row">
              <div className="col-12">
                <FormGroup className="text-center">
                  <Label style={labelStyle}>
                    <strong>LOGO</strong>
                  </Label>
                  <ImageUploader
                    src={this.state.picturePath}
                    style={imageStyle}
                    onClick={this.pictureClick}
                    alt="Logo"
                  />
                  <input
                    name="picture"
                    onChange={(event) => this.handleUserInput(event)}
                    id="picture_upload"
                    type="file"
                    style={imageUploadInputStyle}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="form-section">
              <h2>Basic Info</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>TITLE (Full Name)</Label>
                    <Input
                      name="title"
                      value={this.state.title}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>

                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>DESCRIPTION</Label>
                    <Input
                      name="description"
                      value={this.state.description}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Team</h2>
              <div className="row">
                <div className="col-12 mb-4">
                  <Label>SELECT TEAM</Label>
                  <Select
                    value={this.state.selectedOption}
                    onChange={this.handleSelectChange}
                    options={teamsForSelect}
                    isMulti={true}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        borderColor: state.isFocused ? "#464646" : "#b3b3b3",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Contact Info</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>WEBSITE URL</Label>
                    <Input
                      name="website"
                      value={this.state.website}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>PHONE NUMBER</Label>
                    <Input
                      name="phone"
                      value={this.state.phone}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>EMAIL</Label>
                    <Input
                      name="email"
                      value={this.state.email}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>DISPLAY EMAIL</Label>
                    <Input
                      name="displayEmail"
                      value={this.state.displayEmail}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Address Info</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>ADDRESS</Label>
                    <Input
                      name="address"
                      value={this.state.address}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>CITY</Label>
                    <Input
                      name="city"
                      value={this.state.city}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>LATITUDE</Label>
                    <Input
                      name="lat"
                      value={this.state.lat}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>LONGITUDE</Label>
                    <Input
                      name="long"
                      value={this.state.long}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Deal Info</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>ALERT RADIUS</Label>
                    <Input
                      name="alertRadius"
                      value={this.state.alertRadius}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>REDEEM RADIUS</Label>
                    <Input
                      name="redeemRadius"
                      value={this.state.redeemRadius}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Social Links</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>FACEBOOK PROFILE URL</Label>
                    <Input
                      name="facebook"
                      value={this.state.facebook}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>LINKEDIN PROFILE URL</Label>
                    <Input
                      name="linkedin"
                      value={this.state.linkedin}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>TWITTER PROFILE URL</Label>
                    <Input
                      name="twitter"
                      value={this.state.twitter}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>INSTAGRAM PROFILE URL</Label>
                    <Input
                      name="instagram"
                      value={this.state.instagram}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <h2>Search Info</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>Category</Label>
                    <Input
                      name="category"
                      value={this.state.category}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
                <div className="col-sm-12 col-md-6">
                  <FormGroup>
                    <Label>Keywords</Label>
                    <Input
                      name="keywords"
                      value={this.state.keywords}
                      onChange={(event) => this.handleUserInput(event)}
                      style={inputStyle}
                    ></Input>
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="form-section">
              <div className="row">
                <div className="col-12">
                  <Input type="submit" value="Submit" style={inputStyle} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </StyledContainer>
    );
  }
}

export default withFirebase(Create);
