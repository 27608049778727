import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "reactstrap";
import { SponsorRow } from "../../../../containers/Analytics";
import { withFirebase } from "../../../../containers/Firebase";

import { Section } from "../../../../containers/UIKit";

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsors: null,
      allSponsors: null,
      offers: null,
      users: null,
      teams: null,
      tableData: "",
      redemptionData: {},
      sortedRedemptionSponsor: [],
    };
  }

  componentDidMount() {
    this.loadTeam();
    this.loadAllTeams();
    this.loadSponsors();
    this.loadOffers();
    this.loadUsers();
  }

  loadTeam = () => {
    var teamID = this.props.match.params.team_id;
    var teamPath = "/team/" + teamID;
    var teamsRef = this.props.firebase.database.ref(teamPath);
    teamsRef.once("value").then((dataSnapshot) => {
      if (dataSnapshot.val() !== null) {
        const team = dataSnapshot.val();
        team.key = dataSnapshot.key;
        this.setState({ team }, this.dataLoaded);
      }
    });
  };

  loadAllTeams = () => {
    var allTeamsRef = this.props.firebase.database.ref("team");
    allTeamsRef.once("value").then((dataSnapshot) => {
      var teams = dataSnapshot.val();
      this.setState({ teams }, this.dataLoaded);
    });
  };

  loadSponsors = () => {
    var sponsorRef = this.props.firebase.database.ref("sponsor");
    sponsorRef
      .orderByChild("name")
      .once("value")
      .then((dataSnapshot) => {
        var allSponsors = dataSnapshot.val();
        this.setState({ allSponsors }, this.dataLoaded);
      });
  };

  loadOffers = () => {
    var offerRef = this.props.firebase.database.ref("offer/");
    offerRef.once("value").then((dataSnapshot) => {
      const offers = dataSnapshot.val();
      this.setState({ offers }, this.dataLoaded);
    });
  };

  loadUsers = () => {
    var userRef = this.props.firebase.database.ref("user/");
    userRef.once("value").then((dataSnapshot) => {
      const users = dataSnapshot.val();
      this.setState({ users }, this.dataLoaded);
    });
  };

  dataLoaded = () => {
    if (
      !this.state.team ||
      !this.state.allSponsors ||
      !this.state.offers ||
      !this.state.teams ||
      !this.state.users
    )
      return;

    // var i = 0;
    // for (var key in this.state.offers) {
    //   var offer = this.state.offers[key];
    //   if (offer["teams"] != null) continue;

    //   console.log(key);
    //   i++;
    //   for (var sponsorKey in this.state.allSponsors) {
    //     var sponsor = this.state.allSponsors[sponsorKey];
    //     for (var offerDict in sponsor.offers) {
    //       var offerData = sponsor.offers[offerDict];
    //       if (offerData["key"] != key) continue;
    //         for (var teamID in this.state.teams) {
    //             var team = this.state.teams[teamID];
    //             if (team.sponsors && team.sponsors.includes(sponsorKey)) {
    //                 var ref = this.props.firebase.database.ref('offer/' + key + '/teams');
    //                 ref.set([teamID]);
    //                 console.log(key);
    //                 break;
    //             }
    //         }
    //     }
    //   }
    // }

    // console.log(i);

    var redemptionCount = {};
    var redemptionTotalCount = 0;

    if (this.state.team.sponsors) {
      this.state.team.sponsors.forEach((sponsorID) => {
        var targetSponsor = this.state.allSponsors[sponsorID];

        if (!targetSponsor) {
          return;
        }

        var offers = this.state.allSponsors[sponsorID].offers;
        var users = this.state.users;
        redemptionCount[sponsorID] = 0;

        for (var offer in offers) {
          let offerID = offers[offer].key;

          for (var userID in users) {
            let redeems = users[userID].redeemed;
            if (!redeems) continue;

            const redeemKeys = Object.keys(redeems);
            if (redeemKeys.length < 1) continue;

            if (redeemKeys.includes(offerID)) {
              redemptionCount[sponsorID]++;
              redemptionTotalCount++;
            }
          }
        }
      });
    }

    const keys = Object.keys(redemptionCount);
    var sorted = keys.sort((a, b) =>
      this.state.allSponsors[a].name > this.state.allSponsors[b].name
        ? 1
        : this.state.allSponsors[b].name > this.state.allSponsors[a].name
        ? -1
        : 0
    );

    this.setState(
      {
        redemptionData: redemptionCount,
        sortedRedemptionSponsor: sorted,
        redemptionTotalCount,
      },
      () => this.renderTable()
    );
  };

  renderTable = () => {
    if (Object.keys(this.state.redemptionData).length < 1) return;

    const tableData = [];

    this.state.sortedRedemptionSponsor.forEach((sponsorID) => {
      const sponsor = this.state.allSponsors[sponsorID];
      const count = this.state.redemptionData[sponsorID];

      tableData.push(
        <SponsorRow
          sponsor={sponsor}
          count={count}
          sponsorID={sponsorID}
          key={sponsorID}
        />
      );
    });

    return tableData;
  };

  render() {
    const { team, redemptionTotalCount } = this.state;

    return !team ? (
      <div>Loading...</div>
    ) : (
      <div className="animated fadeIn" id="main">
        <h1 data-notranslate>{team.name}</h1>
        <Section>
          <h2>Purchases</h2>
          <Link to={`/portal/teams/${team.key}/purchases`}>
            <Button color="primary">View Purchase Stats</Button>
          </Link>
        </Section>

        <Section>
          <h2>Businesses</h2>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>Business ({team.sponsors && team.sponsors.length})</th>
                <th>Redemptions ({redemptionTotalCount})</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.renderTable()}</tbody>
          </Table>
        </Section>
      </div>
    );
  }
}

export default withFirebase(Show);
