import React from "react";
import "./FeaturedTeamTile.scss";
import OrganizationLink from "../OrganizationLink";
import { Arrow } from "../UIKit/Icons";

function FeaturedTeamTile({ organization }) {
  const first4Sponsors = organization.sponsorObjects.slice(0, 4);

  return (
    <OrganizationLink organization={organization}>
      <div className="component-featured-team-tile">
        <div className="header-background">
          <img src={organization.picture} alt={organization.name} />
        </div>
        <div className="body">
          <img
            src={organization.logo}
            className="logo"
            alt={organization.name}
          />
          <div className="name" data-notranslate>
            {organization.name}
          </div>
          <div className="featured-brands-caption">Featured Brands</div>
          <div className="featured-brands">
            {first4Sponsors.map((sponsor) => (
              <img
                src={sponsor.picture}
                key={sponsor.key}
                className="sponsor-picture"
                alt={sponsor.name}
              />
            ))}
          </div>
        </div>
        <div className="actions">
          View Page <Arrow />
        </div>
      </div>
    </OrganizationLink>
  );
}

export default FeaturedTeamTile;
