import React from "react";
import styled from "styled-components/macro";
import FirstLastNameEdit from "./FirstLastNameEdit";
import UsernameEdit from "./UsernameEdit";
import EmailEdit from "./EmailEdit";
import CityEdit from "./CityEdit";
import GenderEdit from "./GenderEdit";
import BirthdayEdit from "./BirthdayEdit";
import { COLORS } from "../../assets/fansaves-ui";

const PersonalInfoEditContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const PersonalInfoFormContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  padding: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 100px;
  }

  @media (max-width: 768px) {
    padding: 0px;
    padding-bottom: 100px;
    padding-top: 20px;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding: 20px 20px 0 30px;
`;

const PersonalInfoEdit = () => {
  return (
    <PersonalInfoEditContainer>
      <Title>Profile / Personal Information</Title>
      <PersonalInfoFormContainer>
        <LeftContainer>
          <FirstLastNameEdit />
          <UsernameEdit />
          <EmailEdit />
        </LeftContainer>
        <RightContainer>
          <CityEdit />
          <BirthdayEdit />
          <GenderEdit />
        </RightContainer>
      </PersonalInfoFormContainer>
    </PersonalInfoEditContainer>
  );
};

export default PersonalInfoEdit;
