import { useState, useEffect } from 'react';

function useFollowedOrganizationOfferGroups(firebase, loadingFollowedOrganizations, followedOrganizations) {
  const [followedOrganizationOfferGroups, setFollowedOrganizationOfferGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const brandOrganizationMap = {};
  const offerGroupBrandMap = {};
  
  function loadFollowedOrganizationsOfferGroups() {
    const brandPromises = [];

    followedOrganizations.forEach((organization) => {
      if(organization.brandKeys) {
        organization.brandKeys.forEach((brandKey) => {
          brandOrganizationMap[brandKey] = organization;
          brandPromises.push(firebase.database.ref(`/brand/${brandKey}`).once('value'));
        });
      }
    });

    Promise.all(brandPromises)
    .then((brandSnapshots) => {
      const offerGroupPromises = [];
      const offerGroupKeys = [];

      brandSnapshots.forEach((brandSnapshot) => {
        if(brandSnapshot.val() !== null) {
          const brand = brandSnapshot.val();
          brand.key = brandSnapshot.key;

          if(brand.offerGroups) {
            Object.keys(brand.offerGroups).forEach((offerGroupKey) => {
              offerGroupBrandMap[offerGroupKey] = brand;
              offerGroupKeys.push(offerGroupKey);
              offerGroupPromises.push(firebase.database.ref(`/offerGroup/${offerGroupKey}`).once('value'));
            });
          }
        }
      });

      return Promise.all(offerGroupPromises);
    })
    .then((offerGroupSnapshots) => {
      const offerGroups = [];

      offerGroupSnapshots.forEach((offerGroupSnapshot) => {
        if(offerGroupSnapshot.val() !== null) {
          const offerGroup = offerGroupSnapshot.val();
          offerGroup.key = offerGroupSnapshot.key;

          const brandInfo = offerGroupBrandMap[offerGroup.key];
          const organizationInfo = brandOrganizationMap[brandInfo.key];
          offerGroup.organizationInfo = organizationInfo;

          offerGroups.push(offerGroup);
        }
      });

      setFollowedOrganizationOfferGroups(offerGroups);
      setLoading(false);
    });
  }

  useEffect(() => {
    if(!loadingFollowedOrganizations) {
      loadFollowedOrganizationsOfferGroups();
    }
  }, [loadingFollowedOrganizations, followedOrganizations]);

  return { followedOrganizationOfferGroups, loading };
}

export default useFollowedOrganizationOfferGroups