/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { Icon } from "@iconify/react";
import debounce from "lodash.debounce";

import "./../../../scss/base/typography.scss";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid ${COLORS.primaryBlue};
  border-radius: 20px;
  background-color: ${COLORS.chartGrey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: ${(props) => (props.width ? props.width : "290px")};
  height: 35px;
  padding: 8px 15px;
  .search-icon {
    font-size: 25px;
    color: ${COLORS.primaryBlue};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: ${COLORS.transparentBlack};
  &:focus {
    outline: none;
  }
`;
const SearchButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${COLORS.primaryBlue};
`;

const SearchBar = ({
  width,
  placeholder,
  searchQuery,
  setSearchQuery,
  className,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query === "") {
        setSearchQuery(null);
      } else {
        setSearchQuery(query);
      }
    }, 300),
    [setSearchQuery]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setSearchQuery(null);
    setSearchTerm("");
  };
  useEffect(() => {
    if (searchQuery) {
      setSearchTerm(searchQuery);
    }
  }, [searchQuery]);

  return (
    <Wrapper width={width} className={className}>
      <Icon icon="mdi:search" className="search-icon" />
      <Input
        placeholder={placeholder ? placeholder : "Search..."}
        value={searchTerm}
        onChange={handleChange}
        className="search-bar-input inter-b2"
        type="text"
        id="search-bar"
        name="query"
      />
      <SearchButton type="button" onClick={handleClear}>
        <Icon icon="mdi:close" />
      </SearchButton>
    </Wrapper>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  width: propTypes.string,
  placeholder: propTypes.string,
  searchQuery: propTypes.string,
  setSearchQuery: propTypes.func,
  className: propTypes.string,
};
