import React, { useState, useEffect, useRef } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { mapStyles } from "./MapStyles";
import { Icon } from "@iconify/react";
import { COLORS } from "../colors";
import styled, { keyframes } from "styled-components/macro";
import useMobile from "../../../hooks/useMobile";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const GoogleMapContainer = styled.div`
  position: relative;

  .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
  .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
  .gm-style .gm-style-iw-c,
  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gm-style .gm-style-iw-c {
    border: 1px solid;
    border-color: ${({ dataType }) =>
      dataType === "team" ? COLORS.primaryBlue : COLORS.primaryGreenDark};
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: ${({ dataType }) =>
      dataType === "team" ? COLORS.primaryBlue : COLORS.primaryGreenDark};
    color: ${COLORS.primaryWhite};
    padding: 0 !important; /* Remove default padding */
    min-height: 40px;
  }

  .gm-style .gm-style-iw-tc::after {
    display: none;
  }

  .gm-style-iw {
    padding: 0 !important; /* Remove default padding */
  }

  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style .gm-style-iw-tc {
    padding-bottom: 0 !important;
  }
`;

const DefaultCard = styled.div`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? "auto" : "0")};
  left: 0;
  right: ${({ isMobile }) => (isMobile ? "0" : "auto")};
  bottom: 0;
  width: ${({ isMobile }) => (isMobile ? "100%" : "30%")};
  min-width: ${({ isMobile }) => (isMobile ? "100%" : "300px")};
  height: ${({ isMobile }) => (isMobile ? "fit-content" : "100%")};
  z-index: 1;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.primaryBlue};
  height: ${({ height }) => height || "400px"};
  font-family: "Manrope", sans-serif;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: ${COLORS.primaryBlue};
  height: ${({ height }) => height || "400px"};
  font-family: "Manrope", sans-serif;
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${COLORS.primaryWhite};
`;

const GenericMapComponent = ({
  markers,
  renderMarker,
  renderCard,
  mapHeight = "400px",
  defaultCenter,
  dataType,
}) => {
  const [center, setCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const mapRef = useRef(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const isMobile = useMobile();

  useEffect(() => {
    if (!navigator.onLine) {
      setError("You are offline. Please check your internet connection.");
      setLoading(false);
      return;
    }

    if (!window.google || !window.google.maps) {
      setError("Google Maps JavaScript API is not loaded.");
      setLoading(false);
      return;
    }

    if (defaultCenter) {
      // check if user location changed and update map center
      if (
        center &&
        defaultCenter.lat === center.lat &&
        defaultCenter.lng === center.lng
      ) {
        return;
      }
      setCenter(defaultCenter);
    } else {
      // Default to Toronto if no center is provided
      setCenter({ lat: 43.6532, lng: -79.3832 });
    }

    setLoading(false);
  }, [center, defaultCenter]);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setSelectedMarker(null);
  };

  return (
    <GoogleMapContainer dataType={dataType}>
      {loading && (
        <LoadingContainer height={mapHeight}>
          <Icon icon="mdi:map-marker" color={COLORS.primaryBlue} width={30} />
          Loading map...
        </LoadingContainer>
      )}
      {error && (
        <ErrorContainer height={mapHeight}>
          <Icon
            icon="mdi:map-marker-off"
            color={COLORS.primaryGrey}
            width={30}
          />
          Oops, something went wrong while loading the map. Please try again
          later.
        </ErrorContainer>
      )}
      {!loading && !error && center && (
        <GoogleMap
          mapContainerStyle={{
            height: mapHeight,
            width: "100%",
          }}
          zoom={6}
          center={center}
          options={{
            styles: mapStyles,
            disableDefaultUI: true,
            zoomControl: true,
          }}
          onLoad={(map) => {
            mapRef.current = map;
          }}
        >
          {markers.map((marker) =>
            renderMarker(marker, () => handleMarkerClick(marker))
          )}
          {selectedMarker && renderCard && (
            <DefaultCard isMobile={isMobile}>
              <CloseButton onClick={handleClose}>
                <Icon icon="mdi:close" width={24} />
              </CloseButton>
              {renderCard(selectedMarker)}
            </DefaultCard>
          )}
        </GoogleMap>
      )}
    </GoogleMapContainer>
  );
};

export default GenericMapComponent;
