const isBlank = function (value) {
  if (value === undefined || value === null || value.length == 0) {
    return true;
  }

  return false;
};

const currentUrlWithoutQueryString = function () {
  return window.location.href.split("?")[0];
};

const removeUndefineFieldFromObject = (inputObject) => {
  const workableObject = { ...inputObject };
  Object.keys(workableObject).forEach(
    (key) => workableObject[key] === undefined && delete workableObject[key]
  );
  return { ...workableObject };
};

const removeDuplicateElementInArray = (inputArray) => {
  return [...new Set(inputArray)];
};

const isMobileScreenSize = (width = 900) => window.innerWidth <= width;

const calculatePercentage = (array, total) => {
  const percentageArray = [];
  array.forEach((item) => {
    percentageArray.push(Math.round((item / total) * 100));
  });
  return percentageArray;
};

const sortObjectByValue = (object, direction) => {
  const result = Object.entries(object)
    .sort(([, a], [, b]) => (direction === "desc" ? a - b : b - a))
    .reduce((sortedObj, [k, v]) => ({ ...sortedObj, [k]: v }), {});
  return result;
};

const sortObjectByKey = (object, direction) => {
  const result = Object.entries(object)
    .sort(([a], [b]) => (direction === "desc" ? a - b : b - a))
    .reduce((sortedObj, [k, v]) => ({ ...sortedObj, [k]: v }), {});
  return result;
};

const sortArrayAlphaNumeric = (array) => {
  // numbers first
  const sortedArray = array.sort(
    (a, b) =>
      a.localeCompare(b, undefined, { numeric: true }) ||
      /^[0-9]/.test(a) - /^[0-9]/.test(b)
  );

  return sortedArray;
};

export {
  isBlank,
  currentUrlWithoutQueryString,
  removeUndefineFieldFromObject,
  removeDuplicateElementInArray,
  isMobileScreenSize,
  calculatePercentage,
  sortObjectByValue,
  sortObjectByKey,
  sortArrayAlphaNumeric,
};
