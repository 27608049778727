import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  DashboardButton,
  FlexContainer,
  Link,
} from "../../../../../assets/fansaves-ui";
import { withFirebase } from "../../../../../containers/Firebase";
import {
  getPointSystem,
  getUserGroupPreferences,
  userGenderGroups,
  userAgeGroups,
} from "../../../../../utilities/admin_helpers";
import moment from "moment";

const RecommendedDeals = ({ firebase }) => {
  const [pointSystem, setPointSystem] = useState(null);
  const [loadingPointSystem, setLoadingPointSystem] = useState(false);
  const [userGroupPreferences, setUserGroupPreferences] = useState(null);
  const [showPointSystem, setShowPointSystem] = useState(false);
  const [showUserGroupPreferences, setShowUserGroupPreferences] =
    useState(false);
  const [selectedUserGenderGroup, setSelectedUserGenderGroup] = useState(null);
  const [selectedUserAgeGroup, setSelectedUserAgeGroup] = useState(null);
  const [selectedUserGroupPreference, setSelectedUserGroupPreference] =
    useState(null);

  const setPointSystemValue = () => {
    setLoadingPointSystem(true);
    getPointSystem(
      firebase,
      (pointSystem) => {
        setPointSystem(pointSystem);
        setLoadingPointSystem(false);
      },
      (error) => {
        console.error(error);
        setLoadingPointSystem(false);
      }
    );
  };

  const setUserGroupPreferencesValue = () => {
    getUserGroupPreferences(
      firebase,
      (userGroupPreferences) => {
        setUserGroupPreferences(userGroupPreferences);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const filterUserGroupPreferences = () => {
    if (!userGroupPreferences) return;
    let filteredUserGroupPreferences = {};
    Object.values(userGroupPreferences).forEach((userGroupPreference) => {
      if (
        userGroupPreference.age === selectedUserAgeGroup.value &&
        userGroupPreference.gender === selectedUserGenderGroup.value
      ) {
        filteredUserGroupPreferences = userGroupPreference;
      }
    });
    setSelectedUserGroupPreference(filteredUserGroupPreferences);
  };

  useEffect(() => {
    if (selectedUserAgeGroup && selectedUserGenderGroup) {
      filterUserGroupPreferences();
    }
  }, [selectedUserAgeGroup, selectedUserGenderGroup]);

  useEffect(() => {
    setPointSystemValue();
    setUserGroupPreferencesValue();
  }, []);

  const submitPointSystem = () => {
    setLoadingPointSystem(true);
    firebase.database
      .ref("pointSystem")
      .set(pointSystem)
      .then(() => {
        getPointSystem(
          firebase,
          (pointSystem) => {
            setPointSystem(pointSystem);
            setLoadingPointSystem(false);
          },
          (error) => {
            console.error(error);
            setLoadingPointSystem(false);
          }
        );
      })
      .catch((error) => {
        setLoadingPointSystem(false);
      });
  };

  const translatePointSystemName = (name, uppercase = false) => {
    let updatedName = name.split(/(?=[A-Z])/).join(" ");
    name === "distanceFromUser" && (updatedName += " (in miles)");
    return uppercase ? updatedName.toUpperCase() : updatedName;
  };

  return (
    <FlexContainer margin="20px 0 50px 0" alignItems="flex-start">
      <h1>Recommended Deals</h1>
      <Link
        className="dropdown-link"
        onClick={(e) => {
          e.preventDefault();
          setShowPointSystem(!showPointSystem);
        }}
      >
        <h3>Point System</h3>
      </Link>
      {showPointSystem && (
        <FlexContainer
          alignItems="flex-start"
          justifyContent="flex-start"
          margin="0 0 50px 0"
        >
          {pointSystem ? (
            Object.entries(pointSystem).map(([key, value]) => (
              <FlexContainer
                key={key}
                direction="horizontal"
                gap="10px"
                alignItems="flex-start"
                justifyContent="flex-start"
                margin="0 0 20px 0"
              >
                <FlexContainer
                  width="400px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {translatePointSystemName(key, true)}
                </FlexContainer>
                {typeof value === "number" ? (
                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <label htmlFor={key}>
                      <input
                        type="number"
                        min={key === "qtyOfOffersToShuffle" ? 1 : undefined}
                        name={key}
                        value={value}
                        onChange={(e) => {
                          const newPointSystem = { ...pointSystem };
                          newPointSystem[key] = parseInt(e.target.value);
                          setPointSystem(newPointSystem);
                        }}
                      />
                    </label>
                  </FlexContainer>
                ) : (
                  <FlexContainer
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {Object.entries(value)
                      .sort((a, b) => b[1] - a[1])
                      .map(([key1, value1]) => (
                        <FlexContainer
                          key={key1}
                          direction="horizontal"
                          gap="10px"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          responsive
                        >
                          <FlexContainer
                            width="100px"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                          >
                            {translatePointSystemName(key1)}
                          </FlexContainer>
                          <label htmlFor={key1}>
                            <input
                              type="number"
                              name={key1}
                              value={value1}
                              onChange={(e) => {
                                const newPointSystem = { ...pointSystem };
                                newPointSystem[key][key1] = parseInt(
                                  e.target.value
                                );
                                setPointSystem(newPointSystem);
                              }}
                            />
                          </label>
                        </FlexContainer>
                      ))}
                  </FlexContainer>
                )}
              </FlexContainer>
            ))
          ) : (
            <div>Loading...</div>
          )}
          <DashboardButton
            title={loadingPointSystem ? "Updating..." : "Update Point System"}
            disabled={!pointSystem || loadingPointSystem}
            onClick={submitPointSystem}
          />
        </FlexContainer>
      )}
      <Link
        className="dropdown-link"
        onClick={(e) => {
          e.preventDefault();
          setShowUserGroupPreferences(!showUserGroupPreferences);
        }}
      >
        <h3>User Group Preferences</h3>
      </Link>
      {showUserGroupPreferences && (
        <FlexContainer
          alignItems="flex-start"
          justifyContent="flex-start"
          margin="0 0 50px 0"
        >
          <FlexContainer
            direction="horizontal"
            gap="10px"
            alignItems="flex-start"
            justifyContent="flex-start"
            margin="0 0 20px 0"
            responsive
          >
            <Select
              options={userGenderGroups}
              value={selectedUserGenderGroup}
              onChange={setSelectedUserGenderGroup}
              styles={{ input: (provided) => ({ ...provided, width: 200 }) }}
            />
            <Select
              options={userAgeGroups}
              value={selectedUserAgeGroup}
              onChange={setSelectedUserAgeGroup}
              styles={{ input: (provided) => ({ ...provided, width: 200 }) }}
            />
          </FlexContainer>
          {selectedUserGroupPreference &&
            Object.entries(selectedUserGroupPreference).map(([key, value]) => (
              <FlexContainer
                key={key}
                direction="horizontal"
                gap="10px"
                alignItems="flex-start"
                justifyContent="flex-start"
                className="group-preference-container"
              >
                <div className="group-preferenc-key">{key}: </div>
                <FlexContainer
                  key={key}
                  // direction="horizontal"
                  gap="10px"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  className="group-preference-value-container"
                >
                  {typeof value === "string"
                    ? value
                    : Object.entries(value)
                        .sort((a, b) => a[1].position - b[1].position)
                        .map(([key1, value1]) => (
                          <FlexContainer
                            key={key1}
                            direction="horizontal"
                            gap="10px"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                          >
                            <div>{key1}: </div>
                            <div>
                              {typeof value === "string"
                                ? value
                                : Object.entries(value1).map(
                                    ([key2, value2]) => (
                                      <div key={key2}>
                                        {key2}: {value2}
                                      </div>
                                    )
                                  )}
                            </div>
                          </FlexContainer>
                        ))}
                </FlexContainer>
              </FlexContainer>
            ))}
          <div className="group-preference-updated-time">
            User Group Preferences Updated:{" "}
            {userGroupPreferences.updatedAt &&
              moment(userGroupPreferences.updatedAt).format(
                "MM/DD/YYYY hh:mm A"
              )}
          </div>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default withFirebase(RecommendedDeals);
