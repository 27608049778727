import React from "react";
import DealListing from "./DealListing";
import LoadingPlaceholder from "../LoadingPlaceholder";

import "./DealsList.scss";

const DealsList = (props) => {
  const { deals, loading, loadingPlaceholderCount, dealSize } = props;

  let height, width, margin;
  if (dealSize === "small") {
    height = 195;
    width = 195;
    margin = 15;
  } else if (dealSize === "medium") {
    height = 225;
    width = 250;
    margin = 15;
  } else if (dealSize === "feed") {
    width = "100%";
    height = 500;
    margin = "15px 0";
  } else {
    height = 390;
    width = 360;
    margin = 15;
  }

  const loadingPlaceholders = [];
  for (let i = 0; i < loadingPlaceholderCount; i++) {
    loadingPlaceholders.push(
      <LoadingPlaceholder key={i} style={{ height, width, margin }} />
    );
  }

  return (
    <div className="component-deals-list">
      {loading
        ? loadingPlaceholders
        : deals &&
          deals.map((deal) => {
            return <DealListing key={deal.key} deal={deal} size={dealSize} />;
          })}
    </div>
  );
};

export default DealsList;
