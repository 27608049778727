import React from 'react';
import SelectableListItem from './SelectableListItem';

function SelectableList({ items, displayField, valueField, selectedValues, onItemToggle }) {

  return (
    <div className='component-selectable-list'>
      {items.map(item => (
        <SelectableListItem
          item={item}
          displayField={displayField}
          valueField={valueField}
          onToggle={onItemToggle}
          selected={selectedValues && selectedValues.includes(item[valueField])}
        />
      ))}
    </div>
  );
}

export default SelectableList;