import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../../containers/Firebase";

//components
import { FlexContainer, GLOBALS } from "../../../../../assets/fansaves-ui";

import DashboardSpecialCodesShowContainer from "../../../../../containers/DashboardSpecialCodesShowContainer";

import useTeamsStore from "../../../../../stores/teamsStore";
import useSpecialCodesStore from "../../../../../stores/specialCodesStore";

const SpecialCodeShowContainer = styled.div`
  .header-title {
    position: relative;
    .link-container {
      position: absolute;
      top: 26px;
      right: 10px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .link-container {
        position: relative;
        margin-left: 30px;
      }
    }
  }
`;

const FlexPassCodeShow = ({ match, firebase }) => {
  const teamId = match.params.team_id;
  const codeId = match.params.flex_pass_code_id;

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);

  const useSpecialCode = useSpecialCodesStore((state) => state.useSpecialCode);

  const { data: specialCode } = useSpecialCode(firebase, teamId, codeId);

  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    (!team || team.key !== teamId) && fetchTeam(firebase, teamId);
  }, [team, teamId, fetchTeam, firebase]);

  return (
    <SpecialCodeShowContainer animated fadeIn>
      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="exclusive-code-header"
        margin="30px 0"
      >
        <DashboardSpecialCodesShowContainer
          specialCode={specialCode}
          team={team}
          teamId={teamId}
        />
      </FlexContainer>
    </SpecialCodeShowContainer>
  );
};

export default withRouter(withFirebase(FlexPassCodeShow));
