import React from 'react';

const EmbedModeContext = React.createContext();

export const withEmbedMode = Component => props => (
  <EmbedModeContext.Consumer>
    {embedMode => <Component {...props} embedMode={embedMode} />}
  </EmbedModeContext.Consumer>
);
  
export default EmbedModeContext;