import React, { useState } from "react";
import propTypes from "prop-types";
import {
  DashboardCard,
  FlexContainer,
  DashboardTitle,
  DashboardFilter,
} from "../../assets/fansaves-ui";

import { Icon } from "@iconify/react";

import "./DashboardChartContainer.scss";

const DashboardChartContainer = ({
  title,
  renderChart,
  onSelectFilter,
  onFilterDaysAdjustment,
  filterDaysAdjustment,
}) => {
  const handleAdjustedDaysButtonClick = (direction) => {
    if (
      onFilterDaysAdjustment &&
      typeof onFilterDaysAdjustment === "function"
    ) {
      if (direction === "previous") {
        onFilterDaysAdjustment(filterDaysAdjustment + 7);
      } else if (direction === "next" && filterDaysAdjustment > 0) {
        onFilterDaysAdjustment(filterDaysAdjustment - 7);
      }
    }
  };

  return (
    <FlexContainer
      className="dashboard-chart-container"
      direction=" vertical"
      alignItems="center"
      justifyContent="flex-start"
      gap="20px"
    >
      <DashboardTitle title={title} className="dashboard-chart-title" />
      <DashboardFilter
        type="daysChart"
        className="component-dashboard-filter inter-b2"
        onSelect={onSelectFilter}
        onFilterAdjustment={filterDaysAdjustment}
      />
      <DashboardCard
        className="dashboard-chart-card"
        width="100%"
        height="350px"
      >
        {renderChart()}
      </DashboardCard>
      <FlexContainer
        className="dashboard-impressions-chart-actions"
        direction="horizontal"
        gap="5px"
        alignItems="center"
        justifyContent="flex-end"
      >
        <div
          onClick={() => handleAdjustedDaysButtonClick("previous")}
          className="dashboard-impressions-chart-action"
        >
          <Icon icon="mdi-chevron-left" className="icon" />{" "}
          <div className="dashboard-impressions-chart-link-text inter-b2">
            View Previous 7 Days
          </div>
        </div>
        {filterDaysAdjustment > 0 && (
          <div
            onClick={() => handleAdjustedDaysButtonClick("next")}
            className="dashboard-impressions-chart-action"
          >
            <div className="dashboard-impressions-chart-link-text inter-b2">
              View Next 7 Days
            </div>
            <Icon icon="mdi-chevron-right" className="icon" />
          </div>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

DashboardChartContainer.propTypes = {
  title: propTypes.string,
  renderChart: propTypes.func,
  onSelectFilter: propTypes.func,
  onFilterDaysAdjustment: propTypes.func,
  filterDaysAdjustment: propTypes.number,
};

DashboardChartContainer.defaultProps = {
  title: "",
  renderChart: () => {},
  onSelectFilter: () => {},
  onFilterDaysAdjustment: () => {},
  filterDaysAdjustment: 0,
};

export default DashboardChartContainer;
