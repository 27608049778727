const messages = {
  en: {
    "app.fans": "Fans",
    "app.deal.redeem": "Redeem",
    "app.team.following": "Following",
    "app.team.follow": "Follow",
    "app.deal.triggered": "Triggered",
    "app.deal.not.triggered": "Not Triggered",
    "app.deal.exclusive": "Exclusive Deal",
    "app.deal.flash": "Flash Deal",
    "app.deal.contest": "Contest",
    "app.category.baseball": "Baseball",
    "app.category.basketball": "Basketball",
    "app.category.volleyball": "Volleyball",
    "app.category.hockey": "Hockey",
    "app.category.bias": "BIAs",
    "app.category.allFanPages": "All FanPages",
    "app.signout": "Sign Out",
    "app.redeemDealModal.messageWithCode":
      "Copy the code below then <a>click here</a> to {action}!",
    "app.redeemDealModal.messageWithoutCode": "<a>Click Here</a> to {action}!",
    "app.redeemDealModal.toViewDeal": "View",
    "app.redeemDealModal.toRedeemDeal": "redeem your deal",
    "app.deal.view": "View",
  },
  fr: {
    "app.fans": "Supporters",
    "app.deal.redeem": "Réclamer",
    "app.team.following": "Suivi(e)",
    "app.team.follow": "Suivre",
    "app.deal.triggered": "Déclenché",
    "app.deal.not.triggered": "Pas Déclenché",
    "app.deal.exclusive": "Offre Exclusive",
    "app.deal.flash": "Offre Éclair",
    "app.deal.contest": "Concours",
    "app.category.baseball": "Baseball",
    "app.category.basketball": "Basketball",
    "app.category.hockey": "Hockey",
    "app.category.volleyball": "Volleyball",
    "app.category.bias": "BIAs",
    "app.category.allFanPages": "Tous les FanPages",
    "app.signout": "Déconnexion",
    "app.redeemDealModal.messageWithCode":
      "Copiez le code ci-dessous puis <a>cliquez ici</a> pour {action}!",
    "app.redeemDealModal.messageWithoutCode":
      "<a>Cliquez Ici</a> pour {action}!",
    "app.redeemDealModal.toViewDeal": "Voir",
    "app.redeemDealModal.toRedeemDeal": "réclamer votre offre",
    "app.deal.view": "Voir",
  },
};

export default messages;
