import cloudFunctionHost from "../containers/CloudFunctionsHost";

function updateUserDataAfterLoginOrSignUp(firebase, user, onSuccess, onError) {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      email: user.email,
      createdAt: user.metadata.creationTime,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/updateUserDataAfterLoginOrSignUp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => {
        if (response.ok) {
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess();
          }
        } else {
          if (onError && typeof onError === "function") {
            onError();
          }
        }
      })
      .catch((error) => {
        console.error(
          "Error updating user data after login or sign up:",
          error
        );
        onError();
      });
  });
}

function recommendedOffer(firebase, onSuccess, onError) {
  if (!firebase.auth.currentUser) return;
  firebase.auth.currentUser.getIdToken().then((token) => {
    fetch(`${cloudFunctionHost}/authorizedFunctions/recommendedOffer`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess(response);
          }
        } else {
          if (onError && typeof onError === "function") {
            onError();
          }
        }
      })
      .catch((error) => {
        onError();
      });
  });
}

const fetchDealsNearby = (firebase, location, onSuccess, onError) => {
  if (!firebase.auth.currentUser) return;
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      location,
    };

    fetch(`${cloudFunctionHost}/authorizedFunctions/dealsNearby`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          if (onSuccess && typeof onSuccess === "function") {
            response.json().then((data) => {
              console.log({ data });
              onSuccess(data);
            });
          }
        } else {
          if (onError && typeof onError === "function") {
            onError();
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching deals nearby:", error);
        onError();
      });
  });
};

const unlockedExclusives = (authUser, teamKey) => {
  if (
    authUser &&
    authUser.unlockedExclusivesTeams &&
    Object.keys(authUser.unlockedExclusivesTeams).includes(teamKey)
  ) {
    return true;
  }
  return false;
};

const checkAndSuggestUsernames = (firebase, userName, onSuccess, onError) => {
  if (!firebase.auth.currentUser) return;
  firebase.auth.currentUser.getIdToken().then((token) => {
    fetch(`${cloudFunctionHost}/authorizedFunctions/checkAndSuggestUsernames`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userName }),
    })
      .then((response) => {
        if (response.ok) {
          if (onSuccess && typeof onSuccess === "function") {
            response.json().then((data) => {
              onSuccess(data);
            });
          }
        } else {
          if (onError && typeof onError === "function") {
            onError();
          }
        }
      })
      .catch((error) => {
        onError();
      });
  });
};

export {
  updateUserDataAfterLoginOrSignUp,
  recommendedOffer,
  unlockedExclusives,
  checkAndSuggestUsernames,
  fetchDealsNearby,
};
