import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import {
  COLORS,
  FlexContainer,
  CSVExport,
  DashboardButton,
} from "../../assets/fansaves-ui";
import { Icon } from "@iconify/react";
import ModalContainer from "../Modals/Modal";

const CodesBox = styled(FlexContainer)`
  border: 1px solid ${COLORS.primaryGreen};
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 10px;
  height: 165px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 20px;
  }
  &::-webkit-scrollbar-track {
    background: ${COLORS.primaryWhite};
    border: 1px solid ${COLORS.primaryBlue};
    border-radius: 10px;
    margin: 10px 0;
  }
  &::-webkit-scrollbar-thumb {
    background: ${COLORS.primaryBlue};
    height: 20px;
    border-radius: 10px;
  }
  å &::-webkit-scrollbar-thumb:hover {
    background: ${COLORS.primaryBlue};
  }
`;

const UploadCodesContainer = styled(FlexContainer)`
  border: 2px dashed ${COLORS.primaryBlue};
  align-items: center;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
`;

const AddExclusiveCodesModal = ({
  onClose,
  onUploadFile,
  codes,
  onSubmit,
  onCancel,
  errors,
  isSubmitting,
  title,
}) => {
  return (
    <ModalContainer
      onClose={() => onClose(false)}
      modalDialogClassName="add-code-modal-component"
    >
      {!codes || (codes && codes.length < 1) ? (
        <>
          {errors && errors.length > 0 ? (
            <>
              <FlexContainer
                className="add-code-error-container"
                gap="30px"
                alighItems="flex-start"
                justifyContent="center"
              >
                <div className="add-code-error">
                  <Icon icon="fa-solid:exclamation-triangle" />
                  <div className="add-code-error-text">
                    {errors.map((error, index) => (
                      <div key={index}>{error.message}</div>
                    ))}
                  </div>
                </div>
              </FlexContainer>
              <FlexContainer
                className="add-code-modal-footer"
                direction="horizontal"
                gap="20px"
              >
                <DashboardButton
                  onClick={() => onCancel()}
                  title="Cancel"
                  size="small"
                />
              </FlexContainer>
            </>
          ) : (
            <FlexContainer
              gap="30px"
              alighItems="flex-start"
              justifyContent="center"
            >
              <div className="add-code-modal-title rubik-h3">
                Add {title ? title : "Exclusive Codes"}
              </div>
              <FlexContainer
                className="add-code-modal-content"
                gap="30px"
                alighItems="center"
                justifyContent="center"
              >
                <FlexContainer
                  className="upload-csv-container"
                  alighItems="center"
                  justifyContent="center"
                >
                  <label htmlFor="upload-csv" className="upload-csv-upload">
                    <UploadCodesContainer direction="horizontal" gap="10px">
                      <Icon
                        icon="ic:sharp-cloud-upload"
                        style={{ fontSize: 25 }}
                      />
                      <span className="upload-csv-button-text inter-b1 underline">
                        Upload Codes
                      </span>
                    </UploadCodesContainer>
                    <input
                      type="file"
                      className="upload-csv-input"
                      id="upload-csv"
                      accept=".csv"
                      onChange={(e) => onUploadFile(e.target.files[0])}
                      hidden
                    />
                  </label>
                  <div className="csv-template-container">
                    <CSVExport
                      data={[]}
                      headers={[
                        { label: "#", key: "index" },
                        { label: "code", key: "code" },
                      ]}
                      filename="exclusive-codes-template"
                      className="download-csv-button inter-b2 underline"
                    >
                      Download CSV Template
                    </CSVExport>
                  </div>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          )}
        </>
      ) : (
        <FlexContainer
          gap="30px"
          alighItems="flex-start"
          justifyContent="center"
        >
          <div className="add-code-modal-title rubik-h3">
            Confirm Your Codes
          </div>
          <FlexContainer
            className="add-code-modal-content"
            direction="horizontal"
            gap="10px"
          >
            <Icon icon="fa-solid:exclamation-circle" />
            Confirm your codes before submitting
          </FlexContainer>
          <CodesBox className="codes-container">
            {codes.map((code, index) => (
              <div className="code-container" key={index}>
                <div className="code">{code}</div>
              </div>
            ))}
          </CodesBox>
          <FlexContainer
            className="add-code-modal-footer"
            direction="horizontal"
            gap="20px"
          >
            <DashboardButton
              onClick={() => onCancel()}
              title="Cancel"
              size="small"
            />
            <DashboardButton
              onClick={() => onSubmit()}
              type="green"
              title={isSubmitting ? "Submitting..." : "Submit"}
              size="medium"
            />
          </FlexContainer>
        </FlexContainer>
      )}
    </ModalContainer>
  );
};

AddExclusiveCodesModal.propTypes = {
  onClose: propTypes.func,
  onUploadFile: propTypes.func,
  codes: propTypes.array,
  onSubmit: propTypes.func,
  onCancel: propTypes.func,
  errors: propTypes.array,
};

AddExclusiveCodesModal.defaultProps = {
  onClose: () => {},
  onUploadFile: () => {},
  codes: null,
  onSubmit: () => {},
  onCancel: () => {},
  errors: null,
};

export default AddExclusiveCodesModal;
