import React, { useEffect, useState, useRef } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";

import "../../../scss/base/typography.scss";
import { COLORS } from "../colors";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .dropdown-control {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    svg.icon {
      font-size: 20px;
    }
  }
  .options-container {
    width: fit-content;
    height: auto;
    border-radius: 10px;
    background-color: ${COLORS.primaryWhite};
    box-shadow: 4px 4px 4px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  .label-text {
    margin-left: 20px;
  }
`;

const Item = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 5%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${COLORS.primaryWhite};
  border: 1px solid ${COLORS.primaryBlue};
`;
const RadioButton = styled.input`
  position: absolute;
  top: 5%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: ${COLORS.primaryBlueHover};
  }
  &:checked + ${Item} {
    background: ${COLORS.primaryBlue};
  }
  &:checked + ${RadioButtonLabel} {
    background: ${COLORS.primaryBlue};
  }
`;

const DashboardFilter = ({
  type,
  className,
  onSelect,
  onFilterAdjustment,
  initialFilterOption,
}) => {
  const daysOptions = [
    {
      value: "7days",
      label: "7 days",
    },
    {
      value: "30days",
      label: "30 days",
    },
    {
      value: "90days",
      label: "90 days",
    },
    {
      value: "ThisYear",
      label: "This year",
    },
    {
      value: "LastYear",
      label: "Last year",
    },
    {
      value: "All",
      label: "All",
    },
    /* {
      value: "custom",
      label: "Custom",
    }, */
  ];

  const daysChartOptions = [
    {
      value: "7days",
      label: "7 days",
    },
    {
      value: "30days",
      label: "30 days",
    },
    {
      value: "90days",
      label: "90 days",
    },
    {
      value: "ThisYear",
      label: "This year",
    },
    {
      value: "LastYear",
      label: "Last year",
    },
    /* {
      value: "custom",
      label: "Custom",
    }, */
  ];

  const textOptions = [
    {
      value: "Weekly",
      label: "Weekly",
    },
    {
      value: "Monthly",
      label: "Monthly",
    },
    {
      value: "Quartely",
      label: "Quartely",
    },
    {
      value: "Annualy",
      label: "Annualy",
    },
    {
      value: "Life-Time",
      label: "Life-Time",
    },
    /* {
      value: "custom",
      label: "Custom",
    }, */
  ];

  const dealOptions = [
    {
      value: "NewestToOldest",
      label: "Newest to Oldest",
    },
    {
      value: "OldestToNewest",
      label: "Oldest to Newest",
    },
    {
      value: "OnlineDeals",
      label: "Online Deals",
    },
    {
      value: "InStoreDeals",
      label: "In-Store Deals",
    },
    {
      value: "ExclusiveDeals",
      label: "Exclusive Deals",
    },
    {
      value: "FlashDeals",
      label: "Flash Deals",
    },
    {
      value: "TriggeredDeals",
      label: "Triggered Deals",
    },
    {
      value: "ContestDeals",
      label: "Contests",
    },
    {
      value: "ArchivedDeals",
      label: "Archived Deals",
    },
  ];

  const businessOptions = [
    {
      value: "Alphabetical",
      label: "Alphabetical",
    },
    {
      value: "NewestToOldest",
      label: "Newest to Oldest",
    },
    {
      value: "OldestToNewest",
      label: "Oldest to Newest",
    },
  ];

  const teamOptions = [
    {
      value: "Alphabetical",
      label: "Alphabetical",
    },
    {
      value: "NewestToOldest",
      label: "Newest to Oldest",
    },
    {
      value: "OldestToNewest",
      label: "Oldest to Newest",
    },
    {
      value: "SportsTeams",
      label: "Sports & E-Sports Teams",
    },
    {
      value: "Communities",
      label: "Chambers, BIAs, & Tourism",
    },
    {
      value: "MediaInfluencers",
      label: "Media & Influencers",
    },
    {
      value: "UniversitiesColleges",
      label: "Universities & Colleges",
    },
    {
      value: "Events",
      label: "Events & Festivals",
    },
    {
      value: "Charities",
      label: "Non-Profits",
    },
  ];

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const node = useRef();

  const handleSelectOption = (e) => {
    if (onSelect && typeof onSelect === "function") {
      onSelect(e.target.value);
    }
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    let optionsArray = [];
    switch (type) {
      case "days":
        optionsArray = [...daysOptions];
        break;
      case "deals":
        optionsArray = [...dealOptions];
        break;
      case "business":
        optionsArray = [...businessOptions];
        break;
      case "team":
        optionsArray = [...teamOptions];
        break;
      case "daysChart":
        optionsArray = [...daysChartOptions];
        break;
      default:
        optionsArray = [...textOptions];
    }

    setOptions(optionsArray);
  }, []);

  useEffect(() => {
    if (options && options.length > 0) {
      if (initialFilterOption) {
        setSelectedOption(
          options.find((option) => option.value === initialFilterOption).value
        );
      } else {
        setSelectedOption(options[0].value);
      }
    }
  }, [options]);

  useEffect(() => {
    if (onFilterAdjustment) {
      setDropdownOpen(false);
      setSelectedOption(
        type === "days" ? daysOptions[0].value : textOptions[0].value
      );
    }
  }, [onFilterAdjustment]);

  const handleClickOutside = (e) => {
    if (node && node.current.contains(e.target)) {
      return;
    }
    setDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper className={className} ref={node}>
      <div
        className="dropdown-control"
        onClick={(e) => {
          e.preventDefault();
          setDropdownOpen(!dropdownOpen);
        }}
      >
        Filter By
        {dropdownOpen ? (
          <Icon icon="mdi-chevron-up" className="icon" />
        ) : (
          <Icon icon="mdi-chevron-down" className="icon" />
        )}
      </div>
      {dropdownOpen && (
        <div className="options-container">
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <Item key={option.value}>
                <RadioButton
                  type="radio"
                  name="radio"
                  value={option.value}
                  onChange={(e) => handleSelectOption(e)}
                  checked={option.value === selectedOption}
                />
                <RadioButtonLabel />
                <div className="label-text">{option.label}</div>
              </Item>
            ))}
        </div>
      )}
    </Wrapper>
  );
};

export default DashboardFilter;

DashboardFilter.propTypes = {
  className: propTypes.string,
  type: propTypes.string,
  onSelect: propTypes.func,
  onFilterAdjustment: propTypes.number,
  initialFilterOption: propTypes.string,
};
