/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { withFirebase } from "../../../../containers/Firebase";
import { withAuthentication } from "../../../../containers/Session";
import { withRouter } from "react-router-dom";
import { DashboardHeader, DashboardStats } from "../../../../containers/UIKit";
import DashboardDealList from "../../../../containers/DashboardDealList";
import DashboardChartContainer from "../../../../containers/DashboardChartContainer";
import DashboardRedemptionAnalytics from "../../../../containers/DashboardRedemptionAnalytics";
import DashboardRedemptionsTable from "../../../../containers/DashboardRedemptionsTable";
import DashboardBusinessesList from "../../../../containers/DashboardBusinessesList";
import { Tooltip } from "reactstrap";
import moment from "moment-timezone";
import { isAdmin, isTeam } from "../../../../containers/Session";

import {
  getRedemptionChartData,
  getImpressionsChartData,
  mapUserDataforCharts,
} from "../../../../utilities/dashboard_helpers";

import {
  LineChart,
  FlexContainer,
  DashboardButton,
  ModalDialog,
  COLORS,
  SpinLoader,
} from "../../../../assets/fansaves-ui";

import "../../../../scss/base/typography.scss";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useSponsorsStore from "../../../../stores/sponsorsStore";
import useOffersStore from "../../../../stores/offersStore";
import useTeamsStore from "../../../../stores/teamsStore";
import useExclusiveCodesStore from "../../../../stores/exclusiveCodesStore";

const EmbedFlexContainter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .instructions {
    margin: 5px 0;
  }

  .code {
    box-shadow: inset 0px 0px 3px ${COLORS.greyText};
    font-family: "Courier", monospace;
    overflow: scroll;
    text-align: left;
    padding: 10px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 0;
    align-self: flex-end;
    gap: 10px;
  }
`;

const PortalTeamDashboard = ({
  team: initialTeam,
  firebase,
  history,
  match,
  authUser,
}) => {
  const teamId = match.params.team_id;
  const linkRef = useRef(null);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const fetchRedemptionsByChunks = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );
  const isRedemptionsLoading = useRedemptionsStore((state) => state.isLoading);

  const fetchTeamSponsors = useSponsorsStore(
    (state) => state.fetchTeamSponsors
  );
  const sponsorsFromStore = useSponsorsStore((state) => state.teamSponsors);
  const redemptions = useRedemptionsStore((state) => state.redemptions);

  const teamOffersFromStore = useOffersStore((state) => state.teamOffers);
  const offers = useOffersStore((state) => state.offersArray);
  const fetchOffers = useOffersStore((state) => state.fetchOffers);
  const fetchTeamOffers = useOffersStore((state) => state.fetchTeamOffers);
  const impressions = useOffersStore((state) => state.teamImpressions);
  const setTeamImpressions = useOffersStore(
    (state) => state.setTeamImpressions
  );

  const fetchExclusiveCodesTotal = useExclusiveCodesStore(
    (state) => state.fetchExclusiveCodesTotal
  );

  const exclusiveCodesTotal = useExclusiveCodesStore(
    (state) => state.exclusiveCodesTotal
  );

  const [team, setTeam] = useState(null);

  const [sponsors, setSponsors] = useState(null);

  const [activeDeals, setActiveDeals] = useState(null);
  const [archivedDeals, setArchivedDeals] = useState(null);
  const [flashDeals, setFlashDeals] = useState(null);
  const [triggeredDeals, setTriggeredDeals] = useState(null);
  const [contestDeals, setContestDeals] = useState(null);
  const [specialDeals, setSpecialDeals] = useState(null);
  const [users, setUsers] = useState(null);
  const [teamOffers, setTeamOffers] = useState(null);
  const [followers, setFollowers] = useState(null);
  const [redemptionsFromEvents, setRedemptionsFromEvents] = useState(null);
  const [contestEntries, setContestEntries] = useState(null);
  const [
    redemptionsWithoutContestEntries,
    setRedemptionsWithoutContestEntries,
  ] = useState(null);
  const [genderChartData, setGenderChartData] = useState(null);
  const [ageChartData, setAgeChartData] = useState(null);
  const [impressionsChartData, setImpressionsChartData] = useState(null);
  const [redemptionsChartData, setRedemptionsChartData] = useState(null);
  const [locationChartData, setLocationChartData] = useState(null);
  const [selectedLocationChartData, setSelectedLocationChartData] =
    useState(null);
  const [selectedRedemtionFilter, setSelectedRedemtionFilter] =
    useState("7days");
  const [selectedImpressionFilter, setSelectedImpressionFilter] =
    useState("7days");
  const [impressionsFilterDaysAdjustment, setImpressionsFilterDaysAdjustment] =
    useState(0);
  const [redemptionsFilterDaysAdjustment, setRedemptionsFilterDaysAdjustment] =
    useState(0);
  const [heatMapData, setHeatMapData] = useState(null);
  const [embedWidgetOpen, setEmbedWidgetOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if (!team || (team && team.key !== teamId)) {
      fetchTeam(firebase, teamId);
      fetchExclusiveCodesTotal(firebase, teamId);
    }
  }, [fetchTeam, teamId]);

  useEffect(() => {
    if (!exclusiveCodesTotal || !exclusiveCodesTotal[teamId]) {
      fetchExclusiveCodesTotal(firebase, teamId);
    }
  }, [fetchExclusiveCodesTotal, firebase, teamId]);

  useEffect(() => {
    if (team) {
      setUsers(team.users);
    }
  }, [team]);

  useEffect(() => {
    if (sponsorsFromStore && sponsorsFromStore[teamId]) {
      setSponsors(sponsorsFromStore[teamId]);
    }
  }, [sponsorsFromStore, teamId]);

  useEffect(() => {
    if (!redemptions) {
      if (isAdmin(authUser)) {
        fetchRedemptionsByChunks(firebase);
      } else {
        fetchRedemptions(firebase);
      }
    }
  }, [fetchRedemptions, firebase, fetchRedemptionsByChunks, authUser]);

  useEffect(() => {
    if (!sponsorsFromStore || !sponsorsFromStore[teamId]) {
      fetchTeamSponsors(firebase, teamId);
    }
  }, [fetchTeamSponsors, teamId]);

  useEffect(() => {
    if (isAdmin(authUser)) {
      fetchOffers(firebase);
    } else if (isTeam(authUser)) {
      fetchTeamOffers(firebase, teamId);
    }
  }, [fetchTeamOffers, fetchOffers, firebase, teamId, authUser]);

  useEffect(() => {
    if (isAdmin(authUser) && offers) {
      setTeamOffers(
        offers.filter((offer) => offer.teams && offer.teams.includes(teamId))
      );
    } else if (isTeam(authUser) && teamOffersFromStore) {
      setTeamOffers(teamOffersFromStore);
    }
  }, [offers, teamId, teamOffersFromStore, authUser]);

  useEffect(() => {
    if (!isRedemptionsLoading && redemptions) {
      const filteredRedemptions = redemptions.filter(
        (redemption) => redemption.teamID === teamId
      );
      setRedemptionsFromEvents(filteredRedemptions);

      const filteredContestEntries = filteredRedemptions.filter(
        (redemption) => redemption.transactionType === "contest"
      );
      setContestEntries(filteredContestEntries);

      const filteredRedemptionsWithoutContestEntries =
        filteredRedemptions.filter(
          (redemption) => redemption.transactionType === "redemption"
        );
      setRedemptionsWithoutContestEntries(
        filteredRedemptionsWithoutContestEntries
      );
    }
  }, [match.teamId, redemptions, teamId, isRedemptionsLoading]);

  const getTeamImpressions = () => {
    const impressions = [];
    const teamOffersMap = teamOffers.reduce((map, obj) => {
      map[obj.key] = obj;
      return map;
    }, {});

    if (sponsors && sponsors.length > 0 && teamOffersMap) {
      sponsors.forEach((sponsor) => {
        if (sponsor.offers && sponsor.offers.length > 0) {
          sponsor.offers.forEach((offer) => {
            if (!offer) return;
            const offerData = teamOffersMap[offer.key];
            if (offerData && offerData.impressions) {
              impressions.push(Object.values(offerData.impressions));
            }
          });
        }
      });
    }

    const flattenedImpressions = [].concat.apply([], impressions);
    setTeamImpressions(flattenedImpressions);
  };

  useEffect(() => {
    if (teamOffers && sponsors) {
      getTeamImpressions();
    }
  }, [teamOffers, sponsors]);

  const getTeamFollowers = () => {
    const teamFollowers = [];
    if (team.followers) {
      Object.entries(team.followers).forEach(([key, value]) => {
        let localTime;
        if (value) {
          localTime = moment.utc(value).tz("Canada/Eastern");
        }
        const user = users.find((user) => user.key === key);
        if (user) {
          let lastRedemption;
          teamOffers.forEach((offer) => {
            if (user.redeemed) {
              Object.entries(user.redeemed).forEach(([dealKey, date]) => {
                if (dealKey === offer.key) {
                  if (!lastRedemption) {
                    lastRedemption = date;
                  } else {
                    if (moment(date).isAfter(lastRedemption)) {
                      lastRedemption = date;
                    }
                  }
                }
              });
            }
          });
          teamFollowers.push({
            ...user,
            lastRedemption: lastRedemption,
            followedAt: localTime,
          });
        }
      });
    }

    teamFollowers.sort((a, b) => {
      return moment(b.followedAt).diff(moment(a.followedAt));
    });

    setFollowers(teamFollowers);
  };

  useEffect(() => {
    if (team && users && teamOffers) {
      getTeamFollowers();
    }
  }, [team, users, teamOffers]);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        margin="30px auto"
        height="100px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };

  const getStats = () => {
    let stats = [
      {
        label: "Impressions",
        value: impressions
          ? impressions.length > 0
            ? impressions.length
            : 0
          : renderLoading(),
      },
      {
        label: "Redemptions",
        value: redemptionsWithoutContestEntries
          ? redemptionsWithoutContestEntries.length > 0
            ? redemptionsWithoutContestEntries.length
            : 0
          : renderLoading(),
      },
      {
        label: "Contest Entries",
        value: contestEntries
          ? contestEntries.length > 0
            ? contestEntries.length
            : 0
          : renderLoading(),
      },
      {
        label: "All Deals",
        value: teamOffers
          ? teamOffers.length > 0
            ? teamOffers.length
            : 0
          : renderLoading(),
      },
      {
        label: "Businesses",
        value: team.sponsors
          ? team.sponsors.length > 0
            ? team.sponsors.length
            : 0
          : renderLoading(),
      },
    ];

    return stats;
  };

  const mapData = () => {
    if (teamOffers) {
      setTriggeredDeals(
        teamOffers
          .filter((offer) => offer.triggered && offer.published)
          .sort((a, b) => b.publishedAt - a.publishedAt)
      );
      setFlashDeals(
        teamOffers
          .filter((offer) => offer.flashDeal && offer.published)
          .sort((a, b) => b.publishedAt - a.publishedAt)
      );
      setActiveDeals(
        teamOffers
          .filter((offer) => offer.published && !offer.triggered)
          .sort((a, b) => b.publishedAt - a.publishedAt)
      );
      setArchivedDeals(teamOffers.filter((offer) => !offer.published));
      setContestDeals(
        teamOffers
          .filter((offer) => offer.contestDeal && offer.published)
          .sort((a, b) => b.publishedAt - a.publishedAt)
      );
      setSpecialDeals(
        teamOffers
          .filter((offer) => offer.hiddenDeal && offer.published)
          .sort((a, b) => b.publishedAt - a.publishedAt)
      );
    }
  };

  const mapUserData = () => {
    if (!isRedemptionsLoading && redemptionsFromEvents) {
      const { genderData, ageData, userLocationMap, userStateMap } =
        mapUserDataforCharts(redemptionsFromEvents);

      let selectedUserLocationMap = {};

      setGenderChartData(genderData);
      setAgeChartData(ageData);

      if (Object.keys(userLocationMap).length > 5) {
        selectedUserLocationMap = Object.keys(userLocationMap)
          .slice(0, 6)
          .reduce((obj, key) => {
            obj[key] = userLocationMap[key];
            return obj;
          }, {});
      }

      setHeatMapData(userStateMap);
      setSelectedLocationChartData(selectedUserLocationMap);
      setLocationChartData(userLocationMap);
    }
  };

  const updateRedemptionsChartData = () => {
    if (!isRedemptionsLoading && redemptionsFromEvents) {
      const redemptionsData = getRedemptionChartData(
        redemptionsFromEvents,
        selectedRedemtionFilter,
        redemptionsFilterDaysAdjustment
      );

      setRedemptionsChartData(redemptionsData);
    }
  };

  const updateImpressionsChartData = () => {
    if (impressions) {
      const impressionsData = getImpressionsChartData(
        impressions,
        selectedImpressionFilter,
        impressionsFilterDaysAdjustment
      );

      setImpressionsChartData(impressionsData);
    }
  };

  const handleCopyLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(linkRef.current.innerText);
    setTooltipOpen(true);
    setInterval(() => {
      setTooltipOpen(false);
    }, 3000);
  };

  useEffect(() => {
    mapData();
  }, [teamOffers, users]);

  useEffect(() => {
    mapUserData();
  }, [redemptionsFromEvents, users, isRedemptionsLoading]);

  useEffect(() => {
    if (!isRedemptionsLoading && redemptionsFromEvents) {
      updateRedemptionsChartData();
    }
  }, [
    redemptionsFromEvents,
    selectedRedemtionFilter,
    redemptionsFilterDaysAdjustment,
    isRedemptionsLoading,
  ]);

  useEffect(() => {
    if (impressions) {
      updateImpressionsChartData();
    }
  }, [impressions, selectedImpressionFilter, impressionsFilterDaysAdjustment]);

  useEffect(() => {
    if (selectedRedemtionFilter) {
      setRedemptionsFilterDaysAdjustment(0);
    }
    if (selectedImpressionFilter) {
      setImpressionsFilterDaysAdjustment(0);
    }
  }, [selectedRedemtionFilter, selectedImpressionFilter]);

  useEffect(() => {
    if (redemptionsFilterDaysAdjustment > 0) {
      setSelectedRedemtionFilter("7days");
    }
    if (impressionsFilterDaysAdjustment > 0) {
      setSelectedImpressionFilter("7days");
    }
  }, [redemptionsFilterDaysAdjustment, impressionsFilterDaysAdjustment]);

  if (!team)
    return (
      <FlexContainer
        className="component-portal-sponsor-dashboard animated fadeIn spin-loader-container"
        direction="horizontal"
        justifyContent="center"
        alignItems="center"
        margin="30px auto"
      >
        <SpinLoader />
      </FlexContainer>
    );

  return (
    <div className="component-portal-team-dashboard animated fadeIn" id="main">
      {team && (
        <DashboardHeader
          logo={team.logo}
          name={team.name}
          email={team.email}
          description={team.description}
          profileUrl={team.website}
          follows={followers ? followers.length : ""}
          followsUrl={`/portal/teams/${teamId}/followers`}
          exlusiveCodesUrl={`/portal/teams/${teamId}/special-offers-codes`}
          exclusiveCodes={
            exclusiveCodesTotal &&
            exclusiveCodesTotal[teamId] &&
            exclusiveCodesTotal[teamId].total
              ? exclusiveCodesTotal[teamId].total
              : 0
          }
          hideEl
        />
      )}
      <DashboardStats stats={getStats()} />
      <FlexContainer
        className="action-buttons"
        direction="horizontal"
        justifyContent="space-between"
        gap="20px"
        margin="50px auto"
        responsive
      >
        {/* <DashboardButton
              title="Assets"
              icon="bxs:folder"
              href={`/portal/teams/${teamId}/assets`}
            /> */}
        <DashboardButton
          title="Payments"
          icon="fa-solid:dollar-sign"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/teams/${teamId}/payments-setup`);
          }}
        />
        <DashboardButton
          title="Purchases"
          icon="fa-solid:receipt"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/teams/${teamId}/purchases`);
          }}
        />
        <EmbedFlexContainter className="embed-button">
          <DashboardButton
            title="Embed Code"
            icon="fa-solid:code"
            onClick={() => setEmbedWidgetOpen(!embedWidgetOpen)}
          />
          {embedWidgetOpen && (
            <ModalDialog
              modalDialogClassName="embed-dashboard-modal"
              position="absolute"
              width="250%"
              right="0"
              top="60px"
              onClick={() => setEmbedWidgetOpen(!embedWidgetOpen)}
            >
              <EmbedFlexContainter className="embed-dashboard-modal-content">
                <div className="embed-header mobile-b2">
                  Embed Team page into your own site!
                </div>
                <div className="instructions mobile-b3">
                  To embed the page, simply copy and paste the following code
                  into your site
                </div>
                <div className="code" ref={linkRef}>
                  &lt;iframe src="
                  {`${window.location.origin}/organizations/${teamId}?embedMode=true`}
                  " width="100%" height="1330" style="border:none;"&gt;
                  <br />
                  &lt;/iframe&gt;
                </div>
                <div className="actions">
                  <i
                    className="fa fa-copy"
                    id="online-code-icon"
                    onClick={(e) => handleCopyLink(e)}
                    style={{ cursor: "pointer" }}
                  ></i>
                  <Tooltip
                    placement="bottom"
                    target="online-code-icon"
                    isOpen={tooltipOpen}
                    onClick={() => setTooltipOpen(!tooltipOpen)}
                  >
                    Copied!
                  </Tooltip>
                  <DashboardButton
                    onClick={() => setEmbedWidgetOpen(false)}
                    title="Done"
                    size="small"
                  />
                </div>
              </EmbedFlexContainter>
            </ModalDialog>
          )}
        </EmbedFlexContainter>
      </FlexContainer>
      {!isRedemptionsLoading && redemptionsFromEvents && sponsors ? (
        <DashboardBusinessesList
          businesses={sponsors}
          redemptions={redemptionsFromEvents}
          teamId={teamId}
          limit={2}
          viewAll
        />
      ) : (
        renderLoadingContainer()
      )}
      {!triggeredDeals && !activeDeals && !flashDeals && !archivedDeals ? (
        renderLoadingContainer()
      ) : (
        <>
          {triggeredDeals && (
            <DashboardDealList
              deals={triggeredDeals}
              className="triggered-deals"
              circledTitle
              dealTitle="Triggered Deals"
              hideTitle
            />
          )}
          {activeDeals && (
            <DashboardDealList
              deals={activeDeals}
              className="deals"
              dealTitle="All Deals"
              circledTitle
              viewAll
              hideTitle
            />
          )}
          {flashDeals && (
            <DashboardDealList
              deals={flashDeals}
              className="flash-deals"
              circledTitle
              dealTitle="Flash Deals"
              hideTitle
            />
          )}
          {contestDeals && (
            <DashboardDealList
              deals={contestDeals}
              className="contest-deals"
              circledTitle
              dealTitle="Contests"
              hideTitle
            />
          )}
          {specialDeals && (
            <DashboardDealList
              deals={specialDeals}
              className="special-deals"
              circledTitle
              dealTitle="Special Deals"
              hideTitle
            />
          )}
          {archivedDeals && (
            <DashboardDealList
              deals={archivedDeals}
              className="archived-deals"
              dealTitle="Archived Deals"
              viewAll
              hideTitle
            />
          )}
        </>
      )}
      <DashboardChartContainer
        title="Impressions"
        onSelectFilter={setSelectedImpressionFilter}
        onFilterDaysAdjustment={setImpressionsFilterDaysAdjustment}
        filterDaysAdjustment={impressionsFilterDaysAdjustment}
        renderChart={() => {
          if (impressionsChartData) {
            return (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={selectedImpressionFilter}
                filterDaysAdjustment={impressionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      {redemptionsFromEvents && (
        <DashboardRedemptionsTable
          title="Redemptions"
          redemptions={redemptionsFromEvents}
          limit={5}
          showAllClick={() => {
            history.push(`/portal/teams/${teamId}/redemptions`);
          }}
        />
      )}
      <DashboardChartContainer
        title="Redemptions Graph"
        onSelectFilter={setSelectedRedemtionFilter}
        onFilterDaysAdjustment={setRedemptionsFilterDaysAdjustment}
        filterDaysAdjustment={redemptionsFilterDaysAdjustment}
        renderChart={() => {
          if (redemptionsChartData) {
            return (
              <LineChart
                dataSet={redemptionsChartData}
                showLegend
                filter={selectedRedemtionFilter}
                filterDaysAdjustment={redemptionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />
      {redemptionsFromEvents ? (
        <DashboardRedemptionAnalytics
          redemptions={redemptionsFromEvents}
          genderChartData={genderChartData}
          ageChartData={ageChartData}
          locationChartData={locationChartData}
          selectedLocationChartData={selectedLocationChartData}
          heatMapData={heatMapData}
        />
      ) : (
        renderLoadingContainer()
      )}
    </div>
  );
};

export default withFirebase(
  withAuthentication(withRouter(PortalTeamDashboard))
);
