import React from "react";

import FollowOrganizationLink from "../../../containers/FollowOrganizationLink";
import { currentUrlWithoutQueryString } from "../../../utilities/core";

function Header({ organization, embedMode }) {
  return (
    <div className="header">
      <img
        src={organization.picture}
        className="header-bg"
        alt={`${organization.name} background`}
      />
      <div className="darkened-bg"></div>
      <div className="header-summary">
        <img
          src={organization.logo}
          className="logo"
          alt={`${organization.name} logo`}
        />
        <div className="writeup">
          <div className="name" data-notranslate>
            {organization.name}
          </div>
          <div className="follow-container">
            {embedMode ? (
              <a
                href={currentUrlWithoutQueryString()}
                target="_blank"
                className="follow-at-fansaves-com"
              >
                Follow
              </a>
            ) : (
              <>
                <FollowOrganizationLink organization={organization} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
