import React from 'react';

const ImageUploader = (props) => {
  const { 
    src,
    style,
    onClick,
    alt,
  } = props;
  
  if(src) {
    return (<img src={src} style={style} onClick={onClick} alt={alt}></img>);
  } else {
    /* To match img default vertical-align: middle */
    const divStyle = {
      ...style,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
    
    return (<div style={divStyle} onClick={onClick}></div>);
  }
};

export default ImageUploader;