import { useState, useEffect } from "react";
import { loadTeamsByKeys } from "../utilities/team_helpers";

function useFollowedOrganizations(firebase, authUser) {
  const [followedOrganizations, setFollowedOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userRef = firebase.database.ref(`/user/${authUser.key}`);

    const handleDataChange = (userSnapshot) => {
      if (userSnapshot.val() !== null) {
        const user = userSnapshot.val();
        user.key = userSnapshot.key;
        loadTeamsByKeys(firebase, user.teams)
          .then((organizationSnapshots) => {
            const organizations = [];
            organizationSnapshots.forEach((organizationSnapshot) => {
              if (organizationSnapshot.val() !== null) {
                const organization = organizationSnapshot.val();
                organization.key = organizationSnapshot.key;

                if (!organization.archived) {
                  organizations.push(organization);
                }
              }
            });

            setFollowedOrganizations(organizations);
            setLoading(false);
          })
          .catch(() => {
            console.error(`useFollowedOrganizations error.`);
          });
      }
    };

    userRef.on("value", handleDataChange);

    return () => {
      userRef.off("value", handleDataChange);
    };
  }, [authUser.key, firebase]);

  return { followedOrganizations, loading };
}

export default useFollowedOrganizations;
