import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

const Button = (props) => {
  if(props.routerLink) {
    return(
      <Link {...props} className={`component-button ${props.className}`}>
        {props.children}
      </Link>
    );
  }

  return(
    <a {...props} className={`component-button ${props.className}`}>
      {props.children}
    </a>
  );
};

export default Button;