import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { TileLayer, Marker, Map, ZoomControl } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "./SponsorMap.scss";

const SponsorMap = ({ location: initialLocation, isMobile }) => {
  const [location, setLocation] = useState(initialLocation);
  const mapRef = useRef();

  useEffect(() => {
    if (initialLocation) {
      const { current = {} } = mapRef;
      const { leafletElement: map } = current;
      if (map) {
        map.setView(location, 14);
      } else {
        setLocation(null);
      }
    }
  }, []);

  return (
    <div className="component-sponsor-map">
      {location && location.length > 0 ? (
        <Map ref={mapRef} zoomControl={false} className="map">
          <ZoomControl position="bottomleft" />
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            minZoom="12"
          />
          <Marker
            position={location}
            icon={
              new Icon({
                iconUrl:
                  "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          />
        </Map>
      ) : (
        <div className="address-map-placeholder">Map Not Available</div>
      )}
    </div>
  );
};

export default SponsorMap;
