import React from "react";
import "./FollowOrganizationModal.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import FollowOrganizationLink from "../FollowOrganizationLink";
import ModalContainer from "../Modals/Modal";
import "../Modals/Modal.scss";
import "../../scss/base/typography.scss";

class FollowOrganizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadOrganizations();
  }

  loadOrganizations = () => {
    const { organizationIds, firebase } = this.props;

    const organizationRequestPromises = [];

    organizationIds.forEach((organizationId) => {
      const organizationPath = `/team/${organizationId}`;
      const organizationRef = firebase.database.ref(organizationPath);
      organizationRequestPromises.push(organizationRef.once("value"));
    });

    Promise.all(organizationRequestPromises).then((organizationSnapshots) => {
      const organizations = [];

      organizationSnapshots.forEach((organizationSnapshot) => {
        if (organizationSnapshot.val() !== null) {
          const organization = organizationSnapshot.val();
          organization.key = organizationSnapshot.key;
          organizations.push(organization);
        }
      });

      this.setState({ organizations, loading: false });
    });
  };

  render() {
    const { onClose, isMobile, deal } = this.props;

    const { loading, organizations } = this.state;

    return (
      <div className="component-follow-organization-modal">
        <ModalContainer onClose={onClose}>
          <>
            {!loading &&
              (organizations.length === 0 ? (
                <div className="not-available">
                  We're sorry! This deal is no longer available. Check out more
                  great deals <Link to="/deals">here</Link>!
                </div>
              ) : (
                <>
                  <div
                    className={`follow-message ${
                      isMobile ? "mobile-h2" : " standard-h3"
                    }`}
                  >
                    {deal && deal.contestDeal
                      ? "To enter this contest, you must first follow:"
                      : "To redeem this deal, you must first follow:"}
                  </div>
                  <div className="organizations">
                    {organizations.map((organization) => {
                      return (
                        <div className="organization">
                          <div className="logo-container">
                            <img src={organization.logo} className="logo" />
                          </div>
                          <div
                            className={`name ${
                              isMobile ? "mobile-h1" : " standard-h6"
                            }`}
                            data-notranslate
                          >
                            {organization.name}
                          </div>
                          <FollowOrganizationLink
                            organization={organization}
                            onFollow={onClose}
                            isMobile={isMobile}
                            className={`button ${
                              isMobile
                                ? "popup-cta-mobile-btn"
                                : "popup-cta-btn"
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              ))}
          </>
        </ModalContainer>
      </div>
    );
  }
}

FollowOrganizationModal.propTypes = {
  organizationIds: PropTypes.array.isRequired,
  onClose: PropTypes.func,
};

export default withFirebase(FollowOrganizationModal);
