import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import { withRouter, Link } from "react-router-dom";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { DefaultContainer } from "../../../containers/UIKit";
import { dbLoadBrand } from "../../../containers/DatabaseHelpers/DatabaseHelpers";
import OrganizationListing from "../../../containers/OrganizationsList/OrganizationListing";
import styled from "styled-components/macro";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  font-size: 16px;
`;

const EmptyStateContainer = styled.div`
  margin: 50px 0;
`;

const Organizations = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 20px;
  margin: 20px auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 1120px;

  @media only screen and (max-width: 1220px) {
    max-width: 742px;
  }

  @media only screen and (max-width: 840px) {
    max-width: 362px;
  }
`;

function Show({ firebase, match, authUser }) {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState();

  function loadbrand() {
    const brandKey = match.params.brand_id;
    dbLoadBrand(firebase, brandKey, setBrand);
  }

  function loadTeams() {
    if (brand && brand.teamKeys) {
      const teamPromises = [];
      brand.teamKeys.forEach((teamKey) => {
        teamPromises.push(
          firebase.database.ref(`/team/${teamKey}`).once("value")
        );
      });

      Promise.all(teamPromises).then((snapshots) => {
        const results = [];
        snapshots.forEach((snapshot) => {
          const team = snapshot.val();
          team.key = snapshot.key;
          results.push(team);
        });
        setTeams(results);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }

  useEffect(loadbrand, []);
  useEffect(loadTeams, [brand]);

  return (
    <CheckOnboarding>
      <DefaultContainer>
        {!loading && (
          <MainContainer className="component-views-brands-show">
            {brand ? (
              <>
                <div className="caption">
                  This organization has multiple properties. Choose the specific
                  FanPage you would like to view below.
                </div>
                <Organizations>
                  {teams.map((team) => {
                    return <OrganizationListing organization={team} />;
                  })}
                </Organizations>
              </>
            ) : (
              <EmptyStateContainer>
                These FanPages are no longer available as part of this group.
                Please <Link to="/organizations">Browse FanPages</Link> to view
                all.
              </EmptyStateContainer>
            )}
          </MainContainer>
        )}
      </DefaultContainer>
    </CheckOnboarding>
  );
}

export default withRouter(withFirebase(withAuthentication(Show)));
