import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../colors";

const SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const SuggestionsTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter";
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const SuggestionsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

const Suggestion = styled.button`
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter";
  color: ${COLORS.primaryBlack};
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #dffcdc;
  border: 1px solid #1c4b17;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #c7f0c7;
  }
`;

const UsernameSuggestions = ({
  suggestions,
  updateUsername,
  updateSuggestions,
  updateError,
}) => {
  return (
    <SuggestionsContainer>
      <SuggestionsTitle>Suggestions:</SuggestionsTitle>
      <SuggestionsList>
        {suggestions.map((suggestion, index) => {
          if (index < 3) {
            return (
              <Suggestion
                key={suggestion}
                onClick={() => {
                  updateUsername(suggestion);
                  updateSuggestions([]);
                  updateError("");
                }}
              >
                {suggestion}
              </Suggestion>
            );
          }
        })}
      </SuggestionsList>
    </SuggestionsContainer>
  );
};

export default UsernameSuggestions;
