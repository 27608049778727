import cloudFunctionHost from "../containers/CloudFunctionsHost";
import { fetchData, fetchDataByRole } from "./db_helpers";
import moment from "moment-timezone";

const loadTeam = async (firebase, teamKey, onSuccess, onError) => {
  if (!teamKey) {
    return;
  }

  try {
    const teamSnapshot = await firebase.database
      .ref(`team/${teamKey}`)
      .once("value");
    const team = teamSnapshot.val();

    if (!team) {
      onError();
      return;
    }

    team.key = teamSnapshot.key;

    const followerKeys = Object.keys(team.followers || {});
    const exclusiveCodeKeys = Object.values(team.exclusiveCodes || {})
      .map((code) => code.users)
      .filter(Boolean)
      .flatMap(Object.keys);

    const userKeys = [...new Set([...followerKeys, ...exclusiveCodeKeys])];

    const userSnapshots = await Promise.all(
      userKeys.map((userKey) =>
        firebase.database.ref(`user/${userKey}`).once("value")
      )
    );

    team.users = userSnapshots
      .filter((snapshot) => snapshot.val() !== null)
      .map((snapshot) => ({ ...snapshot.val(), key: snapshot.key }));

    onSuccess(team);
  } catch (error) {
    console.error("Error loading team", error);
    onError(error);
  }
};

function loadTeamsByKeys(firebase, keys) {
  const teamPromises = [];

  keys &&
    keys.forEach((key) => {
      teamPromises.push(firebase.database.ref(`/team/${key}`).once("value"));
    });

  return Promise.all(teamPromises);
}

function loadTeams(firebase, onLoaded, onError) {
  firebase.database
    .ref("team/")
    .orderByChild("name")
    .on(
      "value",
      (snapshots) => {
        const results = [];

        snapshots.forEach((snapshot) => {
          const team = snapshot.val();
          team.key = snapshot.key;
          if (!team.archived) {
            results.push(team);
          }
        });

        onLoaded(results);
      },
      (error) => {
        onError(error);
      }
    );
}

function loadAllTeams(firebase, onLoaded, onError) {
  firebase.database
    .ref("team/")
    .orderByChild("name")
    .on(
      "value",
      (snapshots) => {
        const results = [];

        snapshots.forEach((snapshot) => {
          const team = snapshot.val();
          team.key = snapshot.key;
          results.push(team);
        });

        onLoaded(results);
      },
      (error) => {
        onError(error);
      }
    );
}

function loadSponsors(firebase, onLoaded, onError) {
  firebase.database
    .ref("sponsor/")
    .orderByChild("name")
    .on(
      "value",
      (snapshots) => {
        const results = [];

        snapshots.forEach((snapshot) => {
          const sponsor = snapshot.val();
          sponsor.key = snapshot.key;
          if (!sponsor.archived) {
            results.push(sponsor);
          }
        });

        onLoaded(results);
      },
      (error) => {
        onError(error);
      }
    );
}

function loadAllSponsors(firebase, onLoaded, onError) {
  firebase.database
    .ref("sponsor/")
    .orderByChild("name")
    .on(
      "value",
      (snapshots) => {
        const results = [];

        snapshots.forEach((snapshot) => {
          const sponsor = snapshot.val();
          sponsor.key = snapshot.key;
          results.push(sponsor);
        });

        onLoaded(results);
      },
      (error) => {
        onError(error);
      }
    );
}

function loadTeamSponsors(firebase, teamKey, onLoaded, onError) {
  firebase.database
    .ref(`team/${teamKey}`)
    .orderByChild("name")
    .once("value")
    .then((snapshot) => {
      const team = snapshot.val();
      team.key = snapshot.key;
      return team;
    })
    .then((team) => {
      const sponsorsPromises = [];

      team.sponsors &&
        team.sponsors.forEach((sponsorKey) => {
          sponsorsPromises.push(
            firebase.database.ref(`sponsor/${sponsorKey}`).once("value")
          );
        });

      return Promise.all(sponsorsPromises);
    })
    .then((sponsors) => {
      const results = [];
      sponsors.forEach((sponsor) => {
        const sponsorObj = sponsor.val();
        sponsorObj.key = sponsor.key;
        results.push(sponsorObj);
      });

      onLoaded(results);
    })
    .catch((error) => {
      if (onError) {
        onError();
      }
    });
}

function unlockExclusives(
  firebase,
  teamKey,
  exclusiveCode,
  onSuccess,
  onError
) {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      exclusiveCode: exclusiveCode ? exclusiveCode.trim() : "",
    };

    fetch(`${cloudFunctionHost}/authorizedFunctions/unlockExclusives`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseObject) => {
        onSuccess(
          responseObject.exclusiveCodeStatus,
          responseObject.specialCodeStatus,
          responseObject.userUpdateStatus
        );
      })
      .catch(() => {
        onError();
      });
  });
}

// const checkAndSuggestUsernames = (firebase, userName, onSuccess, onError) => {
//   firebase.auth.currentUser.getIdToken().then((token) => {
//     fetch(`${cloudFunctionHost}/authorizedFunctions/checkAndSuggestUsernames`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ userName }),
//     })
//       .then((response) => response.json())
//       .then((responseObject) => {
//         onSuccess(responseObject);
//       })
//       .catch(() => {
//         onError();
//       });
//   });
// };

function sendLogDeaImpression(header, data, onSuccess, onError) {
  fetch(`${cloudFunctionHost}/logImpressionsFunctions/logDealImpression`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((responseObject) => {
      onSuccess(responseObject.success);
    })
    .catch(() => {
      onError();
    });
}

function logDealImpression(firebase, dealKey, onSuccess, onError) {
  const headers = {
    "Content-Type": "application/json",
  };
  const data = {
    dealKey,
  };
  if (firebase.auth.currentUser && firebase.auth.currentUser.getIdToken()) {
    firebase.auth.currentUser
      .getIdToken()
      .then((token) => {
        headers.Authorization = `Bearer ${token}`;
      })
      .then(() => {
        sendLogDeaImpression(headers, data, onSuccess, onError);
      });
  } else {
    sendLogDeaImpression(headers, data, onSuccess, onError);
  }
}

function loadTeamLogo(firebase, teamKey, onSuccess, onError) {
  firebase.database
    .ref(`team/${teamKey}`)
    .once("value")
    .then((snapshot) => {
      const team = snapshot.val();
      team.key = snapshot.key;
      return team;
    })
    .then((team) => {
      if (team.logo) {
        onSuccess(team.logo);
      } else {
        onError();
      }
    })
    .catch((error) => {
      if (onError) {
        onError();
      }
    });
}

function loadSponsorLogo(firebase, sponsorKey, onSuccess, onError) {
  firebase.database
    .ref(`sponsor/${sponsorKey}`)
    .once("value")
    .then((snapshot) => {
      const sponsor = snapshot.val();
      sponsor.key = snapshot.key;
      return sponsor;
    })
    .then((sponsor) => {
      if (sponsor.picture) {
        onSuccess(sponsor.picture);
      } else {
        onError();
      }
    })
    .catch((error) => {
      if (onError) {
        onError();
      }
    });
}

function loadSponsorData(firebase, sponsorKey, onSuccess, onError) {
  firebase.database
    .ref(`sponsor/${sponsorKey}`)
    .once("value")
    .then((snapshot) => {
      const sponsor = snapshot.val();
      sponsor.key = snapshot.key;
      return sponsor;
    })
    .then((sponsor) => {
      if (sponsor) {
        if (onSuccess) {
          onSuccess(sponsor);
        }
      } else {
        if (onError) {
          onError();
        }
      }
    })
    .catch((error) => {
      if (onError) {
        onError();
      }
    });
}

async function loadUsersData(firebase, onSuccess, onError) {
  try {
    const result = await fetchData("user", firebase);

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      } else {
        return result;
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      } else {
        return null;
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    } else {
      return null;
    }
  }
}

async function loadOffersData(firebase, onSuccess, onError) {
  try {
    const result = await fetchData("offer", firebase);

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

async function loadBrands(firebase, onSuccess, onError) {
  try {
    const result = await fetchData("brand", firebase);

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

async function loadOfferGroups(firebase, onSuccess, onError) {
  try {
    const result = await fetchData("offerGroup", firebase);

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

async function loadTeamOffers(firebase, teamKey, onSuccess, onError) {
  try {
    const result = await fetchDataByRole("offer", "team", teamKey, firebase);

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

async function loadSponsorOffers(firebase, sponsorKey, onSuccess, onError) {
  try {
    const result = await fetchDataByRole(
      "offer",
      "sponsor",
      sponsorKey,
      firebase
    );

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

async function loadOfferData(firebase, offerKey, onSuccess, onError) {
  try {
    const result = await firebase.database
      .ref(`offer/${offerKey}`)
      .once("value")
      .then((snapshot) => {
        const offer = snapshot.val();
        offer.key = snapshot.key;
        return offer;
      });

    if (result) {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess(result);
      }
    } else {
      if (onError && typeof onError === "function") {
        onError();
      }
    }

    return result;
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

const updateTeamWithExclusiveCodesUserEmails = (team) => {
  if (!team) {
    return;
  }

  const { users, exclusiveCodes, key: teamKey } = team;
  if (users && exclusiveCodes) {
    const newExclusiveCodes = {};

    Object.entries(exclusiveCodes).forEach(([key, value]) => {
      if (value.users) {
        const newUsers = {};
        Object.entries(value.users).forEach(([userKey, userValue]) => {
          const user = users.find((u) => u.key === userKey);
          if (user) {
            newUsers[userKey] = {
              email: user.email,
              status: userValue,
              unlocked:
                user.unlockedExclusivesTeams &&
                user.unlockedExclusivesTeams[teamKey] &&
                user.unlockedExclusivesTeams[teamKey].unlocked &&
                moment(user.unlockedExclusivesTeams[teamKey].unlocked).format(
                  "DD MMM YYYY"
                ),
            };
          }
        });
        newExclusiveCodes[key] = {
          ...value,
          users: newUsers,
        };
      } else {
        newExclusiveCodes[key] = {
          ...value,
          users: {},
        };
      }
    });

    return {
      ...team,
      exclusiveCodes: newExclusiveCodes,
    };
  } else {
    return team;
  }
};

export {
  loadTeam,
  loadTeams,
  loadAllTeams,
  loadSponsors,
  loadBrands,
  loadAllSponsors,
  loadTeamsByKeys,
  unlockExclusives,
  logDealImpression,
  loadTeamSponsors,
  loadTeamOffers,
  loadTeamLogo,
  loadSponsorLogo,
  loadSponsorData,
  loadSponsorOffers,
  loadUsersData,
  loadOffersData,
  loadOfferGroups,
  loadOfferData,
  updateTeamWithExclusiveCodesUserEmails,
  // checkAndSuggestUsernames,
};
