import React, { useState, useEffect, useRef, useCallback } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Pin from "../../../assets/img/deal-marker.svg";
import "./DealMarker.scss";
import styled from "styled-components/macro";
import { useIntl } from "react-intl";

const StyledDealMarkerDescription = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const TranketedDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DealMarker = ({ deal, onClick }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selected, setSelected] = useState(null);
  const infoWindowRef = useRef();
  const { locale } = useIntl();

  const handleOnClick = () => {
    setSelected(!selected);
    if (onClick && typeof onClick === "function") {
      onClick(deal);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target)
      ) {
        setShowInfoWindow(false);
        setSelected(false);
        if (onClick && typeof onClick === "function") {
          onClick(null);
        }
      }
    },
    [onClick]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const translateDescription = () => {
    if (!deal) return;
    const description = deal.description;
    const descriptionFr = deal.descriptionFr;
    if (descriptionFr) {
      return (
        <TranketedDescription data-notranslate>
          {locale === "fr" ? descriptionFr : description}
        </TranketedDescription>
      );
    } else {
      return <TranketedDescription>{description}</TranketedDescription>;
    }
  };

  return (
    <Marker
      key={deal.objectID}
      position={{ lat: deal._geoloc.lat, lng: deal._geoloc.lng }}
      onClick={handleOnClick}
      onMouseOver={() => setShowInfoWindow(true)}
      onMouseOut={() => !selected && setShowInfoWindow(false)}
      icon={{
        url: Pin,
        scaledSize: new window.google.maps.Size(30, 30),
      }}
    >
      {(showInfoWindow || selected) && (
        <InfoWindow
          onCloseClick={() => {
            setShowInfoWindow(false);
            setSelected(false);
          }}
          options={{
            maxWidth: 200,
            minWidth: 200,
            pixelOffset: new window.google.maps.Size(120, 40),
            disableAutoPan: true,
            headerDisabled: true,
          }}
        >
          <div ref={infoWindowRef} className="team-marker-info-window">
            <StyledDealMarkerDescription>
              {translateDescription()}
            </StyledDealMarkerDescription>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default DealMarker;
