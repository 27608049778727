import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

const SortedResultsWithEmptyState = connectStateResults(
  ({
    searchState,
    searchResults,
    children,
    message,
    sortedBy,
    filtteredOutDealKey,
  }) => {
    if (searchResults && searchResults.nbHits !== 0) {
      if (sortedBy === "pinned") {
        const sortedHits = searchResults.hits.sort((a, b) => {
          if (a.pinned && !b.pinned) {
            return -1;
          } else if (!a.pinned && b.pinned) {
            return 1;
          } else {
            return 0;
          }
        });
        searchResults.hits = sortedHits;
      } else if (sortedBy === "contestDeal") {
        const sortedHits = searchResults.hits.sort((a, b) => {
          if (a.contestDeal && !b.contestDeal) {
            return -1;
          } else if (!a.contestDeal && b.contestDeal) {
            return 1;
          } else {
            return 0;
          }
        });
        searchResults.hits = sortedHits;
      }

      if (filtteredOutDealKey) {
        // remove the current deal from the results if it's there
        const filteredHits = searchResults.hits.filter(
          (hit) => hit.objectID !== filtteredOutDealKey
        );
        searchResults.hits = filteredHits;
      }
      return children;
    }

    let resultingMessage;

    if (message) {
      resultingMessage = message;
    } else {
      /* Used for Organization Show page DealsTab */
      if (!searchState.query || searchState.query.trim() === "") {
        if (!searchState.refinementList) {
          resultingMessage = `There are no Deals available for this organization just yet. Check back soon!`;
        } else {
          const exclusive =
            searchState.refinementList &&
            searchState.refinementList.exclusive === "true";

          const contestDeal =
            searchState.refinementList &&
            searchState.refinementList.contestDeal === "true";

          resultingMessage = `There are no ${
            exclusive ? "Exclusive" : contestDeal ? "Contest" : "Public"
          } Deals available for this organization just yet. Check back soon!`;
        }
      } else {
        resultingMessage = `No results were found for '${searchState.query}'.`;
      }
    }

    return (
      <div className="component-results-with-empty-state empty-state">
        {resultingMessage}
      </div>
    );
  }
);

export default SortedResultsWithEmptyState;
