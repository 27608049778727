import React from 'react';
import { SearchBox as AlgoliaSearchBox } from 'react-instantsearch-dom';
import './SearchBox.scss';

const SearchBox = (props) => {
  const { 
    hideSubmit,
    placeholder, 
  } = props;

  let submit = <span><i className='icon-magnifier' /> Search</span>;
  if (hideSubmit) {
    submit = null;
  }

  return (
    <div className={`component-search-box ${hideSubmit ? 'submit-hidden' : ''}`}>
      <AlgoliaSearchBox 
        submit={submit} 
        translations={{
          placeholder: placeholder || 'Search deals...',
        }}
        {...props} 
      />
    </div>
  );
}

export default SearchBox