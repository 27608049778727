import React from "react";

import "./DealTypeOverlay.scss";
import blueRibbon from "../../assets/img/Ribbon_blue.png";
import greenRibbon from "../../assets/img/Ribbon_green.png";
import { Icon } from "@iconify/react";

const DealTypeOverlay = (props) => {
  const { deal, isMobile } = props;
  const { dealType } = deal;

  if (deal.contestDeal) {
    return (
      <div className="component-deal-type-overlay">
        <img
          className="deal-special-background-image"
          src={greenRibbon}
          alt="deal-type-contest"
        />
        <div className="deal-type-container">
          <Icon
            icon="mingcute:celebrate-fill"
            width="20"
            height="20"
            style={{ marginRight: "5px", marginBottom: "2px" }}
          />
          <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
            CONTEST
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="component-deal-type-overlay">
      <img
        className="deal-type-background-image"
        src={blueRibbon}
        alt="deal-type-ribbon"
      />

      {dealType === "online" ? (
        <div className="deal-type-container">
          <i className="fa fa-globe" />
          <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
            Online Deal
          </div>
        </div>
      ) : (
        <div className="deal-type-container">
          <i className="fa fa-shopping-bag" />
          <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
            In-Store Deal
          </div>
        </div>
      )}
    </div>
  );
};

export default DealTypeOverlay;
