import { create } from "zustand";
import {
  loadOffersData,
  loadOfferData,
  loadTeamOffers,
  loadSponsorOffers,
} from "../utilities/team_helpers";

const useOffersStore = create((set, get) => ({
  offersArray: null,
  teamOffers: null,
  sponsorOffers: null,
  impressions: null,
  teamImpressions: null,
  sponsorImpressions: null,
  isLoading: false,
  isLoadingTeamOffers: false,
  isLoadingSponsorOffers: false,
  error: null,
  isFetching: false,

  fetchOffers: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }

    set({ isLoading: true, error: null, isFetching: true });
    try {
      const onSuccess = (offers) => {
        set({ offersArray: offers });
      };

      const onError = (error) => {
        set({ error });
      };

      loadOffersData(firebase, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchOffersByRole: async (firebase, role, teamId, sponsorId) => {
    try {
      if (role === "admin") {
        get().fetchOffers(firebase);
      } else if (role === "team") {
        get().fetchTeamOffers(firebase, teamId);
      } else if (role === "sponsor") {
        get().fetchSponsorOffers(firebase, sponsorId);
      }
    } catch (error) {
      set({ error });
    }
  },

  fetchTeamOffers: async (firebase, teamId) => {
    if (get().isLoadingTeamOffers || teamId === null) {
      return;
    }

    set({ isLoadingTeamOffers: true, error: null });
    try {
      const onSuccess = (offers) => {
        set({ teamOffers: offers });
      };

      const onError = (error) => {
        set({ error });
      };

      loadTeamOffers(firebase, teamId, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoadingTeamOffers: false });
    }
  },

  fetchSponsorOffers: async (firebase, sponsorId) => {
    if (get().isLoadingSponsorOffers || sponsorId === null) {
      return;
    }

    set({ isLoadingSponsorOffers: true, error: null });
    try {
      const onSuccess = (offers) => {
        set({ sponsorOffers: offers });
      };

      const onError = (error) => {
        set({ error });
      };

      loadSponsorOffers(firebase, sponsorId, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoadingSponsorOffers: false });
    }
  },

  setTeamOffers: (teamOffers) => {
    set({ teamOffers });
  },

  setSponsorOffers: (sponsorOffers) => {
    set({ sponsorOffers });
  },

  setImpressions: (impressions) => {
    set({ impressions });
  },

  setTeamImpressions: (teamImpressions) => {
    set({ teamImpressions });
  },

  setSponsorImpressions: (sponsorImpressions) => {
    set({ sponsorImpressions });
  },

  refetchOffers: async (firebase) => {
    await get().fetchOffers(firebase);
  },

  fetchOffer: async (firebase, offerId, onFetchSuccess, onFetchError) => {
    try {
      const onSuccess = (offer) => {
        if (onFetchSuccess && typeof onFetchSuccess === "function") {
          onFetchSuccess(offer);
        }
        set({
          offersArray: get().offersArray.map((o) =>
            o.key === offer.key ? offer : o
          ),
          teamOffers: get().teamOffers.map((o) =>
            o.key === offer.key ? offer : o
          ),
          sponsorOffers: get().sponsorOffers.map((o) =>
            o.key === offer.key ? offer : o
          ),
        });
      };

      const onError = (error) => {
        console.log("error", error);

        if (error && onFetchError && typeof onFetchError === "function") {
          onFetchError(error);
        }

        set({ error });
      };

      loadOfferData(firebase, offerId, onSuccess, onError);
    } catch (error) {
      set({ error });
    }
  },
}));

export default useOffersStore;
