import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { Line } from "react-chartjs-2";
import { getDateArray } from "../../../utilities/dashboard_helpers";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0;
`;

const LineChart = ({
  dataSet,
  showLegend,
  filter,
  filterDaysAdjustment,
  fill,
}) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  const dataDefault = {
    labels: [],
    datasets: [],
  };

  const optionsDefault = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {},
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            stepSize: 10,
          },
        },
      ],
    },
  };

  const createDataSet = (dataSet, fill) => {
    const data = {
      label: dataSet.label,
      data: dataSet.data,
      fill: false,
      borderColor: dataSet.color || COLORS.primaryBlue,
    };
    if (fill) {
      data.fill = true;
      data.backgroundColor = COLORS.primaryBlue;
    }
    return data;
  };

  useEffect(() => {
    if (dataSet && dataSet.length > 0) {
      const tempData = { ...dataDefault };
      const tempOptions = { ...optionsDefault };
      tempData.datasets = dataSet.map((data) => createDataSet(data, fill));
      const { dateArray } = getDateArray(filter, filterDaysAdjustment);
      tempData.labels = dateArray;
      if (showLegend) {
        tempOptions.legend.display = true;
      }
      setChartData(tempData);
      setChartOptions(tempOptions);
    }
  }, [dataSet, filter, filterDaysAdjustment]);

  return (
    <Wrapper>
      {chartData && chartOptions && (
        <Line data={chartData} options={chartOptions} />
      )}
    </Wrapper>
  );
};

export default LineChart;

LineChart.propTypes = {
  dataSet: propTypes.array,
  showLegend: propTypes.bool,
  filter: propTypes.string,
  filterDaysAdjustment: propTypes.number,
  fill: propTypes.bool,
};
