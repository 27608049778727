/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import { withAuthentication } from "../../../../containers/Session";
import "./Followers.scss";

import { Table } from "reactstrap";

function Followers({ authUser, firebase }) {
  const [teams, setTeams] = useState([]);

  function loadTeams() {
    var teamID = authUser ? authUser.teamID : null;

    if (authUser && authUser.role === "team" && !teamID) return;

    var teamsRef = firebase.database.ref("team");
    teamsRef
      .orderByChild("name")
      .once("value")
      .then((teamSnapshots) => {
        const results = [];
        teamSnapshots.forEach((teamSnapshot) => {
          const team = teamSnapshot.val();
          results.push(team);
        });

        setTeams(results);
      });
  }

  function total() {
    const total = teams.reduce((acc, team) => {
      if (team.followers) {
        return acc + Object.keys(team.followers).length;
      }
      return acc;
    }, 0);

    return total;
  }

  useEffect(() => {
    loadTeams();
  }, []);

  return (
    <div className="views-portal-reports-followers">
      <h1>Followers Report</h1>

      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Followers</th>
          </tr>
        </thead>
        <tbody>
          <tr className="total-row">
            <td>
              <b>Total</b>
            </td>
            <td>
              <b>{total()}</b>
            </td>
          </tr>
          {teams.map((team) => {
            return (
              <tr>
                <td data-notranslate>{team.name}</td>
                <td>
                  {team.followers ? Object.keys(team.followers).length : 0}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default withFirebase(withAuthentication(Followers));
