import React from "react";
import OrganizationLink from "../OrganizationLink";

import "./OrganizationIconLink.scss";

function OrganizationIconLink({ organization }) {
  return (
    <OrganizationLink
      organization={organization}
      className="component-organization-icon-link"
    >
      <img
        src={organization.logo}
        alt={`${organization.name} logo`}
        className="organization-logo"
      />
      <div className="name" data-notranslate>
        {organization.name}
      </div>
    </OrganizationLink>
  );
}

export default OrganizationIconLink;
