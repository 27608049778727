export const genderChoicesOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Non-binary",
    value: "Non-binary",
  },
  {
    label: "Rather not say",
    value: "Rather not say",
  },
];

export const genderChoicesOptionsFrench = [
  {
    label: "Mâle",
    value: "Male",
  },
  {
    label: "Femme",
    value: "Female",
  },
  {
    label: "Non-binaire",
    value: "Non-binary",
  },
  {
    label: "Ne pas dire",
    value: "Rather not say",
  },
];
