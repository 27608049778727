/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { HorizontalBarChart } from "../../assets/fansaves-ui";

const PortalHorizontalChartContainer = ({ data, type, options, className }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(options);
  const [labelOptions, setLabelOptions] = useState(null);

  const updatelabelOptions = (type) => {
    let labels = [];
    if (type === "age") {
      labels = ["15-19", "20-29", "30-39", "40-49", "50-59", "60+"];
    } else if (type === "cities") {
      Object.keys(data).forEach((key) => {
        // take name of the city before the bracket
        labels.push(key.split(" (")[0]);
      });
      if (labels.length < 6) {
        for (let i = labels.length; i < 6; i++) {
          labels.push("");
        }
      }
    }

    return labels;
  };

  useEffect(() => {
    if (type && data) {
      const labels = updatelabelOptions(type);
      let updatedDataSet = [];
      setLabelOptions(labels);
      if (type === "cities") {
        Object.values(data).forEach((value) => {
          updatedDataSet.push(value);
        });
      } else if (type === "age") {
        updatedDataSet = data;
      }
      setChartData(updatedDataSet);

      setChartOptions(options);
    }
  }, [data, type]);

  return (
    <>
      {chartData && labelOptions && (
        <HorizontalBarChart
          dataSet={chartData}
          options={chartOptions}
          labels={labelOptions}
          className={className}
        />
      )}
    </>
  );
};

export default PortalHorizontalChartContainer;
