import React from "react";
import { FlexContainer } from "../../assets/fansaves-ui";
import "../../scss/base/typography.scss";

const DashboardFilterDisplay = ({ filter, renderCount }) => {
  const daysOptions = [
    {
      value: "7days",
      label: "7 days",
    },
    {
      value: "30days",
      label: "30 days",
    },
    {
      value: "90days",
      label: "90 days",
    },
    {
      value: "ThisYear",
      label: "This year",
    },
    {
      value: "LastYear",
      label: "Last year",
    },
    {
      value: "All",
      label: "All",
    },
    /* {
      value: "custom",
      label: "Custom",
    }, */
  ];

  const getFilterLabel = (value) => {
    const filterOption = daysOptions.find((option) => option.value === value);
    if (filterOption) {
      if (filterOption.value.includes("days")) {
        return `Past ${filterOption.label}`;
      }
      return filterOption.label;
    }
  };

  return (
    <FlexContainer
      gap="5px"
      className="component-dashboard-filter-display"
      width="fit-content"
    >
      <div className="rubik-h6">{renderCount}</div>
      <div className="public-h2">{getFilterLabel(filter)}</div>
    </FlexContainer>
  );
};

export default DashboardFilterDisplay;
