import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const imgStyle = {
    maxWidth: '50px',
}

export default class SponsorRow extends React.Component {
    render() {
        return (
            <tr>
                <td><img src={this.props.sponsor.picture} style={imgStyle} alt='Sponsor Logo' /></td>
                <td>{this.props.sponsor.name}</td>
                <td>{this.props.count}</td>
                <td>
                    <Link to={'/portal/sponsors/' + this.props.sponsorID}>
                        <Button className={"ml-2 btn-success"}>
                            <li className="cui-magnifying-glass icons font-lg d-inline"></li>
                            <span className={"ml-2 d-inline font-lg"}><strong>View</strong></span>
                        </Button>
                    </Link>
                </td>
            </tr>
        );
    }
};