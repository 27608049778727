/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, lazy } from "react";
import styled from "styled-components/macro";
import papa from "papaparse";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../../containers/Firebase";
import { useQueryClient } from "@tanstack/react-query";
import {
  FlexContainer,
  CSVExport,
  DashboardButton,
} from "../../../../../assets/fansaves-ui";
import moment from "moment";
import { Icon } from "@iconify/react";

import AddExclusiveCodesModal from "../../../../../containers/AddExclusiveCodesModal";
import DashboardSpecialCodesHeader from "../../../../../containers/DashboardSpecialCodesHeader";

import "../../../../../scss/base/typography.scss";

import useTeamsStore from "../../../../../stores/teamsStore";
import useSpecialCodesStore from "../../../../../stores/specialCodesStore";

const DashboardSpecialCodesTable = lazy(() =>
  import("../../../../../containers/DashboardSpecialCodesTable")
);

const StyledFlexPassCodesList = styled.div`
  margin-bottom: 50px;
  .exclusive-codes-list-header {
    position: relative;
    .link-container {
      position: absolute;
      top: 16px;
      right: 10px;
    }
  }
`;

const FlexPassCodes = ({ match, firebase, history }) => {
  const queryClient = useQueryClient();
  const teamId = match.params.team_id;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addCodeDropdown, setAddCodeDropdown] = useState(false);
  const [CSVcodes, setCSVcodes] = useState(null);
  const [CSVcodesErrors, setCSVcodesErrors] = useState(null);
  const [team, setTeam] = useState(null);

  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const teamFromStore = useTeamsStore((state) => state.team);

  const addSpecialCodes = useSpecialCodesStore(
    (state) => state.addSpecialCodes
  );

  const specialCodesFromStore = useSpecialCodesStore(
    (state) => state.specialCodes
  );

  const fetchSpecialCodes = useSpecialCodesStore(
    (state) => state.fetchSpecialCodes
  );

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if (!team || team.key !== teamId) {
      fetchTeam(firebase, teamId);
    }
  }, [team, fetchTeam, firebase, teamId]);

  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "code", key: "code" },
      { label: "limit", key: "limit" },
      { label: "used", key: "used" },
      { label: "status", key: "status" },
      { label: "users", key: "users" },
    ];
  };

  const generateData = () => {
    if (!specialCodesFromStore || !specialCodesFromStore[teamId]) {
      return {};
    }
    const data = specialCodesFromStore[teamId].reduce((acc, code) => {
      if (!code || code.accessLevel !== "flexPass") {
        return acc;
      }
      const data = {
        index: acc.length + 1,
        code: code.code,
        limit: code.limit || "unlimited",
        used: code.used,
        status: code.status,
        users:
          code.users && Object.keys(code.users).length > 0
            ? Object.entries(code.users).map((user) => user[1].email)
            : "none",
      };
      acc.push(data);
      return acc;
    }, []);

    return data;
  };

  const handleUploadFile = (file) => {
    try {
      papa.parse(file, {
        header: true,
        complete: (results) => {
          const codes = results.data
            .map((code) => code.code || code.Code)
            .filter((code) => code !== undefined && code !== "");
          setCSVcodes(codes);
          setCSVcodesErrors(results.errors);
        },
      });
    } catch (error) {
      console.error("Error parsing CSV file", error);
    }
  };

  const handleSubmitCodes = async () => {
    if (!CSVcodes || CSVcodes.length === 0) {
      return;
    }
    setIsSubmitting(true);
    const result = await addSpecialCodes(
      firebase,
      teamId,
      CSVcodes,
      "flexPass"
    );

    if (!result) {
      console.error("Error adding flex pass codes");
      setIsSubmitting(false);
      return;
    } else {
      window.alert("Codes added successfully", result);
      queryClient.invalidateQueries(["specialCodes", teamId, "flexPass"]);
      setIsSubmitting(false);
      setCSVcodes(null);
      setCSVcodesErrors(null);
      setAddCodeDropdown(false);
    }
  };

  const handleCancelCodes = () => {
    setCSVcodes(null);
    setCSVcodesErrors(null);
  };

  const memoizedSCVExport = useMemo(() => {
    if (!specialCodesFromStore || !specialCodesFromStore[teamId]) {
      return (
        <DashboardButton
          title="Get Data for CSV Export"
          type="white"
          icon="fa-solid:file-export"
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            fetchSpecialCodes(firebase, teamId, "flexPass");
          }}
        />
      );
    }
    if (team && specialCodesFromStore && specialCodesFromStore[teamId]) {
      return (
        <CSVExport
          data={generateData()}
          headers={generateHeaders()}
          filename={
            team && team.slug
              ? `${team.slug}-flex-pass-codes-list-${moment().format(
                  "YYYY-MM-DD"
                )}`
              : `flex-pass-codes-list-${moment().format("YYYY-MM-DD")}`
          }
          target="_blank"
          className="rubik-h3"
        >
          <Icon icon="fa-solid:file-export" className="user-export-icon" />{" "}
          Export Data
        </CSVExport>
      );
    }
  }, [team, specialCodesFromStore, teamId, firebase]);

  return (
    <StyledFlexPassCodesList className="exclusive-codes-list" animated fadeIn>
      {addCodeDropdown && (
        <AddExclusiveCodesModal
          onClose={() => setAddCodeDropdown(false)}
          onUploadFile={handleUploadFile}
          codes={CSVcodes}
          onSubmit={handleSubmitCodes}
          onCancel={handleCancelCodes}
          errors={CSVcodesErrors}
          isSubmitting={isSubmitting}
          title="Flex Pass Codes"
        />
      )}

      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="exclusive-codes-list-header"
        margin="30px 0 0 0"
      >
        <DashboardSpecialCodesHeader team={team} title="Flex Pass Codes" />
        <FlexContainer gap="20px" alignItems="flex-start" width="100%">
          <DashboardButton
            title="Add Flex Pass Codes"
            type="green"
            icon="fa-solid:plus"
            onClick={(e) => {
              e.preventDefault();
              setAddCodeDropdown(true);
            }}
            size="medium"
          />
        </FlexContainer>
        <div className="link-container">{memoizedSCVExport}</div>
      </FlexContainer>
      <FlexContainer className="exclusive-codes-list-table">
        <DashboardSpecialCodesTable team={team} accessLevel="flexPass" />
      </FlexContainer>
    </StyledFlexPassCodesList>
  );
};

export default withRouter(withFirebase(FlexPassCodes));
