import React from 'react';
import { SortBy as AlgoliaSortBy } from 'react-instantsearch-dom';
import './SortBy.scss';

const SortBy = (props) => {
  return (
    <div className='component-sort-by'>
      <AlgoliaSortBy 
        {...props} 
      />
    </div>
  );
}

export default SortBy