import { create } from "zustand";
import Cookies from "js-cookie";

const useLanguageStore = create((set) => ({
  language:
    Cookies.get("selectedLanguage") || navigator.language.split("-")[0] || "en",
  setLanguage: (lang) => set({ language: lang }),
}));

export default useLanguageStore;
