import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import { withRouter } from "react-router-dom";

import "../../../scss/base/typography.scss";
import { COLORS } from "../colors";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  gap: 10px;
  position: relative;
  width: ${({ width }) => width || "100%"};
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid ${COLORS.primaryGreen};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const BackButton = styled(Icon)`
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.primaryBlueHover};
  }
`;

const DashboardTitle = ({
  title,
  number,
  circle,
  className,
  width,
  justifyContent,
  backButtonPath,
  onBack,
  history,
}) => {
  return (
    <Wrapper
      className={className}
      width={width}
      justifyContent={justifyContent}
    >
      {(backButtonPath || onBack) && (
        <BackButton
          icon="uil:angle-left"
          onClick={() => {
            if (onBack && typeof onBack === "function") {
              onBack();
            } else {
              if (backButtonPath.includes("dashboard")) {
                history.push(backButtonPath, { to: "dashboard" });
              } else {
                history.push(backButtonPath);
              }
            }
          }}
        />
      )}
      <div className="rubik-h4">{title}</div>
      {(number || number === 0) && (
        <div className={`rubik-h4 ${circle ? "circle" : ""}`}>{number}</div>
      )}
    </Wrapper>
  );
};

export default withRouter(DashboardTitle);

DashboardTitle.propTypes = {
  title: propTypes.string,
  number: propTypes.number,
  circle: propTypes.bool,
  className: propTypes.string,
  width: propTypes.string,
  justifyContent: propTypes.string,
  backButtonPath: propTypes.string,
};
