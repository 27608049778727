import React from "react";
import Select from "react-select";

import {
  Months,
  DaysOptions,
  Years,
} from "../../../containers/constants/DateConstants";
import { Label } from "reactstrap";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import ErrorMessage from "./ErrorMessage";

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledSubLabel = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryGrey};
  margin-bottom: 2px;
  padding-left: 5px;
`;

const commonStyles = {
  backgroundColor: "white",
  borderWidth: "1px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: '"Manrope", sans-serif',
  width: "100%",
};

const OnboardingBirthdayForm = ({
  dobMonth = "",
  dobDay = "",
  dobYear = "",
  onChange,
  error = "",
  onFieldChangeClearError,
}) => {
  const handleUserInput = (selectedOption, action) => {
    const value = selectedOption ? selectedOption.value : "";
    onChange({ target: { name: action.name, value } });
    onFieldChangeClearError(action.name);
  };

  const getControlStyles = (error, isDisabled) => ({
    ...commonStyles,
    borderColor: error ? COLORS.errorRed : COLORS.primaryBlue,
    color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
  });

  const getSingleValueStyles = (isDisabled) => ({
    color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
  });

  return (
    <div className="form-section">
      <StyledLabel>Birthday</StyledLabel>
      <div
        style={{
          gap: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="col-auto" style={{ padding: "0" }}>
          <StyledSubLabel>Year</StyledSubLabel>
          <Select
            name="dobYear"
            value={Years.find((option) => option.value?.toString() === dobYear)}
            onChange={handleUserInput}
            options={Years}
            placeholder="YYYY"
            isSearchable
            styles={{
              control: (styles, { isDisabled }) => ({
                ...styles,
                ...getControlStyles(error, isDisabled),
              }),
              singleValue: (styles, { isDisabled }) => ({
                ...styles,
                ...getSingleValueStyles(isDisabled),
              }),
            }}
          />
        </div>
        <div className="col-auto" style={{ padding: "0" }}>
          <StyledSubLabel>Month</StyledSubLabel>
          <Select
            name="dobMonth"
            value={Months.find((option) => option.value === dobMonth)}
            onChange={handleUserInput}
            options={Months}
            placeholder="MM"
            isSearchable
            styles={{
              control: (styles, { isDisabled }) => ({
                ...styles,
                ...getControlStyles(error, isDisabled),
              }),
              singleValue: (styles, { isDisabled }) => ({
                ...styles,
                ...getSingleValueStyles(isDisabled),
              }),
            }}
          />
        </div>
        <div className="col-auto" style={{ padding: "0" }}>
          <StyledSubLabel>Day</StyledSubLabel>
          <Select
            name="dobDay"
            value={DaysOptions.find((option) => option.value === dobDay)}
            onChange={handleUserInput}
            options={DaysOptions}
            placeholder="DD"
            isSearchable
            styles={{
              control: (styles, { isDisabled }) => ({
                ...styles,
                ...getControlStyles(error, isDisabled),
              }),
              singleValue: (styles, { isDisabled }) => ({
                ...styles,
                ...getSingleValueStyles(isDisabled),
              }),
            }}
          />
        </div>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default OnboardingBirthdayForm;
