import { create } from "zustand";
import { loadBrands } from "../utilities/team_helpers";

const useBrandsStore = create((set, get) => ({
  brands: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchBrands: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const onSuccess = (brands) => {
        set({ brands });
      };

      const onError = (error) => {
        set({ error });
      };

      loadBrands(firebase, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },
}));

export default useBrandsStore;
