import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { DealListing } from "../DealsList";
import { recommendedOffer } from "../../utilities/user_helpers";

function FeaturedDeal({ firebase, authUser }) {
  const [loading, setLoading] = useState(true);
  const [featuredOffer, setFeaturedOffer] = useState();

  useEffect(() => {
    if (!authUser) {
      setLoading(false);
      return;
    }

    recommendedOffer(
      firebase,
      (response) => {
        response
          .json()
          .then((data) => {
            setFeaturedOffer(data.offer);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          });
      },
      () => {
        setLoading(false);
      }
    );
  }, [firebase, authUser]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : featuredOffer ? (
        <DealListing deal={featuredOffer} size="feed" />
      ) : (
        <div>Unfortunately we could not find a featured deal for you.</div>
      )}
    </div>
  );
}

export default withFirebase(withAuthentication(FeaturedDeal));
