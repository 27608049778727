import environment from "../environment";

let url = "";

if (environment.name === "production") {
  url = "https://fansaves.com";
} else if (environment.name === "staging") {
  url = "https://fansaves-staging.web.app";
} else if (environment.name === "dev") {
  url = "http://localhost:3000";
} else {
  url = "http://localhost:3000";
}

export default url;
