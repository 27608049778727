import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import LoadingPlaceholder from "../LoadingPlaceholder";

const ResultsWithEmptyStateLoading = connectStateResults(
  ({
    searchState,
    searchResults,
    children,
    message,
    loadingPlacehoderCount,
    isSearchStalled,
  }) => {
    if (isSearchStalled) {
      const loadingPlaceholders = [];
      for (let i = 0; i < loadingPlacehoderCount; i++) {
        loadingPlaceholders.push(
          <LoadingPlaceholder
            key={`loading-placeholder-${i}`}
            style={{ height: 225, width: 250, margin: 15 }}
          />
        );
      }
      return (
        <div className="loading-deals-placeholder-container">
          {loadingPlaceholders}
        </div>
      );
    }

    if (searchResults && searchResults.nbHits !== 0) {
      return children;
    }

    let resultingMessage;

    if (message) {
      resultingMessage = message;
    } else {
      /* Used for Organization Show page DealsTab */
      if (!searchState.query || searchState.query.trim() === "") {
        const exclusive =
          searchState.refinementList &&
          searchState.refinementList.exclusive === "true";
        resultingMessage = `There are no ${
          exclusive ? "Exclusive" : "Public"
        } Deals available for this organization just yet. Check back soon!`;
      } else {
        resultingMessage = `No results were found for '${searchState.query}'.`;
      }
    }

    return (
      <div className="component-results-with-empty-state empty-state">
        {resultingMessage}
      </div>
    );
  }
);

export default ResultsWithEmptyStateLoading;
