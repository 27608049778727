import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withFirebase } from "../../../../containers/Firebase";
import {
  withAuthentication,
  isAdmin,
  isSponsor,
} from "../../../../containers/Session";
import { Doughnut } from "react-chartjs-2";
import { Button, Card, CardBody, CardHeader, Label, Row } from "reactstrap";

import FriendlyDateTime from "../../../../containers/FriendlyDateTime";
import TransactionsTable from "../../../../containers/TransactionsTable";
import LocationsTable from "../../../../containers/LocationsTable";
import OfferButton from "./OfferButton";
import OfferPreview from "./OfferPreview";
import "./Show.scss";

const genderChartDefault = {
  labels: ["Female", "Male", "Non-Binary"],
  datasets: [
    {
      data: [300, 500, 100],
      backgroundColor: ["#73B051", "#373A5D", "#82839B"],
      hoverBackgroundColor: ["#73B051", "#373A5D", "#82839B"],
    },
  ],
};

const ageChartDefault = {
  labels: ["15-19", "20-29", "30-39", "40-49", "50-59", "60+"],
  datasets: [
    {
      data: [300, 500, 100],
      backgroundColor: [
        "#75AC58",
        "#9DCA87",
        "#C3DEB1",
        "#373A5D",
        "#81839A",
        "#82839B",
      ],
      hoverBackgroundColor: [
        "#75AC58",
        "#9DCA87",
        "#C3DEB1",
        "#373A5D",
        "#81839A",
        "#82839B",
      ],
    },
  ],
};

class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsor: null,
      masterListOfOffers: null,
      users: null,
      transactions: [],
      filteredTransactions: [],
      redemptions: null,
      genderChartData: genderChartDefault,
      ageChartData: ageChartDefault,
      document: "",
      selectedOfferKey: "all",
      offerToActivate: undefined,
    };
  }

  componentDidMount() {
    this.loadSponsorData();
    this.loadOfferData();
    this.loadUserData();
  }

  loadSponsorData = () => {
    const sponsorKey = this.props.match.params.sponsor_id;
    const sponsorPath = "/sponsor/" + sponsorKey;

    var sponsorRef = this.props.firebase.database.ref(sponsorPath);
    sponsorRef.once("value").then((dataSnapshot) => {
      var sponsor = dataSnapshot.val();
      this.setState(
        {
          sponsor: sponsor,
          document: sponsor.document ? sponsor.document : "",
        },
        this.dataLoaded
      );
    });
  };

  loadOfferData = () => {
    const offerRef = this.props.firebase.database.ref("offer/");
    offerRef.once("value").then((offersSnapshot) => {
      const masterListOfOffers = {};

      offersSnapshot.forEach((offerSnapshot) => {
        const offer = offerSnapshot.val();
        offer.key = offerSnapshot.key;
        masterListOfOffers[offer.key] = offer;
      });

      this.setState({ masterListOfOffers }, this.dataLoaded);
    });
  };

  loadUserData = () => {
    var userRef = this.props.firebase.database.ref("user/");
    userRef.once("value").then((userSnapshots) => {
      const users = [];

      userSnapshots.forEach((userSnapshot) => {
        if (userSnapshot.val() !== null) {
          const user = userSnapshot.val();
          user.key = userSnapshot.key;
          users.push(user);
        }
      });

      this.setState({ users }, this.dataLoaded);
    });
  };

  calculateAge = (birthDate) => {
    var dob = new Date(birthDate);
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  };

  dataLoaded = () => {
    if (
      !this.state.sponsor ||
      !this.state.masterListOfOffers ||
      !this.state.users
    )
      return;

    const { sponsor } = this.state;

    this.mapData();
  };

  mapData = () => {
    const { sponsor, users, masterListOfOffers, selectedOfferKey } = this.state;

    let selectedOffers = [];
    let calculatingTotal = false;

    if (selectedOfferKey === "all") {
      if (sponsor.offers) {
        selectedOffers = sponsor.offers;
      }
      calculatingTotal = true;
    } else {
      selectedOffers = [masterListOfOffers[selectedOfferKey]];
    }

    let transactions = [];
    let maleCount = 0;
    let femaleCount = 0;
    let otherCount = 0;
    let activeCount = 0;
    let ageData = [0, 0, 0, 0, 0, 0];

    users.forEach((user) => {
      let userRedeemKeys = [];
      let userPurchaseOfferKeys = [];
      let userPurchaseOfferCreatedAtMap = {};

      // Prepare redeemed offer keys
      if (user.redeemed) {
        userRedeemKeys = Object.keys(user.redeemed);
      }

      // Prepare purchase offer keys
      if (user.purchases) {
        for (let userPurchaseKey in user.purchases) {
          const offerKey = user.purchases[userPurchaseKey].offerKey;
          const createdAt = user.purchases[userPurchaseKey].createdAt;
          userPurchaseOfferKeys.push(offerKey);
          userPurchaseOfferCreatedAtMap[offerKey] = createdAt;
        }
      }

      if (userRedeemKeys.length > 0 || userPurchaseOfferKeys.length > 0) {
        selectedOffers.forEach((selectedOffer) => {
          let transaction;
          let offer;
          let numberOfTimes = 0;
          const userAge = this.calculateAge(user.birthDate || user.dob);

          // Check if offer was redeeemed
          if (userRedeemKeys.includes(selectedOffer.key)) {
            // TODO: This needs to be adjusted in the future,
            //       but right now, it's only stored once on the user
            numberOfTimes = 1;

            offer = masterListOfOffers[selectedOffer.key];

            transaction = {
              offerID: offer.key,
              offer: offer,
              user,
              time: user.redeemed[selectedOffer.key],
              userAge,
              transactionType: "redemption",
            };
          }

          // Check if offer was purchased
          if (userPurchaseOfferKeys.includes(selectedOffer.key)) {
            const filteredUserPurchaseOfferKeys = userPurchaseOfferKeys.filter(
              (offerKey) => offerKey === selectedOffer.key
            );
            numberOfTimes = filteredUserPurchaseOfferKeys.length;

            offer = masterListOfOffers[selectedOffer.key];

            transaction = {
              offerID: offer.key,
              offer: offer,
              user,
              time: userPurchaseOfferCreatedAtMap[selectedOffer.key],
              userAge,
              transactionType: "purchase",
            };
          }

          if (transaction) {
            // Log each transaction
            for (let i = 0; i < numberOfTimes; i++) {
              transactions.push(transaction);
              if (offer.active) activeCount++;
            }

            // Count the user only once per offer
            if (user.gender && user.gender.toLowerCase() === "female")
              femaleCount++;
            else if (user.gender && user.gender.toLowerCase() === "male")
              maleCount++;
            else otherCount++;

            if (userAge <= 19) ageData[0]++;
            else if (20 <= userAge && userAge <= 29) ageData[1]++;
            else if (30 <= userAge && userAge <= 39) ageData[2]++;
            else if (40 <= userAge && userAge <= 49) ageData[3]++;
            else if (50 <= userAge && userAge <= 59) ageData[4]++;
            else ageData[5]++;
          }
        });
      }
    });

    // Sort transactions
    transactions = transactions.sort((a, b) =>
      a.time < b.time ? 1 : b.time < a.time ? -1 : 0
    );

    // In the order of Female, male, LGBTQ+
    var genderData = [femaleCount, maleCount, otherCount];
    var genderChartTemplate = genderChartDefault;
    genderChartTemplate["datasets"][0]["data"] = genderData;

    var ageChartTemplate = ageChartDefault;
    ageChartTemplate["datasets"][0]["data"] = ageData;

    this.setState({
      filteredTransactions: transactions,
      genderChartData: genderChartTemplate,
      ageChartData: ageChartTemplate,
      activeCount,
    });

    if (calculatingTotal) {
      this.setState({ transactions });
    }
  };

  toggleStatus = (offer, statusField) => {
    const sponsorKey = this.props.match.params.sponsor_id;

    const { sponsor } = this.state;

    const { firebase } = this.props;

    if (sponsor) {
      let offerSpecIndex;
      let offerSpecToUpdate;
      sponsor.offers.forEach((offerSpec, index) => {
        if (offerSpec.key === offer.key) {
          offerSpecIndex = index;
          offerSpecToUpdate = offerSpec;
        }
      });

      if (offerSpecIndex !== undefined) {
        const sponsorActivePath = `sponsor/${sponsorKey}/offers/${offerSpecIndex}/${statusField}/`;
        const sponsorActiveRef = firebase.database.ref(sponsorActivePath);

        const newStatusFieldValue = !offerSpecToUpdate[statusField];

        /* Update Sponsor Collection */
        sponsorActiveRef.set(newStatusFieldValue).then(() => {
          this.loadSponsorData();
        });

        /* Update offer */
        const offerPath = `offer/${offer.key}`;
        const offerRef = firebase.database.ref(offerPath);

        const newOfferData = {};
        newOfferData[statusField] = newStatusFieldValue;

        offerRef.update(newOfferData).then(() => {
          this.loadOfferData();
          this.setState({ offerToActivate: undefined });
        });
      } else {
        console.error(`Could not find offerSpecIndex for offer ${offer.key}`);
      }
    }
  };

  togglePublish = (offer) => {
    this.toggleStatus(offer, "published");
  };

  toggleActive = (offer) => {
    this.toggleStatus(offer, "active");
  };

  showOfferPreview = (offer) => {
    this.setState({ offerToActivate: offer });
  };

  clearOfferPreview = () => {
    this.setState({ offerToActivate: undefined });
  };

  handleDealButtonClick = (offerKey) => {
    this.setState({ selectedOfferKey: offerKey }, this.mapData);
  };

  handleDelete = () => {
    const sponsorKey = this.props.match.params.sponsor_id;
    if (
      window.confirm(
        "Do you really want to delete this sponsor? (Your action is irreversible)"
      )
    ) {
      let sponsorPath = "sponsor/" + sponsorKey;
      let ref = this.props.firebase.database.ref(sponsorPath);
      ref.remove().then(() => this.props.history.push("/sponsors"));
    }
  };

  renderTransactionsPeriod = () => {
    const { filteredTransactions } = this.state;

    if (filteredTransactions.length === 0) {
      return "";
    }

    // Note this assumes transactions are sorted in reverse chronological order
    const firstTransactionChronologically =
      filteredTransactions[filteredTransactions.length - 1];
    const lastTransactionChronologically = filteredTransactions[0];

    var toDate = lastTransactionChronologically.time;
    var fromDate = firstTransactionChronologically.time;

    if (!toDate || toDate.length < 1 || !fromDate || fromDate.length < 1) {
      return "";
    }

    return (
      <>
        <FriendlyDateTime
          timestamp={fromDate}
          format="MMM DD, yyyy"
          hideTime={true}
        />
        {" - "}
        <FriendlyDateTime
          timestamp={toDate}
          format="MMM DD, yyyy"
          hideTime={true}
        />
      </>
    );
  };

  publishedOffers = () => {
    const { sponsor, masterListOfOffers } = this.state;
    const offers = [];

    if (masterListOfOffers && sponsor.offers) {
      sponsor.offers.forEach((offerSpec) => {
        const offer = masterListOfOffers[offerSpec.key];
        if (offer && offer.published) {
          offers.push(offer);
        }
      });
    }

    return offers;
  };

  archivedOffers = () => {
    const { sponsor, masterListOfOffers } = this.state;
    const offers = [];

    if (masterListOfOffers && sponsor.offers) {
      sponsor.offers.forEach((offerSpec) => {
        const offer = masterListOfOffers[offerSpec.key];
        if (offer && !offer.published) {
          offers.push(offer);
        }
      });
    }

    return offers;
  };

  inactiveTriggeredOffers = () => {
    const { sponsor, masterListOfOffers } = this.state;
    const offers = [];

    if (masterListOfOffers && sponsor.offers) {
      sponsor.offers.forEach((offerSpec) => {
        const offer = masterListOfOffers[offerSpec.key];
        if (offer && !offer.active && offer.triggered) {
          offers.push(offer);
        }
      });
    }

    return offers;
  };

  renderDealsSection = () => {
    const sponsorKey = this.props.match.params.sponsor_id;
    const { sponsor, masterListOfOffers, selectedOfferKey } = this.state;

    if (!sponsor || !masterListOfOffers) {
      return null;
    }

    const publishedOffers = this.publishedOffers();
    const archivedOffers = this.archivedOffers();

    return (
      <div className="deals-list">
        <Button
          key="allButton"
          className={`w-75 h-50 mb-2 mt-2 ${
            this.state.selectedOfferKey === "all"
              ? "btn-success"
              : "btn-secondary"
          }`}
          onClick={() => this.handleDealButtonClick("all")}
        >
          All Deals
        </Button>

        <div className="deals-heading">Published Offers</div>
        {publishedOffers.map((offer) => (
          <OfferButton
            key={offer.key}
            sponsorKey={sponsorKey}
            offer={offer}
            selectedOfferKey={selectedOfferKey}
            onClick={() => this.handleDealButtonClick(offer.key)}
            onToggleActiveClick={() => this.toggleActive(offer)}
            onTogglePublishClick={() => this.togglePublish(offer)}
            onTeamActivateClick={() => this.showOfferPreview(offer)}
          />
        ))}
        {publishedOffers.length === 0 && (
          <div className="no-deals-placeholder">(None)</div>
        )}

        <div className="deals-heading">Archived Offers</div>
        {archivedOffers.map((offer) => (
          <OfferButton
            key={offer.key}
            sponsorKey={sponsorKey}
            offer={offer}
            selectedOfferKey={selectedOfferKey}
            onClick={() => this.handleDealButtonClick(offer.key)}
            onToggleActiveClick={() => this.toggleActive(offer)}
            onTogglePublishClick={() => this.togglePublish(offer)}
          />
        ))}
        {archivedOffers.length === 0 && (
          <div className="no-deals-placeholder">(None)</div>
        )}
      </div>
    );
  };

  renderSelectedTransactionsTitle = () => {
    const { selectedOfferKey, masterListOfOffers } = this.state;
    if (selectedOfferKey === "all") {
      return "Redemptions & Purchases";
    } else {
      const offer = masterListOfOffers[selectedOfferKey];
      if (offer.flashDeal) {
        return "Purchases";
      } else {
        return "Redemptions";
      }
    }
  };

  renderSelectedOfferTitle = () => {
    const { selectedOfferKey, masterListOfOffers } = this.state;
    if (selectedOfferKey === "all") {
      return "All Deals";
    } else {
      return masterListOfOffers[selectedOfferKey].description;
    }
  };

  render() {
    const sponsorKey = this.props.match.params.sponsor_id;
    const { sponsor, transactions, filteredTransactions, offerToActivate } =
      this.state;

    if (!sponsor) {
      return null;
    }

    return (
      <div className="component-portal-sponsors-show animated fadeIn" id="main">
        <Row>
          <div className="col-12 col-md-3 d-block text-center mb-4">
            <Card className="d-block h-100">
              <img
                className="mt-5 mb-3"
                src={sponsor.picture}
                style={{ width: "200px" }}
                alt="Sponsor"
              />
              <div>
                <span>
                  <strong data-notranslate>{sponsor.name}</strong>
                </span>
                <br />
                {sponsor.email}
              </div>
            </Card>
          </div>
          <div className="col-12 col-md-9 d-block text-center align-items-stretch mb-4">
            <Card className="d-block h-100">
              <CardHeader>Redemption & Purchase Analytics</CardHeader>
              <div className="mt-1">
                <p style={{ color: "#25A919" }}>
                  <strong>
                    {transactions.length} Total Redemptions & Purchases
                  </strong>
                  <br />
                  (Active+Archive)
                </p>
                <p>
                  <strong>
                    {this.state.activeCount} Active{" "}
                    {this.renderSelectedTransactionsTitle()}
                  </strong>
                  <br />({this.renderSelectedOfferTitle()} -{" "}
                  {this.renderTransactionsPeriod()})
                </p>
              </div>
              <div className="mt-2">
                {isAdmin(this.props.authUser) ? (
                  <Link to={`/portal/sponsors/${sponsorKey}/deals/create`}>
                    <Button className={"btn-success"}>
                      <li className={"cui-note icons font-lg d-inline"}></li>
                      <span className={"ml-2 d-inline font-lg"}>
                        <strong>Add Deal</strong>
                      </span>
                    </Button>
                  </Link>
                ) : null}
              </div>
            </Card>
          </div>
        </Row>
        <Row>
          <div
            className="col-12 col-md-3 d-block text-center align-items-stretch mb-4"
            style={{ height: "100%" }}
          >
            <Card className="d-block h-100" style={{ overflow: "auto" }}>
              <CardHeader>Deals</CardHeader>
              {this.renderDealsSection()}
            </Card>

            {(isAdmin(this.props.authUser) ||
              isSponsor(this.props.authUser)) && (
              <Card className="d-block h-100" style={{ overflow: "auto" }}>
                <CardHeader>Purchases</CardHeader>
                <CardBody>
                  <Link to={`/portal/sponsors/${sponsorKey}/purchases`}>
                    <Button className={"btn-success"}>
                      <span className={"ml-2 d-inline font-lg"}>
                        <strong>Manage Purchases</strong>
                      </span>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            )}

            <Card className="d-block h-100" style={{ overflow: "auto" }}>
              <CardHeader>Training Documents</CardHeader>
              <CardBody>
                {this.state.document ? (
                  <a target="_blank" href={this.state.document}>
                    <Button className="btn-light mb-2">
                      View Training document
                    </Button>
                  </a>
                ) : null}
                <Link to={`/portal/sponsors/${sponsorKey}/documents/create`}>
                  <Button className={"btn-success"}>
                    <span className={"ml-2 d-inline font-lg"}>
                      <strong>Upload Document</strong>
                    </span>
                  </Button>
                </Link>
              </CardBody>
            </Card>
            {(isAdmin(this.props.authUser) ||
              isSponsor(this.props.authUser)) && (
              <Card>
                <CardHeader>Payments</CardHeader>
                <CardBody>
                  <Link to={`/portal/sponsors/${sponsorKey}/payments-setup`}>
                    <Button className={"btn-success"}>
                      <span className={"ml-2 d-inline font-lg"}>
                        <strong>Setup Payments</strong>
                      </span>
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            )}
            {isAdmin(this.props.authUser) ? (
              <Card className="d-block h-100" style={{ overflow: "auto" }}>
                <CardHeader>Delete Sponsor</CardHeader>
                <Button
                  className={"btn-danger mt-2 mb-2"}
                  onClick={(event) => this.handleDelete(event)}
                >
                  <span className={"ml-2 d-inline font-lg"}>
                    <strong>Delete Sponsor</strong>
                  </span>
                </Button>
              </Card>
            ) : null}
          </div>
          <div className="col-12 col-md-9">
            <Row>
              <div className="col-12 col-md-6">
                <Card>
                  <CardHeader>Gender Breakdown</CardHeader>
                  <CardBody>
                    <div className="chart-wrapper">
                      <Doughnut data={this.state.genderChartData} />
                    </div>
                  </CardBody>
                </Card>
              </div>
              <div className="col-12 col-md-6">
                <Card>
                  <CardHeader>Age Breakdown</CardHeader>
                  <CardBody>
                    <div className="chart-wrapper">
                      <Doughnut data={this.state.ageChartData} />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="col-12 col-md-12">
                <Card>
                  <CardHeader>
                    {this.renderSelectedTransactionsTitle()} by Day
                  </CardHeader>
                  <CardBody>
                    <TransactionsTable transactions={filteredTransactions} />
                  </CardBody>
                </Card>
              </div>
            </Row>
            <Row>
              <div className="col-12 col-md-12">
                <Card>
                  <CardHeader>
                    {this.renderSelectedTransactionsTitle()} by Location
                  </CardHeader>
                  <CardBody>
                    <LocationsTable transactions={filteredTransactions} />
                  </CardBody>
                </Card>
              </div>
            </Row>
          </div>
        </Row>
        {offerToActivate && (
          <OfferPreview
            offerKey={offerToActivate.key}
            onCancel={this.clearOfferPreview}
            onClose={this.clearOfferPreview}
            onActivate={() => {
              this.toggleActive(offerToActivate);
            }}
          />
        )}
      </div>
    );
  }
}

export default withAuthentication(withFirebase(Show));
