import { create } from "zustand";
import {
  loadAllSponsors,
  loadTeamSponsors,
  loadSponsorData,
} from "../utilities/team_helpers";

const useSponsorsStore = create((set, get) => ({
  sponsors: null,
  sponsor: null,
  teamSponsors: null,
  teamImpressions: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchSponsors: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const onSuccess = (sponsors) => {
        set({ sponsors });
      };

      const onError = (error) => {
        set({ error });
      };

      loadAllSponsors(firebase, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchTeamSponsors: async (firebase, teamId) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const onSuccess = (sponsors) => {
        const teamSponsorsObj = {};
        teamSponsorsObj[teamId] = sponsors;
        set({ teamSponsors: teamSponsorsObj });
      };

      const onError = (error) => {
        set({ error });
      };

      loadTeamSponsors(firebase, teamId, onSuccess, onError);
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchSponsor: async (firebase, sponsorId) => {
    try {
      const onSuccess = (sponsor) => {
        const sponsorObj = {};
        sponsorObj[sponsorId] = sponsor;
        set({ sponsor: sponsorObj });
      };

      const onError = (error) => {
        set({ error });
      };

      loadSponsorData(firebase, sponsorId, onSuccess, onError);
    } catch (error) {
      set({ error });
    }
  },

  setSponsor: (sponsor) => {
    const sponsorObj = {};
    sponsorObj[sponsor.id] = sponsor;
    set({ sponsor: sponsorObj });
  },
}));

export default useSponsorsStore;
