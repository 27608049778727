import { Icon } from "@iconify/react";
import React from "react";

const MapPin = ({ color, size }) => {
  return (
    <Icon icon="mdi:location" size={size || 20} color={color || "black"} />
  );
};

export default MapPin;
