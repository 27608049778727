/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Highlight, connectRefinementList } from "react-instantsearch-dom";

import "./ContestRefinementList.scss";

function RefinementList({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  currentRefinement,
}) {
  let contestDealRefinementItem;

  items.forEach((item) => {
    if (item.label === "true") {
      contestDealRefinementItem = item;
    }
  });

  return (
    <div className="component-deal-contest-refinement-list tab-headers level-2-headers">
      <a
        href="#"
        className={`tab-header ${
          currentRefinement.includes("true") ? "selected" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          refine("true");
        }}
      >
        Contests (
        {(contestDealRefinementItem && contestDealRefinementItem.count) || 0})
      </a>
    </div>
  );
}

const ContestRefinementList = connectRefinementList(RefinementList);

export default ContestRefinementList;
