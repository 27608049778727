import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

import './ExclusiveCodeLinkRefinementList.scss';

function RefinementList({
  currentRefinement,
  organizationKey
}){
  if(currentRefinement.includes('true')) {
    return (
      <div className='component-exclusive-code-link-refinement-list'>
        <Link to={`/unlock-exclusives?organizationKey=${organizationKey}`}>Have an exclusive code? Click here to enter it now.</Link>
      </div>
    );
  }

  return null;
}

const ExclusiveCodeLinkRefinementList = connectRefinementList(RefinementList);

export default ExclusiveCodeLinkRefinementList;