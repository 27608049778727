import React, { useState, useEffect, useRef, useCallback } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Pin from "../../../assets/img/team-marker.svg";
import "./TeamMarkerWithFollowButton.scss";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import useMobile from "../../../hooks/useMobile";
import { Icon } from "@iconify/react";
import { withAuthentication } from "../../../containers/Session";
import { withFirebase } from "../../../containers/Firebase";

const TeamName = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryWhite};
  text-align: center;
  flex: 2;
`;

const IconContainer = styled.div`
  background-color: ${({ isFollowed }) =>
    isFollowed ? COLORS.primaryBlue : COLORS.primaryGreenDark};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FollowIcon = styled(Icon)`
  color: ${COLORS.primaryWhite};
  border-radius: 50%;
  border: ${({ isFollowed }) =>
    isFollowed ? `1px solid ${COLORS.primaryWhite}` : "none"};
  cursor: pointer;
`;

const TeamMarkerWithFollowButton = ({ team, onClick, authUser, firebase }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selected, setSelected] = useState(null);
  const infoWindowRef = useRef();
  const isMobile = useMobile();
  const [isFollowed, setIsFollowed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authUser && authUser.teams) {
      setIsFollowed(authUser.teams.includes(team.objectID));
    }
  }, [authUser, team, team.key, team.objectID]);

  const handleOnClick = () => {
    setSelected(!selected);
    if (onClick && typeof onClick === "function") {
      onClick(team);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target)
      ) {
        setShowInfoWindow(false);
        setSelected(false);
        if (onClick && typeof onClick === "function") {
          onClick(null);
        }
      }
    },
    [onClick]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const followOrganization = async () => {
    if (!authUser || !team || !team.objectID) {
      return;
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams || [];
      teams.push(team.objectID);

      const userPath = `user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      await userRef.update({ teams });

      setIsFollowed(true);
      setSubmitting(false);
    } catch (error) {
      console.error("Error following organization", error);
      setSubmitting(false);
    }
  };

  const unfollowOrganization = async () => {
    if (!authUser || !team || !team.objectID) {
      return;
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams;
      const indexOfTeam = teams.indexOf(team.objectID);

      if (indexOfTeam > -1) {
        teams.splice(indexOfTeam, 1);

        const userPath = `user/${authUser.key}`;
        const userRef = firebase.database.ref(userPath);

        await userRef.update({ teams });

        setIsFollowed(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error unfollowing organization", error);
      setSubmitting(false);
    }
  };

  const handleFollowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (submitting) {
      return;
    }
    if (isFollowed) {
      unfollowOrganization();
    } else {
      followOrganization();
    }
  };

  return (
    <Marker
      key={team.objectID}
      position={{ lat: team._geoloc.lat, lng: team._geoloc.lng }}
      onClick={handleOnClick}
      onMouseOver={() => setShowInfoWindow(true)}
      onMouseOut={() => !selected && setShowInfoWindow(false)}
      icon={{
        url: Pin,
        scaledSize: new window.google.maps.Size(30, 30),
      }}
    >
      {(showInfoWindow || selected) && (
        <InfoWindow
          onCloseClick={() => {
            setShowInfoWindow(false);
            setSelected(false);
          }}
          options={{
            maxWidth: isMobile ? 140 : 200,
            minWidth: isMobile ? 140 : 200,
            pixelOffset: new window.google.maps.Size(120, 40),
            disableAutoPan: true,
            headerDisabled: true,
          }}
        >
          <div
            ref={infoWindowRef}
            className="team-marker-info-window-with-follow-button"
            onClick={handleFollowClick}
          >
            <TeamName isMobile={isMobile}>{team.name}</TeamName>
            <IconContainer isMobile={isMobile} isFollowed={isFollowed}>
              <FollowIcon
                icon={isFollowed ? "mdi:minus" : "mdi:plus"}
                isFollowed={isFollowed}
                width={25}
                height={25}
              />
            </IconContainer>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default withAuthentication(withFirebase(TeamMarkerWithFollowButton));
