import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

function TransactionsTable({transactions}) {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th></th>
          <th>Date</th>
          <th>Offer</th>
          <th>Gender</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        {
          transactions.map((transaction, index) => {
            const transactionMoment = moment(transaction.time);
            const dateStr = transactionMoment.format('MMM DD, YYYY [at] h:mm:ss A');
      
            return (
              <tr key={index}>
                <td>
                  {
                    transaction.transactionType === 'redemption' ?
                      <i className='fa fa-ticket' title='Redemption' />
                    :
                      <i className='fa fa-dollar' title='Purchase' />
                  }
                </td>
                <td>{dateStr}</td>
                <td>{transaction.offer.description}</td>
                <td>{transaction.user.gender}</td>
                <td>{transaction.userAge}</td>
              </tr>
            );
          })
        }
      </tbody>
    </Table>
  );
}

export default TransactionsTable;