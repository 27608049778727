const checkImageUploads = (brandData, imageUrls) => {
  if (brandData.brandType === "team") {
    if (!imageUrls.logoUrl || !imageUrls.pictureUrl) {
      return false;
    }
  } else {
    if (!imageUrls.logoUrl) {
      return false;
    }
  }

  return true;
};

const prepBrandData = (brandData, imageUrls) => {
  const dataSet = {
    brandType: brandData.brandType,
    name: brandData.name,
  };

  if (brandData.brandType === "team") {
    dataSet.categories = brandData.categories;
    dataSet.subcategories = brandData.subcategories;
    dataSet.teamKeys = brandData.teamKeys;
  } else {
    dataSet.teamKey = brandData.teamKey;
    dataSet.sponsorKeys = brandData.sponsorKeys;
  }

  if (imageUrls.logoUrl) {
    dataSet.logo = imageUrls.logoUrl;
  }

  if (imageUrls.pictureUrl) {
    dataSet.picture = imageUrls.pictureUrl;
  }

  return dataSet;
};

export { checkImageUploads, prepBrandData };
