import React from 'react';
import numeral from 'numeral';

import './Price.scss';

const getCurrentyPrefix = (currency) => {
  switch(currency){
    case "CAD": return "C";
    case "USD": return "US";
    default: return "C"
  }
};

export default ({ value, className, currency }) => {
  return (
    <span className={`component-price ${className}`}>
      {getCurrentyPrefix(currency)}
      ${numeral(value).format('0,0.00')}
    </span>
  );
}