/* eslint-disable react-hooks/exhaustive-deps */
/* https://agatatocz.medium.com/countdown-timer-with-months-using-react-and-moment-js-fa40b86c2dd4 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  INSTORE_REDEMPTION_TIME_IN_MINUTES,
  DATABASE_DATETIME_FORMAT,
} from "../../utilities/constants";

import "../../scss/base/typography.scss";
import "./Countdown.scss";

function Countdown({
  targetDateTimeString,
  fullText,
  onStopCountDown,
  dealType,
  isMobile,
}) {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const targetMoment = moment(
      targetDateTimeString,
      DATABASE_DATETIME_FORMAT
    ).add(INSTORE_REDEMPTION_TIME_IN_MINUTES, "minutes");
    const interval = setInterval(() => {
      const diff = targetMoment.diff(moment());
      if (diff <= 0) {
        clearInterval(interval);
        if (onStopCountDown) {
          onStopCountDown(true);
        }
      } else {
        setCountdown(moment.utc(diff).format("mm:ss"));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="component-countdown">
      {fullText ? (
        <span
          className={`countdown-container full-text ${
            isMobile ? "mobile-b1" : "standard-h4"
          }`}
        >
          <i className="fa fa-clock-o" />
          <span
            className={`countdown-full-text  ${
              isMobile ? "mobile-b1" : "standard-h6"
            }`}
          >
            {dealType === "online" && !isMobile && "Code will disappear in"}
            {(dealType === "instore" || (dealType === "online" && isMobile)) &&
              "Offer expires in"}
          </span>
          <span
            className={`count-caption ${
              isMobile ? "mobile-b2" : "standard-h5"
            }`}
            data-notranslate
          >
            {countdown && countdown}{" "}
          </span>
        </span>
      ) : (
        <span
          className={`countdown-container ${
            isMobile ? "mobile-h2" : "standard-h2"
          }`}
        >
          <i className="fa fa-clock-o" />
          <span className="count-caption" data-notranslate>
            {countdown && countdown}{" "}
          </span>
        </span>
      )}
    </div>
  );
}

Countdown.propTypes = {
  targetDateTimeString: PropTypes.string.isRequired,
};

export default Countdown;
