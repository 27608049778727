import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { categorySelectorStore } from "../../stores/categorySelectorStore";
import { withFirebase } from "../Firebase";
import { Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { selectorStyle } from "../../views/Portal/utilities/PortalFormStyles";

const CategorySelector = observer(
  ({
    firebase,
    categories: categoryNameArray,
    subcategories: subCategoryNameArray,
    categoriesOnUpdate,
    subcategoriesOnUpdate,
  }) => {
    const {
      initialize,
      updateSubCategories,
      categories,
      subcategories,
      cleanUp,
    } = categorySelectorStore;

    const [currentCategoryName, setCurrentCategoryName] = useState();

    useEffect(() => {
      let defaultCategoryName = null;

      if (categoryNameArray.length > 0) {
        defaultCategoryName = categoryNameArray[0];
      }

      setCurrentCategoryName(defaultCategoryName);
      initialize(firebase, defaultCategoryName);
      return cleanUp;
    }, []);

    useEffect(() => {
      updateSubCategories(currentCategoryName);
    }, [currentCategoryName]);

    const toggleCategorySelection = (categoryName) => {
      if (currentCategoryName === categoryName) {
        const isCategoryAlreadySelected =
          categoryNameArray.includes(categoryName);
        const categoryObject = categories.find(
          (category) => category.name === categoryName
        );
        const categoryObjectsSubcategoryNames =
          categoryObject.subCategories.map(
            (subCategoryObj) => subCategoryObj.name
          );

        if (isCategoryAlreadySelected) {
          // Remove category from selected category array
          categoriesOnUpdate(
            categoryNameArray.filter(
              (selectedCategoryName) => selectedCategoryName !== categoryName
            )
          );

          // Remove all subcategories of that category
          subcategoriesOnUpdate(
            subCategoryNameArray.filter((selectedSubCategoryName) => {
              const isSelectedSubCategoryNameInSelectedCategory =
                categoryObjectsSubcategoryNames.includes(
                  selectedSubCategoryName
                );
              return !isSelectedSubCategoryNameInSelectedCategory;
            })
          );
        } else {
          categoriesOnUpdate([...categoryNameArray, categoryName]);
        }
      } else {
        categoriesOnUpdate([...categoryNameArray, categoryName]);
      }

      setCurrentCategoryName(categoryName);
    };

    const toggleSubCategorySelection = (subCategoryName) => {
      const isSubCategoryAlreadySelected =
        subCategoryNameArray.includes(subCategoryName);

      if (isSubCategoryAlreadySelected) {
        subcategoriesOnUpdate(
          subCategoryNameArray.filter(
            (selectSubCategoryName) => selectSubCategoryName !== subCategoryName
          )
        );
      } else {
        subcategoriesOnUpdate([...subCategoryNameArray, subCategoryName]);
      }
    };

    return (
      <Row>
        <Col sm={6}>
          <ListGroup>
            {categories.map((category) => (
              <ListGroupItem
                className="category-selectable"
                onClick={() => toggleCategorySelection(category.name)}
                key={category.categoryId}
                active={currentCategoryName === category.name}
                style={selectorStyle}
              >
                {category.name}
                {categoryNameArray.includes(category.name) && (
                  <span className="cui-check font-md d-inline float-right" />
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
        <Col sm={6}>
          <ListGroup>
            {subcategories.map((subcategory) => (
              <ListGroupItem
                key={subcategory.id}
                className="category-selectable"
                onClick={() => toggleSubCategorySelection(subcategory.name)}
                style={selectorStyle}
              >
                {subcategory.name}
                {subCategoryNameArray.includes(subcategory.name) && (
                  <span className="cui-check font-md d-inline float-right" />
                )}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </Row>
    );
  }
);

export default withFirebase(CategorySelector);
