import React, { Component, Suspense } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Button } from "../../../containers/UIKit";
import { Container } from "reactstrap";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import logo from "../../../assets/img/brand/logo.png";
import { Icon } from "@iconify/react";

import "./PortalLayout.scss";

// sidebar nav config
import { adminNavigation, teamNavigation, sponsorNavigation } from "./_nav";
import {
  adminNavigationMenu,
  sponsorNavigationMenu,
  teamNavigationMenu,
} from "./PortalAsideMenu/_navMenu";
// routes config
import {
  adminUserPortalRoutes,
  teamUserPortalRoutes,
  sponsorUserPortalRoutes,
} from "../../../routes_portal_layout";

const PortalAsideMenu = React.lazy(() =>
  import("./PortalAsideMenu/PortalAsideMenu")
);

const isAdmin = (authUser) => authUser && authUser.role === "admin";
const isSponsor = (authUser) => authUser && authUser.role === "sponsor";
const isTeam = (authUser) => authUser && authUser.role === "team";

class PortalLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
    };
  }

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  canViewPortal = () => {
    const { authUser } = this.props;
    return isAdmin(authUser) || isTeam(authUser) || isSponsor(authUser);
  };

  signOut(e) {
    e.preventDefault();
    this.props.history.push("/login");
  }

  navigationControl = () => {
    if (isAdmin(this.props.authUser)) {
      return adminNavigation;
    } else if (isTeam(this.props.authUser)) {
      return teamNavigation;
    } else if (isSponsor(this.props.authUser)) {
      return sponsorNavigation;
    }
  };

  menuNavigationControl = () => {
    if (isAdmin(this.props.authUser)) {
      return adminNavigationMenu;
    } else if (isTeam(this.props.authUser)) {
      return teamNavigationMenu;
    } else if (isSponsor(this.props.authUser)) {
      return sponsorNavigationMenu;
    }
  };

  routeComponentControl = () => {
    if (isAdmin(this.props.authUser)) {
      return adminUserPortalRoutes.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => <route.component {...props} />}
          />
        ) : null;
      });
    } else if (isTeam(this.props.authUser)) {
      return teamUserPortalRoutes.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => <route.component {...props} />}
          />
        ) : null;
      });
    } else if (isSponsor(this.props.authUser)) {
      return sponsorUserPortalRoutes.map((route, idx) => {
        return route.component ? (
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => <route.component {...props} />}
          />
        ) : null;
      });
    }
  };

  toggleMobileMenu = () => {
    const { showMobileMenu } = this.state;
    this.setState({ showMobileMenu: !showMobileMenu });
  };

  render() {
    const { authUser } = this.props;
    const { showMobileMenu } = this.state;

    return (
      <div className="app component-portal-layout">
        {!this.canViewPortal() ? (
          <div className="portal-unauthorized">
            <div className="logo-container">
              <img src={logo} className="logo" />
            </div>
            {authUser ? (
              <div>
                <div className="main-message">
                  Welcome to the portal. To continue, please logout and login as
                  a user that has the proper permissions.
                </div>
                <div className="actions">
                  <Button
                    routerLink
                    to="/logout?toLoginToPortal=true"
                    className="primary"
                  >
                    Logout
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="main-message">
                  Welcome to the FanSaves Portal! Please log in below
                </div>
                <div className="actions">
                  <Button
                    routerLink
                    to="/login?toPortal=true"
                    className="primary wide"
                  >
                    Login
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="portal-app-body">
              <div className="mobile-navigation-container">
                <a
                  href="#"
                  className="mobile-menu-toggle"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleMobileMenu();
                  }}
                >
                  <Icon icon="codicon:three-bars" />
                </a>
                <Suspense fallback={this.loading()}>
                  <PortalAsideMenu
                    navigationList={this.menuNavigationControl()}
                    user={this.props.authUser}
                    history={this.props.history}
                    forMobile={true}
                    showMobile={showMobileMenu}
                    onMobileClose={this.toggleMobileMenu}
                  />
                </Suspense>
              </div>
              <Suspense fallback={this.loading()}>
                <PortalAsideMenu
                  navigationList={this.menuNavigationControl()}
                  user={this.props.authUser}
                  history={this.props.history}
                />
              </Suspense>
              <main className="main">
                <Container fluid>
                  <Suspense fallback={this.loading()}>
                    <Switch>{this.routeComponentControl()}</Switch>
                  </Suspense>
                </Container>
              </main>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withFirebase(withAuthentication(withRouter(PortalLayout)));
