import cloudFunctionHost from "../containers/CloudFunctionsHost";

const updateRedemptionsWithDeviceInfo = async (
  redemption,
  firebase,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      redemption,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/updateRedemptionsWithDeviceInfo`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseData) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseData);
        }
        console.log(responseData);
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError();
        }
        console.error(error);
      });
  });
};

export { updateRedemptionsWithDeviceInfo };
