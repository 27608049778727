import React from "react";
import {
  InstantSearch,
  Hits,
  PoweredBy,
  Configure,
} from "react-instantsearch-dom";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import {
  searchClient,
  SearchBox,
  VirtualRefinementList,
  SortedResultsWithEmptyState,
} from "../../../containers/Algolia";
import { EmbedSizeDealSearchResult } from "../../../containers/DealSearchResult";
import { orderBy } from "lodash";
import SpecialDealsRefinementList from "./SpecialDealsRefinementList";
import { withAuthentication } from "../../../containers/Session";
import UnlockExclusives from "../../Pages/UnlockExclusives/UnlockExclusives";

import "./ContestsTab.scss";

const StyledSpecialDealsTab = styled.div`
  min-height: 200px;
  position: relative;
  .component-unlock-exclusives {
    padding: 20px;
  }
  .component-unlock-exclusives::before {
    top: 0;
  }
`;

function SpecialDealsTab({ organization, authUser }) {
  const haveAccessToSpecialDeals = () => {
    return (
      authUser &&
      authUser.unlockedSpecialDeals &&
      authUser.unlockedSpecialDeals[organization.key]
    );
  };

  const tagFilters = () => {
    if (authUser && authUser.unlockedSpecialDeals) {
      const accessTags = Object.entries(authUser.unlockedSpecialDeals).flatMap(
        ([teamKey, value]) => {
          return Object.keys(value).map(
            (accessLevel) => `hidden${teamKey}-${accessLevel}`
          );
        }
      );

      const tags = [[...accessTags, "-hidden"]];

      return tags;
    }

    return ["-hidden"];
  };
  return (
    <InstantSearch searchClient={searchClient} indexName="offers">
      <Configure
        filters="published:true AND NOT contestDeal:true"
        tagFilters={tagFilters()}
      />
      <StyledSpecialDealsTab className="component-contests-tab">
        {!haveAccessToSpecialDeals() ? (
          <UnlockExclusives teamKey={organization && organization.key} />
        ) : (
          <div className="deals-list">
            <VirtualRefinementList
              attribute="teams.name"
              transformItems={(items) => orderBy(items, "label", "asc")}
              defaultRefinement={[organization.name]}
            />
            <VirtualRefinementList
              attribute="published"
              defaultRefinement={["true"]}
            />
            <VirtualRefinementList
              attribute="hiddenDeal"
              defaultRefinement={["true"]}
            />

            <div className="deal-filters">
              <SpecialDealsRefinementList
                attribute="hiddenDeal"
                transformItems={(items) => orderBy(items, "label", "asc")}
                defaultRefinement={["true"]}
              />
              <div className="search-bar-container">
                <SearchBox
                  hideSubmit={true}
                  placeholder="Search special deals..."
                />
              </div>
            </div>
            <div className="search-results">
              <SortedResultsWithEmptyState sortedBy="pinned">
                <Hits hitComponent={EmbedSizeDealSearchResult} />
              </SortedResultsWithEmptyState>

              <div className="algolia-powered-by-container">
                <PoweredBy />
              </div>
            </div>
          </div>
        )}
      </StyledSpecialDealsTab>
    </InstantSearch>
  );
}

export default withAuthentication(SpecialDealsTab);
