import React, { useState, useEffect } from "react";
import { withAuthentication } from "../../../containers/Session";
import { withFirebase } from "../../../containers/Firebase";
import ModalContainer from "../../../containers/Modals/Modal";
import { COLORS } from "../colors";

import { FormGroup, Label, Input } from "reactstrap";

import styled from "styled-components/macro";
import useMobile from "../../../hooks/useMobile";
import { Button } from "../../../containers/UIKit";
import ErrorMessage from "./ErrorMessage";

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  margin-bottom: 20px;
  text-align: center;
`;

const TermsConditionsLabel = styled(Label)`
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  height: 100%;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 100%;
  margin-top: 20px;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const ErrorMessageContainer = styled.div`
  padding-left: 0;
  width: 100%;
`;

const TermsConditionsModal = ({ onClose, authUser, firebase }) => {
  const [subscribeToUpdates, setSubscribeToUpdates] = useState(false);
  const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] =
    useState(false);
  const [agreeToTermsAndConditionsError, setAgreeToTermsAndConditionsError] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (!authUser) return;
    setSubscribeToUpdates(authUser.subscribeToUpdates);
    setAgreeToTermsAndConditions(authUser.agreeToTermsAndConditions);
  }, [authUser]);

  const handleSubmit = async () => {
    if (!agreeToTermsAndConditions || submitting) return;
    if (!authUser) return;
    try {
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      setSubmitting(true);
      const userUpdates = {
        subscribeToUpdates: subscribeToUpdates ? subscribeToUpdates : false,
        agreeToTermsAndConditions: agreeToTermsAndConditions
          ? agreeToTermsAndConditions
          : false,
        agreeToTermsAndConditionsAt:
          firebase.databaseServerValue.TIMESTAMP || "",
      };

      await userRef.update(userUpdates);

      setSubmitting(false);
      setAgreeToTermsAndConditionsError(false);

      if (onClose && typeof onClose === "function") {
        onClose();
      }
    } catch (error) {
      console.error(error);
      setAgreeToTermsAndConditionsError(true);
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    if (!agreeToTermsAndConditions) {
      setAgreeToTermsAndConditionsError(true);
      return;
    }
    if (onClose && typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <ModalContainer onClose={handleClose}>
      <div className="sign-up-checkboxes">
        <Title>Before you continue</Title>
        <FormContainer>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={subscribeToUpdates}
              onChange={(e) => setSubscribeToUpdates(e.target.checked)}
            />{" "}
            <TermsConditionsLabel check isMobile={isMobile}>
              Keep me up to date on the hottest deals and newest FanPages
            </TermsConditionsLabel>
          </FormGroup>
          <FormGroup check>
            <Input
              error={agreeToTermsAndConditionsError}
              type="checkbox"
              checked={agreeToTermsAndConditions}
              onChange={(e) => setAgreeToTermsAndConditions(e.target.checked)}
            />{" "}
            <TermsConditionsLabel check isMobile={isMobile}>
              I agree to the{" "}
              <a
                href="https://fansaves.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className="underlined-link"
              >
                Terms and Conditions
              </a>{" "}
              and have read the{" "}
              <a
                href="https://fansaves.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underlined-link"
              >
                Privacy Policy
              </a>
              .
            </TermsConditionsLabel>
          </FormGroup>
          {agreeToTermsAndConditionsError && (
            <ErrorMessageContainer>
              <ErrorMessage error="Please agree to the Terms and Conditions to continue." />
            </ErrorMessageContainer>
          )}
          <ButtonContainer>
            <Button
              className={`green-continue rounded-10 wide ${
                (submitting || !agreeToTermsAndConditions) && "disabled"
              }`}
              onClick={handleSubmit}
            >
              <StyledButtonTitle
                disabled={submitting || !agreeToTermsAndConditions}
              >
                Continue
              </StyledButtonTitle>
            </Button>
          </ButtonContainer>
        </FormContainer>
      </div>
    </ModalContainer>
  );
};

export default withFirebase(withAuthentication(TermsConditionsModal));
