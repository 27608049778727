import React from 'react';
import DealListing from '../DealsList/DealListing';
import { withEmbedMode } from '../EmbedModeContext';

const EmbedSizeDealSearchResult = (props) => {
  const { hit, embedMode } = props;
  
  return (
    <DealListing deal={hit} size={embedMode ? 'small' : 'large'} />
  );
};

export default withEmbedMode(EmbedSizeDealSearchResult);