import React, { useState, useEffect, lazy, Suspense } from "react";
import { withFirebase } from "../../../../../containers/Firebase";
import { withRouter } from "react-router-dom";
import {
  FlexContainer,
  CSVExport,
  SpinLoader,
  DashboardTitle,
  GLOBALS,
} from "../../../../../assets/fansaves-ui";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import moment from "moment";
import { DashboardHeader } from "../../../../../containers/UIKit";

import { getLocation } from "../../../../../utilities/dashboard_helpers";
import useRedemptionsStore from "../../../../../stores/redemptionsStore";
import useSponsorsStore from "../../../../../stores/sponsorsStore";
import useTeamsStore from "../../../../../stores/teamsStore";

const DashboardRedemptionsTable = lazy(() =>
  import("../../../../../containers/DashboardRedemptionsTable")
);

const StyledRedemptionsList = styled.div`
  .redemptions-total {
    margin-top: 20px;
    width: fit-content;
    display: flex;
    direction: row;
    gap: 10px;
  }
  .component-dashboard-redemptions-table {
    .component-dashboard-filter {
      top: -20px;
    }
    .component-dashboard-filter-display {
      top: -70px;
    }
    .search-bar {
      top: -120px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .component-dashboard-filter {
        top: 130px;
      }
      .search-bar {
        margin-bottom: 30px;
      }
    }
  }
  .redemptions-list-header {
    position: relative;
    .link-container {
      position: absolute;
      top: 0px;
      right: 30px;
    }
    .logo-redemptions-list-header {
      margin: 30px 0 0 0;
    }

    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .link-container {
        position: relative;
        margin-left: 30px;
      }
    }
  }
`;

const DealRedemptions = ({ firebase, history, match }) => {
  const dealID = match.params.deal_id;
  const sponsorId = match.params.sponsor_id;
  const fetchDealRedemptions = useRedemptionsStore(
    (state) => state.fetchDealRedemptions
  );
  const dealRedemptionsFromStore = useRedemptionsStore(
    (state) => state.dealRedemptions
  );
  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const sponsorFromStore = useSponsorsStore((state) => state.sponsor);
  const fetchSponsor = useSponsorsStore((state) => state.fetchSponsor);
  const [redemptions, setRedemptions] = useState(null);

  const [sponsor, setSponsor] = useState(null);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    const teamId = sponsor && sponsor.teams && Object.keys(sponsor.teams)[0];
    if (teamId && teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [sponsor, teamFromStore]);

  useEffect(() => {
    if (sponsorFromStore && sponsorFromStore[sponsorId]) {
      setSponsor(sponsorFromStore[sponsorId]);
    }
  }, [sponsorFromStore, sponsorId]);

  useEffect(() => {
    if (dealID && sponsor) {
      const teamId = sponsor && sponsor.teams && Object.keys(sponsor.teams)[0];
      const sponsorId = sponsor && sponsor.key;
      fetchDealRedemptions(firebase, dealID, sponsorId, teamId);
    }
  }, [dealID, fetchDealRedemptions, firebase, sponsor, sponsorId]);

  useEffect(() => {
    if (!dealRedemptionsFromStore || !dealRedemptionsFromStore[dealID]) {
      setRedemptions([]);
    } else {
      setRedemptions(dealRedemptionsFromStore[dealID]);
    }
  }, [dealRedemptionsFromStore, dealID]);

  useEffect(() => {
    if (!sponsorId) {
      setSponsor(null);
      return;
    }
    if (!sponsor || (sponsor && sponsor.key !== sponsorId)) {
      fetchSponsor(firebase, sponsorId);
    }
  }, [sponsor, sponsorId, fetchSponsor, firebase, setSponsor]);

  useEffect(() => {
    if (!sponsor) {
      setTeam(null);
      return;
    }
    const teamId = sponsor && sponsor.teams && Object.keys(sponsor.teams)[0];
    if ((teamId && !team) || (team && team.key !== teamId)) {
      fetchTeam(firebase, teamId);
    }
  }, [sponsor, team, fetchTeam, firebase, setTeam]);

  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "Date of Redemption", key: "dateOfRedemption" },
      { label: "Offer", key: "offer" },
      { label: "Name", key: "name" },
      { label: "Gender", key: "gender" },
      { label: "Age", key: "age" },
      { label: "Location", key: "location" },
      { label: "Email", key: "email" },
      { label: "Account Created", key: "accountCreated" },
    ];
  };

  const generateData = () => {
    if (!redemptions) return null;
    const data = redemptions.reduce((acc, redemption) => {
      const { time, offer, user, userAge, userCity } = redemption;

      let userLocation = "N/A";

      if (userCity) {
        userLocation = userCity;
      } else if (user && user.zip) {
        const location = getLocation(user.zip);
        userLocation = location.city;
      }

      const redemptionData = {
        index: acc.length + 1,
        dateOfRedemption: time
          ? moment(time).format("YYYY/MM/DD HH:mm A")
          : "N/A",
        offer: (offer && offer.description) || "N/A",
        name: (user && user.name) || "N/A",
        gender: (user && user.gender) || "N/A",
        age: userAge || "N/A",
        location: userLocation,
        email: (user && user.email) || "N/A",
        accountCreated:
          user && user.createdAt
            ? moment(user.createdAt).format("YYYY/MM/DD HH:mm A")
            : "N/A",
      };

      acc.push(redemptionData);
      return acc;
    }, []);

    return data;
  };

  return (
    <StyledRedemptionsList className="component-redemptions-list">
      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="redemptions-list-header"
        margin="30px 0"
      >
        <DashboardTitle
          title="Redemption Information"
          justifyContent="flex-start"
          backButtonPath={
            sponsorId ? `/portal/sponsors/${sponsorId}/dashboard` : ""
          }
          className="standard-h5 blue"
        />
        {sponsor && (
          <FlexContainer
            direction="horizontal"
            justifyContent="space-between"
            width="100%"
            responsive
            mobileDirection="column-reverse"
          >
            <DashboardHeader
              logo={sponsor.picture}
              name={sponsor.name}
              email={sponsor.displayEmail}
              profileUrl={sponsor.url}
              hideEl
              className="logo-redemptions-list-header"
            />
            {team && (
              <DashboardHeader
                logo={team.logo}
                name={team.name}
                email={team.displayEmail}
                profileUrl={team.website}
                hideEl
                secondaryHeader
              />
            )}
          </FlexContainer>
        )}

        <div className="link-container">
          {redemptions && redemptions.length > 0 && (
            <CSVExport
              data={generateData()}
              headers={generateHeaders()}
              filename={
                sponsor && sponsor.name && dealID
                  ? `${sponsor.name}-${dealID}-redemptions-${moment().format(
                      "MM-DD-YYYY"
                    )}`
                  : `redemptions-list-${moment().format("MM-DD-YYYY")}`
              }
              target="_blank"
              className="rubik-h3"
            >
              <Icon icon="fa-solid:file-export" className="user-logo-icon" />{" "}
              Export Data
            </CSVExport>
          )}
        </div>
      </FlexContainer>
      <FlexContainer>
        {!redemptions ? (
          <div className="no-organizations">
            <SpinLoader />
          </div>
        ) : (
          <Suspense fallback={<SpinLoader />}>
            <DashboardRedemptionsTable redemptions={redemptions} />
          </Suspense>
        )}
      </FlexContainer>
    </StyledRedemptionsList>
  );
};

export default withFirebase(withRouter(DealRedemptions));
