import Create from "./Create";
import Edit from "./Edit";
import Show from "./Show";
import List from "./List";
import RedemptionsList from "./Redemptions/RedemptionsList";
import DealRedemptions from "./DealRedemptions";
import { isBlank } from "../../../../utilities/core";
import { setupForFlashDeals } from "../../../../containers/DataValidationHelpers";

const validateDeal = (deal, sponsor, sponsorTeam) => {
  const {
    picturePath,
    delayHour,
    delayMonth,
    delayWeek,
    delayDay,
    dealType,
    flashDeal,
    currency,
    contestDeal,
    hiddenDeal,
    accessLevels,
  } = deal;

  if (!sponsorTeam) {
    alert("Team is not defined. Please contact support team.");
    return false;
  }

  if (!picturePath) {
    alert("Please set the picture");
    return false;
  }

  if (typeof delayHour !== "number") {
    alert("Deal Delay Hour must be a number");
    return false;
  }

  if (typeof delayMonth !== "number") {
    alert("Deal Delay Month must be a number");
    return false;
  }

  if (typeof delayWeek !== "number") {
    alert("Deal Delay Week must be a number");
    return false;
  }

  if (typeof delayDay !== "number") {
    alert("Deal Delay Day must be a number");
    return false;
  }

  if (!flashDeal && !contestDeal) {
    if (!delayHour && !delayMonth && !delayWeek && !delayDay) {
      alert("Please set a delay for the deal");
      return false;
    }
  }

  if (hiddenDeal) {
    if (!accessLevels || accessLevels.length === 0) {
      alert("Please select at least one access level");
      return false;
    }
  }

  if (dealType === "instore" && isBlank(sponsor["redeem radius"])) {
    alert(
      `You cannot create In-Store deals because ${sponsor.name} is missing a Redeem Radius value.`
    );
    return false;
  }

  if (!setupForFlashDeals(sponsor, sponsorTeam) && flashDeal) {
    alert(
      "Unfortunately you cannot setup Flash Deals for this business. The business does not have payments setup."
    );
    return false;
  }

  if (currency !== "CAD" && currency !== "USD") {
    alert("Currency must be either CAD or USD");
    return false;
  }

  return true;
};

export {
  Create,
  Edit,
  Show,
  List,
  validateDeal,
  RedemptionsList,
  DealRedemptions,
};
