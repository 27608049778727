import React from 'react';
import LoadingPlaceholder from '../LoadingPlaceholder';
import OrganizationIconLink from '../OrganizationIconLink';
import { Link } from 'react-router-dom';

import './FollowedOrganizations.scss';

function FollowedOrganizations({ organizations, loading }) {
  const loadingPlaceholderCount = 12;
  const loadingPlaceholders = [];
  for (let i = 0; i < loadingPlaceholderCount; i++) {
    loadingPlaceholders.push(<div key={i} className='for-flex-overflow'><LoadingPlaceholder style={{display: 'flex', height: 80, width: 80, borderRadius: 40, marginRight: 15}} /></div>);
  }

  return (
    <div className='component-followed-organizations'>
      {
        loading ?
          loadingPlaceholders
        :
          organizations.map((organization) => 
            <OrganizationIconLink key={organization.key} organization={organization} />
          )
      }
      <div className='for-flex-overflow'>
        <Link to="/organizations" className="follow-more">
          +
        </Link>
      </div>
    </div>
  );
}

export default FollowedOrganizations;