import React from 'react';

function DealPercentageOff({deal}) {

  if(!deal || !deal.flashDeal || !deal.price || !deal.originalValue || deal.originalValue <= 0) {
    return null;
  }

  const percentageOff = parseInt((deal.originalValue - deal.price) / (deal.originalValue) * 100);

  return (
    <span className='component-deal-percentage-off'>
      {percentageOff}% OFF
    </span>
  );
}

export default DealPercentageOff;