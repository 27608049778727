import React from "react";
import BrandForm from "../Form";
import { uploadResourceImages } from "../../utilities/FormHelpers";
import { withFirebase } from "../../../../containers/Firebase";
import { checkImageUploads, prepBrandData } from "../brand_utils";

function Create({ firebase, history }) {
  function pushBrandData(brandKey, brandData, imageUrls) {
    if (checkImageUploads(brandData, imageUrls)) {
      const dataSet = prepBrandData(brandData, imageUrls);

      const brandRef = firebase.database.ref(`/brand/${brandKey}`);
      brandRef.set(dataSet).then(() => {
        history.push("/portal/brands");
      });
    } else {
      window.alert("You must upload photos.");
    }
  }

  function handleBrandFormSubmit(brandData) {
    const brandRef = firebase.database.ref("brand").push();
    const brandKey = brandRef.key;

    const imageTypes =
      brandData.brandType === "team" ? ["logo", "picture"] : ["logo"];

    uploadResourceImages(
      firebase,
      brandKey,
      brandData,
      "images/brands",
      imageTypes,
      pushBrandData
    );
  }

  return (
    <div>
      <h1>Create Brand</h1>
      <BrandForm onSubmit={handleBrandFormSubmit} />
    </div>
  );
}

export default withFirebase(Create);
