import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const UserNameForm = ({
  firstName = "",
  lastName = "",
  userName = "",
  onChange,
  handleUsernameCheck,
  errors = {},
  suggestions = [],
  onFieldChangeClearError,
}) => {
  const handleUserInput = (e) => {
    onChange(e);
    onFieldChangeClearError(e.target.name);
  };

  return (
    <div className="form-section">
      <h2>Personal Info</h2>
      <div className="row">
        <div className="col-sm-12">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              name="firstName"
              value={firstName}
              onChange={handleUserInput}
              placeholder="First Name"
            />
            {errors.firstName && (
              <span className="text-danger">{errors.firstName}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              name="lastName"
              value={lastName}
              onChange={handleUserInput}
              placeholder="Last Name"
            />
            {errors.lastName && (
              <span className="text-danger">{errors.lastName}</span>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Username</Label>
            <Input
              name="userName"
              value={userName}
              onChange={handleUserInput}
              placeholder="Username"
              onBlur={handleUsernameCheck}
            />
            {errors.userName && (
              <span className="text-danger">{errors.userName}</span>
            )}
            {suggestions.length > 0 && (
              <div className="suggestions">
                <span>Username suggestions:</span>
                <ul>
                  {suggestions.map((suggestion, index) => (
                    <li key={index}>{suggestion}</li>
                  ))}
                </ul>
              </div>
            )}
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default UserNameForm;
