import React from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../containers/Firebase';
import CheckOnboarding from '../../../containers/CheckOnboarding';
import { withRouter } from 'react-router-dom';
import DealReceipt from '../../../containers/DealReceipt';
import { DefaultContainer } from '../../../containers/UIKit';

import './PurchaseConfirmation.scss';

class PurchaseConfirmation extends React.Component {
  render() {
    const { 
      match,
    } = this.props;

    const dealKey = match.params.deal_id;

    return(
      <CheckOnboarding>
        <DefaultContainer>
          <div className='views-deals-purchase-confirmation'>
            <div className='message'>
              <div className='main'>
                Thank you for your purchase!
              </div>
              <div className='instructions'>
                Thank you for purchasing this deal. When you are ready to redeem it,
                simply click on <Link to='/purchases'>My Purchases</Link>.
              </div>
              <div className='disclaimer'>
                Please note that the purchase may take a minute to show up in My Purchases.
              </div>
            </div>
            <DealReceipt dealKey={dealKey} />
            <div className='more-deals-container'>
              <Link to='/deals'>Browse more deals</Link>
            </div>
          </div>
        </DefaultContainer>
      </CheckOnboarding>
    );
  }
}

export default withRouter(withFirebase(PurchaseConfirmation));