import React from 'react';
import CheckOnboarding from '../../../containers/CheckOnboarding';
import { withFirebase } from '../../../containers/Firebase';
import { withAuthentication } from '../../../containers/Session';
import { loadTeams } from '../../../utilities/team_helpers';
import OrganizationBrowser from "../OrganizationBrowser";

import './List.scss';

class List extends React.Component {
  state = {
    loadingOrganizations: true,
    organizations: [],
  }

  componentDidMount() {
    const { 
      firebase, 
    } = this.props;

    loadTeams(
      firebase,
      (results) => {
        this.setState({ organizations: results, loadingOrganizations: false})
      },
      (error) => {
        this.setState({ error });
      })
  }

  filteredOrganizations = () => {
    const { authUser } = this.props;
    const { organizations } = this.state;

    const myOrganizations = [];
    const otherOrganizations = [];
        
    organizations.forEach((organization) => {
      if(authUser) {
        if(authUser.teams && authUser.teams.includes(organization.key)) {
          myOrganizations.push(organization);
        } else {
          otherOrganizations.push(organization);
        }
      }
    });

    return { myOrganizations, otherOrganizations };
  }

  render() {
    return(
      <CheckOnboarding>
        <OrganizationBrowser/>
      </CheckOnboarding>
    );
  }
}

export default withFirebase(withAuthentication(List));