import React from 'react';
import './Section.scss';

const Section = (props) => {
  return(
    <div {...props} className={`component-section ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Section;