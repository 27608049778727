import React from "react";
import PropTypes from "prop-types";

import {
  Configure,
  InstantSearch,
  Hits,
  PoweredBy,
} from "react-instantsearch-dom";
import {
  searchClient,
  VirtualRefinementList,
  ResultsWithEmptyState,
  SortedResultsWithEmptyState,
} from "../../../containers/Algolia";
import { EmbedSizeDealSearchResult } from "../../../containers/DealSearchResult";
import { orderBy } from "lodash";

import "./SimilarDeals.scss";

function SimilarDeals({ organization, isMobile, isContest, dealKey }) {
  return (
    <div className="component-similar-deals">
      <div className={`caption ${isMobile ? "mobile-h1" : " standard-h2"}`}>
        {!isMobile ? "Check out similar deals:" : "Similar Deals:"}
      </div>

      <InstantSearch searchClient={searchClient} indexName="offers">
        <Configure filters="NOT objectID:-N3z8KLWkselZwMENLEh" />
        <div className="deals-list">
          <VirtualRefinementList
            attribute="teams.name"
            transformItems={(items) => orderBy(items, "label", "asc")}
            defaultRefinement={[organization.name]}
          />
          <VirtualRefinementList
            attribute="published"
            defaultRefinement={["true"]}
          />

          <div className="search-results">
            <SortedResultsWithEmptyState
              sortedBy={isContest ? "contestDeal" : "pinned"}
              filtteredOutDealKey={dealKey}
              emptyState={<ResultsWithEmptyState />}
            >
              <Hits hitComponent={EmbedSizeDealSearchResult} />
            </SortedResultsWithEmptyState>
          </div>
        </div>
        <div className="algolia-powered-by-container">
          <PoweredBy />
        </div>
      </InstantSearch>
    </div>
  );
}

SimilarDeals.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default SimilarDeals;
