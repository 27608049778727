import React from "react";
import { Link } from "react-router-dom";

import "./AdminSettings.scss";

function AdminSettings() {
  return (
    <div className="views-portal-pages-admin-settings">
      <h1>Admin Settings</h1>

      <ul>
        <li>
          <Link to="/portal/admin-settings/featured-organizations">
            Featured Organizations
          </Link>
        </li>
        <li>
          <Link to="/portal/admin-settings/categories">
            Categories & Subcategories
          </Link>
        </li>
        <li>
          <Link to="/portal/admin-settings/recommended-deals">
            Recommended Deals (Point System)
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default AdminSettings;
