import React from 'react';
import { withAuthentication } from '../../../containers/Session';
import { withFirebase } from '../../../containers/Firebase';
import environment from '../../../environment';
import { Redirect } from 'react-router-dom';

class RulesTester extends React.Component {
  handleClick = (e) => {
    if(environment.name === 'dev') {
      e.preventDefault();

      const { authUser, firebase } = this.props;

      /* Add your rule testing code here */
    }
  }

  render() {
    if(environment.name === 'dev') {
      const { authUser } = this.props;
  
      return (
        <div className='component-views-pages-rules-tester'>
          <div>Currently logged in as {authUser.email} ({authUser.key})</div>
          <a href='#' onClick={this.handleClick}>Test</a>
        </div>
      );
    } else {
      return (
        <Redirect to='/' />
      );
    }
  }
}

export default withAuthentication(withFirebase(RulesTester));