import React from "react";
import OrganizationLink from "../OrganizationLink";
import { withAuthentication } from "../Session";
import FollowOrganizationLink from "../FollowOrganizationLink";

import "./OrganizationListing.scss";

class OrganizationListing extends React.Component {
  render() {
    const { organization, size } = this.props;

    return (
      <div className={`component-organization-listing ${size}`}>
        <OrganizationLink organization={organization} className="image-area">
          <img
            src={organization.picture}
            className="organization-picture"
            alt={organization.name}
          />
          <img
            src={organization.logo}
            className="logo"
            alt={`${organization.name} logo`}
          />
        </OrganizationLink>
        <div className="writeup">
          <div className="name" data-notranslate>
            {organization.name}
          </div>
          <div className="actions">
            <OrganizationLink organization={organization}>
              View Deals
            </OrganizationLink>
            {!organization.isBrand && (
              <FollowOrganizationLink organization={organization} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withAuthentication(OrganizationListing);
