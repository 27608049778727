function setupImageUploadPromises(firebase, dealKey, deal) {
  const imageUploadPromises = [];
    
  const offerStorageRef = firebase.storage.ref();

  let pictureFilePromise;
  if (deal.pictureFile) {
    const picturePath = `images/offers/${dealKey}/offer.jpg`;
    pictureFilePromise = offerStorageRef.child(picturePath).put(deal.pictureFile);
  } else {
    pictureFilePromise = new Promise((resolve) => resolve(null));
  }
  imageUploadPromises.push(pictureFilePromise);

  let redeemImageFilePromise;
  if (deal.redeemImageFile) {
    const redeemImagePath = `images/offers/${dealKey}/redeem_image.jpg`;
    redeemImageFilePromise= offerStorageRef.child(redeemImagePath).put(deal.redeemImageFile);
  } else {
    redeemImageFilePromise = new Promise((resolve) => resolve(null));
  }
  imageUploadPromises.push(redeemImageFilePromise);

  return imageUploadPromises;
}

function setupGetDownloadUrlPromises(imageUploadSnapshots) {
  // Get Download URLs
  const getDownloadUrlPromises = [];

  const pictureFileSnapshot = imageUploadSnapshots[0];
  const redeemImageFileSnapshot = imageUploadSnapshots[1];

  let getDownloadUrlForPictureFilePromise;
  if(pictureFileSnapshot !== null) {
    getDownloadUrlForPictureFilePromise = pictureFileSnapshot.ref.getDownloadURL();
  } else {
    getDownloadUrlForPictureFilePromise = new Promise((resolve) => resolve(null));
  }
  getDownloadUrlPromises.push(getDownloadUrlForPictureFilePromise);

  let getDownloadUrlForRedeemImageFilePromise;
  if(redeemImageFileSnapshot !== null) {
    getDownloadUrlForRedeemImageFilePromise = redeemImageFileSnapshot.ref.getDownloadURL();
  } else {
    getDownloadUrlForRedeemImageFilePromise = new Promise((resolve) => resolve(null));
  }
  getDownloadUrlPromises.push(getDownloadUrlForRedeemImageFilePromise);

  return getDownloadUrlPromises;
}

export { setupImageUploadPromises, setupGetDownloadUrlPromises };