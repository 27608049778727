import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";

import { FormGroup } from "reactstrap";
import { Button } from "../../containers/UIKit";
import { Icon } from "@iconify/react";
import { GooglePlaceAutocompeteInput } from "../../assets/fansaves-ui";

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const EditIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
`;

const EditIconContainer = styled.div`
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: #cfe6ff;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    background-color: #b3d6ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  gap: 10px;
  margin-top: 10px;
`;

const StyledButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const InputWithErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CityEdit = ({ authUser, firebase }) => {
  const [editMode, setEditMode] = useState(false);
  const [address, setAddress] = useState({
    zipCode: authUser.zip || "",
    city: authUser.city || "",
    state: authUser.state || "",
    country: authUser.country || "",
    latitude: authUser.latitude || "",
    longitude: authUser.longitude || "",
    address: authUser.address || "",
  });
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authUser) {
      setAddress({
        zipCode: authUser.zip || "",
        city: authUser.city || "",
        state: authUser.state || "",
        country: authUser.country || "",
        latitude: authUser.latitude || "",
        longitude: authUser.longitude || "",
        address: authUser.address || "",
      });
    }
  }, [authUser]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!address || !address.latitude || !address.longitude || !address.city) {
      setError("City is required");
      return;
    }

    try {
      setSubmitting(true);
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      const userUpdates = {
        zip: address?.zipCode || "",
        city: address?.city || "",
        state: address?.state || "",
        country: address?.country || "",
        latitude: address?.latitude || "",
        longitude: address?.longitude || "",
        address: address?.address || "",
      };

      await userRef.update(userUpdates);
      setSubmitting(false);
      setEditMode(false);
      setError("");
    } catch (error) {
      setSubmitting(false);
      setError("Error updating city. Please try again.");
      console.error("Error updating email", error);
    }
  };

  const handleCancel = () => {
    setAddress({
      zipCode: authUser.zip,
      city: authUser.city,
      state: authUser.state,
      country: authUser.country,
      latitude: authUser.latitude,
      longitude: authUser.longitude,
      address: authUser.address,
    });
    setEditMode(false);
    setError("");
  };

  return (
    <FormContainer>
      <FormGroup style={{ width: "100%" }}>
        <InputContainer>
          <InputWithErrorContainer>
            <GooglePlaceAutocompeteInput
              value={address}
              onChange={setAddress}
              error={error}
              onFieldChangeClearError={() => setError("")}
              disabled={!editMode}
            />
          </InputWithErrorContainer>
          <EditIconContainer onClick={() => setEditMode(!editMode)}>
            <EditIcon icon="fa-solid:pencil-alt" width={17} />
          </EditIconContainer>
        </InputContainer>
        {editMode && (
          <ButtonContainer>
            <Button
              className="green-outline-continue rounded-10 medium"
              onClick={handleCancel}
              disabled={submitting}
              tabIndex={1}
            >
              <StyledButtonTitle>Cancel</StyledButtonTitle>
            </Button>
            <Button
              className={`green-continue rounded-10 medium ${
                (submitting ||
                  !address ||
                  !address.latitude ||
                  !address.longitude ||
                  !address.city) &&
                "disabled"
              }`}
              onClick={handleUpdate}
              disabled={submitting}
              tabIndex={2}
            >
              <StyledButtonTitle>Save</StyledButtonTitle>
            </Button>
          </ButtonContainer>
        )}
      </FormGroup>
    </FormContainer>
  );
};

export default withFirebase(withAuthentication(CityEdit));
