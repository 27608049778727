import React from "react";
import { Link } from "react-router-dom";

const OrganizationLink = (props) => {
  const { organization, className, children } = props;

  let url = "";
  if (organization.isBrand) {
    url = `/brands/${organization.key}`;
  } else {
    let slugOrKey;
    if (organization.slug && organization.slug.length > 0) {
      slugOrKey = organization.slug;
    } else {
      slugOrKey = organization.key;
    }
    url = `/organizations/${slugOrKey}`;
  }

  return (
    <Link to={url} className={`component-organization-link ${className}`}>
      {children}
    </Link>
  );
};

export default OrganizationLink;
