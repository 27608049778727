import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { withAuthentication } from "../../../containers/Session";
import { withFirebase } from "../../../containers/Firebase";

import { FormGroup } from "reactstrap";
import { Button } from "../../../containers/UIKit";
import useOnboardingStore from "../../../stores/onboardingStore";
import OnboardingGenderForm from "./OnboardingGenderForm";
import OnboardingBirthdayForm from "./OnboardingBirthdayForm";
import moment from "moment";
import { GooglePlaceAutocompeteInput } from "../GooglePlaceAutocompeteInput";

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  height: 100%;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const OnboardingPersonalInfoForm = ({ firebase, authUser }) => {
  const [gender, setGender] = useState("");
  const [dobYear, setDobYear] = useState("");
  const [dobMonth, setDobMonth] = useState("");
  const [dobDay, setDobDay] = useState("");
  const [dobError, setDobError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [address, setAddress] = useState({
    zipCode: authUser.zip,
    city: authUser.city,
    state: authUser.state,
    country: authUser.country,
    latitude: authUser.latitude,
    longitude: authUser.longitude,
    address: authUser.address,
  });

  const [submitting, setSubmitting] = useState(false);
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep);

  useEffect(() => {
    if (authUser && authUser.dob) {
      const dobComponents = authUser.dob.split("-");
      setDobYear(dobComponents[0]);
      setDobMonth(dobComponents[1]);
      setDobDay(dobComponents[2]);
    }

    if (authUser && authUser.gender) {
      setGender(authUser.gender);
    } else {
      setGender("Male");
    }
  }, [authUser]);

  useEffect(() => {
    setDobError("");
    setGenderError("");
    setAddressError("");
  }, []);

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "dobYear":
        setDobYear(value);
        break;
      case "dobMonth":
        setDobMonth(value);
        break;
      case "dobDay":
        setDobDay(value);
        break;
      case "gender":
        setGender(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let isValid = true;

    // validate birthday

    const dob = `${dobYear}-${dobMonth}-${dobDay}`;
    const momentDob = moment(dob);

    if (!momentDob.isValid()) {
      setDobError("Please enter a valid date of birth");
      isValid = false;
    }

    // validate gender
    if (!gender || gender.trim().length === 0) {
      setGenderError("Please select a gender");
      isValid = false;
    }

    // validate address

    if (!address || !address.latitude || !address.longitude || !address.city) {
      setAddressError("Please select a city");
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!authUser) return;
    setSubmitting(true);
    try {
      if (!validateForm()) {
        setSubmitting(false);
        return;
      }

      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);
      const dob = `${dobYear}-${dobMonth}-${dobDay}`;

      const dateObj = moment(dob).format("YYYY-MM-DD");

      const userUpdates = {
        dob: dateObj || "",
        gender: gender || "",
        zip: address?.zipCode || "",
        city: address?.city || "",
        state: address?.state || "",
        country: address?.country || "",
        latitude: address?.latitude || "",
        longitude: address?.longitude || "",
        address: address?.address || "",
      };

      await userRef.update(userUpdates);

      setSubmitting(false);
      setCurrentStep(3);
    } catch (error) {
      setSubmitting(false);
      console.error("Error updating user", error);
    }
  };

  return (
    <FormContainer>
      <div className="row">
        <div
          className="col-sm-10"
          style={{
            padding: "0",
            margin: "0 auto",
            width: "100%",
          }}
        >
          <FormGroup className="mb-4">
            <GooglePlaceAutocompeteInput
              value={address}
              onChange={setAddress}
              error={addressError}
              onFieldChangeClearError={() => setAddressError("")}
            />
          </FormGroup>
          <FormGroup className="mb-4">
            <OnboardingBirthdayForm
              dobMonth={dobMonth}
              dobDay={dobDay}
              dobYear={dobYear}
              onChange={handleUserInput}
              error={dobError}
              onFieldChangeClearError={() => setDobError("")}
            />
          </FormGroup>
          <FormGroup className="mb-4">
            <OnboardingGenderForm
              gender={gender}
              onChange={handleUserInput}
              error={genderError}
              onFieldChangeClearError={() => setGenderError("")}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <ButtonContainer>
            <Button
              className={`green-continue rounded-10 wide ${
                submitting && "disabled"
              }`}
              onClick={handleSubmit}
            >
              <StyledButtonTitle disabled={submitting}>
                Continue
              </StyledButtonTitle>
            </Button>
          </ButtonContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default withFirebase(withAuthentication(OnboardingPersonalInfoForm));
