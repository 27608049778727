import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DefaultContainer, Button } from "../../../containers/UIKit";
import LoginModal from "../../../containers/LoginModal";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";

import whiteLogo from "../../../assets/img/brand/logo-white.png";

import googlePlayImage from "../../../assets/img/google-play-badge.png";
import appleAppStoreImage from "../../../assets/img/apple-app-store-badge.png";
import { isSponsor, isAdmin, isTeam } from "../../../containers/Session";

function DefaultLayoutFooter(props) {
  const [showLoginModal, setShowLoginModal] = useState(false);

  function handleLoginClick() {
    setShowLoginModal(true);
  }

  function handleLoginModalClose() {
    setShowLoginModal(false);
  }

  function getPortalLink(authUser) {
    if (isSponsor(authUser) && authUser.sponsorID) {
      return `/portal/sponsors/${authUser.sponsorID}/dashboard`;
    } else if (isAdmin(authUser)) {
      return "/portal/dashboard";
    } else if (isTeam(authUser && authUser.teamID)) {
      return `/portal/teams/${authUser.teamID}/dashboard`;
    } else {
      return "/portal/dashboard";
    }
  }

  return (
    <div className="default-footer">
      <DefaultContainer>
        <div className="footer-main-call-to-action">
          <span>
            Are you a sports team, organization, influencer or brand who can
            benefit from FanSaves?
          </span>
          <div className="actions">
            <Link to="/how-it-works" className="learn-more-link">
              Learn More
            </Link>
            <span>or</span>
            <Button
              href="https://about.fansaves.com/contact-us"
              className="book-demo-button light-blue"
            >
              Book a Free Demo
            </Button>
          </div>
        </div>
        <div className="footer-info-sections">
          <div className="footer-section about-section">
            <img className="logo" src={whiteLogo} />
            <div className="about-writeup">
              FanSaves is a free digital couponing platform that connects fans
              with deals from affiliated brands of their favorite sports teams,
              organizations, influencers, events and more to help users engage
              with businesses and brands while saving money.
            </div>
            <div className="social-links">
              <a href="http://www.facebook.com/fansaves" target="_blank">
                <i className="social-icon fa fa-facebook" />
              </a>
              <a
                href="http://www.linkedin.com/company/fansaves/"
                target="_blank"
              >
                <i className="social-icon fa fa-linkedin" />
              </a>
              <a href="http://www.twitter.com/fan_saves" target="_blank">
                <i className="social-icon fa fa-twitter" />
              </a>
              <a href="http://www.instagram.com/fansaves" target="_blank">
                <i className="social-icon fa fa-instagram" />
              </a>
            </div>
          </div>
          <div className="footer-section links-section">
            <h2>Company</h2>

            <ul>
              <li>
                <a
                  href="https://about.fansaves.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About FanSaves
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/team"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Team
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/advisors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Advisors
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/resources"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resources
                </a>
              </li>
              <li>
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-section links-section">
            <h2>Work with FanSaves</h2>

            <ul>
              <li>
                <Link
                  to={getPortalLink(props.authUser)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FanSaves Portal
                </Link>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/businesses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join as a Business
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/organizations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Join as an Organization
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Request a Free Demo
                </a>
              </li>
              <li>
                <a
                  href="https://about.fansaves.com/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-section app-links-section">
            <h2>Download the App</h2>

            <div className="app-links">
              <div>
                <a
                  href="http://play.google.com/store/apps/details?id=com.fansaves.daydream&hl=en"
                  target="_blank"
                >
                  <img src={googlePlayImage} />
                </a>
              </div>
              <div>
                <a
                  href="http://itunes.apple.com/us/app/fansaves/id1342516391?mt=8&app=itunes&ign-mpt=uo%3D4"
                  target="_blank"
                >
                  <img src={appleAppStoreImage} />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-section login-section">
            <Button className="green login-button" onClick={handleLoginClick}>
              Log In / Sign Up
            </Button>
          </div>
        </div>
      </DefaultContainer>

      {showLoginModal && <LoginModal onClose={handleLoginModalClose} />}
    </div>
  );
}

export default withAuthentication(withFirebase(DefaultLayoutFooter));
