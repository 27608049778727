import React from 'react';
import './CategoryTile.scss';
import { Link } from 'react-router-dom';

function CategoryTile({title, imageSrc, subcategories, size}) {

  function subcategoryElements() {
    const elements = [];
    subcategories.forEach((subcategory, index) => {

      elements.push(<Link to={`/organizations?subcategory=${encodeURIComponent(subcategory)}`} key={index} className='subcategory'>{subcategory}</Link>);
      if(index < subcategories.length - 1) {
        elements.push(<span key={`${index}-separator`} className='separator'>·</span>);
      }
    });

    return elements;
  }

  return (
    <div className={`component-category-tile ${size}-tile`}>
      <Link to={`/organizations?category=${encodeURIComponent(title)}`}><img src={imageSrc} /></Link>
      <div className='body'>
        <div className='title'><Link to={`/organizations?category=${encodeURIComponent(title)}`}>{title}</Link></div>
        <div className='description'>
          {subcategoryElements()}
        </div>
      </div>
    </div>
  );
}

export default CategoryTile;