import React from 'react';

import './Banner.scss';

function Banner({color, children}) {
  return (
    <div className={`component-banner ${color}`}>{children}</div>
  );
}

export default Banner;