import React, { Component } from "react";
import PaymentsSetupComponent from "../../../../containers/PaymentsSetup";
import queryString from "query-string";

class PaymentsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ teamId: this.props.match.params.team_id });
  }

  fromRefresh = () => {
    const { location } = this.props;
    const parsedQueryString = queryString.parse(location.search);
    return parsedQueryString.refresh === "true";
  };

  render() {
    const { teamId } = this.state;

    return teamId ? (
      <PaymentsSetupComponent
        resourceType="team"
        resourceId={teamId}
        refresh={this.fromRefresh()}
      />
    ) : (
      <div>...</div>
    );
  }
}

export default PaymentsSetup;
