/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import { withAuthentication } from "../../../../containers/Session";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import moment from "moment";
import queryString from "query-string";
import {
  DashboardButton,
  FlexContainer,
  DashboardFilter,
  SearchBar,
  DashboardTitle,
  SpinLoader,
  MobileMarginContainer,
} from "../../../../assets/fansaves-ui";

import { DashboardStats } from "../../../../containers/UIKit";
import DashboardBusinessCard from "../../../../containers/DashboardBusinessCard";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useBrandsStore from "../../../../stores/brandsStore";
import useSponsorsStore from "../../../../stores/sponsorsStore";

import "../../../../scss/base/typography.scss";

const StyledBrandList = styled(FlexContainer)`
  gap: 20px;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;
  .component-dashboard-filter {
    position: absolute;
    top: -30px;
    left: 0;
    z-index: 2;
  }
`;

function List({ firebase, history }) {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );

  const fetchBrands = useBrandsStore((state) => state.fetchBrands);
  const brands = useBrandsStore((state) => state.brands);
  const redemptions = useRedemptionsStore(
    (state) => state.redemptionsWithoutContestEntries
  );

  const contestEntries = useRedemptionsStore((state) => state.contestEntries);

  const sponsors = useSponsorsStore((state) => state.sponsors);
  const fetchSponsors = useSponsorsStore((state) => state.fetchSponsors);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!sponsors) {
      fetchSponsors(firebase);
    }
  }, [fetchSponsors, firebase, sponsors]);

  useEffect(() => {
    if (!redemptions) {
      fetchRedemptions(firebase);
    }
  }, [fetchRedemptions, firebase, redemptions]);

  useEffect(() => {
    if (!brands) {
      fetchBrands(firebase);
    }
  }, [fetchBrands, firebase, brands]);

  const calculateBrandsOffersCount = () => {
    let brandsOffersCount = 0;
    const countedOfferIDs = new Set();

    if (brands && brands.length > 0 && sponsors && sponsors.length > 0) {
      brands.forEach((brand) => {
        if (brand.sponsorKeys && brand.sponsorKeys.length > 0) {
          brand.sponsorKeys.forEach((sponsorKey) => {
            let sponsor = sponsors.find(
              (sponsor) => sponsor.key === sponsorKey
            );
            if (sponsor && sponsor.offers && sponsor.offers.length > 0) {
              sponsor.offers.forEach((offer) => {
                if (
                  (offer.active || offer.triggered) &&
                  !countedOfferIDs.has(offer.key)
                ) {
                  brandsOffersCount += 1;
                  countedOfferIDs.add(offer.key);
                }
              });
            }
          });
        }
      });
    } else {
      return null;
    }
    return brandsOffersCount;
  };

  const calculateBrandsRedemptionsCount = () => {
    let brandsRedemptionsCount = 0;
    let brandContestEntriesCount = 0;
    const countedOfferIDs = new Set();

    if (
      brands &&
      brands.length > 0 &&
      redemptions &&
      redemptions.length > 0 &&
      sponsors &&
      sponsors.length > 0
    ) {
      brands.forEach((brand) => {
        if (brand.sponsorKeys && brand.sponsorKeys.length > 0) {
          brand.sponsorKeys.forEach((sponsorKey) => {
            let sponsor = sponsors.find(
              (sponsor) => sponsor.key === sponsorKey
            );
            if (sponsor && sponsor.offers && sponsor.offers.length > 0) {
              sponsor.offers.forEach((offer) => {
                if (!countedOfferIDs.has(offer.key)) {
                  let redemptionsCount = redemptions.filter(
                    (redemption) =>
                      redemption.offerID === offer.key &&
                      redemption.transactionType === "redemption"
                  ).length;
                  brandsRedemptionsCount += redemptionsCount;

                  let contestEntriesCount = contestEntries.filter(
                    (redemption) =>
                      redemption.offerID === offer.key &&
                      redemption.transactionType === "contest"
                  ).length;
                  brandContestEntriesCount += contestEntriesCount;
                  countedOfferIDs.add(offer.key);
                }
              });
            }
          });
        }
      });
    } else {
      return {};
    }
    return { brandsRedemptionsCount, brandContestEntriesCount };
  };

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const getStats = () => {
    const { brandsRedemptionsCount, brandContestEntriesCount } =
      calculateBrandsRedemptionsCount();
    let stats = [
      {
        label: "Total Brands",
        value: brands
          ? brands.length > 0
            ? brands.length
            : 0
          : renderLoading(),
      },
      {
        label: "Active Brands",
        value: brands
          ? brands.filter((brand) => brand.active).length
          : renderLoading(),
      },
      {
        label: "Archived Brands",
        value: brands
          ? brands.filter((brand) => !brand.active).length
          : renderLoading(),
      },
      {
        label: "Active Offers",
        value:
          calculateBrandsOffersCount() || calculateBrandsOffersCount() === 0
            ? calculateBrandsOffersCount()
            : renderLoading(),
      },
      {
        label: "Total Redemptions",
        value:
          brandsRedemptionsCount || brandsRedemptionsCount === 0
            ? brandsRedemptionsCount
            : renderLoading(),
      },
      {
        label: "Total Contest Entries",
        value:
          brandContestEntriesCount || brandContestEntriesCount === 0
            ? brandContestEntriesCount
            : renderLoading(),
      },
    ];
    return stats;
  };

  const getFilterAndQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const filter = search.filter || "Alphabetical";
    const query = search.query;

    if (filter) {
      setFilter(filter || "Alphabetical");
    }
    if (query) {
      setSearchQuery(query);
    }
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (filter) {
      params.filter = filter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };

  useEffect(() => {
    getFilterAndQueryFromSearch();
  }, []);

  useEffect(() => {
    setUrlQueryFromFilters();
  }, [filter, searchQuery]);

  const filterItems = () => {
    let filteredItems = [...brands];
    switch (filter) {
      case "Alphabetical":
        filteredItems = filteredItems.sort(
          (a, b) =>
            (a.name &&
              a.name.localeCompare(b.name, undefined, { numeric: true })) ||
            /^[0-9]/.test(a.name) - /^[0-9]/.test(b.name)
        );
        break;
      case "NewestToOldest":
        filteredItems = filteredItems.sort((a, b) => {
          return moment(a.createdAt).diff(moment(b.createdAt));
        });
        break;
      case "OldestToNewest":
        filteredItems = filteredItems.sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        });
        break;
      default:
        break;
    }

    setSelectedBrands(filteredItems);
  };

  const handleSearch = () => {
    let tempBrands = [...brands];
    if (!searchQuery) {
      setSelectedBrands(tempBrands);
      return;
    }
    tempBrands = tempBrands.filter((brand) => {
      return brand.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setSelectedBrands(tempBrands);
  };

  useEffect(() => {
    if (brands) {
      filterItems();
    }
  }, [filter, brands]);

  useEffect(() => {
    if (brands) {
      handleSearch();
    }
  }, [searchQuery, brands]);

  return (
    <div
      className="component-views-portal-brands-list animated fadeIn"
      id="main"
    >
      <FlexContainer gap="30px" margin="50px 0" alignItems="flex-start">
        <FlexContainer
          direction="horizontal"
          justifyContent="space-between"
          width="100%"
          responsive
        >
          <FlexContainer gap="30px" alignItems="flex-start" width="100%">
            <DashboardTitle
              title="Brands"
              className="brands-list-title"
              justifyContent="flex-start"
              backButtonPath={`/portal/dashboard`}
            />
            <DashboardButton
              title="Add an Brand"
              icon="fa-solid:plus"
              size="medium"
              type="green"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/portal/brands/create`);
              }}
              width="220px"
            />
          </FlexContainer>
          <MobileMarginContainer margin="10px">
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Brands"
            />
          </MobileMarginContainer>
        </FlexContainer>
        <DashboardStats stats={getStats()} />
        <StyledBrandList>
          <DashboardFilter
            type="business"
            className="component-dashboard-filter inter-b2"
            onSelect={setFilter}
            initialFilterOption={filter}
          />
          {!brands && !selectedBrands ? (
            <div className="no-brands">
              <SpinLoader />
            </div>
          ) : selectedBrands && selectedBrands.length > 0 ? (
            selectedBrands.map((brand) => (
              <DashboardBusinessCard
                key={brand.key}
                business={brand}
                renderButtonsGroup
                brands
                subtitle={
                  brand.brandType === "team"
                    ? "Organization Brand"
                    : "Business Brand"
                }
              />
            ))
          ) : (
            <div className="no-brands">No brands found.</div>
          )}
        </StyledBrandList>
      </FlexContainer>
    </div>
  );
}

export default withFirebase(withAuthentication(withRouter(List)));
