/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withFirebase } from "../../containers/Firebase";
import { withAuthentication } from "../../containers/Session";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import useLanguageStore from "../../stores/useLanguageStore";

const PortalLangSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  color: ${COLORS.primaryWhite};
  cursor: pointer;

  .active {
    text-decoration: underline;
    text-decoration-color: ${COLORS.primaryWhite};
    text-underline-offset: 0.2em;
  }
`;

const TranslationDropdown = ({
  firebase,
  authUser,
  isMobile,
  onNavLinkClick,
  portal,
}) => {
  const [userLanguage, setUserLanguage] = useState("English");
  const [closeDropdown, setCloseDropdown] = useState(false);
  const setLanguage = useLanguageStore((state) => state.setLanguage);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("fanSavesLanguagePreference");
    if (authUser && authUser.languagePreference) {
      setUserLanguage(authUser.languagePreference);
      setLanguage(authUser.languagePreference === "English" ? "en" : "fr");
    } else if (storedLanguage) {
      setUserLanguage(storedLanguage);
      setLanguage(storedLanguage === "English" ? "en" : "fr");
    }
  }, [authUser]);

  useEffect(() => {
    localStorage.setItem("fanSavesLanguagePreference", userLanguage);
    const lang = userLanguage === "English" ? "en" : "fr";
    setLanguage(lang);
    window.lzSwitchLanguage && window.lzSwitchLanguage(lang);
  }, [userLanguage]);

  const updateLanguagePreference = (newLanguage) => {
    setUserLanguage(newLanguage);
    setCloseDropdown(true);

    if (authUser) {
      firebase.database
        .ref(`user/${authUser.key}/languagePreference`)
        .set(newLanguage)
        .catch((err) => {
          console.error("Error updating language preference in database:", err);
        });
    }
  };

  useEffect(() => {
    if (closeDropdown) {
      setTimeout(() => {
        if (onNavLinkClick && typeof onNavLinkClick === "function") {
          onNavLinkClick();
        }
        setCloseDropdown(false);
      }, 500);
    }
  }, [closeDropdown]);

  return (
    <>
      {!portal ? (
        <>
          {!isMobile ? (
            <div className="submenu-group" data-notranslate>
              <Link to="#" className="submenu-title">
                <span data-notranslate>{userLanguage}</span>
              </Link>
              <div className="submenu language-submenu">
                <Link
                  to="#"
                  onClick={() => updateLanguagePreference("English")}
                  className={userLanguage === "English" ? "active" : ""}
                >
                  <span data-notranslate> English</span>
                </Link>
                <Link
                  to="#"
                  onClick={() => updateLanguagePreference("Français")}
                  className={userLanguage === "Français" ? "active" : ""}
                >
                  <span data-notranslate> Français</span>
                </Link>
              </div>
            </div>
          ) : (
            <div className="submenu-group" data-notranslate>
              <Link to="#">
                <div className="submenu language-submenu">
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      updateLanguagePreference("English");
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      updateLanguagePreference("English");
                    }}
                    className={`mobile-lang-link ${
                      userLanguage === "English" ? "active" : ""
                    }`}
                  >
                    <span data-notranslate> English</span>
                  </span>
                  <span
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      updateLanguagePreference("Français");
                    }}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      updateLanguagePreference("Français");
                    }}
                    className={`mobile-lang-link ${
                      userLanguage === "Français" ? "active" : ""
                    }`}
                  >
                    <span data-notranslate> Français</span>
                  </span>
                </div>
              </Link>
            </div>
          )}
        </>
      ) : (
        <PortalLangSelect data-notranslate>
          <div
            onClick={() => updateLanguagePreference("English")}
            className={userLanguage === "English" ? "active" : ""}
          >
            <span data-notranslate>English</span>
          </div>
          <div
            onClick={() => updateLanguagePreference("Français")}
            className={userLanguage === "Français" ? "active" : ""}
          >
            <span data-notranslate>Français</span>
          </div>
        </PortalLangSelect>
      )}
    </>
  );
};

TranslationDropdown.propTypes = {
  firebase: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  onNavLinkClick: PropTypes.func,
  portal: PropTypes.bool,
};

export default withFirebase(withAuthentication(TranslationDropdown));
