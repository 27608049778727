import styled from "styled-components/macro";

const Grid = styled.div`
  display: grid;
  width: ${({ width }) => width || "100%"};
  grid-gap: ${({ gap }) => gap || "0"};
  margin: ${({ margin }) => margin || "0 auto"};
  overflow-x: auto;
`;

export default Grid;
