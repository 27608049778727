import React, { lazy, useEffect, useState } from "react";
import DashboardSpecialCodesHeader from "../../../../containers/DashboardSpecialCodesHeader";
import useTeamsStore from "../../../../stores/teamsStore";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../containers/Firebase";
import styled from "styled-components/macro";

const StyledSpecialOffersCodesList = styled.div`
  margin-bottom: 50px;
`;

const DashboardSpecialCodesTableShowMode = lazy(() =>
  import("../../../../containers/DashboardSpecialCodesTableShowMode")
);

const SpecialOffersCodes = ({ match, firebase }) => {
  const teamId = match.params.team_id;
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);
  const teamFromStore = useTeamsStore((state) => state.team);
  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if (!team || team.key !== teamId) {
      fetchTeam(firebase, teamId);
    }
  }, [team, fetchTeam, firebase, teamId]);
  return (
    <StyledSpecialOffersCodesList animated fadeIn>
      <DashboardSpecialCodesHeader title="All Codes" team={team} />
      <DashboardSpecialCodesTableShowMode team={team} />
    </StyledSpecialOffersCodesList>
  );
};

export default withRouter(withFirebase(SpecialOffersCodes));
