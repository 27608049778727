import React from 'react';
import { useEffect, useState } from 'react';
import {
  Form,
} from 'reactstrap';
import { withFirebase } from "../../../../../containers/Firebase";
import { loadTeams } from '../../../../../utilities/team_helpers';
import { Button } from '../../../../../containers/UIKit';
import FeaturedTeam from './FeaturedTeam';

import './FeaturedTeams.scss';

function FeaturedTeams({ firebase }) {

  const numberOfFeaturedResources = 12;

  const [loadingFeaturedTeams, setLoadingFeaturedTeams] = useState(true);
  const [teams, setTeams] = useState([]);
  const [featuredTeams, setFeaturedTeams] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  function handleFeaturedTeamChange(team, index) {
    const newFeaturedTeams = [...featuredTeams];
    newFeaturedTeams[index] = team;
    setFeaturedTeams(newFeaturedTeams);
    setUnsavedChanges(true);
  }

  function saveFeaturedTeams(e) {
    e.preventDefault();
    const featuredResourcesRef = firebase.database.ref('/adminSettings/featuredTeams');
    featuredResourcesRef.set(featuredTeams)
      .then(() => {
        setUnsavedChanges(false);
      })
      .catch((error) => {
        alert(`There was an error saving your changes. ${error}`);
      });
  }

  function loadFeaturedTeams() {
    const results = Array(numberOfFeaturedResources).fill({ key: '', featuredSponsorKeys: ['', '', '', '']});

    const featuredTeamsRef = firebase.database.ref('/adminSettings/featuredTeams');

    featuredTeamsRef.once('value', (snapshot) => {      
      if(snapshot.val() !== null) {
        const teams = snapshot.val();
        teams.forEach((team, index) => {
          results[index] = team;
        });
      }
      setFeaturedTeams(results);
      setLoadingFeaturedTeams(false);
    }).catch(() => {
      setFeaturedTeams(results);
      setLoadingFeaturedTeams(false);
    });
  }

  useEffect(() => {
    loadTeams(firebase, setTeams);
  }, []);

  useEffect(loadFeaturedTeams, []);

  return (
    <div className='views-portal-pages-admin-settings-featured-teams'>
      <h1>Featured Organizations</h1>
        <div className='section'>
            {
              !loadingFeaturedTeams &&
              <Form>
                {
                  featuredTeams.map((team, index) => (
                    <FeaturedTeam
                      key={index}
                      initialTeam={team}
                      index={index}
                      potentialTeams={teams}
                      onChange={handleFeaturedTeamChange}
                    />
                  ))
                }
                <Button className={`${unsavedChanges ? 'green' : 'blue'} save-button`} onClick={saveFeaturedTeams}>
                  {unsavedChanges ? 'Save Unsaved Changes' : 'Save Changes'}
                </Button>
              </Form>
            }
        </div>

      </div>
  );
}

export default withFirebase(FeaturedTeams);