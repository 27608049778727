import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Input,
  Row,
  Col,
  Button,
  ListGroupItem
} from 'reactstrap';

const SubCategoryContainer = observer(({subCategory}) => {
  const {
    name,
    isEditing,
    newSubCategoryName,
    editButtonOnClick,
    saveSubCategoryNameOnClick,
    newSubCategoryNameOnChange,
    removeSubCategoryButtonOnClick
  } = subCategory;

  return (
    <ListGroupItem className="subcategory-container">
      {
        isEditing ?
        <Row>
          <Col sm={10}>
            <Input value={newSubCategoryName} onChange={e => newSubCategoryNameOnChange(e.target.value)}/>
          </Col>
          <Col sm={2} className="action-buttons">
            <Button color="primary" onClick={saveSubCategoryNameOnClick}>Save</Button>
          </Col>
        </Row>
        :
        <Row>
          <Col sm={8} className="category-subcategory-name">
            {name}
          </Col>
          <Col sm={4} className="action-buttons">
            <Button color="primary" outline onClick={editButtonOnClick}>Edit</Button>
            <Button color="danger" outline onClick={() => removeSubCategoryButtonOnClick()}>Remove</Button>
          </Col>
        </Row>
      }
    </ListGroupItem>
  );
});

export default SubCategoryContainer;