import React, { useRef, useState, useEffect, useCallback } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import useLanguageStore from "../../../stores/useLanguageStore";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { Label } from "reactstrap";
import ErrorMessage from "../OnboardingForms/ErrorMessage";

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const GooglePlaceAutocompeteInput = ({
  value,
  onChange,
  error,
  onFieldChangeClearError,
  disabled,
}) => {
  const cityInputRef = useRef(null);
  const { language } = useLanguageStore();
  const [predefinedValue, setPredefinedValue] = useState(null);

  useEffect(() => {
    if (
      value?.latitude &&
      value?.longitude &&
      (!predefinedValue ||
        predefinedValue.geometry.location.lat !== value.latitude ||
        predefinedValue.geometry.location.lng !== value.longitude)
    ) {
      setPredefinedValue({
        description: `${value.city}, ${value.state}, ${value.country}`,
        geometry: { location: { lat: value.latitude, lng: value.longitude } },
      });
    }
  }, [value, predefinedValue]);

  const handlePlaceSelect = async (data) => {
    if (!data || !data.value) {
      setPredefinedValue(null);
      onChange(null);
      return;
    }
    try {
      const details = await geocodeByPlaceId(data.value.place_id);
      if (details) {
        const location = details[0].geometry.location;
        const addressComponents = details[0].address_components;

        const address = {
          latitude: location.lat(),
          longitude: location.lng(),
          city: addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name,
          state: addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name,
          country: addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name,
          zipCode: addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.long_name,
        };

        setPredefinedValue({
          description: `${address.city}, ${address.state}, ${address.country}`,
          geometry: {
            location: { lat: address.latitude, lng: address.longitude },
          },
        });

        onChange(address);
        onFieldChangeClearError("address");
      } else {
        onChange(null);
      }
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  const updateDefaultLabel = useCallback(() => {
    if (value) {
      const { city, state, country } = value;
      const parts = [city, state, country].filter(Boolean);
      return parts.length > 0 ? parts.join(", ") : "Enter your city";
    }
    return "Enter your city";
  }, [value]);

  return (
    <div>
      <StyledLabel>City</StyledLabel>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyCp5J3s2EH5bZIpIKNBxA4-BfkDShv22Sw"
        apiOptions={{ language: language }}
        ref={cityInputRef}
        debounce={600}
        minLengthAutocomplete={3}
        predefinedPlaces={predefinedValue ? [predefinedValue] : []}
        selectProps={{
          key: predefinedValue ? JSON.stringify(predefinedValue) : "default",
          value: predefinedValue
            ? { value: predefinedValue, label: updateDefaultLabel() }
            : null,
          placeholder: "Enter your city",
          isClearable: true,
          isDisabled: disabled || false,
          styles: {
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? "#f9f9f9" : "white",
              color: state.isSelected ? "black" : "black",
              cursor: "pointer",
              padding: "10px",
            }),
            control: (styles) => ({
              ...styles,
              backgroundColor: "white",
              borderWidth: "1px",
              borderColor: error ? COLORS.errorRed : COLORS.primaryBlue,
              borderRadius: "10px",
              fontSize: "16px",
              fontWeight: "400",
              fontFamily: "Manrope, sans-serif",
              color: `${COLORS.primaryBlack}`,
              width: "100%",
            }),
            singleValue: (styles, { isDisabled }) => ({
              ...styles,
              color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
            }),
          },

          onChange: handlePlaceSelect,
          onInputChange: (inputValue) => {
            if (!inputValue) {
              setPredefinedValue(null);
              onChange(null);
            }
          },
        }}
      />
      {error && <ErrorMessage error={error} />}
    </div>
  );
};

export default GooglePlaceAutocompeteInput;
