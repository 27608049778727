import app from "firebase/app";
import "firebase/performance";
import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import environment from "../../environment";

let config = {};

if (environment.name === "production") {
  // PRODUCTION settings
  config = {
    appId: "1:1028813924242:web:cda132a53439ce2d",
    apiKey: "AIzaSyD4y7IPCkWQjtFPfYunWwez8v3-vZZk4Ms",
    projectId: "fansaves-d0d9e",
    authDomain: "fansaves-d0d9e.firebaseapp.com",
    databaseURL: "https://fansaves-d0d9e.firebaseio.com/",
    storageBucket: "fansaves-d0d9e.appspot.com",
    messagingSenderId: "1028813924242",
    measurementId: "G-YCRE375211",
  };
} else if (environment.name === "staging") {
  // STAGING settings
  config = {
    apiKey: "AIzaSyAEB6Giz2JJSgO0_XwJA8GaEGMaK9edwYc",
    authDomain: "fansaves-staging.firebaseapp.com",
    databaseURL: "https://fansaves-staging.firebaseio.com",
    projectId: "fansaves-staging",
    storageBucket: "fansaves-staging.appspot.com",
    messagingSenderId: "625577823825",
    appId: "1:625577823825:web:ad69b15684eada0a5fb270",
  };
} else if (environment.name === "dev") {
  // DEV settings
  config = {
    apiKey: "AIzaSyBCAlL3Hmi41iS_UHoOL1RW9KckwQTQDIk",
    authDomain: "fansaves-dev-14f73.firebaseapp.com",
    databaseURL: "https://fansaves-dev-14f73.firebaseio.com",
    projectId: "fansaves-dev-14f73",
    storageBucket: "fansaves-dev-14f73.appspot.com",
    messagingSenderId: "942474866395",
    appId: "1:942474866395:web:5c4f7c913663a5452826fe",
  };
}

if (environment.useFirebaseEmulators) {
  if (window.location.hostname === "localhost") {
    console.log("Using Firebase Emulators");
    config.databaseURL = "http://localhost:9000?ns=fansaves-dev-14f73";
  }
}

class Firebase {
  constructor() {
    console.log(`Firebase initializing (${environment.name})`);
    if (app.apps.length === 0) {
      this.app = app.initializeApp(config);
      this.app2 = app.initializeApp(config, "forCreatingNewUsers");
      this.perf = this.app.performance();

      this.auth = this.app.auth();
      this.auth2 = this.app2.auth();

      this.database = this.app.database();
      this.firestore = this.app.firestore();
      this.databaseServerValue = firebase.database.ServerValue;
      this.storage = this.app.storage();

      if (environment.useFirebaseEmulators) {
        this.auth.useEmulator("http://localhost:9099");
        this.auth2.useEmulator("http://localhost:9099");
        this.storage.useEmulator("localhost", 9199);
        this.firestore.useEmulator("localhost", 8080);
      }
    }
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doSignInWithGoogle = () => {
    const provider = new app.auth.GoogleAuthProvider();
    app.auth().useDeviceLanguage();
    return this.auth.signInWithPopup(provider);
  };

  doSignInWithFacebook = () => {
    const provider = new app.auth.FacebookAuthProvider();
    app.auth().useDeviceLanguage();
    return this.auth.signInWithPopup(provider);
  };

  doSignInWithApple = () => {
    const provider = new app.auth.OAuthProvider("apple.com");
    app.auth().useDeviceLanguage();
    return this.auth.signInWithPopup(provider);
  };

  doSaveUserEMailAfterSignIn = (email) => {
    const user = this.auth.currentUser;
    console.log("doSaveUserEMailAfterSignIn", user);
    if (user) {
      const userRef = this.database.ref(`/user/${user.uid}`);
      return userRef.update({
        email: email,
      });
    } else {
      return new Promise((resolve, reject) => {
        reject("No user signed in");
      });
    }
  };

  doReauthenticateWithCredential = (email, password) => {
    const user = this.auth.currentUser;
    const credential = app.auth.EmailAuthProvider.credential(email, password);
    return user.reauthenticateWithCredential(credential);
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  DatabaseServerValue = () => app.database.ServerValue;
}

export default Firebase;
