import React, { useEffect } from "react";
import "./AboutTab.scss";
import { Icon } from "@iconify/react";
import { Scrollbars } from "react-custom-scrollbars-2";

import DashIcon from "../../../assets/img/connect-icons/dash-green.svg";
import DashMobileIcon from "../../../assets/img/connect-icons/dash-mobile.svg";

import ExternalLinkButton from "../../../containers/ExternalLinkButton";
import GoogleMapComponent from "../../../containers/Map";
import { FlexContainer, Text, COLORS } from "../../../assets/fansaves-ui";

// hooks
import useMobile from "../../../hooks/useMobile";

function AboutTab({ organization }) {
  const isMobile = useMobile();

  const ajustDescriptionAlignment = () => {
    const description = document.querySelector(".description-text");
    const descriptionContainer = document.querySelector(".description");
    if (description && descriptionContainer) {
      if (description.clientHeight < descriptionContainer.clientHeight) {
        descriptionContainer.classList.add("center");
      } else {
        descriptionContainer.classList.remove("center");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", ajustDescriptionAlignment);
    ajustDescriptionAlignment();

    return () => {
      window.removeEventListener("resize", ajustDescriptionAlignment);
    };
  }, []);

  const checkNumberOfButtons = (row) => {
    const socialKeys = {
      1: ["website", "buy tickets link", "dashUrl"],
      2: ["app iOS", "app android"],
      3: ["social fb", "social instagram", "social twitter", "social snapchat"],
    };

    const existingLinks = socialKeys[row].filter((key) => organization[key]);
    return existingLinks.length;
  };

  return (
    <div className="component-about-tab">
      <FlexContainer direction="horizontal" gap="30px" responsive>
        <FlexContainer className="organization-description-container">
          <Text
            type={isMobile ? "mobile-h2" : "standard-h5"}
            blue
            data-notranslate
          >
            About {organization.name}
          </Text>
          <FlexContainer
            className="organization-location"
            gap="10px"
            responsive
            direction="vertical"
          >
            <br />
            <div className="organization-description card-container">
              <Scrollbars
                autoHide
                renderThumbVertical={({ style, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...style,
                      backgroundColor: COLORS.primaryGreyLight,
                      borderRadius: "3px",
                      height: "100%",
                      width: "5px",
                    }}
                  />
                )}
              >
                <div className="description">
                  <Text
                    type={isMobile ? "mobile-h6" : "standard-h5"}
                    green
                    semiBold
                    className="description-text"
                  >
                    {organization.description}
                  </Text>
                </div>
              </Scrollbars>
            </div>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className="organization-location-container">
          <Text type={isMobile ? "mobile-h2" : "standard-h5"} blue>
            Location
          </Text>
          <FlexContainer
            className="organization-location"
            gap="10px"
            responsive
            direction="vertical"
          >
            <Text type="inter-b1" data-notranslate>
              {organization["arena name"]} {organization["arena address"]}
            </Text>
            <div className="card-container map-container">
              <GoogleMapComponent
                address={organization["arena address"]}
                lat={organization.lat}
                long={organization.long}
              />
            </div>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer gap="25px" margin="70px 0" responsive>
        <Text type={isMobile ? "mobile-h2" : "standard-h5"} blue>
          Connect with <span data-notranslate>{organization.name}</span>
        </Text>

        <FlexContainer
          className="organization-socials"
          direction={isMobile ? "horizontal" : "vertical"}
          gap={isMobile ? "25px" : "40px"}
          responsive
          justifyContent="space-between"
        >
          {isMobile ? (
            <>
              <ExternalLinkButton
                link={organization.website}
                iconElement={
                  <Icon
                    icon="mdi:world"
                    width={isMobile ? "40px" : "54px"}
                    height={isMobile ? "40px" : "54px"}
                    color={COLORS.primaryGreen}
                  />
                }
                text="Visit Website"
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["buy tickets link"]}
                iconElement={
                  <Icon
                    icon="fontisto:ticket"
                    width={isMobile ? "39px" : "56px"}
                    height={isMobile ? "26px" : "37px"}
                    color={COLORS.primaryGreen}
                  />
                }
                text="Buy Tickets"
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["dashUrl"]}
                iconElement={
                  <img src={isMobile ? DashMobileIcon : DashIcon} alt="dash" />
                }
                text="Bid on Team Auctions"
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["app iOS"]}
                iconElement={
                  <Icon
                    icon="ic:baseline-apple"
                    width={isMobile ? "37px" : "44px"}
                    height={isMobile ? "45px" : "53px"}
                    color={COLORS.primaryGreen}
                  />
                }
                text="Download iOS App"
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["app android"]}
                iconElement={
                  <Icon
                    icon="uiw:android"
                    width={isMobile ? "37px" : "45px"}
                    height={isMobile ? "44px" : "53px"}
                    color={COLORS.primaryGreen}
                  />
                }
                text="Download Android App"
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["social fb"]}
                iconElement={
                  <Icon
                    icon="brandico:facebook"
                    width={isMobile ? "21px" : "26px"}
                    height={isMobile ? "39px" : "48px"}
                    color={COLORS.primaryGreen}
                  />
                }
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["social instagram"]}
                iconElement={
                  <Icon
                    icon="mdi:instagram"
                    width={isMobile ? "42px" : "49px"}
                    height={isMobile ? "42px" : "49px"}
                    color={COLORS.primaryGreen}
                  />
                }
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["social twitter"]}
                iconElement={
                  <Icon
                    icon="icon-park-twotone:big-x"
                    width={isMobile ? "32px" : "41px"}
                    height={isMobile ? "29px" : "37px"}
                    color={COLORS.primaryGreen}
                  />
                }
                className="organization-socials-button"
              />
              <ExternalLinkButton
                link={organization["social snapchat"]}
                iconElement={
                  <Icon
                    icon="ic:baseline-tiktok"
                    width={isMobile ? "44px" : "52px"}
                    height={isMobile ? "48px" : "57px"}
                    color={COLORS.primaryGreen}
                  />
                }
                className="organization-socials-button"
              />
            </>
          ) : (
            <>
              <FlexContainer
                direction="horizontal"
                justifyContent="space-around"
                gap="25px"
                responsive
                width={(checkNumberOfButtons("1") === 2 && "70%") || "100%"}
              >
                <ExternalLinkButton
                  link={organization.website}
                  iconElement={
                    <Icon
                      icon="mdi:world"
                      width={isMobile ? "40px" : "54px"}
                      height={isMobile ? "40px" : "54px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  text="Visit Website"
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["buy tickets link"]}
                  iconElement={
                    <Icon
                      icon="fontisto:ticket"
                      width={isMobile ? "39px" : "56px"}
                      height={isMobile ? "26px" : "37px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  text="Buy Tickets"
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["dashUrl"]}
                  iconElement={
                    <img
                      src={isMobile ? DashMobileIcon : DashIcon}
                      alt="dash"
                    />
                  }
                  text="Bid on Team Auctions"
                  className="organization-socials-button"
                />
              </FlexContainer>
              <FlexContainer
                direction="horizontal"
                justifyContent="space-around"
                gap="25px"
                responsive
                width={(checkNumberOfButtons("2") === 2 && "70%") || "100%"}
              >
                <ExternalLinkButton
                  link={organization["app iOS"]}
                  iconElement={
                    <Icon
                      icon="ic:baseline-apple"
                      width={isMobile ? "37px" : "44px"}
                      height={isMobile ? "45px" : "53px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  text="Download iOS App"
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["app android"]}
                  iconElement={
                    <Icon
                      icon="uiw:android"
                      width={isMobile ? "37px" : "45px"}
                      height={isMobile ? "44px" : "53px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  text="Download Android App"
                  className="organization-socials-button"
                />
              </FlexContainer>
              <FlexContainer
                direction="horizontal"
                justifyContent="space-around"
                gap="25px"
                responsive
                width={(checkNumberOfButtons("3") === 2 && "70%") || "100%"}
              >
                <ExternalLinkButton
                  link={organization["social fb"]}
                  iconElement={
                    <Icon
                      icon="brandico:facebook"
                      width={isMobile ? "21px" : "26px"}
                      height={isMobile ? "39px" : "48px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["social instagram"]}
                  iconElement={
                    <Icon
                      icon="mdi:instagram"
                      width={isMobile ? "42px" : "49px"}
                      height={isMobile ? "42px" : "49px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["social twitter"]}
                  iconElement={
                    <Icon
                      icon="icon-park-twotone:big-x"
                      width={isMobile ? "32px" : "41px"}
                      height={isMobile ? "29px" : "37px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  className="organization-socials-button"
                />
                <ExternalLinkButton
                  link={organization["social snapchat"]}
                  iconElement={
                    <Icon
                      icon="ic:baseline-tiktok"
                      width={isMobile ? "44px" : "52px"}
                      height={isMobile ? "48px" : "57px"}
                      color={COLORS.primaryGreen}
                    />
                  }
                  className="organization-socials-button"
                />
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </FlexContainer>
    </div>
  );
}

export default AboutTab;
