import React from "react";
import {
  InstantSearch,
  Hits,
  PoweredBy,
  Configure,
} from "react-instantsearch-dom";
import { withAuthentication } from "../../../containers/Session";
import {
  searchClient,
  SearchBox,
  VirtualRefinementList,
  SortedResultsWithEmptyState,
} from "../../../containers/Algolia";
import { EmbedSizeDealSearchResult } from "../../../containers/DealSearchResult";
import { orderBy } from "lodash";
import DealTypeRefinementList from "./DealTypeRefinementList";
import ExclusiveCodeLinkRefinementList from "./ExclusiveCodeLinkRefinementList";

import "./DealsTab.scss";

function DealsTab({ organization, dealType, authUser, hiddenDeals }) {
  return (
    <InstantSearch searchClient={searchClient} indexName="offers">
      <Configure
        filters={`published:true AND NOT contestDeal:true AND NOT hiddenDeal:true`}
      />
      <div className="component-deals-tab">
        <div className="deals-list">
          <VirtualRefinementList
            attribute="teams.name"
            transformItems={(items) => orderBy(items, "label", "asc")}
            defaultRefinement={[organization.name]}
          />
          <VirtualRefinementList
            attribute="published"
            defaultRefinement={["true"]}
          />

          <div className="deal-filters">
            {/* Hiding exclusive deals for NASCAR */}
            {organization.key !== "-NUXCK_m_X4c87nrubCg" && (
              <DealTypeRefinementList
                attribute="exclusive"
                transformItems={(items) => orderBy(items, "label", "asc")}
                defaultRefinement={[
                  dealType === "exclusive" ? "true" : "false",
                ]}
              />
            )}

            <div className="search-bar-container">
              <SearchBox hideSubmit={true} />
            </div>
          </div>
          <div className="search-results">
            <ExclusiveCodeLinkRefinementList
              attribute="exclusive"
              organizationKey={organization.key}
              defaultRefinement={[dealType === "exclusive" ? "true" : "false"]}
            />
            <SortedResultsWithEmptyState sortedBy="pinned">
              <Hits hitComponent={EmbedSizeDealSearchResult} />
            </SortedResultsWithEmptyState>

            <div className="algolia-powered-by-container">
              <PoweredBy />
            </div>
          </div>
        </div>
      </div>
    </InstantSearch>
  );
}

export default withAuthentication(DealsTab);
