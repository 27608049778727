import React from 'react';

import './LoadingPlaceholder.scss';

const LoadingPlaceholder = (props) => {
  return (
    <div className='component-loading-placeholder' style={props.style}></div>
  );
}

export default LoadingPlaceholder;