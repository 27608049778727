/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./DealRedeemedInfo.scss";
import Countdown from "../Countdown";
import moment from "moment";
import {
  INSTORE_REDEMPTION_TIME_IN_MINUTES,
  DATABASE_DATETIME_FORMAT,
} from "../../utilities/constants";

import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";
import { FormattedMessage } from "react-intl";

function DealRedeemedInfo({
  userRedeemedDateTime,
  isMobile,
  onRedeemSuccess,
  isEasyRedemption = false,
}) {
  const redemptionTimeExpired = moment(
    userRedeemedDateTime,
    DATABASE_DATETIME_FORMAT
  )
    .add(INSTORE_REDEMPTION_TIME_IN_MINUTES, "minutes")
    .isBefore(moment());

  function renderDealRedeemed() {
    if (redemptionTimeExpired && !isEasyRedemption) {
      return (
        <>
          <div className="caption standard-h2">
            {" "}
            <i className="fa fa-clock-o" /> 0:00
          </div>
        </>
      );
    }

    return (
      <>
        <div className="caption">
          <Countdown targetDateTimeString={userRedeemedDateTime} />
        </div>
      </>
    );
  }

  function handleClick() {
    if (!redemptionTimeExpired || isEasyRedemption) {
      onRedeemSuccess();
    }
  }

  return (
    <div className="component-deal-redeemed-info">
      {!redemptionTimeExpired || isEasyRedemption ? (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className={`deal-redeemed-button button ${
            isMobile ? "primary-cta-small-mobile-btn" : "primary-cta-btn"
          } ${isEasyRedemption ? "easy-redemption" : ""}`}
        >
          {isEasyRedemption ? (
            <span data-notranslate>
              <FormattedMessage id="app.deal.view" defaultMessage="View" />
            </span>
          ) : (
            "View Coupon"
          )}
        </a>
      ) : (
        <div
          className={`deal-redeemed-button button disabled ${
            isMobile ? "primary-cta-small-mobile-btn" : "primary-cta-btn"
          }`}
        >
          Expired
        </div>
      )}
      {!isEasyRedemption && renderDealRedeemed()}
    </div>
  );
}

export default DealRedeemedInfo;
