import cloudFunctionHost from "../containers/CloudFunctionsHost";

const fetchData = async (resource, firebase) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    const data = {
      resource: resource,
    };
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchDataByResource`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error fetching data", error);
    return null;
  }
};

const fetchDataByRole = async (resource, role, key, firebase) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    const data = {
      role,
      key,
      resource,
    };

    if (!role || !key) {
      return;
    }
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchDataByResourceAndRole`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error fetching data", error);
    return null;
  }
};

const fetchUsersData = async (firebase) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchUsersReportData`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("Error fetching data", error);
    return null;
  }
};

export { fetchData, fetchDataByRole, fetchUsersData };
