import { create } from "zustand";

import { getEventsByType } from "../utilities/event_helpers";

const useFollowEventsStore = create((set, get) => ({
  followEvents: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchFollowEvents: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await getEventsByType("follow", firebase);

      set({ followEvents: data });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },
}));

export default useFollowEventsStore;
