import React, { useState, useEffect } from "react";
import { connectStateResults } from "react-instantsearch-dom";
import {
  TeamMarkerWithFollowButton,
  GenericMapComponent,
} from "../../assets/fansaves-ui";

const FanPagesSelectorMapView = connectStateResults(
  ({ searchState, searchResults, children, mapHeight, authUser }) => {
    const [markers, setMarkers] = useState([]);
    const [defaultCenter, setDefaultCenter] = useState(null);

    useEffect(() => {
      if (authUser?.latitude && authUser?.longitude) {
        // check if user location changed and update map center
        if (
          defaultCenter &&
          defaultCenter.lat === authUser.latitude &&
          defaultCenter.lng === authUser.longitude
        ) {
          return;
        }
        setDefaultCenter({
          lat: authUser.latitude,
          lng: authUser.longitude,
        });
      } else {
        setDefaultCenter({ lat: 43.6532, lng: -79.3832 });
      }
    }, [authUser, defaultCenter]);

    useEffect(() => {
      if (searchResults && searchResults.nbHits > 0) {
        const hits = searchResults.hits || [];
        const newMarkers = hits
          .map((hit) => {
            if (hit.isBrand) {
              return null;
            }
            if (hit?._geoloc && hit._geoloc.lat && hit._geoloc.lng) {
              return {
                ...hit,
                lat: hit._geoloc.lat,
                lng: hit._geoloc.lng,
              };
            }
            return null;
          })
          .filter(Boolean);
        setMarkers(newMarkers);
      }
    }, [searchResults]);

    return (
      <GenericMapComponent
        defaultCenter={defaultCenter}
        mapHeight={mapHeight}
        dataType="team"
        markers={markers}
        renderMarker={(marker, onClick) => (
          <TeamMarkerWithFollowButton
            key={marker.objectID}
            onClick={onClick}
            team={marker}
          />
        )}
      />
    );
  }
);

export default FanPagesSelectorMapView;
