import environment from "../environment";

function logEvent(firebase, eventName, params) {
  if (environment.name === "production") {
    firebase.app.analytics().logEvent(eventName, params);
  }
}

const eventNames = {
  AE_DEALS_SHOW_VIEW: "deals_show_view",
  AE_ORGANIZATIONS_SHOW_VIEW: "organizations_show_view",
  AE_COPIED_SHARE_LINK: "copied_share_link",
};

export { logEvent, eventNames };
