import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Pin from "../../assets/img/pin.svg";
import { Icon } from "@iconify/react";
import { COLORS } from "../../assets/fansaves-ui";

function GoogleMapComponent({ address, lat, long }) {
  const [center, setCenter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    if (center) {
      return;
    }

    let errorListener;

    errorListener = setTimeout(() => {
      if (!center) {
        setError(true);
        setLoading(false);
      } else {
        setError(false);
      }
    }, 5000);

    if (center) {
      clearTimeout(errorListener);
      return;
    }

    // Check if user is online
    if (!navigator.onLine) {
      setError(true);
      console.error("You are offline. Please check your internet connection.");
      return;
    }

    // Check if google is defined
    if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
      setError(true);
      console.error("Google Maps JavaScript API is not loaded.");
      return;
    }

    if (!address) {
      setError(true);
      return;
    }

    if (lat && long) {
      clearTimeout(errorListener);
      setCenter({
        lat: lat,
        lng: long,
      });

      return;
    }

    setLoading(true);

    const geocoder = new window.google.maps.Geocoder();
    if (!geocoder) {
      setError(true);
      console.error("Google Maps Geocoder is not loaded.");
      return;
    }

    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        setLoading(false);
        setError(false);
        const location = results[0].geometry.location;
        setCenter({
          lat: location.lat(),
          lng: location.lng(),
        });

        clearTimeout(errorListener);
      } else {
        setError(true);
        setLoading(false);
        console.error(`Geocode was not successful: ${status}`);
      }
    });

    return () => {
      clearTimeout(errorListener);
    };
  }, [address, center, lat, long]);

  if (!loading && error) {
    return (
      <div>
        <Icon icon="mdi:map-marker-off" color={COLORS.primaryGrey} width={30} />
        <br />
        Oops, something went wrong while loading the map.
        <br />
        Please try again later.
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <Icon icon="mdi:map-marker" color={COLORS.primaryBlue} width={30} />
        <br />
        Loading map...
      </div>
    );
  }

  return (
    <div style={{ height: "200px", width: "100%" }}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ height: "100%", width: "100%", borderRadius: 30 }}
        center={center || { lat: 0, lng: 0 }}
        zoom={15}
        onLoad={() => setLoading(false)}
        onError={() => {
          setError(true);
          console.error("There was an issue loading the Google Map");
        }}
      >
        <Marker position={center || { lat: 0, lng: 0 }} icon={Pin} />
      </GoogleMap>
    </div>
  );
}

export default GoogleMapComponent;
