import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import DealSearchResult from "../../../containers/DealSearchResult";
import { DefaultContainer } from "../../../containers/UIKit";
import { MapToggleButton } from "../../../assets/fansaves-ui";

import { orderBy } from "lodash";

import {
  InstantSearch,
  Hits,
  PoweredBy,
  Configure,
} from "react-instantsearch-dom";
import {
  searchClient,
  SearchBox,
  RefinementList,
  ResultsWithEmptyState,
  DealsMapView,
} from "../../../containers/Algolia";

import "./List.scss";

class List extends React.Component {
  constructor(props) {
    super(props);

    const queryStringValues = queryString.parse(props.location.search);

    this.state = {
      searchTerms: queryStringValues.searchTerms || "",
      showFiltersInMobile: false,
      showMap: false,
    };
  }

  toggleMapVisibility = () => {
    this.setState({ showMap: !this.state.showMap });
    localStorage.setItem("dealMapView", !this.state.showMap);
  };

  flashDealsPage = () => {
    const { location } = this.props;
    const queryStringValues = queryString.parse(location.search);
    return queryStringValues.flashDeals === "true";
  };

  componentDidMount() {
    const dealMapView = localStorage.getItem("dealMapView");
    if (dealMapView === "true") {
      this.setState({ showMap: true });
    }
  }

  contestDealsPage = () => {
    const { location } = this.props;
    const queryStringValues = queryString.parse(location.search);
    return queryStringValues.contestDeals === "true";
  };

  toggleMobileFilters = (e) => {
    e.preventDefault();
    const { showFiltersInMobile } = this.state;
    this.setState({ showFiltersInMobile: !showFiltersInMobile });
  };

  filters = () => {
    if (this.contestDealsPage()) {
      return "contestDeal:true";
    } else {
      return "published:true AND NOT contestDeal:true";
    }
  };

  tagFilters = () => {
    const { authUser } = this.props;

    if (authUser && authUser.unlockedSpecialDeals) {
      const accessTags = Object.entries(authUser.unlockedSpecialDeals).flatMap(
        ([teamKey, value]) => {
          return Object.keys(value).map(
            (accessLevel) => `hidden${teamKey}-${accessLevel}`
          );
        }
      );

      const tags = [[...accessTags, "-hidden"]];

      return tags;
    }

    return ["-hidden"];
  };

  render() {
    const { searchTerms, showFiltersInMobile, showMap } = this.state;
    const { authUser } = this.props;

    return (
      <CheckOnboarding>
        <DefaultContainer>
          <div className="views-deals-list">
            {this.flashDealsPage() && <h1>Flash Deals</h1>}
            {this.contestDealsPage() && <h1>Contests</h1>}
            <InstantSearch searchClient={searchClient} indexName="offers">
              <Configure
                filters={this.filters()}
                tagFilters={this.tagFilters()}
              />
              <div className="search-bar-container">
                <SearchBox
                  defaultRefinement={searchTerms}
                  placeholder={
                    this.contestDealsPage()
                      ? "Search contests..."
                      : "Search deals..."
                  }
                />
              </div>
              <div className="search-results">
                <a
                  onClick={this.toggleMobileFilters}
                  className="toggle-mobile-filters"
                >
                  {showFiltersInMobile ? "Hide Filters" : "Show Filters"}
                </a>
                <div
                  className={`sort-and-filters ${
                    showFiltersInMobile ? "show-mobile" : "hide-mobile"
                  }`}
                >
                  <div className="section">
                    <h2>Filters</h2>

                    <div className="filter-section">
                      <h3>Category</h3>
                      <div>
                        <RefinementList
                          attribute="sponsor.category"
                          transformItems={(items) =>
                            orderBy(items, "label", "asc")
                          }
                        />
                      </div>
                    </div>

                    <div style={{ display: "none" }}>
                      <RefinementList
                        attribute="published"
                        defaultRefinement={["true"]}
                      />
                      {/* <RefinementList
                        attribute="hiddenDeal"
                        defaultRefinement={["false"]}
                      /> */}
                      <RefinementList
                        attribute="flashDeal"
                        defaultRefinement={
                          this.flashDealsPage() ? ["true"] : []
                        }
                      />
                      <RefinementList
                        attribute="contestDeal"
                        defaultRefinement={
                          this.contestDealsPage() ? ["true"] : []
                        }
                        searchablePlaceholder="Search for a contest"
                      />
                    </div>
                  </div>
                </div>
                <div className="results">
                  <div className="results-header">
                    <h2>Results</h2>
                    <MapToggleButton
                      showMap={showMap}
                      toggleMap={this.toggleMapVisibility}
                    />
                  </div>
                  {showMap && (
                    <DealsMapView authUser={authUser} mapHeight={"600px"} />
                  )}
                  {!showMap && (
                    <ResultsWithEmptyState message="We're sorry, but there were no results for your search.">
                      <Hits hitComponent={DealSearchResult} />
                    </ResultsWithEmptyState>
                  )}

                  <div className="algolia-powered-by-container">
                    <PoweredBy />
                  </div>
                </div>
              </div>
            </InstantSearch>
          </div>
        </DefaultContainer>
      </CheckOnboarding>
    );
  }
}

export default withFirebase(withRouter(withAuthentication(List)));
