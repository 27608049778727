import { makeAutoObservable, runInAction } from "mobx";
import Category from "./category";

export class CategoryPage {
  constructor() {
    this.categories = [];
    this.database = null;
    this.loading = true;

    this.tobeCreatedCategoryName = "";

    makeAutoObservable(this, {}, {autoBind: true});
  }

  tobeCreatedCategoryNameOnChange(newName){
    this.tobeCreatedCategoryName = newName;
  }

  async initilize(firebase){
    this.database = firebase.database;
    const categoriesSnapshot = (await this.database.ref("category/").once("value")).val();
    if(categoriesSnapshot){
      Object.keys(categoriesSnapshot)
      .forEach(
        categoryId => {
          const category = categoriesSnapshot[categoryId];
          this.categories.push(
            new Category(this.database, categoryId, category.name, category.subcategories)
          );
      });
    };
    
    this.loading = false;
  }

  async createCategory(){
    const newCategoryRef = await this.database.ref("category/").push({
      name: this.tobeCreatedCategoryName,
      subcategory: []
    });
    
    const newCategory = new Category(
      this.database,
      newCategoryRef.key,
      this.tobeCreatedCategoryName
    );

    runInAction(() => {
      this.categories.push(newCategory);
      this.tobeCreatedCategoryName = "";
    })
  }

  async removeCategory(categoryId){
    await this.database.ref(`category/${categoryId}`).remove();
    runInAction(() => {
      this.categories = this.categories.filter(category => category.categoryId !== categoryId);
    });
  }

  cleanUp(){
    this.categories = [];
  }
}

export const categoryPage = new CategoryPage()