import { create } from "zustand";
import { fetchUsersData } from "../utilities/db_helpers";

const useUsersStore = create((set, get) => ({
  users: null,
  customers: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchUsers: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await fetchUsersData(firebase);

      const customers = data.filter(
        (user) =>
          user.role !== "admin" &&
          user.role !== "team" &&
          user.role !== "sponsor"
      );

      set({ users: data, customers });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },
}));

export default useUsersStore;
