import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Button } from '../UIKit';
import PropTypes from 'prop-types';
import './DealTermsModal.scss';

class DealTermsModal extends React.Component {
  handleCloseClick = (e) => {
    e.preventDefault();
    this.props.onClose();
  }

  handleProceedClick = (e) => {
   e.preventDefault();
   this.props.onProceed();
  }

  render() {
    const { deal, proceeding } = this.props;
    
    return (
      <div className='component-deal-terms-modal'>
        <Container className='close-modal-container'>
          <Row className="justify-content-center">
            <Col xl="8" lg="9" md="8" >
              <div className='close-modal-icon-container'>
                <i className='icon-close' onClick={this.handleCloseClick} />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col xl="8" lg="9" md="8" >
              <div className='main-content'>
                <div className='header'>Wahoo! You're about to purchase this awesome Flash Deal and support local!</div>
                <div className='instructions'>
                  Please be sure to read over the Terms & Conditions below before Proceeding to Checkout.
                </div>
                <div className='terms-and-conditions'>
                  {deal.terms}
                </div>
                <Button className='green' onClick={this.handleProceedClick}>
                  {
                    proceeding ?
                      <><i className='fa fa-circle-o-notch fa-spin' /> One moment...</>
                    :
                     'Proceed to Checkout'
                  }
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

DealTermsModal.propTypes = {
  onProceed: PropTypes.func,
  onClose: PropTypes.func,
};

export default DealTermsModal;