import React, { useEffect } from 'react';
import { categoryPage } from "../../../../../stores/categoryPage";
import { withFirebase } from '../../../../../containers/Firebase';
import { observer } from 'mobx-react-lite';
import CategoryContainer from "./CategoryContainer";
import {
  Input,
  Row,
  Col,
  Button,
} from 'reactstrap';

import './Categories.scss';

const Categories = observer(({firebase}) => {
  const { 
    loading,
    categories,
    createCategory,
    tobeCreatedCategoryName,
    tobeCreatedCategoryNameOnChange,
    cleanUp
  } = categoryPage;

  useEffect(() => {
    categoryPage.initilize(firebase);
    return cleanUp;
  }, []);

  return (
    <div className='views-portal-pages-categories'>
      <h1>Categories & Subcategories</h1>
      {loading && "Loading ..."}

      {
        !loading &&
        <div>
          {
            categories.map(
              (category) => <CategoryContainer key={category.id} category={category}/>
            )
          }
          <Row row className="category-add-component">
            <Col sm={10}>
              <Input value={tobeCreatedCategoryName} onChange={e => tobeCreatedCategoryNameOnChange(e.target.value)}/>
            </Col>
            <Col sm={2}>
              <Button block color="primary" onClick={() => createCategory()}>Add Category</Button>
            </Col>
          </Row>
        </div>
      }
    </div>
  );
});

export default withFirebase(Categories);