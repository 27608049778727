import environment from '../../environment';

let host = '';

if(environment.name === 'production') {
  host = 'https://us-central1-fansaves-d0d9e.cloudfunctions.net';
} else if (environment.name === 'staging') {
  host = 'https://us-central1-fansaves-staging.cloudfunctions.net';
} else if (environment.name === 'dev') {
  host = 'https://us-central1-fansaves-dev-14f73.cloudfunctions.net';

  if(environment.useFirebaseEmulators) {
    host = 'http://localhost:5001/fansaves-dev-14f73/us-central1';
  }
}

export default host;