import React, { useState, useEffect } from "react";
import BrandForm from "../Form";
import { withFirebase } from "../../../../containers/Firebase";
import { uploadResourceImages } from "../../utilities/FormHelpers";
import { dbLoadBrand } from "../../../../containers/DatabaseHelpers/DatabaseHelpers";
import { prepBrandData } from "../brand_utils";

function Edit({ firebase, history, match }) {
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState();

  function loadBrand() {
    const brandKey = match.params.brand_id;
    dbLoadBrand(firebase, brandKey, (brand) => {
      setBrand(brand);
      setLoading(false);
    });
  }

  function pushBrandData(brandKey, brandData, imageUrls) {
    const dataSet = prepBrandData(brandData, imageUrls);

    const brandRef = firebase.database.ref(`/brand/${brandKey}`);

    brandRef.update(dataSet).then(() => {
      history.push(`/portal/brands/${brandKey}`);
    });
  }

  function handleSubmit(brandData) {
    const brandKey = match.params.brand_id;

    const imageTypes =
      brandData.brandType === "team" ? ["logo", "picture"] : ["logo"];

    uploadResourceImages(
      firebase,
      brandKey,
      brandData,
      "images/brands",
      imageTypes,
      pushBrandData
    );
  }

  useEffect(() => {
    loadBrand();
  }, []);

  return (
    <div>
      <h1>Edit Brand</h1>
      {!loading && <BrandForm existingBrand={brand} onSubmit={handleSubmit} />}
    </div>
  );
}

export default withFirebase(Edit);
