/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense } from "react";
import { withFirebase } from "../../../../containers/Firebase";
import {
  FlexContainer,
  CSVExport,
  SpinLoader,
  DashboardTitle,
  GLOBALS,
} from "../../../../assets/fansaves-ui";
import styled from "styled-components/macro";
import { Icon } from "@iconify/react";
import moment from "moment";
import { DashboardHeader } from "../../../../containers/UIKit";

import { getLocation } from "../../../../utilities/dashboard_helpers";

import useRedemptionsStore from "../../../../stores/redemptionsStore";
import useTeamsStore from "../../../../stores/teamsStore";
import { withAuthentication, isAdmin } from "../../../../containers/Session";

const DashboardRedemptionsTable = lazy(() =>
  import("../../../../containers/DashboardRedemptionsTable")
);

const StyledRedemptionsList = styled.div`
  margin-bottom: 50px;
  .redemptions-total {
    margin-top: 20px;
    width: fit-content;
    display: flex;
    direction: row;
    gap: 10px;
  }
  .component-dashboard-redemptions-table {
    .component-dashboard-filter {
      top: -30px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .component-dashboard-filter {
        top: 130px;
      }
    }
  }
  .redemptions-list-header {
    position: relative;
    .logo-deals-list-header {
      margin: 30px 0 0 0;
    }
    .link-container {
      position: absolute;
      top: 0px;
      right: 10px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .link-container {
        position: relative;
        margin-left: 30px;
      }
    }
  }
`;

const RedemptionsList = ({ firebase, match, authUser }) => {
  const teamId = match.params.team_id;
  const [redemptions, setRedemptions] = useState(null);
  const [contestEntries, setContestEntries] = useState(null);
  const [
    redeemptionsWithoutContestEntries,
    setRedemptionsWithoutContestEntries,
  ] = useState(null);
  const [team, setTeam] = useState(null);

  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptions
  );
  const redemptionsFromStore = useRedemptionsStore(
    (state) => state.redemptions
  );

  const fetchRedemptionsByChunks = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );
  const isRedemptionsLoading = useRedemptionsStore((state) => state.isLoading);

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);

  useEffect(() => {
    if (!redemptions) {
      if (isAdmin(authUser)) {
        fetchRedemptionsByChunks(firebase);
      } else {
        fetchRedemptions(firebase);
      }
    }
  }, [fetchRedemptions, firebase]);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    if ((teamId && !team) || (team && team.key !== teamId)) {
      fetchTeam(firebase, teamId);
    }
  }, [firebase, team, teamId, fetchTeam]);

  useEffect(() => {
    if (!isRedemptionsLoading && redemptionsFromStore) {
      if (teamId) {
        setRedemptions(
          redemptionsFromStore.filter(
            (redemption) => redemption.teamID === teamId
          )
        );

        setContestEntries(
          redemptionsFromStore.filter(
            (redemption) =>
              redemption.teamID === teamId &&
              redemption.transactionType === "contest"
          )
        );

        setRedemptionsWithoutContestEntries(
          redemptionsFromStore.filter(
            (redemption) =>
              redemption.teamID === teamId &&
              redemption.transactionType === "redemption"
          )
        );
      } else {
        setRedemptions(redemptionsFromStore);
        setContestEntries(
          redemptionsFromStore.filter(
            (redemption) => redemption.transactionType === "contest"
          )
        );
        setRedemptionsWithoutContestEntries(
          redemptionsFromStore.filter(
            (redemption) => redemption.transactionType === "redemption"
          )
        );
      }
    }
  }, [redemptionsFromStore, isRedemptionsLoading, teamId]);

  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "Date of Redemption", key: "dateOfRedemption" },
      { label: "Offer", key: "offer" },
      { label: "Business", key: "sponsor" },
      { label: "Gender", key: "gender" },
      { label: "Age", key: "age" },
      { label: "Location", key: "location" },
      { label: "Email", key: "email" },
      { label: "Device", key: "device" },
      { label: "App Version", key: "appVersion" },
      { label: "App Install Date", key: "appInstallDate" },
      { label: "Account Created", key: "accountCreated" },
    ];
  };

  const generateData = () => {
    if (!redemptions) return null;
    const data = redemptions.reduce((acc, redemption) => {
      const { time, offer, user, userAge, userCity, sponsorName, deviceInfo } =
        redemption || {};

      const { platform, web, appVersion, installationTime } = deviceInfo || {};

      let userLocation = "N/A";
      if (userCity) {
        userLocation = userCity;
      } else if (user && user.zip) {
        const location = getLocation(user.zip);
        userLocation = location.city;
      }

      const redemptionData = {
        index: acc.length + 1,
        dateOfRedemption: time
          ? moment(time).format("YYYY/MM/DD HH:mm A")
          : "N/A",
        offer: (offer && offer.description) || "N/A",
        sponsor: sponsorName || "N/A",
        gender: (user && user.gender) || "N/A",
        age: userAge || "N/A",
        location: userLocation || "N/A",
        email: (user && user.email) || "N/A",
        device: web ? "Web" : platform ? platform : "N/A",
        appVersion: web ? "N/A" : appVersion ? appVersion : "N/A",
        appInstallDate:
          web || !installationTime
            ? "N/A"
            : moment(installationTime).format("YYYY/MM/DD HH:mm A"),
        accountCreated:
          user && user.createdAt
            ? moment(user.createdAt).format("YYYY/MM/DD HH:mm A")
            : "N/A",
      };

      acc.push(redemptionData);
      return acc;
    }, []);

    const sortedData = data.sort((a, b) =>
      a.dateOfRedemption !== "N/A" && b.dateOfRedemption !== "N/A"
        ? a.dateOfRedemption < b.dateOfRedemption
          ? 1
          : b.dateOfRedemption < a.dateOfRedemption
          ? -1
          : 0
        : 0
    );

    return sortedData;
  };

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  return (
    <StyledRedemptionsList className="component-redemptions-list">
      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="redemptions-list-header"
        margin="30px 0"
      >
        <DashboardTitle
          title="Redemption Information"
          justifyContent="flex-start"
          backButtonPath={
            teamId ? `/portal/teams/${teamId}/dashboard` : "/portal/dashboard"
          }
          className="standard-h5 blue"
        />
        {team && (
          <DashboardHeader
            logo={team.logo}
            name={team.name}
            email={team.displayEmail}
            profileUrl={team.website}
            hideEl
            className="logo-deals-list-header"
          />
        )}
        <div className="redemptions-total standard-6">
          <FlexContainer direction="vertical" alignItems="flex-start">
            <div>
              <span className="redemption-total-title">
                Number of Redemptions:{" "}
              </span>
              <span>
                {redeemptionsWithoutContestEntries
                  ? redeemptionsWithoutContestEntries.length
                  : renderLoading()}
              </span>
            </div>
            <div>
              <span className="redemption-total-title">
                Number of Contest Entries:{" "}
              </span>
              <span>
                {contestEntries ? contestEntries.length : renderLoading()}
              </span>
            </div>
          </FlexContainer>
        </div>
        <div className="link-container">
          {!isRedemptionsLoading && redemptions && redemptions.length > 0 && (
            <CSVExport
              data={generateData()}
              headers={generateHeaders()}
              filename={
                team && team.name
                  ? `${team.name}-redemptions-list-${moment().format(
                      "YYYY-MM-DD"
                    )}`
                  : `redemptions-list-${moment().format("YYYY-MM-DD")}`
              }
              target="_blank"
              className="rubik-h3"
            >
              <Icon icon="fa-solid:file-export" className="user-logo-icon" />{" "}
              Export Data
            </CSVExport>
          )}
        </div>
      </FlexContainer>
      <FlexContainer>
        {!redemptions ? (
          <div className="no-redemptions">
            <SpinLoader />
          </div>
        ) : (
          redemptions &&
          redemptions.length > 0 && (
            <Suspense fallback={<SpinLoader />}>
              <DashboardRedemptionsTable redemptions={redemptions} />
            </Suspense>
          )
        )}
      </FlexContainer>
    </StyledRedemptionsList>
  );
};

export default withFirebase(withAuthentication(RedemptionsList));
