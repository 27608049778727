import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { Icon } from "@iconify/react";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { Button } from "../UIKit";

const FormContainer = styled.div`
  width: 100%;
  display: flex;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  border: 1px solid ${COLORS.primaryBlue};
  border-color: ${({ error }) =>
    error ? COLORS.errorRed : COLORS.primaryBlue};
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;

  &:focus {
    border-color: ${COLORS.primaryBlueHover};
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
    color: ${COLORS.darkGrey};
  }
`;

const EditIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
`;

const EditIconContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 7px;
  background-color: #cfe6ff;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    background-color: #b3d6ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  gap: 10px;
  margin-top: 10px;
`;

const StyledButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const InputWithErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EditableInput = ({
  label,
  value,
  onChange,
  onSave,
  authUser,
  firebase,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!inputValue || inputValue.trim() === "") {
      setError(`${label} is required`);
      return;
    }

    try {
      setSubmitting(true);
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      let labelToSave = "";
      if (label === "First Name") {
        labelToSave = "firstName";
      } else if (label === "Last Name") {
        labelToSave = "lastName";
      }

      await userRef.update({ [labelToSave]: inputValue });
      setSubmitting(false);
      setEditMode(false);
      setError("");
      onSave(inputValue);
    } catch (error) {
      setSubmitting(false);
      setError("An error occurred. Please try again.");
      console.error(`Error updating ${label.toLowerCase()}`, error);
    }
  };

  const handleCancel = () => {
    setInputValue(value);
    setError("");
    setEditMode(false);
  };

  return (
    <FormContainer>
      <FormGroup style={{ width: "100%" }}>
        <StyledLabel>{label}</StyledLabel>
        <InputContainer>
          <InputWithErrorContainer>
            <StyledInput
              name={label.toLowerCase()}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={label}
              onFocus={() => setError("")}
              error={error}
              disabled={!editMode}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate(e);
                }
              }}
            />
            {error && <ErrorMessage error={error} />}
          </InputWithErrorContainer>
          <EditIconContainer onClick={() => setEditMode(!editMode)}>
            <EditIcon icon="fa-solid:pencil-alt" width={17} />
          </EditIconContainer>
        </InputContainer>

        {editMode && (
          <ButtonContainer>
            <Button
              className="green-outline-continue rounded-10 medium"
              onClick={handleCancel}
              disabled={submitting}
            >
              <StyledButtonTitle>Cancel</StyledButtonTitle>
            </Button>
            <Button
              className={`green-continue rounded-10 medium ${
                (submitting || !inputValue || inputValue.trim() === "") &&
                "disabled"
              }`}
              onClick={handleUpdate}
              disabled={submitting}
            >
              <StyledButtonTitle>Save</StyledButtonTitle>
            </Button>
          </ButtonContainer>
        )}
      </FormGroup>
    </FormContainer>
  );
};

export default EditableInput;
