import React, { createContext, useState } from "react";
import QueryString from "query-string";

export const LoginFormContext = createContext();

export const LoginFormProvider = ({ children }) => {
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);

  const toggleLoginForm = () => {
    setIsLoginFormOpen((prevState) => !prevState);
    const parsed = QueryString.parse(window.location.search);

    if (isLoginFormOpen) {
      delete parsed.login;
    } else {
      parsed.login = true;
    }
    const stringified = QueryString.stringify(parsed);
    window.history.pushState({}, "", `?${stringified}`);
  };

  return (
    <LoginFormContext.Provider value={{ isLoginFormOpen, toggleLoginForm }}>
      {children}
    </LoginFormContext.Provider>
  );
};
