import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";
import { Wrapper } from "@googlemaps/react-wrapper";
import { FlexContainer } from "../Containers";

const Map = ({ chartData }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (chartData) {
      const myWeightedData = Object.entries(chartData).map(
        ([key, value], index) => {
          const [lat, lng] = key.split(",");
          if (lat !== "null" && lng !== "null") {
            return {
              location: new window.google.maps.LatLng(lat, lng),
              weight: value,
              title: value,
            };
          } else {
            return {
              location: new window.google.maps.LatLng(index, index),
              weight: 0,
              title: "Unknown Location for " + value + " users",
            };
          }
        }
      );

      const heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: myWeightedData,
        map: map,
      });
      heatmap.set("radius", heatmap.get("radius") ? null : 20);
      heatmap.setMap(map);

      myWeightedData.forEach((marker) => {
        const contentString = `<div id="content">
        <div id="bodyContent">
        <div id="firstHeading" class="firstHeading">Redemptions: ${marker.title}</div>
        </div>
        </div>`;

        const markerInstance = new window.google.maps.Marker({
          position: marker.location,
          map: map,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString,
          maxWidth: 200,
        });

        window.google.maps.event.addListener(infoWindow, "domready", () => {
          const closeButton = document.querySelector(".gm-ui-hover-effect");
          closeButton.style.display = "none";
        });

        markerInstance.setOpacity(0);
        markerInstance.setCursor("pointer");

        window.google.maps.event.addListener(
          markerInstance,
          "mouseover",
          function () {
            infoWindow.open(map, markerInstance);
          }
        );
        window.google.maps.event.addListener(
          markerInstance,
          "mouseout",
          function () {
            infoWindow.close();
          }
        );
      });
    }
  }, [chartData, map]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    const lat = 39.8283;
    const lng = -98.5795;
    const center = { lat: lat, lng: lng };
    if (map) {
      map.setOptions({ center: center, zoom: 3 });
    }
  }, [map]);

  return (
    <FlexContainer
      height="400px"
      width="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      margin="10px 0 0 0"
    >
      <div ref={ref} style={{ width: "100%", height: "100%" }} />
    </FlexContainer>
  );
};

const GeoChart = ({ data }) => {
  return (
    <>
      {data && (
        <Wrapper apiKey="AIzaSyCp5J3s2EH5bZIpIKNBxA4-BfkDShv22Sw">
          <Map chartData={data} />
        </Wrapper>
      )}
    </>
  );
};

export default GeoChart;

GeoChart.propTypes = {
  data: propTypes.object,
};
