import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { GLOBALS, COLORS } from "../../assets/fansaves-ui";
import { withRouter } from "react-router-dom";
import { withAuthentication } from "../Session";

import {
  DashboardButton,
  DashboardCard,
  FlexContainer,
} from "../../assets/fansaves-ui";

import "../../scss/base/typography.scss";
import { isAdmin } from "../Session";
import useMobile from "../../hooks/useMobile";

const DashboardBusinessCardWrapper = styled(DashboardCard)`
  width: 100%;
  display: flex;
  height: 66px;

  column-gap: 20px;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;

  @media screen and (max-width: ${GLOBALS.wideMobileDeviceWidth}) {
    padding: 10px;
    height: auto;
    flex-direction: column;
  }

  .business-card-image-container {
    width: 50px;
    img {
      width: 50px;
      height: 50px;
      object-fit: scale-down;
    }
  }
  .business-card-title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: flex-start;
    width: 100%;
  }

  .business-card-buttons-group {
    width: 100%;
  }

  .business-card-teams-logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      object-fit: scale-down;
      margin-left: 10px;
    }
  }
`;

const Subtitle = styled.span`
  font-size: 12px;
  color: ${COLORS.darkGrey};
`;

const DashboardBusinessCard = ({
  business,
  redemptionsCount,
  contestEntriesCount,
  renderButtonsGroup,
  teams,
  brands,
  history,
  showRedemptionsTitle,
  subtitle,
  authUser,
  teamsForLogo,
  match,
}) => {
  const isMobile = useMobile();
  const team_id = match.params.team_id;

  const getEditPath = (key) => {
    if (teams) {
      return `/portal/teams/${key}/edit`;
    } else if (brands) {
      return `/portal/brands/${key}/edit`;
    } else {
      return `/portal/sponsors/${key}/edit`;
    }
  };

  const getViewPath = (key) => {
    if (teams) {
      return `/portal/teams/${key}/dashboard`;
    } else if (brands) {
      return `/portal/brands/${key}`;
    } else {
      if (team_id) {
        return `/portal/sponsors/${key}/dashboard?team_id=${team_id}`;
      } else {
        return `/portal/sponsors/${key}/dashboard`;
      }
    }
  };

  return (
    <DashboardBusinessCardWrapper
      className="dashboard-business-card"
      teams={teams}
    >
      <FlexContainer className="business-card-image-container">
        {!brands && !teams ? (
          <img src={business.picture} alt={business.description} />
        ) : (
          <img src={business.logo} alt={business.name} />
        )}
      </FlexContainer>
      <div className="business-card-title-text inter-b1" data-notranslate>
        {business.name}
        {subtitle && (
          <>
            <br />
            <Subtitle>{subtitle}</Subtitle>
          </>
        )}
      </div>
      <FlexContainer
        direction="horizontal"
        aligItems="center"
        justifyContent="space-between"
        gap="15px"
        responsive
      >
        {isAdmin(authUser) && teamsForLogo && Array.isArray(teamsForLogo) && (
          <div className="business-card-teams-logos">
            {teamsForLogo.map((team) => (
              <img src={team.logo} alt={team.name} />
            ))}
          </div>
        )}
        {!teams && (
          <>
            {(redemptionsCount || redemptionsCount === 0) &&
              (contestEntriesCount || contestEntriesCount === 0) && (
                <div className="business-card-redemption-count inter-b2">
                  Redemptions: <span>{redemptionsCount}</span>
                  <br />
                  Contest Entries: <span>{contestEntriesCount}</span>
                </div>
              )}
          </>
        )}
      </FlexContainer>
      {!renderButtonsGroup ? (
        <div className="business-card-buttons-group">
          <DashboardButton
            title="View"
            size="small"
            type="green"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portal/sponsors/${business.key}/dashboard`);
            }}
          />
        </div>
      ) : (
        <FlexContainer
          className="business-card-buttons-group"
          direction="horizontal"
          aligItems="center"
          justifyContent={isMobile ? "center" : "flex-end"}
          gap="15px"
        >
          <DashboardButton
            title="View"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              history.push(getViewPath(business.key));
            }}
          />
          <DashboardButton
            title="Edit"
            size="small"
            type="white"
            onClick={(e) => {
              e.preventDefault();
              history.push(getEditPath(business.key));
            }}
          />
        </FlexContainer>
      )}
    </DashboardBusinessCardWrapper>
  );
};

export default withRouter(withAuthentication(DashboardBusinessCard));

DashboardBusinessCard.propTypes = {
  business: propTypes.object,
  redemptionsCount: propTypes.number,
  renderButtonsGroup: propTypes.bool,
  teams: propTypes.bool,
  brands: propTypes.bool,
  history: propTypes.object,
  showRedemptionsTitle: propTypes.bool,
  subtitle: propTypes.string,
  authUser: propTypes.object,
  teamsForLogo: propTypes.array,
};
