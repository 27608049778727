import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

const defaultSortHits = (a, b) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  } else if (a.name) {
    return -1;
  } else if (b.name) {
    return 1;
  } else {
    return 0;
  }
};

const BaseTeamsResultsWithEmptyState = connectStateResults(
  ({
    searchState,
    searchResults,
    children,
    message,
    filterHits,
    sortHits = defaultSortHits,
  }) => {
    if (searchResults && searchResults.nbHits !== 0) {
      let filteredHits = searchResults.hits;

      if (filterHits) {
        filteredHits = filterHits(filteredHits);
      }

      filteredHits = filteredHits.sort(sortHits);
      searchResults.hits = filteredHits;

      return children;
    }

    let resultingMessage;

    if (message) {
      resultingMessage = message;
    } else {
      if (!searchState.query || searchState.query.trim() === "") {
        resultingMessage =
          "We're sorry, but there were no results for your search. Please try again.";
      } else {
        resultingMessage = `No results were found for '${searchState.query}'.`;
      }
    }

    return (
      <div className="component-results-with-empty-state empty-state">
        {resultingMessage}
      </div>
    );
  }
);

export default BaseTeamsResultsWithEmptyState;
