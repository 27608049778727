import React from 'react';
import Price from '../Price';

export default function SponsorPurchaseDashboardTotalStats(
  {
    totalPurchase, 
    totalRevenue, 
    totalPayout, 
    disablePayout,
    currency
  }){

  if(totalPurchase === 0) return null;
  
  return (
    <div className='stats'>
      <div className='stat'>
        <div className='label'>Total Purchases</div>
        <div className='value'>{totalPurchase}</div>
      </div>
      <div className='stat'>
        <div className='label'>Total Revenue</div>
        <div className='value'><Price value={totalRevenue} currency={currency}/></div>
      </div>
      {
        !disablePayout &&
        <div className='stat payout-stat'>
          <div className='label'>Total Payout</div>
          <div className='value'><Price value={totalPayout} currency={currency}/></div>
        </div>
      }
    </div>
  )
}
