import React from "react";
import { withFirebase } from "../Firebase";
import { updateUserDataAfterLoginOrSignUp } from "../../utilities/user_helpers";
import styled from "styled-components/macro";
import { LoginFormContext } from "../LoginFormContext";
import { Icon } from "@iconify/react";

import {
  Button,
  Card,
  CardBody,
  CardGroup,
  CardText,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup,
  Label,
} from "reactstrap";
import "./LoginForm.scss";

import logo from "../../assets/img/brand/logo-white.png";
import People from "../../assets/img/bg/bg-people.png";
import { COLORS } from "../../assets/fansaves-ui";

const StyledText = styled(CardText)`
  font-size: 11px;
  text-align: center;
  color: ${COLORS.primaryBlue};
  .green {
    color: #25a919;
  }
`;

class LoginForm extends React.Component {
  static contextType = LoginFormContext;
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      password: "",
      passwordConfirmation: "",
      passwordError: "",
      mode: props.mode || "login",
      subscribeToUpdates: false,
      agreeToTermsAndConditions: false,
      passwordVisible: false,
      passwordConfirmationVisible: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateEmail = () => {
    const { email } = this.state;

    if (email.trim() === "" || !email.includes("@")) {
      this.setState({ emailError: "Please enter a valid email address" });
    } else {
      this.setState({ emailError: "" });
    }
  };

  togglePasswordVisibility = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      passwordVisible: !prevState.passwordVisible,
    }));
  };

  togglePasswordConfirmationVisibility = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      passwordConfirmationVisible: !prevState.passwordConfirmationVisible,
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { firebase, onSuccessfulLogin } = this.props;

    const {
      email,
      password,
      mode,
      subscribeToUpdates,
      agreeToTermsAndConditions,
    } = this.state;

    if (mode === "login") {
      firebase
        .doSignInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");
          updateUserDataAfterLoginOrSignUp(
            firebase,
            userCredential.user,
            () => {
              console.log("Data updated successfully");
            },
            () => {
              console.error("Error while updating data");
            }
          );
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    } else {
      if (!agreeToTermsAndConditions) {
        alert("Please agree to the terms and conditions before signing up.");

        return;
      }
      firebase.auth
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const userRef = firebase.database.ref(
            `/user/${userCredential.user.uid}`
          );

          return userRef.update({
            subscribeToUpdates,
            agreeToTermsAndConditions,
            agreeToTermsAndConditionsAt: firebase.databaseServerValue.TIMESTAMP,
          });
        })
        .then(() => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");
        })
        .catch((error) => {
          this.setState({ error: error.message });
        });
    }
  };

  componentDidMount() {
    const { signUpSuggestion } = this.props;
    const { toggleLoginForm } = this.context;
    if (!signUpSuggestion) {
      toggleLoginForm();
    }
  }

  componentWillUnmount() {
    const { signUpSuggestion } = this.props;
    const { toggleLoginForm } = this.context;
    if (!signUpSuggestion) {
      toggleLoginForm();
    }
  }

  handleForgotPasswordClick = (event) => {
    event.preventDefault();
    var email = prompt(
      "Please enter your email address",
      "example@fansaves.com"
    );
    if (email != null) {
      this.props.firebase.auth.sendPasswordResetEmail(email).then(() => {
        alert("Success! Please check your email.");
      });
    }
  };

  handleLoginClick = (e) => {
    e.preventDefault();
    this.setState({ mode: "login", error: "" });
  };

  handleSignupClick = (e) => {
    e.preventDefault();
    this.setState({ mode: "signup", error: "" });
  };

  doSignInWithGoogle = (event) => {
    event.preventDefault();
    const { firebase, onSuccessfulLogin } = this.props;

    const { mode, subscribeToUpdates, agreeToTermsAndConditions } = this.state;

    if (mode === "login") {
      firebase
        .doSignInWithGoogle()
        .then((result) => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }
          updateUserDataAfterLoginOrSignUp(
            firebase,
            result.user,
            () => {
              console.log("Data updated successfully");
            },
            () => {
              console.error("Error while updating data");
            }
          );
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            alert(`Oops! Something went wrong. Please try again. Error: ${error.message}
            `);
            this.setState({ error: error.message });
          }
        });
    } else {
      if (!agreeToTermsAndConditions) {
        alert("Please agree to the terms and conditions before signing up.");

        return;
      }

      firebase
        .doSignInWithGoogle()
        .then((result) => {
          if (!result.additionalUserInfo.isNewUser) {
            return Promise.resolve(result);
          }

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }

          const userRef = firebase.database.ref(`/user/${result.user.uid}`);

          return userRef.update({
            subscribeToUpdates,
            agreeToTermsAndConditions,
            agreeToTermsAndConditionsAt: firebase.databaseServerValue.TIMESTAMP,
          });
        })
        .then(() => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            console.log("error", error);
            this.setState({ error: error.message });
          }
        });
    }
  };

  doSignInWithFacebook = (event) => {
    event.preventDefault();
    const { firebase, onSuccessfulLogin } = this.props;

    const { mode, subscribeToUpdates, agreeToTermsAndConditions } = this.state;

    if (mode === "login") {
      firebase
        .doSignInWithFacebook()
        .then((result) => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }

          updateUserDataAfterLoginOrSignUp(
            firebase,
            result.user,
            () => {
              console.log("Data updated successfully");
            },
            () => {
              console.error("Error while updating data");
            }
          );
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            alert(`Oops! Something went wrong. Please try again. Error: ${error.message}
            `);
            this.setState({ error: error.message });
          }
        });
    } else {
      if (!agreeToTermsAndConditions) {
        alert("Please agree to the terms and conditions before signing up.");

        return;
      }

      firebase
        .doSignInWithFacebook()
        .then((result) => {
          if (!result.additionalUserInfo.isNewUser) {
            return Promise.resolve(result);
          }

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }

          const userRef = firebase.database.ref(`/user/${result.user.uid}`);

          return userRef.update({
            subscribeToUpdates,
            agreeToTermsAndConditions,
            agreeToTermsAndConditionsAt: firebase.databaseServerValue.TIMESTAMP,
          });
        })
        .then(() => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            console.log("error", error);
            this.setState({ error: error.message });
          }
        });
    }
  };

  doSignInWithApple = (event) => {
    event.preventDefault();
    const { firebase, onSuccessfulLogin } = this.props;
    const { mode, subscribeToUpdates, agreeToTermsAndConditions } = this.state;

    if (mode === "login") {
      firebase
        .doSignInWithApple()
        .then((result) => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }
          updateUserDataAfterLoginOrSignUp(
            firebase,
            result.user,
            () => {
              console.log("Data updated successfully");
            },
            () => {
              console.error("Error while updating data");
            }
          );
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            alert(
              `Oops! Something went wrong. Please try again. Error: ${error.message}`
            );
            this.setState({ error: error.message });
          }
        });
    } else {
      if (!agreeToTermsAndConditions) {
        alert("Please agree to the terms and conditions before signing up.");
        return;
      }

      firebase
        .doSignInWithApple()
        .then((result) => {
          if (!result.additionalUserInfo.isNewUser) {
            return Promise.resolve(result);
          }

          if (!result.user) {
            return Promise.reject(new Error("No user signed in"));
          }

          const userRef = firebase.database.ref(`/user/${result.user.uid}`);
          return userRef.update({
            subscribeToUpdates,
            agreeToTermsAndConditions,
            agreeToTermsAndConditionsAt: firebase.databaseServerValue.TIMESTAMP,
          });
        })
        .then(() => {
          onSuccessfulLogin();
          localStorage.removeItem("showPopup");
        })
        .catch((error) => {
          if (error.code === "auth/account-exists-with-different-credential") {
            alert(
              "Oops! It looks like you have already signed up with a different method. Please sign in with the same method you used to sign up."
            );
          } else {
            console.log("error", error);
            this.setState({ error: error.message });
          }
        });
    }
  };

  validForSubmission = () => {
    const {
      email,
      password,
      passwordConfirmation,
      mode,
      agreeToTermsAndConditions,
      emailError,
    } = this.state;
    if (mode === "login") {
      let formValid = false;
      if (email.trim() !== "" && password.trim() !== "" && emailError === "") {
        formValid = true;
      }
      return formValid;
    } else {
      return (
        password.trim() !== "" &&
        email.trim() !== "" &&
        password === passwordConfirmation &&
        agreeToTermsAndConditions
      );
    }
  };

  render() {
    const {
      email,
      password,
      passwordConfirmation,
      error,
      mode,
      subscribeToUpdates,
      agreeToTermsAndConditions,
    } = this.state;

    const { signUpSuggestion } = this.props;

    const isLoginMode = mode === "login";

    return (
      <Container className="component-login-form">
        <Row className="justify-content-center">
          <Col xl="8" lg="9" md="8">
            <CardGroup
              className={`${
                mode === "login" ? "login-card-group" : "sign-up-card-group"
              }`}
            >
              <Card className="login-card">
                <CardBody>
                  <Form className="login-form">
                    <div
                      className={`login-form-tab-headers tab-headers ${
                        signUpSuggestion
                          ? "margin-bottom-20"
                          : "margin-bottom-50"
                      }`}
                    >
                      <a
                        className={`tab-header ${
                          isLoginMode ? "selected" : ""
                        }`}
                        onClick={this.handleLoginClick}
                        href="#"
                      >
                        Login
                      </a>
                      <a
                        className={`tab-header ${
                          !isLoginMode ? "selected" : ""
                        }`}
                        onClick={this.handleSignupClick}
                        href="#"
                      >
                        Sign Up
                      </a>
                    </div>
                    {signUpSuggestion && (
                      <StyledText className="popup-header text-center">
                        <span className="green">Sign up</span> or{" "}
                        <span className="green">Log in</span> for FREE to
                        continue browsing deals.
                      </StyledText>
                    )}
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-envelope"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className={`login-input ${
                          this.state.emailError ? "error-input" : ""
                        }`}
                        value={email}
                        onChange={this.handleChange}
                        name="email"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        onBlur={this.validateEmail}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleSubmit(e);
                          }
                        }}
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="login-input"
                        value={password}
                        onChange={this.handleChange}
                        name="password"
                        type={this.state.passwordVisible ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                        style={{
                          paddingRight: "40px",
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleSubmit(e);
                          }
                        }}
                      />
                      <Icon
                        icon={
                          this.state.passwordVisible
                            ? "mdi:eye-off-outline"
                            : "mdi:eye-outline"
                        }
                        width={20}
                        onClick={this.togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "22%",
                          cursor: "pointer",
                          color: "rgba(101, 101, 101, 1)",
                        }}
                      />
                    </InputGroup>
                    {!isLoginMode && (
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={passwordConfirmation}
                          onChange={this.handleChange}
                          name="passwordConfirmation"
                          className={
                            passwordConfirmation !== "" &&
                            passwordConfirmation !== password
                              ? "error-input login-input"
                              : "login-input"
                          }
                          type={
                            this.state.passwordConfirmationVisible
                              ? "text"
                              : "password"
                          }
                          placeholder="Confirm Password"
                          autoComplete="current-password"
                          style={{
                            paddingRight: "40px",
                            borderTopRightRadius: "8px",
                            borderBottomRightRadius: "8px",
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.handleSubmit(e);
                            }
                          }}
                        />
                        <Icon
                          icon={
                            this.state.passwordConfirmationVisible
                              ? "mdi:eye-off-outline"
                              : "mdi:eye-outline"
                          }
                          width={20}
                          onClick={this.togglePasswordConfirmationVisibility}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "22%",
                            cursor: "pointer",
                            color: "rgba(101, 101, 101, 1)",
                          }}
                        />
                      </InputGroup>
                    )}
                    {!isLoginMode && (
                      <div className="sign-up-checkboxes">
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={subscribeToUpdates}
                            onChange={(e) =>
                              this.setState({
                                subscribeToUpdates: e.target.checked,
                              })
                            }
                          />{" "}
                          <Label check>
                            Keep me up to date on the hottest deals and newest
                            FanPages
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={agreeToTermsAndConditions}
                            onChange={(e) =>
                              this.setState({
                                agreeToTermsAndConditions: e.target.checked,
                              })
                            }
                          />{" "}
                          <Label check>
                            I agree to the{" "}
                            <a
                              href="https://fansaves.com/terms-and-conditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underlined-link"
                            >
                              Terms and Conditions
                            </a>{" "}
                            and have read the{" "}
                            <a
                              href="https://fansaves.com/privacy-policy"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underlined-link"
                            >
                              Privacy Policy
                            </a>
                            .
                          </Label>
                        </FormGroup>
                      </div>
                    )}
                    {isLoginMode && (
                      <Row>
                        <Col xs="12" className="text-right">
                          <Button
                            color={COLORS.primaryBlue}
                            className="px-0 forgot-password-button"
                            onClick={(event) =>
                              this.handleForgotPasswordClick(event)
                            }
                          >
                            <span className="forgot-password">
                              Forgot password?
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    )}
                    <Row className="social-login-buttons">
                      <Col xs="12" className="text-center">
                        {isLoginMode ? (
                          <span>Login with</span>
                        ) : (
                          <span>Sign Up with</span>
                        )}
                      </Col>
                      <Col
                        xs="12"
                        className="d-flex justify-content-center mt-2"
                      >
                        <Button
                          onClick={this.doSignInWithGoogle}
                          className="social-icons-button"
                        >
                          <Icon
                            icon="logos:google-icon"
                            width="26"
                            height="26"
                          />
                        </Button>
                        {/* <Button
                          onClick={this.doSignInWithFacebook}
                          className="social-icons-button"
                        >
                          <Icon icon="logos:facebook" width="26" height="26" />
                        </Button>
                        <Button
                          onClick={this.doSignInWithApple}
                          className="social-icons-button"
                        >
                          <Icon icon="logos:apple" width="26" height="26" />
                        </Button> */}
                      </Col>
                    </Row>
                    {error && (
                      <Row className="error">
                        <Col xs="12">{error}</Col>
                      </Row>
                    )}{" "}
                    <Button
                      outline
                      className="login-button"
                      disabled={!this.validForSubmission()}
                      onClick={this.handleSubmit}
                    >
                      Continue
                    </Button>
                  </Form>
                </CardBody>
              </Card>
              {signUpSuggestion ? (
                <Card className="text-white d-md-down-none right-side-card">
                  <CardBody
                    className="text-center"
                    style={{
                      width: "100%",
                      backgroundColor: COLORS.primaryBlue,
                      position: "relative",
                      aspectRatio: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: "32px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      height: "100%",
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Background"
                      style={{ marginTop: "16%", marginBottom: "10%" }}
                    />
                    <CardText
                      className="card-popup-text"
                      style={{
                        fontWeight: 600,
                        fontSize: "21px",
                        color: "rgba(255, 255, 255, 1)",
                        zIndex: 2,
                      }}
                    >
                      Create your account to get access to 1,000s of FREE deals
                      from brands affiliated with your favorite organizations.
                    </CardText>
                  </CardBody>
                  <img
                    src={People}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "100%",
                    }}
                    alt="Background"
                  />
                </Card>
              ) : (
                <Card
                  className="text-white py-5 d-md-down-none right-side-card"
                  style={{
                    width: "100%",
                    backgroundColor: COLORS.primaryBlue,
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <CardBody
                    className="text-center"
                    style={{
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                    }}
                  >
                    <img
                      src={logo}
                      style={{ marginBottom: "10%", marginTop: "16%" }}
                      alt="Background"
                    />
                  </CardBody>
                  <img
                    src={People}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      width: "100%",
                    }}
                    alt="Background"
                  />
                </Card>
              )}
            </CardGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withFirebase(LoginForm);
