/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "..";
import useMobile from "../../../hooks/useMobile";
import { withFirebase } from "../../../containers/Firebase";
import { withRouter, Link } from "react-router-dom";
import { withAuthentication } from "../../../containers/Session";
import { Button } from "../../../containers/UIKit";
import { FormattedMessage } from "react-intl";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? "5px" : "5px")};
  padding: ${({ isMobile }) => (isMobile ? "10px" : "25px 10px")};
  background-color: ${COLORS.primaryBlue};
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? "fit-content" : "100%")};
`;

const TeamTitle = styled.div`
  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? "18px" : "22px")};
  color: ${COLORS.primaryWhite};
  padding-top: ${({ isMobile }) => (isMobile ? "0px" : "5px")};
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
  justify-content: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
`;

const CardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
  justify-content: ${({ isMobile }) =>
    isMobile ? "flex-start" : "flex-start"};
  gap: ${({ isMobile }) => (isMobile ? "5px" : "15px")};
  background-color: ${COLORS.primaryWhite};
  padding: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
  border-radius: ${({ isMobile }) => (isMobile ? "10px" : "30px")};
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? "fit-content" : "100%")};
  color: ${COLORS.primaryBlack};
`;

const TeamDescription = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  color: ${COLORS.primaryBlack};
  font-weight: 500;
  width: 100%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isMobile }) => (isMobile ? 3 : 4)};
  -webkit-box-orient: vertical;
`;

const TeamAddress = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "10px" : "14px")};
  color: ${COLORS.primaryBlack};
  font-weight: 400;
  width: 100%;
  text-align: left;
  margin-bottom: ${({ isMobile }) => (isMobile ? "17px" : "0px")};
  padding-bottom: ${({ isMobile }) => (isMobile ? "17px" : "0px")};
`;

const TeamImageContainer = styled.div`
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
  flex: 1;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: ${COLORS.primaryWhite};
`;

const TeamImage = styled.img`
  flex: 1;
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

const TeamInfo = styled.div`
  width: ${({ isMobile }) => (isMobile ? "60%" : "100%")};
  gap: ${({ isMobile }) => (isMobile ? "5px" : "20px")};
  padding-top: ${({ isMobile }) => (isMobile ? "0px" : "20px")};
  display: flex;
  flex-direction: column;
`;

const TeamLogo = styled.img`
  width: ${({ isMobile }) => (isMobile ? "39px" : "65px")};
  height: ${({ isMobile }) => (isMobile ? "39px" : "65px")};
  background-color: ${COLORS.primaryWhite};
  border-radius: 50%;
  object-fit: scale-down;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  bottom: ${({ isMobile }) => (isMobile ? "-10px" : "-30px")};
  left: 0px;
  z-index: 20;
  border: 1px solid ${COLORS.primaryWhite};
`;

const TeamImageSection = styled.div`
  position: relative;
  width: ${({ isMobile }) => (isMobile ? "40%" : "100%")};
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
`;

const TeamCardLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.primaryBlack};
  height: 100%;
  width: 100%;
`;

const ButtonText = styled.span`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "19px")};
  font-weight: 600;
`;
const ButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  flex: 2;
`;

const FollowTeamButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const FanPageMapCard = ({ team, firebase, authUser, history, match }) => {
  const isMobile = useMobile();
  const [isFollowed, setIsFollowed] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [teamData, setTeamData] = useState(team);

  const loadTeam = async () => {
    if (!team) return;
    const teamKey = team.key || team.objectID;

    try {
      const teamPath = `team/${teamKey}`;
      const teamRef = firebase.database.ref(teamPath);
      const snapshot = await teamRef.once("value");
      const teamData = snapshot.val();
      if (teamData) {
        setTeamData(teamData);
      }
    } catch (error) {
      console.error("Error loading team", error);
    }
  };

  useEffect(() => {
    if (team) {
      loadTeam();
    }
  }, [team]);

  let url = "";

  const teamKey = team.key || team.objectID;
  if (team.isBrand) {
    url = `/brands/${teamKey}`;
  } else {
    let slugOrKey;
    if (team.slug && team.slug.length > 0) {
      slugOrKey = team.slug;
    } else {
      slugOrKey = teamKey;
    }
    url = `/organizations/${slugOrKey}`;
  }

  useEffect(() => {
    if (authUser && authUser.teams) {
      setIsFollowed(authUser.teams.includes(team.objectID));
    }
  }, [authUser, team, team.key, team.objectID]);

  const followOrganization = async () => {
    if (!authUser) {
      alert("Sign up or Log in for FREE to follow this organization.");
      localStorage.setItem("showPopup", "true");
      return;
    }
    if (!team || !team.objectID) {
      alert(
        "Oops! Something went wrong. Please refresh the page and try again."
      );
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams || [];
      teams.push(team.objectID);

      const userPath = `user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      await userRef.update({ teams });

      setIsFollowed(true);
      setSubmitting(false);
    } catch (error) {
      console.error("Error following organization", error);
      setSubmitting(false);
    }
  };

  const unfollowOrganization = async () => {
    if (!authUser) {
      alert("Sign up or Log in for FREE to unfollow this organization.");
      localStorage.setItem("showPopup", "true");
      return;
    }
    if (!team || !team.objectID) {
      alert(
        "Oops! Something went wrong. Please refresh the page and try again."
      );
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams;
      const indexOfTeam = teams.indexOf(team.objectID);

      if (indexOfTeam > -1) {
        teams.splice(indexOfTeam, 1);

        const userPath = `user/${authUser.key}`;
        const userRef = firebase.database.ref(userPath);

        await userRef.update({ teams });

        setIsFollowed(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error unfollowing organization", error);
      setSubmitting(false);
    }
  };

  const handleFollowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (submitting) {
      return;
    }
    if (isFollowed) {
      unfollowOrganization();
    } else {
      followOrganization();
    }
  };

  const handleOnLearnMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const organization_id = team.key || team.objectID;
    match.params.organization_id = organization_id;

    history.push({
      pathname: `${url}?tab=about`,
    });
  };

  const handleOnViewDealsClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    history.push({
      pathname: url,
    });
  };

  if (!team) return null;

  return (
    <CardContainer isMobile={isMobile}>
      <TeamTitle isMobile={isMobile}>
        <span
          data-notranslate
          style={{
            textAlign: !isMobile ? "center" : "initial",
            width: !isMobile ? "100%" : "90%",
          }}
        >
          {team.name}
        </span>
      </TeamTitle>

      <TeamCardLink to={url}>
        <CardBody isMobile={isMobile}>
          {isMobile ? (
            <>
              <TeamImageSection isMobile={isMobile}>
                <TeamLogo
                  isMobile={isMobile}
                  src={team.logo}
                  alt={`${team.name} logo`}
                />

                <TeamImageContainer isMobile={isMobile}>
                  <TeamImage
                    isMobile={isMobile}
                    src={team.picture}
                    alt={team.name}
                  />
                </TeamImageContainer>
              </TeamImageSection>
              <TeamInfo isMobile={isMobile}>
                <TeamDescription isMobile={isMobile}>
                  {team.description}
                </TeamDescription>
                {teamData &&
                  teamData["arena name"] &&
                  teamData["arena address"] && (
                    <TeamAddress isMobile={isMobile}>
                      <span data-notranslate>
                        {teamData["arena name"]} {teamData["arena address"]}
                      </span>
                    </TeamAddress>
                  )}
                {!team.isBrand && (
                  <FollowTeamButton
                    className={`${
                      isFollowed ? "green-outline-continue" : "green-continue"
                    } rounded-10 small`}
                    onClick={handleFollowClick}
                  >
                    {isFollowed ? (
                      <ButtonText isMobile={isMobile}>
                        <span data-notranslate>
                          <FormattedMessage
                            id="app.team.following"
                            defaultMessage={"Following"}
                          />
                        </span>
                      </ButtonText>
                    ) : (
                      <ButtonText isMobile={isMobile}>
                        <FormattedMessage
                          id="app.team.follow"
                          defaultMessage={"Follow"}
                        />
                      </ButtonText>
                    )}
                  </FollowTeamButton>
                )}
              </TeamInfo>
            </>
          ) : (
            <>
              <TopSection>
                <TeamImageSection isMobile={isMobile}>
                  <TeamLogo
                    isMobile={isMobile}
                    src={team.logo}
                    alt={`${team.name} logo`}
                  />

                  <TeamImageContainer isMobile={isMobile}>
                    <TeamImage
                      isMobile={isMobile}
                      src={team.picture}
                      alt={team.name}
                    />
                  </TeamImageContainer>
                </TeamImageSection>
                <TeamInfo isMobile={isMobile}>
                  <TeamDescription isMobile={isMobile}>
                    {team.description}
                  </TeamDescription>
                  {teamData &&
                    teamData["arena name"] &&
                    teamData["arena address"] && (
                      <TeamAddress isMobile={isMobile}>
                        <span data-notranslate>
                          {teamData["arena name"]} {teamData["arena address"]}
                        </span>
                      </TeamAddress>
                    )}
                </TeamInfo>
              </TopSection>
              {!team.isBrand && (
                <Button
                  className={`${
                    isFollowed ? "green-outline-continue" : "green-continue"
                  } rounded-10 wide`}
                  onClick={handleFollowClick}
                >
                  {isFollowed ? (
                    <ButtonText isMobile={isMobile}>
                      <span data-notranslate>
                        <FormattedMessage
                          id="app.team.following"
                          defaultMessage={"Following"}
                        />
                      </span>
                    </ButtonText>
                  ) : (
                    <ButtonText isMobile={isMobile}>
                      <FormattedMessage
                        id="app.team.follow"
                        defaultMessage={"Follow"}
                      />
                    </ButtonText>
                  )}
                </Button>
              )}
              <ButtonGroupContainer>
                <Button
                  className="green-outline-continue rounded-10 medium"
                  onClick={handleOnLearnMoreClick}
                >
                  Learn More
                </Button>
                <Button
                  className="green-outline-continue rounded-10 medium"
                  onClick={handleOnViewDealsClick}
                >
                  View Deals
                </Button>
              </ButtonGroupContainer>
            </>
          )}
        </CardBody>
      </TeamCardLink>
    </CardContainer>
  );
};

export default withRouter(withFirebase(withAuthentication(FanPageMapCard)));
