import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";

import { FormGroup, Label, Input } from "reactstrap";
import { Button } from "../../containers/UIKit";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { Icon } from "@iconify/react";

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  border: 1px solid ${COLORS.primaryBlue};
  border-color: ${({ error }) =>
    error ? COLORS.errorRed : COLORS.primaryBlue};
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;

  &:focus {
    border-color: ${COLORS.primaryBlueHover};
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
    color: ${COLORS.darkGrey};
  }
`;

const EditIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
`;

const EditIconContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: #cfe6ff;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    background-color: #b3d6ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  gap: 10px;
  margin-top: 10px;
`;

const StyledButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const InputWithErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EmailEdit = ({ authUser, firebase }) => {
  const [editMode, setEditMode] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authUser) {
      setEmail(authUser.email || "");
    }
  }, [authUser]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!email || email.trim() === "") {
      setError("Email is required");
      return;
    }

    try {
      setSubmitting(true);
      const user = firebase.auth.currentUser;

      await user.updateEmail(email);
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      await userRef.update({ email });
      setSubmitting(false);
      setEditMode(false);
      setError("");
    } catch (error) {
      setSubmitting(false);
      switch (error.code) {
        case "auth/invalid-email":
          setError("The email address is not valid.");
          break;
        case "auth/email-already-in-use":
          setError("The email address is already in use by another account.");
          break;
        case "auth/requires-recent-login":
          setError("Please log in again and try to update your email.");
          break;
        default:
          setError("Error updating email. Please try again.");
          break;
      }
      console.error("Error updating email", error);
    }
  };

  const handleCancel = () => {
    setEmail(authUser.email);
    setEditMode(false);
    setError("");
  };

  return (
    <FormContainer>
      <FormGroup style={{ width: "100%" }}>
        <StyledLabel>Email</StyledLabel>
        <InputContainer>
          <InputWithErrorContainer>
            <StyledInput
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              onFocus={() => setError("")}
              error={error}
              disabled={!editMode}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleUpdate(e);
                }
              }}
            />
            {error && <ErrorMessage error={error} />}
          </InputWithErrorContainer>
          <EditIconContainer onClick={() => setEditMode(!editMode)}>
            <EditIcon icon="fa-solid:pencil-alt" width={17} />
          </EditIconContainer>
        </InputContainer>
        {editMode && (
          <ButtonContainer>
            <Button
              className="green-outline-continue rounded-10 medium"
              onClick={handleCancel}
              disabled={submitting}
            >
              <StyledButtonTitle>Cancel</StyledButtonTitle>
            </Button>
            <Button
              className={`green-continue rounded-10 medium ${
                (submitting || !email || email.trim() === "") && "disabled"
              }`}
              onClick={handleUpdate}
              disabled={submitting}
            >
              <StyledButtonTitle>Save</StyledButtonTitle>
            </Button>
          </ButtonContainer>
        )}
      </FormGroup>
    </FormContainer>
  );
};

export default withFirebase(withAuthentication(EmailEdit));
