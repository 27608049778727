import React from 'react';
import "./TestimonialSlide.scss";

function TestimonialSlide({ testimonial }) {

  const { quote, imageSrc, name, role } = testimonial;

  return (
    <div className='component-testimonial-slide'>
      <div className='content'>
        <div className='quote'>
          “{quote}”
        </div>
        <div className='image'><img src={imageSrc} /></div>
        <div className='name'>{name}</div>
        <div className='role'>{role}</div>
      </div>
    </div>
  );
}

export default TestimonialSlide;