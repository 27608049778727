import React, { Component } from "react";
import {
  Form as ReactStrapForm,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ImageUploader from "../../../../../containers/ImageUploader";

import "./Form.scss";

import moment from "moment";

import {
  imageUploadInputStyle,
  imageStyle,
  labelStyle,
} from "../../../utilities/PortalFormStyles";

class Form extends Component {
  constructor(props) {
    super(props);

    const defaultExpiresAtMoment = moment();
    defaultExpiresAtMoment.add(30, "days");
    defaultExpiresAtMoment.set("hour", 23);
    defaultExpiresAtMoment.set("minute", 59);
    defaultExpiresAtMoment.set("second", 0);

    let initialState = {
      pictureFile: null,
      picturePath: null,
      redeemImageFile: null,
      redeemImagePath: null,
      description: "",
      descriptionFr: "",
      terms: "",
      sponsorID: "",
      delayHour: 0,
      delayMonth: 0,
      delayWeek: 0,
      delayDay: 0,
      dealType: "instore",
      onlineCode: "",
      website: "",
      price: "0.00",
      originalValue: "0.00",
      totalAvailable: 0,
      expiresAt: defaultExpiresAtMoment.toDate(),
      keywords: "",
      visibility: "public",
      currency: "CAD",
      triggered: "false",
      triggerDurationHours: 24,
      redemptionsLimit: "false",
      notTriggeredMessage: "",
      accessLevels: [],
      easyRedemption: false,
    };

    const { existingDeal } = props;

    if (existingDeal) {
      let days = 0;
      let hours = 0;
      let week = 0;
      let month = 0;

      if (existingDeal.interval) {
        days = Math.floor(existingDeal.interval.hour / 24);
        hours = existingDeal.interval.hour % 24;
        week = existingDeal.interval.week;
        month = existingDeal.interval.month;
      }

      let expiresAt = new Date();
      if (
        existingDeal.expiresAt !== undefined &&
        existingDeal.expiresAt !== ""
      ) {
        expiresAt = new Date(existingDeal.expiresAt);
      }

      let visibility = "public";
      if (existingDeal.exclusive) {
        visibility = "exclusive";
      } else if (existingDeal.flashDeal) {
        visibility = "flash";
      } else if (existingDeal.contestDeal) {
        visibility = "contest";
      } else if (existingDeal.hiddenDeal) {
        visibility = "hidden";
      }

      initialState = {
        picturePath: existingDeal.picture,
        redeemImagePath: existingDeal.redeemImage,
        description: existingDeal.description || "",
        descriptionFr: existingDeal.descriptionFr || "",
        terms: existingDeal.terms || "",
        delayMonth: month || 0,
        delayWeek: week || 0,
        delayHour: hours || 0,
        delayDay: days || 0,
        dealType: existingDeal.dealType || "instore",
        onlineCode: existingDeal.onlineCode || "",
        website: existingDeal.website || "",
        price: existingDeal.price || 0.0,
        originalValue: existingDeal.originalValue || 0.0,
        totalAvailable: existingDeal.totalAvailable || 0,
        expiresAt,
        keywords: existingDeal.keywords || "",
        visibility,
        currency: existingDeal.currency || "CAD",
        triggered: existingDeal.triggered ? "true" : "false",
        triggerDurationHours: existingDeal.triggerDurationHours || 24,
        redemptionsLimit: existingDeal.redemptionsLimit ? "true" : "false",
        notTriggeredMessage: existingDeal.notTriggeredMessage || "",
        accessLevels: existingDeal.accessLevels || [],
        easyRedemption: existingDeal.easyRedemption || false,
      };
    }
    this.state = initialState;
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const files = e.target.files;
    const isChecked = e.target.checked;

    if (files) {
      const fileNameStateKey = name + "File";
      const filePathStateKey = name + "Path";
      const file = files[0];
      this.setState({ [fileNameStateKey]: file });
      this.setState({ [filePathStateKey]: URL.createObjectURL(file) });
    } else {
      if (name.includes("delay") && value) {
        value = Number.parseInt(value);
      } else if (name.includes("accessLevels")) {
        const { accessLevels } = this.state;
        if (isChecked) {
          accessLevels.push(value);
        } else {
          const index = accessLevels.indexOf(value);
          if (index > -1) {
            accessLevels.splice(index, 1);
          }
        }
        value = accessLevels;
      } else if (name === "easyRedemption") {
        value = isChecked;
      }

      this.setState({ [name]: value });
    }
  };

  setDate = (field, date) => {
    this.setState({ [field]: date });
  };

  pictureClick = () => {
    document.getElementById("picture_upload").click();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { onSubmit } = this.props;
    const {
      pictureFile,
      picturePath,
      redeemImageFile,
      redeemImagePath,
      description,
      descriptionFr,
      terms,
      sponsorID,
      delayHour,
      delayMonth,
      delayWeek,
      delayDay,
      dealType,
      onlineCode,
      website,
      price,
      originalValue,
      totalAvailable,
      keywords,
      visibility,
      currency,
      triggered,
      triggerDurationHours,
      redemptionsLimit,
      notTriggeredMessage,
      accessLevels,
      easyRedemption,
    } = this.state;

    let { expiresAt } = this.state;

    let exclusive = false;
    let flashDeal = false;
    let contestDeal = false;
    let hiddenDeal = false;

    if (visibility === "public") {
      exclusive = false;
      flashDeal = false;
      contestDeal = false;
      hiddenDeal = false;
      expiresAt = null;
    } else if (visibility === "exclusive") {
      exclusive = true;
      flashDeal = false;
      contestDeal = false;
      hiddenDeal = false;
      expiresAt = null;
    } else if (visibility === "flash") {
      exclusive = false;
      flashDeal = true;
      hiddenDeal = false;
      contestDeal = false;
    } else if (visibility === "contest") {
      exclusive = false;
      flashDeal = false;
      hiddenDeal = false;
      contestDeal = true;
      if (triggered === "true") {
        expiresAt = null;
      }
    } else if (visibility === "hidden") {
      hiddenDeal = true;
      exclusive = false;
      flashDeal = false;
      contestDeal = false;
      expiresAt = null;
    }

    const deal = {
      pictureFile,
      picturePath,
      redeemImageFile,
      redeemImagePath,
      description,
      descriptionFr,
      terms,
      sponsorID,
      delayHour,
      delayMonth,
      delayWeek,
      delayDay,
      dealType,
      onlineCode,
      website,
      price: parseFloat(price),
      originalValue: parseFloat(originalValue),
      totalAvailable: parseInt(totalAvailable),
      expiresAt,
      keywords,
      visibility,
      exclusive,
      flashDeal,
      contestDeal,
      hiddenDeal,
      currency: currency,
      triggerDurationHours: parseFloat(triggerDurationHours),
      notTriggeredMessage,
      accessLevels,
      easyRedemption,
    };

    if (contestDeal) {
      deal.dealType = "online";
    }

    if (triggered === "true") {
      deal.active = false;
      deal.triggered = true;
      deal.active = false;
      if (!contestDeal) {
        deal.redemptionsLimit = redemptionsLimit === "true";
      } else {
        deal.redemptionsLimit = false;
      }
    } else {
      deal.triggered = false;
      if (contestDeal) {
        deal.active = false;
      } else {
        deal.active = true;
      }
    }

    deal.published = true;

    onSubmit(deal);
  };

  render() {
    return (
      <ReactStrapForm className="component-portal-sponsors-deals-form">
        <div className="row">
          <div className="col-12">
            <FormGroup className="text-center">
              <Label style={labelStyle}>
                <strong>PICTURE</strong>
              </Label>
              <ImageUploader
                src={this.state.picturePath}
                style={imageStyle}
                onClick={this.pictureClick}
                alt="Deal"
              />
              <input
                name="picture"
                onChange={(event) => this.handleUserInput(event)}
                id="picture_upload"
                type="file"
                style={imageUploadInputStyle}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <FormGroup>
              <Label>DESCRIPTION</Label>
              <Input
                name="description"
                value={this.state.description}
                onChange={(event) => this.handleUserInput(event)}
              ></Input>
            </FormGroup>
          </div>
          <div className="col-sm-12 col-md-6">
            <FormGroup>
              <Label>DESCRIPTION (FR)</Label>
              <Input
                name="descriptionFr"
                value={this.state.descriptionFr}
                onChange={(event) => this.handleUserInput(event)}
              ></Input>
            </FormGroup>
          </div>
          <div className="col-sm-12 col-md-6">
            <FormGroup>
              <Label>TERMS</Label>
              <Input
                name="terms"
                value={this.state.terms}
                onChange={(event) => this.handleUserInput(event)}
              ></Input>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-3">
            <FormGroup>
              <Label for="visbility">Visibility</Label>
              <Input
                type="select"
                name="visibility"
                id="visibility"
                value={this.state.visibility}
                onChange={(event) => this.handleUserInput(event)}
              >
                <option value="public">Public</option>
                <option value="exclusive">Exclusive</option>
                <option value="flash">Flash</option>
                <option value="contest">Contest</option>
                <option value="hidden">Hidden</option>
              </Input>
            </FormGroup>
          </div>
        </div>

        {this.state.visibility !== "contest" &&
          this.state.visibility !== "flash" && (
            <div className="row margin-bottom-20">
              <div className="col-sm-12 col-md-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="easyRedemption"
                      checked={this.state.easyRedemption}
                      onChange={(event) => this.handleUserInput(event)}
                      className="custom-checkbox"
                    />{" "}
                    Easy Redemption
                  </Label>
                </FormGroup>
              </div>
            </div>
          )}

        {this.state.visibility !== "flash" &&
          this.state.visibility !== "contest" && (
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label>Deal Delay (Month)</Label>
                  <Input
                    type="number"
                    name="delayMonth"
                    value={this.state.delayMonth}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label>Deal Delay (Week)</Label>
                  <Input
                    type="number"
                    name="delayWeek"
                    value={this.state.delayWeek}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label>Deal Delay (Day)</Label>
                  <Input
                    type="number"
                    name="delayDay"
                    value={this.state.delayDay}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label>Deal Delay (Hour)</Label>
                  <Input
                    type="number"
                    name="delayHour"
                    value={this.state.delayHour}
                    onChange={(event) => this.handleUserInput(event)}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          )}

        {this.state.visibility === "hidden" && (
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label for="accessLevels">Access Levels</Label>
                <div>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="accessLevels"
                        value="flexPass"
                        checked={this.state.accessLevels.includes("flexPass")}
                        onChange={this.handleUserInput}
                      />{" "}
                      Flex Pass Holders
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="accessLevels"
                        value="groupPass"
                        checked={this.state.accessLevels.includes("groupPass")}
                        onChange={this.handleUserInput}
                      />{" "}
                      Group Pass Holders
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="accessLevels"
                        value="player"
                        checked={this.state.accessLevels.includes("player")}
                        onChange={this.handleUserInput}
                      />{" "}
                      Players
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </div>
          </div>
        )}

        {this.state.visibility === "flash" && (
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>Price</Label>
                <Input
                  type="number"
                  name="price"
                  value={this.state.price}
                  onChange={(event) => this.handleUserInput(event)}
                ></Input>
              </FormGroup>
            </div>

            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>Currency</Label>
                <Input
                  type="select"
                  name="currency"
                  value={this.state.currency}
                  onChange={(e) => this.setState({ currency: e.target.value })}
                >
                  <option value="CAD">CAD</option>
                  <option value="USD">USD</option>
                </Input>
              </FormGroup>
            </div>

            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>Original Value</Label>
                <Input
                  type="number"
                  name="originalValue"
                  value={this.state.originalValue}
                  onChange={(event) => this.handleUserInput(event)}
                ></Input>
              </FormGroup>
            </div>

            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>Total Available</Label>
                <Input
                  type="number"
                  name="totalAvailable"
                  value={this.state.totalAvailable}
                  onChange={(event) => this.handleUserInput(event)}
                ></Input>
              </FormGroup>
            </div>

            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label>Expires at</Label>
                <div>
                  <DatePicker
                    className="form-control"
                    selected={this.state.expiresAt}
                    onChange={(date) => this.setDate("expiresAt", date)}
                    showTimeInput
                  />
                </div>
              </FormGroup>
            </div>
          </div>
        )}

        {this.state.visibility !== "flash" &&
          this.state.visibility !== "contest" && (
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label for="dealType">Deal Type</Label>
                  <Input
                    type="select"
                    name="dealType"
                    id="dealType"
                    value={this.state.dealType}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value="instore">In Store</option>
                    <option value="online">Online</option>
                  </Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-3">
                {this.state.dealType === "online" && (
                  <FormGroup>
                    <Label>Website</Label>
                    <Input
                      name="website"
                      value={this.state.website}
                      onChange={(event) => this.handleUserInput(event)}
                    ></Input>
                  </FormGroup>
                )}
              </div>
              <div className="col-sm-12 col-md-3">
                {this.state.dealType === "online" && (
                  <FormGroup>
                    <Label>Online Code</Label>
                    <Input
                      name="onlineCode"
                      value={this.state.onlineCode}
                      onChange={(event) => this.handleUserInput(event)}
                    ></Input>
                  </FormGroup>
                )}
              </div>
            </div>
          )}

        <div className="row">
          <div className="col-sm-12 col-md-3">
            <FormGroup>
              <Label for="triggered">Triggered Deal</Label>
              <Input
                type="select"
                name="triggered"
                id="triggered"
                value={this.state.triggered}
                onChange={(event) => this.handleUserInput(event)}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </Input>
            </FormGroup>
          </div>

          {this.state.triggered === "true" && (
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label for="triggerDurationHours">
                  Active for how many Hours?
                </Label>
                <Input
                  type="number"
                  name="triggerDurationHours"
                  id="triggerDurationHours"
                  value={this.state.triggerDurationHours}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </FormGroup>
            </div>
          )}
          {this.state.triggered === "true" &&
            this.state.visibility !== "contest" && (
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label for="redemptionsLimit">
                    Limit number of redemptions?
                  </Label>
                  <Input
                    type="select"
                    name="redemptionsLimit"
                    id="redemptionsLimit"
                    value={this.state.redemptionsLimit}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </Input>
                </FormGroup>
                {this.state.redemptionsLimit === "true" && (
                  <FormGroup>
                    <Label>Total Available</Label>
                    <Input
                      type="number"
                      name="totalAvailable"
                      value={this.state.totalAvailable}
                      onChange={(event) => this.handleUserInput(event)}
                    ></Input>
                  </FormGroup>
                )}
              </div>
            )}
          {this.state.triggered === "true" && (
            <div className="col-sm-12 col-md-6">
              <FormGroup>
                <Label for="notTriggeredMessage">
                  Message to show when deal is not triggered (optional)
                </Label>
                <Input
                  type="textarea"
                  name="notTriggeredMessage"
                  id="notTriggeredMessage"
                  value={this.state.notTriggeredMessage}
                  onChange={(event) => this.handleUserInput(event)}
                  placeholder="e.g. When the Minnesota North Stars score a hat trick you will be able to enter this contest to win a free puppy!"
                />
              </FormGroup>
            </div>
          )}
        </div>

        {this.state.visibility === "contest" &&
          this.state.triggered !== "true" && (
            <div className="row">
              <div className="col-sm-12 col-md-3">
                <FormGroup>
                  <Label>Expires at</Label>
                  <div>
                    <DatePicker
                      className="form-control"
                      selected={this.state.expiresAt}
                      onChange={(date) => this.setDate("expiresAt", date)}
                      showTimeInput
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          )}

        {this.state.visibility !== "contest" && (
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <FormGroup>
                <Label for="triggered">
                  Redeem Image (e.g. Barcode, QR Code)
                </Label>
                <img
                  src={this.state.redeemImagePath}
                  className="redeem-image-preview"
                  alt="RedeemImage"
                ></img>
                <Input
                  name="redeemImage"
                  onChange={(event) => this.handleUserInput(event)}
                  type="file"
                />
              </FormGroup>
            </div>

            <div className="col-sm-12 col-md-9"></div>
          </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <FormGroup>
              <Label>Keywords</Label>
              <Input
                name="keywords"
                value={this.state.keywords}
                onChange={(event) => this.handleUserInput(event)}
              ></Input>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Button color="primary" onClick={this.handleSubmit}>
              {this.props.isSubmitting ? "Submitting ..." : "Submit"}
            </Button>
          </div>
        </div>
      </ReactStrapForm>
    );
  }
}

export default Form;
