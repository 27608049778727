import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";
import ReactPaginate from "react-paginate";

import "../../../scss/base/typography.scss";

const PaginateContainer = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline;
      margin: 0 5px;
      cursor: pointer;

      a {
        color: ${COLORS.primaryBlue};
        background-color: transparent;
        border: 1px solid ${COLORS.primaryBlue};
        padding: 3px 6px;
        width: 100%;
        height: 0;
      }

      &.break a,
      &.previous a,
      &.next a {
        border: none;
      }

      &.selected a {
        background-color: ${COLORS.primaryBlue};
        color: ${COLORS.primaryWhite};
        padding: 3px 6px;
        width: 100%;
        height: 0;
      }
    }
  }
`;

const Pagination = ({
  totalPages,
  onPageChange,
  pageNumber,
  className,
  marginBottom,
}) => {
  return (
    <PaginateContainer
      className={`react-pagination ${className}`}
      marginBottom={marginBottom}
    >
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={2}
        forcePage={pageNumber - 1}
        marginPagesDisplayed={1}
        onPageChange={(page) => {
          onPageChange(page.selected + 1);
        }}
        previousLabel={`${totalPages > 1 && pageNumber > 1 ? "<" : " "}`}
        nextLabel={`${totalPages > 1 && pageNumber < totalPages ? ">" : " "}`}
        renderOnZeroPageCount={null}
        className={`rubik-h3 ${className}`}
      />
    </PaginateContainer>
  );
};

export default Pagination;

Pagination.propTypes = {
  totalPages: propTypes.number,
  onPageChange: propTypes.func,
  pageNumber: propTypes.number,
  className: propTypes.string,
  marginBottom: propTypes.string,
};
