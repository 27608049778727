import React from "react";
import "./ConfirmContestDealModal.scss";
import PropTypes from "prop-types";
import ModalContainer from "../Modals/Modal";
import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";
import { Text } from "../../assets/fansaves-ui";

const ConfirmContestDealModal = ({
  onClose,
  onConfirm,
  deal,
  organization,
  isMobile,
  backgroundColor,
}) => {
  return (
    <div className="component-context-modal">
      <ModalContainer
        onClose={onClose && onClose}
        backgroundColor={backgroundColor}
      >
        {deal && (
          <div className="contest-main-content">
            <div className="contest-modal-title">
              <Text type="standard-h2">CONGRATS!</Text>
            </div>
            <div className="contest-modal-text">
              <Text type="standard-h3">
                You have successfully entered this contest! We will be in touch
                if you win.
              </Text>
            </div>
          </div>
        )}
      </ModalContainer>
    </div>
  );
};

ConfirmContestDealModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  deal: PropTypes.object,
  organization: PropTypes.object,
  isMobile: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default ConfirmContestDealModal;
