import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Price from '../Price';
import { withFirebase } from '../Firebase';

import './DealReceipt.scss';

const DealReceipt = (props) => {

  const [dealKey, setDealKey] = useState();
  const [deal, setDeal] = useState();
  const [purchase, setPurchase] = useState();
  const [sponsor, setSponsor] = useState();

  const loadSponsor = () => {
    const {
      firebase,
    } = props;

    const sponsorPath = `/sponsor/${deal.sponsor.sponsorKey}`;
    const sponsorRef = firebase.database.ref(sponsorPath);
    sponsorRef.once('value').then((snapshot) => {
      if(snapshot.val() !== null) {
        const sponsor = snapshot.val();
        sponsor.key = snapshot.key;
  
        setSponsor(sponsor);
      }
    });
  }

  const loadDeal = () => {
    const { 
      firebase,
    } = props;

    const offerPath = `/offer/${dealKey}`;
    const offerRef = firebase.database.ref(offerPath);
    offerRef.once('value').then((snapshot) => {
      if(snapshot.val() !== null) {
        const deal = snapshot.val();
        deal.key = snapshot.key;
  
        setDeal(deal);
      }
    });
  }

  const loadPurchase = () => {
    const {
      firebase,
      purchaseKey,
    } = props;

    const purchasePath = `/purchase/${purchaseKey}`;
    const purchaseRef = firebase.database.ref(purchasePath);
    purchaseRef.once('value').then((snapshot) => {
      if(snapshot.val() !== null) {
        const purchase = snapshot.val();
        purchase.key = snapshot.key;
  
        setPurchase(purchase);
        setDealKey(purchase.offerKey);
      }
    });
  }

  useEffect(() => {
    if(dealKey) {
      loadDeal();
    }
  }, [dealKey]);

  useEffect(() => {
    if(deal) {
      loadSponsor();
    }
  }, [deal]);

  useEffect(() => {
    if(props.purchaseKey) {
      loadPurchase();
    } else if(props.dealKey) {
      setDealKey(props.dealKey);
    } 
  }, []);

  if(!deal) {
    return null;
  }

  return (
    <div className='component-deal-receipt'>
      <div className='image-area'>
        <img src={deal.picture} alt={deal.description} className='deal-picture' />
      </div>
      <div className='deal-info'>
        <div className='description'>
          {deal.description}
        </div>
        <div className='price'>
          <Price value={deal.price} currency={deal.currency}/>
        </div>
      </div>
      {
        purchase &&
        <div className='purchase-info'>
          <div className='label'>REDEEM CODE</div>
          <div className='code'>{purchase.code}</div>
        </div>
      }
      {
        sponsor &&
        <div className='sponsor-info'>
          <div className='name'>{sponsor.name}</div>
          <div className='address'>{sponsor.address}</div>
        </div>
      }
      <div className='deal-link-container'>
        <Link to={`/deals/${deal.key}`}>View original deal</Link>
      </div>
    </div>
  );
};

export default withFirebase(DealReceipt);