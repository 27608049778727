import React, { useEffect } from "react";
import People from "../../assets/img/bg/bg-people.png";
import { COLORS } from "../../assets/fansaves-ui";
import logo from "../../assets/img/brand/logo-white.png";
import { DefaultContainer } from "../../containers/UIKit";
import { withAuthentication } from "../../containers/Session";
import { withFirebase } from "../../containers/Firebase";
import { withRouter } from "react-router-dom";

import { Card, CardBody, CardGroup, CardText, Col, Row } from "reactstrap";
import OnboardingTitle from "./OnboardingTitle";
import useMobile from "../../hooks/useMobile";
import {
  OnboardingUserNameForm,
  OnboardingPersonalInfoForm,
} from "../../assets/fansaves-ui";
import useOnboardingStore from "../../stores/onboardingStore";
import FanPagesSelector from "../../containers/FanPagesSelector";
import { Redirect } from "react-router-dom";

const Onboarding = ({ firebase, authUser, history }) => {
  const isMobile = useMobile();
  const currentStep = useOnboardingStore((state) => state.currentStep);
  const setCurrentStep = useOnboardingStore((state) => state.setCurrentStep);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <OnboardingUserNameForm />;
      case 2:
        return <OnboardingPersonalInfoForm />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!authUser) {
      return;
    }
    history.push(`/onboarding?step=${currentStep}`);

    const handleBack = (e) => {
      e.preventDefault();
      if (currentStep === 1) {
        setCurrentStep(1);
      } else {
        setCurrentStep(currentStep - 1);
      }
    };

    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [authUser, currentStep, history, setCurrentStep]);

  if (!authUser) {
    return <Redirect to="/" />;
  }

  return (
    <DefaultContainer className="onboarding-container">
      <Row className="justify-content-center">
        <Col xl="12" lg="12" md="12" sm="12" xs="12">
          {currentStep === 3 ? (
            <FanPagesSelector />
          ) : (
            <CardGroup
              style={{
                minHeight: isMobile ? "90vh" : "750px",
                marginTop: isMobile ? "0px" : "10px",
                display: "flex",
                gap: isMobile ? "0px" : "50px",
              }}
            >
              <Card style={{ flex: 1, border: "none" }}>
                <CardBody
                  style={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    aspectRatio: 1,
                    minHeight: !isMobile ? "750px" : "auto",
                  }}
                >
                  <OnboardingTitle stepNumber={currentStep} />
                  {renderStep()}
                </CardBody>
              </Card>
              <Card className="text-white d-md-down-none right-side-card">
                <CardBody
                  className="text-center"
                  style={{
                    width: "100%",
                    aspectRatio: 1,
                    backgroundColor: COLORS.primaryBlue,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: "60px",
                    justifyContent: "flex-start",
                    paddingTop: "60px",
                    alignItems: "center",
                    height: "100%",
                    minHeight: "750px",
                  }}
                >
                  <img src={logo} alt="Background" />
                  <CardText
                    className="card-slogan"
                    style={{
                      fontWeight: 600,
                      fontSize: "21px",
                      color: "rgba(255, 255, 255, 1)",
                      zIndex: 2,
                      width: "80%",
                      maxWidth: "450px",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Create your account to get access to 1,000s of FREE deals
                    from brands affiliated with your favorite organizations.
                  </CardText>
                </CardBody>
                <img
                  src={People}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "100%",
                  }}
                  alt="Background"
                />
              </Card>
            </CardGroup>
          )}
        </Col>
      </Row>
    </DefaultContainer>
  );
};

export default withFirebase(withAuthentication(withRouter(Onboarding)));
