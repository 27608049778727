const Months = [
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

const Days = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const DaysOptions = Days.map((day) => ({ value: day, label: day }));

const generateArray = (start, end, reverse) => {
  const array = [];
  if (reverse) {
    for (let i = end; i >= start; i--) {
      array.push({ label: i.toString(), value: i });
    }
    return array;
  }
  for (let i = start; i <= end; i++) {
    array.push({ label: i.toString(), value: i });
  }
  return array;
};

// generate a list of years from 1900 to current year

const Years = generateArray(
  new Date().getFullYear() - 100,
  new Date().getFullYear() - 12,
  true
);

export { Months, Days, Years, DaysOptions };
