export const imageUploadInputStyle = {
  display: "none",
};

export const imageStyle = {
  borderRadius: "50%",
  background: "white",
  width: "100px",
  height: "100px",
  border: "1px solid #b3b3b3",
};

export const labelStyle = {
  display: "block",
  textAlign: "center",
};

export const inputStyle = {
  borderColor: "#b3b3b3",
};

export const selectorStyle = {
  borderColor: "#b3b3b3",
};
