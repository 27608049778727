import React, { useState, useEffect, useRef, useCallback } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import Pin from "../../../assets/img/team-marker.svg";
import "./TeamMarker.scss";

const TeamMarker = ({ team, onClick }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selected, setSelected] = useState(null);
  const infoWindowRef = useRef();

  const handleOnClick = () => {
    setSelected(!selected);
    if (onClick && typeof onClick === "function") {
      onClick(team);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (
        infoWindowRef.current &&
        !infoWindowRef.current.contains(event.target)
      ) {
        setShowInfoWindow(false);
        setSelected(false);
        if (onClick && typeof onClick === "function") {
          onClick(null);
        }
      }
    },
    [onClick]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Marker
      key={team.objectID}
      position={{ lat: team._geoloc.lat, lng: team._geoloc.lng }}
      onClick={handleOnClick}
      onMouseOver={() => setShowInfoWindow(true)}
      onMouseOut={() => !selected && setShowInfoWindow(false)}
      icon={{
        url: Pin,
        scaledSize: new window.google.maps.Size(30, 30),
      }}
    >
      {(showInfoWindow || selected) && (
        <InfoWindow
          onCloseClick={() => {
            setShowInfoWindow(false);
            setSelected(false);
          }}
          options={{
            maxWidth: 200,
            minWidth: 200,
            pixelOffset: new window.google.maps.Size(120, 40),
            disableAutoPan: true,
            headerDisabled: true,
          }}
        >
          <div ref={infoWindowRef} className="team-marker-info-window">
            <div>{team.name}</div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default TeamMarker;
