import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container, Row, Col } from "reactstrap";
import { withFirebase } from "../../../../containers/Firebase";
import "./OfferPreview.scss";

function OfferPreview({ firebase, offerKey, onClose, onCancel, onActivate }) {
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState();

  function loadOffer() {
    firebase.database
      .ref(`/offer/${offerKey}`)
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          setLoading(false);
          const result = snapshot.val();
          result.key = snapshot.key;
          setOffer(result);
        } else {
          setLoading(false);
        }
      });
  }

  useEffect(loadOffer, []);

  return (
    <div className="component-offer-preview">
      <Container className="close-modal-container">
        <Row className="justify-content-center">
          <Col xl="8" lg="9" md="8">
            <div className="close-modal-icon-container">
              <i className="icon-close" onClick={onClose} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-center">
          <Col xl="8" lg="9" md="8">
            <div className="main-content">
              <div className="caption">
                Are you sure you want to activate the following offer?
              </div>

              <div className="offer-info">
                {!loading && (
                  <>
                    {offer ? (
                      <div className="offer-preview-box">
                        <div className="image-container ">
                          <img
                            src={offer.picture}
                            className="offer-picture"
                            alt="offer"
                          />
                        </div>
                        <div className="description">{offer.description}</div>
                        {offer.sponsor && (
                          <div className="sponsor-info">
                            <img
                              src={offer.sponsor.picture}
                              className="sponsor-picture"
                              alt="sponsor"
                            />
                            <div className="sponsor-name" data-notranslate>
                              {offer.sponsor.name}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="no-offer">
                        We're sorry, but the offer information could not be
                        loaded.
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="actions">
                <Button className="" onClick={onCancel}>
                  Cancel
                </Button>
                <Button className="btn-success" onClick={onActivate}>
                  Activate
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

OfferPreview.propTypes = {
  offerKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onActivate: PropTypes.func,
};

export default withFirebase(OfferPreview);
