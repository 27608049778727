import React from 'react';
import DealReceipt from '../../../containers/DealReceipt';
import { Link } from 'react-router-dom';
import { DefaultContainer } from '../../../containers/UIKit'

import './Show.scss';

const Show = ({ match }) => {
  const purchaseKey = match.params.purchase_id;

  return (
    <DefaultContainer>
      <div className='views-purchases-show'>
        <div className='instructions'>
          <p className='main'>
            To redeem this purchased deal, show the following code to a staff member.
          </p>
          <p className='sub'>
            You may show this code on your phone or print it out and bring it in.
            This code is transferable (i.e. you can share it as a gift) but please note
            that this code can only be used once. Once it is redeemed,
            the time and date of redemption will be indicated in your <Link to='/purchases'>My Purchases</Link> tab.
          </p>
        </div>
        <DealReceipt purchaseKey={purchaseKey} />
      </div>
    </DefaultContainer>
  );
}

export default Show;