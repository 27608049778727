const moment = require("moment");

const offerExpired = (offer) => {
  return moment().isAfter(offer.expiresAt);
};

const offerSoldOut = (offer) => {
  return offer.totalPurchased >= offer.totalAvailable;
};

const triggeredOfferLimitReached = (offer) => {
  return (
    offer.triggered &&
    offer.redemptionsLimit &&
    offer.totalAvailable &&
    (offer.totalRedeemed || 0) >= offer.totalAvailable
  );
};

module.exports = {
  offerExpired,
  offerSoldOut,
  triggeredOfferLimitReached,
};
