import styled from "styled-components/macro";

const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 3fr));
  grid-auto-flow: column;
  grid-gap: ${({ gap }) => gap || "0"};
  margin: ${({ margin }) => margin || "0 auto"};
  .row-item {
    grid-column: span 2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    text-align: center;
    border-bottom: ${(props) =>
      props.hideBorder
        ? "none"
        : props.boldBorder
        ? `2px solid rgba(70, 70, 70, 0.5)`
        : `1px solid rgba(70, 70, 70, 0.5)`};
  }
  .ellipsis-row-item {
    justify-content: flex-start;
    .email,
    .name,
    .location {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      justify-content: flex-start;
    }
  }
  .wide-row-item {
    grid-column: span 3;
  }
  .narrow-row-item {
    grid-column: span 1;
  }
  .ellipsis-row-item.center {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
  }

  .flex-start-row-item {
    justify-content: flex-start;
    text-align: left;
  }
`;

export default GridRow;
