import React from "react";
import propTypes from "prop-types";
import { COLORS } from "../colors";
import { Icon } from "@iconify/react";
import { FlexContainer } from "../Containers";

const SpinLoader = ({ className, iconSize, iconColor, hideTitle }) => {
  return (
    <FlexContainer
      direction="horizontal"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      className={`spin-loader ${className}`}
    >
      {!hideTitle && "Loading"}
      <Icon
        icon="eos-icons:loading"
        style={{ fontSize: iconSize, color: iconColor }}
      />
    </FlexContainer>
  );
};

SpinLoader.propTypes = {
  className: propTypes.string,
  iconSize: propTypes.string,
  iconColor: propTypes.string,
  hideTitle: propTypes.bool,
};

SpinLoader.defaultProps = {
  iconSize: "25px",
  iconColor: COLORS.primaryBlack,
  hideTitle: false,
};

export default SpinLoader;
