import React from "react";

import "./DealExclusiveBanner.scss";
import "../../scss/base/typography.scss";

const DealExclusiveBanner = ({
  deal,
  unlocked,
  isMobile,
  name,
  onClose,
  onSuccess,
  onShowUnlockExclusiveModal,
}) => {
  if (!deal.exclusive) {
    return <></>;
  }

  return (
    <div className="component-deal-exclusive-banner">
      {deal.exclusive && (
        <>
          <div className={`exclusive-deal-banner ${onSuccess && "success"}`}>
            <div className="exclusive-deal-banner-content">
              {!onSuccess ? (
                <div
                  className={`locked ${
                    isMobile ? "mobile-b8" : " standard-h5"
                  }`}
                >
                  Please note this is an Exclusive Deal. To redeem it please{" "}
                  <a
                    className="deal-exclusive-banner-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      onShowUnlockExclusiveModal(true);
                    }}
                  >
                    Enter Code Here!
                  </a>
                </div>
              ) : (
                <div
                  className={`unlocked ${
                    isMobile ? "mobile-b8" : " standard-h5"
                  }`}
                >
                  Congrats! You have successfully unlocked all deals from{" "}
                  {name && name}!
                </div>
              )}
            </div>
            <i className="fa fa-times close-banner-button" onClick={onClose} />
          </div>
        </>
      )}
    </div>
  );
};

export default DealExclusiveBanner;
