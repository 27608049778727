import React, { useState, useEffect } from "react";
import DealGroupForm from "../Form";
import { withFirebase } from "../../../../../containers/Firebase";
import {
  dbLoadBrand,
  dbLoadOfferGroup,
} from "../../../../../containers/DatabaseHelpers/DatabaseHelpers";
import { uploadResourceImages } from "../../../utilities/FormHelpers";

function Edit({ firebase, match, history }) {
  const brandKey = match.params.brand_id;
  const [loadingDealGroup, setLoadingDealGroup] = useState(true);
  const [loadingBrand, setLoadingBrand] = useState(true);
  const [brand, setBrand] = useState();
  const [dealGroup, setDealGroup] = useState();

  function updateDealsWithDealGroupData(dealGroupKey, dealGroupData) {
    const updates = {};

    dealGroup.offerKeys &&
      dealGroup.offerKeys.forEach((offerKey) => {
        updates[`/offer/${offerKey}/offerGroupKey`] = null;
      });

    dealGroupData.offerKeys &&
      dealGroupData.offerKeys.forEach((offerKey) => {
        updates[`/offer/${offerKey}/offerGroupKey`] = dealGroupKey;
      });

    return firebase.database.ref().update(updates);
  }

  function pushDealGroupData(dealGroupKey, dealGroupData, imageUrls) {
    const dealGroupRef = firebase.database.ref(`/offerGroup/${dealGroupKey}`);
    const brandDealGroupRef = firebase.database.ref(
      `/brand/${brandKey}/offerGroups/${dealGroupKey}`
    );

    updateDealsWithDealGroupData(dealGroupKey, dealGroupData).then(() => {
      const dataSet = {
        description: dealGroupData.description,
        exclusive: dealGroupData.exclusive,
        brand: {
          brandKey,
          name: brand.name,
          logo: brand.logo,
          teamKey: brand.teamKey,
        },
        offerKeys: dealGroupData.offerKeys,
        updatedAt: firebase.databaseServerValue.TIMESTAMP,
      };

      if (imageUrls.pictureUrl) {
        dataSet.picture = imageUrls.pictureUrl;
      }

      const promises = [];

      promises.push(
        brandDealGroupRef.set(
          dealGroupData.description || "(default description)"
        )
      );
      promises.push(dealGroupRef.update(dataSet));

      Promise.all(promises).then(() => {
        history.push(`/portal/brands/${brandKey}`);
      });
    });
  }

  function handleSubmit(dealGroupData) {
    var dealGroupKey = match.params.deal_group_id;
    uploadResourceImages(
      firebase,
      dealGroupKey,
      dealGroupData,
      "images/offerGroups",
      ["picture"],
      pushDealGroupData
    );
  }

  function loadDealGroup() {
    const dealGroupKey = match.params.deal_group_id;
    dbLoadOfferGroup(firebase, dealGroupKey, (result) => {
      setDealGroup(result);
      setLoadingDealGroup(false);
    });
  }

  function loadBrand() {
    const brandKey = match.params.brand_id;

    dbLoadBrand(firebase, brandKey, (result) => {
      setBrand(result);
      setLoadingBrand(false);
    });
  }

  useEffect(loadDealGroup, []);
  useEffect(loadBrand, []);

  return (
    <div>
      {!loadingDealGroup && !loadingBrand && (
        <>
          <h1>Edit Deal Group</h1>
          <DealGroupForm
            brandKey={brandKey}
            existingDealGroup={dealGroup}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  );
}

export default withFirebase(Edit);
