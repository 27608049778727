import ZipCodes from "zipcodes";
import moment from "moment";
import { sortObjectByValue } from "./core";
import cloudFunctionHost from "../containers/CloudFunctionsHost";

const getLocation = (zip) => {
  if (zip && /[a-z]/.test(zip)) {
    zip = zip.toUpperCase();
  }
  const zipcodeData = ZipCodes.lookup(zip);
  let location = "";
  if (zipcodeData) {
    const city = zipcodeData.city.split(" (")[0];
    location = {
      city: city + ", " + zipcodeData.state,
      state: zipcodeData.state,
      country: zipcodeData.country,
      latitude: zipcodeData.latitude,
      longitude: zipcodeData.longitude,
    };
  } else {
    location = {
      city: "Unknown",
      state: "Unknown",
      country: "Unknown",
      latitude: null,
      longitude: null,
    };
  }
  return location;
};

const getDateArray = (selectedFilter, days) => {
  let startDate;
  let endDate;

  const dateArray = [];

  if (selectedFilter.includes("days")) {
    const numberOfDaysInFilter = parseInt(selectedFilter.match(/\d+/g)[0]);
    const adjustedDays = days || 0;
    startDate = moment().subtract(numberOfDaysInFilter + adjustedDays, "days");
    endDate = moment().subtract(adjustedDays, "days");

    for (
      let i = adjustedDays + numberOfDaysInFilter - 1;
      i >= adjustedDays;
      i--
    ) {
      dateArray.push(moment().add(-i, "days").format("YYYY-MM-DD"));
    }
  } else if (selectedFilter === "ThisYear") {
    startDate = moment().startOf("year").format("YYYY-MM-DD");
    endDate = moment();
    for (let i = 11; i >= 0; i--) {
      dateArray.push(moment().subtract(i, "months").format("MM"));
    }
    dateArray.sort((a, b) => moment(a).format("MM") - moment(b).format("MM"));
  } else if (selectedFilter === "LastYear") {
    startDate = moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD");

    endDate = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");

    for (let i = 11; i >= 0; i--) {
      dateArray.push(
        moment().subtract(1, "year").subtract(i, "months").format("MM")
      );
    }

    dateArray.sort((a, b) => moment(a).format("MM") - moment(b).format("MM"));
  } else if (selectedFilter === "Custom") {
    // create in the future
  }

  return { startDate, endDate, dateArray };
};

const getImpressionsChartData = (
  impressions,
  selectedFilter,
  adjustedDaysFilter
) => {
  let impressionsData = [];

  const dateObj = {};

  const { startDate, endDate, dateArray } = getDateArray(
    selectedFilter,
    adjustedDaysFilter
  );

  if (!impressions || impressions.length < 1)
    return {
      label: "Impressions",
      data: Array(dateArray.length).fill(0),
      color: "#00083e",
    };

  impressions
    .filter((impression) => {
      const date = moment(impression.viewedAt);
      if (selectedFilter === "ThisYear" || selectedFilter === "LastYear") {
        if (date >= moment(startDate) && date <= moment(endDate)) {
          return impression;
        }
      } else {
        if (date >= startDate && date <= endDate) {
          return impression;
        }
      }

      return null;
    })
    .forEach((impression) => {
      let dateKey;
      if (selectedFilter === "ThisYear" || selectedFilter === "LastYear") {
        dateKey = moment(impression.viewedAt).format("MM");
      } else {
        dateKey = moment(impression.viewedAt).format("YYYY-MM-DD");
      }
      if (dateObj[dateKey]) {
        dateObj[dateKey] += 1;
      } else {
        dateObj[dateKey] = 1;
      }
    });

  const sortedDateObj = Object.entries(dateObj)
    .sort(([a], [b]) => moment(a).diff(moment(b)))
    .reduce((sortedObj, [k, v]) => ({ ...sortedObj, [k]: v }), {});

  if (sortedDateObj) {
    dateArray.forEach((date, i) => {
      if (Object.keys(sortedDateObj).includes(date)) {
        impressionsData.push(sortedDateObj[date]);
      } else {
        impressionsData.push(0);
      }
    });
  }

  return {
    label: "Impressions",
    data: impressionsData,
    color: "#00083e",
  };
};

const getRedemptionChartData = (
  redemptions,
  selectedFilter,
  adjustedDaysFilter,
  dealType
) => {
  const publicDealsData = {
    label: "Public Deals",
    data: [],
    color: "#00083e",
  };
  const triggeredDealsData = {
    label: "Triggered Deals",
    data: [],
    color: "#464646",
  };
  const flashDealsData = {
    label: "Flash Deals",
    data: [],
    color: "#BA0909",
  };

  const exclusiveDealsData = {
    label: "Exclusive Deals",
    data: [],
    color: "#35b729",
  };

  const contestDealsData = {
    label: "Contests",
    data: [],
    color: "#a34d4d",
  };

  const hiddenDealsData = {
    label: "Special Deals",
    data: [],
    color: "#f5a623",
  };

  let redemptionsChartData = [];
  let publicDealsDataArray = [];
  let triggeredDealsDataArray = [];
  let flashDealsDataArray = [];
  let exclusiveDealsDataArray = [];
  let contestDealsDataArray = [];
  let hiddenDealsDataArray = [];

  const dateObj = {};

  const { startDate, endDate, dateArray } = getDateArray(
    selectedFilter,
    adjustedDaysFilter
  );
  if (redemptions.length < 1 || !redemptions) {
    publicDealsData.data = Array(dateArray.length).fill(0);
    triggeredDealsData.data = Array(dateArray.length).fill(0);
    flashDealsData.data = Array(dateArray.length).fill(0);
    exclusiveDealsData.data = Array(dateArray.length).fill(0);
    contestDealsData.data = Array(dateArray.length).fill(0);
    hiddenDealsData.data = Array(dateArray.length).fill(0);
    redemptionsChartData = [
      publicDealsData,
      triggeredDealsData,
      flashDealsData,
      exclusiveDealsData,
      contestDealsData,
      hiddenDealsData,
    ];
    return redemptionsChartData;
  }

  redemptions
    .filter((redemption) => {
      if (!redemption.time) return null;
      const date = moment(redemption.time.slice(0, 10));
      if (selectedFilter === "ThisYear" || selectedFilter === "LastYear") {
        if (date >= moment(startDate) && date <= moment(endDate)) {
          return redemption;
        }
      } else {
        if (date >= startDate && date <= endDate) {
          return redemption;
        }
      }
    })
    .forEach((redemption) => {
      let dateKey;
      if (selectedFilter === "ThisYear" || selectedFilter === "LastYear") {
        dateKey = moment(redemption.time).format("MM");
      } else {
        dateKey = redemption.time.slice(0, 10);
      }
      if (
        redemption.offer.triggered &&
        !redemption.offer.contestDeal &&
        !redemption.offer.hiddenDeal
      ) {
        if (dateObj[dateKey]) {
          dateObj[dateKey].triggeredDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 0,
            triggeredDeals: 1,
            flashDeals: 0,
            exclusiveDeals: 0,
            contestDeals: 0,
            hiddenDeals: 0,
          };
        }
      } else if (redemption.offer.flashDeal) {
        if (dateObj[dateKey]) {
          dateObj[dateKey].flashDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 0,
            triggeredDeals: 0,
            flashDeals: 1,
            exclusiveDeals: 0,
            contestDeals: 0,
            hiddenDeals: 0,
          };
        }
      } else if (redemption.offer.exclusive) {
        if (dateObj[dateKey]) {
          dateObj[dateKey].exclusiveDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 0,
            triggeredDeals: 0,
            flashDeals: 0,
            exclusiveDeals: 1,
            contestDeals: 0,
            hiddenDeals: 0,
          };
        }
      } else if (redemption.offer.contestDeal) {
        if (dateObj[dateKey]) {
          dateObj[dateKey].contestDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 0,
            triggeredDeals: 0,
            flashDeals: 0,
            exclusiveDeals: 0,
            contestDeals: 1,
            hiddenDeals: 0,
          };
        }
      } else if (redemption.offer.hiddenDeal) {
        if (dateObj[dateKey]) {
          dateObj[dateKey].hiddenDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 0,
            triggeredDeals: 0,
            flashDeals: 0,
            exclusiveDeals: 0,
            contestDeals: 0,
            hiddenDeals: 1,
          };
        }
      } else {
        if (dateObj[dateKey]) {
          dateObj[dateKey].publicDeals += 1;
        } else {
          dateObj[dateKey] = {
            publicDeals: 1,
            triggeredDeals: 0,
            flashDeals: 0,
            exclusiveDeals: 0,
            contestDeals: 0,
            hiddenDeals: 0,
          };
        }
      }
    });

  const sortedDateObj = Object.entries(dateObj)
    .sort(([a], [b]) => moment(a).diff(moment(b)))
    .reduce((sortedObj, [k, v]) => ({ ...sortedObj, [k]: v }), {});

  if (sortedDateObj) {
    dateArray.forEach((date, i) => {
      if (Object.keys(sortedDateObj).includes(date)) {
        publicDealsDataArray.push(sortedDateObj[date].publicDeals);
        triggeredDealsDataArray.push(sortedDateObj[date].triggeredDeals);
        flashDealsDataArray.push(sortedDateObj[date].flashDeals);
        exclusiveDealsDataArray.push(sortedDateObj[date].exclusiveDeals);
        contestDealsDataArray.push(sortedDateObj[date].contestDeals);
        hiddenDealsDataArray.push(sortedDateObj[date].hiddenDeals);
      } else {
        publicDealsDataArray.push(0);
        triggeredDealsDataArray.push(0);
        flashDealsDataArray.push(0);
        exclusiveDealsDataArray.push(0);
        contestDealsDataArray.push(0);
        hiddenDealsDataArray.push(0);
      }
    });

    publicDealsData.data = publicDealsDataArray;
    triggeredDealsData.data = triggeredDealsDataArray;
    flashDealsData.data = flashDealsDataArray;
    exclusiveDealsData.data = exclusiveDealsDataArray;
    contestDealsData.data = contestDealsDataArray;
    hiddenDealsData.data = hiddenDealsDataArray;

    if (!dealType) {
      redemptionsChartData = [
        publicDealsData,
        triggeredDealsData,
        flashDealsData,
        exclusiveDealsData,
        contestDealsData,
        hiddenDealsData,
      ];
    } else {
      if (dealType === "public") {
        redemptionsChartData = [publicDealsData];
      }
      if (dealType === "triggered") {
        redemptionsChartData = [triggeredDealsData];
      }
      if (dealType === "flashDeal") {
        redemptionsChartData = [flashDealsData];
      }
      if (dealType === "exclusive") {
        redemptionsChartData = [exclusiveDealsData];
      }
      if (dealType === "contestDeal") {
        redemptionsChartData = [contestDealsData];
      } else if (dealType === "hiddenDeal") {
        redemptionsChartData = [hiddenDealsData];
      }
    }

    return redemptionsChartData;
  }
};

const sortOffersByStatus = (offers, status) => {
  offers.sort((a, b) => {
    if (a[status] && !b[status]) {
      return -1;
    } else if (!a[status] && b[status]) {
      return 1;
    } else {
      return 0;
    }
  });

  return offers;
};

const sortOffersByDate = (offers, direction) => {
  const sortedOffers = [];
  const sortedOffersByPublished = sortOffersByStatus(
    offers,
    "published"
  ).filter((offer) => offer.published === true);
  const sortedOffersByNotPublished = sortOffersByStatus(
    offers,
    "published"
  ).filter((offer) => !offer.published);

  sortedOffersByPublished
    .sort((a, b) => {
      if (direction === "asc") {
        if (a.publishedAt && b.publishedAt) {
          return moment(a.publishedAt).diff(moment(b.publishedAt));
        } else if (a.publishedAt && !b.publishedAt) {
          return 1;
        } else if (!a.publishedAt && b.publishedAt) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a.publishedAt && b.publishedAt) {
          return moment(b.publishedAt).diff(moment(a.publishedAt));
        } else if (a.publishedAt && !b.publishedAt) {
          return -1;
        } else if (!a.publishedAt && b.publishedAt) {
          return 1;
        } else {
          return 0;
        }
      }
    })
    .sort((a, b) => {
      if (direction === "asc") {
        if (a.activatedAt && b.activatedAt) {
          return moment(a.activatedAt).diff(moment(b.activatedAt));
        } else if (a.activatedAt && !b.activatedAt) {
          return 1;
        } else if (!a.activatedAt && b.activatedAt) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a.activatedAt && b.activatedAt) {
          return moment(b.activatedAt).diff(moment(a.activatedAt));
        } else if (a.activatedAt && !b.activatedAt) {
          return -1;
        } else if (!a.activatedAt && b.activatedAt) {
          return 1;
        } else {
          return 0;
        }
      }
    });

  sortedOffersByNotPublished.sort((a, b) => {
    if (direction === "asc") {
      if (a.archivedAt && b.archivedAt) {
        return moment(a.archivedAt).diff(moment(b.archivedAt));
      } else if (a.archivedAt && !b.archivedAt) {
        return 1;
      } else if (!a.archivedAt && b.archivedAt) {
        return -1;
      } else {
        return 0;
      }
    } else {
      if (a.archivedAt && b.archivedAt) {
        return moment(b.archivedAt).diff(moment(a.archivedAt));
      } else if (a.archivedAt && !b.archivedAt) {
        return -1;
      } else if (!a.archivedAt && b.archivedAt) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  sortedOffers.push(...sortedOffersByPublished, ...sortedOffersByNotPublished);
  return sortedOffers;
};

const calculateAge = (birthDate) => {
  const dob = new Date(birthDate);
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  const age = Math.abs(age_dt.getUTCFullYear() - 1970);

  if (isNaN(age)) {
    return "N/A";
  }

  return age;
};

const mapUserDataforCharts = (transactions) => {
  if (!transactions) return;

  let maleCount = 0;
  let femaleCount = 0;
  let nonBinaryCount = 0;
  let otherCount = 0;
  let ageData = [0, 0, 0, 0, 0, 0];
  let userLocationMap = {};
  let userStateMap = {};

  transactions.forEach((transaction) => {
    let { userAge, userCity, user, userLatitude, userLongitude } = transaction;

    // calculate gender
    if (user) {
      if (user.gender && user.gender.toLowerCase() === "female") femaleCount++;
      else if (user.gender && user.gender.toLowerCase() === "male") maleCount++;
      else if (user.gender && user.gender.toLowerCase() === "non-binary")
        nonBinaryCount++;
      else otherCount++;
    }

    // calculate age

    if (userAge <= 19 && userAge >= 15) ageData[0]++;
    else if (20 <= userAge && userAge <= 29) ageData[1]++;
    else if (30 <= userAge && userAge <= 39) ageData[2]++;
    else if (40 <= userAge && userAge <= 49) ageData[3]++;
    else if (50 <= userAge && userAge <= 59) ageData[4]++;
    else ageData[5]++;

    // calculate location
    if (userCity && userCity.toLowerCase() !== "unknown") {
      if (userLocationMap[userCity]) {
        userLocationMap[userCity]++;
      } else {
        userLocationMap[userCity] = 1;
      }

      if (!userLatitude || !userLongitude) {
        userLatitude = 0;
        userLongitude = 0;
      }

      if (userStateMap[userLatitude + "," + userLongitude]) {
        userStateMap[userLatitude + "," + userLongitude]++;
      } else {
        userStateMap[userLatitude + "," + userLongitude] = 1;
      }
    }
  });

  const genderData = [femaleCount, maleCount, nonBinaryCount, otherCount];

  userLocationMap = sortObjectByValue(userLocationMap, "asc");

  return {
    genderData,
    ageData,
    userLocationMap,
    userStateMap,
  };
};

const mapSponsorRedemptions = (sponsor, users, masterListOfOffers) => {
  let selectedOffers = sponsor.offers;
  let redemptions = [];

  users.forEach((user) => {
    let userRedeemKeys = [];
    let userPurchaseOfferKeys = [];
    let userPurchaseOfferCreatedAtMap = {};

    // Prepare redeemed offer keys
    if (user.redeemed) {
      userRedeemKeys = Object.keys(user.redeemed);
    }

    // Prepare purchase offer keys
    if (user.purchases) {
      for (let userPurchaseKey in user.purchases) {
        const offerKey = user.purchases[userPurchaseKey].offerKey;
        const createdAt = user.purchases[userPurchaseKey].createdAt;
        userPurchaseOfferKeys.push(offerKey);
        userPurchaseOfferCreatedAtMap[offerKey] = createdAt;
      }
    }

    if (userRedeemKeys.length > 0 || userPurchaseOfferKeys.length > 0) {
      if (selectedOffers && selectedOffers.length > 0) {
        selectedOffers.forEach((selectedOffer) => {
          let redemption;
          let offer;
          let numberOfTimes = 0;
          const userAge = calculateAge(user.birthDate || user.dob);
          const { city, state, country, latitude, longitude } = getLocation(
            user.zip
          );

          if (userRedeemKeys.includes(selectedOffer.key)) {
            numberOfTimes = 1;

            offer = masterListOfOffers[selectedOffer.key];

            redemption = {
              offerID: offer.key,
              offer: offer,
              user,
              time: user.redeemed[selectedOffer.key],
              userAge,
              userCity: city,
              userState: state,
              userCountry: country,
              userLatitude: latitude,
              userLongitude: longitude,
              transactionType: "redemption",
            };
          }

          // Check if offer was purchased
          if (userPurchaseOfferKeys.includes(selectedOffer.key)) {
            const filteredUserPurchaseOfferKeys = userPurchaseOfferKeys.filter(
              (offerKey) => offerKey === selectedOffer.key
            );
            numberOfTimes = filteredUserPurchaseOfferKeys.length;

            offer = masterListOfOffers[selectedOffer.key];

            redemption = {
              offerID: offer.key,
              offer: offer,
              user,
              time: userPurchaseOfferCreatedAtMap[selectedOffer.key],
              userAge,
              userCity: city,
              userState: state,
              userCountry: country,
              userLatitude: latitude,
              userLongitude: longitude,
              transactionType: "purchase",
            };
          }

          // Log each transaction
          for (let i = 0; i < numberOfTimes; i++) {
            redemptions.push(redemption);
          }
        });
      }
    }
  });

  // Sort transactions
  redemptions = redemptions.sort((a, b) =>
    a.time < b.time ? 1 : b.time < a.time ? -1 : 0
  );

  return redemptions;
};

const mapRedemptionsFromEvents = (events, offers, users) => {
  let redemptions = [];

  events &&
    events.forEach((event) => {
      const { resourceKey, metaData, userKey } = event;
      const { redeemed, teamKey } = metaData;
      if (offers && offers.length > 0) {
        offers.forEach((offer) => {
          let redemption;

          if (offer.key === resourceKey) {
            const user = users.find((user) => user.key === userKey);
            let userAge,
              userCity,
              userState,
              userCountry,
              userLatitude,
              userLongitude;
            if (user) {
              userAge = calculateAge(user.birthDate || user.dob);
              const { city, state, country, latitude, longitude } = getLocation(
                user.zip
              );
              userCity = city;
              userState = state;
              userCountry = country;
              userLatitude = latitude;
              userLongitude = longitude;
            }

            redemption = {
              offerID: offer.key,
              sponsorID: offer.sponsor ? offer.sponsor.sponsorKey : null,
              sponsorName: offer.sponsor ? offer.sponsor.name : null,
              offer: {
                description: offer.description,
                triggered: offer.triggered,
                flashDeal: offer.flashDeal,
                exclusive: offer.exclusive,
                hiddenDeal: offer.hiddenDeal,
              },
              user: {
                gender: user.gender,
                email: user.email,
                createdAt: user.createdAt,
              },
              time: redeemed,
              userAge,
              userCity: userCity,
              userState: userState,
              userCountry: userCountry,
              userLatitude: userLatitude,
              userLongitude: userLongitude,
              transactionType: "redemption",
              teamID: teamKey,
            };
          }

          if (redemption) {
            redemptions.push(redemption);
          }
        });
      }
    });

  redemptions = redemptions.sort((a, b) =>
    a.time < b.time ? 1 : b.time < a.time ? -1 : 0
  );

  return redemptions;
};

const mapTeamRedemptions = (teamOffers, users) => {
  let redemptions = [];

  users.forEach((user) => {
    let userRedeemKeys = [];
    let userPurchaseOfferKeys = [];
    let userPurchaseOfferCreatedAtMap = {};

    // Prepare redeemed offer keys
    if (user.redeemed) {
      userRedeemKeys = Object.keys(user.redeemed);
    }

    // Prepare purchase offer keys
    if (user.purchases) {
      for (let userPurchaseKey in user.purchases) {
        const offerKey = user.purchases[userPurchaseKey].offerKey;
        const createdAt = user.purchases[userPurchaseKey].createdAt;
        userPurchaseOfferKeys.push(offerKey);
        userPurchaseOfferCreatedAtMap[offerKey] = createdAt;
      }
    }

    if (userRedeemKeys.length > 0 || userPurchaseOfferKeys.length > 0) {
      if (teamOffers && teamOffers.length > 0) {
        teamOffers.forEach((offer) => {
          let transaction;
          let numberOfTimes = 0;
          const userAge = calculateAge(user.birthDate || user.dob);
          const { city, state, country, latitude, longitude } = getLocation(
            user.zip
          );

          if (userRedeemKeys.includes(offer.key)) {
            numberOfTimes = 1;

            transaction = {
              offerID: offer.key,
              offer: offer,
              user,
              time: user.redeemed[offer.key],
              userAge,
              userCity: city,
              userState: state,
              userCountry: country,
              userLatitude: latitude,
              userLongitude: longitude,
              transactionType: "redemption",
            };

            if (userPurchaseOfferKeys.includes(offer.key)) {
              const filteredUserPurchaseOfferKeys =
                userPurchaseOfferKeys.filter(
                  (offerKey) => offerKey === offer.key
                );
              numberOfTimes = filteredUserPurchaseOfferKeys.length;

              transaction = {
                offerID: offer.key,
                offer: offer,
                user,
                time: userPurchaseOfferCreatedAtMap[offer.key],
                userAge,
                userCity: city,
                userState: state,
                userCountry: country,
                userLatitude: latitude,
                userLongitude: longitude,
                transactionType: "purchase",
              };
            }

            for (let i = 0; i < numberOfTimes; i++) {
              redemptions.push(transaction);
            }
          }
        });
      }
    }
  });

  redemptions = redemptions.sort((a, b) =>
    a.time < b.time ? 1 : b.time < a.time ? -1 : 0
  );

  return redemptions;
};

const toggleDealStatus = (offer, statusField, firebase, onSuccess, onError) => {
  const sponsorKey = offer.sponsor && offer.sponsor.sponsorKey;

  const promises = [];

  // Update offer status
  const offerRef = firebase.database.ref(`offer/${offer.key}`);
  const offerUpdate = {
    [statusField]: !offer[statusField],
  };
  if (statusField === "published") {
    if (!offer.triggered) {
      offerUpdate.active = !offer[statusField];
    } else {
      offerUpdate.active = false;
    }
  }
  promises.push(offerRef.update(offerUpdate));

  // Update sponsor offer status

  if (sponsorKey) {
    const sponsorOfferRef = firebase.database.ref(
      `sponsor/${sponsorKey}/offers`
    );

    sponsorOfferRef.once("value").then((snapshot) => {
      const sponsorOffers = snapshot.val();
      const sponsorOfferToUpdate = sponsorOffers.find(
        (sponsorOffer) => sponsorOffer.key === offer.key
      );
      sponsorOfferToUpdate[statusField] = !offer[statusField];
      if (statusField === "published") {
        if (!offer.triggered) {
          sponsorOfferToUpdate.active = !offer[statusField];
        } else {
          sponsorOfferToUpdate.active = false;
        }
      }
      promises.push(sponsorOfferRef.set(sponsorOffers));
    });
  }

  if (promises.length > 0) {
    Promise.all(promises)
      .then(() => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  }
};

const deactivateExclusiveCodeForUser = (
  firebase,
  teamKey,
  userKey,
  exclusiveCode,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      userKey,
      exclusiveCode,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/deactivateExclusiveCodeForUser`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};

const deactivateSpecialCodeForUser = (
  firebase,
  teamKey,
  userKey,
  specialCode,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      userKey,
      specialCode,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/deactivateSpecialCodeForUser`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};

const deactivateAllCodesForTeam = (
  firebase,
  teamKey,
  codes,
  deactivateAll,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      codes,
      deactivateAll,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/deactivateAllCodesForTeam`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};

const deleteAllCodesForTeam = (
  firebase,
  teamKey,
  codes,
  deleteAll,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      codes,
      deleteAll,
    };

    fetch(`${cloudFunctionHost}/authorizedFunctions/deleteAllCodesForTeam`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};

const deactivateAllSpecialCodesForTeam = (
  firebase,
  teamKey,
  codes,
  deactivateAll,
  accessLevel,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      codes,
      deactivateAll,
      accessLevel,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/deactivateAllSpecialCodesForTeam`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};
const deleteAllSpecialCodesForTeam = (
  firebase,
  teamKey,
  codes,
  deleteAll,
  accessLevel,
  onSuccess,
  onError
) => {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      teamKey,
      codes,
      deleteAll,
      accessLevel,
    };

    fetch(
      `${cloudFunctionHost}/authorizedFunctions/deleteAllSpecialCodesForTeam`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },

        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(responseJson);
        }
      })
      .catch((error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      });
  });
};

const handleFirebaseUpdate = (
  firebase,
  updates,
  successMessage,
  errorMessage,
  callback
) => {
  firebase.database
    .ref()
    .update(updates)
    .then(() => {
      window.alert(successMessage);
      callback && typeof callback === "function" && callback();
    })
    .catch((error) => {
      console.error(error);
      window.alert(errorMessage);
    });
};

const calculateImpressionsForTeams = (sponsors, offers, teams) => {
  if (!sponsors || !offers || !teams) return;

  const sponsorMap = sponsors.reduce((acc, sponsor) => {
    acc[sponsor.key] = sponsor;
    return acc;
  }, {});

  const offerMap = offers.reduce((acc, offer) => {
    acc[offer.key] = offer;
    return acc;
  }, {});

  const teamImpressions = teams.reduce((acc, team) => {
    const { key } = team;
    const teamSponsorsKeys = team.sponsors || [];

    let totalImpressions = 0;

    teamSponsorsKeys.forEach((sponsorKey) => {
      const sponsor = sponsorMap[sponsorKey];
      if (sponsor && sponsor.offers) {
        sponsor.offers.forEach((offer) => {
          const offerData = offerMap[offer.key];
          if (offerData && offerData.impressions) {
            totalImpressions += Object.keys(offerData.impressions).length;
          }
        });
      }
    });

    if (totalImpressions > 0) {
      acc.push({
        teamKey: key,
        impressions: totalImpressions,
      });
    }

    return acc;
  }, []);

  return teamImpressions;
};

const encodeKey = (str) => {
  return str
    .replace(/\./g, "-DOT-")
    .replace(/@/g, "-AT-")
    .replace(/#/g, "-HSH-")
    .replace(/\$/g, "-DLR-")
    .replace(/\//g, "-SLH-")
    .replace(/\[/g, "-OB-")
    .replace(/\]/g, "-CB-");
};

const decodeKey = (encodedStr) => {
  return encodedStr
    .replace(/-DOT-/g, ".")
    .replace(/-AT-/g, "@")
    .replace(/-HSH-/g, "#")
    .replace(/-DLR-/g, "$")
    .replace(/-SLH-/g, "/")
    .replace(/-OB-/g, "[")
    .replace(/-CB-/g, "]");
};

export {
  getLocation,
  getRedemptionChartData,
  getImpressionsChartData,
  getDateArray,
  sortOffersByStatus,
  sortOffersByDate,
  calculateAge,
  mapUserDataforCharts,
  mapSponsorRedemptions,
  mapTeamRedemptions,
  toggleDealStatus,
  mapRedemptionsFromEvents,
  deactivateExclusiveCodeForUser,
  deactivateAllCodesForTeam,
  deleteAllCodesForTeam,
  deactivateAllSpecialCodesForTeam,
  deleteAllSpecialCodesForTeam,
  deactivateSpecialCodeForUser,
  handleFirebaseUpdate,
  calculateImpressionsForTeams,
  encodeKey,
  decodeKey,
};
