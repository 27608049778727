import React from "react";
import OrganizationListing from "./OrganizationListing";

class OrganizationListingHitWrapper extends React.Component {
  render() {
    const { name, picture, logo, fans, slug, sponsors, objectID, isBrand } =
      this.props.hit;

    return (
      <OrganizationListing
        size="small"
        organization={{
          picture,
          name,
          logo,
          fans,
          slug,
          key: objectID,
          sponsors,
          isBrand,
        }}
      />
    );
  }
}

export default OrganizationListingHitWrapper;
