import React from "react";
import PropTypes from "prop-types";
import "./Modal.scss";
import { Container, Row, Col } from "reactstrap";

const ModalContainer = ({ onClose, onProceed, children, backgroundColor }) => {
  return (
    <div className="component-modal-container">
      <Container className="close-modal-container">
        <Row className="justify-content-center">
          <Col className="close-modal-container">
            <div className="close-modal-icon-container">
              <div
                className={`icon-close ${
                  backgroundColor === "blue" ? "blue" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="main-content-container">
        <Row className="justify-content-center">
          <Col>
            <div
              className={`main-content ${
                backgroundColor === "blue" ? "blue" : ""
              }`}
            >
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ModalContainer.propTypes = {
  onClose: PropTypes.func,
  onProceed: PropTypes.func,
  children: PropTypes.node,
};

export default ModalContainer;
