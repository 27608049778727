import React from "react";
import { connectStateResults } from "react-instantsearch-dom";

const TeamsResultsWithEmptyState = connectStateResults(
  ({ searchState, searchResults, children, message }) => {
    if (searchResults && searchResults.nbHits !== 0) {
      const brandKeys = searchResults.hits
        .filter((hit) => hit.isBrand === true)
        .map((brand) => brand.objectID);

      if (brandKeys.length === 0) {
        return children;
      }

      const filteredHits = searchResults.hits.filter(
        (hit) =>
          hit.name &&
          (hit.isBrand === true ||
            !hit.belongsToBrandKey ||
            !brandKeys.includes(hit.belongsToBrandKey))
      );

      //sort by name alphabetically and handle if no name

      const sortedHits = filteredHits.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else if (a.name) {
          return -1;
        } else if (b.name) {
          return 1;
        } else {
          return 0;
        }
      });
      searchResults.hits = sortedHits;

      return children;
    }

    let resultingMessage;

    if (message) {
      resultingMessage = message;
    } else {
      /* Used for Organization Show page DealsTab */
      if (!searchState.query || searchState.query.trim() === "") {
        const exclusive =
          searchState.refinementList &&
          searchState.refinementList.exclusive === "true";
        resultingMessage = `There are no ${
          exclusive ? "Exclusive" : "Public"
        } Deals available for this organization just yet. Check back soon!`;
      } else {
        resultingMessage = `No results were found for '${searchState.query}'.`;
      }
    }

    return (
      <div className="component-results-with-empty-state empty-state">
        {resultingMessage}
      </div>
    );
  }
);

export default TeamsResultsWithEmptyState;
