import React from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";

import {
  DashboardButton,
  FlexContainer,
  SpinLoader,
} from "../../assets/fansaves-ui";

import "../../scss/base/typography.scss";

import DashboardBusinessCard from "../DashboardBusinessCard";

const DashboardBusinessesTitle = styled(FlexContainer)`
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "image title redemption view";
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
  margin: 0 auto 20px auto;
  .businesses-list-businesses-count {
    grid-area: image;
  }
  .businesses-list-redemption-count {
    grid-area: redemption;
  }
  .view-all-button {
    align-self: flex-end;
  }
`;

const DashboardBusinessesList = ({
  teamId,
  businesses,
  redemptions,
  viewAll,
  limit,
  history,
}) => {
  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const filterRedemptionsAndCount = (business) => {
    if (!redemptions) {
      return 0;
    }

    const filteredRedemptions = redemptions.filter((redemption) => {
      return (
        redemption.sponsorID === business.key &&
        redemption.transactionType === "redemption"
      );
    });

    return filteredRedemptions ? filteredRedemptions.length : 0;
  };

  const filteredContestEntriesAndCount = (business) => {
    if (!redemptions) {
      return 0;
    }
    const filteredContestEntries = redemptions.filter((redemption) => {
      return (
        redemption.sponsorID === business.key &&
        redemption.transactionType === "contest"
      );
    });

    return filteredContestEntries ? filteredContestEntries.length : 0;
  };

  const limitBusinessesIfNecessary = (businesses) => {
    if (limit) {
      return businesses.slice(0, limit);
    } else {
      return businesses;
    }
  };

  return (
    <div className="dashboard-businesses-list">
      <DashboardBusinessesTitle
        direction="horizontal"
        justifyContent="space-between"
      >
        <div className="businesses-list-businesses-count standard-b1">
          Businesses{" "}
          {businesses && businesses.length > 0 ? businesses.length : 0}
        </div>
        <div className="businesses-list-redemption-count standard-b1">
          Redempions{" "}
          {redemptions
            ? redemptions.length > 0
              ? redemptions.length
              : 0
            : renderLoading()}
        </div>
      </DashboardBusinessesTitle>
      <FlexContainer direction="vertical" gap="20px">
        {businesses && businesses.length > 0 ? (
          limitBusinessesIfNecessary(businesses).map((business) => (
            <DashboardBusinessCard
              key={business.key}
              business={business}
              redemptionsCount={filterRedemptionsAndCount(business)}
              contestEntriesCount={filteredContestEntriesAndCount(business)}
            />
          ))
        ) : (
          <div className="no-businesses">No businesses found.</div>
        )}
      </FlexContainer>
      {businesses && businesses.length > limit && viewAll && (
        <FlexContainer
          alignItems="flex-end"
          className="view-all"
          margin="10px auto 0 auto"
        >
          <DashboardButton
            title="View All"
            className="view-all-button"
            size="small"
            type="shadow-white"
            typography="public-h2"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portal/teams/${teamId}/sponsors`);
            }}
          />
        </FlexContainer>
      )}
    </div>
  );
};

export default withRouter(DashboardBusinessesList);

DashboardBusinessesList.propTypes = {
  teamId: propTypes.string,
  businesses: propTypes.array,
  redemptions: propTypes.array,
  viewAll: propTypes.bool,
  limit: propTypes.number,
};
