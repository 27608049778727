/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../containers/Firebase";
import styled from "styled-components/macro";
import { withAuthentication } from "../../../containers/Session";
import { Icon } from "@iconify/react";
import "../../../scss/base/typography.scss";
import { Link } from "react-router-dom";
import {
  COLORS,
  FlexContainer,
  DashboardButton,
  LineChart,
  DashboardTitle,
  SpinLoader,
} from "../../../assets/fansaves-ui";

// stores
import useRedemptionsStore from "../../../stores/redemptionsStore";
import useUsersStore from "../../../stores/usersStore";
import useFollowEventsStore from "../../../stores/followEventsStore";
import useTeamsStore from "../../../stores/teamsStore";
import useSponsorsStore from "../../../stores/sponsorsStore";
import useOffersStore from "../../../stores/offersStore";

// components
import DashboardListCard from "../../../containers/DashboardListCard";
import DashboardRedemptionsTable from "../../../containers/DashboardRedemptionsTable";
import DashboardChartContainer from "../../../containers/DashboardChartContainer";
import DashboardRedemptionAnalytics from "../../../containers/DashboardRedemptionAnalytics";
import DashboardWebAnalytics from "../../../containers/DashboardWebAnalytics";
import AdminDashboardStats from "../../../containers/AdminDashboardStats";
import moment from "moment";

import {
  getRedemptionChartData,
  getImpressionsChartData,
  mapUserDataforCharts,
} from "../../../utilities/dashboard_helpers";

import "../../../scss/base/typography.scss";

const PoartalAdminDashboardWrapper = styled.div`
  .portal-admin-header {
    margin: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .portal-admin-header-title,
    .user-logo-icon,
    .user-profile-link {
      color: ${COLORS.primaryBlue};
    }
  }
  .portal-admin-header-container {
    position: relative;
    .dashboard-header-links {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

const PortalAdminDashboard = ({ firebase, history }) => {
  const fetchRedemptions = useRedemptionsStore(
    (state) => state.fetchRedemptionsByChunks
  );
  const redemptionsFromEvents = useRedemptionsStore(
    (state) => state.redemptions
  );
  const isRedemptionsLoading = useRedemptionsStore((state) => state.isLoading);

  const contestEntries = useRedemptionsStore((state) => state.contestEntries);
  const redemptionsWithoutContestEntries = useRedemptionsStore(
    (state) => state.redemptionsWithoutContestEntries
  );

  const followEvents = useFollowEventsStore((state) => state.followEvents);
  const fetchFollowEvents = useFollowEventsStore(
    (state) => state.fetchFollowEvents
  );

  const customers = useUsersStore((state) => state.customers);
  const fetchUsers = useUsersStore((state) => state.fetchUsers);

  const teams = useTeamsStore((state) => state.teams);
  const fetchTeams = useTeamsStore((state) => state.fetchTeams);

  const fetchSponsors = useSponsorsStore((state) => state.fetchSponsors);
  const businesses = useSponsorsStore((state) => state.sponsors);
  const offersArray = useOffersStore((state) => state.offersArray);
  const fetchOffers = useOffersStore((state) => state.fetchOffers);
  const setImpressions = useOffersStore((state) => state.setImpressions);
  const impressions = useOffersStore((state) => state.impressions);

  const [genderChartData, setGenderChartData] = useState(null);
  const [ageChartData, setAgeChartData] = useState(null);
  const [impressionsChartData, setImpressionsChartData] = useState(null);
  const [redemptionsChartData, setRedemptionsChartData] = useState(null);
  const [locationChartData, setLocationChartData] = useState(null);
  const [selectedLocationChartData, setSelectedLocationChartData] =
    useState(null);
  const [selectedHeaderFilter, setSelectedHeaderFilter] = useState(null);
  const [headerFilterAdjustment, setHeaderFilterAdjustment] = useState(0);
  const [selectedRedemtionFilter, setSelectedRedemtionFilter] =
    useState("7days");
  const [selectedWebFilter, setSelectedWebFilter] = useState("7days");
  const [webFilterDaysAdjustment, setWebFilterDaysAdjustment] = useState(0);
  const [redemptionsFilterDaysAdjustment, setRedemptionsFilterDaysAdjustment] =
    useState(0);
  const [heatMapData, setHeatMapData] = useState(null);

  const renderLoading = () => {
    return <SpinLoader hideTitle iconSize="20px" />;
  };

  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        margin="30px auto"
        height="400px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };
  useEffect(() => {
    fetchRedemptions(firebase);
  }, [fetchRedemptions]);

  useEffect(() => {
    fetchUsers(firebase);
  }, [fetchUsers]);

  useEffect(() => {
    fetchFollowEvents(firebase);
  }, [fetchFollowEvents]);

  useEffect(() => {
    fetchTeams(firebase);
  }, [fetchTeams]);

  useEffect(() => {
    fetchSponsors(firebase);
  }, [fetchSponsors]);

  useEffect(() => {
    fetchOffers(firebase);
  }, [fetchOffers]);

  useEffect(() => {
    if (teams) {
      setSelectedHeaderFilter("Life-Time");
    }
  }, [teams]);

  const getImpressions = () => {
    const impressions = [];
    if (offersArray) {
      offersArray.forEach((offer) => {
        if (offer.impressions) {
          impressions.push(Object.values(offer.impressions));
        }
      });
    }
    const flattenedImpressions = [].concat.apply([], impressions);
    setImpressions(flattenedImpressions);
  };

  useEffect(() => {
    if (offersArray) {
      getImpressions();
    }
  }, [offersArray]);

  const filterStats = (value, start, end) => {
    if (!value) return false;
    const dateIsValid = moment(value).isValid();
    if (!dateIsValid) return false;
    return moment(value).isBetween(start, end);
  };

  const getStats = () => {
    let tempStats = [
      { label: "Total Fans", value: 0, prevValue: 0 },
      { label: "Total Users", value: 0, prevValue: 0 },
      {
        label: "Total Redemptions",
        value: 0,
        prevValue: 0,
      },
      {
        label: "Total Contest Entries",
        value: 0,
        prevValue: 0,
      },
      { label: "Total Businesses", value: 0, prevValue: 0 },
    ];

    let totalFans = 0;
    let prevTotalFans = 0;

    let totalUsers = 0;
    let prevTotalUsers = 0;

    let totalRedemptions = 0;
    let prevTotalRedemptions = 0;

    let totalContestEntries = 0;
    let prevTotalContestEntries = 0;

    let totalBusinesses = 0;
    let prevTotalBusinesses = 0;

    let startOfThePeriod;
    let endOfThePeriod;
    let startOfTheNextPeriod;
    let endOfTheNextPeriod;

    switch (selectedHeaderFilter) {
      case "Life-Time":
        totalFans = followEvents ? followEvents.length : 0;
        totalUsers = customers ? customers.length : 0;
        totalRedemptions = redemptionsWithoutContestEntries
          ? redemptionsWithoutContestEntries.length
          : 0;
        totalContestEntries = contestEntries ? contestEntries.length : 0;
        totalBusinesses = businesses ? businesses.length : 0;
        break;
      case "Weekly":
        startOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "week")
          .startOf("week");
        endOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "week")
          .endOf("week");
        startOfTheNextPeriod = startOfThePeriod.clone().add(1, "week");
        endOfTheNextPeriod = endOfThePeriod.clone().add(1, "week");

        break;

      case "Monthly":
        startOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "month")
          .startOf("month");
        endOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "month")
          .endOf("month");
        startOfTheNextPeriod = startOfThePeriod.clone().add(1, "month");
        endOfTheNextPeriod = endOfThePeriod.clone().add(1, "month");

        break;

      case "Quartely":
        startOfThePeriod = moment()
          .quarter(moment().quarter() - headerFilterAdjustment)
          .startOf("quarter");
        endOfThePeriod = moment()
          .quarter(moment().quarter() - headerFilterAdjustment)
          .endOf("quarter");
        startOfTheNextPeriod = startOfThePeriod.clone().add(1, "quarter");
        endOfTheNextPeriod = endOfThePeriod.clone().add(1, "quarter");

        break;
      case "Annualy":
        startOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "year")
          .startOf("year");
        endOfThePeriod = moment()
          .subtract(headerFilterAdjustment, "year")
          .endOf("year");
        startOfTheNextPeriod = startOfThePeriod.clone().add(1, "year");
        endOfTheNextPeriod = endOfThePeriod.clone().add(1, "year");

        break;
      default:
        totalFans = followEvents
          ? followEvents.length > 0
            ? followEvents.length
            : 0
          : renderLoading();
        totalUsers = customers
          ? customers.length > 0
            ? customers.length
            : 0
          : renderLoading();
        totalRedemptions = redemptionsWithoutContestEntries
          ? redemptionsWithoutContestEntries.length > 0
            ? redemptionsWithoutContestEntries.length
            : 0
          : renderLoading();
        totalContestEntries = contestEntries
          ? contestEntries.length > 0
            ? contestEntries.length
            : 0
          : renderLoading();
        totalBusinesses = businesses
          ? businesses.length > 0
            ? businesses.length
            : 0
          : renderLoading();
        break;
    }

    if (
      startOfThePeriod &&
      endOfThePeriod &&
      startOfTheNextPeriod &&
      endOfTheNextPeriod
    ) {
      totalFans = followEvents
        ? followEvents.filter((event) => {
            return filterStats(
              event.createdAt,
              startOfThePeriod,
              endOfThePeriod
            );
          }).length
        : 0;
      prevTotalFans = followEvents
        ? followEvents.filter((event) => {
            return filterStats(
              event.createdAt,
              startOfTheNextPeriod,
              endOfTheNextPeriod
            );
          }).length
        : 0;

      totalUsers = customers
        ? customers.filter((customer) => {
            return filterStats(
              customer.createdAt,
              startOfThePeriod,
              endOfThePeriod
            );
          }).length
        : 0;
      prevTotalUsers = customers
        ? customers.filter((customer) => {
            return filterStats(
              customer.createdAt,
              startOfTheNextPeriod,
              endOfTheNextPeriod
            );
          }).length
        : 0;

      totalRedemptions = redemptionsWithoutContestEntries
        ? redemptionsWithoutContestEntries.filter((redemption) => {
            return filterStats(
              redemption.time,
              startOfThePeriod,
              endOfThePeriod
            );
          }).length
        : 0;

      prevTotalRedemptions = redemptionsWithoutContestEntries
        ? redemptionsWithoutContestEntries.filter((redemption) => {
            return filterStats(
              redemption.time,
              startOfTheNextPeriod,
              endOfTheNextPeriod
            );
          }).length
        : 0;

      totalContestEntries = contestEntries
        ? contestEntries.filter((contestEntry) => {
            return filterStats(
              contestEntry.time,
              startOfThePeriod,
              endOfThePeriod
            );
          }).length
        : 0;

      prevTotalContestEntries = contestEntries
        ? contestEntries.filter((contestEntry) => {
            return filterStats(
              contestEntry.time,
              startOfTheNextPeriod,
              endOfTheNextPeriod
            );
          }).length
        : 0;

      totalBusinesses = businesses
        ? businesses.filter((business) => {
            return filterStats(
              business.createdAt,
              startOfThePeriod,
              endOfThePeriod
            );
          }).length
        : 0;

      prevTotalBusinesses = businesses
        ? businesses.filter((business) => {
            return filterStats(
              business.createdAt,
              startOfTheNextPeriod,
              endOfTheNextPeriod
            );
          }).length
        : 0;
    }

    tempStats[0].value = totalFans;
    tempStats[0].prevValue = prevTotalFans;

    tempStats[1].value = totalUsers;
    tempStats[1].prevValue = prevTotalUsers;

    tempStats[2].value = totalRedemptions;
    tempStats[2].prevValue = prevTotalRedemptions;

    tempStats[3].value = totalContestEntries;
    tempStats[3].prevValue = prevTotalContestEntries;

    tempStats[4].value = totalBusinesses;
    tempStats[4].prevValue = prevTotalBusinesses;

    return tempStats;
  };

  const mapUserData = () => {
    if (redemptionsFromEvents) {
      const { genderData, ageData, userLocationMap, userStateMap } =
        mapUserDataforCharts(redemptionsFromEvents);

      let selectedUserLocationMap = {};

      setGenderChartData(genderData);
      setAgeChartData(ageData);

      if (Object.keys(userLocationMap).length > 5) {
        selectedUserLocationMap = Object.keys(userLocationMap)
          .slice(0, 6)
          .reduce((obj, key) => {
            obj[key] = userLocationMap[key];
            return obj;
          }, {});
      }

      setHeatMapData(userStateMap);

      setSelectedLocationChartData(selectedUserLocationMap);
      setLocationChartData(userLocationMap);
    }
  };

  useEffect(() => {
    if (!isRedemptionsLoading) {
      mapUserData();
    }
  }, [redemptionsFromEvents, isRedemptionsLoading]);

  const updateRedemptionsChartData = () => {
    if (redemptionsFromEvents) {
      const redemptionsData = getRedemptionChartData(
        redemptionsFromEvents,
        selectedRedemtionFilter,
        redemptionsFilterDaysAdjustment
      );

      setRedemptionsChartData(redemptionsData);
    }
  };

  const updateImpressionsChartData = () => {
    if (impressions) {
      const impressionsData = getImpressionsChartData(
        impressions,
        selectedWebFilter,
        webFilterDaysAdjustment
      );

      setImpressionsChartData(impressionsData);
    }
  };

  useEffect(() => {
    if (!isRedemptionsLoading) {
      if (redemptionsFromEvents) {
        updateRedemptionsChartData();
      }
    }
  }, [
    redemptionsFromEvents,
    selectedRedemtionFilter,
    redemptionsFilterDaysAdjustment,
    isRedemptionsLoading,
  ]);

  useEffect(() => {
    if (impressions) {
      updateImpressionsChartData();
    }
  }, [impressions, selectedWebFilter, webFilterDaysAdjustment]);

  useEffect(() => {
    if (selectedRedemtionFilter) {
      setRedemptionsFilterDaysAdjustment(0);
    }
    if (selectedWebFilter) {
      setWebFilterDaysAdjustment(0);
    }
  }, [selectedRedemtionFilter, selectedWebFilter]);

  useEffect(() => {
    if (redemptionsFilterDaysAdjustment > 0) {
      setSelectedRedemtionFilter("7days");
    }
    if (webFilterDaysAdjustment > 0) {
      setSelectedWebFilter("7days");
    }
  }, [redemptionsFilterDaysAdjustment, webFilterDaysAdjustment]);

  useEffect(() => {
    setHeaderFilterAdjustment(0);
  }, [selectedHeaderFilter]);

  return (
    <PoartalAdminDashboardWrapper className="portal-admin-dashboard animated fadeIn">
      <FlexContainer
        className="portal-admin-header-container"
        alignItems="center"
        justifyContent="center"
        margin="30px auto"
      >
        <FlexContainer
          className="portal-admin-header-left"
          alignItems="flex-start"
          justifyContent="center"
          gap="30px"
        >
          <DashboardTitle
            className="portal-admin-header-title standard-b1"
            title="Home"
            justifyContent="flex-start"
          />
          <DashboardTitle
            className="portal-admin-header-title standard-b2"
            title="FanSaves Analytics"
            justifyContent="flex-start"
          />
        </FlexContainer>
        <div className="dashboard-header-links" style={{ display: "none" }}>
          <FlexContainer
            className="link-container"
            gap="10px"
            direction="horizontal"
          >
            <Icon icon="fa-solid:user-alt" className="user-logo-icon" />
            <Link to="" className="user-profile-link rubik-h3 ">
              Profile
            </Link>
          </FlexContainer>
        </div>
      </FlexContainer>
      <AdminDashboardStats
        stats={getStats()}
        selectedFilter={selectedHeaderFilter}
        onSelectFilter={setSelectedHeaderFilter}
        onFilterAdjustment={setHeaderFilterAdjustment}
        filterAdjustment={headerFilterAdjustment}
      />
      <FlexContainer
        className="action-buttons"
        direction="horizontal"
        responsive
        justifyContent="space-between"
        gap="20px"
        margin="0 auto"
      >
        <DashboardButton
          title="Add an Organization"
          icon="fa-solid:plus"
          type="green"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/teams/create`);
          }}
        />
        <DashboardButton
          title="Add a Business"
          icon="fa-solid:plus"
          type="green"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/sponsors/create`);
          }}
        />
        <DashboardButton
          title="Add a Deal"
          icon="fa-solid:plus"
          type="green"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/deals/create`);
          }}
          disabled={true}
          disabledMessage="Admin can add a deal on a Business Dashboard"
        />
        <DashboardButton
          title="Add a Brand"
          icon="fa-solid:plus"
          type="green"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/brands/create`);
          }}
        />
      </FlexContainer>
      <FlexContainer
        direction="horizontal"
        responsive
        justifyContent="space-between"
        margin="50px auto"
        gap="20px"
        alignItems="flex-start"
      >
        <DashboardListCard
          title="Organizations"
          total={
            teams ? (teams.length > 0 ? teams.length : 0) : renderLoading()
          }
          list={
            teams &&
            teams.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
          }
          onClick={() => {
            history.push("/portal/teams");
          }}
        />

        <DashboardListCard
          title="Businesses"
          total={
            businesses
              ? businesses.length > 0
                ? businesses.length
                : 0
              : renderLoading()
          }
          list={
            businesses &&
            businesses.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
          }
          onClick={() => {
            history.push("/portal/sponsors");
          }}
        />

        <DashboardListCard
          title="Deals"
          columns={3}
          total={
            offersArray
              ? offersArray.length > 0
                ? offersArray.length
                : 0
              : renderLoading()
          }
          list={
            offersArray &&
            offersArray.sort(
              (a, b) => moment(a.createdAt) - moment(b.createdAt)
            )
          }
          onClick={() => {
            history.push("/portal/deals");
          }}
        />
      </FlexContainer>
      <DashboardWebAnalytics
        impressionsChartData={impressionsChartData}
        filter={selectedWebFilter}
        onSelectFilter={setSelectedWebFilter}
        onDaysAdjustment={setWebFilterDaysAdjustment}
        filterDaysAdjustment={webFilterDaysAdjustment}
      />
      {!isRedemptionsLoading && redemptionsFromEvents ? (
        <DashboardRedemptionsTable
          title="Redemptions"
          redemptions={redemptionsFromEvents}
          limit={5}
          showAllClick={(e) => {
            e.preventDefault();
            history.push("/portal/redemptions");
          }}
        />
      ) : (
        renderLoadingContainer()
      )}
      <DashboardChartContainer
        title="Redemptions Graph"
        onSelectFilter={setSelectedRedemtionFilter}
        onFilterDaysAdjustment={setRedemptionsFilterDaysAdjustment}
        filterDaysAdjustment={redemptionsFilterDaysAdjustment}
        renderChart={() => {
          if (redemptionsChartData) {
            return (
              <LineChart
                dataSet={redemptionsChartData}
                showLegend
                filter={selectedRedemtionFilter}
                filterDaysAdjustment={redemptionsFilterDaysAdjustment}
              />
            );
          } else {
            return renderLoadingContainer();
          }
        }}
      />

      {redemptionsFromEvents ? (
        <DashboardRedemptionAnalytics
          redemptions={redemptionsFromEvents}
          genderChartData={genderChartData}
          ageChartData={ageChartData}
          locationChartData={locationChartData}
          selectedLocationChartData={selectedLocationChartData}
          heatMapData={heatMapData}
        />
      ) : (
        renderLoadingContainer()
      )}
    </PoartalAdminDashboardWrapper>
  );
};

export default withFirebase(
  withAuthentication(withRouter(PortalAdminDashboard))
);
