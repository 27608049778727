import React from "react";
import { COLORS, Text, FlexContainer } from "../../assets/fansaves-ui";

//hooks
import useMobile from "../../hooks/useMobile";

const ExternalLinkButton = ({ link, iconElement, text, className }) => {
  const isMobile = useMobile();
  if (!link || link === "") return null;

  return (
    <FlexContainer className={className}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="social-link-container"
      >
        <FlexContainer
          alignItems="center"
          cursor="pointer"
          justifyContent={`${text ? "space-between" : "center"}`}
          height="100%"
        >
          {iconElement}
          {text && (
            <div>
              <Text
                semiBold
                color={COLORS.primaryBlack}
                type={isMobile ? "mobile-h5" : "standard-h5"}
                style={{ textWrap: "nowrap" }}
              >
                {text}
              </Text>
            </div>
          )}
        </FlexContainer>
      </a>
    </FlexContainer>
  );
};

export default ExternalLinkButton;
