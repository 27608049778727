const Teams = {
  name: "Organizations",
  url: "/portal/teams",
  icon: "icon-list",
};

const Brands = {
  name: "Brands",
  url: "/portal/brands",
  icon: "icon-list",
};

const Sponsors = {
  name: "Businesses",
  url: "/portal/sponsors",
  icon: "icon-list",
};

const CreateTeam = {
  name: "Create Organization",
  url: "/portal/teams/create",
  icon: "icon-pencil",
};

const CreateSponsor = {
  name: "Create Business",
  url: "/portal/sponsors/create",
  icon: "icon-pencil",
};

const Reports = {
  name: "Reports",
  url: "/portal/reports",
  icon: "icon-doc",
};

const AdminSettings = {
  name: "Admin Settings",
  url: "/portal/admin-settings",
  icon: "icon-settings",
};

const MainSite = {
  name: "Main Site",
  url: "/",
  icon: "icon-home",
};

const Logout = {
  name: "Logout",
  url: "/logout",
  icon: "cui-account-logout",
};

const adminNavigation = {
  items: [
    Teams,
    Brands,
    Sponsors,
    CreateTeam,
    CreateSponsor,
    Reports,
    AdminSettings,
    MainSite,
    Logout,
  ],
};

const sponsorNavigation = {
  items: [Sponsors, MainSite, Logout],
};

const teamNavigation = {
  items: [Teams, Sponsors, MainSite, Logout],
};

export { adminNavigation, teamNavigation, sponsorNavigation };
