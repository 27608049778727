/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./RedeemDealModal.scss";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import ModalContainer from "../Modals/Modal";
import Countdown from "../Countdown";
import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";
import logo from "../../assets/img/brand/logo-white-hollow.png";
import moment from "moment";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const RedeemImage = styled.img`
  width: 100%;
  margin: 20px 0;
`;

function RedeemDealModal({
  onClose,
  onConfirm,
  deal,
  organization,
  targetDateTimeString,
  redemptionTimeExpired,
  isMobile,
  dealRedeemedAt,
  renderRedeem,
}) {
  const [closeCountDown, setCloseCountDown] = useState(false);
  const [showCopyCodeTooltip, setShowCopyCodeTooltip] = useState(false);

  useEffect(() => {
    if (closeCountDown) {
      onClose();
      setCloseCountDown(false);
    }
  }, [closeCountDown]);

  const handleCopyCode = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(deal.onlineCode);
    setShowCopyCodeTooltip(true);
    setInterval(() => {
      setShowCopyCodeTooltip(false);
    }, 3000);
  };

  const renderRedeemInstructions = (contact, code) => {
    const actionMessageId = deal.easyRedemption
      ? "app.redeemDealModal.toViewDeal"
      : "app.redeemDealModal.toRedeemDeal";

    if (contact.startsWith("http")) {
      return (
        <div data-notranslate>
          <FormattedMessage
            id={
              code
                ? "app.redeemDealModal.messageWithCode"
                : "app.redeemDealModal.messageWithoutCode"
            }
            defaultMessage={
              code
                ? "Copy the code below then <a>click here</a> to {action}!"
                : "<a>Click Here</a> to {action}!"
            }
            values={{
              a: (chunks) => (
                <a
                  href={contact}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="online-redeem-link"
                >
                  {chunks}
                </a>
              ),
              action: (
                <FormattedMessage
                  id={actionMessageId}
                  defaultMessage={
                    deal.easyRedemption ? "view your deal" : "redeem your deal"
                  }
                />
              ),
            }}
          />
        </div>
      );
    } else if (
      (/^\d/.test(contact) || /\(/.test(contact)) &&
      !contact.includes("@")
    ) {
      return (
        <div>
          Please call <span className="online-redeem-link">{contact}</span> and
          provide the discount code below to receive this deal!
        </div>
      );
    } else if (contact.includes("@")) {
      return (
        <div>
          Copy the code below then email{" "}
          <a
            href={`mailto:${contact}`}
            target="_blank"
            rel="noopener noreferrer"
            className="online-redeem-link"
          >
            {contact}
          </a>{" "}
          to redeem your deal!
        </div>
      );
    }
  };

  return (
    <div className="component-in-store-redeem-modal">
      <ModalContainer onClose={onClose && onClose}>
        {deal && (
          <>
            {targetDateTimeString && (
              <>
                <div
                  className={`deal-redeemed-container ${
                    isMobile ? "mobile-h2" : "standard-h1"
                  }`}
                >
                  <div className="deal-redeemed-text">
                    <i className="success-icon fa fa-check-circle" />
                    <div>Redeemed</div>
                  </div>
                  <div className="fansaves-logo">
                    <img className="fansaves-logo-img" src={logo} alt="" />
                  </div>
                </div>
                <div
                  className={`deal-redeemed-time ${
                    isMobile ? "mobile-b3" : "standard-b1"
                  }`}
                >
                  Redeemed on{" "}
                  {moment(dealRedeemedAt).format("MMMM D, YYYY [at] h:mma")}
                </div>
              </>
            )}
            <div
              className={`business-container ${
                targetDateTimeString ? "redeemed-business-container" : ""
              }`}
            >
              <div className="business-logo-container">
                <img
                  src={deal.sponsor.picture}
                  alt={`${deal.sponsor.name} logo`}
                  className="business-logo"
                />
              </div>
              <div className="business-details">
                <div
                  className={`business-name ${
                    isMobile ? "mobile-h2" : "standard-h5"
                  }`}
                  data-notranslate
                >
                  {deal.sponsor.name}
                </div>
                <div
                  className={`business-address ${
                    isMobile ? "mobile-b3" : "standard-b1"
                  }`}
                  data-notranslate
                >
                  {deal.sponsor.address}
                </div>
              </div>
            </div>
            <div className="deal-container">
              <div
                className={`description ${
                  isMobile ? "mobile-h2" : "standard-h3"
                }`}
              >
                {deal.description}
              </div>
              <div
                className={`deal-terms ${
                  isMobile ? "mobile-b3" : "standard-b1"
                }`}
              >
                <b>Terms & Conditions</b>: {deal.terms}
              </div>
            </div>
            <div className="image-container">
              <img
                src={deal.picture}
                alt={deal.description}
                className="deal-picture"
              />
            </div>
            <div
              className={`redeem-disclaimer-container ${
                isMobile ? "mobile-b3" : "standard-b1"
              }`}
            >
              {!targetDateTimeString && !deal.easyRedemption ? (
                <>
                  {deal.dealType === "instore" && (
                    <>
                      <div className="redeem-disclaimer-text">
                        Do not redeem this deal unless you are in the physical
                        establishment. You will only have
                        <span
                          className={`${
                            isMobile ? "mobile-b4" : "standard-b2"
                          }`}
                        >
                          {" "}
                          10 minutes{" "}
                        </span>
                        to receive your deal and it may not be available again
                        afterward.
                      </div>
                    </>
                  )}

                  {deal.dealType === "online" && (
                    <>
                      <div className="redeem-disclaimer-text">
                        Do not redeem this deal unless you plan to use it. You
                        will only have access to your discount code for
                        <span
                          className={`${
                            isMobile ? "mobile-b4" : "standard-b2"
                          }`}
                        >
                          {" "}
                          10 minutes{" "}
                        </span>
                        and it may not be available again afterward.
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div
                  className={`redeem-disclaimer-text ${
                    isMobile ? "mobile-b4" : "standard-b4"
                  }`}
                >
                  {deal.dealType === "instore" && (
                    <>
                      <div className="redeemed-important-text">
                        Show this screen at the point of purchase to receive
                        this exclusive offer!
                      </div>
                      {deal.redeemImage && (
                        <RedeemImage src={deal.redeemImage} />
                      )}
                    </>
                  )}
                  {deal.dealType === "online" && (
                    <>
                      {deal.website &&
                        renderRedeemInstructions(deal.website, deal.onlineCode)}
                      {deal.onlineCode && (
                        <div className="redeem-online-code-container">
                          <div
                            className={`redeem-online-code redeemed-important-text ${
                              isMobile ? "mobile-b2" : "standard-h5"
                            }`}
                          >
                            {deal.onlineCode}
                          </div>
                          <a href="#" onClick={(e) => handleCopyCode(e)}>
                            <i className="fa fa-copy" id="online-code-icon"></i>
                          </a>
                          <Tooltip
                            placement="bottom"
                            target="online-code-icon"
                            isOpen={showCopyCodeTooltip}
                            onClick={() =>
                              setShowCopyCodeTooltip(!showCopyCodeTooltip)
                            }
                          >
                            Copied!
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        {!deal.easyRedemption && (
          <div className="actions">
            {!targetDateTimeString ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (onConfirm) {
                    onConfirm();
                  }
                }}
                className={`button ${
                  isMobile ? "popup-cta-mobile-btn" : "popup-cta-btn"
                }`}
              >
                {renderRedeem && typeof renderRedeem === "function"
                  ? renderRedeem()
                  : "Redeem"}
              </a>
            ) : redemptionTimeExpired ? (
              <div className={`${isMobile ? "mobile-b2" : "standard-h4"}`}>
                0:00
              </div>
            ) : (
              <Countdown
                fullText
                targetDateTimeString={targetDateTimeString}
                onStopCountDown={setCloseCountDown}
                dealType={deal.dealType}
                isMobile={isMobile}
              />
            )}
          </div>
        )}
      </ModalContainer>
    </div>
  );
}

RedeemDealModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  deal: PropTypes.object,
  organization: PropTypes.object,
  renderRedeem: PropTypes.func,
};

export default RedeemDealModal;
