/* Description
 * -----------
 * Controls things like
 * - which firebase config to use
 * - which algolia endpoints to use
 * - which cloud functions host to use
 *
 * Usage
 * -----
 * import environment from './environment'
 *
 * Values
 * ------
 * name [dev, staging, production]
 * Main config that controls most switches
 *
 * useEmulators [true, false]
 * Whether to use local emulators or deployed dev environment
 *
 */

export default {
  // name: "dev",
  // name: "staging",
  name: "production",
  useFirebaseEmulators: false,
};
