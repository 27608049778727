import { create } from "zustand";
import {
  useQueryClient,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";

import {
  createSpecialCodes,
  fetchSpecialCodes,
} from "../utilities/admin_helpers";

const useSpecialCodesStore = create((set, get) => ({
  specialCodes: null,
  specialCodesTotal: null,
  isLoading: false,
  error: null,
  isFetching: false,
  isFetchingTotal: false,
  isLoadingTotal: false,
  errorTotal: null,

  addSpecialCodes: async (firebase, teamId, codes, accessLevel) => {
    try {
      const result = await createSpecialCodes(
        firebase,
        teamId,
        codes,
        accessLevel
      );

      return result;
    } catch (error) {
      console.error("Error adding special codes", error);
      return null;
    }
  },

  useInfiniteQuerySpecialCodes: (firebase, teamId, accessLevel, searchTerm) => {
    return useInfiniteQuery({
      queryKey: ["specialCodes", teamId, accessLevel, searchTerm],
      queryFn: async ({ pageParam = null }) => {
        let q = firebase.firestore
          .collection("specialCodes")
          .where("teamKey", "==", teamId);

        if (accessLevel) {
          q = q.where("accessLevel", "==", accessLevel);
        }

        q = q.orderBy("code", "asc");

        if (pageParam) {
          q = q.startAfter(pageParam);
        }

        if (searchTerm) {
          const endString = searchTerm.replace(/.$/, (c) =>
            String.fromCharCode(c.charCodeAt(0) + 1)
          );
          q = q.where("code", ">=", searchTerm).where("code", "<", endString);
        }

        q = q.limit(50);

        const querySnapshot = await q.get();

        const specialCodes = [];
        if (querySnapshot.empty) {
          return { specialCodes, hasNewPage: false };
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          specialCodes.push({ id: doc.id, ...doc.data() });
        });

        return { specialCodes, hasNewPage: true, lastVisible };
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.hasNewPage) {
          return undefined;
        }
        return lastPage.lastVisible;
      },
    });
  },

  fetchSpecialCodes: async (firebase, teamId, accessLevel) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });

    try {
      const data = await fetchSpecialCodes(firebase, teamId, accessLevel);

      const teamSpecialCodesObj = {};
      teamSpecialCodesObj[teamId] = data;

      set({ specialCodes: teamSpecialCodesObj });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchSpecialCodesTotal: async (firebase, teamId, accessLevel) => {},
  updateSpecialCode: async (firebase, teamId, code, updates) => {
    const codeId = code.id;
    try {
      await firebase.firestore
        .collection("specialCodes")
        .doc(codeId)
        .update(updates);
    } catch (error) {
      console.error("Error updating special code", error);
      return null;
    }
  },

  deleteSpecialCode: async (firebase, teamId, code) => {
    const codeId = code.id;
    const accessLevel = code.accessLevel;
    try {
      await firebase.firestore.collection("specialCodes").doc(codeId).delete();

      const specialCodesTotalRef = firebase.firestore
        .collection("specialCodesTotals")
        .doc(teamId);

      const specialCodesTotalDoc = await specialCodesTotalRef.get();

      if (specialCodesTotalDoc.exists) {
        const specialCodesTotalData = specialCodesTotalDoc.data();
        if (!specialCodesTotalData) {
          console.error("No special codes totals found");
          return false;
        }
        const specialCodesTotal = specialCodesTotalData[accessLevel];
        if (!specialCodesTotal) {
          console.error("No special codes totals found");
          return false;
        }
        const newSpecialCodesTotal = specialCodesTotal - 1;
        const updates = {};
        updates[accessLevel] = newSpecialCodesTotal;
        await specialCodesTotalRef.update(updates);
      } else {
        console.error("No special codes totals found");
      }

      return true;
    } catch (error) {
      console.error("Error deleting special code", error);
      return false;
    }
  },

  useSpecialCode: (firebase, teamId, codeId) => {
    return useQuery({
      queryKey: ["specialCode", teamId, codeId],
      queryFn: async () => {
        const doc = await firebase.firestore
          .collection("specialCodes")
          .doc(codeId)
          .get();

        const specialCode = doc.data();
        if (!specialCode || specialCode.teamKey !== teamId) {
          return null;
        }
        specialCode.id = doc.id;

        return specialCode;
      },
      initialData: () => {
        const specialCodes = get().specialCodes;
        if (specialCodes && specialCodes[teamId]) {
          return specialCodes[teamId].find((code) => code.id === codeId);
        }
        return null;
      },
    });
  },
}));

export default useSpecialCodesStore;
