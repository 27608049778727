import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { 
  withFirebase
} from '../../../../../containers/Firebase';

class Create extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      documentFile: null,
      pictureURL: '',
      description: '',
      teams: [],
      terms: '',
    };

    this.pictureClick = this.pictureClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    var sponsorID = this.props.match.params.sponsor_id;
    this.setState({sponsorID});
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    const files = e.target.files;
    if (files) {
      let fileName = name + 'File';
      let file = files[0];
      this.setState({[fileName]: file});
    } else {
      this.setState({[name]: value});
    }
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  pictureClick() {
    document.getElementById("picture_upload").click()
  }

  handleSubmit(event) {
    event.preventDefault();
    let documentStorageRef = this.props.firebase.storage.ref();
    let documentStorageBasePath = 'documents/' + this.state.sponsorID + '/training_document.pdf';

    if (!this.state.documentFile) {
      return;
    }
    
    documentStorageRef.child(documentStorageBasePath).put(this.state.documentFile).then(
    dataSnapshot => dataSnapshot.ref.getDownloadURL())
    .then(url => {
        this.setState({'documentURL': url,},
        () => this.pushSponsorData());
    });
  }

  pushSponsorData = () => {
    if (!this.state.documentURL) { return; }

    let sponsorDocumentPath = 'sponsor/' + this.state.sponsorID + '/document';
    let sponsorDocumentRef = this.props.firebase.database.ref(sponsorDocumentPath);
    sponsorDocumentRef.set(this.state.documentURL)
    .then(() => {
      this.props.history.push(`/portal/sponsors/${this.state.sponsorID}`);
    });

  }

  render() {
    return (
      <div className="animated fadeIn" id="main">
        <Form className="row" onSubmit={(event) => this.handleSubmit(event)}>
          <div className="col-12">
          <FormGroup className="text-center">
            <Label><strong>TRIANING DOCUMENT</strong></Label>
            <br/>
            <input name="document" onChange={(event) => this.handleUserInput(event)} id="document_upload" type="file"/>
          </FormGroup>
          </div>
          <div className="col-12">
            <Input type="submit" value="Submit"/>
          </div>
        </Form>
      </div>
    );
  }
}

export default withFirebase(Create);
