import React from "react";
import { DashboardStats } from "../UIKit";
import { DashboardFilter, FlexContainer } from "../../assets/fansaves-ui";
import styled from "styled-components/macro";
import moment from "moment";
import { Icon } from "@iconify/react";
import "../../scss/base/typography.scss";

const StyledFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 30px auto;
  width: 100%;
  position: relative;
  .dashboard-filter {
    position: absolute;
    top: -10px;
    left: 0;
    z-index: 2;
  }
  .dashboard-stats-actions {
    align-self: flex-end;
  }
`;

const AdminDashboardStats = ({
  stats,
  selectedFilter,
  onSelectFilter,
  onFilterAdjustment,
  filterAdjustment,
}) => {
  const renderTitle = (selectedFilter) => {
    switch (selectedFilter) {
      case "Life-Time":
        return "";
      case "Weekly":
        if (filterAdjustment === 0) {
          return `This Week ${moment()
            .startOf("week")
            .format("MMM DD, YYYY")} - ${moment()
            .endOf("week")
            .format("MMM DD, YYYY")}`;
        } else {
          return `Previous Week ${moment()
            .subtract(filterAdjustment, "weeks")
            .startOf("week")
            .format("MMM DD, YYYY")} - ${moment()
            .subtract(filterAdjustment, "weeks")
            .endOf("week")
            .format("MMM DD, YYYY")}`;
        }
      case "Monthly":
        if (filterAdjustment === 0) {
          return `This Month ${moment()
            .startOf("month")
            .format("MMM DD, YYYY")} - ${moment()
            .endOf("month")
            .format("MMM DD, YYYY")}`;
        } else {
          return `Previous Month ${moment()
            .subtract(filterAdjustment, "months")
            .startOf("month")
            .format("MMM DD, YYYY")} - ${moment()
            .subtract(filterAdjustment, "months")
            .endOf("month")
            .format("MMM DD, YYYY")}`;
        }
      case "Quartely":
        if (filterAdjustment === 0) {
          return `This Quarter ${moment()
            .quarter(moment().quarter())
            .startOf("quarter")
            .format("MMM DD, YYYY")} - ${moment()
            .quarter(moment().quarter())
            .endOf("quarter")
            .format("MMM DD, YYYY")}`;
        } else {
          return `Previous Quarter ${moment()
            .quarter(moment().quarter() - filterAdjustment)
            .startOf("quarter")
            .format("MMM DD, YYYY")} - ${moment()
            .quarter(moment().quarter() - filterAdjustment)
            .endOf("quarter")
            .format("MMM DD, YYYY")}`;
        }
      case "Annualy":
        if (filterAdjustment === 0) {
          return `This Year ${moment()
            .startOf("year")
            .format("MMM DD, YYYY")} - ${moment()
            .endOf("year")
            .format("MMM DD, YYYY")}`;
        } else {
          return `Previous Year ${moment()
            .subtract(filterAdjustment, "years")
            .startOf("year")
            .format("MMM DD, YYYY")} - ${moment()
            .subtract(filterAdjustment, "years")
            .endOf("year")
            .format("MMM DD, YYYY")}`;
        }
      default:
        return "";
    }
  };

  const getButtonTitle = (selectedFilter) => {
    switch (selectedFilter) {
      case "Weekly":
        return "Week";
      case "Monthly":
        return "Month";
      case "Quartely":
        return "Quarter";
      case "Annualy":
        return "Year";
      default:
        return "";
    }
  };

  const handleAdjustedDaysButtonClick = (direction) => {
    if (onFilterAdjustment && typeof onFilterAdjustment === "function") {
      if (direction === "previous") {
        onFilterAdjustment(filterAdjustment + 1);
      } else if (direction === "next" && filterAdjustment > 0) {
        onFilterAdjustment(filterAdjustment - 1);
      }
    }
  };

  return (
    <StyledFlexContainer className="admin-dashboard-stats">
      <DashboardFilter
        className="dashboard-filter"
        onSelect={onSelectFilter}
        initialFilterOption="Life-Time"
      />
      <div className="stats-title public-h1">{renderTitle(selectedFilter)}</div>
      <DashboardStats stats={stats} showDifference={filterAdjustment} admin />
      {selectedFilter !== "Life-Time" && (
        <FlexContainer
          className="dashboard-stats-actions"
          direction="horizontal"
          gap="10px"
          alignItems="center"
          justifyContent="flex-end"
          margin="0"
          width="fit-content"
        >
          <FlexContainer
            gap="5px"
            alignItems="center"
            justifyContent="center"
            direction="horizontal"
            onClick={() => handleAdjustedDaysButtonClick("previous")}
            className="dashboard-stats-action"
            width="fit-content"
          >
            <Icon icon="mdi-chevron-left" className="icon" />{" "}
            <div className="dashboard-stats-link-text inter-b2">
              Previous {getButtonTitle(selectedFilter)}
            </div>
          </FlexContainer>
          {filterAdjustment > 0 && (
            <FlexContainer
              gap="5px"
              alignItems="center"
              justifyContent="center"
              direction="horizontal"
              onClick={() => handleAdjustedDaysButtonClick("next")}
              className="dashboard-stats-action"
              width="fit-content"
            >
              <div className="dashboard-stats-link-text inter-b2">
                Next {getButtonTitle(selectedFilter)}
              </div>
              <Icon icon="mdi-chevron-right" className="icon" />
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    </StyledFlexContainer>
  );
};

export default AdminDashboardStats;
