import React from "react";
import useOffersStore from "../stores/offersStore";

function withOffersStore(Component) {
  return function WrappedComponent(props) {
    const storeState = useOffersStore((state) => state);
    return <Component {...props} {...storeState} />;
  };
}

export default withOffersStore;
