import React from "react";
import { Icon } from "@iconify/react";
import { FormattedMessage } from "react-intl";

import "./SpecialOverlay.scss";

const SpecialOverlay = ({ deal, unlocked, size }) => {
  // Not special
  if (
    !deal.flashDeal &&
    !deal.exclusive &&
    !deal.triggered &&
    !deal.contestDeal
  ) {
    return <></>;
  }

  let backgroundClass = "exclusive";
  let text = "";

  if (deal.exclusive) {
    text += "Exclusive ";
  }

  if (deal.contestDeal) {
    text += "Contest";
  }

  if (deal.flashDeal) {
    text += "Flash ";
  }

  if (!deal.contestDeal) {
    text += "Deal";
  }

  // Flash background takes precedence
  if (deal.flashDeal) {
    backgroundClass = "flash";
  }

  // Triggered background takes precedence further
  if (deal.triggered) {
    if (!deal.active) {
      backgroundClass = "not-triggered";
      if (deal.contestDeal) {
        text = "Contest";
      } else {
        text = "Not triggered";
      }
    } else {
      backgroundClass = "triggered";
      if (deal.contestDeal) {
        text = "Contest";
      } else {
        text = "Triggered!";
      }
    }
  }

  const translateText = (text) => {
    if (text === "Exclusive Deal") {
      return "app.deal.exclusive";
    } else if (text === "Flash Deal") {
      return "app.deal.flash";
    } else if (text === "Triggered!") {
      return "app.deal.triggered";
    } else if (text === "Not triggered") {
      return "app.deal.not.triggered";
    } else if (text === "Contest") {
      return "app.deal.contest";
    }
  };

  return (
    <div className={`component-special-overlay ${backgroundClass} ${size}`}>
      {deal.exclusive && (
        <>
          {unlocked ? (
            <i className="fa fa-unlock" />
          ) : (
            <i className="fa fa-lock" />
          )}
        </>
      )}
      {deal.flash && (
        <>
          <i className="fa fa-bolt" />
        </>
      )}
      {deal.triggered && (
        <>
          <i className="fa fa-clock-o" />
        </>
      )}
      {deal.contestDeal && (
        <>
          <Icon
            icon="mingcute:celebrate-fill"
            width="20"
            height="20"
            style={{ marginRight: "5px", marginBottom: "2px" }}
          />
        </>
      )}
      <span data-notranslate>
        <FormattedMessage id={translateText(text)} defaultMessage={text} />
      </span>
    </div>
  );
};

export default SpecialOverlay;
