import React, { Component } from "react";
import { withFirebase } from "../../../../../containers/Firebase";
import { withRouter } from "react-router-dom";
import { withAuthentication } from "../../../../../containers/Session";
import withOffersStore from "../../../../../stores/withOffersStore";
import { validateDeal } from "../";
import SponsorDealForm, {
  setupImageUploadPromises,
  setupGetDownloadUrlPromises,
} from "../Form";

import {
  dbLoadSponsor,
  dbLoadSponsorTeam,
} from "../../../../../containers/DatabaseHelpers";

import "./Create.scss";

class Create extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingSponsor: true,
      loadingSponsorTeam: true,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const { sponsor_id: sponsorID } = this.props.match.params;

    this.setState({ sponsorID }, () => {
      this.loadSponsor();
      this.loadSponsorTeam();
    });
  }

  loadSponsor = () => {
    const { firebase } = this.props;
    const { sponsorID } = this.state;

    dbLoadSponsor(firebase, sponsorID, (sponsor) => {
      this.setState({ sponsor, loadingSponsor: false });
    });
  };

  loadSponsorTeam = () => {
    const { firebase } = this.props;
    const { sponsorID } = this.state;

    dbLoadSponsorTeam(firebase, sponsorID, (sponsorTeam) => {
      this.setState({ sponsorTeam, loadingSponsorTeam: false });
    });
  };

  handleSubmit = (deal) => {
    const { sponsor, sponsorTeam } = this.state;
    this.setState({
      isSubmitting: true,
    });

    if (!validateDeal(deal, sponsor, sponsorTeam)) {
      this.setState({
        isSubmitting: false,
      });
      return;
    }

    const dealRef = this.props.firebase.database.ref("offer").push();
    this.uploadImagesAndPushOfferData(dealRef, deal);
  };

  uploadImagesAndPushOfferData = (dealRef, deal) => {
    const { firebase } = this.props;

    const dealKey = dealRef.key;

    const imageUploadPromises = setupImageUploadPromises(
      firebase,
      dealKey,
      deal
    );

    Promise.all(imageUploadPromises)
      .then((snapshots) => {
        const getDownloadUrlPromises = setupGetDownloadUrlPromises(snapshots);

        return Promise.all(getDownloadUrlPromises);
      })
      .then((urls) => {
        this.pushOfferData(dealRef, deal, {
          pictureUrl: urls[0],
          redeemImageUrl: urls[1],
        });
      });
  };

  pushOfferData = (ref, deal, imageUrls) => {
    const { firebase } = this.props;

    const { sponsorID, sponsorTeam } = this.state;

    const {
      active,
      published,
      delayDay,
      delayHour,
      delayWeek,
      delayMonth,
      description,
      descriptionFr,
      terms,
      exclusive,
      dealType,
      website,
      onlineCode,
      flashDeal,
      contestDeal,
      hiddenDeal,
      price,
      originalValue,
      totalAvailable,
      expiresAt,
      keywords,
      currency,
      triggered,
      triggerDurationHours,
      redemptionsLimit,
      notTriggeredMessage,
      accessLevels,
      easyRedemption,
    } = deal;

    if (!sponsorTeam) {
      alert("Team is not defined. Please contact support team.");
    }

    var hours = parseInt(delayDay * 24) + parseInt(delayHour);
    var interval = {
      week: delayWeek,
      month: delayMonth,
      hour: hours,
    };

    const newDeal = {
      active,
      published,
      description,
      descriptionFr,
      terms,
      teams: [sponsorTeam.key],
      interval,
      exclusive,
      dealType,
      website,
      onlineCode,
      flashDeal,
      contestDeal,
      hiddenDeal,
      price,
      originalValue,
      totalAvailable,
      totalPurchased: 0,
      keywords,
      currency,
      createdAt: firebase.databaseServerValue.TIMESTAMP,
      updatedAt: firebase.databaseServerValue.TIMESTAMP,
      triggered,
      accessLevels,
      easyRedemption,
    };

    if (triggered) {
      newDeal.triggerDurationHours = triggerDurationHours;
      newDeal.redemptionsLimit = redemptionsLimit;
      newDeal.totalRedeemed = 0;
      newDeal.notTriggeredMessage = notTriggeredMessage;
    }

    if (imageUrls.pictureUrl) {
      newDeal.picture = imageUrls.pictureUrl;
    }

    if (imageUrls.redeemImageUrl) {
      newDeal.redeemImage = imageUrls.redeemImageUrl;
    }

    /* Note: Theory, not proven. Because the sponsorRef.child('offers') gets set
              afterwards, this causes the cloud function to update all offers with
              sponsor information. So we don't have to set that directly here. */

    ref.set(newDeal).then(() => {
      // For some reason, Firebase Realtime database wasn't taking dates when calling
      // ref.set, but works on ref.update
      ref
        .update({ expiresAt })
        .then(() => {
          if (!sponsorID || sponsorID.length < 1) {
            return;
          }

          const sponsorRef = this.props.firebase.database.ref(
            "sponsor/" + sponsorID
          );
          sponsorRef.once("value").then((dataSnapshot) => {
            const offerList = dataSnapshot.val().offers
              ? dataSnapshot.val().offers
              : [];
            offerList.push({
              active,
              triggered,
              published,
              key: ref.key,
              hiddenDeal,
              accessLevels,
            });
            sponsorRef
              .child("offers")
              .set(offerList)
              .then(() => {
                const { authUser, history, fetchOffersByRole, firebase } =
                  this.props;
                const { sponsorID } = this.state;
                fetchOffersByRole(
                  firebase,
                  authUser.role,
                  authUser.teamID,
                  sponsorID || authUser.sponsorID
                );

                history.push(`/portal/sponsors/${sponsorID}/dashboard`);
              });
          });
        })
        .catch((error) => {
          window.alert(error.message);
        });
    });
  };

  render() {
    const { sponsorTeam, loadingSponsorTeam, loadingSponsor, isSubmitting } =
      this.state;

    return loadingSponsorTeam || loadingSponsor ? (
      <div>Loading...</div>
    ) : !sponsorTeam ? (
      <div>
        We're sorry, but deals cannot be added for this business until it has
        been associated with an organization.
      </div>
    ) : (
      <div
        className="views-portal-sponsors-deals-create animated fadeIn"
        id="main"
      >
        <SponsorDealForm
          onSubmit={this.handleSubmit}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  }
}

export default withFirebase(
  withAuthentication(withRouter(withOffersStore(Create)))
);
