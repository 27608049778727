import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../fansaves-ui";
import { Icon } from "@iconify/react";

const MapButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 20px;
  border-radius: 15px;
  border: 1px solid ${COLORS.primaryBlue};
  cursor: pointer;
  width: 100px;
`;

const MapTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlue};
`;

const MapToggleButton = ({ showMap, toggleMap }) => {
  const handleToggle = (e) => {
    e.preventDefault();
    if (toggleMap && typeof toggleMap === "function") {
      toggleMap(!showMap);
    }
  };

  return (
    <MapButtonContainer onClick={handleToggle}>
      <Icon
        icon={showMap ? "fa6-solid:list" : "fa6-solid:map"}
        color={COLORS.primaryBlue}
        size="24"
      />
      <MapTitle>{showMap ? "List" : "Map"}</MapTitle>
    </MapButtonContainer>
  );
};

export default MapToggleButton;
