/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../fansaves-ui";
import useMobile from "../../../hooks/useMobile";
import { useIntl, FormattedMessage } from "react-intl";
import { withFirebase } from "../../../containers/Firebase";
import { withRouter, Link } from "react-router-dom";
import { withAuthentication } from "../../../containers/Session";
import { Button } from "../../../containers/UIKit";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
  padding: ${({ isMobile }) => (isMobile ? "10px" : "20px 10px")};
  background-color: ${COLORS.primaryGreenDark};
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? "fit-content" : "100%")};
`;

const DealTypeTitle = styled.div`
  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? "18px" : "26px")};
  color: ${COLORS.primaryWhite};
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
  justify-content: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
`;

const CardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  align-items: ${({ isMobile }) => (isMobile ? "flex-start" : "center")};
  justify-content: ${({ isMobile }) =>
    isMobile ? "flex-start" : "space-around"};
  gap: ${({ isMobile }) => (isMobile ? "5px" : "20px")};
  background-color: ${COLORS.primaryWhite};
  padding: ${({ isMobile }) => (isMobile ? "5px" : "10px")};
  border-radius: ${({ isMobile }) => (isMobile ? "10px" : "30px")};
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? "fit-content" : "100%")};
  color: ${COLORS.primaryBlack};
`;

const DealDescription = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "20px")};
  color: ${COLORS.primaryBlue};
  font-weight: 600;
  width: 100%;
  text-align: ${({ isMobile }) => (isMobile ? "left" : "center")};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DealImageContainer = styled.div`
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
  flex: 1;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: ${COLORS.primaryWhite};
`;

const DealImage = styled.img`
  flex: 1;
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

const DealInfo = styled.div`
  width: ${({ isMobile }) => (isMobile ? "60%" : "100%")};
  gap: ${({ isMobile }) => (isMobile ? "5px" : "20px")};
  padding-top: ${({ isMobile }) => (isMobile ? "0px" : "20px")};
  display: flex;
  flex-direction: column;
`;

const TeamImage = styled.img`
  width: ${({ isMobile }) => (isMobile ? "39px" : "65px")};
  height: ${({ isMobile }) => (isMobile ? "39px" : "65px")};
  background-color: ${COLORS.primaryWhite};
  border-radius: 50%;
  object-fit: scale-down;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: absolute;
  bottom: ${({ isMobile }) => (isMobile ? "-10px" : "-30px")};
  left: 0px;
  z-index: 20;
  border: 1px solid ${COLORS.primaryWhite};
`;

const DealImageSection = styled.div`
  position: relative;
  width: ${({ isMobile }) => (isMobile ? "40%" : "100%")};
  height: ${({ isMobile }) => (isMobile ? "100px" : "190px")};
`;

const DealCardLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.primaryBlack};
  height: 100%;
  width: 100%;
`;

const ButtonText = styled.span`
  font-size: ${({ isMobile }) => (isMobile ? "14px" : "19px")};
  font-weight: 600;
`;
const RedeemDealButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const DealMapCard = ({ deal, firebase, authUser }) => {
  const isMobile = useMobile();
  const { locale } = useIntl();
  const [team, setTeam] = useState(null);
  const dealFromSearch = deal.objectID !== undefined;

  let url;

  if (dealFromSearch) {
    url = `/deals/${deal.objectID}`;
  } else {
    url = `/deals/${deal.key}`;
  }

  const loadTeam = async () => {
    if (!deal || !deal.teams || deal.teams.length === 0) return;
    const teamKey = deal.teams?.[0].key;
    if (!teamKey) return;
    const teamPath = `/team/${teamKey}`;

    const teamRef = firebase.database.ref(teamPath);
    teamRef
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          const team = snapshot.val();
          team.key = snapshot.key;
          setTeam(team);
        }
      })
      .catch((error) => {
        console.error("Error loading team", error);
      });
  };

  useEffect(() => {
    loadTeam();
  }, [deal]);

  const translateDescription = () => {
    if (!deal) return;
    const description = deal.description;
    const descriptionFr = deal.descriptionFr;
    if (descriptionFr) {
      return (
        <DealDescription isMobile={isMobile}>
          <span data-notranslate>
            {locale === "fr" ? descriptionFr : description}
          </span>
        </DealDescription>
      );
    } else {
      return (
        <DealDescription isMobile={isMobile}>
          <span>{description}</span>
        </DealDescription>
      );
    }
  };

  const isEnteredContest = () => {
    let entered = false;
    if (authUser) {
      if (authUser.redeemed) {
        Object.keys(authUser.redeemed).forEach((key) => {
          if (deal.contestDeal && (key === deal.objectID || key === deal.key)) {
            entered = true;
          }
        });
      }
    }
    return entered;
  };

  const renderContestButtonText = () => {
    if (isEnteredContest()) {
      return "Entered";
    } else {
      return "Enter";
    }
  };

  if (!deal) return null;

  return (
    <CardContainer isMobile={isMobile}>
      {deal.dealType === "online" ? (
        <DealTypeTitle isMobile={isMobile}>
          <i className="fa fa-globe" />
          <div>Online Deal</div>
        </DealTypeTitle>
      ) : (
        <DealTypeTitle isMobile={isMobile}>
          <i className="fa fa-shopping-bag" />
          <div>In-Store Deal</div>
        </DealTypeTitle>
      )}
      <DealCardLink to={url}>
        <CardBody isMobile={isMobile}>
          {isMobile ? (
            <>
              <DealImageSection isMobile={isMobile}>
                {team && (
                  <TeamImage
                    isMobile={isMobile}
                    src={team.logo}
                    alt={`${team.name} logo`}
                  />
                )}
                <DealImageContainer isMobile={isMobile}>
                  <DealImage
                    isMobile={isMobile}
                    src={deal.picture}
                    alt={deal.description}
                  />
                </DealImageContainer>
              </DealImageSection>
              <DealInfo isMobile={isMobile}>
                {translateDescription()}
                {deal.sponsor && (
                  <>
                    <div>{deal.sponsor?.name}</div>
                    <div>
                      {deal.sponsor?.address === "Multiple Locations" ? (
                        <span>Multiple Locations</span>
                      ) : (
                        deal.sponsor?.address
                      )}
                    </div>
                  </>
                )}
                {deal.brand !== undefined && (
                  <>
                    <div>{deal.brand?.name}</div>
                    <div>
                      <span>Multiple Locations</span>
                    </div>
                  </>
                )}
                <RedeemDealButton
                  className={`${
                    isEnteredContest()
                      ? "green-outline-continue"
                      : "green-continue"
                  } rounded-10 small`}
                >
                  {deal.contestDeal ? (
                    <ButtonText isMobile={isMobile}>
                      {renderContestButtonText()}
                    </ButtonText>
                  ) : (
                    <ButtonText data-notranslate isMobile={isMobile}>
                      <FormattedMessage
                        id="app.deal.redeem"
                        defaultMessage={"Redeem"}
                      />
                    </ButtonText>
                  )}
                </RedeemDealButton>
              </DealInfo>
            </>
          ) : (
            <>
              <TopSection>
                {translateDescription()}
                <DealImageSection isMobile={isMobile}>
                  {team && (
                    <TeamImage
                      isMobile={isMobile}
                      src={team.logo}
                      alt={`${team.name} logo`}
                    />
                  )}
                  <DealImageContainer isMobile={isMobile}>
                    <DealImage
                      isMobile={isMobile}
                      src={deal.picture}
                      alt={deal.description}
                    />
                  </DealImageContainer>
                </DealImageSection>
              </TopSection>
              <DealInfo isMobile={isMobile}>
                {deal.sponsor && (
                  <>
                    <div>{deal.sponsor?.name}</div>
                    <div>
                      {deal.sponsor?.address === "Multiple Locations" ? (
                        <span>Multiple Locations</span>
                      ) : (
                        deal.sponsor?.address
                      )}
                    </div>
                  </>
                )}
                {deal.brand !== undefined && (
                  <>
                    <div>{deal.brand?.name}</div>
                    <div>
                      <span>Multiple Locations</span>
                    </div>
                  </>
                )}
              </DealInfo>

              <Button
                className={`${
                  isEnteredContest()
                    ? "green-outline-continue"
                    : "green-continue"
                } rounded-10 wide`}
              >
                {deal.contestDeal ? (
                  <ButtonText isMobile={isMobile}>
                    {renderContestButtonText()}
                  </ButtonText>
                ) : (
                  <ButtonText data-notranslate isMobile={isMobile}>
                    <FormattedMessage
                      id="app.deal.redeem"
                      defaultMessage={"Redeem"}
                    />
                  </ButtonText>
                )}
              </Button>
            </>
          )}
        </CardBody>
      </DealCardLink>
    </CardContainer>
  );
};

export default withRouter(withFirebase(withAuthentication(DealMapCard)));
