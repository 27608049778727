import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import {
  withAuthentication,
  isAdmin,
  isTeam,
} from "../../../../containers/Session";

import "./OfferButton.scss";

function OfferButton({
  authUser,
  selectedOfferKey,
  sponsorKey,
  offer,
  onClick,
  onToggleActiveClick,
  onTogglePublishClick,
  onTeamActivateClick,
}) {
  let publishedActiveState = "archived";
  let publishedActiveStateTitle = "Archived";

  if (offer.published) {
    publishedActiveState = "published-and-not-active";
    publishedActiveStateTitle = "Published but not Active";

    if (offer.active) {
      publishedActiveState = "published-and-active";
      publishedActiveStateTitle = "Published and Active";
    }
  }

  return (
    <div
      id={offer.key}
      key={offer.key}
      className={`component-offer-button ${
        selectedOfferKey === offer.key ? "selected" : ""
      }`}
      onClick={onClick}
    >
      <div className="description">{offer.description}</div>
      <div className="attributes">
        <span
          className={`status-circle ${publishedActiveState}`}
          title={publishedActiveStateTitle}
        />
        {offer.exclusive && (
          <>
            <i className="fa fa-lock" title="Exclusive Deal" />{" "}
          </>
        )}
        {offer.flashDeal && (
          <>
            <i className="fa fa-bolt" title="Flash Deal" />{" "}
          </>
        )}
        {offer.triggered && (
          <>
            <i className="fa fa-clock-o" title="Triggered Deal" />{" "}
          </>
        )}
      </div>
      <div className="actions">
        <div className="group-1-actions">
          {isAdmin(authUser) && (
            <Link to={`/portal/sponsors/${sponsorKey}/deals/${offer.key}/edit`}>
              Edit
            </Link>
          )}
        </div>
        <div className="group-2-actions">
          {isAdmin(authUser) && (
            <>
              {offer.published && (
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    onToggleActiveClick();
                  }}
                >
                  {offer.active ? "Deactivate" : "Activate"}
                </Link>
              )}
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  onTogglePublishClick();
                }}
              >
                {offer.published ? "Archive" : "Publish"}
              </Link>
            </>
          )}
          {isTeam(authUser) &&
            offer.published &&
            !offer.active &&
            offer.triggered && (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  onTeamActivateClick();
                }}
              >
                Activate
              </Link>
            )}
        </div>
      </div>
    </div>
  );
}

OfferButton.propTypes = {
  authUser: PropTypes.object,
  selectedOfferKey: PropTypes.string,
  sponsorKey: PropTypes.string,
  offer: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onToggleActiveClick: PropTypes.func,
};

export default withAuthentication(OfferButton);
