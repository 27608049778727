import React from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import ForgotPassword from "./ForgotPassword";
import UpdatePassword from "./UpdatePassword";
import { useLocation, useHistory } from "react-router-dom";

const PasswordSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-height: 600px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const EditButton = styled.button`
  display: flex;
  justify-content: space-between;
  max-width: 493px;
  width: 100%;
  height: fit-content;
  background: ${COLORS.primaryWhite};
  border-radius: 10px;
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primaryBlue};
`;

const ButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
`;

const ButtonTitleGroup = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Disclaimer = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.mediumDarkGrey};
  max-width: 493px;
  width: 100%;
`;

const PasswordSection = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const subTab = params.get("subTab");

  const handleSubTabClick = (subTab) => {
    params.set("subTab", subTab);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  return (
    <PasswordSectionContainer>
      {!subTab && (
        <>
          <Title>Profile / Password</Title>
          <EditButton
            onClick={(e) => {
              e.preventDefault();
              handleSubTabClick("forgotPassword");
            }}
          >
            <ButtonTitleGroup>
              <Icon
                icon="mdi:shield-lock"
                width={24}
                color={COLORS.primaryGreenDark}
              />
              <ButtonTitle>Forgot Password</ButtonTitle>
            </ButtonTitleGroup>
            <Icon
              icon="ic:outline-keyboard-arrow-right"
              width={30}
              color={COLORS.primaryGreenDark}
            />
          </EditButton>
          <EditButton
            onClick={(e) => {
              e.preventDefault();
              handleSubTabClick("updatePassword");
            }}
          >
            <ButtonTitleGroup>
              <Icon
                icon="mdi:shield-lock"
                width={24}
                color={COLORS.primaryGreenDark}
              />
              <ButtonTitle>Change Password</ButtonTitle>
            </ButtonTitleGroup>
            <Icon
              icon="ic:outline-keyboard-arrow-right"
              width={30}
              color={COLORS.primaryGreenDark}
            />
          </EditButton>
          <Disclaimer>
            If you signed in using Google, Apple, or Facebook, you must change
            your password through the settings on the respective platform.
          </Disclaimer>
        </>
      )}
      {subTab === "forgotPassword" && <ForgotPassword />}
      {subTab === "updatePassword" && <UpdatePassword />}
    </PasswordSectionContainer>
  );
};

export default PasswordSection;
