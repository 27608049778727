/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";

import FriendlyDateTime from "../../../containers/FriendlyDateTime";
import { Button, DefaultContainer } from "../../../containers/UIKit";
import LoginModal from "../../../containers/LoginModal";

import "./List.scss";

const List = ({ firebase, authUser }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [loadingPurchases, setLoadingPurchases] = useState(true);

  function loadPurchases() {
    if (!authUser) {
      return;
    }

    const userPurchasesPath = `user/${authUser.key}/purchases`;
    const userPurchasesRef = firebase.database.ref(userPurchasesPath);

    userPurchasesRef
      .once("value")
      .then((userPurchaseSnapshots) => {
        const purchasePromises = [];

        userPurchaseSnapshots.forEach((userPurchaseSnapshot) => {
          const userPurchase = userPurchaseSnapshot.val();
          userPurchase.key = userPurchaseSnapshot.key;

          const purchasePath = `/purchase/${userPurchase.key}`;
          const purchaseRef = firebase.database.ref(purchasePath);

          purchasePromises.push(purchaseRef.once("value"));
        });

        return Promise.all(purchasePromises);
      })
      .then((purchaseSnapshots) => {
        const purchases = [];

        purchaseSnapshots.forEach((purchaseSnapshot) => {
          const purchase = purchaseSnapshot.val();
          purchase.key = purchaseSnapshot.key;
          purchases.push(purchase);
        });

        setPurchases(purchases);
        setLoadingPurchases(false);
      });
  }

  function handleSignInClick(e) {
    e.preventDefault();
    setShowLoginModal(true);
  }

  function hideLoginModal() {
    setShowLoginModal(false);
  }

  useEffect(() => {
    loadPurchases();
  }, []);

  useEffect(() => {
    loadPurchases();
  }, [authUser]);

  return (
    <DefaultContainer>
      <div className="views-purchases-list">
        <h1>My Purchases</h1>
        {authUser ? (
          loadingPurchases ? (
            <div>Loading purchases...</div>
          ) : purchases.length === 0 ? (
            <div className="no-purchases">
              You have not purchased anything. If you'd like to purchase a deal,
              you can browse them <Link to="/deals">here</Link>.
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Deal</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {purchases.map((purchase) => {
                  const createdAtMoment = moment(purchase.createdAt);
                  return (
                    <tr key={purchase.key}>
                      <td>
                        <FriendlyDateTime timestamp={purchase.createdAt} />
                      </td>
                      <td>
                        <span className="offer-description">
                          {purchase.offer.description}
                        </span>
                        {purchase.sponsor && (
                          <>
                            <br />
                            <span data-notranslate>
                              {purchase.sponsor.name}
                            </span>
                            <br />
                            <span data-notranslate>
                              {purchase.sponsor.address}
                            </span>
                          </>
                        )}
                      </td>
                      <td>
                        <Link to={`/deals/${purchase.offerKey}`}>
                          View Original Deal
                        </Link>
                      </td>
                      <td>
                        {purchase.redeemed ? (
                          <>
                            Redeemed on{" "}
                            <FriendlyDateTime timestamp={purchase.redeemedAt} />
                          </>
                        ) : (
                          <Button
                            routerLink={true}
                            to={`/purchases/${purchase.key}`}
                            className="primary"
                          >
                            Redeem
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )
        ) : (
          <>
            <div className="sign-in-instructions">
              To view your purchases, please sign in.
            </div>
            <div className="sign-in-button-container">
              <Button className="green" onClick={handleSignInClick}>
                Sign in
              </Button>
            </div>
            {showLoginModal && (
              <LoginModal onClose={hideLoginModal} mode={"login"} />
            )}
          </>
        )}
      </div>
    </DefaultContainer>
  );
};

export default withAuthentication(withFirebase(List));
