/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Icon } from "@iconify/react";
import useLanguageStore from "../../stores/useLanguageStore";

const LanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  min-height: 600px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const ToggleButton = styled.button`
  display: flex;
  justify-content: space-between;
  max-width: 493px;
  width: 100%;
  height: fit-content;
  background: ${COLORS.primaryWhite};
  border-radius: 10px;
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.primaryBlue};
`;

const ButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
`;

const languageOptions = [
  { label: "English", value: "English" },
  { label: "Français", value: "Français" },
];

const LanguageEdit = ({ firebase, authUser }) => {
  const [userLanguage, setUserLanguage] = useState("English");
  const setLanguage = useLanguageStore((state) => state.setLanguage);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("fanSavesLanguagePreference");
    if (authUser?.languagePreference) {
      setUserLanguage(authUser.languagePreference);
      setLanguage(authUser.languagePreference === "English" ? "en" : "fr");
    } else if (storedLanguage) {
      setUserLanguage(storedLanguage);
      setLanguage(storedLanguage === "English" ? "en" : "fr");
    }
  }, [authUser]);

  useEffect(() => {
    localStorage.setItem("fanSavesLanguagePreference", userLanguage);
    const lang = userLanguage === "English" ? "en" : "fr";
    setLanguage(lang);
    window.lzSwitchLanguage && window.lzSwitchLanguage(lang);
  }, [userLanguage]);

  const updateLanguagePreference = (newLanguage) => {
    setUserLanguage(newLanguage);

    if (authUser) {
      firebase.database
        .ref(`user/${authUser.key}/languagePreference`)
        .set(newLanguage)
        .catch((err) => {
          console.error("Error updating language preference in database:", err);
        });
    }
  };

  return (
    <LanguageContainer>
      <Title>Profile / Language</Title>
      {languageOptions.map((option) => (
        <ToggleButton
          key={option.value}
          onClick={() => updateLanguagePreference(option.value)}
        >
          <ButtonTitle>
            <span data-notranslate>{option.label}</span>
          </ButtonTitle>
          {userLanguage === option.value && (
            <Icon
              icon="akar-icons:check"
              style={{ color: COLORS.primaryGreen }}
              width={24}
            />
          )}
        </ToggleButton>
      ))}
    </LanguageContainer>
  );
};

export default withFirebase(withAuthentication(LanguageEdit));
