import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { withFirebase } from "./containers";
import { withAuthentication } from "./containers/Session";
import { publicUserRoutes } from "./routes";
import ScrollToTop from "./containers/ScrollToTop";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LanguageProvider from "./i18n/LanguageProvider";

const queryClient = new QueryClient();

const App = () => {
  const routeComponentControl = () => {
    return publicUserRoutes.map((route, idx) => {
      return route.component ? (
        <Route
          key={idx}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
  };

  return (
    <LanguageProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>{routeComponentControl()}</Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </LanguageProvider>
  );
};

export default withFirebase(withAuthentication(App));
