import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import {
  FlexContainer,
  DashboardButton,
  DashboardTitle,
} from "../../assets/fansaves-ui";

import { DashboardHeader } from "../../containers/UIKit";

const DashboardSpecialCodesHeader = ({ team, history, title }) => {
  const teamId = team && team.key;
  return (
    <FlexContainer
      gap="20px"
      alignItems="flex-start"
      className="header-title"
      width="100%"
      margin="30px 0"
    >
      <DashboardTitle
        title={title}
        justifyContent="flex-start"
        // backButtonPath={teamId ? `/portal/teams/${teamId}/dashboard` : ""}
        backButtonPath={
          teamId
            ? title === "All Codes"
              ? `/portal/teams/${teamId}/dashboard`
              : `/portal/teams/${teamId}/special-offers-codes`
            : ""
        }
        className="standard-h5 blue"
      />
      {team && (
        <DashboardHeader
          logo={team.logo}
          name={team.name}
          email={team.displayEmail}
          profileUrl={team.website}
          hideEl
          className="logo-codes-list-header"
        />
      )}

      <FlexContainer
        direction="horizontal"
        gap="20px"
        responsive
        alignItems="center"
        justifyContent="space-evenly"
      >
        <DashboardButton
          title="All Codes"
          type={title === "All Codes" ? "shadow-green" : "shadow-white-green"}
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/portal/teams/${teamId}/special-offers-codes`);
          }}
        />
        <DashboardButton
          title="Exclusive Codes"
          type={
            title === "Exclusive Codes" ? "shadow-green" : "shadow-white-green"
          }
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/portal/teams/${teamId}/special-offers-codes/exclusive-codes`
            );
          }}
        />
        <DashboardButton
          title="Player Codes"
          type={
            title === "Player Codes" ? "shadow-green" : "shadow-white-green"
          }
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/portal/teams/${teamId}/special-offers-codes/player-codes`
            );
          }}
        />
        <DashboardButton
          title="Flex Pass Codes"
          type={
            title === "Flex Pass Codes" ? "shadow-green" : "shadow-white-green"
          }
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/portal/teams/${teamId}/special-offers-codes/flex-pass-codes`
            );
          }}
        />
        <DashboardButton
          title="Group Pass Codes"
          type={
            title === "Group Pass Codes" ? "shadow-green" : "shadow-white-green"
          }
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/portal/teams/${teamId}/special-offers-codes/group-pass-codes`
            );
          }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default withRouter(DashboardSpecialCodesHeader);
