import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from 'uuid';

export default class SubCategory {
  constructor(database, category, name) {
    this.id = uuidv4(); // Generate a unique ID for rendering & querying purposes
    this.database = database;
    this.name = name;
    this.parentCategory = category;

    this.isEditing = false;
    this.newSubCategoryName = name;

    makeAutoObservable(this, {}, {autoBind: true});
  }

  editButtonOnClick(){
    this.isEditing = true;
  }

  newSubCategoryNameOnChange(newName){
    this.newSubCategoryName = newName;
  }

  async saveSubCategoryNameOnClick(){
    runInAction(() => {
      this.name = this.newSubCategoryName;
      this.isEditing = false;
    });
    await this.parentCategory.saveSubCategoriesToDatabase();
  }

  async removeSubCategoryButtonOnClick(){
    await this.parentCategory.removeSubCategory(this.id);
  }
};