import React from "react";
import Select from "react-select";
import { Form as ReactStrapForm, FormGroup, Label, Input } from "reactstrap";
import { dbLoadSponsors } from "../../../../containers/DatabaseHelpers";
import { withFirebase } from "../../../../containers/Firebase";
import ImageUploader from "../../../../containers/ImageUploader";
import {
  imageUploadInputStyle,
  imageStyle,
  labelStyle,
  inputStyle,
} from "../../utilities/PortalFormStyles";
import CategorySelector from "../../../../containers/CategorySelector";

class Form extends React.Component {
  constructor(props) {
    super(props);

    let initialState = {
      title: "",
      shortName: "",
      description: "",
      slug: "",
      sport: "",
      league: "",
      division: "",
      teamWebsite: "",
      buyTicketsLink: "",
      teamAppLinkiOS: "",
      teamAppLinkAndroid: "",
      facebook: "",
      instagram: "",
      snapchat: "",
      twitter: "",
      dashUrl: "",
      arenaName: "",
      arenaAddress: "",
      city: "",
      lat: "",
      long: "",
      newsRssFeed: "",
      emblem: "",
      emblemFile: null,
      emblemPath: null,
      logo: "",
      logoFile: null,
      logoPath: null,
      teamPhoto: "",
      teamPhotoFile: null,
      teamPhotoPath: null,
      teamSponsors: [],
      archived: false,
      keywords: "",
      categories: [],
      subcategories: [],
    };

    const { existingTeam } = props;

    if (existingTeam) {
      initialState = {
        title: existingTeam.name ? existingTeam.name : "",
        shortName: existingTeam["short name"] ? existingTeam["short name"] : "",
        description: existingTeam.description ? existingTeam.description : "",
        slug: existingTeam.slug ? existingTeam.slug : "",
        sport: existingTeam.sport ? existingTeam.sport : "",
        league: existingTeam.league ? existingTeam.league : "",
        division: existingTeam.division ? existingTeam.division : "",
        teamWebsite: existingTeam.website ? existingTeam.website : "",
        buyTicketsLink: existingTeam["buy tickets link"]
          ? existingTeam["buy tickets link"]
          : "",
        teamAppLinkiOS: existingTeam["app iOS"] ? existingTeam["app iOS"] : "",
        teamAppLinkAndroid: existingTeam["app android"]
          ? existingTeam["app android"]
          : "",
        facebook: existingTeam["social fb"] ? existingTeam["social fb"] : "",
        instagram: existingTeam["social instagram"]
          ? existingTeam["social instagram"]
          : "",
        snapchat: existingTeam["social snapchat"]
          ? existingTeam["social snapchat"]
          : "",
        twitter: existingTeam["social twitter"]
          ? existingTeam["social twitter"]
          : "",
        dashUrl: existingTeam["dashUrl"] ? existingTeam["dashUrl"] : "",
        arenaName: existingTeam["arena name"] ? existingTeam["arena name"] : "",
        arenaAddress: existingTeam["arena address"]
          ? existingTeam["arena address"]
          : "",
        city: existingTeam.city ? existingTeam.city : "",
        lat: existingTeam.lat ? existingTeam.lat : "",
        long: existingTeam.long ? existingTeam.long : "",
        newsRssFeed: existingTeam["newsRssFeed"]
          ? existingTeam["newsRssFeed"]
          : "",
        emblemPath: existingTeam.emblem ? existingTeam.emblem : null,
        logoPath: existingTeam.logo ? existingTeam.logo : null,
        teamPhotoPath: existingTeam.picture ? existingTeam.picture : null,
        teamSponsors:
          existingTeam.sponsors && existingTeam.sponsors.length > 0
            ? existingTeam.sponsors
            : [],
        archived: existingTeam.archived ? existingTeam.archived : false,
        keywords: existingTeam.keywords || "",
        categories: Array.isArray(existingTeam.categories)
          ? existingTeam.categories
          : [],
        subcategories: Array.isArray(existingTeam.subcategories)
          ? existingTeam.subcategories
          : [],
      };
    }

    initialState.sponsorsForSelect = [];

    this.state = initialState;
  }

  componentDidMount() {
    this.loadAllSponsors();
  }

  loadAllSponsors = () => {
    const { firebase } = this.props;

    dbLoadSponsors(firebase, (sponsors) => {
      const allSponsorsMap = {};
      sponsors.forEach((sponsor) => {
        allSponsorsMap[sponsor.key] = sponsor;
      });

      const sponsorsForSelect = sponsors
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((sponsor) => ({ value: sponsor.key, label: sponsor.name }));

      this.setState({ allSponsorsMap, sponsorsForSelect }, this.mapSponsorList);
    });
  };

  mapSponsorList = () => {
    const { teamSponsors, allSponsorsMap } = this.state;

    if (teamSponsors.length < 1 || !allSponsorsMap) return;

    var defaultValue = [];
    teamSponsors.forEach((sponsorKey) => {
      var sponsor = allSponsorsMap[sponsorKey];
      if (sponsor) {
        defaultValue.push({ value: sponsorKey, label: sponsor.name });
      }
    });

    this.setState({ selectedOption: defaultValue });
    this.handleSelectChange(defaultValue);
  };

  handleUserInput = (e) => {
    const { target } = e;
    const name = target.name;
    let value = target.value;
    const files = target.files;

    if (files) {
      let fileName = name + "File";
      let filePath = name + "Path";
      let file = files[0];
      this.setState({ [fileName]: file });
      this.setState({ [filePath]: URL.createObjectURL(file) });
    } else {
      if (name === "slug") {
        value = value.toLowerCase().replace(/\s/g, "");
      }

      if (target.type === "checkbox") {
        value = target.checked;
      }

      this.setState({ [name]: value });
    }
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption });
    var sponsorKeys = [];
    selectedOption.forEach((option) => {
      sponsorKeys.push(option.value);
    });
    this.setState({ teamSponsors: sponsorKeys });
  };

  emblemClick() {
    document.getElementById("emblem_upload").click();
  }

  logoClick() {
    document.getElementById("logo_upload").click();
  }

  teamPhotoClick() {
    document.getElementById("teamphoto_upload").click();
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { onSubmit } = this.props;
    const {
      title,
      shortName,
      description,
      slug,
      sport,
      league,
      division,
      teamWebsite,
      buyTicketsLink,
      teamAppLinkiOS,
      teamAppLinkAndroid,
      facebook,
      instagram,
      snapchat,
      twitter,
      dashUrl,
      arenaName,
      arenaAddress,
      city,
      lat,
      long,
      newsRssFeed,
      emblem,
      emblemPath,
      emblemFile,
      logo,
      logoPath,
      logoFile,
      teamPhoto,
      teamPhotoPath,
      teamPhotoFile,
      teamSponsors,
      archived,
      keywords,
      categories,
      subcategories,
    } = this.state;

    const teamData = {
      title,
      shortName,
      description,
      slug,
      sport,
      league,
      division,
      teamWebsite,
      buyTicketsLink,
      teamAppLinkiOS,
      teamAppLinkAndroid,
      facebook,
      instagram,
      snapchat,
      twitter,
      dashUrl,
      arenaName,
      arenaAddress,
      city,
      lat,
      long,
      newsRssFeed,
      emblem,
      emblemFile,
      emblemPath,
      logo,
      logoFile,
      logoPath,
      teamPhoto,
      teamPhotoFile,
      teamPhotoPath,
      teamSponsors,
      archived,
      keywords,
      categories,
      subcategories,
    };

    onSubmit(teamData);
  };

  render() {
    const { sponsorsForSelect } = this.state;

    return (
      <div className="animated fadeIn" id="main">
        <ReactStrapForm onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-4 align-bottom">
              <FormGroup className="text-center">
                <Label style={labelStyle}>
                  <strong>EMBLEM</strong>
                </Label>
                <ImageUploader
                  src={this.state.emblemPath}
                  style={imageStyle}
                  onClick={this.emblemClick}
                  alt="Emblem"
                />
                <input
                  name="emblem"
                  onChange={this.handleUserInput}
                  id="emblem_upload"
                  type="file"
                  style={imageUploadInputStyle}
                />
              </FormGroup>
            </div>
            <div className="col-4">
              <FormGroup className="text-center">
                <Label style={labelStyle}>
                  <strong>LOGO</strong>
                </Label>
                <ImageUploader
                  src={this.state.logoPath}
                  style={imageStyle}
                  onClick={this.logoClick}
                  alt="Logo"
                />
                <Input
                  name="logo"
                  onChange={this.handleUserInput}
                  id="logo_upload"
                  type="file"
                  style={imageUploadInputStyle}
                />
              </FormGroup>
            </div>
            <div className="col-4">
              <FormGroup className="text-center">
                <Label style={labelStyle}>
                  <strong>TEAM PHOTO</strong>
                </Label>
                <ImageUploader
                  src={this.state.teamPhotoPath}
                  style={imageStyle}
                  onClick={this.teamPhotoClick}
                  alt="Team"
                />
                <Input
                  name="teamPhoto"
                  onChange={this.handleUserInput}
                  id="teamphoto_upload"
                  type="file"
                  style={imageUploadInputStyle}
                />
              </FormGroup>
            </div>
          </div>

          <div className="form-section">
            <h2>Basic Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TITLE (Full Name)</Label>
                  <Input
                    name="title"
                    value={this.state.title}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>SHORT NAME</Label>
                  <Input
                    name="shortName"
                    value={this.state.shortName}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>DESCRIPTION</Label>
                  <Input
                    name="description"
                    value={this.state.description}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>SLUG</Label>
                  <Input
                    name="slug"
                    value={this.state.slug}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>KEYWORDS</Label>
                  <Input
                    name="keywords"
                    value={this.state.keywords}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Sport Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>SPORT</Label>
                  <Input
                    name="sport"
                    value={this.state.sport}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LEAGUE</Label>
                  <Input
                    name="league"
                    value={this.state.league}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>DIVISION</Label>
                  <Input
                    name="division"
                    value={this.state.division}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>External Links</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TEAM WEBSITE</Label>
                  <Input
                    name="teamWebsite"
                    value={this.state.teamWebsite}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>BUY TICKETS LINK</Label>
                  <Input
                    name="buyTicketsLink"
                    value={this.state.buyTicketsLink}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TEAM APP LINK (APPLE STORE)</Label>
                  <Input
                    name="teamAppLinkiOS"
                    value={this.state.teamAppLinkiOS}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TEAM APP LINK (GOOGLE PLAY STORE)</Label>
                  <Input
                    name="teamAppLinkAndroid"
                    value={this.state.teamAppLinkAndroid}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>FACEBOOK</Label>
                  <Input
                    name="facebook"
                    value={this.state.facebook}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>INSTAGRAM</Label>
                  <Input
                    name="instagram"
                    value={this.state.instagram}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TIKTOK</Label>
                  <Input
                    name="snapchat"
                    value={this.state.snapchat}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>TWITTER</Label>
                  <Input
                    name="twitter"
                    value={this.state.twitter}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>DASH</Label>
                  <Input
                    name="dashUrl"
                    value={this.state.dashUrl}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Arena Info</h2>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>ARENA NAME</Label>
                  <Input
                    name="arenaName"
                    value={this.state.arenaName}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>ARENA ADDRESS</Label>
                  <Input
                    name="arenaAddress"
                    value={this.state.arenaAddress}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>CITY</Label>
                  <Input
                    name="city"
                    value={this.state.city}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LATITUDE</Label>
                  <Input
                    name="lat"
                    value={this.state.lat}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup>
                  <Label>LONGITUDE</Label>
                  <Input
                    name="long"
                    value={this.state.long}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>News Tab</h2>
            <div className="row">
              <div className="col-sm-12">
                <FormGroup>
                  <Label>RSS Feed</Label>
                  <Input
                    name="newsRssFeed"
                    value={this.state.newsRssFeed}
                    onChange={this.handleUserInput}
                    style={inputStyle}
                  ></Input>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Businesses</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <Label>SELECT BUSINESSES</Label>
                <Select
                  value={this.state.selectedOption}
                  onChange={this.handleSelectChange}
                  options={sponsorsForSelect}
                  isMulti={true}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderColor: state.isFocused ? "#464646" : "#b3b3b3",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Category</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <Label>SELECT CATEGORY AND SUBCATEGORY</Label>
                <CategorySelector
                  categories={this.state.categories}
                  subcategories={this.state.subcategories}
                  categoriesOnUpdate={(categoryNameArray) =>
                    this.setState({ categories: categoryNameArray })
                  }
                  subcategoriesOnUpdate={(subCategoryNameArray) =>
                    this.setState({ subcategories: subCategoryNameArray })
                  }
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Visibility</h2>
            <div className="row">
              <div className="col-12 mb-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="archived"
                      checked={this.state.archived}
                      onChange={this.handleUserInput}
                      style={inputStyle}
                    />{" "}
                    Archived
                  </Label>
                </FormGroup>
              </div>
            </div>
          </div>

          <div className="form-section">
            <div className="row">
              <div className="col-12">
                <Input type="submit" value="Submit" style={inputStyle} />
              </div>
            </div>
          </div>
        </ReactStrapForm>
      </div>
    );
  }
}

export default withFirebase(Form);
