/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import styled from "styled-components/macro";

import DashboardDealCard from "../DashboardDealCard";
import { withAuthentication } from "../Session";
import { withRouter } from "react-router-dom";
import { isAdmin, isTeam, isSponsor } from "../Session";
import DashboardDealsListlToggle from "../DashboardDealsListlToggle";
import {
  DashboardButton,
  DashboardTitle,
  FlexContainer,
  COLORS,
} from "../../assets/fansaves-ui";

const StyledDashboardDealList = styled.div`
  margin-top: ${(props) => (props.expanded ? "60px" : "30px")};
  .filter-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    margin-top: 30px;

    .filter-section-button {
      width: 186px;
      height: 30px;
      border-radius: 10px;
      .count {
        border: 1px solid ${COLORS.primaryBlue};
        border-radius: 500px;
        padding: 2px 12px;
        width: 20vw;
        height: 20vw;
        position: relative;
        margin-left: 10px;
        .count-text {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
      .count.blue {
        border-color: ${COLORS.primaryWhite};
        color: ${COLORS.primaryWhite};
      }
      .count-text.blue-text {
        color: ${COLORS.primaryWhite};
        .blue {
          border-color: ${COLORS.primaryWhite};
          color: ${COLORS.primaryWhite};
        }
      }

      @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
    .view-all {
      align-self: flex-end;
    }
  }
`;

const DashboardDealList = ({
  deals,
  className,
  circledTitle,
  dealTitle,
  limit,
  viewAll,
  hideTitle,
  authUser,
  match,
  hideToggle,
  history,
}) => {
  const [filteredDeals, setFilteredDeals] = useState(deals);
  const [activeFilter, setActiveFilter] = useState("all");
  const [expanded, setExpanded] = useState(false);

  const handlefilterDeals = (filter) => {
    switch (filter) {
      case "exclusive":
        setFilteredDeals(deals.filter((deal) => deal.exclusive));
        setActiveFilter("exclusive");
        break;
      case "public":
        setFilteredDeals(deals.filter((deal) => !deal.exclusive));
        setActiveFilter("public");
        break;
      case "hidden":
        setFilteredDeals(deals.filter((deal) => deal.hiddenDeal));
        setActiveFilter("hidden");
        break;
      default:
        setFilteredDeals(deals);
        setActiveFilter("all");
        break;
    }
  };

  useEffect(() => {
    setFilteredDeals(deals);
  }, [deals]);

  const renderFilterButtons = (view) => {
    let buttonTitle;
    if (view === "exclusive") {
      buttonTitle = `Exclusive Deals <span class="count ${
        activeFilter === "exclusive" ? "blue" : ""
      }"><span class="count-text">${
        deals && deals.filter((deal) => deal.exclusive).length
      }</span></span>`;
    } else if (view === "public") {
      buttonTitle = `Public Deals <span class="count ${
        activeFilter === "public" ? "blue" : ""
      }"><span class="count-text ${
        activeFilter === "public" ? "blue-text" : ""
      }">${
        deals &&
        deals.filter((deal) => !deal.exclusive && !deal.hiddenDeal).length
      }</span></span>`;
    } else if (view === "hidden") {
      buttonTitle = `Special Deals <span class="count ${
        activeFilter === "hidden" ? "blue" : ""
      }"><span class="count-text">${
        deals && deals.filter((deal) => deal.hiddenDeal).length
      }</span></span>`;
    } else if (view === "all") {
      buttonTitle = `All Deals <span class="count ${
        activeFilter === "all" ? "blue" : ""
      }"><span class="count-text">${deals.length}</span></span>`;
    }
    return <div dangerouslySetInnerHTML={{ __html: buttonTitle }} />;
  };

  const getPath = () => {
    const teamId = match.params.team_id;
    const sponsorId = match.params.sponsor_id;
    let path;
    if (isAdmin(authUser)) {
      if (teamId) {
        path = `/portal/teams/${teamId}/deals`;
      } else if (sponsorId) {
        path = `/portal/sponsors/${sponsorId}/deals`;
      } else {
        path = `/portal/deals`;
      }
    } else if (isTeam(authUser)) {
      if (sponsorId) {
        path = `/portal/sponsors/${sponsorId}/deals`;
      } else {
        path = `/portal/teams/${authUser.teamID}/deals`;
      }
    } else if (isSponsor(authUser)) {
      path = `/portal/sponsors/${authUser.sponsorID}/deals`;
    }

    if (viewAll) {
      if (dealTitle === "Archived Deals") {
        path = path + "?filter=ArchivedDeals";
      }
    }

    return path;
  };

  const showList = () => {
    return (!hideToggle && expanded) || (hideToggle && !expanded);
  };

  return (
    <StyledDashboardDealList
      className="component-dashboard-deal-list"
      expanded={expanded}
    >
      {!hideToggle && (
        <FlexContainer alignItems="flex-start">
          <DashboardDealsListlToggle
            show={expanded}
            onShow={() =>
              setExpanded((prevState) => {
                return !prevState;
              })
            }
            className={`deal-list-toggle ${
              dealTitle === "All Deals" ? "all-deals" : ""
            }`}
          >
            <FlexContainer
              direction="horizontal"
              gap="10px"
              justifyContent="flex-start"
              className="deal-list-toggle-title standard-h9"
            >
              {dealTitle}
              <span className="toggle-number standard-h9 circle">
                {deals.length}
              </span>
            </FlexContainer>
          </DashboardDealsListlToggle>
        </FlexContainer>
      )}
      {showList() && (
        <FlexContainer className={className} direction="vertical" gap="30px">
          <FlexContainer direction="vertical" gap="50px">
            {viewAll && dealTitle !== "Archived Deals" ? (
              <div className="filter-buttons">
                <DashboardButton
                  title={renderFilterButtons("all")}
                  onClick={(e) => {
                    e.preventDefault();
                    handlefilterDeals("all");
                  }}
                  typography="inter-b1"
                  className="filter-section-button"
                  type={activeFilter === "all" ? "" : "white"}
                />
                <DashboardButton
                  title={renderFilterButtons("public")}
                  onClick={(e) => {
                    e.preventDefault();
                    handlefilterDeals("public");
                  }}
                  typography="inter-b1"
                  className="filter-section-button"
                  type={activeFilter === "public" ? "" : "white"}
                />
                <DashboardButton
                  title={renderFilterButtons("exclusive")}
                  onClick={(e) => {
                    e.preventDefault();
                    handlefilterDeals("exclusive");
                  }}
                  typography="inter-b1"
                  className="filter-section-button"
                  type={activeFilter === "exclusive" ? "" : "white"}
                />
                <DashboardButton
                  title={renderFilterButtons("hidden")}
                  onClick={(e) => {
                    e.preventDefault();
                    handlefilterDeals("hidden");
                  }}
                  typography="inter-b1"
                  className="filter-section-button"
                  type={activeFilter === "hidden" ? "" : "white"}
                />
              </div>
            ) : (
              !hideTitle && (
                <DashboardTitle
                  title={dealTitle}
                  number={deals.length}
                  circle={circledTitle}
                />
              )
            )}
            {filteredDeals && filteredDeals.length > 0 ? (
              limit ? (
                filteredDeals
                  .slice(0, limit)
                  .map((deal, index) => (
                    <DashboardDealCard
                      deal={deal}
                      key={
                        deal.key + deal.active + deal.published + deal.pinned
                      }
                    />
                  ))
              ) : (
                filteredDeals.map((deal, index) => (
                  <DashboardDealCard
                    deal={deal}
                    key={deal.key + deal.active + deal.published + deal.pinned}
                  />
                ))
              )
            ) : (
              <div className="no-deals">
                <p>No {dealTitle.toLowerCase()} yet</p>
              </div>
            )}
          </FlexContainer>

          {filteredDeals && filteredDeals.length > 0 && viewAll && (
            <DashboardButton
              title="View All"
              className="view-all"
              size="small"
              type="shadow-white"
              typography="public-h2"
              onClick={(e) => {
                e.preventDefault();
                history.push(getPath());
              }}
            />
          )}
        </FlexContainer>
      )}
    </StyledDashboardDealList>
  );
};

export default withAuthentication(withRouter(DashboardDealList));

DashboardDealList.propTypes = {
  deals: propTypes.array.isRequired,
};

DashboardDealList.defaultProps = {
  circledTitle: false,
  dealTitle: "Deals",
  limit: 0,
  viewAll: false,
  hideTitle: false,
};
