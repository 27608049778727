import styled from "styled-components/macro";
import { COLORS } from "../colors";
import { GLOBALS } from "../globals";

const DashboardCardHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  background-color: ${(props) => {
    if (props.type === "active") {
      return COLORS.primaryGreen;
    } else if (props.type === "inactive") {
      return COLORS.yellow;
    } else if (props.type === "archived") {
      return COLORS.chartRed;
    } else if (props.type === "admin") {
      return COLORS.primaryWhite;
    }
    return COLORS.primaryGreen;
  }};
  border-radius: 10px 10px 0 0;
  width: ${(props) => props.width || "max-content"};
  height: ${(props) => props.height || "35px"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  position: ${(props) => props.position || "absolute"};
  top: ${(props) => props.top || "0"};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  .content {
    color: ${(props) => props.color || COLORS.primaryWhite};
  }

  @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
    width: ${(props) =>
      props.mobileWidth ? props.mobileWidth : "max-content"};
  }
`;

export default DashboardCardHeader;
