import React, { Suspense, lazy } from "react";
import {
  FlexContainer,
  DashboardTitle,
  Text,
  CSVExport,
  SpinLoader,
} from "../../assets/fansaves-ui";
import { Icon } from "@iconify/react";
import moment from "moment";

import { DashboardHeader } from "../../containers/UIKit";

const DashboardSpecialCodeUsersTable = lazy(() =>
  import("../../containers/DashboardSpecialCodeUsersTable")
);

const DashboardSpecialCodesShowContainer = ({ specialCode, team, teamId }) => {
  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "code", key: "code" },
      { label: "user email", key: "userEmail" },
      { label: "user status", key: "status" },
      { label: "unlocked", key: "unlocked" },
      { label: "locked", key: "locked" },
    ];
  };

  const generateData = () => {
    if (!specialCode) return {};
    const data = [];
    const users = specialCode.users;
    let index = 0;
    for (const user in users) {
      const userObj = users[user];
      const unlocked = userObj.unlocked;
      const locked = userObj.locked;

      let unlockedTime = null;
      if (
        unlocked &&
        typeof unlocked === "object" &&
        unlocked.hasOwnProperty("seconds")
      ) {
        unlockedTime = unlocked.seconds * 1000 + unlocked.nanoseconds / 1000000;
      }

      let lockedTime = null;
      if (
        locked &&
        typeof locked === "object" &&
        locked.hasOwnProperty("seconds")
      ) {
        lockedTime = locked.seconds * 1000 + locked.nanoseconds / 1000000;
      }

      data.push({
        index: ++index,
        code: specialCode.code,
        userEmail: userObj.email,
        status: userObj.status,
        unlocked: unlockedTime
          ? moment(unlockedTime).format("MMM DD, YYYY HH:mm A")
          : "-",
        locked: lockedTime
          ? moment(lockedTime).format("MMM DD, YYYY HH:mm A")
          : "-",
      });
    }
    return data;
  };

  const splitAccessLevel = (accessLevel) => {
    // if flexPass groupPass return flex-pass group-pass
    return accessLevel
      .split(/(?=[A-Z])/)
      .join("-")
      .toLowerCase();
  };

  return (
    <div className="component-dashboard-special-codes-show-container">
      <FlexContainer
        gap="20px"
        alignItems="flex-start"
        className="header-title"
        width="100%"
      >
        {specialCode && (
          <DashboardTitle
            title={`${
              specialCode &&
              specialCode.accessLevel.charAt(0).toUpperCase() +
                specialCode.accessLevel.slice(1)
            } Code ${specialCode ? specialCode.code : ""}`}
            justifyContent="flex-start"
            backButtonPath={
              teamId
                ? `/portal/teams/${teamId}/special-offers-codes/${splitAccessLevel(
                    specialCode.accessLevel
                  )}-codes`
                : ""
            }
            className="standard-h5 blue"
          />
        )}

        {team && (
          <DashboardHeader
            logo={team.logo}
            name={team.name}
            email={team.displayEmail}
            profileUrl={team.website}
            hideEl
            className="codes-list-header"
          />
        )}

        <div className="link-container">
          {specialCode && (
            <CSVExport
              data={generateData()}
              headers={generateHeaders()}
              filename={
                team && team.name
                  ? `${team.name}-${specialCode.accessLevel}-code-${
                      specialCode.id
                    }-users-${moment().format("YYYY-MM-DD")}`
                  : `${specialCode.accessLevel}-code-${
                      specialCode.id
                    }-users-${moment().format("YYYY-MM-DD")}`
              }
              target="_blank"
              className="rubik-h3"
            >
              <Icon icon="fa-solid:file-export" className="user-logo-icon" />{" "}
              Export Data
            </CSVExport>
          )}
        </div>
      </FlexContainer>
      <FlexContainer>
        {specialCode ? (
          <FlexContainer
            justifyContent="flex-start"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text type="rubik-h6">
              Status:{" "}
              {specialCode
                ? specialCode.status
                  ? specialCode.status
                  : "N/A"
                : "N/A"}
            </Text>
            <Text type="rubik-h6">
              Used: {specialCode ? specialCode.used : 0}
            </Text>
            <Text type="rubik-h6">
              Limit:{" "}
              {specialCode
                ? specialCode.limit
                  ? specialCode.limit
                  : "Unlimited"
                : "Unlimited"}
            </Text>
            <Suspense fallback={<SpinLoader />}>
              <DashboardSpecialCodeUsersTable specialCode={specialCode} />
            </Suspense>
          </FlexContainer>
        ) : (
          <SpinLoader />
        )}
      </FlexContainer>
    </div>
  );
};

export default DashboardSpecialCodesShowContainer;
