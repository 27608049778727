import React, { useState, useEffect } from "react";
import { withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";

import EditableInput from "./EditableInput";

const FirstLastNameEdit = ({ authUser, firebase }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    if (authUser) {
      setFirstName(authUser.firstName || "");
      setLastName(authUser.lastName || "");
    }
  }, [authUser]);

  return (
    <>
      <EditableInput
        label="First Name"
        value={firstName}
        onChange={setFirstName}
        onSave={(value) => setFirstName(value)}
        authUser={authUser}
        firebase={firebase}
      />
      <EditableInput
        label="Last Name"
        value={lastName}
        onChange={setLastName}
        onSave={(value) => setLastName(value)}
        authUser={authUser}
        firebase={firebase}
      />
    </>
  );
};

export default withAuthentication(withFirebase(FirstLastNameEdit));
