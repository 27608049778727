import React, { useState, useEffect, lazy, Suspense } from "react";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../../containers/Firebase";

//components
import {
  FlexContainer,
  DashboardTitle,
  Text,
  CSVExport,
  GLOBALS,
  SpinLoader,
} from "../../../../../assets/fansaves-ui";
import { Icon } from "@iconify/react";
import moment from "moment";
import { DashboardHeader } from "../../../../../containers/UIKit";

import useTeamsStore from "../../../../../stores/teamsStore";
import useExclusiveCodesStore from "../../../../../stores/exclusiveCodesStore";

const DashboardExclusiveCodeUsersTable = lazy(() =>
  import("../../../../../containers/DashboardExclusiveCodeUsersTable")
);

const ExclusiveCodeShowContainer = styled.div`
  .header-title {
    position: relative;
    .link-container {
      position: absolute;
      top: 26px;
      right: 10px;
    }
    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      .link-container {
        position: relative;
        margin-left: 30px;
      }
    }
  }
`;

const ExclusiveCodeShow = ({ match, firebase }) => {
  const teamId = match.params.team_id;
  const exclusiveCodeId = match.params.exclusive_code_id;

  const teamFromStore = useTeamsStore((state) => state.team);
  const fetchTeam = useTeamsStore((state) => state.fetchTeam);

  const useExclusiveCode = useExclusiveCodesStore(
    (state) => state.useExclusiveCode
  );

  const {
    data: exclusiveCode,
    isLoading: exclusiveCodeIsLoading,
    isError: exclusiveCodeIsError,
  } = useExclusiveCode(firebase, teamId, exclusiveCodeId);

  const [team, setTeam] = useState(null);

  useEffect(() => {
    if (teamFromStore && teamFromStore[teamId]) {
      setTeam(teamFromStore[teamId]);
    }
  }, [teamFromStore, teamId]);

  useEffect(() => {
    (!team || team.key !== teamId) && fetchTeam(firebase, teamId);
  }, [team, teamId, fetchTeam, firebase]);

  const generateHeaders = () => {
    return [
      { label: "#", key: "index" },
      { label: "code", key: "code" },
      { label: "user email", key: "userEmail" },
      { label: "user status", key: "status" },
      { label: "unlocked", key: "unlocked" },
      { label: "locked", key: "locked" },
    ];
  };

  const generateData = () => {
    if (!exclusiveCode) return {};
    const data = [];
    const users = exclusiveCode.users;
    let index = 0;
    for (const user in users) {
      const userObj = users[user];
      const unlocked = userObj.unlocked;
      const locked = userObj.locked;

      let unlockedTime = null;
      if (
        unlocked &&
        typeof unlocked === "object" &&
        unlocked.hasOwnProperty("seconds")
      ) {
        unlockedTime = unlocked.seconds * 1000 + unlocked.nanoseconds / 1000000;
      }

      let lockedTime = null;
      if (
        locked &&
        typeof locked === "object" &&
        locked.hasOwnProperty("seconds")
      ) {
        lockedTime = locked.seconds * 1000 + locked.nanoseconds / 1000000;
      }

      data.push({
        index: ++index,
        code: exclusiveCode.code,
        userEmail: userObj.email,
        status: userObj.status,
        unlocked: unlockedTime
          ? moment(unlockedTime).format("MMM DD, YYYY HH:mm A")
          : "-",
        locked: lockedTime
          ? moment(lockedTime).format("MMM DD, YYYY HH:mm A")
          : "-",
      });
    }
    return data;
  };

  return (
    <ExclusiveCodeShowContainer animated fadeIn>
      <FlexContainer
        alignItems="space-between"
        width="100%"
        className="exclusive-code-header"
        margin="30px 0"
      >
        <FlexContainer
          gap="20px"
          alignItems="flex-start"
          className="header-title"
          width="100%"
        >
          {(exclusiveCode || exclusiveCodeId) && (
            <DashboardTitle
              title={`Exclusive Code ${
                exclusiveCode ? exclusiveCode.code : ""
              }`}
              justifyContent="flex-start"
              backButtonPath={
                teamId
                  ? `/portal/teams/${teamId}/special-offers-codes/exclusive-codes`
                  : ""
              }
              className="standard-h5 blue"
            />
          )}

          {team && (
            <DashboardHeader
              logo={team.logo}
              name={team.name}
              email={team.displayEmail}
              profileUrl={team.website}
              hideEl
              className="codes-list-header"
            />
          )}

          <div className="link-container">
            {exclusiveCode && (
              <CSVExport
                data={generateData()}
                headers={generateHeaders()}
                filename={
                  team && team.name
                    ? `${
                        team.name
                      }-exclusive-code-${exclusiveCodeId}-users-${moment().format(
                        "YYYY-MM-DD"
                      )}`
                    : `exclusive-code-${exclusiveCodeId}-users-${moment().format(
                        "YYYY-MM-DD"
                      )}`
                }
                target="_blank"
                className="rubik-h3"
              >
                <Icon icon="fa-solid:file-export" className="user-logo-icon" />{" "}
                Export Data
              </CSVExport>
            )}
          </div>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer>
        {exclusiveCode ? (
          <FlexContainer
            justifyContent="flex-start"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text type="rubik-h6">
              Status:{" "}
              {exclusiveCode
                ? exclusiveCode.status
                  ? exclusiveCode.status
                  : "N/A"
                : "N/A"}
            </Text>
            <Text type="rubik-h6">
              Used: {exclusiveCode ? exclusiveCode.used : 0}
            </Text>
            <Text type="rubik-h6">
              Limit:{" "}
              {exclusiveCode
                ? exclusiveCode.limit
                  ? exclusiveCode.limit
                  : "Unlimited"
                : "Unlimited"}
            </Text>
            <Suspense fallback={<SpinLoader />}>
              <DashboardExclusiveCodeUsersTable exclusiveCode={exclusiveCode} />
            </Suspense>
          </FlexContainer>
        ) : (
          <SpinLoader />
        )}
      </FlexContainer>
    </ExclusiveCodeShowContainer>
  );
};

export default withRouter(withFirebase(ExclusiveCodeShow));
