import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import useMobile from "../../hooks/useMobile";
import { Icon } from "@iconify/react";
import { withAuthentication } from "../Session";
import { withFirebase } from "../Firebase";

const FanPageListItemContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? "87px" : "105px")};
  gap: 10px;
  align-items: center;
  justify-content: center;
  /* margin: 10px; */
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const FanPageLogo = styled.div`
  width: ${({ isMobile }) => (isMobile ? "87px" : "105px")};
  height: ${({ isMobile }) => (isMobile ? "87px" : "105px")};
  border-radius: 50%;
  border: 2px solid ${COLORS.primaryBlack};
  background-color: ${COLORS.primaryWhite};
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(15%, 15%);
  background-color: ${({ isFollowed }) =>
    isFollowed ? COLORS.primaryBlue : COLORS.primaryGreenDark};
  border-radius: 50%;
`;

const FanPageName = styled.h3`
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  text-align: center;
  margin-top: 5px;
`;

const FanPageListItem = ({ hit, authUser, firebase }) => {
  const { name, logo, objectID, isBrand } = hit;
  const isMobile = useMobile();
  const [isFollowed, setIsFollowed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authUser && authUser.teams) {
      setIsFollowed(authUser.teams.includes(objectID));
    }
  }, [authUser, objectID]);

  const followOrganization = async () => {
    if (!authUser) {
      return;
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams || [];
      teams.push(objectID);

      const userPath = `user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      await userRef.update({ teams });

      setIsFollowed(true);
      setSubmitting(false);
    } catch (error) {
      console.error("Error following organization", error);
      setSubmitting(false);
    }
  };

  const unfollowOrganization = async () => {
    if (!authUser) {
      return;
    }

    try {
      setSubmitting(true);
      const teams = authUser.teams;
      const indexOfTeam = teams.indexOf(objectID);

      if (indexOfTeam > -1) {
        teams.splice(indexOfTeam, 1);

        const userPath = `user/${authUser.key}`;
        const userRef = firebase.database.ref(userPath);

        await userRef.update({ teams });

        setIsFollowed(false);
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error unfollowing organization", error);
      setSubmitting(false);
    }
  };

  const handleFollowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (submitting) {
      return;
    }
    if (isFollowed) {
      unfollowOrganization();
    } else {
      followOrganization();
    }
  };

  return (
    <FanPageListItemContainer
      key={objectID}
      onClick={handleFollowClick}
      isMobile={isMobile}
      disabled={submitting}
    >
      <FanPageLogo src={logo} alt={name} isMobile={isMobile}>
        {!isBrand && (
          <IconContainer isMobile={isMobile} isFollowed={isFollowed}>
            <Icon
              icon={isFollowed ? "mdi:minus" : "mdi:plus"}
              style={{ color: COLORS.primaryWhite }}
              width={25}
              height={25}
            />
          </IconContainer>
        )}
      </FanPageLogo>
      <FanPageName isMobile={isMobile}>{name}</FanPageName>
    </FanPageListItemContainer>
  );
};

export default withAuthentication(withFirebase(FanPageListItem));
