import React from 'react';
import './DefaultContainer.scss';

function DefaultContainer(props) {
  return(
    <div className='component-default-container'>
      {props.children}
    </div>
  );
}

export default DefaultContainer;