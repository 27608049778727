// Team Components
import {
  Edit as PortalTeamsEdit,
  Show as PortalTeamsShow,
  Create as PortalTeamsCreate,
  List as PortalTeams,
  PaymentsSetup as PortalTeamsPaymentsSetup,
  RedemptionsList as PortalTeamRedemptionsList,
  AdminRedemptionsList as PortalAdminRedemptionsList,
  PortalTeamDashboard,
  FollowersList as PortalTeamFollowersList,
  Assets as PortalTeamAssets,
  ExclusiveCodes as PortalTeamExclusiveCodes,
  ExclusiveCodeShow as PortalTeamExclusiveCodeShow,
  SpecialOffersCodes as PortalTeamSpecialOffersCodes,
  PlayerCodeShow as PortalPlayerCodeShow,
  PlayerCodes as PortalPlayerCodes,
  FlexPassCodeShow as PortalFlexPassCodeShow,
  FlexPassCodes as PortalFlexPassCodes,
  GroupPassCodeShow as PortalGroupPassCodeShow,
  GroupPassCodes as PortalGroupPassCodes,
} from "./views/Portal/Teams";

// Team Purchases Components
import { List as PortalTeamPurchases } from "./views/Portal/Teams/Purchases";

// Brand Components
import {
  List as PortalBrands,
  Create as PortalBrandsCreate,
  Edit as PortalBrandsEdit,
  Show as PortalBrandsShow,
} from "./views/Portal/Brands";

// Brand Deal Group Components
import {
  Create as PortalBrandsDealGroupsCreate,
  Edit as PortalBrandsDealGroupsEdit,
} from "./views/Portal/Brands/DealGroups";

// Sponsor Components
import {
  Edit as PortalSponsorsEdit,
  Show as PortalSponsorsShow,
  Create as PortalSponsorsCreate,
  List as PortalSponsors,
  PaymentsSetup as PortalSponsorsPaymentsSetup,
  PortalSponsorDashboard,
} from "./views/Portal/Sponsors";

// Sponsor Purchases Components
import { List as PortalSponsorPurchases } from "./views/Portal/Sponsors/Purchases";

// Sponsor Deal Components
import {
  Edit as PortalSponsorDealsEdit,
  Show as PortalSponsorDealsShow,
  Create as PortalSponsorDealsCreate,
  List as PortalSponsorDeals,
  RedemptionsList as PortalSponsorDealsRedemptionsList,
  DealRedemptions as PortalSponsorDealRedemptions,
} from "./views/Portal/Sponsors/Deals";

// Sponsor Document Components
import {
  Create as PortalSponsorDocumentsCreate,
  List as PortalSponsorDocuments,
} from "./views/Portal/Sponsors/Documents";

// Page Components
import AdminSettings, {
  Categories as AdminSettingsCategories,
  FeaturedTeams as AdminSettingsFeaturedTeams,
  RecommendedDeals as AdminSettingsRecommendedDeals,
} from "./views/Portal/Pages/AdminSettings";
import PortalWelcome from "./views/Portal/Pages/Welcome";

// Admin Components

import PortalAdminDashboard from "./views/Portal/PortalAdminDashboard";
import PortalReports from "./views/Portal/Reports/List";
import PortalReportsFollowers from "./views/Portal/Reports/Followers";

// PORTAL ROUTES -----------------------------------------------

// Organization Routes
const PortalTeamsPaymentsSetupRoute = {
  path: "/portal/teams/:team_id/payments-setup",
  name: "Payments Setup",
  component: PortalTeamsPaymentsSetup,
};
const PortalTeamsEditRoute = {
  path: "/portal/teams/:team_id/edit",
  name: "Edit Organization",
  component: PortalTeamsEdit,
};
const PortalTeamsCreateRoute = {
  path: "/portal/teams/create",
  name: "Create Organization",
  component: PortalTeamsCreate,
};
const PortalTeamsShowRoute = {
  path: "/portal/teams/:team_id",
  name: "Organization Info",
  component: PortalTeamsShow,
};
const PortalTeamsRoute = {
  path: "/portal/teams",
  exact: true,
  name: "Organizations",
  component: PortalTeams,
};

const PortalTeamSponsorsRoute = {
  path: "/portal/teams/:team_id/sponsors",
  exact: true,
  name: "Sponsors",
  component: PortalSponsors,
};

const PortalTeamDashboardRoute = {
  path: "/portal/teams/:team_id/dashboard",
  exact: true,
  name: "Portal",
  component: PortalTeamDashboard,
};

const PortalTeamFollowersListRoute = {
  path: "/portal/teams/:team_id/followers",
  exact: true,
  name: "Followers",
  component: PortalTeamFollowersList,
};

// special offers codes

const PortalTeamSpecialOffersCodesRoute = {
  path: "/portal/teams/:team_id/special-offers-codes",
  exact: true,
  name: "Special Offers Codes",
  component: PortalTeamSpecialOffersCodes,
};

const PortalTeamExclusiveCodesRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/exclusive-codes",
  exact: true,
  name: "Exclusive Codes",
  component: PortalTeamExclusiveCodes,
};

const PortalTeamExclusiveCodesShowRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/exclusive-codes/:exclusive_code_id",
  name: "Exclusive Code Info",
  exact: true,
  component: PortalTeamExclusiveCodeShow,
};

const PortalPlayerCodesRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/player-codes",
  exact: true,
  name: "Player Codes",
  component: PortalPlayerCodes,
};

const PortalPlayerCodeShowRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/player-codes/:player_code_id",
  name: "Player Code Info",
  exact: true,
  component: PortalPlayerCodeShow,
};

const PortalFlexPassCodesRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/flex-pass-codes",
  exact: true,
  name: "Flex Pass Codes",
  component: PortalFlexPassCodes,
};

const PortalFlexPassCodeShowRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/flex-pass-codes/:flex_pass_code_id",
  name: "Flex Pass Code Info",
  exact: true,
  component: PortalFlexPassCodeShow,
};

const PortalGroupPassCodesRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/group-pass-codes",
  exact: true,
  name: "Group Pass Codes",
  component: PortalGroupPassCodes,
};

const PortalGroupPassCodeShowRoute = {
  path: "/portal/teams/:team_id/special-offers-codes/group-pass-codes/:group_pass_code_id",
  name: "Group Pass Code Info",
  exact: true,
  component: PortalGroupPassCodeShow,
};

// Organization Redempions Routes
const PortalTeamRedemptionsRoute = {
  path: "/portal/teams/:team_id/redemptions",
  exact: true,
  name: "Redemptions",
  component: PortalTeamRedemptionsList,
};

// Organization Purchase Routes
const PortalTeamPurchasesRoute = {
  path: "/portal/teams/:team_id/purchases",
  exact: true,
  name: "Purchases",
  component: PortalTeamPurchases,
};

const PortalTeamAssetsRoute = {
  path: "/portal/teams/:team_id/assets",
  exact: true,
  name: "Assets",
  component: PortalTeamAssets,
};

// Organization Deal Routes
const PortalTeamDealsRoute = {
  path: "/portal/teams/:team_id/deals",
  exact: true,
  name: "Deals",
  component: PortalSponsorDeals,
};

const PortalTeamDealShowRoute = {
  path: "/portal/teams/:team_id/deals/:deal_id",
  name: "Deal Info",
  component: PortalSponsorDealsShow,
};

// Brand Routes
const PortalBrandsRoute = {
  path: "/portal/brands",
  exact: true,
  name: "Brands",
  component: PortalBrands,
};
const PortalBrandsCreateRoute = {
  path: "/portal/brands/create",
  name: "Create Brand",
  component: PortalBrandsCreate,
};
const PortalBrandsEditRoute = {
  path: "/portal/brands/:brand_id/edit",
  name: "Edit Brand",
  component: PortalBrandsEdit,
};
const PortalBrandsShowRoute = {
  path: "/portal/brands/:brand_id",
  name: "Brand Info",
  component: PortalBrandsShow,
};

// Brand Deal Group Routes
const PortalBrandsDealGroupsCreateRoute = {
  path: "/portal/brands/:brand_id/deal-groups/create",
  name: "Create Deal Group",
  component: PortalBrandsDealGroupsCreate,
};
const PortalBrandsDealGroupsEditRoute = {
  path: "/portal/brands/:brand_id/deal-groups/:deal_group_id/edit",
  name: "Edit Deal Group",
  component: PortalBrandsDealGroupsEdit,
};

// Sponsor Routes
const PortalSponsorsPaymentsSetupRoute = {
  path: "/portal/sponsors/:sponsor_id/payments-setup",
  name: "Payments Setup",
  component: PortalSponsorsPaymentsSetup,
};
const PortalSponsorsEditRoute = {
  path: "/portal/sponsors/:sponsor_id/edit",
  name: "Edit Business",
  component: PortalSponsorsEdit,
};
const PortalSponsorsCreateRoute = {
  path: "/portal/sponsors/create",
  exact: true,
  name: "Create Business",
  component: PortalSponsorsCreate,
};
const PortalSponsorsShowRoute = {
  path: "/portal/sponsors/:sponsor_id",
  exact: true,
  name: "Business Info",
  component: PortalSponsorsShow,
};

const PortalSponsorsRoute = {
  path: "/portal/sponsors",
  exact: true,
  name: "Businesses",
  component: PortalSponsors,
};

// Sponsor Document Routes
const PortalSponsorDocumentsCreateRoute = {
  path: "/portal/sponsors/:sponsor_id/documents/create",
  exact: true,
  name: "Create Document",
  component: PortalSponsorDocumentsCreate,
};
const PortalSponsorDocumentsRoute = {
  path: "/portal/sponsors/:sponsor_id/documents",
  exact: true,
  name: "Documents",
  component: PortalSponsorDocuments,
};

// Sponsor Purchase Routes
const PortalSponsorPurchasesRoute = {
  path: "/portal/sponsors/:sponsor_id/purchases",
  exact: true,
  name: "Purchases",
  component: PortalSponsorPurchases,
};

// Sponsor Deal Routes
const PortalSponsorDealsCreateRoute = {
  path: "/portal/sponsors/:sponsor_id/deals/create",
  exact: true,
  name: "Create Deal",
  component: PortalSponsorDealsCreate,
};
const PortalSponsorDealsEditRoute = {
  path: "/portal/sponsors/:sponsor_id/deals/:deal_id/edit",
  exact: true,
  name: "Edit Deal",
  component: PortalSponsorDealsEdit,
};
const PortalSponsorDealsShowRoute = {
  path: "/portal/sponsors/:sponsor_id/deals/:deal_id",
  exact: true,
  name: "Deal Info",
  component: PortalSponsorDealsShow,
};
const PortalSponsorDealsRoute = {
  path: "/portal/sponsors/:sponsor_id/deals",
  exact: true,
  name: "Deals",
  component: PortalSponsorDeals,
};

const PortalSponsorDealsRedemptionsRoute = {
  path: "/portal/sponsors/:sponsor_id/redemptions",
  exact: true,
  name: "Redemptions",
  component: PortalSponsorDealsRedemptionsList,
};

const PortalSponsorDealRedemptionsRoute = {
  path: "/portal/sponsors/:sponsor_id/deals/:deal_id/redemptions",
  exact: true,
  name: "Redemptions",
  component: PortalSponsorDealRedemptions,
};

// Page Routes
const PortalAdminSettingsCategoriesRoute = {
  path: "/portal/admin-settings/categories",
  exact: true,
  name: "Categories",
  component: AdminSettingsCategories,
};

const PortalAdminSettingsRecommendedDealRoute = {
  path: "/portal/admin-settings/recommended-deals",
  exact: true,
  name: "Recommended Deals",
  component: AdminSettingsRecommendedDeals,
};
const PortalAdminSettingsFeaturedOrganizationsRoute = {
  path: "/portal/admin-settings/featured-organizations",
  exact: true,
  name: "Featured Organizations",
  component: AdminSettingsFeaturedTeams,
};
const PortalAdminSettingsRoute = {
  path: "/portal/admin-settings",
  name: "Admin Settings",
  component: AdminSettings,
};
const PortalSponsorDashboardRoute = {
  path: "/portal/sponsors/:sponsor_id/dashboard",
  exact: true,
  name: "Portal",
  component: PortalSponsorDashboard,
};

const PortalWelcomeRoute = {
  path: "/portal",
  name: "Portal",
  component: PortalWelcome,
};

const PortalAdminDashboardRoute = {
  path: "/portal/dashboard",
  name: "Portal",
  exact: true,
  component: PortalAdminDashboard,
};

const PortalDealsRoute = {
  path: "/portal/deals",
  exact: true,
  name: "Offers",
  component: PortalSponsorDeals,
};

const PortalDealsShowRoute = {
  path: "/portal/deals/:deal_id",
  name: "Offer Info",
  exact: true,
  component: PortalSponsorDealsShow,
};

const PortalDealsCreateRoute = {
  path: "/portal/deals/create",
  name: "Create Offer",
  exact: true,
  component: PortalSponsorDealsCreate,
};

const PortalRedemptionsRoute = {
  path: "/portal/redemptions",
  name: "Redemptions",
  exact: true,
  component: PortalAdminRedemptionsList,
};

const PortalReportsRoute = {
  path: "/portal/reports",
  name: "Reports",
  exact: true,
  component: PortalReports,
};

const PortalReportsFollowersRoute = {
  path: "/portal/reports/followers",
  name: "Followers",
  exact: true,
  component: PortalReportsFollowers,
};

/* IMPORTANT!!! More specific routes need to be declared first. Ref: React Router documentation */
const adminUserPortalRoutes = [
  PortalBrandsDealGroupsCreateRoute,
  PortalBrandsDealGroupsEditRoute,

  PortalBrandsCreateRoute,
  PortalBrandsEditRoute,
  PortalBrandsShowRoute,
  PortalBrandsRoute,

  PortalSponsorDealRedemptionsRoute,
  PortalSponsorDealsCreateRoute,
  PortalSponsorDealsEditRoute,
  PortalSponsorDealsShowRoute,
  PortalSponsorDealsRoute,

  PortalSponsorDocumentsCreateRoute,
  PortalSponsorDocumentsRoute,

  PortalSponsorPurchasesRoute,
  PortalSponsorDashboardRoute,

  PortalSponsorsPaymentsSetupRoute,
  PortalSponsorDealsRedemptionsRoute,
  PortalSponsorsCreateRoute,
  PortalSponsorsEditRoute,
  PortalSponsorsShowRoute,
  PortalSponsorsRoute,
  PortalRedemptionsRoute,
  PortalDealsRoute,
  PortalDealsCreateRoute,
  PortalDealsShowRoute,

  PortalTeamPurchasesRoute,
  PortalTeamAssetsRoute,
  PortalTeamDashboardRoute,

  PortalTeamsPaymentsSetupRoute,
  PortalTeamRedemptionsRoute,
  PortalTeamFollowersListRoute,
  PortalTeamExclusiveCodesShowRoute,
  PortalTeamExclusiveCodesRoute,
  PortalPlayerCodeShowRoute,
  PortalPlayerCodesRoute,
  PortalFlexPassCodeShowRoute,
  PortalFlexPassCodesRoute,
  PortalGroupPassCodeShowRoute,
  PortalGroupPassCodesRoute,
  PortalTeamSpecialOffersCodesRoute,
  PortalTeamSponsorsRoute,
  PortalTeamDealsRoute,
  PortalTeamsCreateRoute,
  PortalTeamsEditRoute,
  PortalTeamsShowRoute,
  PortalTeamsRoute,

  PortalReportsRoute,
  PortalReportsFollowersRoute,

  PortalAdminSettingsCategoriesRoute,
  PortalAdminSettingsFeaturedOrganizationsRoute,
  PortalAdminSettingsRecommendedDealRoute,
  PortalAdminSettingsRoute,
  PortalAdminDashboardRoute,
  PortalWelcomeRoute,
];

const sponsorUserPortalRoutes = [
  PortalSponsorDealRedemptionsRoute,
  PortalSponsorDealsEditRoute,
  PortalSponsorDealsCreateRoute,
  PortalSponsorDealsShowRoute,
  PortalSponsorDealsRoute,
  PortalSponsorPurchasesRoute,
  PortalSponsorsPaymentsSetupRoute,
  PortalSponsorDealsRedemptionsRoute,
  PortalSponsorsShowRoute,
  PortalSponsorsRoute,
  PortalSponsorDashboardRoute,
  PortalWelcomeRoute,
];

const teamUserPortalRoutes = [
  PortalSponsorDealRedemptionsRoute,
  PortalSponsorDealsShowRoute,
  PortalSponsorDealsRoute,
  PortalSponsorDealsRedemptionsRoute,
  PortalTeamAssetsRoute,
  PortalTeamPurchasesRoute,
  PortalTeamDashboardRoute,

  PortalTeamsPaymentsSetupRoute,
  PortalTeamRedemptionsRoute,
  PortalTeamFollowersListRoute,
  PortalTeamExclusiveCodesShowRoute,
  PortalTeamExclusiveCodesRoute,
  PortalPlayerCodeShowRoute,
  PortalPlayerCodesRoute,
  PortalFlexPassCodeShowRoute,
  PortalFlexPassCodesRoute,
  PortalGroupPassCodeShowRoute,
  PortalGroupPassCodesRoute,
  PortalTeamSpecialOffersCodesRoute,
  PortalTeamSponsorsRoute,
  PortalTeamDealShowRoute,
  PortalTeamDealsRoute,
  PortalTeamsShowRoute,
  PortalTeamsRoute,
  PortalSponsorDashboardRoute,
  PortalSponsorsEditRoute,
  PortalSponsorsShowRoute,
  PortalSponsorsRoute,
  PortalWelcomeRoute,
];

export { adminUserPortalRoutes, teamUserPortalRoutes, sponsorUserPortalRoutes };
