import React from "react";
import "./SuggestedOrganizations.scss";
import LoadingPlaceholder from "../LoadingPlaceholder";
import FollowOrganizationLink from "../FollowOrganizationLink";
import OrganizationLink from "../OrganizationLink";

function SuggestedOrganizations({
  organizations,
  loading,
  defaultLayoutSettings,
}) {
  const loadingPlaceholderCount = 12;
  const loadingPlaceholders = [];
  for (let i = 0; i < loadingPlaceholderCount; i++) {
    loadingPlaceholders.push(
      <LoadingPlaceholder
        key={i}
        style={{ height: 50, width: "100%", marginBottom: 10 }}
      />
    );
  }

  return (
    <div className="component-suggested-organizations">
      <div className="suggestion-header">Suggestions for you</div>
      {loading
        ? loadingPlaceholders
        : organizations.map((organization, index) => {
            if (index < 6) {
              return (
                <div
                  className="organization"
                  key={`${organization.key}-${index}`}
                >
                  <OrganizationLink organization={organization}>
                    <div className="logo-area">
                      <img src={organization.logo} alt={organization.name} />
                    </div>
                    <div className="name" data-notranslate>
                      {organization.name}
                    </div>
                  </OrganizationLink>
                  <div className="actions">
                    <FollowOrganizationLink organization={organization} />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
    </div>
  );
}

export default SuggestedOrganizations;
