import React from 'react';
import { withFirebase } from '../Firebase';
import FeaturedTeamTile from './FeaturedTeamTile';
import useFeaturedTeams from '../../hooks/useFeaturedTeams';
import './FeaturedTeams.scss';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function FeaturedTeams({ firebase }) {

  const { featuredTeams: teams, loading } = useFeaturedTeams(firebase);

  function groupedTeams(groupSize) {
    const groupedTeams = [];
    if(teams) {
      let group = [];
      teams.forEach((organization) => {
        if (group.length === groupSize) {
          groupedTeams.push(group);
          group = [];
        }
  
        group.push(organization);
      });

      if (group.length > 0) {
        groupedTeams.push(group);
      }
    }

    return groupedTeams;
  }

  return (
    <div className='component-featured-teams'>
      {
        loading ? (
          <div>Loading...</div>
        ) : (
          teams.length > 0 &&
          <>
            <div className='default-featured-teams-container'>
              <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay infiniteLoop>
                {
                  groupedTeams(3).map((group, index) => (
                    <div key={index} className='featured-team-slide'>
                      {group[0] && <FeaturedTeamTile organization={group[0]} />}
                      {group[1] && <FeaturedTeamTile organization={group[1]} />}
                      {group[2] && <FeaturedTeamTile organization={group[2]} />}
                    </div>
                  ))
                }
              </Carousel>
            </div>
            <div className='mobile-featured-teams-container'>
              <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay infiniteLoop>
                {
                  groupedTeams(1).map((group, index) => (
                    <div key={index} className='featured-team-slide'>
                      {group[0] && <FeaturedTeamTile organization={group[0]} />}
                      {group[1] && <FeaturedTeamTile organization={group[1]} />}
                      {group[2] && <FeaturedTeamTile organization={group[2]} />}
                    </div>
                  ))
                }
              </Carousel>
            </div>
          </>
        )
      }
    </div>
  );
}

export default withFirebase(FeaturedTeams);