import React from "react";
import { IntlProvider } from "react-intl";
import messages from "./messages";
import useLanguageStore from "../stores/useLanguageStore";

const LanguageProvider = ({ children }) => {
  const language = useLanguageStore((state) => state.language);

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
