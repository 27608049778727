import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import { withRouter, Link } from "react-router-dom";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { DefaultContainer } from "../../../containers/UIKit";
import { dbLoadOfferGroup } from "../../../containers/DatabaseHelpers/DatabaseHelpers";
import DealListing from "../../../containers/DealsList/DealListing";

import "./Show.scss";

function Show({ firebase, match, authUser }) {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offerGroup, setOfferGroup] = useState();

  function loadOfferGroup() {
    const offerGroupKey = match.params.deal_group_id;
    dbLoadOfferGroup(firebase, offerGroupKey, setOfferGroup);
  }

  function loadOffers() {
    if (offerGroup && offerGroup.offerKeys) {
      const offerPromises = [];
      offerGroup.offerKeys.forEach((offerKey) => {
        offerPromises.push(
          firebase.database.ref(`/offer/${offerKey}`).once("value")
        );
      });

      Promise.all(offerPromises).then((snapshots) => {
        const results = [];
        snapshots.forEach((snapshot) => {
          const offer = snapshot.val();
          offer.key = snapshot.key;
          results.push(offer);
        });
        const sortedResults = results.sort((a, b) => {
          if (a.pinned && !b.pinned) {
            return -1;
          } else if (!a.pinned && b.pinned) {
            return 1;
          } else {
            return 0;
          }
        });
        setOffers(sortedResults);
        setLoading(false);
      });
    }
  }

  useEffect(loadOfferGroup, []);
  useEffect(loadOffers, [offerGroup]);

  return (
    <CheckOnboarding>
      <DefaultContainer>
        {!loading && (
          <div className="component-views-deal-groups-show">
            <div className="caption">
              This deal is available at the following locations. Click deal to
              expand and redeem.
            </div>
            <div className="offers">
              {offers.map((offer) => {
                return (
                  <DealListing
                    key={offer.key}
                    deal={offer}
                    size="large"
                    authUser={authUser}
                    showRedeemButton={true}
                  />
                );
              })}
            </div>
          </div>
        )}
      </DefaultContainer>
    </CheckOnboarding>
  );
}

export default withRouter(withFirebase(withAuthentication(Show)));
