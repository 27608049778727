import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { COLORS } from "../colors";

const ModalDialogContainer = styled.div`
  box-sizing: border-box;
  position: ${(props) => (props.position ? props.position : "fixed")};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "fit-content")};
  z-index: 1000;
  background: ${(props) => props.background || COLORS.primaryWhite};
  border-radius: 16px;
  box-shadow: 2px 2px 5px ${COLORS.greyText};
  margin: 0px auto;
  padding: ${(props) => props.padding || "5px 10px"};
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
`;

function ModalDialog({
  children,
  marginTop,
  modalDialogClassName,
  padding,
  position,
  top,
  left,
  right,
  width,
  height,
  onClose,
  background,
}) {
  return (
    <ModalDialogContainer
      marginTop={marginTop}
      padding={padding}
      position={position}
      top={top}
      left={left}
      right={right}
      width={width}
      height={height}
      onClose={onClose}
      modalDialogClassName={modalDialogClassName}
      background={background}
    >
      {children}
    </ModalDialogContainer>
  );
}

export default ModalDialog;

ModalDialog.defaultProps = {
  children: null,
  modalDialogClassName: "",
};

ModalDialog.propTypes = {
  children: PropTypes.array,
  modalDialogClassName: PropTypes.string,
  maxWidth: PropTypes.number,
  marginTop: PropTypes.number,
  padding: PropTypes.number,
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onClose: PropTypes.func,
};
