import React from "react";
import Feed from "../../../containers/Feed";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { withAuthentication } from "../../../containers/Session";
import { DefaultContainer } from "../../../containers/UIKit";
import styled from "styled-components/macro";

const FeedPageContainer = styled.div`
  font-family: "League Spartan", sans-serif;
`;

const FeedPage = ({ authUser }) => {
  if (!authUser) {
    return null;
  }

  return (
    <CheckOnboarding>
      <DefaultContainer>
        <FeedPageContainer>
          <Feed />
        </FeedPageContainer>
      </DefaultContainer>
    </CheckOnboarding>
  );
};

export default withAuthentication(FeedPage);
