import React, { useState } from "react";
import firebase from "firebase/app";
import { COLORS, Text, FlexContainer, Link } from "../../assets/fansaves-ui";
import Modal from "../../containers/Modals/Modal";
import { Button } from "../../containers/UIKit";
import "firebase/auth";
import styled from "styled-components/macro";

const DeleteAccountButton = styled(Link)`
  color: ${COLORS.mediumGrey};
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const ButtonContainer = styled(FlexContainer)`
  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled(Text)`
  text-align: center;
`;

const ButtonWithCenteredText = styled(Button)`
  text-align: center;
`;

const DeleteAccountLink = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowConfirmModal = () => {
    setShowModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowModal(false);
  };

  const renderErrorMessage = (error) => {
    switch (error.code) {
      case "auth/requires-recent-login":
        return "For security reasons, you must re-login before deleting your account.";
      default:
        return `We're sorry, there was an error while deleting your account: ${
          error && error.message
        }. Please re-login and try again or contact us by email at info@fansaves.com for assistance.`;
    }
  };

  const handleDelete = () => {
    setShowModal(false);
    firebase
      .auth()
      .currentUser.delete()
      .then(() => {
        console.log("Account deleted");
      })
      .catch((e) => {
        console.error(e);
        alert(renderErrorMessage(e));
      });
  };

  return (
    <div>
      <DeleteAccountButton
        onClick={(e) => {
          e.preventDefault();
          handleShowConfirmModal();
        }}
      >
        Delete Account
      </DeleteAccountButton>
      {showModal && (
        <Modal onClose={handleCloseConfirmModal} backgroundColor="white">
          <FlexContainer justifyContent="center" alignItems="center" gap="30px">
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Title color={COLORS.primaryBlue} type="standard-h3-inter" bold>
                We're sorry to see you go!
              </Title>
            </FlexContainer>

            <FlexContainer
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <StyledText color={COLORS.primaryBlue} type="inter-b1">
                Are you sure you want to delete your FanSaves account?
                <br />
                This cannot be undone. If you wish to use FanSaves again, you
                will need to create a new account.
              </StyledText>
            </FlexContainer>

            <ButtonContainer
              justifyContent="space-around"
              direction="horizontal"
              className="modal-buttons"
              gap="20px"
              margin="0 20px"
            >
              <ButtonWithCenteredText
                className="green-outline medium rounded shadow"
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseConfirmModal();
                }}
              >
                Keep Saving
              </ButtonWithCenteredText>
              <ButtonWithCenteredText
                className="red medium rounded shadow"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
                x
              >
                {" "}
                Delete Account
              </ButtonWithCenteredText>
            </ButtonContainer>
          </FlexContainer>
        </Modal>
      )}
    </div>
  );
};

export default DeleteAccountLink;
