import { create } from "zustand";
import { getEventsByType } from "../utilities/event_helpers";

const useAccountDeleteStore = create((set, get) => ({
  accountDeleteEvents: null,
  isLoading: false,
  error: null,
  isFetching: false,

  fetchAccountDeleteEvents: async (firebase) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await getEventsByType("accountDelete", firebase);

      set({ accountDeleteEvents: data });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },
}));

export default useAccountDeleteStore;
