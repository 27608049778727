/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { InView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import OrganizationLink from "../../../containers/OrganizationLink";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { withRouter, Redirect } from "react-router-dom";
import LoadingPlaceholder from "../../../containers/LoadingPlaceholder";
import RedeemDealLink from "../../../containers/RedeemDealLink";
import DealRedeemedInfo from "../../../containers/DealRedeemedInfo";
import DealSpecialOverlay from "../../../containers/DealSpecialOverlay";
import ExclusiveOverlay from "../../../containers/ExclusiveOverlay/ExclusiveOverlay";
import DealExclusiveBanner from "../../../containers/DealExclusiveBanner/DealExclusiveBanner";
import DealTypeOverlay from "../../../containers/DealTypeOverlay";
import { DefaultContainer } from "../../../containers/UIKit";
import SponsorMap from "../../../containers/SponsorMap/SponsorMap";
import ParticlesConfetti from "../../../containers/Confetti/ParticlesConfetti";
import SimilarDeals from "../SimilarDeals";
import RedeemDealModal from "../../../containers/RedeemDealModal";
import { Scrollbars } from "react-custom-scrollbars-2";
import { COLORS } from "../../../assets/fansaves-ui";
import contestLogo from "../../../assets/img/contest_logo.png";
import { useIntl } from "react-intl";
import ShareLinkAndMediaModal from "../../../containers/ShareLinkAndMediaModal";

import {
  INSTORE_REDEMPTION_TIME_IN_MINUTES,
  DATABASE_DATETIME_FORMAT,
} from "../../../utilities/constants";
import { isMobileScreenSize } from "../../../utilities/core";
import { logDealImpression } from "../../../utilities/team_helpers";
import { unlockedExclusives } from "../../../utilities/user_helpers";
import { triggeredOfferLimitReached } from "../../../utilities/offer_helpers";

import "./Show.scss";
import "../../../scss/base/colorswatches.scss";
import "../../../scss/base/buttons.scss";
import "../../../scss/base/typography.scss";

import googlePlayImage from "../../../assets/img/google-play-badge.png";
import appleAppStoreImage from "../../../assets/img/apple-app-store-badge.png";

import { logEvent, eventNames } from "../../../utilities/analytics";

const Show = ({ firebase, authUser, match }) => {
  const [loadingDeal, setLoadingDeal] = useState(true);
  const [deal, setDeal] = useState(null);
  const [loadingOrganization, setLoadingOrganization] = useState(true);
  const [loadingSponsor, setLoadingSponsor] = useState(true);
  const [timeUntilRedeemAgain, setTimeUntilRedeemAgain] = useState("...");
  const [sponsor, setSponsor] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [location, setLocation] = useState(null);
  const [confetti, setConfetti] = useState(false);
  const [showExclusiveDealBanner, setShowExclusiveDealBanner] = useState(true);
  const [unlockSuccess, setUnlockSuccess] = useState(false);
  const [showUnlockExclusiveModal, setShowUnlockExclusiveModal] =
    useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [impressionLogged, setImpressionLogged] = useState(false);
  const { locale } = useIntl();

  const resize = () => {
    if (isMobileScreenSize(900)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [isMobile]);

  useEffect(() => {
    loadDeal();
    resize();

    return () => {
      firebase.database.ref(`/offer/${match.params.deal_id}`).off();
    };
  }, []);

  useEffect(() => {
    loadDeal();
    resize();

    return () => {
      firebase.database.ref(`/offer/${match.params.deal_id}`).off();
    };
  }, [match.params.deal_id]);

  useEffect(() => {
    if (deal) {
      logEvent(firebase, eventNames.AE_DEALS_SHOW_VIEW, {
        key: deal.key,
        description: deal.description,
      });
    }
  }, [deal]);

  const loadDeal = () => {
    const offerPath = `/offer/${match.params.deal_id}`;
    const offerRef = firebase.database.ref(offerPath);
    //set listener for deal
    offerRef.on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        const deal = snapshot.val();
        deal.key = snapshot.key;

        setDeal(deal);
        setLoadingDeal(false);
      } else {
        setLoadingDeal(false);
      }
    });
  };

  useEffect(() => {
    if (deal) {
      loadSponsor();
      loadOrganization();
      setInterval(showTimeUntilRedeemAgain, 1000);
    }
  }, [deal]);

  const logImpression = (inView) => {
    if (inView && deal && !impressionLogged) {
      logDealImpression(
        firebase,
        deal.key,
        (success) => {
          if (success) {
            setImpressionLogged(true);
          } else {
            console.error("Failed to log impression");
          }
        },
        () => {
          console.error("Failed to log impression");
        }
      );
    }
  };

  const updateLocation = () => {
    const newLocation = [];
    if (sponsor && sponsor.lat && sponsor.long) {
      newLocation.push(parseFloat(sponsor.lat));
      newLocation.push(parseFloat(sponsor.long));
    }
    setLocation(newLocation);
  };

  const loadSponsor = () => {
    const sponsorPath = `/sponsor/${deal.sponsor.sponsorKey}`;
    const sponsorRef = firebase.database.ref(sponsorPath);
    sponsorRef.once("value").then((snapshot) => {
      const sponsor = snapshot.val();
      sponsor.key = snapshot.key;
      setSponsor(sponsor);
      setLoadingSponsor(false);
    });
  };

  const loadOrganization = () => {
    if (!deal.teams || deal.teams.length === 0) return;
    const organizationPath = `/team/${deal.teams[0]}`;
    const organizationRef = firebase.database.ref(organizationPath);
    organizationRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        const organization = snapshot.val();
        organization.key = snapshot.key;
        setOrganization(organization);
        setLoadingOrganization(false);
      }
    });
  };

  const handleTimeUntilRedeemAgain = () => {
    return redeemAgainAt().format("MMMM Do YYYY, h:mm a");
  };

  const showTimeUntilRedeemAgain = () => {
    if (dealRedeemed()) {
      setTimeUntilRedeemAgain(handleTimeUntilRedeemAgain());
    }
  };

  const withinRedemptionPeriod = () => {
    const now = moment();
    return redeemAgainAt().diff(now) >= 0;
  };

  const handleRedemptionTimeExpired = () => {
    let redemptionTimeExpired;
    if (dealRedeemed() && withinRedemptionPeriod()) {
      redemptionTimeExpired = moment(
        authUser.redeemed[deal.key],
        DATABASE_DATETIME_FORMAT
      )
        .add(INSTORE_REDEMPTION_TIME_IN_MINUTES, "minutes")
        .isBefore(moment());
    }
    return redemptionTimeExpired;
  };

  const dealRedeemed = () => {
    return (
      authUser &&
      authUser.redeemed &&
      Object.keys(authUser.redeemed).indexOf(deal.key) >= 0
    );
  };

  const redeemAgainAt = () => {
    return redeemedAt()
      .add(deal.interval.hour, "hours")
      .add(deal.interval.week, "weeks")
      .add(deal.interval.month, "months");
  };

  const redeemedAt = () => {
    return moment(authUser.redeemed[deal.key], DATABASE_DATETIME_FORMAT);
  };

  const unlocked = () => {
    let unlocked = false;
    deal.teams.forEach((teamKey) => {
      if (authUser && unlockedExclusives(authUser, teamKey)) {
        unlocked = true;
      }
    });

    return unlocked;
  };

  useEffect(() => {
    if (deal && unlocked()) {
      setShowExclusiveDealBanner(false);
    }
  }, [deal]);

  const translateDescription = (size) => {
    if (!deal) return;
    const description = deal.description;
    const descriptionFr = deal.descriptionFr;

    if (descriptionFr) {
      return (
        <div className={`description ${size}`} data-notranslate>
          {locale === "fr" ? descriptionFr : description}
        </div>
      );
    } else {
      return <div className={`description ${size}`}>{deal.description}</div>;
    }
  };

  const withinContestPeriod = () => {
    if (!deal.triggered) {
      return moment(deal.expiresAt).isAfter(moment());
    } else if (deal.triggered && deal.triggerEndAt) {
      return moment(deal.triggerEndAt).isAfter(moment());
    } else {
      return false;
    }
  };

  const renderExpiryDay = () => {
    if (!deal.expiresAt && !deal.triggerEndAt) return null;
    let expiryMoment = moment().add(1, "months");
    if (!deal.triggered) {
      expiryMoment = moment(deal.expiresAt);
    } else if (deal.triggered && deal.triggerEndAt) {
      expiryMoment = moment(deal.triggerEndAt);
    }

    return (
      <>
        {deal.contestDeal ? (
          <div
            className={`context-expiry ${
              !withinContestPeriod() ? "standard-b1-small" : ""
            }`}
          >
            {withinContestPeriod() ? "Contest ends " : "Contest ended "}{" "}
            <span
              className={`${
                isMobile
                  ? "mobile-b2"
                  : withinContestPeriod()
                  ? "standard-b2"
                  : "standard-b2-small"
              }`}
            >
              {expiryMoment.format("MMMM Do, YYYY [at] h:mm a")}.
            </span>
            {!withinContestPeriod() && (
              <span>
                {" "}
                The winner(s) will be contacted directly by the organization.
                Check back soon for more contests like this one!
              </span>
            )}
          </div>
        ) : (
          <div className="trigger-expiry">
            Triggered until {expiryMoment.format("MMMM Do, YYYY [at] h:mm a")}.
          </div>
        )}
      </>
    );
  };

  const renderAgreeTerms = () => {
    return (
      <div className="terms-agreement-text">
        By pressing the button above you are agreeing to the{" "}
        <span className={`${isMobile ? "mobile-b2" : " standard-b2"}`}>
          Terms & Conditions.
        </span>
      </div>
    );
  };

  const handleUnlockExclusive = () => {
    setUnlockSuccess(true);
    setConfetti(true);
    setShowUnlockExclusiveModal(false);
    setTimeout(() => {
      setUnlockSuccess(false);
      setConfetti(false);
      setShowExclusiveDealBanner(false);
    }, 3000);
  };

  const handleRedeemSuccess = () => {
    setShowCouponModal(true);
  };

  const renderRegularDealActions = () => {
    if (deal.published && !deal.active && !deal.contestDeal) {
      return (
        <div className="not-activated-message">
          {deal.triggered
            ? `This deal has not been triggered yet. ${
                deal.notTriggeredMessage ? deal.notTriggeredMessage : ""
              }`
            : "This deal cannot be redeemed at this time."}
          <br />
          Check out more great deals <Link to="/deals">here</Link>.
        </div>
      );
    } else if (
      !deal.contestDeal &&
      dealRedeemed() &&
      withinRedemptionPeriod()
    ) {
      return (
        <>
          <DealRedeemedInfo
            isMobile={isMobile}
            userRedeemedDateTime={authUser.redeemed[deal.key]}
            onRedeemSuccess={handleRedeemSuccess}
            isEasyRedemption={deal.easyRedemption}
          />

          {showCouponModal && (
            <RedeemDealModal
              isMobile={isMobile}
              onClose={() => setShowCouponModal(false)}
              deal={deal}
              organization={organization}
              targetDateTimeString={authUser.redeemed[deal.key]}
              redemptionTimeExpired={handleRedemptionTimeExpired()}
              dealRedeemedAt={redeemedAt()}
            />
          )}
        </>
      );
    } else if (deal.triggered && triggeredOfferLimitReached(deal)) {
      return (
        <div className="no-longer-available deal-expired">
          We're sorry! This deal is no longer available.
        </div>
      );
    } else if (deal.contestDeal) {
      if (deal.triggered && !deal.active) {
        return (
          <div className="not-activated-message">
            This contest has not been triggered yet.
            {deal.notTriggeredMessage ? ` ${deal.notTriggeredMessage}` : ""}
          </div>
        );
      } else {
        if (withinContestPeriod() && !deal.active) {
          return (
            <div className="not-activated-message">
              This contest has not been activated yet.
            </div>
          );
        } else if (!withinContestPeriod()) {
          return (
            <div>
              <br />
            </div>
          );
        } else {
          return (
            <RedeemDealLink
              isMobile={isMobile}
              deal={deal}
              organization={organization}
              showUnlockExclusiveModal={showUnlockExclusiveModal}
              onShowUnlockExclusiveModal={setShowUnlockExclusiveModal}
              onUnlockExclusiveDeal={handleUnlockExclusive}
              onRedeemSuccess={setShowCouponModal}
            />
          );
        }
      }
    } else {
      return (
        <RedeemDealLink
          isMobile={isMobile}
          deal={deal}
          organization={organization}
          showUnlockExclusiveModal={showUnlockExclusiveModal}
          onShowUnlockExclusiveModal={setShowUnlockExclusiveModal}
          onUnlockExclusiveDeal={handleUnlockExclusive}
          onRedeemSuccess={setShowCouponModal}
        />
      );
    }
  };

  const renderBusinessInfo = () => {
    return (
      <div className="sponsor-info">
        <div className="sponsor-details">
          <div className={`${isMobile ? "mobile-h2" : " standard-h8"}`}>
            More info about <span data-notranslate>{sponsor.name}</span>
          </div>
          {!deal.contestDeal && (
            <div
              className={`address ${isMobile ? "mobile-b1" : " standard-h6"}`}
              data-notranslate
            >
              {sponsor.address}
            </div>
          )}

          <div
            className={`sponsor-description ${
              isMobile ? "mobile-b1" : " standard-b1"
            }`}
          >
            {sponsor.description}
          </div>

          <div className="contact-details">
            {!deal.contestDeal && (
              <div
                className={`contact-details-header ${
                  isMobile ? "mobile-h2" : " standard-h5"
                }`}
              >
                <span data-notranslate>{sponsor.name}</span> Contact
                Information:
              </div>
            )}
            <div className="contact-details-links">
              {sponsor.displayEmail && sponsor.displayEmail != "" && (
                <a href={`mailto:${sponsor.displayEmail}`}>
                  <i className="fa fa-envelope-o" />
                </a>
              )}

              {sponsor.phone && sponsor.phone != "" && (
                <a href={`tel:${sponsor.phone}`}>
                  <i className="fa fa-phone" />
                </a>
              )}

              {sponsor.website && sponsor.website != "" && (
                <a
                  href={sponsor.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-globe" />
                </a>
              )}

              {sponsor.facebook && sponsor.facebook != "" && (
                <a
                  href={sponsor.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              )}

              {sponsor.linkedin && sponsor.linkedin != "" && (
                <a
                  href={sponsor.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin" />
                </a>
              )}

              {sponsor.twitter && sponsor.twitter != "" && (
                <a
                  href={sponsor.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-twitter" />
                </a>
              )}

              {sponsor.instagram && sponsor.instagram != "" && (
                <a
                  href={sponsor.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDealImage = () => {
    return (
      <div className="image-area">
        {!deal.contestDeal && handleRedemptionTimeExpired() && (
          <div
            className={`expired-image-area ${
              isMobile ? "mobile-h2-small" : " standard-h3"
            } ${deal.easyRedemption ? "easy-redemption" : ""}`}
          >
            <div className="expired-deal-icon">
              <i className="icon-lock"></i>
            </div>
            <div className="expired-deal-title">DEAL REDEEMED</div>
            {!deal.easyRedemption && (
              <div className="expired-deal-text">
                This deal will be available again on
                <br />
                <span data-notranslate>{timeUntilRedeemAgain}.</span>
              </div>
            )}
          </div>
        )}
        <img
          src={deal.picture}
          alt={deal.description}
          className="deal-picture"
        />
        <div className="deal-special-overlay">
          <ExclusiveOverlay deal={deal} unlocked={unlocked()} />
        </div>
        <div className="deal-overlays">
          <DealSpecialOverlay deal={deal} isMobile={isMobile} />
          <DealTypeOverlay deal={deal} isMobile={isMobile} />
        </div>
      </div>
    );
  };

  const renderDealInfo = () => {
    return (
      <>
        <div className="actions">
          {isMobile && (
            <ShareLinkAndMediaModal
              url={`/deals/${deal.key}`}
              deal={deal}
              className="share-link-and-media-modal"
              isMobile={isMobile}
            />
          )}
          {renderRegularDealActions()}
        </div>
        {deal.triggered && renderTriggeredDealLimitMessage()}
        {!deal.contestDeal &&
        !deal.easyRedemption &&
        handleRedemptionTimeExpired() ? (
          <div className="time-base-expiry">
            <div
              className={`time-base-expiry-item ${
                isMobile ? "mobile-b2" : " standard-b1"
              }`}
            >
              This deal was redeemed on{" "}
              {redeemedAt().format("dddd, MMMM Do YYYY, h:mm:ss a")}
            </div>
            <div
              className={`time-base-expiry-item ${
                isMobile ? "mobile-b2" : " standard-b1"
              }`}
            >
              This deal will be available again on{" "}
              <span data-notranslate>{timeUntilRedeemAgain}!</span>
            </div>
          </div>
        ) : (
          <>
            {!deal.contestDeal && !deal.easyRedemption ? (
              <>
                {deal.dealType === "instore" && (
                  <div
                    className={`time-base-expiry  ${
                      isMobile ? "mobile-b1" : " standard-b1"
                    }`}
                  >
                    Deal will expire{" "}
                    <span
                      className={`${isMobile ? "mobile-b2" : " standard-b2"}`}
                    >
                      10 minutes
                    </span>{" "}
                    after redeeming.
                  </div>
                )}
                {deal.dealType === "online" && (
                  <div
                    className={`time-base-expiry  ${
                      isMobile ? "mobile-b1" : " standard-b1"
                    }`}
                  >
                    You will have{" "}
                    <span
                      className={`${isMobile ? "mobile-b2" : " standard-b2"}`}
                    >
                      10 minutes
                    </span>{" "}
                    to copy the coupon code. Coupon code will disappear after{" "}
                    <span
                      className={`${isMobile ? "mobile-b2" : " standard-b2"}`}
                    >
                      10 minutes
                    </span>{" "}
                    but will still be valid.
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`time-base-expiry  ${
                    isMobile ? "mobile-b1" : " standard-b1"
                  }`}
                >
                  {renderExpiryDay()}
                </div>
                {withinContestPeriod() && deal.active && (
                  <div
                    className={`terms-agreement  ${
                      isMobile ? "mobile-b1" : " standard-b1"
                    }`}
                  >
                    {renderAgreeTerms()}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {(!deal.contestDeal || (deal.contestDeal && withinContestPeriod())) && (
          <>
            {!isMobile ? (
              <div className="terms">
                <Scrollbars
                  autoHide
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: COLORS.primaryGreyLight,
                        borderRadius: "3px",
                        height: "100%",
                        width: "5px",
                      }}
                    />
                  )}
                >
                  <div className={`${isMobile ? "mobile-b1" : " standard-b1"}`}>
                    <b>Terms & Conditions</b>: {deal.terms}
                  </div>
                </Scrollbars>
              </div>
            ) : (
              <div className="terms-mobile">
                <div className={`${isMobile ? "mobile-b1" : " standard-b1"}`}>
                  <b>Terms & Conditions</b>: {deal.terms}
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  const renderMap = (deal) => {
    return (
      <>
        {deal.dealType === "instore" ? (
          <div className="sponsor-map">
            {sponsor && location && (
              <SponsorMap location={location} isMobile={isMobile} />
            )}
          </div>
        ) : (
          <div className="online-map-placeholder"></div>
        )}
      </>
    );
  };

  const renderDownloadAppInfo = () => {
    return (
      <div className="download-app-info">
        <div
          className={`download-app ${isMobile ? "mobile-b1" : " standard-b1"}`}
        >
          For a Better Experience Download the Free App!
        </div>
        <div className="app-links">
          <a
            href="http://play.google.com/store/apps/details?id=com.fansaves.daydream&hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlayImage} alt="Google Play Store" />
          </a>
          <a
            href="http://itunes.apple.com/us/app/fansaves/id1342516391?mt=8&app=itunes&ign-mpt=uo%3D4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appleAppStoreImage} alt="Apple App Store" />
          </a>
        </div>
      </div>
    );
  };

  const hadnleCloseExclusiveDealBanner = () => {
    setShowExclusiveDealBanner(false);
  };

  const renderTriggeredDealLimitMessage = () => {
    return (
      <>
        {deal.triggered && deal.redemptionsLimit && deal.totalAvailable && (
          <div
            className={`total-left  ${isMobile ? "mobile-b2" : " standard-b2"}`}
          >
            {deal.totalAvailable - (deal.totalRedeemed || 0)} out of{" "}
            {deal.totalAvailable} left.
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (sponsor) {
      updateLocation();
    }
  }, [sponsor]);

  if (deal && deal.flashDeal) {
    return <Redirect to={`/deals/${deal.key}/show_flash`} />;
  }

  return (
    <CheckOnboarding>
      <DefaultContainer>
        <div className="views-deals-show">
          {loadingDeal || loadingOrganization ? (
            <LoadingPlaceholder style={{ height: 300, width: "100%" }} />
          ) : !deal || !deal.published || organization.archived ? (
            <div className="archived">
              <div className="archived-header">We're sorry!</div>
              This deal is no longer available.
              <br />
              Check out more great deals <Link to="/deals">here</Link>.
            </div>
          ) : (
            <InView as="div" onChange={(inView) => logImpression(inView)}>
              <div className="deal-header">
                <div className="business">
                  <img
                    src={
                      deal.contestDeal
                        ? contestLogo
                        : deal.sponsor && deal.sponsor.picture
                    }
                    alt={`${deal.sponsor.name} logo`}
                    className="business-logo"
                  />
                  <div className="business-details">
                    <div
                      className={`business-name ${
                        isMobile ? "mobile-h4" : "standard-h3"
                      }`}
                      data-notranslate
                    >
                      {deal.sponsor.name}
                    </div>
                    {!deal.contestDeal && (
                      <div
                        className={`business-address ${
                          isMobile ? "mobile-h5" : " standard-h6"
                        }`}
                        data-notranslate
                      >
                        {deal.sponsor.address}
                      </div>
                    )}
                  </div>
                </div>
                {organization && (
                  <>
                    <OrganizationLink
                      organization={organization}
                      className="organization"
                    >
                      <img
                        src={organization.logo}
                        alt={`${organization.name} logo`}
                        className="organization-logo"
                      />
                      <div className="organization-details">
                        <div
                          className={`organization-name ${
                            isMobile ? "mobile-h4" : " standard-h3"
                          }`}
                          data-notranslate
                        >
                          {organization.name}
                        </div>
                      </div>
                    </OrganizationLink>
                  </>
                )}
              </div>
              {organization && (
                <div className="exclusive-deal-banner">
                  {deal.exclusive && showExclusiveDealBanner && (
                    <DealExclusiveBanner
                      deal={deal}
                      isMobile={isMobile}
                      name={organization.name}
                      onClose={hadnleCloseExclusiveDealBanner}
                      onSuccess={unlockSuccess}
                      onShowUnlockExclusiveModal={setShowUnlockExclusiveModal}
                    />
                  )}
                </div>
              )}
              <div className="views-deals-show-main-container">
                {!isMobile ? (
                  <>
                    <div className="left-column">
                      {renderDealImage()}
                      {!loadingSponsor && renderBusinessInfo()}
                      {renderMap(deal)}
                    </div>
                    <div className="writeup right-column">
                      <div className="deal-details-top">
                        <ShareLinkAndMediaModal
                          url={`/deals/${deal.key}`}
                          deal={deal}
                          className="share-link-and-media-modal"
                          isMobile={isMobile}
                        />
                        {translateDescription("standard-h1")}
                        <>{renderDealInfo()}</>
                      </div>
                      <div className="deal-details-bottom">
                        {renderDownloadAppInfo()}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mobile-content">
                    {translateDescription("mobile-h2")}
                    {renderDealImage()}
                    <div className="deal-details">
                      <div className="deal-details-top">
                        {renderDealInfo()}
                        {!loadingSponsor && renderBusinessInfo()}
                      </div>
                      {renderMap(deal)}
                      {organization && (
                        <div className="mobile-organization-link-container">
                          <OrganizationLink
                            organization={organization}
                            className="organization"
                          >
                            <img
                              src={organization.logo}
                              alt={`${organization.name} logo`}
                              className="organization-logo"
                            />
                            <div className="organization-details">
                              <div
                                className={`organization-name ${
                                  isMobile ? "mobile-h4" : " standard-h3"
                                }`}
                                data-notranslate
                              >
                                {organization.name}
                              </div>
                            </div>
                          </OrganizationLink>
                        </div>
                      )}
                      <div className="deal-details-bottom">
                        {renderDownloadAppInfo()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {confetti && <ParticlesConfetti />}
            </InView>
          )}
          {organization && (
            <div className="similar-deals-container">
              <SimilarDeals
                organization={organization}
                isMobile={isMobile}
                isContest={deal.contestDeal}
                dealKey={deal.key}
              />
            </div>
          )}
        </div>
      </DefaultContainer>
    </CheckOnboarding>
  );
};

export default withRouter(withFirebase(withAuthentication(Show)));
