import styled, { css } from "styled-components/macro";
import { GLOBALS } from "../globals";

const FlexContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  max-width: 1340px;
  margin: ${(props) => (props.margin ? props.margin : "0 auto")};
  flex-direction: ${(props) =>
    props.direction === "horizontal" ? "row" : "column"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  gap: ${(props) => (props.gap ? props.gap : "0")};

  ${(props) => {
    if (props.responsive && props.direction === "horizontal") {
      return css`
        flex-direction: row;
        @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
          flex-direction: ${(props) =>
            props.mobileDirection ? props.mobileDirection : "column"};
          justify-content: ${(props) =>
            props.mobileJustifyContent ? props.mobileJustifyContent : "center"};
          align-items: ${(props) =>
            props.mobileAlignItems ? props.mobileAlignItems : "center"};
        }
      `;
    }
  }}

  ${(props) => {
    if (props.responsive) {
      return css`
        @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
          width: ${(props) => (props.mobileWidth ? props.mobileWidth : "100%")};
        }
      `;
    }
  }}
`;

export default FlexContainer;
