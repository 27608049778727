import React from "react";
import Particles from "react-tsparticles";
import "../../scss/base/colorswatches.scss";

import blueRectangle from '../../assets/img/Rectangle_blue.png';
import greenRectangle from '../../assets/img/Rectangle_green.png';
import greenDollar from '../../assets/img/Dollar_green.png';
import blueDollar from '../../assets/img/Dollar_blue.png';



const ParticlesConfetti = () => {
  return (
    <div>
      <Particles
      id="tsparticles"
      options={{
        "particles": {
          "color": {
            "value": [
              "#ffffff",
              "#000000",
              "#35b729",
              "#00083e",
            ]
          },
          "move": {
            "direction": "bottom",
            "enable": true,
            "outModes": {
              "default": "out"
            },
            "size": true,
            "speed": {
              "min": 1,
              "max": 3
            }
          },
          "number": {
            "value": 100,
            "density": {
              "enable": true,
              "area": 800
            }
          },
          "opacity": {
            "value": 1,
            "animation": {
              "enable": false,
              "startValue": "max",
              "destroy": "min",
              "speed": 0.3,
              "sync": true
            }
          },
          "rotate": {
            "value": {
              "min": 0,
              "max": 360
            },
            "direction": "random",
            "move": true,
            "animation": {
              "enable": true,
              "speed": 60
            }
          },
          "tilt": {
            "direction": "random",
            "enable": true,
            "move": true,
            "value": {
              "min": 0,
              "max": 360
            },
            "animation": {
              "enable": true,
              "speed": 60
            }
          },
          "size": {
            "value": 3,
            "animation": {
              "enable": true,
              "startValue": "min",
              "count": 1,
              "speed": 16,
              "sync": true
            }
          },
          "roll": {
            "darken": {
              "enable": true,
              "value": 30
            },
            "enlighten": {
              "enable": true,
              "value": 30
            },
            "enable": true,
            "speed": {
              "min": 15,
              "max": 25
            }
          },
          "wobble": {
            "distance": 30,
            "enable": true,
            "move": true,
            "speed": {
              "min": -15,
              "max": 15
            }
          },
          "life": {
            "duration": {
              "sync": true,
              "value": 5
            },
            "count": 1
          },
          "shape": {
            "type": "images",
            "options": {
              "image": [
                {
                  "src": blueRectangle,
                  "width": 6,
                  "height": 6,
                  "particles": {
                    "size": {
                      "value": 5, 
                    }
                  }
                },
                {
                  "src": greenRectangle,
                  "width": 6,
                  "height": 6,
                  "particles": {
                    "size": {
                      "value": 5, 
                    }
                  }
                },
                {
                  "src": greenDollar,
                  "width": 6,
                  "height": 6,
                  "particles": {
                    "size": {
                      "value": 6, 
                    }
                  }
                },
                {
                  "src": blueDollar,
                  "width": 6,
                  "height": 6,
                  "particles": {
                    "size": {
                      "value": 6,
                    }
                  }
                },
              ],
              "square": {
                "options": {
                  'width': '17px',
                  'height': '28px',
                }
              }
            }
          },
        }
      }
     }
    />
    </div>
  );
}

export default ParticlesConfetti;