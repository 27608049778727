import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../../containers/Firebase";
import SponsorPurchasesDashboard from "../../../../../containers/SponsorPurchasesDashboard";
import "./List.scss";

function List({ match, firebase }) {
  const [team, setTeam] = useState();

  function loadTeamAndTeamSponsors() {
    const teamKey = match.params.team_id;
    const teamPath = `/team/${teamKey}`;
    const teamsRef = firebase.database.ref(teamPath);

    teamsRef.once("value").then((dataSnapshot) => {
      if (dataSnapshot.val() !== null) {
        const team = dataSnapshot.val();
        team.key = dataSnapshot.key;
        setTeam(team);
      }
    });
  }

  useEffect(loadTeamAndTeamSponsors, []);

  return (
    <div className="views-portal-teams-purchases-list">
      {team && (
        <>
          <h1>
            <span data-notranslate>{team.name}</span> Sponsor Purchases
          </h1>
          {team.sponsors ? (
            team.sponsors.map((sponsorKey) => (
              <SponsorPurchasesDashboard
                key={sponsorKey}
                sponsorKey={sponsorKey}
                statsOnly={true}
                disablePayout={true}
              />
            ))
          ) : (
            <div>This team does not currently have any sponsors.</div>
          )}
        </>
      )}
    </div>
  );
}

export default withFirebase(List);
