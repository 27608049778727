import React from 'react';
import moment from 'moment';

export default ({timestamp, format, hideTime}) => {
  const timestampMoment = moment(timestamp);

  const displayFormat = format ? format : 'YYYY-MM-DD';

  return (
    <span className='component-friendly-date-time'>
      {timestampMoment.format(displayFormat)}
      {
        !hideTime && ` at ${timestampMoment.format('h:mm A')}`
      }
    </span>
  );
}