import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Icon } from "@iconify/react";
import { COLORS } from "../colors";
import styled from "styled-components/macro";
import useMobile from "../../../hooks/useMobile";

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${COLORS.primaryBlue};
  &:hover {
    color: ${COLORS.primaryBlueHover};
  }
`;

const StyledTooltip = styled(UncontrolledTooltip)`
  background-color: ${COLORS.primaryBlue};
  color: ${COLORS.primaryWhite};
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  font-family: Inter;
  text-align: center;
  font-weight: 600;
  max-width: ${(props) => (props.isMobile ? "200px" : "100%")};
  line-height: 1.5;
  .tooltip-inner {
    background-color: ${COLORS.primaryBlue};
    color: ${COLORS.primaryWhite};
  }
`;

const InfoIcon = ({ text, id }) => {
  const isMobile = useMobile();
  return (
    <div>
      <div id={id}>
        <StyledIcon icon="fa-solid:info-circle" />
      </div>
      <StyledTooltip
        placement={isMobile ? "top" : "right"}
        target={id}
        isMobile={isMobile}
      >
        {text ? text : ""}
      </StyledTooltip>
    </div>
  );
};

export default InfoIcon;
