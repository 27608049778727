/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  dbLoadBrand,
  dbLoadTeam,
  dbLoadBrandSponsors,
  dbLoadBrandTeams,
} from "../../../../containers/DatabaseHelpers/DatabaseHelpers";
import { withFirebase } from "../../../../containers/Firebase";
import { Button } from "../../../../containers/UIKit";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { COLORS } from "../../../../assets/fansaves-ui";

import "./Show.scss";

const StyledH1 = styled.h1`
  margin-bottom: 0;
`;

const Subtitle = styled.div`
  color: ${COLORS.darkGrey};
`;

function Show({ firebase, match, history }) {
  const [brand, setBrand] = useState();

  /* Team Brand Type */
  const [teams, setTeams] = useState([]);

  /* Sponsor Brand Type */
  const [team, setTeam] = useState();
  const [sponsors, setSponsors] = useState([]);

  function loadBrand() {
    const brandKey = match.params.brand_id;
    dbLoadBrand(firebase, brandKey, (result) => setBrand(result));
  }

  function loadTeam() {
    if (brand && brand.teamKey) {
      dbLoadTeam(firebase, brand.teamKey, (result) => setTeam(result));
    }
  }

  function loadTeams() {
    if (brand) {
      dbLoadBrandTeams(firebase, brand.key, (results) => setTeams(results));
    }
  }

  function loadSponsors() {
    if (brand && brand.sponsorKeys) {
      dbLoadBrandSponsors(firebase, brand.key, (results) =>
        setSponsors(results)
      );
    }
  }

  function handleDeleteBrand(e) {
    e.preventDefault();
    const brandKey = match.params.brand_id;

    if (brand.offerGroups) {
      window.alert("You must delete all Deal groups first!");
    } else {
      if (window.confirm("Are you sure you want to delete this brand?")) {
        const promises = [];

        promises.push(
          firebase.storage
            .ref()
            .child(`images/brands/${brandKey}/logo.jpg`)
            .delete()
        );
        promises.push(firebase.database.ref(`/brand/${brandKey}`).remove());

        Promise.all(promises).then(() => {
          history.push("/portal/brands");
        });
      }
    }
  }

  function deleteOfferGroup(offerGroupKey, offerDescription) {
    if (
      window.confirm(`Are you sure you want to delete '${offerDescription}'?`)
    ) {
      const promises = [];
      const brandKey = match.params.brand_id;
      const offerGroupRef = firebase.database.ref(
        `/offerGroup/${offerGroupKey}`
      );
      const brandOfferGroupRef = firebase.database.ref(
        `/brand/${brandKey}/offerGroups/${offerGroupKey}`
      );
      const offerGroupImageRef = firebase.storage
        .ref()
        .child(`images/offerGroups/${offerGroupKey}/picture.jpg`);

      promises.push(offerGroupRef.remove());
      promises.push(brandOfferGroupRef.remove());
      promises.push(offerGroupImageRef.delete());

      Promise.all(promises).then(() => {
        loadBrand();
      });
    }
  }

  useEffect(() => {
    loadBrand();
  }, []);

  useEffect(() => {
    if (brand) {
      if (brand.brandType === "team") {
        loadTeams();
      } else {
        loadTeam();
        loadSponsors();
      }
    }
  }, [brand]);

  return (
    <div className="component-views-portal-brands-show">
      {brand && (
        <>
          <div className="brand-header">
            {brand.logo && <img src={brand.logo} alt="logo" className="logo" />}
            <div>
              <StyledH1 data-notranslate>{brand.name}</StyledH1>
              <Subtitle>
                {brand.brandType === "team"
                  ? "Organization Brand"
                  : "Business Brand"}
              </Subtitle>
            </div>
          </div>
          <div className="section">
            {brand.brandType === "team" ? (
              <>
                <div>
                  Organizations:{" "}
                  <span data-notranslate>
                    {teams?.length > 0 &&
                      teams.map((team) => team.name).join(", ")}
                  </span>
                </div>
                <div>
                  Categories:{" "}
                  {brand.categories ? brand.categories.join(", ") : "(None)"}
                </div>
                <div>
                  Subcategories:{" "}
                  {brand.subcategories
                    ? brand.subcategories.join(", ")
                    : "(None)"}
                </div>
              </>
            ) : (
              <>
                <div>
                  Organization:{" "}
                  <span data-notranslate>{team && team.name}</span>
                </div>
                <div>
                  Businesses:{" "}
                  <span data-notranslate>
                    {sponsors.length > 0 &&
                      sponsors.map((sponsor) => sponsor.name).join(", ")}
                  </span>
                </div>
              </>
            )}
            <Link to={`/portal/brands/${brand.key}/edit`}>Edit</Link>{" "}
            <a href="#" onClick={handleDeleteBrand}>
              Delete
            </a>
          </div>

          {brand.brandType !== "team" && (
            <div className="section">
              <h2>Deal Groups</h2>
              <div>
                <Button
                  className="primary"
                  routerLink
                  to={`/portal/brands/${brand.key}/deal-groups/create`}
                >
                  <i className="fa fa-plus" /> New Deal Group
                </Button>
              </div>
              <div className="deal-groups">
                {brand.offerGroups &&
                  Object.keys(brand.offerGroups).map((offerGroupKey) => (
                    <div className="deal-group">
                      <div>{brand.offerGroups[offerGroupKey]}</div>
                      <div className="actions">
                        <Button
                          routerLink
                          to={`/portal/brands/${brand.key}/deal-groups/${offerGroupKey}/edit`}
                          className="primary"
                        >
                          <i className="fa fa-edit" /> Edit
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            deleteOfferGroup(
                              offerGroupKey,
                              brand.offerGroups[offerGroupKey]
                            );
                          }}
                          className="error"
                        >
                          <i className="fa fa-times" /> Delete
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default withFirebase(Show);
