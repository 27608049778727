import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Months,
  DaysOptions,
  Years,
} from "../../containers/constants/DateConstants";
import { Label } from "reactstrap";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Button } from "../../containers/UIKit";
import { Icon } from "@iconify/react";
import moment from "moment";

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledSubLabel = styled(Label)`
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryGrey};
  margin-bottom: 2px;
  padding-left: 5px;
`;

const EditIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
`;

const EditIconContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  background-color: #cfe6ff;
  border-radius: 35px;
  cursor: pointer;

  &:hover {
    background-color: #b3d6ff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;
  gap: 10px;
  margin-top: 10px;
`;

const StyledButtonTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const commonStyles = {
  backgroundColor: "white",
  borderWidth: "1px",
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: '"Manrope", sans-serif',
  width: "100%",
};

const BirthdayEdit = ({ authUser, firebase }) => {
  const [editMode, setEditMode] = useState(false);
  const [dobDay, setDobDay] = useState(authUser.dobDay || "");
  const [dobMonth, setDobMonth] = useState(authUser.dobMonth || "");
  const [dobYear, setDobYear] = useState(authUser.dobYear || "");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (authUser && authUser.dob) {
      const dobComponents = authUser.dob.split("-");
      setDobYear(dobComponents[0]);
      setDobMonth(dobComponents[1]);
      setDobDay(dobComponents[2]);
    } else {
      setDobDay("");
      setDobMonth("");
      setDobYear("");
    }
  }, [authUser]);

  const handleUserInput = (selectedOption, action) => {
    const value = selectedOption ? selectedOption.value : "";
    if (action.name === "dobDay") {
      setDobDay(value);
    } else if (action.name === "dobMonth") {
      setDobMonth(value);
    } else if (action.name === "dobYear") {
      setDobYear(value);
    }
    setError("");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!dobDay || !dobMonth || !dobYear) {
      setError("Birthday is required");
      return;
    }

    try {
      setSubmitting(true);
      const userPath = `/user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);

      const dob = `${dobYear}-${dobMonth}-${dobDay}`;

      const momentDob = moment(dob);
      if (!momentDob.isValid()) {
        setError("Enter a valid date");
        setSubmitting(false);
        return;
      }

      const dateObj = moment(dob).format("YYYY-MM-DD");

      await userRef.update({
        dob: dateObj || "",
      });
      setSubmitting(false);
      setEditMode(false);
      setError("");
    } catch (error) {
      setSubmitting(false);
      setError("Error updating birthday. Please try again.");
      console.error("Error updating birthday", error);
    }
  };

  const handleCancel = () => {
    if (authUser && authUser.dob) {
      const dobComponents = authUser.dob.split("-");
      setDobYear(dobComponents[0]);
      setDobMonth(dobComponents[1]);
      setDobDay(dobComponents[2]);
    }
    setEditMode(false);
    setError("");
  };

  const getControlStyles = (error, isDisabled) => ({
    ...commonStyles,
    borderColor: error ? COLORS.errorRed : COLORS.primaryBlue,
    color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
  });

  const getSingleValueStyles = (isDisabled) => ({
    color: isDisabled ? COLORS.darkGrey : COLORS.primaryBlack,
  });

  return (
    <div className="form-section">
      <StyledLabel>Birthday</StyledLabel>
      <InputContainer>
        <div style={{ width: "100%" }}>
          <div
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="col-auto" style={{ padding: "0" }}>
              <StyledSubLabel>Year</StyledSubLabel>
              <Select
                name="dobYear"
                value={Years.find(
                  (option) => option.value?.toString() === dobYear
                )}
                onChange={handleUserInput}
                options={Years}
                placeholder="YYYY"
                isSearchable
                isDisabled={!editMode}
                styles={{
                  control: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getControlStyles(error, isDisabled),
                  }),
                  singleValue: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getSingleValueStyles(isDisabled),
                  }),
                }}
              />
            </div>
            <div className="col-auto" style={{ padding: "0" }}>
              <StyledSubLabel>Month</StyledSubLabel>
              <Select
                name="dobMonth"
                value={Months.find((option) => option.value === dobMonth)}
                onChange={handleUserInput}
                options={Months}
                placeholder="MM"
                isSearchable
                isDisabled={!editMode}
                styles={{
                  control: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getControlStyles(error, isDisabled),
                  }),
                  singleValue: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getSingleValueStyles(isDisabled),
                  }),
                }}
              />
            </div>
            <div className="col-auto" style={{ padding: "0" }}>
              <StyledSubLabel>Day</StyledSubLabel>
              <Select
                name="dobDay"
                value={DaysOptions.find((option) => option.value === dobDay)}
                onChange={handleUserInput}
                options={DaysOptions}
                placeholder="DD"
                isSearchable
                isDisabled={!editMode}
                styles={{
                  control: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getControlStyles(error, isDisabled),
                  }),
                  singleValue: (styles, { isDisabled }) => ({
                    ...styles,
                    ...getSingleValueStyles(isDisabled),
                  }),
                }}
              />
            </div>
          </div>
          {error && <ErrorMessage error={error} />}
        </div>
        <EditIconContainer onClick={() => setEditMode(!editMode)}>
          <EditIcon icon="fa-solid:pencil-alt" width={17} />
        </EditIconContainer>
      </InputContainer>
      {editMode && (
        <ButtonContainer>
          <Button
            className="green-outline-continue rounded-10 medium"
            onClick={handleCancel}
            disabled={submitting}
          >
            <StyledButtonTitle>Cancel</StyledButtonTitle>
          </Button>
          <Button
            className={`green-continue rounded-10 medium ${
              (submitting || !dobDay || !dobMonth || !dobYear) && "disabled"
            }`}
            onClick={handleUpdate}
            disabled={submitting}
          >
            <StyledButtonTitle>Save</StyledButtonTitle>
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

export default withFirebase(withAuthentication(BirthdayEdit));
