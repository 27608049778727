import React from "react";
import Select from "react-select";

import {
  Months,
  DaysOptions,
  Years,
} from "../../../containers/constants/DateConstants";

const BirthdayForm = ({
  dobMonth = "",
  dobDay = "",
  dobYear = "",
  onChange,
  errors = {},
  onFieldChangeClearError,
}) => {
  const handleUserInput = (selectedOption, action) => {
    const value = selectedOption ? selectedOption.value : "";
    onChange({ target: { name: action.name, value } });
    onFieldChangeClearError(action.name);
  };

  return (
    <div className="form-section">
      <h2>Birthday</h2>
      <div className="row">
        <div className="col-sm-4">
          <label>Month</label>
          <Select
            name="dobMonth"
            value={Months.find((option) => option.value === dobMonth)}
            onChange={handleUserInput}
            options={Months}
            isSearchable
            isClearable
          />
          {errors.dobMonth && (
            <span className="text-danger">{errors.dobMonth}</span>
          )}
        </div>
        <div className="col-sm-4">
          <label>Day</label>
          <Select
            name="dobDay"
            value={DaysOptions.find((option) => option.value === dobDay)}
            onChange={handleUserInput}
            options={DaysOptions}
            isSearchable
            isClearable
          />
          {errors.dobDay && (
            <span className="text-danger">{errors.dobDay}</span>
          )}
        </div>
        <div className="col-sm-4">
          <label>Year</label>
          <Select
            name="dobYear"
            value={Years.find((option) => option.value?.toString() === dobYear)}
            onChange={handleUserInput}
            options={Years}
            isSearchable
            isClearable
          />
          {errors.dobYear && (
            <span className="text-danger">{errors.dobYear}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default BirthdayForm;
