import React from 'react';
import SponsorPurchasesDashboard from '../../../../../containers/SponsorPurchasesDashboard';
import './List.scss';

const List = ({ match }) => {
  return (
    <div className='views-portal-sponsors-purchases-list'>
      <SponsorPurchasesDashboard sponsorKey={match.params.sponsor_id} />
    </div>
  );
};

export default List;