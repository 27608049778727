import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Button } from "../../containers/UIKit";
import { Label, Input } from "reactstrap";

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 600px;
  max-width: 493px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const InputWithErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledInput = styled(Input)`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  border: 1px solid ${COLORS.primaryBlue};
  border-color: ${({ error }) =>
    error ? COLORS.errorRed : COLORS.primaryBlue};
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;

  &:focus {
    border-color: ${COLORS.primaryBlueHover};
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
    color: ${COLORS.darkGrey};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const SuccessTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  text-align: center;
`;

const SuccessSubTitle = styled.div`
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  font-weight: 400;
  text-align: center;
`;

const ForgotPassword = ({ firebase, authUser }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (authUser) {
      setEmail(authUser.email || "");
    }
  }, [authUser]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    setSubmitting(true);
    try {
      await firebase.auth.sendPasswordResetEmail(email);
      setSuccess(true);
      setError("");
    } catch (error) {
      console.error(error);
      setSuccess(false);
      setError(error.message);
    }
    setSubmitting(false);
  };

  return (
    <ForgotPasswordContainer>
      <Title>Profile / Forgot Password</Title>

      {!success ? (
        <>
          <SubTitle>
            Enter your email and we'll send you a link to reset your password
          </SubTitle>
          <InputWithErrorContainer>
            <StyledLabel>Email</StyledLabel>
            <StyledInput
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              onFocus={() => setError("")}
              error={error}
              disabled={submitting}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleResetPassword(e);
                }
              }}
            />
            {error && <ErrorMessage error={error} />}
          </InputWithErrorContainer>
          <ButtonContainer>
            <Button
              className={`green-continue rounded-10 wide ${
                (submitting || !email) && "disabled"
              }`}
              onClick={handleResetPassword}
            >
              <StyledButtonTitle disabled={submitting || !email}>
                Reset Password
              </StyledButtonTitle>
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <SuccessContainer>
          <Icon
            icon="carbon:checkmark-filled"
            color={COLORS.primaryGreenDark}
            width={60}
          />
          <SuccessTitle>Email Sent</SuccessTitle>
          <SuccessSubTitle>
            Check your email and open the link we sent to continue.
          </SuccessSubTitle>
        </SuccessContainer>
      )}
    </ForgotPasswordContainer>
  );
};

export default withFirebase(withAuthentication(ForgotPassword));
