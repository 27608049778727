// DEFAULT LAYOUT COMPONENTS -------------------------------------
import {
  Home,
  HowItWorks,
  UnlockExclusives,
  FrenchEmails,
  FeedPage,
} from "./views/Pages";

import {
  List as DealsList,
  Show as DealsShow,
  ShowFlash as DealsShowFlash,
  PurchaseConfirmation as DealsPurchaseConfirmation,
} from "./views/Deals";

import { Show as DealGroupsShow } from "./views/DealGroups";

import { Show as BrandsShow } from "./views/Brands";

import {
  List as PurchasesList,
  Show as PurchasesShow,
} from "./views/Purchases";

import {
  List as OrganizationsList,
  Show as OrganizationsShow,
} from "./views/Organizations";

import { Edit as ProfilesEdit } from "./views/Profiles";
import { Onboarding } from "./views/Onboarding";

import TermsAndConditions from "./views/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy";

// DEFAULT LAYOUT ROUTES -----------------------------------------------
const FeedPageRoute = {
  path: "/feed",
  name: "Feed",
  component: FeedPage,
};

const HowItWorksRoute = {
  path: "/how-it-works",
  name: "How it works",
  component: HowItWorks,
};
const UnlockExclusivesRoute = {
  path: "/unlock-exclusives",
  name: "Unlock Exclusives",
  component: UnlockExclusives,
};

const FrenchEmailsRoute = {
  path: "/email-francais/:email_id",
  name: "French Emails",
  component: FrenchEmails,
};
const DealsPurchaseConfirmationRoute = {
  path: "/deals/:deal_id/purchase_confirmation",
  name: "Deal Purchase Confirmation",
  component: DealsPurchaseConfirmation,
};
const DealsShowFlashRoute = {
  path: "/deals/:deal_id/show_flash",
  name: "Deal Info",
  component: DealsShowFlash,
};
const DealsShowRoute = {
  path: "/deals/:deal_id",
  name: "Deal Info",
  component: DealsShow,
};
const DealsListRoute = { path: "/deals", name: "Deals", component: DealsList };
const DealGroupsShowRoute = {
  path: "/deal_groups/:deal_group_id",
  name: "Deal Group Info",
  component: DealGroupsShow,
};

const BrandsShowRoute = {
  path: "/brands/:brand_id",
  name: "Brand Info",
  component: BrandsShow,
};

const PurchasesShowRoute = {
  path: "/purchases/:purchase_id",
  name: "Purchase Info",
  component: PurchasesShow,
};
const PurchasesListRoute = {
  path: "/purchases",
  name: "Purchases",
  component: PurchasesList,
};
const OrganizationsShowRoute = {
  path: "/organizations/:organization_id",
  name: "Organization Info",
  component: OrganizationsShow,
};
const OrganizationsListRoute = {
  path: "/organizations",
  name: "Organizations",
  component: OrganizationsList,
};
const ProfileEditRoute = {
  path: "/profile/edit",
  name: "Profile",
  component: ProfilesEdit,
};

const OnboardingRoute = {
  path: "/onboarding",
  name: "Onboarding",
  component: Onboarding,
};

const TermsAndConditionsRoute = {
  path: "/terms-and-conditions",
  name: "Terms and Conditions",
  component: TermsAndConditions,
};

const PrivacyPolicyRoute = {
  path: "/privacy-policy",
  name: "Privacy Policy",
  component: PrivacyPolicy,
};

// add home page route for authenticated users to be able to access the home page
const HomePageRoute = {
  path: "/home",
  name: "Home",
  component: Home,
};

const HomeRoute = { path: "/", name: "Home", component: Home };

/* IMPORTANT!!! More specific routes need to be declared first. Ref: React Router documentation */
const defaultLayoutRoutes = [
  OnboardingRoute,
  HowItWorksRoute,
  UnlockExclusivesRoute,
  FrenchEmailsRoute,
  DealsPurchaseConfirmationRoute,
  DealsShowFlashRoute,
  DealsShowRoute,
  DealsListRoute,
  DealGroupsShowRoute,
  BrandsShowRoute,
  PurchasesShowRoute,
  PurchasesListRoute,
  OrganizationsShowRoute,
  OrganizationsListRoute,
  ProfileEditRoute,
  TermsAndConditionsRoute,
  PrivacyPolicyRoute,
  HomePageRoute,
  FeedPageRoute,
  HomeRoute,
];

export { defaultLayoutRoutes };
