import React from 'react';
import { observer } from 'mobx-react-lite';
import SubCategoryContainer from './SubcategoryContainer';
import {
  Input,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  ListGroup
} from 'reactstrap';

const CategoryContainer = observer(
  ({category}) => {
    const {
      name,
      subCategories,
      createSubCategory,

      isEditing,
      newCategoryName,
      editButtonOnClick,
      saveCategoryNameOnClick,
      newCategoryNameOnChange,
      removeButtonOnClick,

      toBeCreatedSubCategoryName,
      toBeCreatedSubCategoryNameOnChange
    } = category;

    return (
      <Card>
        <CardBody>
          <CardTitle>
            {
              isEditing ?
              <Row>
                <Col sm={8}>
                  <input value={newCategoryName} onChange={e => newCategoryNameOnChange(e.target.value)}/>
                </Col>
                <Col sm={4} className="action-buttons">
                  <Button color="primary" onClick={saveCategoryNameOnClick}>Save</Button>
                </Col>
              </Row>
              :
              <Row>
                <Col sm={8} className="category-subcategory-name">
                  {name}
                </Col>
                <Col sm={4} className="action-buttons">
                  <Button color="primary" onClick={editButtonOnClick}>Edit</Button>
                  <Button color="danger" onClick={removeButtonOnClick}>Remove</Button>
                </Col>
              </Row>
            }
          </CardTitle>
          <div className="subcategories-container">
            <Card>
              <CardBody>
                <CardTitle>Subcategories</CardTitle>
                <ListGroup>
                  {
                    subCategories.map((subCategory) => 
                      <SubCategoryContainer key={subCategory.id} subCategory={subCategory}/>
                    )
                  }  
                </ListGroup>
              </CardBody>
            </Card>
            <Row className="category-add-component">
              <Col sm={10}>
                <Input value={toBeCreatedSubCategoryName} onChange={e => toBeCreatedSubCategoryNameOnChange(e.target.value)}/>
              </Col>
              <Col sm={2}>
                <Button color="primary" block outline onClick={() => createSubCategory()}>Add Subcategory</Button>
              </Col>
            </Row>
          </div>  
        </CardBody>
      </Card>
    )
  }
);

export default CategoryContainer;