export function uploadResourceImage(firebase, path, file) {
  const storageRef = firebase.storage.ref();

  return storageRef.child(path).put(file)
    .then(dataSnapshot => dataSnapshot.ref.getDownloadURL());
};

export function uploadResourceImages(firebase, resourceKey, updateData, storageRoot, possibleTypesOfImages, onImagesUploaded) {
  const resourceStorageBasePath = `${storageRoot}/${resourceKey}/`

  const imageUploadPromises = [];
  const presentTypesOfImages = [];

  possibleTypesOfImages.forEach((typeOfImage) => {
    if (updateData[`${typeOfImage}File`]) {

      // Save for later
      presentTypesOfImages.push(typeOfImage);

      let fileName = `${typeOfImage}.jpg`;
      
      if(typeOfImage === 'teamPhoto') {  
        fileName = `team_photo.jpg`;
      }

      const imagePath = `${resourceStorageBasePath}${fileName}`;
      imageUploadPromises.push(uploadResourceImage(firebase, imagePath, updateData[`${typeOfImage}File`]));
    }
  });

  Promise.all(imageUploadPromises).then((urls) => {
    const imageUrls = {};

    for(let i = 0; i < urls.length; i++) {
      imageUrls[`${presentTypesOfImages[i]}Url`] = urls[i];
    }

    onImagesUploaded(resourceKey, updateData, imageUrls);
  });
}