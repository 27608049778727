import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../colors";

const StyledStepProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin: auto;
  padding: 10px;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${COLORS.primaryBlue};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ completed }) =>
    completed ? COLORS.primaryBlue : COLORS.primaryWhite};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.primaryBlack};
`;

const StepProgressBar = ({ stepsTotal, currentStep }) => {
  return (
    <StyledStepProgressBar>
      {Array.apply(null, Array(stepsTotal)).map((_, index) => {
        const stepNumber = index + 1;
        const isCompleted = stepNumber <= currentStep;
        return (
          <React.Fragment key={index}>
            <StepContainer>
              <Step>
                <Circle completed={isCompleted} />
              </Step>
            </StepContainer>
            {stepNumber < stepsTotal && <Line />}
          </React.Fragment>
        );
      })}
    </StyledStepProgressBar>
  );
};

export default StepProgressBar;
