import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../containers/Firebase";
import { withAuthentication } from "../../../../containers/Session";

const Welcome = (props) => {
  useEffect(() => {
    const { authUser } = props;
    if (authUser) {
      if (authUser.role === "admin") {
        props.history.push("/portal/dashboard");
      } else if (authUser.role === "team") {
        props.history.push("/portal/teams/" + authUser.teamID + "/dashboard");
      } else if (authUser.role === "sponsor") {
        props.history.push(
          "/portal/sponsors/" + authUser.sponsorID + "/dashboard"
        );
      }
    }
  }, [props.authUser]);

  return (
    <div>
      <div>Welcome to the portal.</div>
    </div>
  );
};

export default withFirebase(withAuthentication(withRouter(Welcome)));
