import React, { useState, useEffect } from 'react';
import {
  Form as ReactStrapForm,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { dbLoadBrandOffers } from '../../../../../containers/DatabaseHelpers/DatabaseHelpers';
import { withFirebase } from '../../../../../containers/Firebase';
import { SelectableList } from '../../../../../containers/UIKit';
import { imageUploadInputStyle, imageStyle, labelStyle } from '../../../utilities/PortalFormStyles';
import ImageUploader from '../../../../../containers/ImageUploader';

function Form({ brandKey, existingDealGroup, onSubmit, firebase }) {

  const initialExclusiveString = (existingDealGroup && existingDealGroup.exclusive !== null) ? (existingDealGroup.exclusive ? 'true' : 'false') : 'false';

  const [offers, setOffers] = useState([]);
  const [exclusiveString, setExclusiveString] = useState(initialExclusiveString);
  const [pictureFile, setPictureFile] = useState();
  const [picturePath, setPicturePath] = useState(existingDealGroup ? existingDealGroup.picture : '');
  const [description, setDescription] = useState(existingDealGroup ? existingDealGroup.description : '');
  const [selectedOfferKeys, setSelectedOfferKeys] = useState(existingDealGroup && existingDealGroup.offerKeys ? existingDealGroup.offerKeys : []);

  function validateForm() {
    if(description.trim().length === 0) {
      window.alert("Please enter a description.");
      return false;
    }

    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    
    if(validateForm()) {
      const dealGroupData = {
        description,
        exclusive: exclusiveString === 'true',
        offerKeys: selectedOfferKeys,
        pictureFile,
        picturePath,
      };

      onSubmit(dealGroupData);
    }
  }

  function handleOfferToggle(offerKey) {
    if(selectedOfferKeys.includes(offerKey)) {
      setSelectedOfferKeys(selectedOfferKeys.filter(key => key !== offerKey));
    } else {
      setSelectedOfferKeys([...selectedOfferKeys, offerKey]);
    }
  }

  function loadTeamOffers() {
    dbLoadBrandOffers(firebase, brandKey, (results) => {

      const offersWithArchivedDescription = [];

      results.forEach((result) => {
        const newOffer = {...result};
        newOffer.archivedDescription = `${result.description}${result.active ? '' : ' (archived)'}`;
        offersWithArchivedDescription.push(newOffer);
      });

      setOffers(offersWithArchivedDescription);
    });
  }

  function pictureClick() {
    // TODO: Should find a way to do this with refs;
    document.getElementById("picture_upload").click();
  }

  function pictureInputChange(e) {
    if (e.target.files) {
      const file = e.target.files[0];
      setPictureFile(file);
      setPicturePath(URL.createObjectURL(file));
    }
  }

  useEffect(loadTeamOffers, []);

  return (
    <div>
      <ReactStrapForm onSubmit={handleSubmit}>
        <div className='form-section'>
        <div className='row'>
          <div className="col-4 offset-md-4">
            <FormGroup className="text-center">
              <Label style={labelStyle}><strong>PICTURE</strong></Label>
              <ImageUploader style={imageStyle} src={picturePath} onClick={pictureClick} alt="Logo" />
              <Input style={imageUploadInputStyle} name="picture" onChange={pictureInputChange} id="picture_upload" type="file" />
            </FormGroup>
          </div>
        </div>

          <div className='row'>
            <div className="col-sm-12 col-md-6">
              <h2>Basic Info</h2>
              <FormGroup>
                <Label>Description</Label>
                <Input name="description" value={description} onChange={(e) => setDescription(e.target.value)}></Input>
              </FormGroup>

              <FormGroup>
                <Label>Visibility</Label>
                <Input type="select" name="exclusiveString" id="exclusiveString" value={exclusiveString} onChange={(event) => setExclusiveString(event.target.value)}>
                  <option value='false'>Public</option>
                  <option value='true'>Exclusive</option>
                </Input>
              </FormGroup>
            </div>
          </div>
        </div>

        <div className='form-section'>
          <div className='row'>
            <div className="col-sm-12 col-md-6">
              <h2>Deals</h2>
              <SelectableList items={offers} displayField="archivedDescription" valueField="key" selectedValues={selectedOfferKeys} onItemToggle={handleOfferToggle} />
            </div>
          </div>
        </div>

        <div className='form-section'>
          <div className='row'>
            <div className="col-12 col-md-6">
              <Input type="submit" value="Submit"/>
            </div>
          </div>
        </div>
      </ReactStrapForm>
    </div>
  );
}

export default withFirebase(Form);