import React from "react";
import propTypes from "prop-types";
import { CSVLink } from "react-csv";

const CSVExport = ({ data, headers, filename, className, children }) => {
  return (
    <CSVLink
      data={data}
      headers={headers}
      filename={filename
        .split(" ")
        .join("-")
        .toLowerCase()
        .replace(/[^\w\s-]/gi, "")}
      className={className}
    >
      {children}
    </CSVLink>
  );
};

export default CSVExport;

CSVExport.propTypes = {
  data: propTypes.array.isRequired,
  headers: propTypes.array.isRequired,
  filename: propTypes.string.isRequired,
  className: propTypes.string,
  children: propTypes.node,
};
