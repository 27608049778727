import React from "react";
import { InstantSearch, Hits, PoweredBy } from "react-instantsearch-dom";
import {
  searchClient,
  SearchBox,
  VirtualRefinementList,
  SortedResultsWithEmptyState,
} from "../../../containers/Algolia";
import { EmbedSizeDealSearchResult } from "../../../containers/DealSearchResult";
import { orderBy } from "lodash";
import ContestRefinementList from "./ContestRefinementList";

import "./ContestsTab.scss";

function ContestsTab({ organization }) {
  return (
    <InstantSearch searchClient={searchClient} indexName="offers">
      <div className="component-contests-tab">
        <div className="deals-list">
          <VirtualRefinementList
            attribute="teams.name"
            transformItems={(items) => orderBy(items, "label", "asc")}
            defaultRefinement={[organization.name]}
          />
          <VirtualRefinementList
            attribute="published"
            defaultRefinement={["true"]}
          />
          <VirtualRefinementList
            attribute="contestDeal"
            defaultRefinement={["true"]}
          />

          <div className="deal-filters">
            <ContestRefinementList
              attribute="contestDeal"
              transformItems={(items) => orderBy(items, "label", "asc")}
              defaultRefinement={["true"]}
            />
            <div className="search-bar-container">
              <SearchBox hideSubmit={true} placeholder="Search contests..." />
            </div>
          </div>
          <div className="search-results">
            <SortedResultsWithEmptyState sortedBy="pinned">
              <Hits hitComponent={EmbedSizeDealSearchResult} />
            </SortedResultsWithEmptyState>

            <div className="algolia-powered-by-container">
              <PoweredBy />
            </div>
          </div>
        </div>
      </div>
    </InstantSearch>
  );
}

export default ContestsTab;
