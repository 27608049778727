import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session';
import {
  Button,
  Row,
} from 'reactstrap';
import PropTypes from 'prop-types';

import './PaymentsSetup.scss';
import cloudFunctionHost from '../CloudFunctionsHost';

class PaymentsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStripeInformation: true,
      fetchingStripeAccountLink: false,
    };
  }

  componentDidMount() {
    this.loadResource();
  }

  loadResource = () => {
    const {
      resourceType,
      resourceId,
    } = this.props;
    
    const resourcePath = `/${resourceType}/${resourceId}`;
    const resourceRef = this.props.firebase.database.ref(resourcePath);

    resourceRef.once("value").then(dataSnapshot => {
      const resource = dataSnapshot.val();
      resource.key = dataSnapshot.key;

      this.setState({ resource }, this.loadStripeAccount);
    });
  }

  loadStripeAccount = () => {
    const { firebase, resourceType, resourceId } = this.props;
    const {
      resource
    } = this.state;

    if(resource.stripeAccountId) {
      firebase.auth.currentUser.getIdToken()
        .then((token) => {
          fetch(`${cloudFunctionHost}/authorizedFunctions/getStripeAccount?resourceType=${resourceType}&resourceId=${resourceId}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then((responseObject) => {
            if(responseObject.message) {
              this.setState({ loadingStripeInformation: false, statusMessage: responseObject.message });
            } else {
              const stripeAccount = responseObject;
              this.setState({ loadingStripeInformation: false, stripeAccount }, this.checkIfRefreshingFromStripe);
            }
          });
        });
    } else {
      this.setState({ loadingStripeInformation: false });
    }
  }

  handleSetupClick = (e) => {
    e.preventDefault();
    this.getStripeAccountLinkAndRedirect();
  }

  checkIfRefreshingFromStripe = () => {
    const { location, refresh } = this.props;

    if(refresh) {
      this.setState({ loadingStripeInformation: true }, this.getStripeAccountLinkAndRedirect);
    }
  }

  getStripeAccountLinkAndRedirect = () => {
    const { firebase, resourceType, resourceId } = this.props;

    this.setState({ statusMessage: false, fetchingStripeAccountLink: true });

    firebase.auth.currentUser.getIdToken()
      .then((token) => {
        fetch(`${cloudFunctionHost}/authorizedFunctions/getStripeAccountLink`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ resourceType, resourceId })
        })
        .then(response => response.json())
        .then(responseObject => {
          if(responseObject.url) {
            const stripeAccountLink = responseObject;
            window.location.href = stripeAccountLink.url;
          } else {
            this.setState({ 
              fetchingStripeAccountLink: false, 
              statusMessage: responseObject.message 
            });
          }
        });
      });
  }

  render() {
    const { 
      resource,
      fetchingStripeAccountLink,
      statusMessage,
      loadingStripeInformation,
      stripeAccount,
    } = this.state;

    return (
      <div className="animated fadeIn component-payments-setup" id="main">
        {
          (!resource || loadingStripeInformation) ?
            <div>
              <i className='fa fa-circle-o-notch fa-spin' /> One moment please...
            </div>
          :
          <>
            <h1>Payments Setup</h1>
            <p>
              Welcome to the Payments Setup page for <b>{resource.name}</b>.<br/>
              Before you can benefit from Flash Deals, it's necesssary to set up
              this section to ensure you get paid automatically.
            </p>
            <Row>
              <div className='col col-12'>
                {
                  (stripeAccount && stripeAccount.details_submitted) ?
                    <div>
                      <div className='successful-setup'>
                        <i className='fa fa-check-circle' />
                        <div className='text'>
                          <b>You are all setup and ready to receive payments from FanSaves!</b>
                        </div>
                      </div>

                      <div className='edit-stripe-account-container'>
                        To edit your account information or provide more information, click <a href='#' onClick={this.handleSetupClick}>here</a>.
                      </div>
                   </div>
                  :
                    <div>
                      <p>
                        To get started, simply click 'Begin Setup' below.
                      </p>
                      {
                        fetchingStripeAccountLink ?
                          <div><i className='fa fa-circle-o-notch fa-spin' /> One moment...</div>
                        :
                          <Button onClick={this.handleSetupClick} color='primary'>Begin Setup</Button>
                      }
                    </div>
                }
              </div>
            </Row>
            {
              statusMessage &&
              <Row>
                <div className='col col-12'>
                  {statusMessage}
                </div>
              </Row>
            }

            <Row className='stripe-legal'>
              <div className='col col-12'>
                Your banking information will be handled safely and securely through Stripe Connect.
                For their complete terms of service,
                click here: <a target='_blank' href='https://stripe.com/en-ca/connect-account/legal'>https://stripe.com/en-ca/connect-account/legal</a>
              </div>
            </Row>
          </>
        }
      </div>
    );
  }
}

PaymentsSetup.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  refresh: PropTypes.bool,
};

export default withAuthentication(withFirebase(PaymentsSetup));
