import cloudFunctionHost from "../containers/CloudFunctionsHost";

const getPointSystem = (firebase, onSuccess, onError) => {
  firebase.database
    .ref("pointSystem")
    .once("value")
    .then((snapshot) => {
      const pointSystem = snapshot.val();
      onSuccess(pointSystem);
    })
    .catch((error) => {
      onError(error);
    });
};

const getUserGroupPreferences = (firebase, onSuccess, onError) => {
  firebase.database
    .ref("userGroupPreferences")
    .once("value")
    .then((snapshot) => {
      const userGroupPreferences = snapshot.val();
      onSuccess(userGroupPreferences);
    })
    .catch((error) => {
      onError(error);
    });
};

const userGenderGroups = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-binary", value: "non-binary" },
  { label: "Rather not say", value: "rather-not-say" },
];

const userAgeGroups = [
  { label: "0-19", value: "0-19" },
  { label: "20-29", value: "20-29" },
  { label: "30-39", value: "30-39" },
  { label: "40-49", value: "40-49" },
  { label: "50-59", value: "50-59" },
  { label: "60+", value: "60-100" },
];

const fetchReport = async (firebase, reportType) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchReportData`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ reportType }),
      }
    );
    const responseObject = await response.json();
    return responseObject;
  } catch (error) {
    console.error(`error fetching ${reportType} report`, error);
    return null;
  }
};

const fetchRedemptionsByChunks = async (
  firebase,
  lastVisible = null,
  limit = 1000
) => {
  const token = await firebase.auth.currentUser.getIdToken();
  if (!token) {
    console.error("No token");
    return {
      redemptions: [],
      lastVisible: null,
    };
  }

  try {
    let query = firebase.firestore.collection("redemptions").limit(limit);

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    const snapshot = await query.get();
    const redemptionsData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const newLastVisible = snapshot.docs[snapshot.docs.length - 1];

    return {
      redemptions: redemptionsData,
      lastVisible: newLastVisible,
    };
  } catch (error) {
    console.error("Error fetching redemptions by chunks:", error);
    return {
      redemptions: [],
      lastVisible: null,
    };
  }
};

const fetchRedemptions = async (firebase) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchRedemptions`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const responseObject = await response.json();
    return responseObject;
  } catch (error) {
    console.error("error fetching redemptions", error);
    return null;
  }
};

const fetchExclusiveCodes = async (firebase, teamId) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }
    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchExclusiveCodes`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId }),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("error fetching exclusive codes", error);
    return null;
  }
};

const fetchExclusiveCodeTotals = async (firebase, teamId) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchExclusiveCodeTotals`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId }),
      }
    );
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("error fetching exclusive code totals", error);
    return null;
  }
};

const createExclusiveCodes = async (firebase, teamId, codes) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/createExclusiveCodes`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId, codes }),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("error creating exclusive codes", error);
    return null;
  }
};

const createSpecialCodes = async (firebase, teamId, codes, accessLevel) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/createSpecialCodes`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId, codes, accessLevel }),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("error creating special codes", error);
    return null;
  }
};

const fetchSpecialCodes = async (firebase, teamId, accessLevel) => {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    if (!token) {
      console.error("No token");
      return null;
    }

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchSpecialCodes`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ teamId, accessLevel }),
      }
    );
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    console.error("error fetching special codes", error);
    return null;
  }
};

export {
  getPointSystem,
  getUserGroupPreferences,
  userGenderGroups,
  userAgeGroups,
  fetchReport,
  fetchRedemptions,
  fetchRedemptionsByChunks,
  fetchExclusiveCodes,
  fetchExclusiveCodeTotals,
  createExclusiveCodes,
  createSpecialCodes,
  fetchSpecialCodes,
};
