/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import queryString from "query-string";
import styled from "styled-components/macro";
import { withRouter } from "react-router-dom";
import DashboardFilterDisplay from "../DashboardFilterDisplay";
import {
  FlexContainer,
  DashboardFilter,
  Grid,
  GridRow,
  SearchBar,
  GLOBALS,
} from "../../assets/fansaves-ui";
import { UncontrolledTooltip } from "reactstrap";

import "../../scss/base/typography.scss";
import moment from "moment";
import { getLocation, calculateAge } from "../../utilities/dashboard_helpers";

const StyledDashboardFollowersTable = styled(FlexContainer)`
  margin: 120px auto 0 auto;
  .followers-title {
    margin-bottom: 10px;
  }
  .component-dashboard-filter {
    position: absolute;
    top: -10px;
    left: 0;
  }
  .search-bar {
    position: absolute;
    top: -110px;
    right: 0;
  }
  .component-dashboard-filter-display {
    position: absolute;
    top: -100px;
    left: 0px;

    @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
      position: absolute;
      top: -60px;
      right: 0;
    }
    .no-followers-message {
      margin-top: 50px;
    }
    .tooltip {
      width: fit-content;
    }
  }
`;

const DashboardFollowersTable = ({ followers }) => {
  const [filteredFollowers, setFilteredFollowers] = useState(followers);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const getHeader = () => {
    const header = [
      "Name",
      "Email",
      "Age",
      "Gender",
      "Location",
      "Account Created",
      "Date Followed",
      "Date of Last Redemption",
    ];
    return header;
  };

  const updateLocation = (follower) => {
    const { city: userCity, state } = follower;
    let city = userCity ? (state ? `${userCity}, ${state}` : userCity) : null;
    if (!city && follower.zip) {
      const { city: userCity } = getLocation(follower.zip);
      city = userCity;
    }
    return city;
  };

  const filterFollowers = (filter) => {
    let tempFollowers = followers;
    const thisYear = moment().startOf("year").format("YYYY");
    const lastYear = moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY");
    if (filter.includes("days")) {
      const numberOfDays = parseInt(filter.match(/\d+/g)[0]);
      tempFollowers = tempFollowers.filter((follower) => {
        return (
          moment().diff(
            moment(follower.followedAt).format("YYYY-MM-DD"),
            "days"
          ) < numberOfDays
        );
      });
    } else if (filter === "ThisYear") {
      tempFollowers = tempFollowers.filter((follower) => {
        return moment(follower.followedAt)
          .format("YYYY-MM-DD")
          .includes(thisYear);
      });
    } else if (filter === "LastYear") {
      tempFollowers = tempFollowers.filter((follower) => {
        return moment(follower.followedAt)
          .format("YYYY-MM-DD")
          .includes(lastYear);
      });
    } else if (filter === "All") {
      tempFollowers = followers;
    }
    setFilteredFollowers(tempFollowers);
  };

  const getQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const filter = search.filter ? search.filter : "All";
    const query = search.query ? search.query : null;

    setFilter(filter);
    if (query) {
      setSearchQuery(query);
    }
  };

  const handleSearch = () => {
    if (!searchQuery) {
      return;
    }

    if (followers && followers.length > 0) {
      const tempFollowers = followers.filter((follower) => {
        if (
          follower.email &&
          follower.email.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return follower;
        }

        return null;
      });
      setFilteredFollowers(tempFollowers);
    }
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (filter) {
      params.filter = filter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };

  useEffect(() => {
    handleSearch();
    setUrlQueryFromFilters();
  }, [searchQuery]);

  useEffect(() => {
    if (filter) {
      filterFollowers(filter);
    }

    setUrlQueryFromFilters();
  }, [filter]);

  useEffect(() => {
    getQueryFromSearch();
  }, []);

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setSearchQuery(null);
  };

  const renderFollowerRow = (follower, index, hideBorder) => {
    return (
      <GridRow
        className="dashboard-followers-table-row"
        key={`follower-row-${follower.lastRedemption}-${follower.key}`}
        columnsCount={getHeader().length}
        hideBorder={hideBorder ? true : false}
      >
        <div className="row-item narrow-row-item flex-start-row-item">N/A</div>
        <div className="row-item ellipsis-row-item wide-row-item flex-start-row-item">
          <div className="email" id={`email-${index}`}>
            {follower.email}
          </div>
          <UncontrolledTooltip
            placement="top"
            target={`email-${index}`}
            className="tooltip"
          >
            <div className="email">{follower.email}</div>
          </UncontrolledTooltip>
        </div>
        <div className="row-item narrow-row-item flex-start-row-item">
          {calculateAge(follower.dob)}
        </div>
        <div className="row-item narrow-row-item flex-start-row-item">
          {follower.gender}
        </div>
        <div className="row-item ellipsis-row-item wide-row-item flex-start-row-item">
          <div className="location">{updateLocation(follower)}</div>
        </div>
        <div className="row-item flex-start-row-item">
          {follower.createdAt ? (
            moment(follower.createdAt).format("YYYY/MM/DD HH:mm A")
          ) : (
            <div className="not-available">N/A</div>
          )}
        </div>
        <div className="row-item flex-start-row-item">
          {follower.followedAt ? (
            moment(follower.followedAt, "YYYY-MM-DD:hh:mm:ss").format(
              "YYYY/MM/DD HH:mm A"
            )
          ) : (
            <div className="not-available">N/A</div>
          )}
        </div>
        <div className="row-item flex-start-row-item">
          {follower.lastRedemption ? (
            moment(follower.lastRedemption, "YYYY-MM-DD:hh:mm:ss").format(
              "YYYY/MM/DD HH:mm A"
            )
          ) : (
            <div className="not-available">N/A</div>
          )}
        </div>
      </GridRow>
    );
  };

  return (
    <StyledDashboardFollowersTable className="component-dashboard-followers-table">
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder="Search"
        className="search-bar"
      />
      <DashboardFilterDisplay
        renderCount={`Followers: ${filteredFollowers.length}`}
        filter={filter}
      />
      <DashboardFilter
        type="days"
        className="component-dashboard-filter inter-b2"
        onSelect={handleFilterChange}
        initialFilterOption={filter}
      />
      <Grid
        className="dashboard-followers-table"
        columns={getHeader().length}
        margin="0 0 50px 0"
      >
        <GridRow
          className="dashboard-followers-table-header inter-b2"
          columnsCount={getHeader().length}
          boldBorder
        >
          {getHeader().map((header, index) => {
            return (
              <div
                className={`row-item ${
                  header === "Email" || header === "Location"
                    ? "wide-row-item"
                    : "" ||
                      header === "Age" ||
                      header === "Gender" ||
                      header === "Name"
                    ? "narrow-row-item"
                    : ""
                } flex-start-row-item`}
                key={`header-${index}`}
              >
                {header}
              </div>
            );
          })}
        </GridRow>
        {filteredFollowers && filteredFollowers.length > 0 ? (
          <div className="dashboard-followers-table-wrapper inter-b2">
            {filteredFollowers.map((follower, i) => {
              return renderFollowerRow(follower, i);
            })}
          </div>
        ) : (
          <FlexContainer className="no-followers" alignItem="center">
            <div className="no-followers-message">
              No followers found. Select another filter option.
            </div>
          </FlexContainer>
        )}
      </Grid>
    </StyledDashboardFollowersTable>
  );
};

export default withRouter(DashboardFollowersTable);

DashboardFollowersTable.propTypes = {
  followers: propTypes.array,
};
