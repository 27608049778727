import React from "react";
import { SearchBox as AlgoliaSearchBox } from "react-instantsearch-dom";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import { Icon } from "@iconify/react";

const FanPagesSelectorSearchBoxContainer = styled.div`
  width: 100%;
  position: relative;
  /* display: flex; */
  /* justify-content: center; */
  .ais-SearchBox {
    .ais-SearchBox-form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .ais-SearchBox-input {
        height: 40px;
        width: 80%;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        font-family: "Manrope", sans-serif;
        padding: 0 20px;
        padding-left: 40px;
        border: 1px solid ${COLORS.primaryBlue};

        &::placeholder {
          color: #00000075;
        }
      }

      button.ais-SearchBox-submit {
        border: none;
        background: none;
        outline: none;
      }

      button.ais-SearchBox-reset {
        display: none;
      }

      &:focus-within {
        .ais-SearchBox-input {
          outline: none;
          border: 1px solid ${COLORS.primaryBlue};
        }
      }
    }
    .search-icon {
      position: absolute;
      left: 10.5%;
      top: 50%;
      transform: translateY(-50%);
      color: ${COLORS.primaryBlue};
    }
  }
`;

const FanPagesSelectorSearchBox = (props) => {
  const { hideSubmit, placeholder } = props;

  let submit = (
    <span>
      <Icon icon="mdi-search" className="search-icon" width={22} />
    </span>
  );
  if (hideSubmit) {
    submit = null;
  }

  return (
    <FanPagesSelectorSearchBoxContainer>
      <AlgoliaSearchBox
        submit={submit}
        translations={{
          placeholder: placeholder || "Search FanPages",
        }}
        {...props}
      />
    </FanPagesSelectorSearchBoxContainer>
  );
};

export default FanPagesSelectorSearchBox;
