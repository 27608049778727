/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import { DefaultContainer } from "../../../containers/UIKit";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { Button } from "../../../containers/UIKit";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { loadTeams, unlockExclusives } from "../../../utilities/team_helpers";
import LoginModal from "../../../containers/LoginModal";
import "./UnlockExclusives.scss";
import queryString from "query-string";
import { InfoIcon, COLORS } from "../../../assets/fansaves-ui";
import Select, { components } from "react-select";
import { Icon } from "@iconify/react";
import styled from "styled-components/macro";

const StyledDropdownIcon = styled(Icon)`
  color: ${COLORS.primaryBlue};
  &:hover {
    color: ${COLORS.primaryGreen};
  }
`;

const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    color: COLORS.primaryBlue,
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: "600",
    borderRadius: "10px",
    border: `1px solid ${
      state.isFocused || state.menuIsOpen
        ? COLORS.primaryGreen
        : COLORS.primaryBlue
    }`,
    borderColor:
      state.isFocused || state.menuIsOpen
        ? COLORS.primaryGreen
        : COLORS.primaryBlue,
    boxShadow: state.isFocused || state.menuIsOpen ? "0 0 0 1px #35b729" : null,
    "&:hover": {
      borderColor: COLORS.primaryGreen,
    },
    background: COLORS.primaryWhite,
  }),
};
const { Option, SingleValue, DropdownIndicator } = components;

const CustomOption = (props) => (
  <Option {...props}>
    <span data-notranslate>{props.data.label}</span>
  </Option>
);

const CustomSingleValue = ({ ...props }) => (
  <SingleValue {...props}>
    <span data-notranslate>{props.data.label}</span>
  </SingleValue>
);

const CustomDropdownIndicator = (props) => (
  <DropdownIndicator {...props}>
    <StyledDropdownIcon icon="fa-solid:chevron-down" />
  </DropdownIndicator>
);

function UnlockExclusives({ authUser, firebase, teamKey }) {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [organizationKey, setOrganizationKey] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [exclusiveCode, setExclusiveCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    loadTeams(firebase, (results) => {
      setOrganizations(
        results.map((org) => ({ value: org.key, label: org.name }))
      );
    });
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const orgKey = parsed.organizationKey || teamKey;
    if (orgKey) {
      const selectedOrg = organizations.find((org) => org.value === orgKey);
      if (selectedOrg) {
        setSelectedOrganization(selectedOrg);
      }
    }
  }, [organizations]);

  useEffect(() => {
    if (selectedOrganization) {
      setOrganizationKey(selectedOrganization.value);
      setOrganizationName(selectedOrganization.label);
    }
  }, [selectedOrganization]);

  function handleSubmit() {
    setSubmitting(true);

    const organizationKey = selectedOrganization
      ? selectedOrganization.value
      : null;

    unlockExclusives(
      firebase,
      organizationKey,
      exclusiveCode,
      (exclusiveCodeStatus, specialCodeStatus, userUpdateStatus) => {
        setSubmitting(false);
        if (exclusiveCodeStatus || specialCodeStatus) {
          if (userUpdateStatus) {
            setSuccess(true);
          } else {
            setErrorMessage(
              "The code you entered is valid, but we had an issue updating your account."
            );
          }
        } else {
          setErrorMessage("The code you entered is not valid.");
        }
      },
      () => {
        setErrorMessage(
          "We're sorry, but there was an error in submitting your code."
        );
        setSubmitting(false);
      }
    );
  }

  return (
    <div className="component-unlock-exclusives">
      <CheckOnboarding>
        <DefaultContainer>
          {authUser ? (
            <>
              {!success ? (
                <div className="enter-code-page">
                  <div className="message">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="42"
                      viewBox="0 0 36 42"
                      fill="none"
                    >
                      <path
                        d="M32.1429 18.375H30.2143V12.4688C30.2143 5.59453 24.7339 0 18 0C11.2661 0 5.78571 5.59453 5.78571 12.4688V18.375H3.85714C1.72768 18.375 0 20.1387 0 22.3125V38.0625C0 40.2363 1.72768 42 3.85714 42H32.1429C34.2723 42 36 40.2363 36 38.0625V22.3125C36 20.1387 34.2723 18.375 32.1429 18.375ZM23.7857 18.375H12.2143V12.4688C12.2143 9.21211 14.8098 6.5625 18 6.5625C21.1902 6.5625 23.7857 9.21211 23.7857 12.4688V18.375Z"
                        fill="#00083E"
                      />
                    </svg>{" "}
                    Unlock Deals
                  </div>
                  <div className="submessage">
                    If you hold a group or flex pass or are a player affiliated
                    with a team and have received an exclusive code or email you
                    are eligible to receive special deals.
                  </div>

                  <Form>
                    <FormGroup>
                      <StyledLabel>
                        Organization{" "}
                        <InfoIcon
                          text="Select the organization you are affiliated with."
                          id="organization-info-icon"
                        />
                      </StyledLabel>
                      <Select
                        value={selectedOrganization}
                        onChange={setSelectedOrganization}
                        options={organizations}
                        components={{
                          Option: CustomOption,
                          SingleValue: CustomSingleValue,
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                        className="select-organization"
                        styles={customStyles}
                      />
                    </FormGroup>
                    <FormGroup>
                      <StyledLabel>
                        Exclusive Code / Email
                        <InfoIcon
                          text="Your Exclusive Code or Email Address associated with your account will unlock Exclusive and Special Deals. If you have not received your code, please reach out to the organization listed above."
                          id="exclusive-code-info-icon"
                        />
                      </StyledLabel>
                      <Input
                        type="text"
                        value={exclusiveCode}
                        onChange={(e) => setExclusiveCode(e.target.value)}
                        onKeyPress={(e) => {
                          setExclusiveCode(e.target.value);
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSubmit();
                          }
                        }}
                        placeholder="This code is case-sensitive"
                        className="exclusive-code-input"
                      />
                    </FormGroup>
                  </Form>

                  <div className="row">
                    <div className="col-sm-12">
                      <Button
                        className="green medium rounded-20"
                        onClick={handleSubmit}
                      >
                        {submitting ? (
                          <i className="fa fa-spin fa-circle-o-notch" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>

                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </div>
              ) : (
                <div className="success-page">
                  <i className="success-icon fa fa-check-circle" />
                  <div className="success" data-notranslate>
                    {authUser && authUser.languagePreference === "Français"
                      ? "Succès!"
                      : "Success!"}
                  </div>
                  <div className="success-submessage">
                    You now have access to exclusive deals from{" "}
                    <Link to={`/organizations/${organizationKey}`}>
                      <span data-notranslate>{organizationName}</span>
                    </Link>
                    !
                  </div>
                  <div className="actions">
                    <Button
                      routerLink
                      to={`/organizations/${organizationKey}`}
                      className="primary rounded-20 medium"
                    >
                      Start browsing{" "}
                      <span data-notranslate>{organizationName}</span> deals
                    </Button>
                  </div>
                  <div className="enter-code-link">
                    <a
                      href="#"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      Enter another exclusive code
                    </a>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="enter-code-page unauthenticated-page">
              <div className="message">Have an exclusive code?</div>
              <div className="submessage">
                Well then you've come to the right place. To get started, please
                Login or Sign Up.
              </div>
              <div>
                <Button
                  className="green rounded-20 medium"
                  onClick={() => setShowLoginModal(true)}
                >
                  Login or Sign Up
                </Button>
              </div>

              {showLoginModal && (
                <LoginModal
                  onClose={() => setShowLoginModal(false)}
                  returnToCurrentPageAfterOnboarding={true}
                />
              )}
            </div>
          )}
        </DefaultContainer>
      </CheckOnboarding>
    </div>
  );
}

export default withFirebase(withAuthentication(UnlockExclusives));
