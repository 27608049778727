import React from "react";
import {
  RefinementList as AlgoliaRefinementList,
  connectRefinementList,
} from "react-instantsearch-dom";
import "./RefinementList.scss";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import useMobile from "../../hooks/useMobile";

const FanPagesCategoryRefinementListContainer = styled.div`
  .ais-RefinementList-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    margin: 10px 0;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    height: 60px;

    &::-webkit-scrollbar {
      width: 50%;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.primaryBlue};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${COLORS.primaryWhite};
      border-radius: 10px;
      border: 1px solid ${COLORS.primaryBlue};
    }
  }
`;

const CategoryButton = styled.button`
  white-space: nowrap;
  font-family: "Manrope", sans-serif;
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "16px")};
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  border: none;
  border-radius: 10px;
  background-color: ${COLORS.primaryBlue};
  padding: ${({ isMobile }) => (isMobile ? "2px 10px" : "5px 10px")};
  align-items: center;
  justify-content: center;
  color: ${COLORS.primaryWhite};

  &:hover {
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: ${COLORS.primaryGreenDark};
  `}
`;

const FanPagesCategoryRefinementList = ({
  items,
  currentRefinement,
  refine,
  clear,
  isFromSearch,
  searchForItems,
  createURL,
  hideCount,
  noTranslate,
}) => {
  const isMobile = useMobile();

  const noTranslateLabels = [
    "Baseball",
    "Basketball",
    "Hockey",
    "BIAs",
    "Volleyball",
  ];

  const handleTranslation = (label) => {
    if (noTranslateLabels.includes(label)) {
      return (
        <span data-notranslate>
          <FormattedMessage
            id={`app.category.${label.toLowerCase()}`}
            defaultMessage={label}
          />
        </span>
      );
    } else {
      return label;
    }
  };
  return (
    <FanPagesCategoryRefinementListContainer>
      <div class="ais-RefinementList">
        <div class="ais-RefinementList-list">
          <CategoryButton
            isMobile={isMobile}
            isSelected={!currentRefinement || currentRefinement.length === 0}
            onClick={() => {
              refine("");
            }}
          >
            <span class="ais-RefinementList-labelText" data-notranslate>
              <FormattedMessage
                id="app.category.allFanPages"
                defaultMessage="All FanPages"
              />
            </span>
          </CategoryButton>

          {items.map((item) => (
            <CategoryButton
              isMobile={isMobile}
              isSelected={currentRefinement?.includes(item.label)}
              onClick={() => {
                refine(item.value);
              }}
            >
              {noTranslate ? (
                <span class="ais-RefinementList-labelText" data-notranslate>
                  {item.label}
                </span>
              ) : (
                <span class="ais-RefinementList-labelText">
                  {handleTranslation(item.label)}
                </span>
              )}{" "}
              {!hideCount && (
                <span class="ais-RefinementList-count">{item.count}</span>
              )}
            </CategoryButton>
          ))}
        </div>
      </div>
    </FanPagesCategoryRefinementListContainer>
  );
};

export default connectRefinementList(FanPagesCategoryRefinementList);
