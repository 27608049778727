import React, { useEffect, useState } from 'react';
import DealGroupForm from '../Form';
import { withFirebase } from '../../../../../containers/Firebase';
import { dbLoadBrand } from '../../../../../containers/DatabaseHelpers/DatabaseHelpers';
import { uploadResourceImages } from '../../../utilities/FormHelpers';

function Create({ firebase, history, match }) {

  const brandKey = match.params.brand_id;
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState();

  function pushDealGroupData(dealGroupKey, dealGroupData, imageUrls) {    
    const dealGroupRef = firebase.database.ref(`/offerGroup/${dealGroupKey}`);
    const brandDealGroupRef = firebase.database.ref(`/brand/${brandKey}/offerGroups/${dealGroupKey}`);

    if (imageUrls.pictureUrl) {

      /* Note: Theory, not proven. Because the sponsorRef.child('offerGroups') gets set
            afterwards, this causes the cloud function to update all offerGroups with
            sponsor information. So we don't have to set that directly here. */
      const dataSet = {
        description: dealGroupData.description,
        exclusive: dealGroupData.exclusive,
        brand: {
          brandKey,
          name: brand.name,
          logo: brand.logo,
          teamKey: brand.teamKey,
        },
        offerKeys: dealGroupData.offerKeys,
        picture: imageUrls.pictureUrl,
        createdAt: firebase.databaseServerValue.TIMESTAMP,
        updatedAt: firebase.databaseServerValue.TIMESTAMP,
      };

      const promises = [];

      promises.push(brandDealGroupRef.set(dealGroupData.description || "(default description)"));
      promises.push(dealGroupRef.set(dataSet));

      Promise.all(promises).then(() => {
        history.push(`/portal/brands/${brandKey}`);
      });
    } else {
      window.alert("You must upload a picture.");
    }
  }

  function handleSubmit(dealGroupData) {
    var dealGroup = firebase.database.ref("offerGroup").push();
    var dealGroupKey = dealGroup.key;

    uploadResourceImages(
      firebase,
      dealGroupKey,
      dealGroupData,
      'images/offerGroups',
      ['picture'],
      pushDealGroupData
    );
  }

  function loadBrand() {
    const brandKey = match.params.brand_id;

    dbLoadBrand(firebase, brandKey, (result) => {
      setBrand(result);
      setLoading(false);
    });

  }

  useEffect(loadBrand, []);

  return (
    <div>
      {
        !loading &&
        <>
          <h1>Create Deal Group</h1>
          <DealGroupForm brandKey={brandKey} onSubmit={handleSubmit} />
        </>
      }
    </div>
  );
}

export default withFirebase(Create);