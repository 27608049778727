import { create } from "zustand";
import {
  useQueryClient,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import {
  fetchExclusiveCodes,
  fetchExclusiveCodeTotals,
  createExclusiveCodes,
} from "../utilities/admin_helpers";

const useExclusiveCodesStore = create((set, get) => ({
  exclusiveCodes: null,
  exclusiveCodesTotal: null,
  isLoading: false,
  error: null,
  isFetching: false,
  isFetchingTotal: false,
  isLoadingTotal: false,
  errorTotal: null,
  exclusiveCode: null,
  isFetchingCode: false,
  isLoadingCode: false,
  exclusiveCodesTotals: null,
  isFetchingTotals: false,
  isLoadingTotals: false,
  errorTotals: null,

  fetchExclusiveCodes: async (firebase, teamId) => {
    if (get().isFetching || get().isLoading) {
      return;
    }
    set({ isLoading: true, error: null, isFetching: true });
    try {
      const data = await fetchExclusiveCodes(firebase, teamId);
      const teamExclusiveCodesObj = {};
      teamExclusiveCodesObj[teamId] = data;
      set({ exclusiveCodes: teamExclusiveCodesObj });
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  fetchExclusiveCodesTotal: async (firebase, teamId) => {
    if (get().isFetchingTotal || get().isLoadingTotal) {
      return;
    }

    set({ isLoadingTotal: true, errorTotal: null, isFetchingTotal: true });
    try {
      const data = await fetchExclusiveCodeTotals(firebase, teamId);
      const teamExclusiveCodesTotalObj = {};
      teamExclusiveCodesTotalObj[teamId] = data;
      set({ exclusiveCodesTotal: teamExclusiveCodesTotalObj });
    } catch (error) {
      set({ errorTotal: error });
    } finally {
      set({ isLoadingTotal: false, isFetchingTotal: false });
    }
  },

  fetchExclusiveCodesTotalsFromFirestore: async (firebase) => {
    // fetch all exclusive codes totals for all teams
    if (get().isFetchingTotals || get().isLoadingTotals) {
      return;
    }

    set({ isLoadingTotals: true, errorTotals: null, isFetchingTotals: true });
    try {
      const data = await firebase.firestore
        .collection("exclusiveCodesTotals")
        .get();

      const exclusiveCodesTotals = {};
      if (data.empty) {
        return;
      }

      data.forEach((doc) => {
        exclusiveCodesTotals[doc.id] = doc.data();
      });

      set({ exclusiveCodesTotals });
    } catch (error) {
      set({ errorTotals: error });
    } finally {
      set({ isLoadingTotals: false, isFetchingTotals: false });
    }
  },

  fetchExclusiveCodesFromFirestore: async (firebase, teamId, searchTerm) => {
    if (get().isFetching || get().isLoading) {
      return;
    }

    set({ isLoading: true, error: null, isFetching: true });
    try {
      if (!searchTerm) {
        const data = await firebase.firestore
          .collection("exclusiveCodes")
          .where("teamKey", "==", teamId)
          .orderBy("createdAt", "desc")
          .get();

        const exclusiveCodes = [];
        if (data.empty) {
          return;
        }

        data.forEach((doc) => {
          exclusiveCodes.push({ id: doc.id, ...doc.data() });
        });

        const teamExclusiveCodesObj = {};
        teamExclusiveCodesObj[teamId] = exclusiveCodes;

        set({ exclusiveCodes: teamExclusiveCodesObj });
      } else {
        const data = await firebase.firestore
          .collection("exclusiveCodes")
          .where("teamKey", "==", teamId)
          .where("code", "==", searchTerm)
          .orderBy("createdAt", "desc")
          .get();

        const exclusiveCodes = [];
        if (data.empty) {
          return;
        }

        data.forEach((doc) => {
          exclusiveCodes.push({ id: doc.id, ...doc.data() });
        });

        const teamExclusiveCodesObj = {};
        teamExclusiveCodesObj[teamId] = exclusiveCodes;

        set({ exclusiveCodes: teamExclusiveCodesObj });
      }
    } catch (error) {
      set({ error });
    } finally {
      set({ isLoading: false, isFetching: false });
    }
  },

  useInfiniteQueryExclusiveCodes: (firebase, teamId, searchTerm) => {
    return useInfiniteQuery({
      queryKey: ["exclusiveCodes", teamId, searchTerm],
      queryFn: async ({ pageParam = null }) => {
        let q = firebase.firestore
          .collection("exclusiveCodes")
          .where("teamKey", "==", teamId)
          .orderBy("code", "asc");

        if (pageParam) {
          q = q.startAfter(pageParam);
        }

        if (searchTerm) {
          const endString = searchTerm.replace(/.$/, (c) =>
            String.fromCharCode(c.charCodeAt(0) + 1)
          );
          q = q.where("code", ">=", searchTerm).where("code", "<", endString);
        }

        q = q.limit(50);

        const querySnapshot = await q.get();

        const exclusiveCodes = [];
        if (querySnapshot.empty) {
          return { exclusiveCodes, hasNewPage: false };
        }

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          exclusiveCodes.push({ id: doc.id, ...doc.data() });
        });

        return { exclusiveCodes, hasNewPage: true, lastVisible };
      },
      initialPageParam: 0,
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.hasNewPage) {
          return undefined;
        }
        return lastPage.lastVisible;
      },
    });
  },
  updateExclusiveCode: async (firebase, teamId, code, updates) => {
    const codeId = code.id;
    try {
      // Update the database first
      await firebase.firestore
        .collection("exclusiveCodes")
        .doc(codeId)
        .update(updates);

      if (updates.status === "deactivated") {
        const exclusiveCodePath = `team/${teamId}/exclusiveCode`;
        // remove exclusiveCode from team exclusiveCode array
        const existingExclusiveCodes = await firebase.database
          .ref(exclusiveCodePath)
          .once("value");

        const existingExclusiveCodesVal = existingExclusiveCodes.val();

        if (!existingExclusiveCodesVal) {
          console.error("No existing exclusive codes found");
        } else {
          const filteredExclusiveCodes = existingExclusiveCodesVal.filter(
            (el) => {
              return el !== code.code;
            }
          );
          firebase.database.ref(exclusiveCodePath).set(filteredExclusiveCodes);

          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error updating exclusive code", error);
      return false;
    }
  },

  addExclusiveCodes: async (firebase, teamId, codes) => {
    try {
      const result = await createExclusiveCodes(firebase, teamId, codes);

      return result;
    } catch (error) {
      console.error("Error adding exclusive codes", error);
      return null;
    }
  },

  useExclusiveCode: (firebase, teamId, codeId) => {
    const queryClient = useQueryClient();
    return useQuery({
      queryKey: ["exclusiveCode", teamId, codeId],
      queryFn: async () => {
        const doc = await firebase.firestore
          .collection("exclusiveCodes")
          .doc(codeId)
          .get();

        const exclusiveCode = doc.data();

        if (exclusiveCode && exclusiveCode.teamKey === teamId) {
          exclusiveCode.id = doc.id;
          return exclusiveCode;
        } else {
          throw new Error("Invalid teamId or codeId");
        }
      },
      initialData: () => {
        const exclusiveCodes = queryClient.getQueryData([
          "exclusiveCodes",
          teamId,
        ]);
        if (exclusiveCodes && exclusiveCodes[teamId]) {
          return exclusiveCodes[teamId].find((code) => code.id === codeId);
        }
        return null;
      },
    });
  },

  fetchExclusiveCode: async (firebase, teamId, codeId) => {
    set({ isFetchingCode: true, isLoadingCode: true });
    try {
      const data = await firebase.firestore
        .collection("exclusiveCodes")
        .doc(codeId)
        .where("teamKey", "==", teamId)
        .get();

      const exclusiveCode = data.data();
      exclusiveCode.id = data.id;

      set({ exclusiveCode });

      return exclusiveCode;
    } catch (error) {
      console.error("Error fetching exclusive code", error);
      return null;
    } finally {
      set({ isFetchingCode: false, isLoadingCode: false });
    }
  },

  deleteExclusiveCode: async (firebase, teamId, code) => {
    const codeId = code.id;
    try {
      const exclusiveCodePath = `team/${teamId}/exclusiveCode`;
      // remove exclusiveCode from team exclusiveCode array
      const existingExclusiveCodes = await firebase.database
        .ref(exclusiveCodePath)
        .once("value");

      if (!existingExclusiveCodes.val()) {
        console.error("No existing exclusive codes found");
      } else {
        const filteredExclusiveCodes = existingExclusiveCodes
          .val()
          .filter((el) => el !== code.code);

        firebase.database.ref(exclusiveCodePath).set(filteredExclusiveCodes);
      }
      // Delete the exclusive code from the database
      await firebase.firestore
        .collection("exclusiveCodes")
        .doc(codeId)
        .delete();

      // update exclusive codes totals in firestore
      const exclusiveCodesTotalsRef = firebase.firestore
        .collection("exclusiveCodesTotals")
        .doc(teamId);
      const exclusiveCodesTotals = await exclusiveCodesTotalsRef.get();

      if (!exclusiveCodesTotals.exists) {
        console.error("No exclusive codes totals found");
      } else {
        const exclusiveCodesTotalsData = exclusiveCodesTotals.data();

        const updatedExclusiveCodesTotals = {
          ...exclusiveCodesTotalsData,
          total: exclusiveCodesTotalsData.total - 1,
        };

        await exclusiveCodesTotalsRef.set(updatedExclusiveCodesTotals);
      }

      return true;
    } catch (error) {
      console.error("Error deleting exclusive code", error);
      return false;
    }
  },
}));

export default useExclusiveCodesStore;
