import React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../colors";

const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-left: 10px;
  color: ${COLORS.errorRed};
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
`;

const ErrorMessage = ({ error }) => {
  return <ErrorMessageContainer>{error}</ErrorMessageContainer>;
};

export default ErrorMessage;
