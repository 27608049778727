import { useState, useEffect } from 'react';

function useFeaturedTeams(firebase) {

  const [featuredTeams, setFeaturedTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  function loadFeaturedTeams() {

    let featuredTeams = [];
    const teamsMap = [];
    const sponsorTeamMap = {};

    firebase.database.ref(`/adminSettings/featuredTeams`).once('value')
      .then((snapshot) => {
        
        const teamRequestPromises = [];
        
        /* Get Featured Teams Keys */
        if(snapshot.val() !== null) {
          featuredTeams = snapshot.val();
          featuredTeams.forEach((featuredTeam) => {
            if(featuredTeam.key.length > 0) {
              const resourceRef = firebase.database.ref(`/team/${featuredTeam.key}`);
              teamRequestPromises.push(resourceRef.once('value'));
            }
          });
        }

        return Promise.all(teamRequestPromises)
      }).then((teamSnapshots) => {
        /* Load Team information */
        teamSnapshots.forEach((teamSnapshot) => {
          if(teamSnapshot.val() !== null) {
            const team = teamSnapshot.val();
            team.key = teamSnapshot.key;
            team.sponsorObjects = [];
            
            teamsMap[team.key] = team;
          }
        });

         /* Load featured Sponsors of featured Teams */
         const sponsorRequestPromises = [];
         featuredTeams.forEach((featuredTeam) => {
          if(featuredTeam.key.length > 0 && featuredTeam.featuredSponsorKeys.length > 0) {

            featuredTeam.featuredSponsorKeys.forEach((sponsorKey) => {
              if(sponsorKey.length > 0) {
                sponsorTeamMap[sponsorKey] = featuredTeam.key;
                const sponsorRef = firebase.database.ref(`/sponsor/${sponsorKey}`);
                sponsorRequestPromises.push(sponsorRef.once('value'));
              }
            });

          }
        });

        return Promise.all(sponsorRequestPromises);

      }).then((sponsorSnapshots) => {

        /* Map Sponsors onto Teams */
        sponsorSnapshots.forEach((sponsorSnapshot) => {
          if(sponsorSnapshot.val() !== null) {
            const sponsor = sponsorSnapshot.val();
            sponsor.key = sponsorSnapshot.key;
            
            const teamKey = sponsorTeamMap[sponsor.key];

            const team = teamsMap[teamKey];
            team.sponsorObjects.push(sponsor);
          }
        });
        
        setLoading(false);
        setFeaturedTeams(Object.values(teamsMap));
      });
  }

  useEffect(loadFeaturedTeams, []);

  return { featuredTeams, loading };
}

export default useFeaturedTeams;