import React from 'react';
import './SelectableListItem.scss';

function SelectableListItem({ item, displayField, valueField, onToggle, selected }) {

  function handleCircleClick() {
    onToggle(item[valueField]);
  }

  return (
    <div className='component-selectable-list-item' onClick={handleCircleClick}>
      <div className='circle-container'>
        <div className={`circle ${selected ? 'selected' : ''}`}>
          {selected && <i className="fa fa-check"></i>}
        </div>
      </div>
      <div>{item[displayField]}</div>
    </div>
  );
}

export default SelectableListItem;