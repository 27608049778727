import React from "react";
import {
  RefinementList as AlgoliaRefinementList,
  connectRefinementList,
} from "react-instantsearch-dom";
import "./RefinementList.scss";
import { FormattedMessage } from "react-intl";

const RefinementList = ({
  items,
  currentRefinement,
  refine,
  isFromSearch,
  searchForItems,
  createURL,
  hideCount,
  noTranslate,
}) => {
  const noTranslateLabels = [
    "Baseball",
    "Basketball",
    "Hockey",
    "BIAs",
    "Volleyball",
  ];

  const handleTranslation = (label) => {
    if (noTranslateLabels.includes(label)) {
      return (
        <span data-notranslate>
          <FormattedMessage
            id={`app.category.${label.toLowerCase()}`}
            defaultMessage={label}
          />
        </span>
      );
    } else {
      return label;
    }
  };
  return (
    <div className="component-refinement-list">
      <div className="ais-RefinementList">
        <ul className="ais-RefinementList-list">
          {items.map((item) => (
            <li className="ais-RefinementList-item" key={item.label}>
              <label className="ais-RefinementList-label">
                <input
                  className="ais-RefinementList-checkbox"
                  type="checkbox"
                  onChange={() => {
                    refine(item.value);
                  }}
                />
                {noTranslate ? (
                  <span
                    className="ais-RefinementList-labelText"
                    data-notranslate
                  >
                    {item.label}
                  </span>
                ) : (
                  <span className="ais-RefinementList-labelText">
                    {handleTranslation(item.label)}
                  </span>
                )}{" "}
                {!hideCount && (
                  <span className="ais-RefinementList-count">{item.count}</span>
                )}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default connectRefinementList(RefinementList);
