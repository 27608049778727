import React from 'react';
import { Link } from 'react-router-dom';

const List = (props) => {
  return (
    <div>
      Documents listing coming soon.
    </div>
  );
};

export default List;