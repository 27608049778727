import React from 'react';
import { Table } from 'reactstrap';
import ZipCodes from 'zipcodes';

function LocationsTable({ transactions }) {

  const locationCounts = {};

  transactions.forEach(transaction => {
    if (!transaction.user) { return; }
    if (!transaction.user.zip || (6 < transaction.user.zip.length && 5 > transaction.user.zip.length )) { return; }    
    
    const zipcodeData = ZipCodes.lookup(transaction.user.zip);
    let location;
    if (!zipcodeData) {
      location = 'Unknown';
    } else {
      location = zipcodeData.city + ", " + zipcodeData.state + ", " + zipcodeData.country;
    }

    if (location in locationCounts) {
      locationCounts[location]++; 
    } else {
      locationCounts[location] = 1;
    }
  });

    var table = [];

  return (
    <Table responsive>
      <thead>
      <tr>
        <th>Location</th>
        <th>Count</th>
      </tr>
      </thead>
      <tbody>
        {
          Object.keys(locationCounts).map((location) => {
            return (
              <tr key={location}>
                <td>{location}</td>
                <td>{locationCounts[location]}</td>
              </tr>
            );
          })
        }
      </tbody>
    </Table>
  );
}

export default LocationsTable;