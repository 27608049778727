/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect, useState, useRef } from "react";
import { FormGroup, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { loadTeamSponsors } from "../../../../../utilities/team_helpers";
import { withFirebase } from "../../../../../containers/Firebase";

function FeaturedTeam({
  firebase,
  initialTeam,
  potentialTeams,
  index,
  onChange,
}) {
  const initialRenderRef = useRef(true);
  const [team, setTeam] = useState(initialTeam);

  const [potentialSponsors, setPotentialSponsors] = useState([]);

  function loadPotentialSponsorsForTeam() {
    loadTeamSponsors(firebase, team.key, (sponsors) => {
      setPotentialSponsors(sponsors);
    });
  }

  function handleTeamInputChange(e) {
    const teamKey = e.target.value;

    const newTeam = {
      key: teamKey,
      featuredSponsorKeys: ["", "", "", ""],
    };

    setTeam(newTeam);
  }

  function updateSponsor(sponsorKey, sponsorIndex) {
    const newTeam = { ...team };

    newTeam.featuredSponsorKeys[sponsorIndex] = sponsorKey;

    setTeam(newTeam);
  }

  useEffect(() => {
    loadPotentialSponsorsForTeam();
    if (!initialRenderRef.current) {
      if (onChange) {
        onChange(team, index);
      }
    } else {
      initialRenderRef.current = false;
    }
  }, [team]);

  return (
    <FormGroup>
      <Row>
        <Col xs="6">Featured Organization</Col>
        <Col xs="6">Featured Businesses for the Organization</Col>
      </Row>
      <Row>
        <Col xs="6">
          <Input
            type="select"
            value={team.key}
            onChange={handleTeamInputChange}
          >
            {potentialTeams.map((team) => (
              <option key={team.key} value={team.key} data-notranslate>
                {team.name}
              </option>
            ))}
          </Input>
          {team.key.length > 0 && (
            <Link
              target="_blank"
              to={`/organizations/${team.key}`}
              className="view-fan-page"
            >
              View FanPage
            </Link>
          )}
        </Col>
        <Col xs="6">
          {team.featuredSponsorKeys.map((featuredSponsorKey, sponsorIndex) => (
            <Input
              type="select"
              value={featuredSponsorKey}
              onChange={(e) => {
                updateSponsor(e.target.value, sponsorIndex);
              }}
            >
              <option key="" value={null}>
                (none)
              </option>
              {potentialSponsors.map((sponsor) => (
                <option key={sponsor.key} value={sponsor.key} data-notranslate>
                  {sponsor.name}
                </option>
              ))}
            </Input>
          ))}
        </Col>
      </Row>
    </FormGroup>
  );
}

export default withFirebase(FeaturedTeam);
