import React from "react";
import { FormattedMessage } from "react-intl";
import LoginModal from "../../../containers/LoginModal";
import { Button, DefaultContainer } from "../../../containers/UIKit";

import CheckOnboarding from "../../../containers/CheckOnboarding";
import { withAuthentication } from "../../../containers/Session";

import "./HowItWorks.scss";
import fansavesLogoWhite from "../../../assets/img/brand/logo-white.png";
import fansavesExcitedGirl from "../../../assets/img/FanSaves_Excited_Girl-_Transparent-_1000x1000.png";

class HowItWorks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginModal: false,
    };
  }

  handleLoginClick = (e) => {
    e.preventDefault();
    this.setState({ showLoginModal: true });
  };

  handleCloseLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  render() {
    const { showLoginModal } = this.state;

    return (
      <CheckOnboarding>
        <DefaultContainer>
          <div className="views-pages-how-it-works">
            <div className="banner">
              <div className="slogan">
                Saving money is as easy as 1, 2, FREE with
                <br />
                <img src={fansavesLogoWhite} className="logo" />
              </div>
              <img
                src={fansavesExcitedGirl}
                className="fansaves-excited-girl"
              />
            </div>

            <div className="caption">
              Start saving money today is three easy steps:
            </div>

            <div className="steps">
              <div className="step">
                <div className="step-number">1</div>
                <div className="step-body">
                  Sign up for your account on our website or by{" "}
                  <a href="http://onelink.to/9z2uxx">
                    downloading the FREE FanSaves app
                  </a>
                  . If you already have an account{" "}
                  <a href="#" onClick={this.handleLoginClick}>
                    log in
                  </a>
                  .
                </div>
              </div>

              <div className="step">
                <div className="step-number">2</div>
                <div className="step-body">
                  Become a fan of your favorite teams and organizations by
                  giving them a follow to gain access to their affiliated deals.
                </div>
              </div>

              <div className="step">
                <div className="step-number">3</div>
                <div className="step-body">
                  Scroll through the awesome deals available and redeem in-store
                  (by showing the redemption on your app) or online.
                </div>
              </div>
            </div>

            <div className="benefits">
              <div className="benefit">
                <div className="icon-container">
                  <svg viewBox="0 0 125.5 160" role="img">
                    <g>
                      <path d="M100.1 95.6c10.5-10.1 17-24.3 17-40C117.2 24.9 92.2 0 61.6 0S6 24.9 6 55.6C6 72 13.2 86.8 24.6 97L0 139.6l23.4 1.3 13.4 19 25.9-46.6 26 46.7 13.4-19 23.4-1.3-25.4-44.1zm-66.7 40.8l-3.9-6-8 .2 14-26c4.1 2.2 8.6 3.9 13.3 5l-15.4 26.8zm28.2-32.2C34.8 104.2 13 82.4 13 55.6S34.8 6.9 61.6 6.9s48.6 21.8 48.6 48.6c0 26.9-21.8 48.7-48.6 48.7zM96 130.3l-3.9 6-15.7-27.2c4.6-1.3 9-3.1 13.1-5.5l14.5 27-8-.3zM61.6 13.9c-23 0-41.7 18.7-41.7 41.7s18.7 41.7 41.7 41.7 41.7-18.7 41.7-41.7-18.7-41.7-41.7-41.7zm22.7 36.8L78.9 56c-2.2 2.1-3.6 6.3-3 9.4l1.3 7.4c.5 3-1.3 4.3-4 2.9l-6.7-3.5c-2.7-1.4-7.1-1.4-9.8 0L50 75.6c-2.7 1.4-4.5.1-4-2.9l1.3-7.4c.5-3-.9-7.2-3-9.4l-5.4-5.3c-2.2-2.1-1.5-4.2 1.5-4.7l7.5-1.1c3-.4 6.6-3 8-5.8l3.3-6.8c1.4-2.7 3.6-2.7 4.9 0l3.3 6.8c1.4 2.7 4.9 5.3 8 5.8l7.5 1.1c2.9.5 3.6 2.6 1.4 4.8z"></path>
                    </g>
                  </svg>
                </div>
                <div className="title" data-notranslate>
                  <FormattedMessage id="app.fans" defaultMessage={"Fans"} />
                </div>
                <div className="body">
                  Fans win because they receive exclusive discounts and deals
                  from brands that are affiliated with our partners.
                </div>
                <div className="action">
                  <Button
                    className="primary"
                    href="http://onelink.to/9z2uxx"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get the app
                  </Button>
                </div>
              </div>

              <div className="benefit">
                <div className="icon-container">
                  <svg viewBox="10 25 180 150" role="img">
                    <g>
                      <path
                        d="M142.229 44.161c1.984 2.71 3.872 5.516 5.518 8.468 21.344 9.435 36.252 30.823 36.252 55.597 0 33.484-27.249 60.726-60.742 60.726-5.13 0-10.116-.629-14.859-1.839-3.098 1.258-6.244 2.371-9.486 3.29A66.794 66.794 0 0 0 123.257 175C160.089 175 190 145.048 190 108.226c.048-30.242-20.135-55.839-47.771-64.065z"
                        fill="#3F7239"
                        data-color="1"
                      ></path>
                      <path
                        d="M173.786 108.226c0-16.016-7.454-30.29-19.118-39.581a80.761 80.761 0 0 1 3.582 23.806c0 27.387-13.697 51.629-34.558 66.29 27.685-.241 50.094-22.741 50.094-50.515z"
                        fill="#3F7239"
                        data-color="1"
                      ></path>
                      <path
                        d="M144.843 92.452c0-37.21-30.25-67.452-67.421-67.452S10 55.242 10 92.452s30.25 67.452 67.421 67.452 67.422-30.243 67.422-67.452zm-67.422 61.354c-33.832 0-61.323-27.532-61.323-61.355S43.59 31.097 77.421 31.097s61.323 27.532 61.323 61.355-27.491 61.354-61.323 61.354z"
                        fill="#3F7239"
                        data-color="1"
                      ></path>
                      <path
                        d="M77.421 41.452c-28.169 0-51.014 22.839-51.014 51s22.845 51 51.014 51 51.014-22.839 51.014-51-22.845-51-51.014-51zm12.633 70.5c-2.13 2.758-5.034 4.597-8.505 5.565v6.81h-5.808v-6.616c-4.031-.677-7.225-2.129-9.403-4.306-2.178-2.177-3.824-5.565-4.84-10.113l7.018-1.452c.968 3.242 2.13 5.516 3.485 6.919 1.452 1.306 3.388 1.984 5.76 1.984 2.323 0 4.308-.774 5.856-2.274 1.549-1.5 2.323-3.387 2.323-5.758 0-2.081-.629-3.774-1.936-5.081-.678-.63-1.646-1.307-2.904-2.082-1.307-.774-2.904-1.597-4.937-2.516-4.114-1.839-6.824-3.532-8.131-5.081-2.081-2.323-3.098-5.081-3.098-8.323 0-1.548.242-2.952.726-4.258s1.21-2.516 2.13-3.581 2.081-1.984 3.436-2.806c1.355-.774 2.904-1.403 4.999-1.839v-6.206h4.84v6.109c2.842.387 4.875 1.21 6.472 2.468s3.098 3.194 4.55 5.758l-6.195 3.484c-1.888-3.387-4.308-5.081-7.308-5.081-1.888 0-3.436.532-4.646 1.645s-1.839 2.468-1.839 4.161c0 1.5.484 2.758 1.5 3.774.968.968 2.952 2.129 5.905 3.484 2.565 1.161 4.695 2.274 6.389 3.29s2.952 2.032 3.775 3c2.42 2.613 3.582 5.71 3.582 9.387.095 3.583-1.018 6.777-3.196 9.535z"
                        fill="#3F7239"
                        data-color="1"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="title">Businesses & Brands</div>
                <div className="body">
                  Brands offering a deal win because they can track their return
                  on investment and gain valuable customer insights.
                </div>
                <div className="action">
                  <Button
                    className="primary"
                    href="https://about.fansaves.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Button>
                </div>
              </div>

              <div className="benefit">
                <div className="icon-container">
                  <svg viewBox="23.5 23.5 153 153" role="img">
                    <g>
                      <path
                        d="M100 23.5c-42.3 0-76.5 34.2-76.5 76.5s34.2 76.5 76.5 76.5 76.5-34.2 76.5-76.5-34.2-76.5-76.5-76.5zM145.6 57l-4.1 5c-4.1-2-19.7-9.5-24.9-8.6l-16-14.1.9-2c17.3.5 32.9 8 44.1 19.7zm-94.2 75.6c-.4-.7-.9-1.5-1.3-2.2l3.5-22.1 17.3-1.5 19.4 20.5c-.9 3.5-3.7 13.2-3.9 17.5l-23.6-.2c-2.6-1.8-8-6.6-11.4-12zM98 41l17.3 15.1c-1.7 3.9-4.7 11-6.1 14.7L82 77.3 69.3 65.6l11.9-21.2c3-1 10.2-3.2 16.8-3.4zm-24.2 64.6l8.6-25.1 26.2-6.3 18.1 20.5-8.2 25.7-25.9 5.2-18.8-20zM129.6 95l16-4.8 12.8 20.3c-.6 3-1.9 10.1-4.7 15.6l-22.5 10.8c-2.6-3.7-7.8-11.2-10.4-14.7l8.8-27.2zM75.1 42.7l2.8 1.9-10.8 19.5S50.7 75.3 47.6 82l-6.3-2.8C47 62.6 59.4 49.6 75.1 42.7zM40.3 81.8l6.5 2.8c-.2 5.4 2.6 14.9 4.3 20.1L47 130.3l-1.5.2c-5-9.1-8-19.5-8-30.5 0-6.3.9-12.5 2.8-18.2zm23.2 69l.2-3 24 .2c4.3 2.8 17.9 6.1 22.5 7.3l1.1 6.3c-3.7.7-7.4 1.1-11.4 1.1-13.5-.2-26-4.6-36.4-11.9zm50.8 10.1l-1.1-6.3c3.4-2.4 12.3-9.3 16.6-13.6l23.3-11.2.2.2.9 1.5c-8.6 14.5-22.9 25.5-39.9 29.4zm47.9-53.8l-1.7.9-11.9-18.8S145.2 72.8 143 65l4.5-5.6c9.3 11 15.1 25.1 15.1 40.8.1 2.2-.1 4.5-.4 6.9z"
                        fill="#121cf2"
                        data-color="1"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="title">Our Partners</div>
                <div className="body">
                  Our partners are able to retain and acquire businesses,
                  advertisers and members while increasing their sales and
                  value.
                </div>
                <div className="action">
                  <Button
                    href="https://about.fansaves.com/contact-us"
                    className="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Request a demo
                  </Button>
                </div>
              </div>
            </div>

            {showLoginModal && (
              <LoginModal onClose={this.handleCloseLoginModal} />
            )}
          </div>
        </DefaultContainer>
      </CheckOnboarding>
    );
  }
}

export default HowItWorks;
