import React from "react";
import "./UnlockExclusiveModal.scss";
import { Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { unlockExclusives } from "../../utilities/team_helpers";
import ModalContainer from "../Modals/Modal";
import "../Modals/Modal.scss";
import "../../scss/base/typography.scss";
import "../../scss/base/buttons.scss";
import "../../scss/base/colorswatches.scss";

class UnlockExclusiveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      exclusiveCode: "",
      errorMessage: "",
      openExplanation: false,
    };
  }

  handleCloseClick = (e) => {
    e.preventDefault();
    this.props.onClose();
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOpenExplanation = (e) => {
    e.preventDefault();
    const { openExplanation } = this.state;
    this.setState({ openExplanation: !openExplanation });
  };

  handleUnlockClick = (e) => {
    const { onClose, firebase, deal, onUnlockExclusiveDeal } = this.props;

    const { exclusiveCode } = this.state;

    e.preventDefault();

    this.setState({ submitting: true });

    const teamKeys = deal.teams;

    if (teamKeys.length > 0) {
      // Warning
      if (teamKeys.length > 1) {
        // More than one team associated with deal, unlock exclusive may not work
        console.warn(
          "DEV Warning: UnlockExclusiveModal.js - More than 1 team."
        );
      }

      const teamKey = teamKeys[0];

      unlockExclusives(
        firebase,
        teamKey,
        exclusiveCode,
        (exclusiveCodeStatus, specialCodeStatus, userUpdateStatus) => {
          this.setState({ submitting: false });
          if (exclusiveCodeStatus || specialCodeStatus) {
            if (userUpdateStatus) {
              if (onUnlockExclusiveDeal) {
                onUnlockExclusiveDeal();
              }
              onClose();
            } else {
              this.setState({
                errorMessage:
                  "The code you entered is valid, but we had an issue updating your account.",
              });
            }
          } else {
            this.setState({
              errorMessage: "The code you entered is not valid.",
            });
          }
        },
        () => {
          this.setState({
            errorMessage:
              "We're sorry, but there was an error in submitting your code.",
            submitting: false,
          });
        }
      );
    }
  };

  render() {
    const { exclusiveCode, errorMessage, submitting, openExplanation } =
      this.state;

    const { onClose, organization, isMobile } = this.props;

    return (
      <div className="component-unlock-exclusive-modal">
        <ModalContainer onClose={onClose}>
          <div className="unclock-exclusive-code-form">
            <div className="unclock-exclusive-code-form-header">
              <i className="fa fa-lock unclock-exclusive-code-form-header-icon" />
              <Label
                htmlFor="ExclusiveCode"
                className={`${isMobile ? "mobile-h2" : " standard-h3"}`}
              >
                Exclusive Code
              </Label>
            </div>
            <div className="logo-container">
              <img src={organization.logo} className="logo" />
            </div>
            <div
              className={`instruction-container ${
                isMobile ? "mobile-h5" : " standard-b1"
              }`}
            >
              This deal is not available to the public. To unlock this deal
              enter the Exclusive Code you received from this organization.
            </div>
            <div
              className={`instruction-container ${
                isMobile ? "mobile-h4" : " standard-b1"
              }`}
            >
              {" "}
              Code is case sensitive.
            </div>
            <Input
              type="text"
              onChange={this.handleInputChange}
              name="exclusiveCode"
              value={exclusiveCode}
              placeholder="Enter code here"
              className="unclock-exclusive-code-form-input"
            />
            <div className="exclusive-code-explanation-button">
              <i
                className="fa fa-question-circle question-icon"
                onClick={(e) => this.handleOpenExplanation(e)}
              >
                <span className={`${isMobile ? "mobile-b3" : " standard-b3"}`}>
                  {" "}
                  What’s an Exclusive Code?
                </span>
              </i>
            </div>
            {openExplanation && (
              <div
                className={`exclusive-code-explanation ${
                  isMobile ? "mobile-b3" : " standard-b3"
                }`}
              >
                Exclusive deals are not available to the public and can only be
                unlocked with an Exclusive Code. If you are a member, partner or
                VIP of this organization, please contact them directly to
                receive your code.
              </div>
            )}

            <div
              className={`button ${
                isMobile ? "popup-cta-mobile-btn" : "popup-cta-btn"
              }`}
              onClick={this.handleUnlockClick}
            >
              {submitting ? (
                <i className="fa fa-spin fa-circle-o-notch" />
              ) : (
                "Submit"
              )}
            </div>
            {errorMessage.length > 0 && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
        </ModalContainer>
      </div>
    );
  }
}

UnlockExclusiveModal.propTypes = {
  onClose: PropTypes.func,
};

export default withFirebase(withAuthentication(UnlockExclusiveModal));
