/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import {
  withAuthentication,
  isSponsor,
  isAdmin,
} from "../../containers/Session";
import {
  DashboardCard,
  DashboardTitle,
  FlexContainer,
  DashboardButton,
  DashboardFilter,
  Grid,
  GridRow,
  SearchBar,
  DeviceInfoIcon,
} from "../../assets/fansaves-ui";
import DashboardFilterDisplay from "../DashboardFilterDisplay";

import { UncontrolledTooltip } from "reactstrap";

import "./DashboardRedemptionsTable.scss";
import "../../scss/base/typography.scss";
import moment from "moment";
import { getLocation } from "../../utilities/dashboard_helpers";

const DashboardRedemptionsTable = ({
  redemptions,
  limit,
  title,
  showAllClick,
  showViewLess,
  match,
  authUser,
}) => {
  const [filteredRedemptions, setFilteredRedemptions] = useState(redemptions);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const getHeader = () => {
    let header = [];
    if (isSponsor(authUser)) {
      header = [
        "Date of Redemption",
        "Offer",
        "Name",
        "Gender",
        "Age",
        "Location",
        "Email",
        "Device",
        "Account Created",
      ];
    } else {
      header = [
        "Date of Redemption",
        "Offer",
        "Business",
        "Gender",
        "Age",
        "Location",
        "Email",
        "Device",
        "Account Created",
      ];
    }
    return header;
  };

  const filterRedemptions = (filter) => {
    let tempRedemptions = redemptions;
    if (filter) {
      if (filter.includes("days")) {
        const numberOfDays = parseInt(filter.match(/\d+/g)[0]);
        tempRedemptions = tempRedemptions
          .filter((redemption) => {
            return (
              moment().diff(
                moment(redemption.time, "YYYY-MM-DD-HH-mm-ss"),
                "days",
                true
              ) < numberOfDays
            );
          })
          .sort((a, b) => {
            return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
              moment(a.time, "YYYY-MM-DD-HH-mm-ss")
            );
          });
      } else if (filter === "ThisYear") {
        tempRedemptions = tempRedemptions
          .filter((redemption) => {
            return moment(redemption.time, "YYYY-MM-DD-HH-mm-ss").isBetween(
              moment().startOf("year"),
              moment()
            );
          })
          .sort((a, b) => {
            return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
              moment(a.time, "YYYY-MM-DD-HH-mm-ss")
            );
          });
      } else if (filter === "LastYear") {
        tempRedemptions = tempRedemptions
          .filter((redemption) => {
            return moment(redemption.time, "YYYY-MM-DD-HH-mm-ss").isBetween(
              moment().subtract(1, "year").startOf("year"),
              moment().subtract(1, "year").endOf("year")
            );
          })
          .sort((a, b) => {
            return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
              moment(a.time, "YYYY-MM-DD-HH-mm-ss")
            );
          });
      } else if (filter === "All") {
        tempRedemptions = redemptions.sort((a, b) => {
          return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
            moment(a.time, "YYYY-MM-DD-HH-mm-ss")
          );
        });
      }
    }
    setFilteredRedemptions(tempRedemptions);
  };

  const updateLocation = (location) => {
    const { city } = getLocation(location);
    return city;
  };

  const getQueryFromSearch = () => {
    const search = queryString.parse(window.location.search);
    const filter = search.filter ? search.filter : "All";
    const query = search.query ? search.query : null;

    setFilter(filter);
    if (query) {
      setSearchQuery(query);
    } else {
      setSearchQuery(null);
    }
  };

  const handleSearch = () => {
    if (!searchQuery) {
      return;
    }

    if (redemptions && redemptions.length > 0) {
      const tempRedemptions = redemptions.filter((redemption) => {
        if (
          (redemption.user &&
            redemption.user.email &&
            redemption.user.email
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (redemption.offer &&
            redemption.offer.description &&
            redemption.offer.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (redemption.sponsorName &&
            redemption.sponsorName
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
        ) {
          return redemption;
        }

        return null;
      });

      const sortedRedemptions = tempRedemptions.sort((a, b) => {
        return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
          moment(a.time, "YYYY-MM-DD-HH-mm-ss")
        );
      });
      setFilteredRedemptions(sortedRedemptions);
    }
  };

  const setUrlQueryFromFilters = () => {
    const { search } = window.location;
    const params = queryString.parse(search);
    if (filter) {
      params.filter = filter;
    }
    if (searchQuery) {
      params.query = searchQuery;
    } else {
      delete params.query;
    }
    const newSearch = queryString.stringify(params);
    window.history.pushState({}, "", `?${newSearch}`);
  };

  useEffect(() => {
    if (filter) {
      filterRedemptions(filter);
    } else {
      const sortedRedemptions = redemptions.sort((a, b) => {
        return moment(b.time, "YYYY-MM-DD-HH-mm-ss").diff(
          moment(a.time, "YYYY-MM-DD-HH-mm-ss")
        );
      });
      setFilteredRedemptions(sortedRedemptions);
    }
  }, [redemptions]);

  useEffect(() => {
    handleSearch();
    if (!limit) {
      setUrlQueryFromFilters();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!limit) {
      getQueryFromSearch();
    } else {
      setFilter("All");
    }
  }, [limit]);

  useEffect(() => {
    filterRedemptions(filter);

    if (!limit) {
      setUrlQueryFromFilters();
    }
  }, [filter]);

  const handleFilterChange = (filter) => {
    setFilter(filter);
    setSearchQuery(null);
  };

  const renderRedemptionRow = (redemption, index, hideBorder) => {
    return (
      <GridRow
        className="dashboard-redemptions-table-row"
        key={`redemption-row-${index}`}
        columnsCount={getHeader().length}
        hideBorder={hideBorder ? true : false}
      >
        <div className="row-item flex-start-row-item" data-notranslate>
          {moment(redemption.time, "YYYY-MM-DD:hh:mm:ss").format(
            "YYYY/MM/DD HH:mm A"
          )}
        </div>
        <div className="row-item ellipsis-row-item flex-start-row-item">
          <div className="name">
            {redemption.offer ? redemption.offer.description : "N/A"}
          </div>
        </div>
        {isSponsor(authUser) ? (
          <div className="row-item narrow-row-item flex-start-row-item">
            N/A
          </div>
        ) : (
          <div
            className="row-item ellipsis-row-item flex-start-row-item"
            id={`business-${index}`}
          >
            <div className="name" data-notranslate>
              {redemption.sponsorName}
            </div>
            <UncontrolledTooltip
              placement="top"
              target={`business-${index}`}
              className="tooltip"
            >
              <div className="name" data-notranslate>
                {redemption.sponsorName}
              </div>
            </UncontrolledTooltip>
          </div>
        )}
        <div className="row-item narrow-row-item flex-start-row-item">
          {redemption.user && redemption.user.gender
            ? redemption.user.gender
            : "N/A"}
        </div>
        <div
          className="row-item narrow-row-item flex-start-row-item"
          data-notranslate
        >
          {redemption.userAge ? redemption.userAge : "N/A"}
        </div>
        <div className="row-item ellipsis-row-item wide-row-item flex-start-row-item">
          <div className="location" data-notranslate>
            {redemption.userCity ||
              updateLocation(redemption.user && redemption.user.zip)}
          </div>
        </div>
        <div
          className="row-item ellipsis-row-item wide-row-item flex-start-row-item"
          data-notranslate
        >
          <div className="email" id={`email-${index}`}>
            {redemption.user && redemption.user.email}
          </div>
          <UncontrolledTooltip
            placement="top"
            target={`email-${index}`}
            className="tooltip"
          >
            <div className="email" data-notranslate>
              {redemption.user && redemption.user.email
                ? redemption.user.email
                : "N/A"}
            </div>
          </UncontrolledTooltip>
        </div>
        <div className="row-item narrow-row-item flex-start-row-item">
          <DeviceInfoIcon deviceInfo={redemption.deviceInfo} />
        </div>
        <div className="row-item flex-start-row-item" data-notranslate>
          {redemption.user && redemption.user.createdAt ? (
            moment(redemption.user.createdAt).format("YYYY/MM/DD HH:mm A")
          ) : (
            <div className="not-available">N/A</div>
          )}
        </div>
      </GridRow>
    );
  };

  return (
    <FlexContainer className="component-dashboard-redemptions-table">
      {title && <DashboardTitle title={title} className="redemptions-title" />}
      {redemptions && redemptions.length > 0 ? (
        <>
          {!limit && (
            <div className="dashboard-redemptions-table-header">
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                placeholder="Search"
                className={`search-bar ${isAdmin(authUser) ? "admin" : ""}`}
              />
              <DashboardFilterDisplay
                renderCount={`Redemptions: ${filteredRedemptions.length}`}
                filter={filter}
              />
            </div>
          )}
          <DashboardFilter
            type="days"
            className="component-dashboard-filter inter-b2"
            onSelect={handleFilterChange}
            initialFilterOption={filter}
          />
          <Grid
            className="dashboard-redemptions-table"
            columns={getHeader().length}
            margin={limit ? "0 0 0 0" : "0 0 50px 0"}
          >
            <GridRow
              className="dashboard-redemptions-table-header inter-b2"
              hideBorder={limit ? true : false}
              boldBorder={limit ? false : true}
              columnsCount={getHeader().length}
            >
              {getHeader().map((header, index) => {
                return (
                  <div
                    key={`header-${index}`}
                    className={`row-item ${
                      header === "Email" || header === "Location"
                        ? "wide-row-item"
                        : "" ||
                          header === "Age" ||
                          header === "Gender" ||
                          header === "Name" ||
                          header === "Device"
                        ? "narrow-row-item"
                        : ""
                    } ${
                      (header === "Location" ||
                        header === "Email" ||
                        header === "Business") &&
                      "ellipsis-row-item"
                    } flex-start-row-item`}
                  >
                    {header}
                  </div>
                );
              })}
            </GridRow>
            {filteredRedemptions && filteredRedemptions.length > 0 ? (
              limit ? (
                <DashboardCard
                  shadowStyle
                  className="dashboard-redemptions-table"
                >
                  <div className="dashboard-redemptions-table-wrapper inter-b2">
                    {filteredRedemptions.map((redemption, i, arr) => {
                      if (i < limit) {
                        if (i === limit - 1 || i === arr.length - 1) {
                          return renderRedemptionRow(
                            redemption,
                            i,
                            "hideBorder"
                          );
                        }
                        return renderRedemptionRow(redemption, i);
                      }
                    })}
                  </div>
                </DashboardCard>
              ) : (
                <div className="dashboard-redemptions-table-wrapper inter-b2">
                  {filteredRedemptions.map((redemption, i) => {
                    return renderRedemptionRow(redemption, i);
                  })}
                </div>
              )
            ) : (
              <FlexContainer
                className="no-redemptions"
                alignItem="center"
                margin="50px 0 0 0"
              >
                <div className="no-redemptions-message">
                  No redemptions found. Select another filter option.
                </div>
              </FlexContainer>
            )}
          </Grid>
          {limit && filteredRedemptions && filteredRedemptions.length > 0 && (
            <DashboardButton
              title={showViewLess ? "View Less" : "View All"}
              className="view-all"
              size="small"
              type="shadow-white"
              typography="public-h2"
              onClick={showAllClick}
            />
          )}
        </>
      ) : (
        <div className="no-redemptions-message">No redemptions yet.</div>
      )}
    </FlexContainer>
  );
};

DashboardRedemptionsTable.propTypes = {
  redemptions: propTypes.array,
  limit: propTypes.number,
  title: propTypes.string,
  showAllClick: propTypes.func,
  showViewLess: propTypes.bool,
  match: propTypes.object,
  authUser: propTypes.object,
};

export default withRouter(withAuthentication(DashboardRedemptionsTable));
