import React, { useState } from "react";
import Modal from "../Modals/Modal";

import { Icon } from "@iconify/react";
import baseUrl from "../../utilities/url_helpers";
import styled from "styled-components/macro";
import { FlexContainer, Text, COLORS } from "../../assets/fansaves-ui";
import { withFirebase } from "../../containers/Firebase";

import { Tooltip } from "reactstrap";
import { logEvent, eventNames } from "../../utilities/analytics";

const LinkInput = styled.input`
  width: 100%;
  height: 29px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #00083e;
  background: rgba(217, 217, 217, 0.38);
  padding: 0 30px 0 10px;
  color: ${COLORS.primaryBlack};
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media screen and (max-width: 900px) {
    padding: 0 40px 0 10px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const CopyIcon = styled.i`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
  color: ${COLORS.primaryGreen};
  font-weight: 900;

  &:hover {
    color: ${COLORS.primaryGreenHover};
  }
`;

const ShareLinkModal = styled(Modal)`
  .main-content-container {
    max-width: 480px;
  }
  .close-modal-container {
    max-width: 480px;
  }
`;

const ShareText = styled(Text)`
  color: #4d4d4d;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
`;
const ShareIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const ShareLinkAndMediaModal = ({
  url,
  deal,
  isMobile,
  className,
  firebase,
}) => {
  const shareUrl = `${baseUrl}${url}`;

  const [showModal, setShowModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  function copyToClipboard(e) {
    e.preventDefault();
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setTooltipOpen(true);
        logEvent(firebase, eventNames.AE_COPIED_SHARE_LINK, {
          key: deal.key,
          description: deal.description,
        });
        setTimeout(() => setTooltipOpen(false), 3000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  }

  return (
    <div className={className}>
      {showModal && (
        <ShareLinkModal onClose={closeModal} backgroundColor="white">
          <FlexContainer alignItems="center" justifyContent="center" gap="30px">
            <FlexContainer
              direction="horizontal"
              justifyContent="center"
              alignItems="center"
              gap="10px"
            >
              <Icon
                icon="fa:share-alt"
                width={25}
                height={25}
                color={COLORS.primaryGreen}
                onClick={openModal}
              />
              <Text size="lg" color={COLORS.primaryBlue}>
                Share this link!
              </Text>
            </FlexContainer>

            <InputContainer>
              <LinkInput type="text" value={shareUrl} readOnly />
              <CopyIcon
                id="share-link-icon"
                className="fa fa-copy"
                onClick={(e) => copyToClipboard(e)}
              ></CopyIcon>
              <Tooltip
                placement="bottom"
                target="share-link-icon"
                isOpen={tooltipOpen}
                onClick={() => setTooltipOpen(!tooltipOpen)}
              >
                Copied!
              </Tooltip>
            </InputContainer>
          </FlexContainer>
        </ShareLinkModal>
      )}
      <FlexContainer
        direction="horizontal"
        justifyContent="center"
        alignItems="center"
        gap="5px"
        onClick={openModal}
      >
        {!isMobile && <ShareText>Share</ShareText>}
        <ShareIcon icon="fa:share-alt" width={15} height={15} color="#4D4D4D" />
      </FlexContainer>
    </div>
  );
};

export default withFirebase(ShareLinkAndMediaModal);
