import React from "react";
import BaseTeamsResultsWithEmptyState from "./BaseTeamsResultsWithEmptyState";

const TeamsSelectorResultsWithEmptyState = (props) => (
  <BaseTeamsResultsWithEmptyState
    {...props}
    filterHits={(hits) =>
      hits.filter((hit) => hit.isBrand !== true && hit.name)
    }
  />
);

export default TeamsSelectorResultsWithEmptyState;
