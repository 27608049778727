import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components/macro";

import { COLORS } from "../colors";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  canvas.chartjs-render-monitor {
    width: auto !important;
    height: 350px !important;
  }
`;

const genderChartDefault = {
  labels: ["Female", "Male", "Non-Binary", "Not Disclosed"],
  datasets: [
    {
      data: [100, 200, 300, 400],
      backgroundColor: [
        COLORS.primaryGreen,
        COLORS.primaryBlue,
        COLORS.chartGrey,
        COLORS.chartRed,
      ],
      borderColor: [
        COLORS.primaryGreen,
        COLORS.primaryBlue,
        COLORS.chartGrey,
        COLORS.chartRed,
      ],
      borderWidth: 1,
    },
  ],
};

const defaultOptions = {
  tooltips: {
    titleFontSize: 15,
    bodyFontSize: 15,
    callbacks: {
      title: function (tooltipItem, data) {
        let title = data.labels[tooltipItem[0].index].split(" ").slice(0, -1);
        return title;
      },
      label: function (tooltipItem, data) {
        let label =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return ` ${label}`;
      },
    },
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 20,
      bottom: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "bottom",
    labels: {
      fontColor: COLORS.primaryBlack,
      usePointStyle: true,
      boxWidth: 10,
      fontSize: 20,
      fontWeigth: 500,
      fontFamily: "Inter",
    },
  },
  plugins: {
    legendMargin: 20,
  },
};

const legendMargin = {
  id: "legendMargin",
  beforeInit(chart, args, opts) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;
    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();

      // Set the legend margin to the value of the plugin option
      this.options.labels.padding = 12;
    };
  },
};

const DoughnutChart = ({ dataSet, className }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (dataSet && dataSet.length > 0) {
      const tempData = { ...genderChartDefault };
      tempData.datasets[0].data = dataSet;
      const updatedLabels = [];
      const sum = dataSet.reduce((a, b) => a + b, 0);
      tempData.labels.forEach((label, index) => {
        dataSet.forEach((data, dataIndex) => {
          if (index === dataIndex) {
            label = `${label} ${Math.round((data / sum) * 100)}%`;
            updatedLabels.push(label);
          }
        });
      });
      tempData.labels = updatedLabels;
      setChartData(tempData);
    }
  }, [dataSet]);

  return (
    <Wrapper className={className}>
      {chartData && (
        <Doughnut
          data={chartData}
          options={defaultOptions}
          plugins={[legendMargin]}
        />
      )}
    </Wrapper>
  );
};

export default DoughnutChart;

DoughnutChart.propTypes = {
  dataSet: propTypes.array,
  className: propTypes.string,
};
