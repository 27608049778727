import styled, { css } from "styled-components/macro";
import { GLOBALS } from "../globals";

const MobileMarginContainer = styled.div`
  margin: 0;

  ${(props) => {
    if (props.horizontal) {
      return css`
        @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
          margin: 0 ${props.margin};
        }
      `;
    }

    return css`
      @media screen and (max-width: ${GLOBALS.mobileDeviceWidth}) {
        margin: ${props.margin} 0;
      }
    `;
  }}
`;

export default MobileMarginContainer;
