import React from "react";
import styled from "styled-components/macro";
import { Text } from "../../assets/fansaves-ui";
import { DefaultContainer } from "../../containers/UIKit";

const Spacer = styled.div`
  height: ${({ height }) => height || "40px"};
`;

const TermsAndConditions = () => {
  return (
    <DefaultContainer>
      <div className="terms-and-conditions animated fadeIn">
        <Text type="standard-h1">Terms and Conditions</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">Last updated: September 1, 2023</Text>
        </p>
        <p>
          <Text type="inter-b1">
            These Terms and Conditions ("Terms", "Terms and Conditions") govern
            your relationship with FanSaves mobile application (the "Service")
            operated by FanSaves Inc. ("us", "we", or "our").
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            Please read these Terms and Conditions carefully before using our
            FanSaves mobile application (the "Service").
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            By accessing or using the Service you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Service.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Accounts</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            When you create an account with us, you must provide us information
            that is accurate, complete, and current at all times. Failure to do
            so constitutes a breach of the Terms, which may result in immediate
            termination of your account on our Service.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            You are responsible for safeguarding the password that you use to
            access the Service and for any activities or actions under your
            password, whether your password is with our Service or a third-party
            service.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            You agree not to disclose your password to any third party. You must
            notify us immediately upon becoming aware of any breach of security
            or unauthorized use of your account.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Copyright Policy</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on the Service
            infringes the copyright or other intellectual property infringement
            ("Infringement") of any person.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement that is taking place through the
            Service, you must submit your notice in writing to the attention of
            "Copyright Infringement" of info@fansaves.com and include in your
            notice a detailed description of the alleged Infringement.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresenting that any Content is infringing
            your copyright.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Intellectual Property</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            The Service and its original content, features and functionality are
            and will remain the exclusive property of FanSaves Inc. and its
            licensors. The Service is protected by copyright, trademark, and
            other laws of both the Canada and foreign countries. Our trademarks
            and trade dress may not be used in connection with any product or
            service without the prior written consent of FanSaves Inc..
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Links To Other Web Sites</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by FanSaves Inc..
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            FanSaves Inc. has no control over, and assumes no responsibility
            for, the content, privacy policies, or practices of any third party
            web sites or services. You further acknowledge and agree that
            FanSaves Inc. shall not be responsible or liable, directly or
            indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any such content, goods
            or services available on or through any such web sites or services.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party web sites or services that you visit.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Termination</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply
            discontinue using the Service.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Limitation Of Liability</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            In no event shall FanSaves Inc., nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your access to or use
            of or inability to access or use the Service; (ii) any conduct or
            content of any third party on the Service; (iii) any content
            obtained from the Service; and (iv) unauthorized access, use or
            alteration of your transmissions or content, whether based on
            warranty, contract, tort (including negligence) or any other legal
            theory, whether or not we have been informed of the possibility of
            such damage, and even if a remedy set forth herein is found to have
            failed of its essential purpose.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Disclaimer</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            Your use of the Service is at your sole risk. The Service is
            provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
            provided without warranties of any kind, whether express or implied,
            including, but not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement
            or course of performance.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            FanSaves Inc. its subsidiaries, affiliates, and its licensors do not
            warrant that a) the Service will function uninterrupted, secure or
            available at any particular time or location; b) any errors or
            defects will be corrected; c) the Service is free of viruses or
            other harmful components; or d) the results of using the Service
            will meet your requirements.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Governing Law</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            These Terms shall be governed and construed in accordance with the
            laws of Ontario, Canada, without regard to its conflict of law
            provisions.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have between us regarding the Service.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Changes</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </Text>
        </p>
        <p>
          <Text type="inter-b1">
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, please stop using the Service.
          </Text>
        </p>
        <Spacer height="20px" />
        <Text type="standard-h3">Contact Us</Text>
        <Spacer />
        <p>
          <Text type="inter-b1">
            If you have any questions about these Terms, please{" "}
            <a
              href="https://about.fansaves.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </Text>
        </p>
        <Spacer />
      </div>
    </DefaultContainer>
  );
};

export default TermsAndConditions;
