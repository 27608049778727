import React from "react";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import LoginModal from "../LoginModal";
import PropTypes from "prop-types";
import { createFollowEvent } from "../../utilities/event_helpers";
import { FormattedMessage } from "react-intl";

import "../../scss/base/buttons.scss";

class FollowOrganizationLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginModal: false,
    };
  }

  followEvent = (type) => {
    const { authUser, organization, firebase } = this.props;
    createFollowEvent(
      type,
      firebase,
      authUser.key,
      organization.key,
      {},
      (response) => {
        console.log(`${type} event created: ${response}`);
      },
      () => {
        console.error(`Error creating ${type} event`);
      }
    );
  };

  followOrganization = () => {
    const { authUser, organization, firebase, onFollow } = this.props;

    const teams = authUser.teams || [];
    teams.push(organization.key);

    const userPath = `user/${authUser.key}`;
    const userRef = firebase.database.ref(userPath);
    userRef.update({ teams }).then(() => {
      if (typeof onFollow === "function") {
        onFollow();
      }
    });
  };

  unfollowOrganization = () => {
    const { firebase, authUser, organization, onUnfollow } = this.props;

    const teams = authUser.teams;
    const indexOfTeam = teams.indexOf(organization.key);
    if (indexOfTeam > -1) {
      teams.splice(indexOfTeam, 1);

      const userPath = `user/${authUser.key}`;
      const userRef = firebase.database.ref(userPath);
      userRef.update({ teams }).then(() => {
        if (typeof onUnfollow === "function") {
          onUnfollow();
        }
      });
    }
  };

  updateOrganizationFanCount = (operation) => {
    const { firebase, organization } = this.props;

    const teamPath = `team/${organization.key}`;
    const teamRef = firebase.database.ref(teamPath);
    teamRef.child("fans").transaction((fans) => {
      if (operation == "increment") {
        return (fans || 0) + 1;
      } else {
        if (fans && fans > 0) {
          return fans - 1;
        } else {
          return 0;
        }
      }
    });
  };

  hideLoginModal = () => {
    this.setState({ showLoginModal: false });
  };

  handleClick = (e) => {
    e.preventDefault();

    const { authUser, organization } = this.props;

    if (authUser) {
      if (this.following()) {
        if (
          window.confirm(
            `Are you sure you want to unfollow ${organization.name}?`
          )
        ) {
          this.unfollowOrganization();
        }
      } else {
        this.followOrganization();
      }
    } else {
      this.setState({ showLoginModal: true });
    }
  };

  following = () => {
    const { authUser, organization } = this.props;

    if (authUser && authUser.teams) {
      return authUser.teams.indexOf(organization.key) > -1;
    } else {
      return false;
    }
  };

  renderFollowing = () => {
    return (
      <span data-notranslate>
        <FormattedMessage
          id="app.team.following"
          defaultMessage={"Following"}
        />
      </span>
    );
  };

  render() {
    const { className } = this.props;
    const { showLoginModal } = this.state;

    return (
      <>
        <a
          href="#"
          onClick={this.handleClick}
          className={`component-follow-organization-link ${className}`}
        >
          {this.following() ? this.renderFollowing() : "Follow"}
        </a>
        {showLoginModal && (
          <LoginModal
            onClose={this.hideLoginModal}
            returnToCurrentPageAfterOnboarding={true}
          />
        )}
      </>
    );
  }
}

FollowOrganizationLink.propTypes = {
  organization: PropTypes.object.isRequired,
  onFollow: PropTypes.func,
  onUnfollow: PropTypes.func,
  className: PropTypes.string,
};

export default withFirebase(withAuthentication(FollowOrganizationLink));
