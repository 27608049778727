import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import OrganizationLink from "../../../containers/OrganizationLink";
import { withFirebase } from "../../../containers/Firebase";
import { withAuthentication } from "../../../containers/Session";
import CheckOnboarding from "../../../containers/CheckOnboarding";
import { withRouter } from "react-router-dom";
import LoadingPlaceholder from "../../../containers/LoadingPlaceholder";
import RedeemDealLink from "../../../containers/RedeemDealLink";
import DealRedeemedInfo from "../../../containers/DealRedeemedInfo";
import SpecialOverlay from "../../../containers/SpecialOverlay";
import DealTypeOverlay from "../../../containers/DealTypeOverlay";
import Price from "../../../containers/Price";
import { DefaultContainer } from "../../../containers/UIKit";
import DealPrecentageOff from "../../../containers/DealPercentageOff";

import { offerExpired, offerSoldOut } from "../../../utilities/offer_helpers";

import "./ShowFlash.scss";

import googlePlayImage from "../../../assets/img/google-play-badge.png";
import appleAppStoreImage from "../../../assets/img/apple-app-store-badge.png";
import getDirectionsMap from "../../../assets/img/get-directions-map.png";

import { logEvent, eventNames } from "../../../utilities/analytics";
import { unlockedExclusives } from "../../../utilities/user_helpers";

/* This is the Legacy Show page. We are keeping this around specifically for Flash deals.
 * So non-flash deals should technically not hit this component.
 * That being said, to save time, we are leaving in the deal.flashDeal conditionals
 * as later on this entire page will be removed in favour of the new Show.js */
class ShowFlash extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingDeal: true,
      loadingOrganization: true,
      loadingSponsor: true,
      timeUntilRedeemAgain: "...",
    };
  }

  componentDidMount() {
    this.loadDeal();
  }

  loadDeal = () => {
    const { firebase, match } = this.props;

    const offerPath = `/offer/${match.params.deal_id}`;
    const offerRef = firebase.database.ref(offerPath);
    offerRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        const deal = snapshot.val();
        deal.key = snapshot.key;

        logEvent(firebase, eventNames.AE_DEALS_SHOW_VIEW, {
          key: deal.key,
          description: deal.description,
        });

        this.setState({ deal, loadingDeal: false }, () => {
          this.loadSponsor();
          this.loadOrganization();
          setInterval(this.setTimeUntilRedeemAgain, 1000);
        });
      } else {
        this.setState({ loadingDeal: false });
      }
    });
  };

  loadSponsor = () => {
    const { firebase } = this.props;

    const { deal } = this.state;

    const sponsorPath = `/sponsor/${deal.sponsor.sponsorKey}`;
    const sponsorRef = firebase.database.ref(sponsorPath);
    sponsorRef.once("value").then((snapshot) => {
      const sponsor = snapshot.val();
      sponsor.key = snapshot.key;

      this.setState({ sponsor, loadingSponsor: false });
    });
  };

  loadOrganization = () => {
    const { firebase } = this.props;

    const { deal } = this.state;

    const organizationPath = `/team/${deal.teams[0]}`;
    const organizationRef = firebase.database.ref(organizationPath);
    organizationRef.once("value").then((snapshot) => {
      if (snapshot.val() !== null) {
        const organization = snapshot.val();
        organization.key = snapshot.key;
        this.setState({ organization, loadingOrganization: false });
      }
    });
  };

  setTimeUntilRedeemAgain = () => {
    if (this.dealRedeemed()) {
      this.setState({
        timeUntilRedeemAgain: this.timeUntilRedeemAgain(),
      });
    }
  };

  timeUntilRedeemAgain = () => {
    return this.redeemAgainAt().fromNow();
  };

  withinRedemptionPeriod = () => {
    const now = moment();
    return this.redeemAgainAt().diff(now) >= 0;
  };

  dealRedeemed = () => {
    const { authUser } = this.props;

    const { deal } = this.state;

    return (
      authUser &&
      authUser.redeemed &&
      Object.keys(authUser.redeemed).indexOf(deal.key) >= 0
    );
  };

  redeemAgainAt = () => {
    const { deal } = this.state;
    return this.redeemedAt()
      .add(deal.interval.hour, "hours")
      .add(deal.interval.week, "weeks")
      .add(deal.interval.month, "months");
  };

  redeemedAt = () => {
    const { authUser } = this.props;
    const { deal } = this.state;

    return moment(authUser.redeemed[deal.key], "YYYY-MM-DD:hh:mm");
  };

  unlocked = () => {
    const { authUser } = this.props;

    const { deal } = this.state;

    let unlocked = false;
    deal.teams.forEach((teamKey) => {
      if (authUser && unlockedExclusives(authUser, teamKey)) {
        unlocked = true;
      }
    });

    return unlocked;
  };

  renderExpiry = () => {
    const { deal } = this.state;

    const expiryMoment = moment(deal.expiresAt);

    return (
      <div className="expiry">
        Available until {expiryMoment.format("MMMM Do, YYYY")}
      </div>
    );
  };

  renderFlashDealActions = () => {
    const { deal } = this.state;

    if (offerExpired(deal)) {
      return (
        <div className="no-longer-available deal-expired">
          This deal is no longer available.
        </div>
      );
    }

    if (offerSoldOut(deal)) {
      return (
        <div className="no-longer-available sold-out">
          This deal is SOLD OUT!
        </div>
      );
    }

    return <RedeemDealLink deal={deal} />;
  };

  renderRegularDealActions = () => {
    const { authUser } = this.props;

    const { deal, timeUntilRedeemAgain } = this.state;

    if (this.dealRedeemed() && this.withinRedemptionPeriod()) {
      return (
        <>
          <DealRedeemedInfo
            deal={deal}
            userRedeemedDateTime={authUser.redeemed[deal.key]}
          />
          <div className="redeem-again-info">
            You will be able to redeem this deal again {timeUntilRedeemAgain}.
          </div>
        </>
      );
    } else {
      return <RedeemDealLink deal={deal} />;
    }
  };

  render() {
    const {
      loadingDeal,
      loadingSponsor,
      loadingOrganization,
      deal,
      sponsor,
      organization,
    } = this.state;

    return (
      <CheckOnboarding>
        <DefaultContainer>
          <div className="views-deals-show-flash">
            {loadingDeal || loadingOrganization ? (
              <LoadingPlaceholder style={{ height: 300, width: "100%" }} />
            ) : !deal ||
              (!deal.active && !deal.triggered) ||
              organization.archived ? (
              <div className="archived">
                <div className="archived-header">We're sorry!</div>
                This deal is no longer available.
                <br />
                Check out more great deals <Link to="/deals">here</Link>.
              </div>
            ) : (
              <>
                <div className="deal-header">
                  <div className="business">
                    <img
                      src={deal.sponsor.picture}
                      alt={`${deal.sponsor.name} logo`}
                      className="business-logo"
                    />
                    <div className="business-details">
                      <div className="business-name" data-notranslate>
                        {deal.sponsor.name}
                      </div>
                      <div className="business-address" data-notranslate>
                        {deal.sponsor.address}
                      </div>
                    </div>
                  </div>
                  {organization && (
                    <OrganizationLink
                      organization={organization}
                      className="organization"
                    >
                      <img
                        src={organization.logo}
                        alt={`${organization.name} logo`}
                        className="organization-logo"
                      />
                      <div className="organization-details">
                        <div className="organization-name" data-notranslate>
                          {organization.name}
                        </div>
                      </div>
                    </OrganizationLink>
                  )}
                </div>

                {deal.published && !deal.active ? (
                  <div className="not-activated-container">
                    <div className="description">{deal.description}</div>
                    <div className="terms">{deal.terms}</div>
                    <div className="not-activated-message">
                      {deal.triggered
                        ? `This deal has not been triggered yet. ${
                            deal.notTriggeredMessage
                              ? deal.notTriggeredMessage
                              : ""
                          }`
                        : "This deal cannot be redeemed at this time."}
                      <br />
                      Check out more great deals <Link to="/deals">here</Link>.
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="marquee">
                      <div className="image-area">
                        <img
                          src={deal.picture}
                          alt={deal.description}
                          className="deal-picture"
                        />
                        <div className="deal-overlays">
                          <SpecialOverlay
                            deal={deal}
                            unlocked={this.unlocked()}
                          />
                          {!deal.flashDeal && <DealTypeOverlay deal={deal} />}
                        </div>
                      </div>
                      <div className="writeup">
                        <div className="description">{deal.description}</div>
                        {deal.flashDeal && (
                          <div className="flash-deal-details">
                            <div className="price">
                              {deal.originalValue &&
                                deal.originalValue > 0.0 && (
                                  <Price
                                    value={deal.originalValue}
                                    currency={deal.currency}
                                    className="strikethrough original-value"
                                  />
                                )}
                              <Price
                                value={deal.price}
                                currency={deal.currency}
                              />
                              <DealPrecentageOff deal={deal} />
                            </div>
                            <div className="quantity-and-expiry">
                              <div className="total-left">
                                {deal.totalAvailable - deal.totalPurchased} left
                              </div>
                              •{this.renderExpiry()}
                            </div>
                          </div>
                        )}
                        <div className="actions">
                          {deal.flashDeal
                            ? this.renderFlashDealActions()
                            : this.renderRegularDealActions()}
                        </div>
                        <div className="terms">{deal.terms}</div>
                        {deal.flashDeal ? (
                          <div className="flash-deal-instructions">
                            To purchase this deal, press the PURCHASE button
                            above. Once you make your purchase, your deal will
                            be available in your{" "}
                            <Link to="/purchases">My Purchases</Link> tab and
                            can be redeemed by showing your unique code to the
                            staff at the establishment. This deal can be
                            redeemed one time only and it is non-refundable.
                          </div>
                        ) : (
                          <div className="download-app-info">
                            {deal.dealType !== "online" && (
                              <div className="download-instructions">
                                {deal.flashDeal
                                  ? "After purchasing, this "
                                  : "This "}
                                deal can only be redeemed through the FanSaves
                                app when you are in the physical establishment.
                                This deal cannot be redeemed through this
                                website. Download the free app to access this
                                and many more great discounts and deals!
                              </div>
                            )}
                            <div className="download-app">
                              Download the free app!
                            </div>
                            <div className="app-links">
                              <a
                                href="http://play.google.com/store/apps/details?id=com.fansaves.daydream&hl=en"
                                target="_blank"
                              >
                                <img src={googlePlayImage} />
                              </a>
                              <a
                                href="http://itunes.apple.com/us/app/fansaves/id1342516391?mt=8&app=itunes&ign-mpt=uo%3D4"
                                target="_blank"
                              >
                                <img src={appleAppStoreImage} />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {loadingSponsor ? (
                      <LoadingPlaceholder
                        style={{ height: 300, width: "100%" }}
                      />
                    ) : (
                      <div className="sponsor-info">
                        <div className="sponsor-details">
                          <h2>
                            More info about{" "}
                            <span data-notranslate>{sponsor.name}</span>
                          </h2>

                          <div className="address" data-notranslate>
                            {sponsor.address}
                          </div>

                          <div className="sponsor-description">
                            {sponsor.description}
                          </div>

                          <div className="contact-details">
                            {sponsor.displayEmail &&
                              sponsor.displayEmail != "" && (
                                <a href={`mailto:${sponsor.displayEmail}`}>
                                  <i className="fa fa-envelope-o" />
                                </a>
                              )}

                            {sponsor.phone && sponsor.phone != "" && (
                              <a href={`tel:${sponsor.phone}`}>
                                <i className="fa fa-phone" />
                              </a>
                            )}

                            {sponsor.website && sponsor.website != "" && (
                              <a href={sponsor.website} target="_blank">
                                <i className="fa fa-globe" />
                              </a>
                            )}

                            {sponsor.facebook && sponsor.facebook != "" && (
                              <a href={sponsor.facebook} target="_blank">
                                <i className="fa fa-facebook" />
                              </a>
                            )}

                            {sponsor.linkedin && sponsor.linkedin != "" && (
                              <a href={sponsor.linkedin} target="_blank">
                                <i className="fa fa-linkedin" />
                              </a>
                            )}

                            {sponsor.twitter && sponsor.twitter != "" && (
                              <a href={sponsor.twitter} target="_blank">
                                <i className="fa fa-twitter" />
                              </a>
                            )}

                            {sponsor.instagram && sponsor.instagram != "" && (
                              <a href={sponsor.instagram} target="_blank">
                                <i className="fa fa-instagram" />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="map">
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${sponsor.address}`}
                            target="_blank"
                          >
                            <img src={getDirectionsMap} />
                          </a>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </DefaultContainer>
      </CheckOnboarding>
    );
  }
}

export default withRouter(withFirebase(withAuthentication(ShowFlash)));
