const dbLoadTeam = (firebase, teamKey, callback) => {
  const teamRef = firebase.database.ref(`team/${teamKey}`);
  teamRef.once("value").then((snapshot) => {
    if (snapshot.val() !== null) {
      const team = snapshot.val();
      team.key = snapshot.key;
      callback(team);
    } else {
      callback();
    }
  });
};

const dbLoadTeams = (firebase, callback) => {
  const teamsRef = firebase.database.ref("team");
  teamsRef.once("value").then((teamSnapshots) => {
    const teams = [];

    teamSnapshots.forEach((teamSnapshot) => {
      const team = teamSnapshot.val();
      team.key = teamSnapshot.key;
      teams.push(team);
    });

    callback(teams);
  });
};

const dbLoadSponsors = (firebase, callback) => {
  const sponsorsRef = firebase.database.ref("sponsor");
  sponsorsRef.once("value").then((sponsorSnapshots) => {
    const sponsors = [];

    sponsorSnapshots.forEach((sponsorSnapshot) => {
      const sponsor = sponsorSnapshot.val();
      sponsor.key = sponsorSnapshot.key;
      sponsors.push(sponsor);
    });

    callback(sponsors);
  });
};

const dbLoadSponsor = (firebase, sponsorKey, callback) => {
  const sponsorRef = firebase.database.ref(`sponsor/${sponsorKey}`);
  sponsorRef.once("value").then((snapshot) => {
    if (snapshot.val() !== null) {
      const sponsor = snapshot.val();
      sponsor.key = snapshot.key;
      callback(sponsor);
    } else {
      callback();
    }
  });
};

const dbLoadSponsorTeam = (firebase, sponsorKey, callback) => {
  let sponsorTeam;

  const teamsRef = firebase.database.ref("team");
  teamsRef.once("value").then((dataSnapshot) => {
    const teamsObj = dataSnapshot.val();
    for (var teamKey in teamsObj) {
      const team = teamsObj[teamKey];
      team.key = teamKey;

      if (team.sponsors && team.sponsors.includes(sponsorKey)) {
        sponsorTeam = team;
        break;
      }
    }
    callback(sponsorTeam);
  });
};

const dbLoadOffer = (firebase, offerKey, callback) => {
  var offerPath = "/offer/" + offerKey;
  var offerRef = firebase.database.ref(offerPath);

  offerRef.once("value").then((dataSnapshot) => {
    if (dataSnapshot.val() !== null) {
      const offer = dataSnapshot.val();
      offer.key = dataSnapshot.key;
      callback(offer);
    } else {
      callback();
    }
  });
};

const dbLoadBrand = (firebase, brandKey, callback) => {
  const brandRef = firebase.database.ref(`brand/${brandKey}`);
  brandRef.once("value").then((snapshot) => {
    if (snapshot.val() !== null) {
      const brand = snapshot.val();
      brand.key = snapshot.key;
      callback(brand);
    } else {
      callback();
    }
  });
};

const dbLoadOfferGroup = (firebase, offerGroupKey, callback) => {
  const offerGroupRef = firebase.database.ref(`offerGroup/${offerGroupKey}`);
  offerGroupRef.once("value").then((snapshot) => {
    if (snapshot.val() !== null) {
      const offerGroup = snapshot.val();
      offerGroup.key = snapshot.key;
      callback(offerGroup);
    } else {
      callback();
    }
  });
};

const dbLoadTeamSponsors = (firebase, teamKey, callback) => {
  const teamRef = firebase.database.ref(`team/${teamKey}`);

  teamRef
    .once("value")
    .then((snapshot) => {
      if (snapshot.val() !== null) {
        const team = snapshot.val();
        team.key = snapshot.key;

        let sponsorPromises = [];

        team.sponsors.forEach((sponsorKey) => {
          sponsorPromises.push(
            firebase.database.ref(`sponsor/${sponsorKey}`).once("value")
          );
        });

        return Promise.all(sponsorPromises);
      } else {
        return [];
      }
    })
    .then((sponsorSnapshots) => {
      const sponsors = [];

      sponsorSnapshots.forEach((sponsorSnapshot) => {
        const sponsor = sponsorSnapshot.val();
        sponsor.key = sponsorSnapshot.key;

        sponsors.push(sponsor);
      });

      callback(sponsors);
    })
    .catch(() => {
      callback();
    });
};

const dbLoadBrandSponsors = (firebase, brandKey, callback) => {
  const brandRef = firebase.database.ref(`brand/${brandKey}`);

  brandRef
    .once("value")
    .then((snapshot) => {
      if (snapshot.val() !== null) {
        const brand = snapshot.val();
        brand.key = snapshot.key;

        let sponsorPromises = [];

        brand.sponsorKeys.forEach((sponsorKey) => {
          sponsorPromises.push(
            firebase.database.ref(`sponsor/${sponsorKey}`).once("value")
          );
        });

        return Promise.all(sponsorPromises);
      } else {
        return [];
      }
    })
    .then((sponsorSnapshots) => {
      const sponsors = [];

      sponsorSnapshots.forEach((sponsorSnapshot) => {
        const sponsor = sponsorSnapshot.val();
        sponsor.key = sponsorSnapshot.key;

        sponsors.push(sponsor);
      });

      callback(sponsors);
    })
    .catch(() => {
      callback();
    });
};

const dbLoadBrandOffers = (firebase, brandKey, callback) => {
  const brandRef = firebase.database.ref(`brand/${brandKey}`);

  brandRef
    .once("value")
    .then((snapshot) => {
      if (snapshot.val() !== null) {
        const brand = snapshot.val();
        brand.key = snapshot.key;

        let sponsorPromises = [];

        brand.sponsorKeys.forEach((sponsorKey) => {
          sponsorPromises.push(
            firebase.database.ref(`sponsor/${sponsorKey}`).once("value")
          );
        });

        return Promise.all(sponsorPromises);
      } else {
        return [];
      }
    })
    .then((sponsorSnapshots) => {
      const offerPromises = [];

      sponsorSnapshots.forEach((sponsorSnapshot) => {
        const sponsor = sponsorSnapshot.val();
        sponsor.key = sponsorSnapshot.key;

        if (sponsor.offers) {
          sponsor.offers.forEach((offerSpec) => {
            offerPromises.push(
              firebase.database.ref(`offer/${offerSpec.key}`).once("value")
            );
          });
        }
      });

      return Promise.all(offerPromises);
    })
    .then((offerSnapshots) => {
      const offers = [];

      offerSnapshots.forEach((offerSnapshot) => {
        const offer = offerSnapshot.val();
        offer.key = offerSnapshot.key;
        offers.push(offer);
      });

      callback(offers);
    })
    .catch(() => {
      callback();
    });
};

const dbLoadBrandTeams = (firebase, brandKey, callback) => {
  const brandRef = firebase.database.ref(`brand/${brandKey}`);

  brandRef
    .once("value")
    .then((snapshot) => {
      if (snapshot.val() !== null) {
        const brand = snapshot.val();
        brand.key = snapshot.key;

        let teamPromises = [];

        brand.teamKeys.forEach((teamKey) => {
          teamPromises.push(
            firebase.database.ref(`team/${teamKey}`).once("value")
          );
        });

        return Promise.all(teamPromises);
      } else {
        return [];
      }
    })
    .then((teamSnapshots) => {
      const teams = [];

      teamSnapshots.forEach((teamSnapshot) => {
        const team = teamSnapshot.val();
        team.key = teamSnapshot.key;

        teams.push(team);
      });

      callback(teams);
    })
    .catch(() => {
      callback();
    });
};

export {
  dbLoadTeam,
  dbLoadTeams,
  dbLoadSponsor,
  dbLoadSponsors,
  dbLoadSponsorTeam,
  dbLoadOffer,
  dbLoadBrand,
  dbLoadOfferGroup,
  dbLoadBrandOffers,
  dbLoadBrandSponsors,
  dbLoadTeamSponsors,
  dbLoadBrandTeams,
};
