import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components/macro";
import { COLORS } from "../../assets/fansaves-ui";
import ErrorMessage from "../../assets/fansaves-ui/OnboardingForms/ErrorMessage";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";
import { Button } from "../../containers/UIKit";
import { Label, Input } from "reactstrap";

const UpdatePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 600px;
  max-width: 493px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const PasswordForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputWithErrorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const InputContainerWithIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledLabel = styled(Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  padding-left: 10px;
`;

const StyledInput = styled(Input)`
  position: relative;
  font-size: 16px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: ${COLORS.primaryBlack};
  border: 1px solid ${COLORS.primaryBlue};
  border-color: ${({ error }) =>
    error ? COLORS.errorRed : COLORS.primaryBlue};
  border-radius: 10px;
  padding: 10px 20px;
  padding-right: 40px;
  width: 100%;

  &:focus {
    border-color: ${COLORS.primaryBlueHover};
    outline: none;
  }

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: transparent;
    color: ${COLORS.darkGrey};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const StyledButtonTitle = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
  line-height: 22px;
  color: ${({ disabled }) =>
    disabled ? COLORS.primaryGreenDark : COLORS.primaryWhite};
`;

const VisibilityToggleIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 24%;
  cursor: pointer;
  color: rgba(101, 101, 101, 1);
`;

const ProviderWarning = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  color: ${COLORS.mediumDarkGrey};
  max-width: 493px;
  width: 100%;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const SuccessTitle = styled.div`
  font-size: 16px;
  font-family: "Inter";
  color: ${COLORS.primaryBlack};
  font-weight: 400;
  text-align: center;
`;

const UpdatePassword = ({ firebase, authUser }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [providersWarning, setProvidersWarning] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const currentUser = firebase.auth.currentUser;
    if (currentUser) {
      const providers = currentUser.providerData?.map(
        (profile) => profile.providerId
      );

      if (!providers) {
        return;
      }

      if (!providers.includes("password")) {
        const providerNames = providers
          .map((provider) => {
            switch (provider) {
              case "google.com":
                return "Google";
              case "facebook.com":
                return "Facebook";
              case "apple.com":
                return "Apple";
              default:
                return provider;
            }
          })
          .join(", ");
        setProvidersWarning(
          `It looks like you've signed in using ${providerNames}. To change your password, please use the respective platforms.`
        );
      }
    }
  }, [firebase]);

  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError("Confirm password is required");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("New password and Confirm password do not match");
      return false;
    }

    setConfirmPasswordError("");
    return true;
  };

  const validateOldPassword = async () => {
    if (!authUser || !authUser.email) {
      return false;
    }
    if (!currentPassword) {
      setCurrentPasswordError("Old password is required");
      return false;
    }
    try {
      const user = firebase.auth.currentUser;

      await firebase.doReauthenticateWithCredential(
        authUser.email,
        currentPassword
      );

      setCurrentPasswordError("");
      return true;
    } catch (error) {
      setCurrentPasswordError("Old password is incorrect");
      return false;
    }
  };

  const validateNewPassword = () => {
    if (!newPassword || newPassword.length < 6) {
      setNewPasswordError("Password must be at least 6 characters");
      return false;
    }

    setNewPasswordError("");
    return true;
  };

  const handleError = (error) => {
    switch (error.code) {
      case "auth/wrong-password":
        setCurrentPasswordError("The old password is incorrect");
        break;
      case "auth/weak-password":
        setNewPasswordError("The new password is too weak");
        break;
      case "auth/requires-recent-login":
        alert("Please log in again and try to update your password");
        break;
      default:
        alert("Oops! Something went wrong. Please try again later.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isOldPasswordValid = await validateOldPassword();
    const isNewPasswordValid = validateNewPassword();
    const isConfirmPasswordValid = validateConfirmPassword();

    if (isOldPasswordValid && isNewPasswordValid && isConfirmPasswordValid) {
      setSubmitting(true);
      try {
        const user = firebase.auth.currentUser;
        await user.updatePassword(newPassword);
        setSuccess(true);
      } catch (error) {
        handleError(error);
        setSuccess(false);
      }
      setSubmitting(false);
    }
  };

  return (
    <UpdatePasswordContainer>
      <Title>Profile / Change Password</Title>
      {!success ? (
        <PasswordForm>
          {providersWarning && (
            <ProviderWarning>{providersWarning}</ProviderWarning>
          )}
          <InputWithErrorContainer>
            <StyledLabel>Old Password</StyledLabel>
            <InputContainerWithIcon>
              <StyledInput
                name="currentPassword"
                type={currentPasswordVisible ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Old Password"
                disabled={submitting}
                onFocus={() => setCurrentPasswordError("")}
              />
              <VisibilityToggleIcon
                icon={
                  currentPasswordVisible
                    ? "mdi:eye-off-outline"
                    : "mdi:eye-outline"
                }
                width={20}
                onClick={() =>
                  setCurrentPasswordVisible(!currentPasswordVisible)
                }
              />
            </InputContainerWithIcon>
            {currentPasswordError && (
              <ErrorMessage error={currentPasswordError} />
            )}
          </InputWithErrorContainer>
          <InputWithErrorContainer>
            <StyledLabel>New Password</StyledLabel>
            <InputContainerWithIcon>
              <StyledInput
                name="newPassword"
                type={newPasswordVisible ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                onFocus={() => setNewPasswordError("")}
                onBlur={validateNewPassword}
                placeholder="New Password"
                disabled={submitting}
              />
              <VisibilityToggleIcon
                icon={
                  newPasswordVisible ? "mdi:eye-off-outline" : "mdi:eye-outline"
                }
                width={20}
                onClick={() => setNewPasswordVisible(!newPasswordVisible)}
              />
            </InputContainerWithIcon>
            {newPasswordError && <ErrorMessage error={newPasswordError} />}
          </InputWithErrorContainer>
          <InputWithErrorContainer>
            <StyledLabel>Confirm Password</StyledLabel>
            <InputContainerWithIcon>
              <StyledInput
                name="confirmPassword"
                type={confirmPasswordVisible ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                disabled={submitting}
                onFocus={() => setConfirmPasswordError("")}
                onBlur={validateConfirmPassword}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              <VisibilityToggleIcon
                icon={
                  confirmPasswordVisible
                    ? "mdi:eye-off-outline"
                    : "mdi:eye-outline"
                }
                width={20}
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
              />
            </InputContainerWithIcon>
            {confirmPasswordError && (
              <ErrorMessage error={confirmPasswordError} />
            )}
          </InputWithErrorContainer>
          <ButtonContainer>
            <Button
              className={`green-continue rounded-10 wide ${
                submitting && "disabled"
              }`}
              onClick={handleSubmit}
            >
              <StyledButtonTitle disabled={submitting}>
                Update Password
              </StyledButtonTitle>
            </Button>
          </ButtonContainer>
        </PasswordForm>
      ) : (
        <SuccessContainer>
          <Icon
            icon="carbon:checkmark-filled"
            color={COLORS.primaryGreenDark}
            width={60}
          />
          <SuccessTitle>
            Your password has been successfully updated.
          </SuccessTitle>
        </SuccessContainer>
      )}
    </UpdatePasswordContainer>
  );
};

export default withFirebase(withAuthentication(UpdatePassword));
