import { error } from "logrocket";
import cloudFunctionHost from "../containers/CloudFunctionsHost";

function createFollowEvent(
  type,
  firebase,
  userKey,
  teamKey,
  metaData,
  onSuccess,
  onError
) {
  firebase.auth.currentUser.getIdToken().then((token) => {
    const data = {
      type: type,
      resource: "team",
      resourceKey: teamKey,
      userKey,
      metaData: metaData || {},
    };

    fetch(`${cloudFunctionHost}/authorizedFunctions/createEvent`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseObject) => {
        onSuccess(responseObject.success);
      })
      .catch(() => {
        onError();
      });
  });
}

async function getEventsByType(type, firebase, onSuccess, onError) {
  try {
    const token = await firebase.auth.currentUser.getIdToken();
    const data = {
      type: type,
    };

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchEventsByType`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (onSuccess && typeof onSuccess === "function") {
      onSuccess(responseData);
    } else {
      return responseData;
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    } else {
      return null;
    }
  }
}

async function getRedemptionsByRole(role, key, firebase, onSuccess, onError) {
  if (!role || !key) {
    return;
  }

  try {
    const token = await firebase.auth.currentUser.getIdToken();
    const data = {
      role,
      key,
    };

    const response = await fetch(
      `${cloudFunctionHost}/authorizedFunctions/fetchRedemptionsByRole`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    const responseData = await response.json();

    if (responseData && onSuccess && typeof onSuccess === "function") {
      onSuccess(responseData);
    } else if (onError && typeof onError === "function") {
      onError();
    } else {
      return responseData;
    }
  } catch (error) {
    if (onError && typeof onError === "function") {
      onError();
    }
  }
}

function getTeamRedemptionsEvents(teamKey, firebase, onSuccess, onError) {
  if (!teamKey) {
    return;
  }
  firebase.firestore
    .collection("events")
    .where("resource", "==", "offer")
    .where("type", "==", "redemption")
    .where("metaData.teamKey", "==", teamKey)
    .orderBy("createdAt", "desc")
    .get()
    .then((querySnapshot) => {
      const events = [];
      querySnapshot.forEach((doc) => {
        const event = doc.data();
        event.key = doc.id;
        events.push(event);
      });
      onSuccess && typeof onSuccess === "function" && onSuccess(events);
    })
    .catch((error) => {
      onError && typeof onError === "function" && onError(error);
    });
}

function getSponsorRedemptionsEvents(sponsorKey, firebase, onSuccess, onError) {
  if (!sponsorKey) {
    return;
  }
  firebase.firestore
    .collection("events")
    .where("resource", "==", "offer")
    .where("type", "==", "redemption")
    .where("metaData.sponsorKey", "==", sponsorKey)
    .orderBy("createdAt", "desc")
    .get()
    .then((querySnapshot) => {
      const events = [];
      querySnapshot.forEach((doc) => {
        const event = doc.data();
        event.key = doc.id;
        events.push(event);
      });
      onSuccess && typeof onSuccess === "function" && onSuccess(events);
    })
    .catch((error) => {
      onError && typeof onError === "function" && onError(error);
    });
}

function getEvents(firebase, onSuccess, onError) {
  firebase.firestore
    .collection("events")
    .orderBy("createdAt", "asc")
    .where("type", "==", "redemption")
    .get()
    .then((querySnapshot) => {
      const events = [];
      querySnapshot.forEach((doc) => {
        const event = doc.data();
        event.key = doc.id;
        events.push(event);
      });
      onSuccess && typeof onSuccess === "function" && onSuccess(events);
    })
    .catch((error) => {
      onError && typeof onError === "function" && onError(error);
    });
}

export {
  createFollowEvent,
  getEventsByType,
  getEvents,
  getTeamRedemptionsEvents,
  getSponsorRedemptionsEvents,
  getRedemptionsByRole,
};
