import React from "react";

import "./DealSpecialOverlay.scss";
import redRibbon from "../../assets/img/Ribbon_red.png";
import blueRibbon from "../../assets/img/Ribbon_blue.png";
import greenRibbon from "../../assets/img/Ribbon_green.png";
import moment from "moment";

const DealSpecialOverlay = ({ deal, size, isMobile }) => {
  // Not special
  if (!deal.flashDeal && !deal.triggered) {
    return <div className="component-deal-special-overlay" />;
  }

  let styleClass = "flash";

  // Flash background takes precedence
  if (deal.flashDeal) {
    styleClass = "flash";
  }

  // Triggered background takes precedence further
  if (deal.triggered) {
    if (!deal.active) {
      styleClass = "not-triggered";
    } else {
      styleClass = "triggered";
    }
  }

  return (
    <div className={`component-deal-special-overlay ${styleClass} ${size}`}>
      {deal.flash && (
        <>
          <img
            className="deal-special-background-image"
            src={redRibbon}
            alt="deal-type-flash"
          />
          <div className="deal-type-container">
            <i className="fa fa-bolt" />
            <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
              Flash Deal
            </div>
          </div>
        </>
      )}
      {deal.triggered && (
        <>
          {deal.active ? (
            <>
              <img
                className="deal-special-background-image"
                src={greenRibbon}
                alt="deal-type-triggered"
              />
              <div
                className={`deal-type-container ${
                  deal.triggerEndAt ? "triggered" : ""
                }`}
              >
                <i className="fa fa-clock-o" />
                <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
                  {deal.triggerEndAt ? (
                    <div className="triggered-text">
                      <div>Triggered </div>
                      <div
                        className={`${isMobile ? "mobile-b5" : " mobile-h5"}`}
                      >
                        until{" "}
                        {moment(deal.triggerEndAt).format(
                          "MMMM D, YYYY [at] h:mma"
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>Triggered!</div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <img
                className="deal-special-background-image"
                src={blueRibbon}
                alt="deal-type-not-triggered"
              />
              <div className="deal-type-container">
                <i className="fa fa-clock-o" />
                <div className={`${isMobile ? "mobile-b2" : " standard-h5"}`}>
                  Not triggered
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DealSpecialOverlay;
