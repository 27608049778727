import React from "react";
import styled from "styled-components/macro";

import { COLORS, StepProgressBar } from "../../assets/fansaves-ui";
import useMobile from "../../hooks/useMobile";

const Title = styled.h1`
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 3%;
  font-family: "Manrope", sans-serif;
  line-height: 34px;
  color: ${COLORS.primaryBlack};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: ${({ isMobile }) => (isMobile ? "10px" : "20px")};
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2%;
  font-family: "Manrope", sans-serif;
  line-height: 24px;
  color: ${COLORS.primaryBlue};
  text-align: center;
  max-width: 400px;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const OnboardingTitle = ({ stepNumber, renderSubtitle }) => {
  const isMobile = useMobile();
  return (
    <TitleContainer key={stepNumber} isMobile={isMobile}>
      <Title>Sign Up</Title>
      {renderSubtitle ? (
        renderSubtitle()
      ) : (
        <SubTitle>
          Complete your profile to receive more personalized deals!
        </SubTitle>
      )}
      <StepProgressBar stepsTotal={3} currentStep={stepNumber} />
    </TitleContainer>
  );
};

export default OnboardingTitle;
