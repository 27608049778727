import React from "react";
import styled from "styled-components/macro";

import {
  FlexContainer,
  DashboardCard,
  DashboardTitle,
  DashboardFilter,
  LineChart,
  SpinLoader,
} from "../../assets/fansaves-ui";

const StyledFlexContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-around;
  gap: 50px;
  margin: 50px auto;
  width: 100%;
  position: relative;
  .dashboard-filter {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

const DashboardWebAnalytics = ({
  impressionsChartData,
  onSelectFilter,
  filter,
  onDaysAdjustment,
  filterDaysAdjustment,
}) => {
  const renderLoadingContainer = () => {
    return (
      <FlexContainer
        className="spin-loader-container"
        direction="horizontal"
        margin="30px auto"
        height="100px"
      >
        <SpinLoader />
      </FlexContainer>
    );
  };
  return (
    <StyledFlexContainer className="dashboard-redemption-analytics-container">
      <DashboardFilter
        className="dashboard-filter"
        onSelect={onSelectFilter}
        type="daysChart"
      />
      <FlexContainer
        className="charts"
        direction="horizontal"
        justifyContent="space-around"
        alignItems="flex-start"
        gap="20px"
        width="100%"
      >
        {/* leave this comments for future use */}
        {/* <FlexContainer direction="vertical" gap="20px" width="100%">
          <DashboardTitle title="Web Views" className="dashboard-title" />
          <DashboardCard
            className="dashboard-web-views-chart-card"
            shadowStyle
            width="100%"
            height="310px"
            padding="20px 15px"
          >
            {impressionsChartData && (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={filter}
                fill
              />
            )}
          </DashboardCard>
        </FlexContainer> */}
        <FlexContainer direction="vertical" gap="20px" width="100%">
          <DashboardTitle title="Impressions" className="dashboard-title" />
          <DashboardCard
            className="dashboard-impressions-chart-card"
            shadowStyle
            width="100%"
            height="310px"
            padding="20px 15px"
          >
            {impressionsChartData ? (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={filter}
              />
            ) : (
              renderLoadingContainer()
            )}
          </DashboardCard>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        className="charts"
        direction="horizontal"
        justifyContent="space-around"
        alignItems="flex-start"
        gap="20px"
        width="100%"
      >
        {/* leave this comments for future use */}
        {/* <FlexContainer
          className="ios-chart-container"
          direction="vertical"
          gap="20px"
          width="100%"
        >
          <DashboardTitle
            title="iOS Downloads"
            className="dashboard-chart-title"
          />
          <DashboardCard
            className="dashboard-ios-downloads-chart-card"
            shadowStyle
            width="100%"
            height="310px"
            padding="20px 15px"
          >
            {impressionsChartData && (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={filter}
              />
            )}
          </DashboardCard>
        </FlexContainer> */}
        {/* <FlexContainer
          className="ios-chart-container"
          direction="vertical"
          gap="20px"
          width="100%"
        >
          <DashboardTitle
            title="Android Downloads"
            className="dashboard-chart-title"
          />
          <DashboardCard
            className="dashboard-android-downloads-chart-card"
            shadowStyle
            width="100%"
            height="310px"
            padding="20px 15px"
          >
            {impressionsChartData && (
              <LineChart
                dataSet={[impressionsChartData]}
                showLegend
                filter={filter}
              />
            )}
          </DashboardCard>
        </FlexContainer> */}
      </FlexContainer>
    </StyledFlexContainer>
  );
};

export default DashboardWebAnalytics;
