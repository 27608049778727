import { makeAutoObservable, runInAction } from "mobx";
import SubCategory from "./subcategory";
import { categoryPage } from "./categoryPage";

export default class Category {
  constructor(database, categoryId, name, subCategories=[]) {
    this.database = database;
    this.categoryId = categoryId;
    this.name = name;
    this.subCategories = [];

    this.isEditing = false;
    this.newCategoryName = name;

    this.toBeCreatedSubCategoryName = "";

    if(subCategories.length > 0){
      subCategories.forEach((subCategoryName) => {
        this.subCategories.push(new SubCategory(database, this, subCategoryName));
      });
    };

    makeAutoObservable(this, {}, {autoBind: true});
  }

  toBeCreatedSubCategoryNameOnChange(newName){
    this.toBeCreatedSubCategoryName = newName;
  }

  async createSubCategory(){
    runInAction(() => {
      this.subCategories.push(new SubCategory(this.database, this, this.toBeCreatedSubCategoryName));
    });
    await this.saveSubCategoriesToDatabase();
    runInAction(() => {
      this.toBeCreatedSubCategoryName = "";
    })
  }

  editButtonOnClick(){
    this.isEditing = true;
  }

  newCategoryNameOnChange(newName){
    this.newCategoryName = newName;
  }

  async saveCategoryNameOnClick(){
    await this.database.ref(`category/${this.categoryId}`).update({
      name: this.newCategoryName
    });
    runInAction(() => {
      this.name = this.newCategoryName;
      this.isEditing = false;
    });
  }

  async removeButtonOnClick(){
    await categoryPage.removeCategory(this.categoryId);
  }

  async removeSubCategory(subCategoryId){
    const updatedSubCategories = this.subCategories.filter(subCategory => subCategory.id !== subCategoryId);
    runInAction(() => {
      this.subCategories = updatedSubCategories;
    });
    await this.saveSubCategoriesToDatabase();
  }

  async saveSubCategoriesToDatabase(){
    await this.database
      .ref(`category/${this.categoryId}/subcategories`)
      .set(
        this.subCategories.map(subCategory => subCategory.name)
      );
  }
}