/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { ButtonGroup, Button as RSButton } from "reactstrap";

import { Button } from "../UIKit";
import { withFirebase } from "../Firebase";
import FriendlyDateTime from "../FriendlyDateTime";
import Price from "../Price";
import SponsorPurchaseDashboardTotalStats from "./SponsorPurchaseDashboardTotalStats";

import "./SponsorPurchasesDashboard.scss";

function SponsorPurchasesDashboard({
  sponsorKey,
  firebase,
  statsOnly,
  disablePayout,
}) {
  const [sponsor, setSponsor] = useState();
  const [loadingPurchases, setLoadingPurchases] = useState(true);
  const [offerPurchases, setOfferPurchases] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [sponsorStatTotal, setSponsorStatTotal] = useState(undefined);

  const loadPurchases = () => {
    const sponsorPath = `/sponsor/${sponsorKey}`;
    const sponsorRef = firebase.database.ref(sponsorPath);

    const offerPurchases = {};

    sponsorRef
      .once("value")
      .then((snapshot) => {
        if (snapshot.val() !== null) {
          const sponsor = snapshot.val();
          sponsor.key = snapshot.key;
          setSponsor(sponsor);
          return sponsor;
        } else {
          setLoadingPurchases(false);
        }
      })
      .then((sponsor) => {
        const offerPromises = [];
        if (sponsor.offers) {
          sponsor.offers.forEach((offer) => {
            const offerPath = `/offer/${offer.key}`;
            const offerRef = firebase.database.ref(offerPath);
            offerPromises.push(offerRef.once("value"));
          });
        }

        return Promise.all(offerPromises);
      })
      .then((offerSnapshots) => {
        const purchasePromises = [];
        offerSnapshots.forEach((offerSnapshot) => {
          if (offerSnapshot.val() !== null) {
            const offer = offerSnapshot.val();
            offer.key = offerSnapshot.key;

            if (offer.flashDeal) {
              offerPurchases[offer.key] = {
                offerKey: offer.key,
                offer,
                purchases: [],
              };

              if (offer.purchases) {
                Object.entries(offer.purchases).forEach((purchaseEntry) => {
                  const purchaseKey = purchaseEntry[0];
                  const purchasePath = `purchase/${purchaseKey}`;
                  const purchaseRef = firebase.database.ref(purchasePath);
                  purchasePromises.push(purchaseRef.once("value"));
                });
              }
            }
          }
        });

        return Promise.all(purchasePromises);
      })
      .then((purchaseSnapshots) => {
        purchaseSnapshots.forEach((purchaseSnapshot) => {
          if (purchaseSnapshot.val() !== null) {
            const purchase = purchaseSnapshot.val();
            purchase.key = purchaseSnapshot.key;
            offerPurchases[purchase.offerKey].purchases.push(purchase);
          }
        });

        setOfferPurchases(offerPurchases);
        setLoadingPurchases(false);
      })
      .catch((error) => {
        setLoadingPurchases(false);
        alert(error.message);
      });
  };

  const redeemPurchase = (purchase) => {
    const confirmationMessage =
      `Once you confirm that Flash Deal code "${purchase.code}" has been redeemed, ` +
      `it will no longer be accessible to the user.\n\nBy clicking OK, you confirm that ` +
      `this deal has been redeemed and used in your establishment.\n\nThis action cannot ` +
      `be undone.`;

    if (window.confirm(confirmationMessage)) {
      const purchasePath = `/purchase/${purchase.key}`;
      const purchaseRef = firebase.database.ref(purchasePath);

      purchaseRef
        .update({
          redeemed: true,
          redeemedAt: firebase.DatabaseServerValue().TIMESTAMP,
        })
        .then(() => {
          loadPurchases();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const getPayoutAmountFromPurchases = (purchases) => {
    let totalPayout = 0;
    purchases.forEach((purchase) => {
      if (purchase.businessTransferAmount) {
        totalPayout += purchase.businessTransferAmount;
      } else {
        totalPayout += purchase.price * 0.9;
      }
    });

    return totalPayout;
  };

  useEffect(() => {
    let totalPurchasesUSD = 0,
      totalRevenueUSD = 0,
      totalPayoutUSD = 0,
      totalPurchasesCAD = 0,
      totalRevenueCAD = 0,
      totalPayoutCAD = 0;

    Object.values(offerPurchases).forEach(({ purchases, offer }) => {
      if (offer.currency === "USD") {
        totalPurchasesUSD += purchases.length;
        totalRevenueUSD += offer.price * purchases.length;
        totalPayoutUSD += getPayoutAmountFromPurchases(purchases);
      } else {
        totalPurchasesCAD += purchases.length;
        totalRevenueCAD += offer.price * purchases.length;
        totalPayoutCAD += getPayoutAmountFromPurchases(purchases);
      }
    });

    const resultStat = {
      USD: {
        totalPurchase: totalPurchasesUSD,
        totalRevenue: totalRevenueUSD,
        totalPayout: totalPayoutUSD,
      },
      CAD: {
        totalPurchase: totalPurchasesCAD,
        totalRevenue: totalRevenueCAD,
        totalPayout: totalPayoutCAD,
      },
    };

    setSponsorStatTotal(resultStat);
  }, [offerPurchases]);

  useEffect(() => {
    loadPurchases();
  }, []);

  return (
    <div className="component-sponsor-purchases-dashboard">
      <div className="sponsor-header" data-notranslate>
        <span data-notranslate>{sponsor && sponsor.name}</span> Purchases
      </div>
      <div className="mode-container">
        {!statsOnly && (
          <ButtonGroup>
            <RSButton
              onClick={() => setViewMode("list")}
              color={viewMode === "list" ? "primary" : "secondary"}
            >
              Purchases
            </RSButton>
            <RSButton
              onClick={() => setViewMode("stats")}
              color={viewMode === "stats" ? "primary" : "secondary"}
            >
              Stats
            </RSButton>
          </ButtonGroup>
        )}
      </div>

      {loadingPurchases ? (
        <div>Loading purchases...</div>
      ) : (
        <>
          {(statsOnly || viewMode === "stats") && sponsorStatTotal && (
            <>
              <SponsorPurchaseDashboardTotalStats
                totalPurchase={sponsorStatTotal.USD.totalPurchase}
                totalRevenue={sponsorStatTotal.USD.totalRevenue}
                totalPayout={sponsorStatTotal.USD.totalPayout}
                disablePayout={disablePayout}
                currency="USD"
              />
              <SponsorPurchaseDashboardTotalStats
                totalPurchase={sponsorStatTotal.CAD.totalPurchase}
                totalRevenue={sponsorStatTotal.CAD.totalRevenue}
                totalPayout={sponsorStatTotal.CAD.totalPayout}
                disablePayout={disablePayout}
                currency="CAD"
              />
            </>
          )}
          {Object.values(offerPurchases).map((offerPurchase) => {
            return (
              <div className="offer-section" key={offerPurchase.offerKey}>
                <div className="offer-description-header">
                  {offerPurchase.offer.description}
                </div>

                {(statsOnly || viewMode === "stats") && (
                  <div className="offer-info">
                    <div className="view-header">Info</div>
                    <div className="stats">
                      <div className="stat">
                        <div className="label">Purchase Price</div>
                        <div className="value">
                          <Price
                            value={offerPurchase.offer.price}
                            currency={offerPurchase.offer.currency}
                          />
                        </div>
                      </div>
                      <div className="stat">
                        <div className="label">Purchases</div>
                        <div className="value">
                          {offerPurchase.purchases.length}
                        </div>
                      </div>
                      <div className="stat">
                        <div className="label">Revenue</div>
                        <div className="value">
                          <Price
                            value={
                              offerPurchase.offer.price *
                              offerPurchase.purchases.length
                            }
                            currency={offerPurchase.offer.currency}
                          />
                        </div>
                      </div>
                      {!disablePayout && (
                        <div className="stat payout-stat">
                          <div className="label">Payout</div>
                          <div className="value">
                            <Price
                              value={getPayoutAmountFromPurchases(
                                offerPurchase.purchases
                              )}
                              currency={offerPurchase.offer.currency}
                            />
                          </div>
                        </div>
                      )}
                      <div className="stat">
                        <div className="label">View Deal</div>
                        <div className="value">
                          <Link
                            to={`/offer/${offerPurchase.offer.key}`}
                            target="_blank"
                          >
                            <i className="fa fa-share" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {!statsOnly && viewMode === "list" && (
                  <div className="offer-purchases">
                    <div className="view-header">Purchases</div>
                    {offerPurchase.purchases.length === 0 ? (
                      <div>
                        No one has purchased this deal. Please check back later.
                      </div>
                    ) : (
                      <Table>
                        <thead>
                          <tr>
                            <th>Purchased</th>
                            <th>Code</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {offerPurchase.purchases.map((purchase) => {
                            return (
                              <tr key={purchase.key}>
                                <td className="purchased-col">
                                  <FriendlyDateTime
                                    timestamp={purchase.createdAt}
                                  />
                                </td>
                                <td className="code-col">{purchase.code}</td>
                                <td>
                                  {purchase.redeemed ? (
                                    <>
                                      Redeemed on{" "}
                                      <FriendlyDateTime
                                        timestamp={purchase.redeemedAt}
                                      />
                                    </>
                                  ) : (
                                    <Button
                                      className="primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        redeemPurchase(purchase);
                                      }}
                                    >
                                      Redeem
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default withFirebase(SponsorPurchasesDashboard);
